'use strict';

angular.module('FormManagement.submissions').controller('FormManagementSubmissionCtrl', ['$scope', '$rootScope', '$submissionService', '$sidePanel', '$q', '$simpleFilterService', '$pipelineService', '$interval', '$browserService', '$modalService', '$userColumnConfigService', '$translate', '$usersContextService', '$customerUserService', '$timeout', function ($scope, $rootScope, $submissionService, $sidePanel, $q, $simpleFilterService, $pipelineService, $interval, $browserService, $modalService, $userColumnConfigService, $translate, $usersContextService, $customerUserService, $timeout) {
  const POLL_HOOK_STATUS_INTERVAL = 5000;

  var self = this;
  var customerId = $scope.$state.params.customerId;
  var pollPipelineStatusInterval;
  var unfinishedPipelineRegistrationIds = [];
  var registrations;
  var mailStatusColumn = {
    size: 'small',
    id: 'mailStatuses',
    name: $translate.instant('EMAIL'),
    $selected: true,
    searchable: false,
    customTemplate: require('../templates/formmanagement.submissions.mailstatus.html')
  };
  var hooksColumn = {
    size: 'small',
    id: 'meta.hookPipelineStatus',
    name: $translate.instant('HOOKS'),
    $selected: true,
    searchable: false,
    customTemplate: require('../templates/formmanagement.submissions.pipelinestatus.html')
  };
  var savedColumns = $userColumnConfigService.getColumnConfigForForm($scope.form ? $scope.form.id: null);

  self.filter = {
    currentPage: 0,
    pageSize: 20,
    sortInfo: {
      directions: [-1],
      fields: ['info.date']
    },
    query: {}
  };
  self.hasSubmissions = false;
  self.idSelection = [];
  self.defaultColumns = [mailStatusColumn, hooksColumn];
  self.visibleColumns = [];
  self.columns = [];
  self.hasFilters = () => !angular.equals(self.filter.query, {});

  init();

  ////////
  function init() {
    $usersContextService.setUsersPromise($customerUserService.getUsers(customerId).$promise);
    setupColumns();

    $scope.$on('hook.pipeline.retry', function (event, registrationId) {
      unfinishedPipelineRegistrationIds.push(registrationId);
    });

    $scope.$on('$destroy', function () {
      killInterval(pollPipelineStatusInterval);
    });

    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', function () {
      $timeout(() => $scope.$destroy(), 0);
    });

    $scope.$on('form.changed', function (event, data) {
      killInterval(pollPipelineStatusInterval);
      savedColumns = $userColumnConfigService.getColumnConfigForForm(data.form.id);

      $scope.$broadcast('paginatedmoregrid.reload');

      self.idSelection = [];
      self.hasSubmissions = false;
      setupColumns();
    });
  }

  function setVisibleColumns() {
    self.visibleColumns = self.defaultColumns.concat(self.selectedColumns);
  }

  function setupColumns() {
    if (savedColumns) {
      savedColumns = savedColumns.filter(function (column) {
        return column.id !== 'mailStatuses' && column.id !== 'meta.hookPipelineStatus';
      });
    }
    self.columns = savedColumns || [];

    if (!savedColumns) {
      self.columns.push({ id: 'info.userId', name: $translate.instant('USER'), $selected: true, type: 'string' });
      self.columns.push({ id: 'info.date', name: $translate.instant('DATE'), $selected: true, type: 'datetime', asTimestamp: true });
      self.columns.push({ id: 'meta.serialNumber', name: $translate.instant('SERIAL_NUMBER'), $selected: true, type: 'number' });
    }

    self.selectedColumns = self.columns.filter(function (col) {
      return col.$selected;
    });
    setVisibleColumns();
  }

  self.columnDefinitionChanged = function (selectedColumns) {
    self.selectedColumns = selectedColumns;
    $userColumnConfigService.setColumnConfigForForm($scope.form.id, self.columns);
    setVisibleColumns();
  };

  self.getSubmissions = function (filter) {
    if (!$scope.form) {
      var deferred = $q.defer();
      deferred.resolve({ listedObjects: [], totalItems: 0 });
      return deferred.promise;
    }

    var simpleFilter = $simpleFilterService.gridFilterToSimpleFilter(filter);
    return $submissionService.filterSubmissions(customerId, $scope.form.id, filter.currentPage, simpleFilter).$promise.then(function (entries) {

      self.hasSubmissions = entries.totalSize > 0;

      var knownHeaderKeys = self.columns.map(function (col) {
        return col.id;
      });
      var newHeaders = entries.headers.filter(function (header) {
        return header.id.match(/^data\./) && knownHeaderKeys.indexOf(header.id) === -1;
      });
      newHeaders.forEach(function (newHeader) {
        self.columns.push({
          id: newHeader.id,
          name: newHeader.label,
          statistics: {}
        });
      });

      var submissionIds = entries.elements.map(function (entry) {
        return entry.id;
      });

      return $pipelineService.getPipelineInfos(customerId, $scope.form.id, submissionIds).$promise.then(function (infos) {
        appendHookPipelineStatusToRegistrations(entries.elements, infos);
        startPollingIfNecessary();

        registrations = entries.elements;

        self.totalRegistrations = entries.totalSize;

        return {
          listedObjects: entries.elements,
          totalItems: entries.totalSize
        };
      });
    });
  };

  function startPollingIfNecessary() {
    if (!pollPipelineStatusInterval && unfinishedPipelineRegistrationIds.length > 0) {
      pollPipelineStatusInterval = $interval(refreshPipelineStatus, POLL_HOOK_STATUS_INTERVAL);
    }
  }

  function appendHookPipelineStatusToRegistrations(registrations, infos) {
    registrations.forEach(function (registration) {
      var registrationHookPipelineInfo = infos.filter(function (info) {
        return info.registrationId === registration.id;
      });
      registration.meta.hookPipelineStatus = registrationHookPipelineInfo[0] && registrationHookPipelineInfo[0].status;

      if (['OPEN', 'IN_PROGRESS', 'RETRY'].indexOf(registration.meta.hookPipelineStatus) > -1) {
        unfinishedPipelineRegistrationIds.push(registration.id);
      }
    });
  }

  function refreshPipelineStatus() {
    $pipelineService.getPipelineInfos(customerId, $scope.form.id, unfinishedPipelineRegistrationIds).$promise.then(function (infos) {
      registrations.forEach(function (registration) {
        if (unfinishedPipelineRegistrationIds.indexOf(registration.id) === -1) {
          return;
        }

        var pipelineInfoForRegistration = infos.filter(function (info) {
          return info.registrationId === registration.id;
        })[0];

        registration.meta.hookPipelineStatus = pipelineInfoForRegistration && pipelineInfoForRegistration.status;
        if (registration.meta.hookPipelineStatus === 'SUCCESS' || registration.meta.hookPipelineStatus === 'BLOCKED') {
          unfinishedPipelineRegistrationIds.splice(unfinishedPipelineRegistrationIds.indexOf(registration.id), 1);
        }
      });
    });

    if (unfinishedPipelineRegistrationIds.length < 1) {
      killInterval(pollPipelineStatusInterval);
    }
  }

  self.viewRegistration = function (submission) {
    $sidePanel.open({
      template: require('../templates/formmanagement.submissionDetails.html'),
      windowClass: 'sidepanel-large',
      resolve: {
        submission: () => submission,
        dataColumns: () => self.columns.filter(function (column) {
          return column.id.match(/^data\./);
        }),
        canShowData: () => submission.info.paid
      },
      controller: 'FormManagementSubmissionDetailCtrl'
    }).then(function (result) {
      if (result && result.deleted === true) {
        $scope.$broadcast('paginatedmoregrid.reload');
      }
    });
  };

  self.changedSelection = function (idSelection) {
    self.idSelection = idSelection;
  };

  self.toggleSearch = function () {
    self.searchEnabled = !self.searchEnabled;
    if (!self.searchEnabled) {
      $scope.$broadcast('paginatedmoregrid.clear_search');
    }
  };

  self.clearSelection = function () {
    while (self.idSelection.length > 0) {
      self.idSelection.pop();
    }
  };

  self.export = () => {
    $modalService.open({
      template: require('../templates/modals/formmanagement.submissions.export.modal.html'),
      controller: 'ExportSubmissionsModalCtrl',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        formId: () => $scope.form.id,
        filter: () => self.filter,
        idSelection: () => self.idSelection
      }
    });
  };

  function killInterval(interval) {
    if (interval) {
      $interval.cancel(interval);
    }
  }
}]);
