'use strict';

angular.module('SelectableDropdown').directive('selectableReorderableDropdown', function () {

  var selectedFilter = function (model) {
    return model.$selected;
  };

  return {
    restrict: 'E',
    replace: true,
    scope: {
      models: '=',
      title: '@',
      orderChanged: '&',
      selectionChanged: '&'
    },
    template: require('../templates/SelectableReorderableDropdown.html'),

    link: function (scope) {
      scope.open = false;
      scope.toggleDropdown = function () {
        scope.open = !scope.open;
      };

      scope.toggleModel = function (model, change, $event) {
        if ($event.target.nodeName !== 'INPUT') {
          model.$selected = !model.$selected;
        }

        var selectedModels = scope.models.filter(selectedFilter);
        scope.selectionChanged({ models: scope.models, selectedModels: selectedModels });
      };

      scope.sortableOptions = {
        orderChanged: function () {
          var selectedModels = scope.models.filter(selectedFilter);
          scope.$apply(function () {
            scope.orderChanged({ models: scope.models, selectedModels: selectedModels });
          });
        }
      };
    }
  };
});