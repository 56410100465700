'use strict';
angular.module('more.ui.form').directive('fieldLabel', function () {
  return {
    restrict : 'E',
    template : require('../templates/field-label.html'),
    scope : {
      'label' : '=',
      'required' : '='
    },
    replace : true
  };
});
