'use strict';

angular.module('FormEditor').directive('actionPicker', ['$formVersionUtilityService', '$widgetService', '$translate', '$stringUtils', function ($formVersionUtilityService, $widgetService, $translate, $stringUtils) {
  return {
    restrict: 'AE',
    template: require('../../../shared/templates/directives/action-picker.html'),
    scope: {
      action: '=',
      targetView: '=',
      widgets: '='
    },
    controller: ['$scope', function ($scope) {

      var init = function () {
        $scope.getFieldDisplayName = $formVersionUtilityService.getFieldDisplayName;

        $scope.updateActionFields();
        $scope.updateActionTypes();

        $scope.updateSelectedTargetField();
        $scope.updateSelectedActionType();
        $scope.updateTargetValue();
        $scope.actionValueObjectKeys = allowedConditionValues($scope.selectedTargetField);
      };

      var getSelectedTarget = function () {
        if (!$scope.targetList) {
          return null;
        }
        for (var i = 0; i < $scope.targetList.length; i++) {
          if ($scope.targetList[i].uid === $scope.action.fieldUid) {
            return $scope.targetList[i];
          }
        }
        return null;
      };

      var widgetForUid = function (widgetUid) {
        var result = $scope.widgets.filter(function (widget) {
          return $widgetService.sanitizeUid(widget.info.uid) === $widgetService.sanitizeUid(widgetUid);
        });
        return result.shift();
      };

      $scope.valueLabelForId = function (id) {
        var answer = null;
        $scope.actionValueObjectKeys.forEach(function (option) {
          if (option.id === id) {
            answer = option.label;
          }
        });
        return answer;
      };

      var fieldForUid = function (view, fieldUid) {
        var innerViewObjects = view.fields || view.items;
        return innerViewObjects.filter(function (item) {
          return item.uid === fieldUid;
        })[0];
      };

      var allowedConditionValues = function (targetField) {
        var answer = [];
        if (targetField) {
          var type = targetField.widget.definition.data && targetField.widget.definition.data.type;

          switch (type) {
            case 'boolean':
              answer.push({ id: true, label: $translate.instant('CHECKED') });
              answer.push({ id: false, label: $translate.instant('UNCHECKED') });
              return answer;
            case 'option':
              var field = fieldForUid($scope.targetView, targetField.uid);
              var options = field.properties.radio_options || field.properties.options;
              options.forEach(function (option) {
                answer.push({ id: option.id, label: option.name });
              });
              return answer;
            default:
              return null;
          }
        }
      };

      $scope.updateSelectedTargetField = function () {
        if ($scope.action.fieldUid && $scope.targetList) {
          for (var j = 0; j < $scope.targetList.length; j++) {
            var targetField = $scope.targetList[j];
            if (targetField.uid === $scope.action.fieldUid) {
              $scope.widgetIconForTarget = targetField.widget.definition.icon;
              $scope.widgetDisplayName = targetField.displayName;
              $scope.selectedTargetField = targetField;
            }
          }
        }
      };

      $scope.updateTargetValue = function () {
        $scope.targetValue = $scope.action.value;
      };

      $scope.selectTargetField = function (targetField) {
        if ($scope.action.fieldUid !== targetField.uid) {
          $scope.action.key = null;
          $scope.keyDisplayName = null;
          $scope.action.value = null;
          $scope.fieldValueDisplayName = null;
        }
        $scope.action.fieldUid = targetField.uid;
        $scope.updateSelectedTargetField();
        $scope.updateActionTypes();
        $scope.updateSelectedActionType();
      };

      $scope.updateActionTypes = function () {
        var field = getSelectedTarget();
        var answer = [];
        if (field) {
          var widget = field.widget;
          if (widget) {
            answer.push({ name: 'RULE_VISIBLE', key: 'visible', value: true });
            answer.push({ name: 'RULE_HIDDEN', key: 'visible', value: false });
          }
          var settableDataTypes = ['text', 'number', 'lookup', 'option', 'datetime-string', 'time-string', 'date-string', 'boolean', 'email'];
          var widgetDataType = widget.definition.data && widget.definition.data.type;

          if (settableDataTypes.indexOf(widgetDataType) > -1 && !$stringUtils.endsWithAny(widget.fullkey, [':calculation', ':currentlocation', ':stopwatch', ':timecalculation'])) {
            answer.push({ name: 'RULE_GETS_VALUE', key: 'value', value: '' });
          }
        }
        $scope.actionTypes = answer;
      };

      $scope.updateSelectedActionType = function () {
        var newSelectedActionType = null;
        $scope.actionTypes.forEach(function (action) {
          if (typeof action.value === 'boolean') {
            if (action.key === $scope.action.key && action.value === $scope.action.value) {
              newSelectedActionType = action;
            }
          } else {
            if (action.key === $scope.action.key) {
              newSelectedActionType = action;
            }
          }
        });
        $scope.selectedActionType = newSelectedActionType;
      };

      $scope.updateActionFields = function () {
        $scope.targetList = [];

        var targetArray = [];
        if ($scope.targetView && $scope.targetView.fields) {
          targetArray = $scope.targetView.fields;
        } else if ($scope.targetView && $scope.targetView.items) {
          targetArray = $scope.targetView.items;
        }

        for (var j = 0; j < targetArray.length; j++) {
          var field = targetArray[j];
          $scope.targetList.push({ displayName: $scope.getFieldDisplayName(field), uid: field.uid, widget: widgetForUid(field.widget) });
        }
      };

      $scope.changeActionType = function (actionType) {
        $scope.action.key = actionType.key;
        $scope.action.value = actionType.value;
        $scope.action.name = actionType.name;
        $scope.selectedActionType = actionType;

        $scope.actionValueObjectKeys = allowedConditionValues($scope.selectedTargetField);
      };

      $scope.setTargetValue = function (value) {
        $scope.action.value = value;
      };

      init();
    }]
  };
}]);
