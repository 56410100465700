'use strict';

angular.module('Billing').directive('billingPlanSelector', function () {
  return {
    restrict: 'E',
    scope: {
      onSelectPlan: '&',
      activePlanId: '=?'
    },
    template: require('../templates/directives/billing.plan-selector.html'),
    link: function (scope) {
      scope.isActivePlan = (plan) => scope.activePlanId && plan.id === scope.activePlanId;
    }
  };
});
