'use strict';

angular.module('UserManagement').controller('UserManagementRolesCtrl', ['$scope', '$state', 'moreConstants', '$modalService', '$sidePanel', '$customerRolesService', '$featureHelper', function ($scope, $state, moreConstants, $modalService, $sidePanel, $customerRolesService, $featureHelper) {

  const self = this;

  self.roles = [];
  self.createRole = createRole;
  self.deleteRole = deleteRole;
  self.updateRoleName = updateRoleName;

  self.permissionCategories = [
    {
      label: 'FORMS',
      permissions: [
        {key: 'CREATE_FORM', label: 'CREATE_FORM', description: 'CREATE_FORM_TOOLTIP'},
        {key: 'UPDATE_FORM', label: 'UPDATE_FORM', description: 'UPDATE_FORM_TOOLTIP'},
        {key: 'ARCHIVE_FORM', label: 'ARCHIVE_FORM', description: 'ARCHIVE_FORM_TOOLTIP'},
      ]
    },
    {
      label: 'FOLDERS',
      permissions: [
        {key: 'CREATE_FOLDER', label: 'CREATE_FOLDER', description: 'CREATE_FOLDER_TOOLTIP'},
        {key: 'ADD_FORM', label: 'ADD_FORM', description: 'ADD_FORM_TOOLTIP'},
        {key: 'REMOVE_FORM', label: 'REMOVE_FORM', description: 'REMOVE_FORM_TOOLTIP'},
        {key: 'UPDATE_FOLDER', label: 'UPDATE_FOLDER', description: 'UPDATE_FOLDER_TOOLTIP'},
        {key: 'DELETE_FOLDER', label: 'DELETE_FOLDER', description: 'DELETE_FOLDER_TOOLTIP'},
      ]
    },
    {
      label: 'SUBMISSIONS',
      permissions: [
        {key: 'CREATE_SUBMISSION', label: 'CREATE_SUBMISSION', description: 'CREATE_SUBMISSION_TOOLTIP'},
        {key: 'READ_SUBMISSION', label: 'READ_SUBMISSION', description: 'READ_SUBMISSION_TOOLTIP'},
        {key: 'DELETE_SUBMISSION', label: 'DELETE_SUBMISSION', description: 'DELETE_SUBMISSION_TOOLTIP'},
        {key: 'UPDATE_SUBMISSION', label: 'UPDATE_SUBMISSION', description: 'UPDATE_SUBMISSION_TOOLTIP'},
      ]
    },
    {
      label: 'TASKS',
      permissions: [
        {key: 'CREATE_TASK', label: 'CREATE_TASK', description: 'CREATE_TASK_TOOLTIP'},
        {key: 'READ_TASK', label: 'READ_TASK', description: 'READ_TASK_TOOLTIP'},
        {key: 'UPDATE_TASK', label: 'UPDATE_TASK', description: 'UPDATE_TASK_TOOLTIP'},
        {key: 'DELETE_TASK', label: 'DELETE_TASK', description: 'DELETE_TASK_TOOLTIP'},
      ]
    },
    {
      label: 'ADMINISTRATIVE',
      permissions: [
        {key: 'MANAGE_FORMS', label: 'MANAGE_FORMS', description: 'MANAGE_FORMS_TOOLTIP'},
        {key: 'MANAGE_REGISTRATIONS', label: 'MANAGE_REGISTRATIONS', description: 'MANAGE_REGISTRATIONS_TOOLTIP'},
        {key: 'MANAGE_TASKS', label: 'MANAGE_TASKS', description: 'MANAGE_TASKS_TOOLTIP'},
        {key: 'MANAGE_USERS', label: 'MANAGE_USERS', description: 'MANAGE_USERS_TOOLTIP'},
        {key: 'MANAGE_SSO', label: 'MANAGE_SSO', description: 'MANAGE_SSO_TOOLTIP'},
        {key: 'MANAGE_SENDING_DOMAIN', label: 'MANAGE_SENDING_DOMAIN', description: 'MANAGE_SENDING_DOMAIN_TOOLTIP'},
        {key: 'MANAGE_CREDITS', label: 'MANAGE_BILLING', description: 'MANAGE_BILLING_TOOLTIP'},
        {key: 'MANAGE_DATA_SOURCES', label: 'MANAGE_DATA_SOURCES', description: 'MANAGE_DATA_SOURCES_TOOLTIP'},
        {key: 'MANAGE_WEBHOOKS', label: 'MANAGE_WEBHOOKS', description: 'MANAGE_WEBHOOKS_TOOLTIP'},
        {key: 'MANAGE_TEMPLATES', label: 'MANAGE_TEMPLATES', description: 'MANAGE_TEMPLATES_TOOLTIP'},
      ]
    }
  ];

  init();

  function init() {
    _loadRoles();
    $featureHelper.hasFeature(moreConstants.ADVANCED_USER_MANAGEMENT).then(hasFeature => {
      self.hasAdvancedUserManagementFeature = hasFeature;
    });
  }

  function updateRoleName(role) {
    return $customerRolesService.patchRole($state.params.customerId, role.id, {name: role.name});
  }

  function createRole($event) {
    if (verifyFeature($event)) {
      $sidePanel.open({
        template: require('../templates/usermanagement.roles.new.html'),
        controller: ['$scope', '$state', ($scope, $state) => {
          $scope.saveRole = (role) => {
            $customerRolesService.saveRole($state.params.customerId, role).then($scope.$close);
          };
        }]
      }).then(_loadRoles);
    }
  }

  function deleteRole(role) {
    $modalService.deleteModal({
      title : 'ROLE_DELETE_TITLE',
      message : 'ROLE_DELETE_MESSAGE',
    }).then(() => $customerRolesService.deleteRole($state.params.customerId, role.id)
        .then(_loadRoles,  error => $modalService.errorModal(error.data ? (error.data.data.reason || error.data.message) : error)));
  }

  function _loadRoles() {
    return $customerRolesService.getRoles($state.params.customerId).then(roles => {
      self.roles = roles;
    });
  }

  function verifyFeature($event) {
    if (self.hasAdvancedUserManagementFeature) {
      return true;
    }
    $event.preventDefault();
    $modalService.upgradePlanModal({
      title: 'FEATURE_ADVANCED_USER_MANAGEMENT',
      message: 'FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE',
      imgSrc: 'https://cdn.moreapp.com/features/en/feature-advanced-user-management-new.png'
    }).then($scope.$close); // close Sidepanel
  }
}]);
