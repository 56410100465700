'use strict';

angular.module('DataSources').controller('DataSourceManagementIndexCtrl', ['$scope', '$rootScope', '$interval', '$customerDataSourceService', '$sidePanel', '$modalService', '$simpleFilterService', '$translate', '$timeout', function ($scope, $rootScope, $interval, $customerDataSourceService, $sidePanel, $modalService, $simpleFilterService, $translate, $timeout) {

  var self = this;
  self.columns = [];
  self.selectedColumns = [];
  self.totalItems = null;
  self.loadingEntries = false;

  $scope.dataSource = null;

  self.filter = {
    currentPage: 0,
    pageSize: 20,
    sortInfo: {
      directions: [],
      fields: []
    },
    query: {}
  };

  var customerId = $scope.$state.params.customerId;
  var dataSourceId;
  var intervalId;
  var shouldUpdateActiveDataSource = false;

  init();

  function init() {
    $scope.$on('dataSource.changed', function (event, dataSource) {
      $scope.dataSource = dataSource;
      dataSourceId = dataSource.id;
      reloadEntries();
      $timeout(updateStatus, 0);
    });

    $scope.$on('dataSource.deleted', function () {
      $scope.dataSource = null;
      dataSourceId = null;
    });

    $scope.$on('$destroy', function () {
      destroyInterval();
    });

    // watch for data source source being updated
    intervalId = $interval(updateStatus, 15000);
  }

  self.columnDefinitionChanged = function (selectedColumns) {
    self.selectedColumns = selectedColumns;
  };

  self.viewDataSourceEntry = function (object) {
    $sidePanel.open({
      template: require('../templates/dataSourceEntryDetails.html'),
      resolve: {
        entry: function () {
          return object;
        },
        dataColumns: function () {
          return self.columns;
        }
      },
      controller: 'DataSourceEntryDetailCtrl'
    });
  };

  self.enableDataSource = function (dataSource) {
    $modalService.open({
      template: require('../templates/dataSource.enable.html'),
      resolve: {
        dataSource: function () {
          return dataSource;
        }
      }
    }).then(function () {
      dataSource.enabled = true;
      $customerDataSourceService.enable(customerId, dataSource).$promise.then(self.scheduleUrlImport);
    });
  };

  self.toggleSearch = function () {
    self.searchEnabled = !self.searchEnabled;
    if (!self.searchEnabled) {
      $scope.$broadcast('paginatedmoregrid.clear_search');
    }
  };

  self.getDataSourceEntries = function (filter) {
    var simpleFilter = $simpleFilterService.gridFilterToSimpleFilter(filter, { prefix: 'data.' });
    return $customerDataSourceService.filterDataSourceEntries(customerId, dataSourceId, filter.currentPage, simpleFilter).$promise.then(function (entries) {
      var listedObjects = entries.map(function (entry) {
        return entry.data;
      });
      return {
        listedObjects: listedObjects,
        totalItems: self.totalItems
      };
    });
  };

  self.updateDataSource = function (updatedDataSource) {
    $customerDataSourceService.update(customerId, updatedDataSource);
  };

  self.editDataSource = function () {
    $sidePanel.open({
      template: require('../templates/dataSource.edit.html'),
      resolve: {
        dataSource: function () {
          return $scope.dataSource;
        }
      },
      controller: 'EditDataSourcePanelCtrl'
    }).then(function (updatedDataSource) {
      $scope.dataSource = updatedDataSource;
    });
  };

  self.deleteDataSource = function (dataSource) {
    $modalService.open({
      template: require('../templates/dataSource.remove.html'),
      resolve: {
        dataSource: function () {
          return dataSource;
        }
      },
      controller: 'RemoveDataSourceCtrl'
    }).then(function () {
      $scope.dataSource = null;
      $rootScope.$broadcast('dataSource.deleted', dataSource);
    });
  };

  self.scheduleUrlImport = function () {
    $scope.dataSourceStatus.inProgress = true;

    $customerDataSourceService.scheduleDataSourceImport(customerId, dataSourceId).$promise.then(function () {
      shouldUpdateActiveDataSource = true;
      updateStatus();
      intervalId = $interval(updateStatus, 3000);
    });
  };

  self.uploadExcel = function () {
    $sidePanel.open({
      template: require('../templates/dataSource.uploadExcel.html'),
      resolve: {
        dataSource: function () {
          return $scope.dataSource;
        }
      },
      controller: 'UploadExcelDataSourceCtrl'
    }).then(function (result) {
      if (result.lastUpdateWarningMessages && result.lastUpdateWarningMessages.length > 0) {
        self.showWarningMessages(result.lastUpdateWarningMessages);
      }
      reloadEntries();
    });
  };

  self.showWarningMessages = function (messages) {
    $modalService.alertModal({
      title: 'DATA_SOURCE_WARNINGS_TITLE',
      template: require('../templates/dataSource.warnings.html'),
      data: {
        messages: messages
      }
    });
  };

  // helpers
  var updateStatus = function () {
    if (!dataSourceId) {
      return;
    }
    $customerDataSourceService.getDataSourceStatus(customerId, dataSourceId).$promise.then(function (result) {
      $scope.dataSourceStatus = result;
      if (!$scope.dataSourceStatus.inProgress) {
        if (shouldUpdateActiveDataSource) {
          $customerDataSourceService.getDataSource(customerId, dataSourceId).$promise.then(function (updated) {
            Object.keys(updated).filter(function (key) {
              return key.indexOf('$') !== 0;
            }).forEach(function (key) {
              $scope.dataSource[key] = updated[key];
            });
            reloadEntries();
            destroyInterval();
            if (updated.lastUpdateWarnings) {
              self.showWarningMessages(updated.lastUpdateWarnings);
            }
          });
        }
        shouldUpdateActiveDataSource = false;
      } else {
        shouldUpdateActiveDataSource = true;
      }
    });
  };

  var destroyInterval = function () {
    $interval.cancel(intervalId);
  };

  var reloadColumns = function () {
    self.columns = [];
    $scope.dataSource.columnMapping.forEach(function (col) {
      self.columns.push({ id: col.id, name: col.label, $selected: true });
    });
    self.selectedColumns = self.columns;
  };

  var reloadEntries = function () {
    self.loadingEntries = true;
    self.totalItems = null;
    $customerDataSourceService.getStatistics(customerId, dataSourceId).$promise.then(function (stats) {
      self.totalItems = stats.numberOfEntries;
      self.loadingEntries = false;
    });
    reloadColumns();
  };
}]);
