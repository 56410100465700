'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', function ($stateProvider, stateConfig) {

  $stateProvider.state('portal.customers.billing', {
    url: '/billing',
    template: require('../../modules/Billing/templates/billing.index.html'),
    abstract: true,
    access: stateConfig.defaultAccess
  }).state('portal.customers.billing.overview', {
    url: '/overview',
    data: {
      viewContentTemplate: require('../../modules/Billing/templates/billing.overview.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.billing.invoices', {
    url: '/invoices',
    data: {
      viewContentTemplate: require('../../modules/Billing/templates/billing.invoices.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.billing.usage', {
    url: '/usage',
    data: {
      viewContentTemplate: require('../../modules/Billing/templates/billing.usage.html')
    },
    access: stateConfig.defaultAccess
  });
}]);
