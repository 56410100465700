'use strict';

angular.module('MoreAppsServices').factory('$customerDataSourceService', ['$q', '$upload', 'moreConstants', '$resource', function ($q, $upload, moreConstants, $resource) {

  var CustomerDataSources = $resource(moreConstants.apiEndpoint + '/customers/:customerId/datasources/:dataSourceId/:action', {
    'customerId': '@customerId',
    'dataSourceId': '@dataSourceId'
  }, {
    update: {
      method: 'PUT'
    },
    status: {
      method: 'GET',
      params: {
        action: 'status'
      }
    },
    schedule: {
      method: 'POST',
      params: {
        action: 'schedule'
      }
    },
    enable: {
      method: 'POST',
      params: {
        action: 'enable'
      }
    }
  });

  var CustomerDataSourceEntries = $resource(moreConstants.apiEndpoint + '/customers/:customerId/datasources/:dataSourceId/entries', {
    'customerId': '@customerId',
    'dataSourceId': '@dataSourceId'
  }, {
    'statistics': {
      'method': 'POST'
    }
  });

  var CustomerDataSourceEntriesIndexed = $resource(moreConstants.apiEndpoint + '/customers/:customerId/datasources/:dataSourceId/entries/indexed', {
    'customerId': '@customerId',
    'dataSourceId': '@dataSourceId'
  }, {
    'indexed': {
      'method': 'POST',
      isArray: true
    }
  });

  var CustomerDataSourceEntriesPages = $resource(moreConstants.apiEndpoint + '/customers/:customerId/datasources/:dataSourceId/entries/page/:pageNumber', {
    'customerId': '@customerId',
    'dataSourceId': '@dataSourceId',
    'pageNumber': '@pageNumber'
  }, {
    'filter': {
      'method': 'POST',
      isArray: true
    }
  });

  var asDataSourcePayload = function (dataSource) {
    return {
      name: dataSource.name,
      urlConfiguration: dataSource.urlConfiguration,
      googleConfiguration: dataSource.googleConfiguration
    };
  };

  return {
    getDataSources: function (customerId) {
      return CustomerDataSources.query({ customerId: customerId });
    },
    getDataSource: function (customerId, dataSourceId) {
      return CustomerDataSources.get({ customerId: customerId, dataSourceId: dataSourceId });
    },
    filterDataSourceEntries: function (customerId, dataSourceId, pageNumber, filter) {
      return CustomerDataSourceEntriesPages.filter({
        customerId: customerId,
        dataSourceId: dataSourceId,
        pageNumber: pageNumber
      }, filter);
    },
    getIndexedDataSourceEntries: function (customerId, dataSourceId, mapping) {
      return CustomerDataSourceEntriesIndexed.indexed({ customerId: customerId, dataSourceId: dataSourceId }, mapping);
    },
    getStatistics: function (customerId, dataSourceId) {
      return CustomerDataSourceEntries.statistics({ customerId: customerId, dataSourceId: dataSourceId }, { pageSize: 1, sort: [] });
    },
    getDataSourceStatus: function (customerId, dataSourceId) {
      return CustomerDataSources.status({ customerId: customerId, dataSourceId: dataSourceId });
    },
    scheduleDataSourceImport: function (customerId, dataSourceId) {
      return CustomerDataSources.schedule({ customerId: customerId, dataSourceId: dataSourceId });
    },
    importFile: function (customerId, dataSourceId, file) {
      var deferred = $q.defer();

      $upload.upload({
        url: moreConstants.apiEndpoint + '/customers/' + customerId + '/datasources/' + dataSourceId + '/importFile',
        method: 'POST',
        data: { customerId: customerId, dataSourceId: dataSourceId },
        file: file,
        fileFormDataName: 'file'
      }).success(function (data) {
        deferred.resolve(data);
      }).error(function (data) {
        deferred.reject(data);
      });

      return deferred.promise;
    },
    save: function (customerId, dataSource) {
      return CustomerDataSources.save({ customerId: customerId }, asDataSourcePayload(dataSource));
    },
    update: function (customerId, dataSource) {
      return CustomerDataSources.update({ customerId: customerId, dataSourceId: dataSource.id }, asDataSourcePayload(dataSource));
    },
    remove: function (customerId, dataSource) {
      return CustomerDataSources.delete({ customerId: customerId, dataSourceId: dataSource.id });
    },
    enable: function (customerId, dataSource) {
      return CustomerDataSources.enable({ customerId: customerId, dataSourceId: dataSource.id });
    }
  };
}]);