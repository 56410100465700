'use strict';

angular.module('UserManagement').controller('GroupManagementPermissionsCtrl', ['$scope', '$rootScope', '$state', '$translate', '$timeout', '$customerFolderService', '$customerRolesService', '$customerGroupsService', '$modalService', 'moreConstants', function ($scope, $rootScope, $state, $translate, $timeout, $customerFolderService, $customerRolesService, $customerGroupsService, $modalService, moreConstants) {

  const self = this;
  let ROLES = [];

  self.changeGrant = changeGrant;
  self.removeGrant = removeGrant;
  self.changeAccountGrant = changeAccountGrant;
  self.removeAccountGrant = removeAccountGrant;
  self.getRoleInfo = $customerRolesService.getRoleInfo;

  init();

  function init() {
    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', () => {
      $timeout(() => $scope.$destroy(), 0);
    });
    $scope.$watch('selectedGroup', _loadPermissions);
  }

  function _loadPermissions() {
    self.accountRole = undefined;
    self.folders = [];
    self.accountRoles = [];
    self.folderRoles = [];
    self.formRoles = [];
    _loadFolders()
      .then(_loadRoles)
      .then(() => _loadGrants({expandFolders: true}));
  }

  function _loadFolders() {
    return $customerFolderService.getFolders($state.params.customerId).$promise.then(res => {
      self.folders = res;
    });
  }

  function _loadGrants(options) {
    const expandFolders = options && options.expandFolders;
    if (!$scope.selectedGroup.grants) {
      return;
    }
    const accountGrant = $scope.selectedGroup.grants.find(grant => grant.resourceType === 'CUSTOMER' && grant.customerId === parseInt($state.params.customerId));
    if (accountGrant) {
      self.accountRole = getRoleById(accountGrant.roleId);
    }

    self.folderGrants = new Map(
      $scope.selectedGroup.grants
        .filter(grant => grant.resourceType === 'FOLDER')
        .map(grant => [grant.resourceId, getRoleById(grant.roleId)])
    );

    self.formGrants = new Map(
      $scope.selectedGroup.grants
        .filter(grant => grant.resourceType === 'FORM')
        .map(grant => [grant.resourceId, getRoleById(grant.roleId)])
    );

    // automatically open folders with at least 1 form permission set
    if (expandFolders) {
      self.folders.forEach((folder) => {
        const hasFormPermission = folder.forms.filter(form => form.status !== 'TRASH').some(form => self.formGrants.get(form.id));
        if (hasFormPermission) {
          folder.$isOpen = true;
        }
      });
    }
  }

  function getRoleById (id) {
    return ROLES.find(role => role.id === id);
  }

  function _loadRoles() {
    return $customerRolesService.getRoles($state.params.customerId).then(roles => {
      // Filter all roles that don't do anything with groups
      ROLES = roles;

      self.formRoles = roles
        .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_FORM_PERMISSIONS.find(p => p === permission)));

      self.folderRoles = roles
        .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_FOLDER_PERMISSIONS.find(p => p === permission)));

      self.accountRoles = roles
        .filter(role => !role.permissions.find(permission => moreConstants.TRANSFER_OWNERSHIP === permission))
        .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_ACCOUNT_PERMISSIONS.find(p => p === permission)));
    });
  }

  function removeGrant(resourceId, resourceType) {
    const removeGrant = {
      operation: 'REMOVE',
      resourceId: resourceId,
      resourceType: resourceType
    };
    $customerGroupsService.patchGrant($state.params.customerId, $scope.selectedGroup.id, removeGrant)
      .then((grants) => {
        $scope.selectedGroup.grants = grants;
        _loadGrants();
      });
  }

  function changeGrant(currentRole, newRole, resourceId, resourceType) {
    const request = {
      operation: currentRole ? 'UPDATE' : 'ADD',
      roleId: newRole.id,
      resourceId: resourceId,
      resourceType: resourceType
    };
    const groupId = $scope.selectedGroup.id;
    $customerGroupsService.patchGrant($state.params.customerId, groupId, request)
      .then((grants) => {
        $scope.selectedGroup.grants = grants;
        _loadGrants();
      }, (err) => {
        if (err.status === 403) {
          $modalService.errorModal(err.data.message);
        } else {
          $modalService.errorModal();
        }
      });
  }

  function changeAccountGrant(newRole) {
    const resourceId = $rootScope.customer.id;
    changeGrant(self.accountRole, newRole, resourceId, 'CUSTOMER');
  }

  function removeAccountGrant() {
    const removeGrant = {
      operation: 'REMOVE',
      resourceId: $rootScope.customer.id,
      resourceType: 'CUSTOMER'
    };
    $customerGroupsService.patchGrant($state.params.customerId, $scope.selectedGroup.id, removeGrant)
      .then(() => self.accountRole = undefined);
  }
}]);
