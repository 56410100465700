'use strict';

angular.module('MoreAppsServices').factory('$adminUserService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const AdminUsers = $resource(moreConstants.apiEndpoint + '/admin/users/:userName', {userName: '@userName'}, {
    remove: {
      method: 'DELETE'
    }
  });
  const AdminUserMails = $resource(moreConstants.apiEndpoint + '/admin/users/:userName/mails', {}, {});
  const AdminUserSuppression = $resource(moreConstants.apiEndpoint + '/admin/users/:email/suppression', {}, {
    remove: {
      method: 'DELETE'
    }
  });

  return {
    removeUser: (userName) => AdminUsers.remove({userName}),
    getUser: (userName) => AdminUsers.get({userName}),
    getUserMails: (userName) => AdminUserMails.query({userName}),
    getSuppression: (email) => AdminUserSuppression.get({email}),
    removeSuppression: (email) => AdminUserSuppression.remove({email}),
  };
}]);
