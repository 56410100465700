'use strict';

angular.module('MoreAppsServices').factory('$widgetService', ['moreConstants', '$resource', '$q', function (moreConstants, $resource, $q) {

  var Widgets = $resource(moreConstants.apiEndpoint + '/customers/:customerId/widgets', {}, {});
  var WidgetFavorites = $resource(moreConstants.apiEndpoint + '/widgets/favorites/:favoriteId', {}, {});

  var resolvedWidgets = {};
  var widgetCache = null;
  var lastCustomerId = null;
  var widgetMap = {};

  var all = function (customerId) {
    if (widgetCache && (!customerId || lastCustomerId === customerId)) {
      return widgetCache;
    }
    lastCustomerId = customerId;

    widgetCache = Widgets.query({ customerId: customerId });
    widgetCache.$promise.then(function (widgets) {
      resolvedWidgets = {};
      widgets.forEach(function (widget) {
        widget.fullkey = asKey(widget.info.namespace, widget.info.key);
        resolvedWidgets[widget.fullkey] = widget;
      });
    });

    return widgetCache;
  };

  function byUid(widgetUid) {
    var deferred = $q.defer();
    widgetUid = sanitizeUid(widgetUid);
    if (widgetMap[widgetUid]) {
      deferred.resolve(widgetMap[widgetUid]);
    }
    all().$promise.then(function (data) {
      for (var i = 0; i < data.length; i++) {
        var widget = data[i];
        if (sanitizeUid(widget.info.uid) === widgetUid) {
          widgetMap[widgetUid] = widget;
          deferred.resolve(widget);
          return;
        }
      }
      deferred.reject();
    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  }

  var asKey = function (ns, name) {
    return ns + ':' + name;
  };

  function sanitizeUid(widgetUid) {
    var components = widgetUid.split(':');
    var namespace = components[0];
    var key = components[1];
    return asKey(namespace, key);
  }

  return {
    all,
    byUid,
    sanitizeUid,
    getWidgetWithSync: widgetKey => {
      var widgetSpecComponents = widgetKey.split(':');
      var namespace = widgetSpecComponents[0];
      var key = widgetSpecComponents[1];
      return resolvedWidgets[asKey(namespace, key)];
    },
    saveFavorite: favorite => WidgetFavorites.save(favorite),
    getFavorites: () => WidgetFavorites.query(),
    getFavorite: favoriteId => WidgetFavorites.get({favoriteId: favoriteId}),
    deleteFavoriteWidget: favoriteId => WidgetFavorites.remove({favoriteId: favoriteId}),
    enrichFieldsWithWidgetInfo: fields => {
      var deferred = $q.defer();
      var promises = [];
      fields.forEach(function (field) {
        var promise = byUid(field.widget);
        promises.push(promise);
        promise.then(function (widget) {
          field.icon = widget.definition.icon;
          field.data = widget.definition.data;
          if (widget.definition.data) {
            field.dataType = widget.definition.data.type;
          }
        });
      });
      $q.all(promises).then(deferred.resolve);
      return deferred.promise;
    }
  };
}]);
