'use strict';

angular.module('CustomerPortalSettings').controller('SubscriberDetailCtrl', ['$scope', '$customerWebhookService', '$modalService', 'subscriber', function ($scope, $customerWebhookService, $modalService, subscriber) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.subscriber = subscriber;
  self.eventTypes = $customerWebhookService.getEventTypes();
  self.columns = [
    { id: 'status', name: 'Status', customTemplate : require('../../templates/settings.webhooks.invocations.status.html') },
    { id: 'type', name: 'Type' },
    { id: 'invokedOn', name: 'Date'},
    { id: 'retryOn', name: 'Next attempt'}
  ];
  self.saving = false;
  self.showSecret = false;
  self.update = update;
  self.viewDetails = viewDetails;
  self.toggleStatus = toggleStatus;

  init();

  function init() {
    loadInvocations();
  }

  function loadInvocations() {
    self.invocations = $customerWebhookService.getInvocations(customerId, subscriber.id);
  }

  function update(updatedSubscriber, form) {
    self.saving = true;
    $customerWebhookService.updateSubscriber(customerId, updatedSubscriber.id, updatedSubscriber).$promise.then(() => {
      form.$setPristine();
      self.successMessage = 'SETTINGS_SUCCESS_MESSAGE';
    }, () => $modalService.errorModal()).finally(() => {
      self.saving = false;
    });
  }

  function viewDetails(invocation) {
    $modalService.open({
      template: require('../../templates/settings.webhooks.event.detail.html'),
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      controller: ['$scope', ($scope) => {
        $scope.requestBody = $customerWebhookService.getEvent(customerId, invocation.eventId);
        $scope.response = invocation.message;
      }]
    });
  }

  function toggleStatus(status) {
    if (status === 'ACTIVE') {
      self.subscriber.status = 'DISABLED';
    } else {
      self.subscriber.status = 'ACTIVE';
    }
  }
}]);
