'use strict';

angular.module('FormEditor').controller('SettingsTabCtrl', ['$scope', '$state', '$rootScope', '$uibModal', function ($scope, $state, $rootScope, $uibModal) {
  var self = this;

  self.selectIcon = function () {
    $uibModal.open({
      template: require('../../../modules/IconPicker/templates/icon-picker.modal.html'),
      controller: 'IconPickerModalCtrl',
      controllerAs: 'ctrl'
    }).result.then(function (newIcon) {
      $scope.targetView.settings.icon = newIcon;
    });
  };
}]);