'use strict';

angular.module('UserManagement').controller('GroupManagementUsersCtrl', ['$scope', '$rootScope', '$state', '$timeout', 'moreConstants', '$translate', '$customerGroupsService', '$customerUserService', '$sidePanel', '$modalService', '$customerInviteService', function ($scope, $rootScope, $state, $timeout, moreConstants, $translate, $customerGroupsService, $customerUserService, $sidePanel, $modalService, $customerInviteService) {
  const ALL_USERS_GROUP = 'all_users_group';

  const self = this;
  self.columns = [
    { id: 'username', name: $translate.instant('USER_MANAGEMENT_SETTINGS_USERNAME'), customTemplate: require('../templates/_username.html')},
    { id: 'settings.firstName', name: $translate.instant('USER_MANAGEMENT_SETTINGS_FIRST_NAME')},
    { id: 'settings.lastName', name: $translate.instant('USER_MANAGEMENT_SETTINGS_LAST_NAME')},
    { id: 'isInvite', name: '', searchable: false, customTemplate: require('../templates/_invite.resend.html')},
  ];
  const customerId = $state.params.customerId;
  self.loading = false;
  self.getFilteredUsers = getFilteredUsers;
  self.viewUserOrInvite = viewUserOrInvite;
  self.addUser = addUser;
  self.addExistingUser = addExistingUser;
  self.removeUserOrInvite = removeUserOrInvite;
  self.toggleSearch = toggleSearch;
  self.allowSpecificObjectDeletion = (user) => $scope.selectedGroup.id === ALL_USERS_GROUP ? !user.externallyManaged : !$scope.selectedGroup.externallyManaged;

  init();

  function init() {
    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', () => {
      $timeout(() => $scope.$destroy(), 0);
    });
    $scope.$watch('selectedGroup', _reloadUsersAndInvites);
  }

  function toggleSearch() {
    self.searchEnabled = !self.searchEnabled;
    if (!self.searchEnabled) {
      $rootScope.$broadcast('paginatedmoregrid.clear_search');
    }
  }

  function isInvitedUser (userOrInvite) {
    // It's a hack, but invites don't have usernames...
    return userOrInvite.username === null;
  }

  function getFilteredUsers (filter) {
    const username = filter.query.username ? filter.query.username.value : undefined;
    const firstName = filter.query['settings.firstName'] ? filter.query['settings.firstName'].value : undefined;
    const lastName = filter.query['settings.lastName'] ? filter.query['settings.lastName'].value : undefined;
    const groupId = $scope.selectedGroup && $scope.selectedGroup.id !== ALL_USERS_GROUP ? $scope.selectedGroup.id : undefined;

    return $customerUserService.findUsers(customerId, groupId, filter, firstName, lastName, username).$promise.then(pagedUsers => ({
      listedObjects: pagedUsers.content.map(u => {
        return isInvitedUser(u) ? {...u, username: u.emailAddress, isInvite: true} : u;
      }),
      totalItems: pagedUsers.totalElements
    }));
  }

  function addExistingUser() {
    $modalService.open({
      template: require('../templates/modals/groupmanagement.user.add.html'),
      resolve: {
        groupId: () => $scope.selectedGroup.id
      },
      controller: 'AddUserToGroupModalCtrl as ctrl'
    }).then(_reloadUsersAndInvites);
  }

  function addUser () {
    $sidePanel.open({
      template: require('../../UserManagement/templates/usermanagement.user.new.html'),
      resolve: {
        groups: () => $customerGroupsService.getGroups(customerId),
        selectedGroupIds: () => $scope.selectedGroup.id !== ALL_USERS_GROUP ? [$scope.selectedGroup.id] : []
      },
      controller: 'AddUserPanelCtrl as ctrl'
    }).then(_reloadUsersAndInvites);
  }

  function viewUserOrInvite (selectedUser) {
    if (selectedUser.isInvite) {
      $sidePanel.open({
        template: require('../templates/usermanagement.permissions.html'),
        windowClass: 'sidepanel-medium',
        resolve: {
          patchGrantMethod: () => $customerInviteService.patchGrant,
          transferOwnershipMethod: () => () => $modalService.errorModal('INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE'),
          user: () => selectedUser,
          userId: () => selectedUser.id,
          grants: () => $customerInviteService.getGrants(customerId, selectedUser.id),
          currentCustomer: () => $rootScope.customer
        },
        controller: 'UserManagementPermissionsCtrl'
      });
    } else {
      $sidePanel.open({ // User
        template: require('../templates/usermanagement.permissions.html'),
        windowClass: 'sidepanel-medium',
        resolve: {
          patchGrantMethod: () => $customerUserService.patchGrant,
          transferOwnershipMethod: () => $customerUserService.transferOwnership,
          user: () => $customerUserService.getUser(customerId, selectedUser.id).$promise,
          userId: () => selectedUser.id,
          grants: () => $customerUserService.getGrants(customerId, selectedUser.id),
          currentCustomer: () => $rootScope.customer
        },
        controller: 'UserManagementPermissionsCtrl'
      });
    }
  }

  function removeUserOrInvite(user) {
    if ($scope.selectedGroup.id === ALL_USERS_GROUP) {
      if (user.isInvite) {
        removeInvite(user);
      } else {
        removeUser(user);
      }
    } else {
      if (user.isInvite) {
        removeInviteFromGroup(user);
      } else {
        removeUserFromGroup(user);
      }
    }
  }

  function removeInviteFromGroup (invite) {
    $modalService.deleteModal({
      title: 'USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE',
      template: require('../templates/usermanagement.invite.remove-from-group.html'),
      data: {
        username: invite.username
      }
    }).then(result => {
      if (!result) {
        return;
      }
      $customerInviteService.removeGroup($state.params.customerId, $scope.selectedGroup.id, invite.id).then(
        () => _reloadUsersAndInvites(),
        () => $modalService.errorModal()
      );
    });
  }

  function removeInvite (invite) {
    $modalService.deleteModal({
      title: 'USER_MANAGEMENT_REMOVE_INVITE_TITLE',
      template: require('../templates/usermanagement.invite.remove.html'),
      data: {
        username: invite.username
      }
    }).then(result => {
      if (!result) {
        return;
      }
      $customerInviteService.removeInvite($state.params.customerId, invite.id).$promise.then(
        () => _reloadUsersAndInvites(),
        () => $modalService.errorModal()
      );
    });
  }

  function removeUser (user) {
    if (user.username === $scope.currentUser.consumerKey && !$scope.currentUser.roles.ADMIN) {
      return $modalService.alertModal({
        title: 'USER_MANAGEMENT_REMOVE_YOURSELF_TITLE',
        message: 'USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE'
      });
    }
    $modalService.deleteModal({
      title: 'USER_MANAGEMENT_REMOVE_TITLE',
      template: require('../templates/usermanagement.user.remove.html'),
      data: {
        username: user.username
      }
    }).then(result => {
      if (!result) {
        return;
      }
      $customerUserService.removeUser($state.params.customerId, user.id).$promise.then(
        () => _reloadUsersAndInvites(),
        () => $modalService.errorModal()
      );
    });
  }

  function removeUserFromGroup (user) {
    $modalService.deleteModal({
      title: 'USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE',
      template: require('../templates/usermanagement.user.remove-from-group.html'),
      data: {
        username: user.username
      }
    }).then(result => {
      if (!result) {
        return;
      }
      $customerGroupsService.removeUser($state.params.customerId, $scope.selectedGroup.id, user.id).then(
        () => _reloadUsersAndInvites(),
        () => $modalService.errorModal()
      );
    });
  }

  function _reloadUsersAndInvites () {
    $scope.$broadcast('paginatedmoregrid.reload');
  }
}]);
