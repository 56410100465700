'use strict';

angular.module('MoreMenu', []);

angular.module('MoreMenu').directive('moreMenu', function () {

  return {
    restrict: 'AE',
    template: require('../shared/templates/menu.html'),
    scope: true,
    link: function (scope) {
      var HEIGHT_SUB_MENU_ITEM = -38;

      scope.navigateToItem = function (item) {
        var newParams = angular.copy(scope.$state.params);
        if (item.requiredParams) {
          item.requiredParams.forEach(function (requiredParam) {
            if (newParams[requiredParam] !== null && newParams[requiredParam] !== undefined) {
              return;
            }

            newParams[requiredParam] = scope.supplementaryParams[requiredParam]();
          });
        }
        if (item.click) {
          item.click(newParams);
        } else {
          scope.$state.go(item.route, newParams);
        }
      };

      scope.subMenuOffSet = function (items, item) {
        var index = items.indexOf(item);
        return items.length - index >= item.subs.length ? 0 : (item.subs.length - (items.length - index) - 1) * -46;
      };

      scope.subMenuOffsetArray = function (obj) {
        var length = Object.keys(obj).length;
        return length === 0 ? 0 : (length - 1) * HEIGHT_SUB_MENU_ITEM;
      };
    }
  };
});