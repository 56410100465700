'use strict';

angular.module('MoreDirectives').directive('infoIcon', ['$sce', '$translate', function ($sce, $translate) {
  return {
    restrict: 'E',
    template: '<span uib-tooltip-html="htmlTooltip" tooltip-append-to-body="true" tooltip-placement="{{ ::placement || \'left\'}}" tooltip-trigger="mouseenter" class="pull-right clickable info-icon">' + '<i style="color: gray;font-size: 14px;" class="fa fa-info-circle"></i>' + '</span>',
    replace: true,
    scope: {
      'message': '@message',
      'placement': '@placement'
    },
    link: function (scope) {
      var tooltipMessage = $translate.instant(scope.message);
      scope.htmlTooltip = $sce.trustAsHtml(tooltipMessage);
    }
  };
}]);