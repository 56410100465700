'use strict';

angular.module('WidgetPropertyPane').service('$widgetPropertyPaneService', ['$sidePanel', function ($sidePanel) {

  function open(options) {
    //Add options as resolve callback
    var resolve = {};
    var resolveProperties = ['focusedWidget', 'focusedFormField', 'targetView', 'formVersion', 'applicationDefinition', 'knownFieldUids', 'deployed', 'callbacks', 'disableFavorites'];
    resolveProperties.forEach(function (key) {
      resolve[key] = function () {
        return options[key];
      };
    });

    return $sidePanel.open({
      template: require('../templates/properties-panel.html'),
      resolve: resolve,
      controller: 'WidgetPropertyPaneCtrl as ctrl'
    });
  }

  return {
    open
  };
}]);
