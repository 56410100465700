'use strict';
angular.module('more.ui.form').factory('$formDataHelper', function ($fileSystemService) {

  var optimizerService = 'https://image-optimization.moreapp.cloud?url=';

  return function (data, fields, collection) {
    var listeners = {
      global : []
    };
    var subFormMapping = {};

    init();

    return {
      widget : {
        getValue : widgetGetValue,
        addListener : widgetAddListener,
        removeListener : widgetRemoveListener,
        getAvailableImages : getAvailableImages
      },
      changeValue : changeValue
    };

    function getAvailableImages () {
      var availableImages = [];
      for (var i = 0; i < fields.length; i++) {
        var widgetValue = widgetGetValue(fields[i].uid);
        if (typeof widgetValue === 'object' && widgetValue !== null) {
          Object.keys(widgetValue).forEach(function (key) {
            if (widgetValue[key] !== null && widgetValue[key].type === 'images') {
              var urls = widgetValue[key].value;
              urls.forEach(function (current, index, arr) {
                arr[index].url = optimizerService + encodeURIComponent(current.url);
              });
              availableImages = availableImages.concat(urls);
            }
          });
        }
      }
      return availableImages;
    }

    function changeValue (field, newValueInput, callback) {
      var dataName = field.properties.data_name;
      var newValue = (newValueInput === undefined ? null : newValueInput);
      var oldValue = data[dataName];
      data[dataName] = angular.copy(newValueInput);

      if (oldValue !== data[dataName] && callback) {
        callback();
      }

      var widgetCallbacks = listeners[field.uid] || [];
      widgetCallbacks.forEach(function (cb) {
        cb(newValue, oldValue);
      });
      listeners.global.forEach(function (cb) {
        cb(newValue, oldValue);
      });

      removeOldFile(oldValue);
      handleSubData(field, newValue);
    }

    function widgetGetValue (fieldUid) {
      if (!fieldUid) {
        return undefined;
      }

      // Option 1: simple field
      var fieldValue = getFieldValue(fieldUid);
      if (fieldValue !== undefined) {
        return fieldValue;
      }

      // Option 2: sub-key of a field, like: <search-field-id>.columnName
      if (fieldUid.indexOf('.') !== -1) {
        var fieldUidSplit = fieldUid.split('.');
        var id = fieldUidSplit[0];
        var column = fieldUidSplit[1];
        fieldValue = getFieldValue(id);
        if (fieldValue && fieldValue[column] !== undefined) {
          return fieldValue[column];
        }
      }

      // Option 3: Try to get data from subform
      var result;
      Object.keys(subFormMapping).forEach(function (formUid) {
        var subFormInfo = subFormMapping[formUid];

        var subFormField = subFormInfo.subFormFieldRefs.filter(function (item) {
          return item.uid === fieldUid;
        })[0];
        if (subFormField) {
          var arrayData = data[subFormInfo.field.properties.data_name];
          result = arrayData.map(function (entry) {
            return entry[subFormField.dataName];
          });
        }
      });
      return result;
    }

    function getFieldValue(fieldUid) {
      var field = getField(fieldUid);
      if (field) {
        var dataName = field.properties.data_name;
        return angular.copy(data[dataName]);
      }
    }

    function widgetAddListener(fieldUid, callback) {
      listeners[fieldUid] = (listeners[fieldUid] || []);
      listeners[fieldUid].push(callback);
    }

    function widgetRemoveListener (fieldUid, callback) {
      var list = listeners[fieldUid] || [];
      var index = list.indexOf(callback);
      list.splice(index, 1);
    }

    function getField (fieldUid) {
      return fields.filter(function (f) {
        return f.uid === fieldUid;
      })[0];
    }


    function handleSubData (field, newValue) {
      if (!subFormMapping[field.uid]) {
        return;
      }

      var subFormInfo = subFormMapping[field.uid];
      subFormInfo.subFormFieldRefs.forEach(function (fieldRef) {
        processSubDataFieldRef(fieldRef, newValue);
      });
    }

    function processSubDataFieldRef (fieldRef, newValue) {
      var fieldListeners = listeners[fieldRef.uid];
      if (!fieldListeners) {
        return;
      }

      var newArray = newValue.map(function (entry) {
        return entry[fieldRef.dataName];
      });

      fieldListeners.forEach(function (cb) {
        cb(newArray);
      });
    }

    function init () {
      setupSubFormMapping(subFormMapping, collection, fields);
      if (collection && collection.fieldProperties) {
        setupPinMapping(fields, collection);
      }
    }
  };

  ////////
  function setupSubFormMapping (subFormMapping, collection, fields) {
    fields.forEach(function (field) {
      var widget = field.$$widget;
      if (!widget || !widget.definition || !widget.definition.data) {
        return;
      }
      var widgetData = widget.definition.data;
      if (widgetData.type !== 'detail') {
        return;
      }

      let subForm;
      if (collection && collection.fieldProperties && field.properties[widgetData.property])  {
        subForm = collection.fieldProperties[field.properties[widgetData.property]];
        field.properties.form = subForm;
      } else if (field.properties.form) {
        subForm = field.properties.form;
      } else if (collection && !collection.formId) {
        const property = widgetData.property;
        const formRef = field.properties[property];

        subForm = collection.views.filter(function (view) {
          return view.uid === formRef;
        })[0];
      }

      if (!subForm || !subForm.fields) {
        return;
      }

      var subFormFieldRefs = [];
      subForm.fields.forEach(function (subFormField) {
        if (subFormField.properties.data_name) {
          subFormFieldRefs.push({
            uid : subFormField.uid,
            dataName : subFormField.properties.data_name
          });
        }
      });

      subFormMapping[field.uid] = subFormMapping[field.uid] || {field : field, subFormFieldRefs : subFormFieldRefs};
    });
  }

  function setupPinMapping (fields, formVersion) {
    fields.forEach(function (field) {
      var widget = field.$$widget;
      if (!widget || !widget.definition || !widget.definition.data) {
        return;
      }
      var widgetData = widget.definition.data;
      if (widgetData.type !== 'pins') {
        return;
      }

      field.properties.pins.forEach(pin => {
        if (pin.target_form_id) {
          pin.form = formVersion.fieldProperties[pin.target_form_id];
        }
      });
    });
  }

  function removeOldFile (oldValue) {
    if (window.moreAppType === 'device' && oldValue !== null && typeof oldValue === 'object' && oldValue._type === 'file') {
      $fileSystemService.getFullRegistrationFilePath(oldValue.value).then(function (fullPath) {
        $fileSystemService.deleteByFullPath(fullPath);
      });
    }
  }
});
