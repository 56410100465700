'use strict';

angular.module('MoreDirectives').directive('collapsablePanel', function () {
  return {
    restrict: 'E',
    scope: {
      title: '@',
      subtitle: '@'

    },
    transclude: true,
    template: require('../../../shared/templates/directives/collapsable-panel.html')
  };
});