'use strict';

angular.module('more.ui.form-builder').factory('$userInfoService', function () {
  // no-op
  return {
    getUserInfo: function () {
      return {
        name: 'demo_builder'
      };
    }
  };
});