'use strict';

angular.module('Billing').controller('BillingIndexCtrl', function () {
  var self = this;

  self.menuItems = [
    {name: 'BILLING_OVERVIEW_MENU_ITEM', state: 'overview'},
    {name: 'BILLING_INVOICES_MENU_ITEM', state: 'invoices'},
    {name: 'BILLING_USAGE_MENU_ITEM', state: 'usage'}
  ];
});