'use strict';

angular.module('more.ui.form-builder').directive('clearButton', function () {
  return {
    restrict: 'E',
    replace: true,
    template: '<a class="clear-button" data-translate="CLEAR" data-ng-if="::!field.properties.required" data-ng-show="initialValue !== null" style="cursor: pointer; float: right"></a>',
    scope: {
      ngClick: '&'
    }
  };
});