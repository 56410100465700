'use strict';

angular.module('FormEditor').controller('FormSearchSettingsCtrl', ['$scope', '$rootScope', '$formVersionUtilityService', '$widgetService', '$featureHelper', '$timeout', '$modalService', 'moreConstants', function ($scope, $rootScope, $formVersionUtilityService, $widgetService, $featureHelper, $timeout, $modalService, moreConstants) {

  var self = this;

  var init = function () {
    self.targetFields = [];
    self.expandedFields = [];

    $featureHelper.hasFeature(moreConstants.SEARCH).then(hasFeature => {
      self.hasSearchFeature = hasFeature;
    });

    $rootScope.$on('more-form.field-removed', function (newValue) {
      if (newValue) {
        self.targetFields = angular.copy($scope.targetView.fields);
        initSearchSettings();
      }
    });
    self.targetFields = angular.copy($scope.targetView.fields);
    initSearchSettings();

    $widgetService.enrichFieldsWithWidgetInfo(self.targetFields);

    $widgetService.enrichFieldsWithWidgetInfo(self.expandedFields);
  };

  self.getNumber = num => new Array(num);

  var initSearchSettings = function () {
    if (!$scope.targetView.settings.searchSettings) {
      $scope.targetView.settings.searchSettings = {
        enabled: false,
        fields: null,
        filteringEnabled: false,
        filteredFields: []
      };
    }
    self.searchSettings = $scope.targetView.settings.searchSettings;

    self.fields = [];
    for (var uid in self.searchSettings.fields) {
      if (self.searchSettings.fields.hasOwnProperty(uid)) {
        var field = getFieldForUid(uid);
        if (field) {
          self.fields.push({
            widget: field.widget,
            displayName: self.getFieldDisplayName(field),
            uid: field.uid
          });
        }
      }
    }

    $widgetService.enrichFieldsWithWidgetInfo(self.fields);

    self.expandedFields = $formVersionUtilityService.findAvailableFieldsRecursively($scope.targetView);
    $widgetService.enrichFieldsWithWidgetInfo(self.expandedFields);
  };

  self.filterAvailableFields = function (filterTypes) {
    filterTypes = filterTypes || ['com.moreapps:pin', 'com.moreapps:detail', 'com.moreapps:signature', 'com.moreapps:photo'];
    return function (field) {
      // TODO MORE-2741, Check the widget to see if you can filter on the content of this widget and which property to use
      return filterTypes.indexOf($widgetService.sanitizeUid(field.widget)) === -1 && field.data;
    };
  };

  var getFieldForUid = function (uid) {
    var fields = self.targetFields.filter(function (field) {
      return field.uid === uid;
    });
    if (fields.length > 0) {
      return fields[0];
    }
  };

  self.getFieldDisplayName = $formVersionUtilityService.getFieldDisplayName;

  self.selectField = function (field, target) {
    if (field.uid) {
      self.searchSettings.fields[field.uid] = false;
    }

    if (!self.searchSettings.fields) {
      self.searchSettings.fields = {};
    }
    field.widget = target.widget;
    field.icon = target.icon;
    field.displayName = self.getFieldDisplayName(target);
    field.uid = target.uid;
    self.searchSettings.fields[field.uid] = true;
  };
  self.addField = function () {
    if (self.fields.length === 0 || self.fields[self.fields.length - 1].uid) {
      self.fields.push({});
    }
  };
  self.removeField = function (field) {
    var index = self.fields.indexOf(field);
    if (index > -1) {
      self.fields.splice(index, 1);
    }
    if (self.searchSettings.fields) {
      delete self.searchSettings.fields[field.uid];
    }
  };

  self.allFieldsSelected = function () {
    return self.targetFields.filter(function (field) {
      return !self.searchSettings.fields || !self.searchSettings.fields[field.uid];
    }).length === 0;
  };

  self.toggleFilteredField = function (uid) {
    if (!self.searchSettings.filteredFields) {
      self.searchSettings.filteredFields = [];
    }
    if (self.searchSettings.filteredFields.indexOf(uid) > -1) {
      self.searchSettings.filteredFields.splice(self.searchSettings.filteredFields.indexOf(uid), 1);
    } else {
      self.searchSettings.filteredFields.push(uid);
    }
  };

  self.enableSearch = function ($event) {
    $event.preventDefault();
    if (!self.hasSearchFeature) {
      return $modalService.upgradePlanModal({
        title: 'FEATURE_SEARCH',
        message: 'FEATURE_SEARCH_MESSAGE',
        imgSrc: 'https://cdn.moreapp.com/features/en/feature-search.png'
      });
    }
    $timeout(() => {
      $scope.targetView.settings.searchSettings.enabled = !$scope.targetView.settings.searchSettings.enabled;
    });
  };

  init();
}]);
