'use strict';

angular.module('MoreAppsServices').factory('$customerSsoService', ['moreConstants', '$resource', function (moreConstants, $resource) {

    const Organization = $resource(moreConstants.apiEndpoint + '/customers/:customerId/sso/:action', {customerId: '@customerId'}, {
        provision: {method: 'POST', params: {action: 'provision'}},
        status: {method: 'GET', params: {action: 'status'}},
    });

    const PortalUrl = $resource(moreConstants.apiEndpoint + '/customers/:customerId/sso/admin-portal/:action', {customerId: '@customerId'}, {
        ssoPortal: {method: 'GET', params: {action: 'sso'}},
        dsyncPortal: {method: 'GET', params: {action: 'dsync'}},
    });

    return {
        getStatus: customerId => Organization.status({customerId}),
        provision: customerId => Organization.provision({customerId}),
        getSsoPortalUrl: (customerId, returnUrl) => PortalUrl.ssoPortal({customerId, returnUrl}),
        getDsyncPortalUrl: (customerId, returnUrl) => PortalUrl.dsyncPortal({customerId, returnUrl}),
    };
}]);
