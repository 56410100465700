'use strict';

angular.module('DataSourcePicker').directive('datasourcePicker', ['$state', '$customerDataSourceService', '$userStateService', '$rootScope', function ($state, $customerDataSourceService, $userStateService, $rootScope) {

  return {
    restrict: 'E',
    template: require('../templates/datasource-picker.html'),
    scope: {
      selectDataSourceCallback: '&',
      addDataSourceCallback: '&'
    },
    link: function (scope) {
      scope.activeDataSource = null;

      var userState = $userStateService.getState();

      var loadDataSources = function (customerId) {
        customerId = customerId || $state.params.customerId;
        $customerDataSourceService.getDataSources(customerId).$promise.then(function (dataSources) {
          scope.dataSources = dataSources;
          dataSources.forEach(function (dataSource) {
            if (dataSource.id === userState.dataSourceId) {
              scope.selectDataSource(dataSource);
            }
          });
        });
      };

      loadDataSources();

      scope.selectDataSource = function (dataSource) {
        scope.activeDataSource = dataSource;
        scope.selectDataSourceCallback({ dataSource: dataSource });
        $userStateService.setDataSourceId(dataSource.id);
      };

      scope.$on('dataSource.added', function (event, newDataSource) {
        scope.dataSources.push(newDataSource);
        scope.selectDataSource(newDataSource);
      });

      scope.$on('dataSource.deleted', function (event, deletedDataSource) {
        var index = scope.dataSources.indexOf(deletedDataSource);
        scope.dataSources.splice(index, 1);
        scope.activeDataSource = null;
        if ($userStateService.getState().dataSourceId === deletedDataSource.id) {
          $userStateService.setDataSourceId(null);
        }
      });

      scope.$watch('activeDataSource', function (newVal) {
        $rootScope.pickerItemActive = newVal !== null;
      });

      scope.$on('$destroy', function () {
        $rootScope.pickerItemActive = false;
      });
    }
  };
}]);