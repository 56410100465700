'use strict';

angular.module('MoreResource').controller('MoreResourceModalCtrl', ['$scope', '$moreResourceService', '$modalService', '$imageUtils', function ($scope, $moreResourceService, $modalService, $imageUtils) {
  const MAX_SIZE = 1024 * 1024 * 5;

  const customerId = $scope.$state.params.customerId;

  $scope.selectedFile = null;
  $scope.fileTooLarge = false;
  $scope.uploadType = 'file';
  $scope.page = 0;
  $scope.pageSize = 20;
  $scope.totalElements = undefined;

  init();

  function init () {
    loadPage();
  }

  function loadPage() {
    $moreResourceService.find(customerId, $scope.page, $scope.pageSize).$promise.then(result => {
      $scope.moreResources = result.content;
      $scope.totalElements = result.totalElements;
    });
  }

  $scope.changePage = (page, pageSize) => {
    $scope.page = page;
    $scope.pageSize = pageSize;
    loadPage();
  };

  $scope.uploadResourceFile = function ($files, $rejectedFiles) {
    $scope.invalidFileMessage = null;
    $scope.fileTooLarge = false;
    $scope.uploading = true;

    if ($files.length === 0) {
      if ($rejectedFiles.length > 0) {
        $scope.invalidFileMessage = 'RESOURCES_UPLOAD_INVALID_FILE';
      }
      $scope.uploading = false;
      return;
    }

    $files.forEach(function (file) {
      if (file.size > MAX_SIZE) {
        $scope.fileTooLarge = true;
      }
    });

    if ($scope.fileTooLarge) {
      $scope.uploading = false;
      return;
    }

    var options = {
      name: 'Some name',
      shared: false,
      importFormat: 'PNG'
    };
    $moreResourceService.saveFileResource(customerId, $files[0], options).then(function (moreResource) {
      $scope.uploading = false;
      $scope.uploadError = null;
      $scope.selectImage(moreResource);
    }, function () {
      $scope.uploading = false;
      $scope.uploadError = 'RESOURCES_UPLOAD_FAILURE';
    });
  };

  $scope.uploadResourceUrl = function (url, name) {
    $scope.uploadError = null;
    $scope.uploading = true;

    var errorCallback = function () {
      $scope.uploading = false;
      $scope.uploadError = 'RESOURCES_UPLOAD_FAILURE';
    };

    $imageUtils.isValidImage(url).then(function () {
      $moreResourceService.saveUrlResource(customerId, { name: name, url: url }).$promise.then(function (moreResource) {
        $scope.uploading = false;
        $scope.uploadError = null;
        $scope.selectImage(moreResource);
      }, errorCallback);
    }, errorCallback);
  };

  $scope.selectImage = function (moreResource) {
    $scope.$close(moreResource);
  };

  $scope.deleteResource = function (moreResource) {
    $modalService.deleteModal({
      title: 'CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE',
      template: require('../templates/resource.remove.html'),
      data: {
        template: moreResource
      }
    }).then(function (result) {
      if (!result) {
        return;
      }
      $moreResourceService.deleteResource(customerId, moreResource.id).$promise.then(() => {
        const indexOfResource = $scope.moreResources.findIndex(x => x.id === moreResource.id);
        $scope.moreResources.splice(indexOfResource, 1);
        $scope.totalElements--;
      });
    });
  };
}]);
