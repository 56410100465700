'use strict';

angular.module('DataSources').directive('datasourceEntryTable', ['$state', '$customerDataSourceService', function ($state, $customerDataSourceService) {
  return {
    restrict: 'E',
    template: require('../../../shared/templates/directives/data-source-entry-table.html'),
    scope: {
      selectedEntry: '=',
      formField: '=',
      mock: '='
    },
    link: function (scope) {
      scope.$watch('formField.properties.data_source_configuration', function (newConfiguration) {
        if (newConfiguration && newConfiguration.id) {
          var dataSourceId = newConfiguration.id;
          scope.dataSource = $customerDataSourceService.getDataSource($state.params.customerId, dataSourceId);
          scope.dataSourceConfig = newConfiguration;
        }
      });
    }
  };
}]);