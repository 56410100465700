'use strict';

angular.module('FormEditor').controller('AddSubFormCtrl', ['$scope', function ($scope) {

  $scope.addSubFormForm = {
    subFormName: null
  };

  $scope.submitSubForm = function () {
    $scope.$close({ name: $scope.addSubFormForm.subFormName });
  };
}]);