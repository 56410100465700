'use strict';

angular.module('MoreDirectives').directive('arrayCarousel', function () {
  return {
    link: function (scope, el) {
      scope.$watch('viewStack', function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) {
          return;
        }
        $(el).css({ 'margin-left': (oldValue.length - 1) * -100 + '%' });
        $(el).css({ 'width': newValue.length * -100 + '%' });
        $(el).css({
          'margin-left': (newValue.length - 1) * -100 + '%',
          'width': newValue.length * 100 + '%',
          WebkitTransition: 'all 300ms',
          MozTransition: 'all 300ms',
          MsTransition: 'all 300ms',
          OTransition: 'all 300ms',
          transition: 'all 300ms'
        });
      }, true);
    }
  };
});