module.exports = {
  title : 'ADD_APPLICATION_PANEL_TITLE',
  titleTooltip : 'TOOLTIP_ADD_APPLICATION',
  elements : [{
    type : 'text',
    label : 'ADD_APPLICATION_PANEL_TITLE_LABEL',
    tooltip : 'TOOLTIP_ADD_APPLICATION_NAME',
    required : true,
    property : 'meta.name'
  }]
};