'use strict';

angular.module('UserManagement').controller('GroupManagementIndexCtrl', ['$scope', '$state', '$customerUserService', function ($scope, $state, $customerUserService) {

  init();

  function init() {
    $scope.$on('group.changed', (event, group) => {
      $scope.owner = $customerUserService.getAccountOwner($state.params.customerId);
      $scope.selectedGroup = group;
      if (group.id === 'all_users_group') {
        $scope.menuItems = [{name: 'USERS', state: 'users'}];
        $state.go('^.users');
      } else {
        $scope.menuItems = [
          {name: 'USERS', state: 'users'},
          {name: 'PERMISSIONS', state: 'permissions'}, // manage grants on groups
        ];
      }
    });
  }
}]);
