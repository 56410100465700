'use strict';

angular.module('WidgetPropertyPane').directive('listedProperty', ['$formVersionUtils', function ($formVersionUtils) {
  return {
    restrict: 'A',
    scope: true,
    link: function (scope) {

      scope.removeItem = removeItem;
      scope.addItem = addItem;

      function addItem () {
        if (!scope.formFieldProperties[scope.widgetProperty.key]) {
          scope.formFieldProperties[scope.widgetProperty.key] = [];
        }

        var newItem = $formVersionUtils.transformPropertiesToObject(scope.widgetProperty.configuration.itemProperties);
        if (scope.widgetProperty.defaultValues) {
          var defaultValues = scope.widgetProperty.defaultValues.filter(function (defaultObject) {
            return !_valuesMatchesItem(defaultObject);
          })[0];
          if (defaultValues) {
            Object.keys(defaultValues).forEach(function (defaultKey) {
              if (newItem[defaultKey] === null || newItem[defaultKey] === undefined) {
                newItem[defaultKey] = defaultValues[defaultKey];
              }
            });
          }
        }
        scope.formFieldProperties[scope.widgetProperty.key].push(newItem);
      }

      function removeItem (option) {
        var index = scope.formFieldProperties[scope.widgetProperty.key].indexOf(option);
        if (index > -1) {
          scope.formFieldProperties[scope.widgetProperty.key].splice(index, 1);
        }
      }

      ////////
      function _valuesMatchesItem (defaultObject) {
        var matches = false;
        scope.formFieldProperties[scope.widgetProperty.key].forEach(function (item) {
          Object.keys(defaultObject).forEach(function (defaultKey) {
            var defaultValue = defaultObject[defaultKey];
            if (defaultValue !== null && defaultValue !== undefined && angular.equals(item[defaultKey], defaultValue)) {
              matches = true;
            }
          });
        });
        return matches;
      }
    }
  };
}]);
