'use strict';

angular.module('UserManagement').controller('UserManagementPermissionsCtrl', ['$scope', '$state', '$timeout', '$translate', '$customerFolderService', '$customerRolesService', 'patchGrantMethod', 'transferOwnershipMethod', 'user', 'userId', 'grants', 'currentCustomer', '$modalService', 'moreConstants', '$customerGroupsService', '$customerInviteService', '$customerUserService', '$usersContextService', function ($scope, $state, $timeout, $translate, $customerFolderService, $customerRolesService, patchGrantMethod, transferOwnershipMethod, user, userId, grants, currentCustomer, $modalService, moreConstants, $customerGroupsService, $customerInviteService, $customerUserService, $usersContextService) {
  const self = this;
  const customerId = $state.params.customerId;
  let ROLES = [];

  self.currentCustomer = currentCustomer;
  $scope.folders = [];
  $scope.accountRoles = [];
  $scope.folderRoles = [];
  $scope.formRoles = [];
  $scope.user = user;
  $scope.grants = grants;
  $scope.userGroups = [];
  $scope.changeGrant = changeGrant;
  $scope.removeGrant = removeGrant;
  $scope.changeAccountGrant = changeAccountGrant;
  $scope.getRoleInfo = $customerRolesService.getRoleInfo;
  $scope.getGrantsForGroups = getGrantsForGroups;
  $scope.updateUserSettings = updateUserSettings;
  $scope.getViaGroup = getViaGroup;

  init();

  function init() {
    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', () => {
      $timeout(() => $scope.$destroy(), 0);
    });
    _loadPermissions();
  }

  function _loadPermissions() {
    _loadFolders()
      .then(_loadRoles)
      .then(() => {
        _loadGrants({expandFolders: true});
        _loadUserGroups();
      });
  }

  function _loadFolders() {
    return $customerFolderService.getFolders(customerId).$promise.then(res => {
      $scope.folders = res;
    });
  }

  function _loadGrants(options) {
    const expandFolders = options && options.expandFolders;
    if (!$scope.grants) {
      return;
    }
    const accountGrant = $scope.grants.find(grant => grant.resourceType === 'CUSTOMER' && grant.customerId === parseInt(customerId));
    if (accountGrant) {
      $scope.accountRole = getRoleById(accountGrant.roleId);
    }

    $scope.folderGrants = new Map(
        $scope.grants
        .filter(grant => grant.resourceType === 'FOLDER')
        .map(grant => [grant.resourceId, getRoleById(grant.roleId)])
    );

    $scope.formGrants = new Map(
        $scope.grants
        .filter(grant => grant.resourceType === 'FORM')
        .map(grant => [grant.resourceId, getRoleById(grant.roleId)])
    );

    // automatically open folders with at least 1 form permission set
    if (expandFolders) {
      $scope.folders.forEach((folder) => {
        const hasFormPermission = folder.forms.filter(form => form.status !== 'TRASH').some(form => $scope.formGrants.get(form.id));
        if (hasFormPermission) {
          folder.$isOpen = true;
        }
      });
    }
  }

  function _loadUserGroups() {
    $customerGroupsService.getGroups(customerId).then(groups => {
      $scope.userGroups = groups.filter(g => user.groups && user.groups.indexOf(g.id) !== -1);

      let output = {};
      $scope.userGroups
        .flatMap(group => group.grants.map(grant => {
          const role = getRoleById(grant.roleId);
          return {
            id: grant.resourceId,
            group: group.name,
            role: role.translatableKey ? $translate.instant(role.translatableKey) : role.name
          };
        }))
        .forEach((item) => {
          if (!output[item.id]) {
            output[item.id] = [];
          }
          output[item.id].push(item);
        });
      $scope.grantsByResource = output;

      // automatically open folders with at least 1 form permission set
      $scope.folders.forEach((folder) => {
        const hasFormPermission = folder.forms
          .filter(form => form.status !== 'TRASH')
          .some(form => $scope.grantsByResource[form.id]);
        if (hasFormPermission) {
          folder.$isOpen = true;
        }
      });
    });
  }

  function getRoleById (id) {
    return ROLES.find(role => role.id === id);
  }

  function _loadRoles() {
    return $customerRolesService.getRoles(customerId).then(roles => {
      // Filter all roles that don't do anything with groups
      ROLES = roles;

      $scope.formRoles = roles
        .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_FORM_PERMISSIONS.find(p => p === permission)));

      $scope.folderRoles = roles
        .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_FOLDER_PERMISSIONS.find(p => p === permission)));

      $scope.accountSystemRoles = roles
        .filter(role => role.readOnly)
        .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_ACCOUNT_PERMISSIONS.find(p => p === permission)));

      $scope.accountCustomRoles = roles
          .filter(role => !role.readOnly)
          .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_ACCOUNT_PERMISSIONS.find(p => p === permission)));

    });
  }

  function updateUserSettings(user) {
    if (user.isInvite) {
      $customerInviteService.updateInviteSettings($state.params.customerId, user.id, user.settings);
    } else {
      $customerUserService.updateSettings($state.params.customerId, user.id, user.settings);
    }
  }

  function getViaGroup(grantsByResource) {
    if (!grantsByResource) {
      return false;
    }
    return 'VIA_GROUP';
  }

  function removeGrant(resourceId, resourceType) {
    const removeGrant = {
      operation: 'REMOVE',
      resourceId: resourceId,
      resourceType: resourceType
    };
    patchGrantMethod(customerId, userId, removeGrant)
      .then((grants) => {
        $scope.grants = grants;
        _loadGrants();
      });
  }

  function changeGrant(currentRole, newRole, resourceId, resourceType) {
    if (user.isInvite) { // invite doesn't support UPDATE yet, so we'll resort to "REMOVE + ADD"
      return changeGrantWithoutPatch(newRole, resourceId, resourceType);
    }
    const request = {
      operation: currentRole ? 'UPDATE' : 'ADD',
      roleId: newRole.id,
      resourceId: resourceId,
      resourceType: resourceType
    };
    patchGrantMethod(customerId, userId, request)
      .then((grants) => {
        $scope.grants = grants;
        _loadGrants();
      }, (err) => {
        if (err.status === 403) {
          $modalService.errorModal(err.data.message);
        } else {
          $modalService.errorModal();
        }
      });
  }

  function changeGrantWithoutPatch(newRole, resourceId, resourceType) {
    const removeGrant = {
      operation: 'REMOVE',
      resourceId: resourceId,
      resourceType: resourceType
    };
    const addGrant = {
      operation: 'ADD',
      roleId: newRole.id,
      resourceId: resourceId,
      resourceType: resourceType
    };
    patchGrantMethod(customerId, userId, removeGrant)
      .then(
        () => patchGrantMethod(customerId, userId, addGrant),
        (err) => $modalService.errorModal(err.data && err.data.message)
      ).then((grants) => {
      $scope.grants = grants;
      _loadGrants();
    }, (err) => $modalService.errorModal(err.data && err.data.message));
  }

  function changeAccountGrant(newRole) {
    if ($customerRolesService.isOwnerRole(newRole)) {
      transferOwnershipModal();
      return;
    }
    const resourceId = self.currentCustomer.id;
    if (user.isInvite) { // invite doesn't support UPDATE yet, so we'll resort to "REMOVE + ADD"
      return changeGrantWithoutPatch(newRole, resourceId, 'CUSTOMER');
    }
    changeGrant($scope.accountRole, newRole, resourceId, 'CUSTOMER');
  }

  function getGrantsForGroups(resourceId) {
    const grants = $scope.grantsByResource[resourceId];
    return `<div class="pa-m">
        <p class="fs-l bold">${$translate.instant('ACCESS_VIA_GROUPS')}</p>
        ${grants.map(g => `<p class="ma-xs mb-m"><i class="fa fa-group mr-s"></i>${g.group} - <span style="color: #a9a9a9;">${g.role}</span></p>`).join('\n')}
    </div>`;
  }
  function transferOwnershipModal() {
    if (user.isInvite) {
      $modalService.alertModal({title: 'INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE', message: 'INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE'});
    } else if (!$usersContextService.canTransferOwnership()) {
      $modalService.alertModal({title: 'MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE', message: 'MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE'});
    } else {
      $modalService.confirmModal({
        title : 'TRANSFER_OWNERSHIP_CONFIRM_TITLE',
        message : $translate.instant('TRANSFER_OWNERSHIP_CONFIRM_MESSAGE', {firstName: user.settings.firstName, lastName: user.settings.lastName, username: user.username}),
        confirmButtonTitle : 'CONFIRM',
        showExclamation : true,
        confirmButtonStyle : 'warning'
      }).then(() => {
          transferOwnershipMethod($scope.customer.customerId, user.id)
              .then(() => {
                $scope.$dismiss();
                $modalService.alertModal({
                  title: 'TRANSFER_OWNERSHIP_CONFIRMED_TITLE',
                  message: $translate.instant('TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE', {firstName: user.settings.firstName, lastName: user.settings.lastName, username: user.username})
                });
              })
              .catch((err) => {
                  $modalService.errorModal(err.data && err.data.message);
              });
      });
    }
  }
}]);
