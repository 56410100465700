'use strict';

angular.module('customerPortal').controller('DataSourcePickerCtrl', ['$scope', '$rootScope', '$sidePanel', function ($scope, $rootScope, $sidePanel) {

  $scope.selectDataSourceCallback = function (dataSource) {
    $rootScope.$broadcast('dataSource.changed', dataSource);
  };

  $scope.addDataSourceCallback = function () {
    $sidePanel.open({
      template: require('../../../../modules/DataSources/templates/dataSource.new.html'),
      resolve: {
        dataSources: function () {
          return [];
        }
      },
      controller: 'AddDataSourcePanelCtrl'
    }).then(function (newDataSource) {
      $scope.$broadcast('dataSource.added', newDataSource);
    });
  };
}]);