'use strict';

angular.module('FormManagement').controller('BillingSubscriptionScheduleCtrl', ['subscription', 'upcomingInvoice', '$scope', '$state', '$modalService', '$billingService', function (subscription, upcomingInvoice, $scope, $state, $modalService, $billingService) {
  const self = this;

  self.subscription = subscription;
  self.upcomingInvoice = upcomingInvoice;
  self.customerId = $state.params.customerId;
  self.cancelSchedule = cancelSchedule;
  self.canceling = true;

  init();

  ////////
  function init() {
    _loadPlans();
  }

  function cancelSchedule () {
    self.canceling = true;
    $billingService.cancelSubscriptionSchedule($state.params.customerId, subscription.subscription).$promise.then(() => {
      $scope.$close();
    }, $modalService.errorModal).finally(() => {
      self.canceling = false;
    });
  }

  function _loadPlans () {
    $billingService.getPlans(self.customerId).then(plans => {
      self.plans = plans;
      self.changes = self.subscription.scheduled.current.filter(x => x.type === 'BASE').map(item => {
        const change = self.subscription.scheduled.future.find(futureItem => futureItem.product === item.product);
        const current = self.plans.find(x => x.id === item.id);
        const future = self.plans.find(x => x.id === change.id);
        return {current, future};
      });
    });
  }
}]);
