'use strict';

angular.module('CustomerPortalSettings').controller('SettingsApiKeysCtrl', ['$scope', '$state', '$customerApiKeyService', '$sidePanel', '$modalService', '$translate', function ($scope, $state, $customerApiKeyService, $sidePanel, $modalService, $translate) {
  const self = this;
  const customerId = $state.params.customerId;

  self.columns = [
    {id : 'name', name : $translate.instant('NAME'), type : 'string'},
    {id : 'lastCharacters', name : $translate.instant('TOKEN'), type : 'string'},
    {id : 'created', name : $translate.instant('CREATED'), type : 'datetime'},
  ];
  self.save = (name) => $customerApiKeyService.save(customerId, name);
  self.update = (id, name) => $customerApiKeyService.update(customerId, id, name);
  self.delete = (id) => $customerApiKeyService.delete(customerId, id);
  self.addKey = addKey;
  self.deleteKey = deleteKey;

  init();

  function init () {
    loadKeys();
  }

  function loadKeys () {
    $customerApiKeyService.find(customerId).then(keys => {
      self.keys = keys.map(key => {
        key.lastCharacters = '✱✱✱✱✱ ' + key.lastCharacters;
        return key;
      });
    });
  }

  function addKey () {
    $sidePanel
      .open({template : require('../templates/settings.apikeys.new.html')})
      .then(name => $customerApiKeyService.save(customerId, name).then(showNewKey));
  }

  function deleteKey (apiKey) {
    $modalService.deleteModal({
      title : 'API_KEY_REMOVE_TITLE',
      message : 'API_KEY_REMOVE_MESSAGE',
      data : {
        template : apiKey
      }
    }).then(function () {
      $customerApiKeyService.delete(customerId, apiKey.id).then(function () {
        loadKeys();
      });
    });
  }

  function showNewKey (key) {
    $modalService.open({
      template : require('../templates/apikey.modal.html'),
      backdrop : 'static',
      keyboard : false,
      controller : ['$scope', ($scope) => {
        $scope.isCopied = false;
        $scope.token = key.token;
        $scope.copyToClipboard = function () {
          navigator.clipboard.writeText(key.token);
          $scope.isCopied = true;
        };
      }]
    }).then(loadKeys);
  }
}]);
