'use strict';

angular.module('customerPortal').controller('SignUpCtrl', ['$scope', '$state', '$signUpCustomerService', '$location', '$translate', '$segmentUtils', '$browserService', '$rootScope', function ($scope, $state, $signUpCustomerService, $location, $translate, $segmentUtils, $browserService, $rootScope) {

  const self = this;
  const urlParams = getUrlParams();

  self.loading = false;
  self.userAgreed = false;
  self.signup = {
    emailAddress: urlParams.get('email') || null,
    password: null,
    language: $translate.use()
  };
  self.createAccount = createAccount;

  init();

  ////////
  function init() {
    if ($rootScope.features.allowSignup === false) {
      $state.go('login');
    }
  }

  function createAccount() {
    self.loading = true;
    self.signUpErrorMessage = null;
    self.validationErrors = null;
    self.signup.plan = urlParams.get('plan');
    self.signup.customerName = self.signup.emailAddress;
    self.signup.segment = $segmentUtils.findSegmentByName($state.params.segment);

    if (urlParams.has('utm_campaign')) {
      self.signup.utmParams = {
        campaign : urlParams.get('utm_campaign'),
        source : urlParams.get('utm_source'),
        medium : urlParams.get('utm_medium'),
        content : urlParams.get('utm_content'),
        term : urlParams.get('utm_term')
      };
    }

    $browserService.getBrowserCountryCode().then(function (countryCode) {
      self.signup.country = countryCode;

      $signUpCustomerService.signUp(self.signup).$promise.then(function () {
        $state.transitionTo('signup-requested');
      }, function (error) {
        if (error.status === 400) {
          if (error.data.details) {
            $scope.validationErrors = error.data.details;
          } else {
            self.signUpErrorMessage = error.data.message;
          }
        } else if (error.status >= 500) {
          self.signUpErrorMessage = 'FORM_SUBMISSION_SERVER_ERROR';
        } else {
          self.signUpErrorMessage = 'UNKNOWN_ERROR';
        }
        self.loading = false;
      });
    });
  }

  function getUrlParams() {
    return (new URL(document.location)).searchParams;
  }
}]);
