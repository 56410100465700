'use strict';

angular.module('customerPortal').directive('formThemePicker', ['$state', '$customerFormThemeService', function ($state, $customerFormThemeService) {

  return {
    restrict: 'E',
    template: require('../../templates/portal/form-theme-picker.html'),
    scope: {
      formVersion: '=',
    },
    link: function (scope) {
      $customerFormThemeService.getThemes($state.params.customerId).$promise.then(function (themes) {
        scope.systemThemes = themes.filter(function (theme) {
          return theme.system === true;
        });
        scope.customThemes = themes.filter(function (theme) {
          return theme.system !== true;
        });
        scope.themeMap = {};
        themes.forEach(function (theme) {
          scope.themeMap[theme.id] = theme;
        });
      });

      scope.selectTheme = function (theme) {
        scope.formVersion.theme = theme;
      };
    }
  };
}]);