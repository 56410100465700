'use strict';

angular.module('MoreGrid').directive('registrationDataCell', ['$state', '$objectPathResolver', '$usersContextService', '$rootScope', '$dateUtils', function ($state, $objectPathResolver, $usersContextService, $rootScope, $dateUtils) {
  var dateRegex = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
  var dateTimeRegex = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2} [0-9]{1,2}:[0-9]{2}$/;
  var isoDateStringRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
  return {
    restrict: 'E',
    template: '<span data-ng-if="customTemplate" compile="customTemplate"></span>' + '<span data-ng-if="dataType === \'number\' || dataType === \'string\'">{{ innerValue }}</span>' + '<span data-ng-if="dataType === \'date\'"><date-time-formatted data-ng-model="innerValue" data-format="dateFormat"></date-time-formatted></span>' + '<span data-ng-if="dataType === \'datetime\'"><date-time-formatted data-ng-model="innerValue" data-format="dateTimeFormat"></date-time-formatted></span>' + '<span data-ng-if="dataType === \'boolean\'"><input type="checkbox" disabled data-ng-checked="innerValue === true"></span>' + '<span data-ng-if="dataType === \'currency\'">{{ innerValue / 100.0 | currency:\'&euro;\' }}</span>' + '<more-grid-object-data-cell data-ng-if="dataType == \'object\'" data-ng-model="innerValue"></more-grid-object-data-cell>' + '<registration-file-link data-ng-if="dataType == \'image\'" data-name="{{name}}" data-gridfs="{{innerValue}}"></registration-file-link>',
    scope: {
      property: '@',
      name: '@',
      registration: '=',
      statistics: '=',
      type: '=',
      customTemplate: '='
    },
    link: function (scope) {
      scope.innerValue = $objectPathResolver.resolve(scope.registration, scope.property);
      scope.dateFormat = $rootScope.settings.dateFormat || 'dd-MM-yyyy';
      scope.dateTimeFormat = scope.dateFormat + ' H:mm';

      scope.$watch('registration', function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          scope.innerValue = $objectPathResolver.resolve(scope.registration, scope.property);
        }
      }, true);

      if (scope.customTemplate) {
        return;
      }

      if (scope.type) {
        scope.dataType = scope.type;
        return;
      }

      if (scope.innerValue === null || scope.innerValue === undefined) {
        return;
      }
      if (scope.property === 'meta.location') {
        scope.dataType = 'map';
      } else if (scope.property === 'info.date') {
        scope.dataType = 'datetime';
      } else if (typeof scope.innerValue === 'string') {
        if (scope.innerValue.match(dateRegex)) {
          scope.innerValue = $dateUtils.parseDateFromString(scope.innerValue);
          scope.dataType = 'date';
        } else if (scope.innerValue.match(dateTimeRegex)) {
          scope.innerValue = $dateUtils.parseDateTimeFromString(scope.innerValue);
          scope.dataType = 'datetime';
        } else if (scope.innerValue.indexOf('gridfs://') !== -1) {
          scope.dataType = 'image';
        } else if (scope.innerValue.match(isoDateStringRegex)) {
          scope.dataType = 'datetime';
        } else {
          scope.dataType = 'string';
        }
      } else if (scope.property === 'info.userId') {
        //Handle username to full name conversion
        $usersContextService.getPrettyName(scope.innerValue, function (prettyName) {
          scope.innerValue = prettyName;
        });
        scope.dataType = 'string';
      } else if (scope.innerValue === true || scope.innerValue === false) {
        scope.dataType = 'boolean';
      } else if (typeof scope.innerValue === 'number') {
        scope.dataType = 'number';
      } else if (typeof scope.innerValue === 'object') {
        if (objectIsEmpty(scope.innerValue)) {
          scope.innerValue = '';
          scope.dataType = 'string';
        } else if (isMultipleLookupValue(scope.innerValue)) {
          scope.innerValue = scope.innerValue.join(', ');
          scope.dataType = 'string';
        } else {
          scope.dataType = 'object';
        }
      } else {
        scope.dataType = 'string';
      }

      if (scope.statistics) {
        var oldValue = scope.statistics[scope.dataType] ? scope.statistics[scope.dataType] : 0;
        scope.statistics[scope.dataType] = oldValue + 1;
      }
    }
  };

  ////////
  function objectIsEmpty(value) {
    return !value.length && !Object.keys(value).length;
  }

  function isMultipleLookupValue(value) {
    return angular.isArray(value) && typeof value[0] === 'string';
  }
}]);
