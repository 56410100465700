'use strict';

angular.module('MoreAppsServices').factory('$formVersionUtilityService', ['$formVersionUtils', '$widgetService', function ($formVersionUtils, $widgetService) {

  const getFieldDisplayName = field => {
    if (field.properties.label_text) {
      return field.properties.label_text;
    }
    if (field.properties.data_name) {
      return field.properties.data_name;
    }
    const widget = $widgetService.getWidgetWithSync(field.widget);
    if (widget) {
      return widget.info.name;
    }
    return field.widget;
  };

  const expandFields = (view, answer) => {
    answer = answer || [];

    const fields = view.fields;
    fields.forEach(field => {
      const form = field.properties.form;
      const dataType = field.$$widget && field.$$widget.definition.data && field.$$widget.definition.data.type;

      const answerItem = {
        displayName: getFieldDisplayName(field),
        uid: field.uid,
        widget: field.widget,
        viewId: view.uid
      };

      if (dataType === 'datasource' && field.properties.data_source_configuration) {
        const mapping = field.properties.data_source_configuration.mapping;
        answerItem.properties = Object.keys(mapping);
      }

      answer.push(answerItem);
      if (!form) {
        return;
      }
      if (dataType === 'detail') {
        expandFields(form, answer);
      } else if (dataType === 'pins') {
        field.properties.pins.forEach((pin) => expandFields(pin.form, answer));
      }
    });
    return answer;
  };

  const getSubFormUsage = function (formVersion) {
    let subFormUsages = [];
    let availableFields = this.findNestedFields(formVersion, formVersion);
    availableFields.forEach(fieldData => {
      if (fieldData.field.properties.target_form_id) {
        subFormUsages.push(fieldData.field.properties.target_form_id);
      }
      if (fieldData.field.properties.pins) {
        fieldData.field.properties.pins.filter(pin => pin.target_form_id).forEach(pin => subFormUsages.push(pin.target_form_id));
      }
    });
    return subFormUsages;
  };

  return {
    findNestedFields: $formVersionUtils.findNestedFields,
    findAvailableFieldsRecursively: view => $formVersionUtils.getFieldsRecursively(view),
    calculateDeploymentHash: applicationDefinition => hex_md5(JSON.stringify(applicationDefinition)),
    expandFields,
    allFieldUids: view => $formVersionUtils.getFieldsRecursively(view).map(field => field.uid),
    getFieldDisplayName: getFieldDisplayName,
    getSubFormUsage: getSubFormUsage,
    copySubform: $formVersionUtils.copySubform
  };
}]);
