'use strict';

angular.module('FormEditor').directive('dragItem', function () {
  return {
    restrict: 'A',
    scope: {
      item: '=',
      sortableSelector: '@',
      onDoubleClick: '&'
    },
    link: function (scope, element) {

      $(element).draggable({
        connectToSortable: scope.sortableSelector,
        helper: 'clone',
        revert: 'invalid',
        revertDuration: 150,
        appendTo: 'body',
        start: function (event, ui) {
          ui.helper.data('widget', scope.item);
        }
      });

      $(element).dblclick(function (e) {
        e.preventDefault();
        scope.$apply(function () {
          scope.onDoubleClick({ widget: scope.item });
        });
      });
      $(element).disableSelection();
    }
  };
});