'use strict';

angular.module('MoreAppsSecurity', ['ui.router', 'StateConstants', 'LocalStorageModule', 'MoreAppsConstants']);

angular.module('MoreAppsSecurity').config(['$httpProvider', function ($httpProvider) {
  $httpProvider.interceptors.push('authenticationInterceptor');
}]);

angular.module('MoreAppsSecurity').factory('authenticationInterceptor', ['OAuth2LoginService', '$q', '$injector', '$rootScope', 'localStorageService', '$location', 'moreConstants', function (OAuth2LoginService, $q, $injector, $rootScope, localStorageService, $location, moreConstants) {
  const unauthenticatedApiV1Urls = ['/reset_password', '/countries', '/activation', '/signups', '/invites', '/common', '/payment/status', '/email-validation'].map(path => moreConstants.apiEndpoint + path);
  const unauthenticatedApiV2Urls = ['/invites', '/email-validation'].map(path => moreConstants.apiEndpointV2 + path);
  const unauthenticatedApiUrls = unauthenticatedApiV1Urls.concat(unauthenticatedApiV2Urls);
  let refreshCall;

  return {
    'request': function (config) {
      // Only add Authorization header when requesting an authenticated More API endpoint
      if (!(config.url.startsWith(moreConstants.apiEndpoint) || config.url.startsWith(moreConstants.apiEndpointV2)) || unauthenticatedApiUrls.some((url) => config.url.startsWith(url))) {
        return config;
      }

      const deferred = $q.defer();
      OAuth2LoginService.userManager.getUser()
        .then((user) => {
          if (user) {
            config.headers = config.headers || {};
            config.headers.Authorization = 'Bearer ' + user.access_token;

            deferred.resolve(config);
          } else {
            deferred.reject('User not logged in (but is required for this endpoint)');
          }
        }).catch((e) => {
          deferred.reject(e);
        });

      return deferred.promise;
    },
    'responseError': function (response) {
      if (response.status === 401) {
        if (!refreshCall) {
          refreshCall = OAuth2LoginService.userManager.signinSilent().catch((err) => {
            console.error('Failed to silently login, clear everything and redirect to login page', err);
            OAuth2LoginService.userManager.removeUser()
              .finally(() => {
                localStorageService.remove('user');
                $rootScope.user = null;
                $location.url(`/login?originalUrl=${$location.url()}`);
              });
          }).finally(() => {
            refreshCall = null;
          });
        }

        const deferred = $q.defer();
        refreshCall.then(() => {
          $injector.get('$http')(response.config).then(deferred.resolve, deferred.reject);
        });

        return deferred.promise;
      } else {
        return $q.reject(response);
      }
    }
  };
}]);

angular.module('MoreAppsSecurity').config(['$stateProvider', '$urlRouterProvider', 'stateConfig', function ($stateProvider, $urlRouterProvider, stateConfig) {

  $urlRouterProvider.when(/[.]*$/i, ['$state', '$match', '$stateParams', '$securityService', '$location', function ($state, $match, $stateParams, $securityService, $location) {
    const path = $location.path();
    if (
      path === '/login' ||
      path === '/login/callback' ||
      path === '/logout/callback' ||
      path === '/accessdenied' ||
      path === '/loginfailed' ||
      path.startsWith('/invites') ||
      path.startsWith('/activate') ||
      path.startsWith('/change_password') ||
      path.startsWith('/activate_user') ||
      path.startsWith('/changed_password')
    ) {
      return false;
    }

    if (stateConfig.unauthenticatedPaths && stateConfig.unauthenticatedPaths.some((unauthenticatedPath) => path.startsWith(unauthenticatedPath))) {
      return false;
    }

    if (!$securityService.isLoggedIn()) {
      const originalUrl = encodeURIComponent($location.url());
      return `/login?originalUrl=${originalUrl}`;
    }
    return false;
  }]);

  $stateProvider.state('login', {
    url: '/login',
    onEnter: ['$state', 'OAuth2LoginService', '$securityService', '$location', function ($state, OAuth2LoginService, $securityService, $location) {
      if ($securityService.isLoggedIn()) {
        $state.transitionTo('portal');
        return;
      }

      const url = $location.search().originalUrl;

      OAuth2LoginService.login(url);
    }],
    access: [],
  }).state('login-callback', {
    url: '/login/callback',
    onEnter: ['OAuth2LoginService', '$securityService', '$userService', '$location', '$state', function (OAuth2LoginService, $securityService, $userService, $location, $state) {
      OAuth2LoginService.userManager.signinCallback().then(user => {
        $userService.getUser().$promise.then(user => {
          window.posthog.identify(user.id, { environment: window.MORE_ENV.environment });
        });
        $securityService.authenticate(() => {
          const url = user.state && user.state.originalUrl ? decodeURIComponent(user.state.originalUrl) : '/';
          $location.search('');  // Remove any query params
          $location.url(url);
        }, (e) => {
          console.error('Login - Failed to fetch user profile', e);
          $location.search(''); // Remove any query params
          $state.go('accessdenied');
        });
      }).catch(e => {
        console.error(e);
        $location.search(''); // Remove any query params
        if(e.toString().startsWith('Error: iat is in the future')) {
          $state.go('loginfailed');
        } else {
          $state.go('accessdenied');
        }
      });
    }],
    access: [],
  }).state('logout-callback', {
    url: '/logout/callback',
    template: require('../../shared/templates/logout_successful.html'),
    onEnter: ['OAuth2LoginService', '$securityService', '$location', function (OAuth2LoginService) {
      window.posthog.reset();
      OAuth2LoginService.userManager.signoutRedirectCallback();
    }],
    access: [],
  }).state('accessdenied', {
    url: '/accessdenied',
    template: require('../../shared/templates/accessdenied.html'),
    controller: 'AccessDeniedCtrl',
    access: [],
    data: {
      entryView: true
    }
  }).state('loginfailed', {
    url: '/loginfailed',
    template: require('../../shared/templates/login-failed.html'),
    access: [],
    data: {
      entryView: true
    }
  });
}]);

angular.module('MoreAppsSecurity').run(['$rootScope', '$securityService', '$location', 'moreConstants', function ($rootScope, $securityService, $location, moreConstants) {
  $rootScope.$on('$stateChangeStart', function (event, next) {
    $rootScope.error = null;
    if (!$securityService.authorize(next.access)) {
      if (!$securityService.isLoggedIn()) {
        $location.path('/login'); // Don't think this can ever happen..
      } else {
        $location.path('/accessdenied');
      }
    } else {
      $rootScope.currentUser = $securityService.currentUser();
    }
  });

  $rootScope.adminRoles = function adminRoles() {
    if (!$rootScope.currentUser) {
      return [];
    }

    var answer = [];
    var customerAdminRoles = [moreConstants.MANAGE_CREDITS, moreConstants.MANAGE_DATA_SOURCES, moreConstants.MANAGE_FORMS, moreConstants.MANAGE_TASKS, moreConstants.MANAGE_REGISTRATIONS, moreConstants.MANAGE_USERS, moreConstants.MANAGE_SSO, moreConstants.TRANSFER_OWNERSHIP, moreConstants.MANAGE_ACCOUNT];
    var resourceAdminRoles = [moreConstants.UPDATE_FOLDER, moreConstants.READ_SUBMISSION, moreConstants.UPDATE_SUBMISSION, moreConstants.DELETE_SUBMISSION, moreConstants.READ_REGISTRATION, moreConstants.DELETE_REGISTRATION, moreConstants.CREATE_TASK, moreConstants.READ_TASK, moreConstants.UPDATE_TASK, moreConstants.DELETE_TASK, moreConstants.CREATE_USER, moreConstants.READ_USER, moreConstants.UPDATE_USER, moreConstants.DELETE_USER, moreConstants.CREATE_FORM, moreConstants.UPDATE_FORM, moreConstants.ARCHIVE_FORM, moreConstants.RESTORE_FORM, moreConstants.ADD_FORM, moreConstants.REMOVE_FORM, moreConstants.CREATE_FOLDER, moreConstants.UPDATE_FOLDER, moreConstants.DELETE_FOLDER, moreConstants.READ_DATA_SOURCE, moreConstants.CREATE_DATA_SOURCE, moreConstants.UPDATE_DATA_SOURCE, moreConstants.DELETE_DATA_SOURCE, moreConstants.READ_RESOURCE, moreConstants.READ_WEBHOOK, moreConstants.CREATE_WEBHOOK, moreConstants.UPDATE_WEBHOOK, moreConstants.DELETE_WEBHOOK, moreConstants.READ_THEME, moreConstants.CREATE_THEME, moreConstants.UPDATE_THEME, moreConstants.DELETE_THEME];

    if ($rootScope.currentUser.permissions) {
      const adminRoles = customerAdminRoles.concat(resourceAdminRoles);
      $rootScope.currentUser.permissions
          .filter(grant => grant.permissions.find(permission => {
            return adminRoles.find(allowed => {
              return allowed === permission;
            });
          }))
          .forEach(grant => {
            if (answer.indexOf(grant.customerId) === -1) {
              answer.push(grant.customerId);
            }
          });
    }
    Object.keys($rootScope.currentUser.roles).forEach(function (role) {
      if (customerAdminRoles.indexOf(role) >= 0) {
        $rootScope.currentUser.roles[role].forEach(function (customerId) {
          var customerIdInt = parseInt(customerId);
          if (answer.indexOf(customerIdInt) === -1) {
            answer.push(customerIdInt);
          }
        });
      }
    });

    return answer;
  };
}]);
