'use strict';

angular.module('MoreDirectives').directive('contenteditable', function () {
  // Based on: http://gaboesquivel.com/blog/2014/in-place-editing-with-contenteditable-and-angularjs/
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {

      function read() {
        ngModel.$setViewValue(element.text());
      }

      ngModel.$render = function () {
        element.html(ngModel.$modelValue || element.html());
      };

      element.bind('blur keyup change', function () {
        scope.$apply(read);
      });
    }
  };
});