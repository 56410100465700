'use strict';

angular.module('FormEditor').controller('UrlSelectModalCtrl', ['$scope', 'initialValue', 'defaultValues', 'headerTranslation', function ($scope, initialValue, defaultValues, headerTranslation) {

  $scope.urlValue = initialValue;
  $scope.headerTranslation = headerTranslation;

  $scope.defaultValues = defaultValues;
  $scope.selectUrl = function (newValue) {
    $scope.$close({ success: true, value: newValue });
  };
}]);