'use strict';

angular.module('MorePagination').factory('$paginatorFactory', function () {

  var Paginator = function (options) {
    var target = options.target;
    var page = options.page;
    var defaultArguments = options.defaultArguments || [];
    var filter = options.filter || { pageSize: 30, sort: [], idSelection: [] };
    var currentPage = 0;

    this.nextPage = function () {
      return page.apply(target, defaultArguments.concat([currentPage++, filter])).$promise.then(function (page) {
        return {
          page: page,
          morePages: filter.pageSize === page.length
        };
      }, function () {
        return null;
      });
    };
  };

  return {
    createPaginator: function (options) {
      return new Paginator(options);
    }
  };
});