'use strict';

angular.module('MoreAppsServices').factory('$customerUsageService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerUsage = $resource(moreConstants.apiEndpoint + '/customers/:customerId/usage/:action/:year', {}, {
    'monthly': {
      method: 'GET',
      params: { action: 'monthly' }
    },
    'lastMonth': {
      method: 'GET',
      params: { action: 'lastMonth' }
    }
  });

  return {
    getMonthlyUsageForYear: (customerId, year) => CustomerUsage.monthly({customerId, year}),
    getDailyUsageLastMonth: customerId => CustomerUsage.lastMonth({customerId})
  };
}]);
