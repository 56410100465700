'use strict';
angular.module('more.ui.form').service('$freemarkerHelper', function () {

  var traversePath = function (path, data) {
    var components = path.split(".");
    var target = data;
    for (var i = 0; i < components.length; i++) {
      target = target[components[i]];
      if (target == null) {
        return null;
      }
    }
    return target;
  };

  return {
    interpolate : function (template, data) {
      return template.replace(/\$\{([^\}]*)}/g, function (match, path) {
        var result = traversePath(path, data);
        if (result == null) {
          return '';
        }
        return result;
      }).trim();
    }
  };
});
