'use strict';

angular.module('MoreAppsServices').factory('$errorListener', ['$rootScope', '$state', function ($rootScope, $state) {

  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
    $rootScope.errorMessage = error ? error.message || error : error;
    $state.transitionTo('portal.error');
  });

  return {};
}]);