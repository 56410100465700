'use strict';

angular.module('MoreAppsFilters', ['MoreAppsConstants']);

angular.module('MoreAppsFilters').filter('validCssClass', function () {
  return function (input) {
    if (input !== undefined) {
      return input.replace(/[^a-zA-Z0-9]/g, '-');
    }
    return '';
  };
});

angular.module('MoreAppsFilters').filter('asImage', ['moreConstants', function (moreConstants) {
  return function (input) {
    if (input && input.indexOf('gridfs://registrationFiles') === 0) {
      return moreConstants.apiEndpoint + '/client/registrations/files' + input.substr('gridfs://registrationFiles'.length);
    }
    return input;
  };
}]);

angular.module('MoreAppsFilters').filter('camelCaseToHuman', function () {
  return function (input) {
    if (input) {
      return input.charAt(0).toUpperCase() + input.substr(1).replace(/[A-Z]/g, ' $&');
    }
  };
});

angular.module('MoreAppsFilters').filter('priceInCents', ['currencyFilter', function (currencyFilter) {
  return function (input) {
    if (input) {
      return currencyFilter(input / 100.0, '€');
    }
  };
}]);

angular.module('MoreAppsFilters').filter('reverse', function () {
  return function (items) {
    return items.slice().reverse();
  };
});

angular.module('MoreAppsFilters').filter('slug', function () {
  return function (input) {
    if (input) {
      return input.toLowerCase().replace(/[^a-z_]/g, '_');
    }
  };
});

angular.module('MoreAppsFilters').filter('translate_html', ['$sce', '$translate', function ($sce, $translate) {
  return function (input) {
    if (input) {
      return $sce.trustAsHtml($translate.instant(input));
    }
  };
}]);