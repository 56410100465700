'use strict';

angular.module('MoreAppsServices').factory('$customerApiKeyService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const CustomerApiKeys = $resource(moreConstants.apiEndpoint + '/customers/:customerId/keys/:id', {}, {
    update: {
      method: 'PUT'
    }
  });

  return {
    find: customerId => CustomerApiKeys.query({customerId}).$promise,
    get: (customerId, id) => CustomerApiKeys.get({customerId, id}).$promise,
    save: (customerId, name) => CustomerApiKeys.save({customerId}, {name}).$promise,
    update: (customerId, id, name) => CustomerApiKeys.update({customerId, id}, {name}).$promise,
    delete: (customerId, id) => CustomerApiKeys.delete({customerId, id}).$promise
  };
}]);
