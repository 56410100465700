'use strict';

angular.module('DataSources').controller('UploadExcelDataSourceCtrl', ['$scope', '$customerDataSourceService', 'dataSource', function ($scope, $customerDataSourceService, dataSource) {

  var MAX_SIZE = 1024 * 1024 * 10;

  var files;
  $scope.fileTooLarge = false;

  $scope.selectedFiles = function ($files) {
    $scope.fileTooLarge = false;
    files = $files;
    $files.forEach(function (file) {
      if (file.size > MAX_SIZE) {
        $scope.fileTooLarge = true;
      }
    });
  };

  $scope.hasNoFileSelected = function () {
    return !files;
  };

  $scope.startImportWithFile = function () {
    if (files !== null && files !== undefined) {
      $scope.saving = true;
      $customerDataSourceService.importFile($scope.$state.params.customerId, dataSource.id, files[0]).then(function () {
        $scope.saving = false;
        dataSource.$get({ dataSourceId: dataSource.id }).then($scope.$close);
      });
    }
  };
}]);
