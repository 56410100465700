'use strict';

angular.module('CustomerPortalSettings').controller('SettingsSendingDomainCtrl', ['$scope', '$timeout', '$translate', 'moreConstants', '$featureHelper', '$sendingDomainService', '$modalService', function ($scope, $timeout, $translate, moreConstants, $featureHelper, $sendingDomainService, $modalService) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  const VALID_DOMAIN = /^([a-z\d]+(-[a-z\d]+)*\.)+[a-z]{2,}$/;

  self.createSendingDomainRequest = {};
  self.updateSendingDomainRequest = {};
  self.sendingDomain = null;
  self.errorMessage = null;
  self.verifying = false;
  self.creating = false;
  self.updating = false;
  self.copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  self.isSendingVerified = () => self.sendingDomain && self.sendingDomain.records.sending.status === 'verified';
  self.isBounceVerified = () => self.sendingDomain && self.sendingDomain.records.bounce.status === 'verified';
  self.isVerified = () => self.sendingDomain && self.isSendingVerified() && self.isBounceVerified();

  const loadUpdateForm = () => {
    self.updateSendingDomainRequest = {
      fromName: self.sendingDomain.fromName,
      fromEmail: self.sendingDomain.fromEmail.split('@')[0],
    };
  };

  const init = () => {
    $sendingDomainService.get(customerId).then((sendingDomain) => {
      self.sendingDomain = sendingDomain;
      loadUpdateForm();
    });

    $featureHelper.hasFeature(moreConstants.SENDING_DOMAIN).then(hasFeature => {
      self.hasFeature = hasFeature;
    });
  };

  const resetError = () => {
    self.errorMessage = null;
  };

  self.showUpgradeModal = () => $modalService.upgradePlanModal({
    title: 'FEATURE_SENDING_DOMAIN',
    message: 'FEATURE_SENDING_DOMAIN_MESSAGE',
    imgSrc: 'https://cdn.moreapp.com/features/en/feature-sending-domain.png'
  });

  self.createSendingDomain = () => {
    resetError();
    if (!VALID_DOMAIN.test(self.createSendingDomainRequest.domain)) {
      self.errorMessage = $translate.instant('SENDING_DOMAIN_ERROR_INVALID_DOMAIN', {domain: self.createSendingDomainRequest.domain});
      return;
    }
    const request = angular.copy(self.createSendingDomainRequest);
    request.fromEmail = `${request.fromEmail}@${request.domain}`;
    self.creating = true;
    $sendingDomainService.create(customerId, request).then((sendingDomain) => {
      self.sendingDomain = sendingDomain;
      loadUpdateForm();
    }, _handleError).finally(() => {
      self.creating = false;
    });
  };

  self.updateSendingDomain = () => {
    resetError();
    self.updating = true;
    const request = angular.copy(self.updateSendingDomainRequest);
    request.fromEmail = `${request.fromEmail}@${self.sendingDomain.domain}`;
    $sendingDomainService.update(customerId, request).then((sendingDomain) => {
      self.sendingDomain = sendingDomain;
      self.updateSuccess = true;
    }, _handleError).finally(() => {
      self.updating = false;
      $timeout(() => self.updateSuccess = false, 5000);
    });
  };

  self.verifySendingDomain = () => {
    resetError();
    self.verifying = true;
    $sendingDomainService.verify(customerId).then((sendingDomain) => {
      self.sendingDomain = sendingDomain;
    }, _handleError).finally(() => {
      self.verifying = false;
    });
  };

  self.deleteSendingDomain = () => {
    resetError();
    $modalService.confirmModal({
      title: 'SENDING_DOMAIN_DELETE_TITLE',
      message: 'SENDING_DOMAIN_DELETE_MESSAGE',
      confirmButtonTitle: 'DELETE',
      showExclamation: true,
      confirmButtonStyle: 'danger'
    }).then(() => {
      $sendingDomainService.delete(customerId).then(() => {
        self.sendingDomain = null;
      }, _handleError);
    });
  };

  function _handleError(err) {
    self.errorMessage = err.data.message;
  }

  init();
}]);
