'use strict';

angular.module('FormManagement').controller('FormManagementCopyCtrl', ['$scope', '$state', '$translate', '$customerFolderService', 'customers', 'result', function ($scope, $state, $translate, $customerFolderService, customers, result) {
  $scope.customers = customers;
  $scope.result = result;

  $scope.onChangeCustomer = onChangeCustomer;
  $scope.folders = [];

  onChangeCustomer($scope.result.customerId);

  function onChangeCustomer(customerId) {
    if ($scope.result) {
      delete $scope.result.folderId;
    }
    $scope.folders = $customerFolderService.getFolders(customerId, true);
  }
}]);
