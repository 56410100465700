'use strict';

angular.module('UserManagement').controller('AddGroupCtrl', ['$scope', '$state', '$customerGroupsService', function ($scope, $state, $customerGroupsService) {

  const self = this;

  self.saving = false;

  self.saveNewGroup = (name) => {
    self.saving = true;
    $customerGroupsService.create($state.params.customerId, {name}).then(savedGroup => {
      self.saving = false;
      $scope.$close(savedGroup);
    });
  };
}]);
