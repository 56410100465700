'use strict';

angular.module('MoreAppsServices').factory('$customerUserService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const CustomerUsers = $resource(moreConstants.apiEndpoint + '/customers/:customerId/users/:userId/:action', {}, {
    find: {method: 'GET', params: {action: 'page'}},
    update: {method: 'PUT'},
    updateSettings: {method: 'PUT', params: {'action': 'settings'}}
  });

  const CustomerUserGrants = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/users/:userId/grants', {}, {
    patch: {method: 'PATCH', isArray: true}
  });

  const CustomerUserTransferOwnership = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/users/:userId/grants/transfer', {}, {
    put: {method: 'PUT',}
  });

  const CustomerUserInvites = $resource(moreConstants.apiEndpoint + '/customers/:customerId/invites/:userId/:action', {}, {
    changePermission: {method: 'POST', params: {'action': 'permissions'}},
    changeGroup: {method: 'POST', params: {'action': 'groups'}}
  });

  const CustomerUserAccountOwner = $resource(moreConstants.apiEndpoint + '/customers/:customerId/users/account-owner', {}, {
    get: {method: 'GET'}
  });

  return {
    getUsers: customerId => CustomerUsers.query({customerId}),
    findUsers: (customerId, groupId, filter, firstName, lastName, username) => CustomerUsers.find({customerId, groupId, page: filter.currentPage, size: filter.pageSize, firstName, lastName, username }),
    getUser: (customerId, userId) => CustomerUsers.get({customerId, userId}),
    getAccountOwner: (customerId) => CustomerUserAccountOwner.get({customerId}),
    updateSettings: (customerId, userId, settings) => CustomerUsers.updateSettings({customerId, userId}, settings),
    removeUser: (customerId, userId) => CustomerUsers.remove({customerId, userId}),
    changeGroup: (customerId, user, groupChange) => CustomerUserInvites.changeGroup({customerId, userId: user.id}, groupChange),
    patchGrant: (customerId, userId, grantChange) => CustomerUserGrants.patch({customerId, userId}, grantChange).$promise,
    getGrants: (customerId, userId) => CustomerUserGrants.query({customerId, userId}).$promise,
    transferOwnership: (customerId, userId) => CustomerUserTransferOwnership.put({customerId, userId}, {}).$promise
  };
}]);
