'use strict';

(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else {
    // Browser globals
    factory(window.jQuery);
  }
})(function ($) {

  $.extend($.summernote.plugins, {
    'showmap': function (context) {
      var ui = $.summernote.ui;

      context.memo('button.showmap', function () {
        var button = ui.button({
          contents: '<i class="fa fa-map-marker"/> Showmap',
          tooltip: 'Showmap',
          click: function () {
            context.invoke('editor.insertText', '@{showmap#location=meta.location}');
          }
        });

        return button.render();
      });
    }
  });
});