'use strict';

angular.module('CustomerPortalSettings').controller('AddSubscriberCtrl', ['$scope', '$customerWebhookService', function ($scope, $customerWebhookService) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.saving = false;
  self.subscriber = {};
  self.save = save;
  self.eventTypes = $customerWebhookService.getEventTypes();

  function save(subscriber) {
    self.saving = true;
    subscriber.status = 'ACTIVE';
    $customerWebhookService.createSubscriber(customerId, subscriber).$promise.then(savedSubscriber => {
      $scope.$close(savedSubscriber);
    }).finally(() => {
      self.saving = false;
    });
  }
}]);
