'use strict';

angular.module('AdminServices').factory('$adminInvoiceService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var AdminInvoice = $resource(moreConstants.apiEndpoint + '/admin/invoices/:partnerId/:invoiceId/download', {}, {
    getDownloadTicket: {
      method: 'GET'
    }
  });

  return {
    getInvoiceDownloadTicket: function (partnerId, invoiceId) {
      return AdminInvoice.getDownloadTicket({ partnerId: partnerId, invoiceId: invoiceId });
    }
  };
}]);