'use strict';

angular.module('Billing').directive('billingPaymentMethodSelector', ['$translate', function () {
  return {
    restrict: 'E',
    scope: {
      billing: '=',
      allowPayOnFile: '=',
      onSave: '&',
    },
    template: require('../templates/directives/billing.payment-method-selector.html')
  };
}]);
