'use strict';

angular.module('MoreGrid').directive('endlessPaginatedMoreGrid', function () {
  return {
    restrict: 'E',
    template: require('../templates/endless-paginated-more-grid.html'),
    scope: {
      getListedObjects: '&',
      columns: '=',
      searchEnabled: '=?',
      sortEnabled: '=?',
      selectionEnabled: '=?',
      idSelectionChanged: '&',
      viewObject: '&',
      viewEnabled: '=?',
      selection: '=?',
      pageSize: '@'
    },
    link: function (scope) {
      var init = function () {
        scope.totalItems = 0;
        scope.listedObjects = [];
        scope.hasMoreObjects = true;

        scope.filter = {
          currentPage: 0,
          pageSize: scope.pageSize,
          sortInfo: {
            directions: [],
            fields: []
          },
          query: {}
        };
        scope.idSelection = [];

        // overridable defaults
        scope.columns = scope.columns || [];
        scope.selection = scope.selection || [];
        scope.searchEnabled = false;

        getListedObjects();
      };

      var getListedObjects = function () {
        scope.getListedObjects({ filter: scope.filter }).then(function (result) {
          if (result.totalItems < scope.pageSize) {
            scope.hasMoreObjects = false;
          }
          scope.listedObjects.push.apply(scope.listedObjects, result.listedObjects);
          scope.totalItems += result.totalItems;
          scope.loadingMore = false;
        });
      };

      scope.loadMore = function () {
        scope.filter.currentPage++;
        scope.loadingMore = true;
        getListedObjects();
      };

      init();

      scope.$on('endlesspaginatedmoregrid.reload', function () {
        init();
      });
    }
  };
});