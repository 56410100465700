'use strict';

angular.module('MoreAppsServices').factory('$customerCreationService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const CustomerCreation = $resource(moreConstants.apiEndpoint + '/customers/:customerId/create', {}, {});

  return {
    createCustomer: function (customerId, customerRequest) {
      return CustomerCreation.save({ customerId: customerId }, customerRequest).$promise;
    },
  };
}]);
