'use strict';

angular.module('MoreAppsServices').factory('$customerRegistrationFileService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerRegistrationFile = $resource(moreConstants.apiEndpoint + '/customers/:customerId/registrationFile/:registrationFileId', {
    customerId: '@customerId',
    registrationFileId: '@registrationFileId'
  }, {});

  return {
    getSecureUri: function (customerId, registrationFileId) {
      return CustomerRegistrationFile.get({ customerId: customerId, registrationFileId: registrationFileId }).$promise.then(function (secureUri) {
        return moreConstants.apiEndpoint + '/downloads/secured?' + secureUri.secureUri;
      });
    },
    getRegistrationFile: function (customerId, registrationFileId) {
      return CustomerRegistrationFile.get({
        customerId: customerId,
        registrationFileId: registrationFileId
      }).$promise.then(function (secureUri) {
        return {
          uri: moreConstants.apiEndpoint + '/client/registrations/files/' + registrationFileId,
          contentType: secureUri.contentType
        };
      });
    }
  };
}]);