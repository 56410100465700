'use strict';

angular.module('customerPortal').controller('EmailValidationCtrl', ['$scope', '$state', '$emailValidationService', '$timeout', function ($scope, $state, $emailValidationService, $timeout) {

  $scope.loading = true;

  $timeout(() => {
    $emailValidationService.check($scope.$state.params.token).$promise.then(() => {
      $state.go('email-validated');
    }, err => {
      console.error('err', err);
      $scope.loading = false;
      $scope.success = false;
    });
  }, 1000);
}]);
