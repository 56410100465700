'use strict';

angular.module('customerPortal').controller('NewFormCtrl', ['$scope', '$rootScope', 'folder', '$customerFormService', '$customerFolderService', '$customerFormTemplateService', '$customerUserService', '$languageUtil', function ($scope, $rootScope, folder, $customerFormService, $customerFolderService, $customerFormTemplateService, $customerUserService, $languageUtil) {
  const self = this;
  const customerId = $scope.$state.params.customerId;
  const BLANK_FORM_NAMES = $customerFormService.getDefaultFormNames();

  self.temp = {};
  self.formName = '';
  self.filter = {query: '', language: $languageUtil.getActiveLanguageISO(), tags: []};
  self.blankForm = {formId : 'id', fields : [], fieldProperties : {}};
  self.selectableTags = $customerFormTemplateService.getSelectableTags();
  self.formTemplates = [];
  self.selectedTemplate = null;
  self.formVersionPreview = null;
  self.saveNewForm = saveNewForm;
  self.selectTemplate = selectTemplate;
  self.selectBlank = selectBlank;
  self.loadTemplates = loadTemplates;

  init();

  function init() {
    selectBlank();
    $scope.$watch('ctrl.filter', loadTemplates, true);
  }

  function loadTemplates() {
    $customerFormTemplateService.findGlobal(customerId, self.filter.query, self.filter.language, self.filter.tags).$promise.then(templates => {
      self.formTemplates = templates.filter(t => t.publishedVersion);
    });
  }

  function loadFormAsPreview(formVersion) {
    self.formVersionPreview = formVersion;
  }

  function selectBlank() {
    self.formVersionPreview = null;
    self.selectedTemplate = null;
    self.formName = BLANK_FORM_NAMES[$languageUtil.getActiveLanguage()] || BLANK_FORM_NAMES.en;
  }

  function selectTemplate(template) {
    self.formName = template.meta.name;
    self.selectedTemplate = template;
    $customerFormService.getFormVersionTemplate(customerId, template.publishedVersion.formVersion).$promise
      .then(formVersion => loadFormAsPreview(formVersion));
  }

  function saveNewForm () {
    self.saving = true;

    const newForm = {
      meta : {
        name : self.formName,
        icon : self.selectedTemplate ? self.selectedTemplate.meta.icon : 'ios-paper-outline',
        description : self.selectedTemplate ? self.selectedTemplate.meta.description : '',
        tags : [self.formName],
        templateId: self.selectedTemplate ? self.selectedTemplate.id : null
      }
    };

    createForm(newForm);
  }

  function createForm(newForm) {
    $customerFormService.createForm(customerId, newForm).$promise.then(createdForm => {
      if (self.formVersionPreview) {
        self.formVersionPreview.formId = createdForm.id;
      }
      return $customerFolderService.addForm(customerId, folder.id, createdForm.id).$promise
          .then(updatedFolder => {
                $scope.$close({form: createdForm, folder: updatedFolder, formVersion: self.formVersionPreview});
          });
    });
  }
}]);
