'use strict';

angular.module('FormTemplates').controller('AddTemplatePanelCtrl', ['$scope', '$customerFormTemplateService', '$modalService', function ($scope, $customerFormTemplateService, $modalService) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.template = {
    meta: {
      name: '',
      icon: 'ios-paper-outline',
      description: '',
      tags: []
    }
  };
  self.saving = false;

  self.saveTemplate = template => {
    self.saving = true;
    $customerFormTemplateService.createForm(customerId, template).$promise.then(savedTemplate => {
      $scope.$close(savedTemplate);
    }, () => $modalService.errorModal()).finally(() => {
      self.saving = false;
    });
  };
}]);
