'use strict';

angular.module('FormSimulator').directive('formSimulator', ['$widgetService', '$state', '$subFormHelper', '$rootScope', function ($widgetService, $state, $subFormHelper, $rootScope) {
  return {
    restrict: 'EA',
    template: require('../../../shared/templates/directives/form-simulator.html'),
    scope: {
      viewId: '=',
      formVersion: '=',
      enableDataEntry: '=',
      ngModel: '=',
      customerId: '=?'
    },
    controller: ['$scope', function ($scope) {
      if (!$scope.customerId) {
        $scope.customerId = $state.params.customerId;
      }
      $scope.indexesCache = [];
      $scope.formRecompile = 0;
      $scope.widgetsPromise = getWidgets();

      $subFormHelper.setCallbackHandler(function (form, field, collection, data, success) {
        $scope.viewStack.push({
          view: form,
          data: data || {},
          onPop: success
        });
      });

      $scope.popNavigation = function () {
        $scope.viewStack.pop();
      };

      $scope.saveDetail = function () {
        var popped = $scope.viewStack.pop();
        popped.onPop(popped.data);
      };

      $scope.$watch('viewId', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $scope.ngModel = {};
          setupViewStack();
          $scope.formRecompile++;
        }
      });

      $scope.$watch('formVersion', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          $scope.ngModel = {};
          setupViewStack();
          $scope.formRecompile++;
        }
      });

      setupViewStack();

      function setupViewStack() {
        var firstView = $scope.formVersion;
        $scope.viewStack = [{
          view: firstView,
          data: $scope.ngModel
        }];
      }

      function getWidgets() {
        var customerId = $state.params.customerId ? $state.params.customerId : $rootScope.currentCustomer.customerId;
        return $widgetService.all(customerId).$promise;
      }
    }]
  };
}]);
