'use strict';

angular.module('MoreDirectives').directive('validationMessages', function () {
  return {
    restrict: 'E',
    template: require('../../MoreDirectives/templates/validation-messages.html'),
    scope: {
      formField: '=',
      form: '=',
      options: '='
    }
  };
});