'use strict';

angular.module('MoreAppsServices').factory('$pipelineService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var FormSubmissionPipeline = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/registrations/:registrationId/hookpipeline/:action', { customerId: '@customerId', registrationId: '@registrationId', action: '@action' }, {
    update: { method: 'PUT' }
  });

  var FormSubmissionPipelineInfos = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/hookpipeline', { customerId: '@customerId', formId: '@formId' }, { 'info': { method: 'POST', isArray: true } });


  return {
    getPipeline: function (customerId, formId, registrationId) {
      return FormSubmissionPipeline.get({ customerId: customerId, formId: formId, registrationId: registrationId });
    },
    retryPipeline: function (customerId, formId, registrationId) {
      return FormSubmissionPipeline.get({ customerId: customerId, formId: formId, registrationId: registrationId, action: 'retry' });
    },
    getPipelineInfos: function (customerId, formId, registrationIds) {
      return FormSubmissionPipelineInfos.info({ customerId: customerId, formId: formId }, registrationIds);
    },
    skipPipeline: function (customerId, formId, registrationId) {
      return FormSubmissionPipeline.get({ customerId: customerId, formId: formId, registrationId: registrationId, action: 'skip' });
    }
  };
}]);
