'use strict';

angular.module('MoreDirectives').directive('loadingSpinner', function () {
  return {
    restrict: 'E',
    template: '<span data-ng-if="message" data-ng-class="size"><span data-translate="{{ message }}"></span> &nbsp; <i class="fa fa-circle-o-notch fa-spin"></i></span>' + '<span data-ng-if="!message"><i class="fa fa-circle-o-notch fa-spin"></i></span>',
    scope: {
      message: '@',
      size: '@' // h1 - h6
    },
    link: function (scope) {
      scope.size = scope.size || 'h4';
    }
  };
});