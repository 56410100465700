'use strict';

angular.module('more.ui.form-builder').factory('$searchHelper', ['$modalService', '$q', '$customerDataSourceService', function ($modalService, $q, $customerDataSourceService) {

  var indexesCache = [];

  return function (customerId) {
    return {
      process: function (configuration, value) {
        var deferred = $q.defer();
        if (value === null || value === undefined) {
          deferred.resolve(null);
        }
        return deferred.promise;
      },
      getMapping: function (configuration) {
        var deferred = $q.defer();
        $customerDataSourceService.getDataSource(customerId, configuration.id).$promise.then(function (data) {
          deferred.resolve(data.columnMapping);
        }, deferred.reject);
        return deferred.promise;
      },
      start: function (dataSourceConfig) {
        var deferred = $q.defer();

        $modalService.open({
          template: require('../templates/search-modal.html'),
          controller: ['$scope', function ($scope) {
            $scope.dataSourceConfig = dataSourceConfig;
            $scope.indexesCache = indexesCache;
            $scope.customerId = customerId;
            $scope.result = {};
          }]
        }).then(function (result) {
          deferred.resolve({ changed: true, value: result });
        }, function () {
          deferred.resolve({ changed: false });
        });

        return deferred.promise;
      },
      cancel: function () {
        //NO-OP in portal
      }
    };
  };
}]);