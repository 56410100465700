'use strict';

angular.module('CustomerPortalSettings').controller('SettingsWebhooksCtrl', ['$scope', 'moreConstants', '$customerWebhookService', '$modalService', '$sidePanel', '$translate', '$featureHelper', function ($scope, moreConstants, $customerWebhookService, $modalService, $sidePanel, $translate, $featureHelper) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.columns = [
    {id: 'name', name: $translate.instant('NAME'), type: 'string', size: 'small'},
    {id: 'url', name: $translate.instant('URL'), type: 'string'},
    {id: 'status', name: $translate.instant('STATUS'), type: 'string', customTemplate: require('../../templates/settings.webhooks.status.html')}
  ];
  self.error = undefined;
  self.subscribers = [];
  self.addSubscriber = addSubscriber;
  self.editSubscriber = editSubscriber;
  self.deleteSubscriber = deleteSubscriber;

  init();

  function init() {
    loadSubscribers();
    $featureHelper.hasFeature(moreConstants.WEBHOOKS).then(hasFeature => {
      self.hasWebhooksFeature = hasFeature;
    });
  }

  function addSubscriber () {
    if (!self.hasWebhooksFeature) {
      return showUpgradePlanModal();
    }
    $sidePanel.open({
      template: require('../../templates/settings.webhooks.new.html'),
      controller: 'AddSubscriberCtrl as ctrl'
    }).then(createdSubscriber => {
      if (createdSubscriber) {
        self.subscribers.push(createdSubscriber);
      }
    });
  }

  function editSubscriber (subscriber) {
    if (!self.hasWebhooksFeature) {
      return showUpgradePlanModal();
    }
    $modalService.open({
      template: require('../../templates/settings.webhooks.detail.html'),
      controller: 'SubscriberDetailCtrl as ctrl',
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      resolve: {
        subscriber: () => angular.copy(subscriber)
      }
    }).then(loadSubscribers, loadSubscribers);
  }

  function deleteSubscriber(subscriber) {
    $modalService.deleteModal({
      title : 'WEBHOOK_REMOVE_TITLE',
      message : 'WEBHOOK_REMOVE_MESSAGE',
    }).then(() => $customerWebhookService.deleteSubscriber(customerId, subscriber.id).$promise.then(loadSubscribers));
  }

  function loadSubscribers() {
    $customerWebhookService.getSubscribers(customerId).$promise.then(subscribers => {
      self.subscribers = subscribers;
    }, () => {
      self.error = 'SUBSCRIBERS_LOAD_FAILED';
    });
  }

  function showUpgradePlanModal() {
    return $modalService.upgradePlanModal({
      title: 'FEATURE_WEBHOOKS',
      message: 'FEATURE_WEBHOOKS_MESSAGE',
      imgSrc: 'https://cdn.moreapp.com/features/en/feature-webhooks.png'
    });
  }
}]);
