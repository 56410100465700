'use strict';

angular.module('Billing').controller('BillingInfoCtrl', ['$scope', '$state', '$modalService', '$customerBillingService', '$billingService', '$countryService', function ($scope, $state, $modalService, $customerBillingService, $billingService, $countryService) {
  const self = this;

  self.billing = $scope.billing;
  self.save = save;
  self.addReference = addReference;
  self.addCoupon = addCoupon;
  self.deleteCoupon = deleteCoupon;
  self.hideVat = false;
  // for ES-specific tax reasons, all companies MUST provide a NIF/VAT. Even if not validated via VIES VAT service
  self.requiredVat = self.billing.stripeAccount === 'moreapp-es';

  init();

  function init() {
    $billingService.getCoupon($state.params.customerId).$promise.then(coupon => {
      self.coupon = coupon;
    });

    $scope.$watch('ctrl.billing.billingAddress.countryCode', setIsEuCountry);
  }

  ////////
  function save () {
    self.saving = true;
    $customerBillingService.updateBilling($state.params.customerId, self.billing).$promise.then(() => {
      self.saving = false;
      $scope.onSave({billing: self.billing});
    }, (error) => {
      self.saving = false;
      if (error.data.message) {
        self.errorMessage = error.data.message;
      } else {
        self.errorMessage = error;
      }
    });
  }

  function addReference () {
    if (!self.billing.invoiceFields) {
      self.billing.invoiceFields = [];
    }
    if (self.billing.invoiceFields.length < 2) {
      self.billing.invoiceFields.push({label : '', value : ''});
    }
  }

  function addCoupon (coupon) {
    $billingService.addCoupon($state.params.customerId, coupon).$promise.then(addedCoupon => {
      self.coupon = addedCoupon;
    }, () => {
      self.couponError = true;
    });
  }

  function deleteCoupon () {
    $modalService.deleteModal({
      title : 'COUPON_REMOVE_TITLE',
      message : 'COUPON_REMOVE_MESSAGE',
    }).then(() => $billingService.deleteCoupon($state.params.customerId).$promise.then(() => self.coupon = undefined));
  }

  function setIsEuCountry(countryCode) {
    if(!countryCode) {
      return;
    }

    $countryService.getCountry(countryCode).$promise.then(({regionalBlocs}) => {
      if(!Array.isArray(regionalBlocs)) {
        self.hideVat = true;
      }else {
        self.hideVat = regionalBlocs.filter(({acronym})=> acronym === 'EU').length === 0;
      }

      if (self.hideVat) {
        self.billing.vatNumber = null;
      }
    });
  }
}]);
