'use strict';
require('./bootstrap');
require('./directives/Compile');
require('./directives/WidgetPlugin');
require('./directives/FieldLabel');
require('./directives/MoreForm');
require('./directives/CustomerResource');
require('./directives/KcdRecompile');
require('./directives/BindJavascript');
require('./directives/FreemarkerTemplate');
require('./directives/PlaceholderImage');

require('./helpers/FreemarkerHelper');
require('./helpers/FormDataHelper');

require('./services/WidgetPluginUtility');
require('./services/FieldInitializer');
require('./services/WidgetStyleService');
