'use strict';

angular.module('UserState').factory('$userColumnConfigService', ['localStorageService', function (localStorageService) {

  var STORAGE_KEY = 'userColumnConfigs';

  var getColumnConfigs = function () {
    try {
      return localStorageService.get(STORAGE_KEY) || {};
    } catch (e) {
      return {};
    }
  };

  return {
    setColumnConfigForForm: function (formId, columns) {
      var config = getColumnConfigs();
      config[formId] = columns;
      localStorageService.add(STORAGE_KEY, config);
    },
    getColumnConfigForForm: function (formId) {
      var config = getColumnConfigs();
      return config[formId];
    }
  };
}]);