'use strict';

angular.module('GroupPicker').directive('groupPicker', ['$state', '$customerGroupsService', '$userStateService', '$rootScope', '$sidePanel', function ($state, $customerGroupsService, $userStateService, $rootScope, $sidePanel) {
  const ALL_USERS_GROUP = {
    id: 'all_users_group',
    name: 'GROUP_PICKER_ALL_USERS'
  };

  return {
    restrict: 'E',
    template: require('../templates/group-picker.html'),
    scope: {
      selectGroupCallback: '&',
      editGroupCallback: '&',
      addGroupCallback: '&'
    },
    link: function (scope) {
      scope.activeGroup = null;
      scope.allUsersGroup = ALL_USERS_GROUP;

      const userState = $userStateService.getState();

      var loadGroups = function (customerId) {
        customerId = customerId || $state.params.customerId;
        $customerGroupsService.getGroups(customerId).then(function (groups) {
          scope.groups = groups;
          const foundGroup = groups.find(group => group.id === userState.groupId);
          scope.selectGroup(foundGroup || ALL_USERS_GROUP);
        });
      };

      loadGroups();

      scope.selectGroup = function (group) {
        scope.activeGroup = group;
        scope.selectGroupCallback({group});
        $userStateService.setGroupId(group.id);
      };

      scope.editGroup = (group, event) => {
        event.stopPropagation();
        scope.editGroupCallback({group});
      };

      scope.manageRoles = function () {
        $sidePanel.open({
          template : require('../../UserManagement/templates/usermanagement.roles.html'),
          controller : 'UserManagementRolesCtrl as ctrl'
        });
      };

      scope.$on('group.added', function (event, newGroup) {
        scope.groups.push(newGroup);
        scope.selectGroup(newGroup);
      });

      scope.$on('group.updated', (event, updatedGroup) => {
        scope.groups = scope.groups.map(group => group.id === updatedGroup.id ? updatedGroup : group);
        scope.selectGroup(updatedGroup);
      });

      scope.$on('group.removed', (event, removedGroup) => {
        scope.groups = scope.groups.filter(group => group.id !== removedGroup.id);
        scope.selectGroup(ALL_USERS_GROUP);
      });

      scope.$watch('activeGroup', function (newVal) {
        $rootScope.pickerItemActive = newVal !== null;
      });

      scope.$on('$destroy', function () {
        $rootScope.pickerItemActive = false;
      });
    }
  };
}]);
