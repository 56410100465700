'use strict';

angular.module('CustomerPortalSettings').controller('SettingsIndexCtrl', ['$scope', '$rootScope', '$usersContextService', '$featureHelper', 'moreConstants', function ($scope, $rootScope, $usersContextService) {
  const self = this;

  self.menuItems = [];

  init();

  ////////
  function init() {
    const showWebhooks = $usersContextService.canManageWebhooks();
    const showApiKeys = $usersContextService.canManageUsers();
    const showSso = $usersContextService.canManageSSO();
    const showSendingDomain = $usersContextService.canManageSendingDomain();
    self.menuItems = [
      {name: 'SETTINGS_TAB_GENERAL', state: 'general'},
      ...(showApiKeys ? [{name: 'SETTINGS_TAB_APIKEYS', state: 'apikeys'}] : []),
      {name: 'SETTINGS_TAB_SERVICE_ACCOUNTS', state: 'service-accounts'},
      ...(showWebhooks ? [{name: 'SETTINGS_TAB_WEBHOOKS', state: 'webhooks'}] : []),
      ...(showSso ? [{name: 'SETTINGS_TAB_SSO', state: 'sso'}] : []),
      ...(showSendingDomain ? [{name: 'SETTINGS_TAB_SENDING_DOMAIN', state: 'sending-domain'}] : []),
    ];
  }
}]);
