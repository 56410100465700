'use strict';

angular.module('MoreAppsServices').factory('$customerFormTemplateService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerForm = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/templates/:formId/:action', {}, {
    publish: { method: 'PATCH' },
    update: { method: 'PATCH' }
  });
  var CustomerFormVersion = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/templates/:formId/versions/:formVersionId/:action', {'customerId' : '@customerId', 'formId' : '@formId', 'formVersionId' : '@formVersionId'}, {
    update: { method: 'PUT' },
    finalize: { method: 'POST', params: { 'action': 'finalize' } },
    copy: { method: 'POST', params: { 'action': 'copy' } }
  });

  var CustomerPdf = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:action', { 'customerId': '@customerId' }, {
    defaultPdfContent: { method: 'POST', params: { 'action': 'defaultPdfContent' } },
  });


  return {
    findGlobal : (customerId, query, language, tags) => {
      const filter = {customerId, scope: 'GLOBAL'};
      if (query) {
        filter.query = query;
      }
      if (language) {
        filter.language = language;
      }
      if (tags && tags.length) {
        filter.tags = tags[0]; // For now use 1 tag, but API already supports multiple tags
      }
      return CustomerForm.query(filter);
    },
    find : (customerId, query, language) => {
      const filter = {customerId};
      if (query) {
        filter.query = query;
      }
      if (language) {
        filter.language = language;
      }
      return CustomerForm.query(filter);
    },
    findForCustomer : (customerId) => CustomerForm.query({customerId}),
    getForm : function (customerId, formId) {
      return CustomerForm.get({customerId : customerId, formId : formId});
    },
    createForm : function (customerId, form) {
      return CustomerForm.save({customerId : customerId}, form);
    },
    trashForm : function (customerId, formId) {
      return CustomerForm.remove({customerId : customerId, formId : formId});
    },
    getFormVersions : function (customerId, formId) {
      return CustomerFormVersion.query({customerId : customerId, formId : formId});
    },
    getFormVersion : function (customerId, formId, formVersionId) {
      return CustomerFormVersion.get({customerId : customerId, formId : formId, formVersionId: formVersionId});
    },
    getDefaultPdf : function (customerId, form, triggerConfiguration) {
      return CustomerPdf.defaultPdfContent({ customerId: customerId }, { form: form, mailTriggerConfiguration: triggerConfiguration });
    },
    createFormVersion : function (customerId, formId, formVersion) {
      return CustomerFormVersion.save({customerId : customerId, formId : formId}, formVersion);
    },
    saveFormVersion : function (customerId, formId, formVersionId, formVersion) {
      return CustomerFormVersion.update({customerId : customerId, formId : formId, formVersionId: formVersionId}, formVersion);
    },
    finalizeFormVersion : function (customerId, formId, formVersionId) {
      return CustomerFormVersion.finalize({customerId : customerId, formId : formId, formVersionId: formVersionId});
    },
    publishFormVersion : function (customerId, formId, formVersionId, iconColor) {
      return CustomerForm.publish({customerId : customerId, formId : formId },{ publishedVersion: { formVersion: formVersionId }, meta: { iconColor } });
    },
    copyFormVersion : function(customerId, formId, formVersionId, newFolderId, newFormName, newCustomerId) {
      return CustomerFormVersion.copy({customerId, formId, formVersionId }, { folderId: newFolderId, formName: newFormName, customerId: newCustomerId });
    },
    updateName(customerId, formId, name) {
      return CustomerForm.update({customerId, formId}, {meta: {name}});
    },
    updateDescription(customerId, formId, description) {
      return CustomerForm.update({customerId, formId}, {meta: {description}});
    },
    updateIcon(customerId, formId, icon) {
      return CustomerForm.update({customerId, formId}, {meta: {icon}});
    },
    updateTags(customerId, formId, tags) {
      return CustomerForm.update({customerId, formId}, {meta: {tags}});
    },
    updateLanguage(customerId, formId, language) {
      return CustomerForm.update({customerId, formId}, {meta: {language}});
    },
    updateScope(customerId, formId, scope) {
      return CustomerForm.update({customerId, formId, action: 'scope'}, {scope});
    },
    getSelectableTags() {
      return ['GENERIC', 'FACILITY', 'CONSTRUCTION', 'SALES', 'HR', 'AUTOMOTIVE', 'SAFETY', 'LOGISTICS', 'INSTALLATION', 'MAINTENANCE', 'SECURITY', 'REAL_ESTATE', 'HEALTHCARE', 'AGRICULTURE', 'INDUSTRY'];
    }
  };
}]);
