'use strict';

angular.module('customerPortal').factory('$emailValidationService', ['$resource', 'moreConstants', function ($resource, moreConstants) {

  const EmailValidation = $resource(moreConstants.apiEndpoint + '/email-validation/', {});
  const EmailTokenValidation = $resource(moreConstants.apiEndpointV2 + '/email-validation/', {});

  return {
    check: function (token) {
      return EmailValidation.save({ token: token }, { token: token });
    },
    checkToken: (token) => EmailTokenValidation.save({ token })
  };
}]);
