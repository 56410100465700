'use strict';

angular.module('DataSources').directive('datasourceEntrySearch', ['$customerDataSourceService', function ($customerDataSourceService) {
  return {
    restrict: 'EA',
    template: require('../../../shared/templates/directives/data-source-entry-search.html'),
    scope: {
      customerId: '=',
      ngModel: '=',
      config: '=',
      indexesCache: '='
    },
    controller: ['$scope', function ($scope) {
      $scope.dataSourceConfig = $scope.config;
      $scope._tmp = {};
      $scope.loadingEntries = false;

      $scope.clickSearch = function () {
        if ($scope.dataSourceConfig && !$scope.entries && !$scope.loadingEntries) {
          var mapping = $scope.dataSourceConfig.mapping;
          var dataSourceId = $scope.dataSourceConfig.id;
          var cachedEntries = getCachedEntriesForConfig($scope.dataSourceConfig);
          if (cachedEntries) {
            $scope.entries = cachedEntries;
            return;
          }
          $scope.loadingEntries = true;
          $customerDataSourceService.getIndexedDataSourceEntries($scope.customerId, dataSourceId, mapping).$promise.then(function (indexedEntries) {
            $scope.entries = indexedEntries;
            $scope.loadingEntries = false;
            $scope.indexesCache.push({ id: dataSourceId, mapping: mapping, entries: indexedEntries });
          });
        }
      };

      $scope.$watch('_tmp.selectedEntryId', function (newVal) {
        if (newVal) {
          var filter = { pageSize: 1, sort: [], idSelection: [newVal] };
          $customerDataSourceService.filterDataSourceEntries($scope.customerId, $scope.dataSourceConfig.id, 0, filter).$promise.then(function (result) {
            $scope.ngModel = result[0].data;
          });
        }
      });

      ////////
      function getCachedEntriesForConfig(dataSourceConfig) {
        var found = $scope.indexesCache.filter(function (index) {
          return dataSourceConfig.id === index.id && angular.equals(index.mapping, dataSourceConfig.mapping);
        });
        if (found.length > 0) {
          return found[0].entries;
        }
      }
    }]
  };
}]);