'use strict';

angular.module('WidgetPropertyPane').directive('urlOrResourceProperty', function () {
  return {
    restrict: 'A',
    scope: true,
    link: function (scope) {

      scope.resourceCallback = function (type, resourceId) {
        scope.formFieldProperties[scope.widgetProperty.key] = {
          type: type,
          value: resourceId
        };
      };
    }
  };
});