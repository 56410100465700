'use strict';

angular.module('MoreAppsServices').factory('$customerGroupsService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const CustomerGroups = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/groups/:groupId', {}, {
    patch: {
      method: 'PATCH'
    }
  });
  const CustomerGroupUsers = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/groups/:groupId/users/:userId', {}, {});
  const CustomerGroupGrants = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/groups/:groupId/grants', {}, {
    patch: {
      method: 'PATCH',
      isArray: true
    },
  });

  return {
    getGroups: customerId => CustomerGroups.query({customerId}).$promise,
    getUsers: (customerId, groupId) => CustomerGroupUsers.query({customerId, groupId}).$promise,
    addUser: (customerId, groupId, userId) => CustomerGroupUsers.save({customerId, groupId, userId}, {}).$promise,
    removeUser: (customerId, groupId, userId) => CustomerGroupUsers.delete({customerId, groupId, userId}).$promise,
    create: (customerId, group) => CustomerGroups.save({customerId}, group).$promise,
    patch: (customerId, group) => CustomerGroups.patch({customerId, groupId: group.id}, group).$promise,
    removeGroup: (customerId, groupId) => CustomerGroups.delete({customerId, groupId}).$promise,
    patchGrant: (customerId, groupId, grantChange) => CustomerGroupGrants.patch({customerId, groupId}, grantChange).$promise
  };
}]);
