'use strict';

angular.module('customerPortal').directive('manageRolePermission', ['$state', '$customerRolesService', '$modalService', function ($state, $customerRolesService, $modalService) {

  return {
    restrict: 'E',
    template: require('../templates/directives/manage-roles-permissions.html'),
    scope: {
      permission: '=',
      role: '=',
      isAllowed: '='
    },
    link: function (scope) {
      const hasAdvancedUserManagementFeature = scope.isAllowed || false;

      scope.addPermission = (role, permission, $event) => {
        if (verifyFeature($event)) {
          role.permissions.push(permission);
          return $customerRolesService.patchRole($state.params.customerId, role.id, {permissions: role.permissions});
        }
      };

      scope.removePermission = (role, permission, $event) => {
        if (verifyFeature($event)) {
          role.permissions = role.permissions.filter(x => x !== permission);
          return $customerRolesService.patchRole($state.params.customerId, role.id, {permissions: role.permissions});
        }
      };

      function verifyFeature($event) {
        if (hasAdvancedUserManagementFeature) {
          return true;
        }
        $event.preventDefault();
        $modalService.upgradePlanModal({
          title: 'FEATURE_ADVANCED_USER_MANAGEMENT',
          message: 'FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE',
          imgSrc: 'https://cdn.moreapp.com/features/en/feature-advanced-user-management-new.png'
        });
      }
    }
  };
}]);
