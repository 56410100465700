'use strict';

angular.module('FormEditor').directive('emailList', function () {
  return {
    restrict: 'A',
    scope: {},
    require: 'ngModel',
    link: function (scope, element, attr, ctrl) {
      //Pre-defined seperators
      var separators = new RegExp('[,; ]');

      var parse = function (viewValue) {
        // If the viewValue is invalid (say required but empty) it will be `undefined`
        if (viewValue === undefined) {
          return;
        }

        var list = [];

        if (viewValue) {
          angular.forEach(viewValue.split(separators), function (value) {
            if (value) {
              list.push($.trim(value));
            }
          });
        }

        return list;
      };

      ctrl.$parsers.push(parse);
      ctrl.$formatters.push(function (value) {
        if (angular.isArray(value)) {
          return value.join(', ');
        }

        return undefined;
      });
    }
  };
});