'use strict';

angular.module('StateConstants', []);
angular.module('StateConstants').constant('stateConfig', {
  defaultAccess: ['MANAGE_CREDITS', 'MANAGE_DATA_SOURCES', 'MANAGE_FORMS', 'MANAGE_TASKS', 'MANAGE_REGISTRATIONS', 'MANAGE_USERS', 'MANAGE_SSO', 'READ_SUBMISSION', 'UPDATE_SUBMISSION', 'DELETE_SUBMISSION', 'READ_REGISTRATION', 'DELETE_REGISTRATION', 'CREATE_TASK', 'READ_TASK', 'UPDATE_TASK', 'DELETE_TASK', 'CREATE_USER', 'READ_USER', 'UPDATE_USER', 'DELETE_USER', 'CREATE_FORM', 'UPDATE_FORM', 'ARCHIVE_FORM', 'RESTORE_FORM', 'ADD_FORM', 'REMOVE_FORM', 'CREATE_FOLDER', 'UPDATE_FOLDER', 'DELETE_FOLDER', 'READ_DATA_SOURCE', 'CREATE_DATA_SOURCE', 'UPDATE_DATA_SOURCE', 'DELETE_DATA_SOURCE', 'READ_RESOURCE', 'READ_WEBHOOK', 'CREATE_WEBHOOK', 'UPDATE_WEBHOOK', 'DELETE_WEBHOOK', 'READ_THEME', 'CREATE_THEME', 'UPDATE_THEME', 'DELETE_THEME'],
  unauthenticatedPaths: ['/signup', '/email-validation', '/email-validated', '/onboarding', '/onboarding-success']
});

angular.module('customerPortal').config(['$stateProvider', '$moreResourceModalStateProvider', 'stateConfig', function ($stateProvider, $moreResourceModalStateProvider, stateConfig) {

  $stateProvider.state('portal', {
    url: '/',
    template: '<div data-ui-view=""></div>',
    controller: 'PortalCtrl',
    access: stateConfig.defaultAccess
  }).state('portal.error', {
    url: 'error',
    template: require('../../shared/templates/error/error.html'),
    access: []
  }).state('portal.insufficient_permissions', {
    url: 'insufficient-permissions',
    template: require('../templates/portal/insufficient_permissions.html')
  }).state('admin-login', {
    url: '/admin',
    template: require('../../modules/Impersonation/templates/admin-login.html'),
    access: ['ADMIN'],
    data: {
      entryView: true
    }
  }).state('portal.customers', {
    url: 'customers/:customerId',
    controller: 'PortalCustomersCtrl',
    resolve: {
      currentCustomer: ['$stateParams', '$customerService', function ($stateParams, $customerService) {
        return $customerService.getCustomer($stateParams.customerId).$promise;
      }],
      billingStatus: ['$stateParams', '$billingService', function($stateParams, $billingService) {
        return $billingService.getStatus($stateParams.customerId).$promise;
      }]
    },
    template: require('../templates/portal.html'),
    access: stateConfig.defaultAccess
  });
}]);
