'use strict';

angular.module('FormEditor').controller('DeleteWidgetFavoriteCtrl', ['$rootScope', '$scope', '$state', '$widgetService', function ($rootScope, $scope, $state, $widgetService) {

  $scope.$state = $state;

  $widgetService.byUid($state.params.widgetUid).then(function (data) {
    $scope.widget = data;
  });

  $scope.favoriteWidget = $widgetService.getFavorite($state.params.widgetId);

  $scope.deleteFavorite = function () {
    $widgetService.deleteFavoriteWidget($scope.favoriteWidget.id).$promise.then(function () {
      $rootScope.$broadcast('deletedFavorite');
      $scope.$close();
    });
  };
}]);