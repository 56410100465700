'use strict';

angular.module('WidgetPropertyPane').controller('WidgetPropertyPaneCtrl', ['$scope', '$rootScope', 'formVersion', 'deployed', 'focusedFormField', 'focusedWidget', 'targetView', '$moreDataNameService', 'callbacks', 'knownFieldUids', 'disableFavorites', '$formVersionUtilityService', '$timeout', '$widgetService', '$uibModal', function ($scope, $rootScope, formVersion, deployed, focusedFormField, focusedWidget, targetView, $moreDataNameService, callbacks, knownFieldUids, disableFavorites, $formVersionUtilityService, $timeout, $widgetService, $uibModal) {

  var self = this;

  $scope.state = {
    advancedMode: false
  };
  $scope.formVersion = formVersion;
  $scope.focusedFormField = focusedFormField;
  $scope.focusedWidget = focusedWidget;
  $scope.targetView = targetView;
  $scope.deployed = deployed;
  $scope.disableFavorites = disableFavorites || false;

  $scope.requiredWidgetProperty = {
    type: 'boolean',
    key: 'required',
    name: 'Required',
    nameKey: 'WIDGET_PROPERTY_REQUIRED',
    defaultValue: true,
    required: true,
    description: 'If checked, this field must be filled to be able to save the form',
    descriptionKey: 'WIDGET_PROPERTY_REQUIRED_DESCRIPTION'
  };

  $scope.rememberInputWidgetProperty = {
    type: 'boolean',
    key: 'remember_input',
    name: 'Remember input',
    nameKey: 'WIDGET_PROPERTY_REMEMBER_INPUT',
    defaultValue: false,
    required: false,
    description: 'Check this to remember the last input of the user on that device for the next registration. If you have set a default value as well, remember input will not function',
    descriptionKey: 'WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION'
  };

  $scope.getFields = function () {
    var fields = $scope.targetView.fields || $scope.targetView.items || [];
    return fields;
  };

  $scope.updateDataNameDuplicateWarning = function () {
    $scope.showDuplicateWarning = focusedFormField.properties.data_name && $moreDataNameService.isDataNameDuplicated($scope.getFields(), focusedFormField);
  };

  $scope.updateDataName = function (oldValue, newValue) {
    if (!$scope.focusedFormField) {
      return;
    }
    var properties = $scope.focusedFormField.properties;
    if (properties.hasOwnProperty('data_name') === false) {
      return;
    }

    var oldDataName = $moreDataNameService.asDataName(oldValue);
    if ($moreDataNameService.matchesDataName(properties, oldDataName)) {
      $scope.focusedFormField.properties.data_name = $moreDataNameService.asDataName(newValue);

      properties.data_name = $moreDataNameService.uniquify($scope.getFields(), $scope.focusedFormField);
      $scope.showDuplicateWarning = false;
    }
    if (!properties.data_name) {
      $scope.state.advancedMode = true;
    }
  };

  $scope.createFavorite = function (field, widget) {
    $uibModal.open({
      template : require('../../../shared/templates/builder/formeditor.widgets.favorites.html'),
      controller : 'WidgetFavoriteCtrl',
      resolve : {
        formVersion : [() => {
          return formVersion;
        }],
        widget : [() => {
          return widget;
        }],
        field : [() => {
          return field;
        }]
      }
    });
  };

  $scope.widgetProducesData = function () {
    if (!$scope.focusedWidget) {
      return null;
    }
    return $scope.focusedWidget.definition.data;
  };

  if (!deployed || !knownFieldUids || knownFieldUids.indexOf(focusedFormField.uid) === -1) {
    $scope.$watch('focusedFormField.properties.label_text', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        $scope.updateDataName(oldValue, newValue);
      }
    }, true);

    $scope.$watch('focusedFormField.properties.text_placeholder', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        $scope.updateDataName(oldValue, newValue);
      }
    }, true);
  }

  init();

  /////////////////
  function init() {
    $scope.viewFields = $formVersionUtilityService.expandFields($scope.targetView);
    $widgetService.enrichFieldsWithWidgetInfo($scope.viewFields);

    $scope.updateDataNameDuplicateWarning();

    startValidationWatcher();

    $scope.$watch('focusedFormField.properties', $scope.focusedFormField.$$recompile, true);

    $scope.$on('ADD_SUB_FORM_REQUEST', function ($event, data) {
      var formFieldProperties = data.formFieldProperties;
      var property = data.property;
      callbacks.addSubForm(formFieldProperties, property, function (viewId) {
        formFieldProperties[property] = viewId;
        $scope.$dismiss();
      });
    });

    $scope.$on('NAVIGATE_FORM_REQUEST', function ($event, viewId) {
      callbacks.openDetail(viewId, function () {
        $scope.$dismiss();
      });
    });

    $scope.$on('NAVIGATE_SUBFORM_REQUEST', function ($event, targetSubForm) {
      callbacks.openDetail(targetSubForm, function () {
        $scope.$dismiss();
      });
    });
  }

  function startValidationWatcher() {
    $timeout(function () {
      self.form.$setDirty();
      $scope.$broadcast('show-errors-check-validity');
      $scope.$watch('targetView', function () {
        $scope.$broadcast('show-errors-check-validity');
        $rootScope.$broadcast('more.field.validated', {
          valid: self.form.$valid,
          fieldId: $scope.focusedFormField.uid
        });
      }, true);
    }, 1);
  }
}]);
