'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', '$moreResourceModalStateProvider', function ($stateProvider, stateConfig, $moreResourceModalStateProvider) {

  $stateProvider.state('portal.customers.settings', {
    url: '/settings',
    template: require('../../modules/CustomerPortalSettings/templates/settings.index.html'),
    abstract: true,
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.general', {
    url: '/general',
    data: {
      viewContentTemplate: require('../../modules/CustomerPortalSettings/templates/settings.general.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.service-accounts', {
    url: '/service-accounts',
    data: {
      viewContentTemplate: require('../../modules/CustomerPortalSettings/templates/settings.service-accounts.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.webhooks', {
    url: '/webhooks',
    data: {
      viewContentTemplate: require('../../modules/CustomerPortalSettings/templates/settings.webhooks.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.apikeys', {
    url: '/apikeys',
    data: {
      viewContentTemplate: require('../../modules/CustomerPortalSettings/templates/settings.apikeys.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.sso', {
    url: '/sso',
    data: {
      viewContentTemplate: require('../../modules/CustomerPortalSettings/templates/settings.sso.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.sending-domain', {
    url: '/sending-domain',
    data: {
      viewContentTemplate: require('../../modules/CustomerPortalSettings/templates/settings.sending-domain.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.settings.general.moreResource', $moreResourceModalStateProvider.$get());
}]);
