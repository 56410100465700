'use strict';

angular.module('MoreDirectives').directive('moreUrlProperty', ['$uibModal', function ($uibModal) {
  return {
    restrict: 'A',
    scope: {
      callback: '&',
      initialValue: '=',
      defaultValues: '=',
      headerTranslation: '@',
      buttonTranslation: '@'
    },
    template: require('../../../shared/templates/directives/more-url-property.html'),
    link: function (scope) {

      scope.selectUrl = function () {
        $uibModal.open({
          template: require('../../FormEditor/templates/urlselect.modal.html'),
          resolve: {
            initialValue: function () {
              return scope.initialValue;
            },
            defaultValues: function () {
              return scope.defaultValues;
            },
            headerTranslation: function () {
              return scope.headerTranslation;
            }
          },
          controller: 'UrlSelectModalCtrl'
        }).result.then(function (result) {
          if (result.success) {
            scope.callback({ resourceId: result.value });
          }
        });
      };
    }
  };
}]);
