'use strict';

angular.module('DataSources').directive('datasourceUrlConfiguration', ['moreConstants', '$modalService', '$featureHelper',  function (moreConstants, $modalService, $featureHelper) {
  return {
    restrict: 'E',
    template: require('../templates/directives/datasource-url-configuration.html'),
    scope: {
      ngModel: '='
    },
    link: function (scope) {
      scope.ngModel = scope.ngModel || { updateInterval: 'NEVER', requestHeaders: {}, parameters: {} };
      scope.authentication = scope.ngModel.credentials !== null && scope.ngModel.credentials !== undefined;

      init();

      ////////
      function init() {
        $featureHelper.hasFeature(moreConstants.AUTOMATIC_DATASOURCES).then(hasFeature => {
          scope.hasAutomaticDataSourcesFeature = hasFeature;
          scope.$watch('ngModel.updateInterval', (newVal, oldVal) => {
            if (!scope.hasAutomaticDataSourcesFeature && newVal && newVal !== 'NEVER') {
              scope.ngModel.updateInterval = oldVal;
              $modalService.upgradePlanModal({
                title: 'FEATURE_AUTOMATIC_DATASOURCES',
                message: 'FEATURE_AUTOMATIC_DATASOURCES_MESSAGE',
                imgSrc: 'https://cdn.moreapp.com/features/en/feature-automatic-data-import.png'
              }).then(scope.$parent.$close); // close Sidepanel
            }
          });
        });

        if (scope.ngModel.parameters) {
          scope.items = Object.keys(scope.ngModel.parameters).map((key) => {
            return {
              key: key,
              value: scope.ngModel.parameters[key]
            };
          });
        }

        if (scope.ngModel.requestHeaders) {
          scope.requestHeaders = Object.keys(scope.ngModel.requestHeaders).map((key) => {
            return {
              key: key,
              value: scope.ngModel.requestHeaders[key]
            };
          });
        }

        scope.$watch('items', (newValue) => {
          var newParameters = {};
          newValue.forEach((item) => {
            if (item.key && item.value) {
              newParameters[item.key] = item.value;
            }
          });
          scope.ngModel.parameters = newParameters;
        }, true);

        scope.$watch('requestHeaders', (newValue) => {
          const newRequestHeaders = {};
          newValue.forEach((item) => {
            if (item.key && item.value) {
              newRequestHeaders[item.key] = item.value;
            }
          });
          scope.ngModel.requestHeaders = newRequestHeaders;
        }, true);
      }

      scope.addParameter = () => {
        scope.items.push({});
      };

      scope.addRequestHeader = () => {
        scope.requestHeaders.push({});
      };

      scope.removeParameter = (index) => {
        scope.items.splice(index, 1);
      };

      scope.removeRequestHeader = (index) => {
        scope.requestHeaders.splice(index, 1);
      };
    }
  };
}]);