'use strict';

angular.module('Activation').controller('ActivateSuccessCtrl', ['$scope', '$state', '$mobileService', '$featuresService', '$inviteService', '$stateParams', 'moreConstants', function ($scope, $state, $mobileService, $featuresService, $inviteService, $stateParams, moreConstants) {

  var self = this;
  self.isMobileDevice = isMobileDevice;
  self.showAppLinks = showAppLinks;
  self.moreIosDownloadLink = null;
  self.moreAndroidDownloadLink = null;
  self.webclientUrl = null;

  init();

  ///////
  function init() {
    $scope.features.$promise.then(function (features) {
      self.moreIosDownloadLink = features.urls.ios;
      self.moreAndroidDownloadLink = features.urls.android;
      if (features.urls.webclient === 'https://app.moreapp.com') {
        self.webclientUrl = moreConstants.webClientUrl; // override with localhost/develop/staging links
      } else {
        self.webclientUrl = features.urls.webclient;
      }
    });
  }

  function isMobileDevice() {
    return $mobileService.isMobileDevice();
  }

  function showAppLinks() {
    return !!$featuresService.getFeatures().showAppLinks;
  }
}]);
