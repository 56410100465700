'use strict';

angular.module('FormEditor').directive('integrationPanel', ['$state', '$timeout', '$translate', '$integrationService', '$integrationEolUtils', function ($state, $timeout, $translate, $integrationService, $integrationEolUtils) {

  return {
    restrict: 'E',
    template: require('../templates/directives/integration-panel.html'),
    scope: {
      integration: '=',
      integrationConfiguration: '=',
      removeIntegrationConfiguration: '&',
      integrationInfo: '=',
      formVersion: '=',
      formVersionFields: '='
    },
    controllerAs: 'ctrl',
    bindToController: true,
    controller: ['$scope', function ($scope) {
      var self = this;

      init();

      function init() {
        resetValues();

        self.integration.$promise.then(function (integration) {
          self.isEol = $integrationEolUtils.isEolIntegration(integration);

          // migrate to newer version
          if (integration.info.version > self.integrationConfiguration.version) {
            self.integrationConfiguration.version = integration.info.version;
            $scope.$broadcast('show-errors-check-validity');
          }

          // save non-configurable integrations immediately. show save when error occurs
          if (!integration.definition.configuration || !integration.definition.configuration.length) {
            self.configurable = false;
            validate(self.integrationConfiguration);
          } else {
            self.configurable = true;
          }
        });
      }

      self.validate = validate;

      $scope.$watch('ctrl.integrationConfiguration.$$isOpen', function (newVal) {
        if (newVal) {
          init();
        }
      });

      ////
      function resetValues() {
        self.globalError = null;
        self.validating = false;
        self.valid = null;
        self.showNotValidated = false;
      }

      function validate(integrationConfiguration) {
        resetValues();
        var view = angular.copy(self.formVersion);
        view.type = 'form';
        view.dslVersion = 2;
        view.uid = view.id;

        var request = { view: view, configuration: integrationConfiguration };
        $integrationService.validateIntegrationConfiguration($state.params.customerId, $state.params.formId, request).$promise.then(function testIntegrationSuccess(response) {
          self.validating = false;
          removeFieldErrorsFromForm();
          $scope.integrationForm.$setPristine();
          $scope.$broadcast('show-errors-reset');

          switch (response.status) {
            case 'VALID':
              self.valid = true;
              break;
            case 'INVALID':
              putFieldErrorsOnForm(response.fieldErrors);
              self.globalError = response.globalError;
              break;
            case 'NOT_VALIDATED':
              self.showNotValidated = true;
              break;
            default:
              self.globalError = response.globalError || $translate.instant('FORM_SUBMISSION_SERVER_ERROR');
          }
        }, saveIntegrationError);
      }

      function saveIntegrationError() {
        self.globalError = $translate.instant('FORM_SUBMISSION_SERVER_ERROR');
      }

      function putFieldErrorsOnForm(fieldErrors) {
        for (var key in fieldErrors) {
          if (fieldErrors.hasOwnProperty(key)) {
            if ($scope.integrationForm[key] === undefined) {
              $scope.integrationForm[key] = { $error: {} };
            }
            $scope.integrationForm[key].$error.custom = fieldErrors[key];
          }
        }
      }

      function removeFieldErrorsFromForm() {
        for (var key in $scope.integrationForm) {
          if ($scope.integrationForm.hasOwnProperty(key) && $scope.integrationForm[key] && $scope.integrationForm[key].$error) {
            $scope.integrationForm[key].$error = {};
          }
        }
      }
    }]
  };
}]);