'use strict';

angular.module('UsageCharts', ['highcharts-ng', 'MoreAppsServices']);

angular.module('UsageCharts').factory('$chartConfigFactory', ['$translate', function ($translate) {

  var sampleChartConfig = {
    options: {
      chart: {
        type: 'column'
      },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '<div style="color: white"><p>{point.key}</p>',
        pointFormat: '<p>' + $translate.instant('CUSTOMER_USAGE_TOTAL_FORMS_SENT') + ': {point.y}</p>',
        footerFormat: '</div>',
        valueDecimals: 0,
        backgroundColor: '#4b4b4b'
      },
      legend: { enabled: false },
      title: {
        text: ''
      }
    },
    series: [],
    loading: false,
    xAxis: {
      categories: null
    },
    yAxis: {
      labels: {
        enabled: false
      },
      min: 0,
      gridLineWidth: 0,
      title: {
        text: '',
        align: 'high'
      }
    }
  };

  return {
    createChartConfig: function (xAxisLabels) {
      var copy = angular.copy(sampleChartConfig);
      copy.xAxis.categories = xAxisLabels;
      return copy;
    }
  };
}]);

angular.module('UsageCharts').factory('$monthlyUsageChartService', ['$chartConfigFactory', function ($chartConfigFactory) {

  var sampleSeries = {
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    'color': '#eee',
    'borderColor': '#bbb',
    states: {
      hover: {
        color: '#1dc4c2'
      }
    }
  };

  return {
    getChartConfig: function () {
      return $chartConfigFactory.createChartConfig(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
    },
    setMonthData: function (chartConfig, dataPromise) {
      chartConfig.loading = true;
      dataPromise.then(function (usage) {
        var copy = angular.copy(sampleSeries);
        for (var i = 0; i < usage.months.length; i++) {
          copy.data[usage.months[i].month - 1] = usage.months[i].count;
        }
        chartConfig.series = [copy];
        chartConfig.loading = false;
      });
    }
  };
}]);

angular.module('UsageCharts').factory('$dailyUsageChartService', ['$chartConfigFactory', function ($chartConfigFactory) {

  var createLabels = function () {
    var answer = [];
    for (var i = 29; i >= 0; i--) {
      var date = new Date();
      date.setDate(date.getDate() - i);
      answer.push(date.getMonth() + 1 + '/' + date.getDate());
    }
    return answer;
  };

  var sampleSeries = {
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    'color': '#eee',
    'borderColor': '#bbb',
    states: {
      hover: {
        color: '#1dc4c2'
      }
    }
  };

  function daydiff(first, second) {
    return Math.floor(second / (1000 * 60 * 60 * 24)) - Math.floor(first / (1000 * 60 * 60 * 24));
  }

  return {
    getChartConfig: function (dailyData) {
      var chartConfig = $chartConfigFactory.createChartConfig(createLabels());
      var copy = angular.copy(sampleSeries);

      for (var i = 0; i < dailyData.days.length; i++) {
        var day = dailyData.days[i];
        var daysBefore = daydiff(day.dayTimestamp, new Date().getTime());
        var index = copy.data.length - 1 - daysBefore;
        copy.data[index] = day.count;
      }
      chartConfig.loading = false;
      chartConfig.series = [copy];
      return chartConfig;
    }
  };
}]);
