'use strict';

require('./bootstrap');
require('./helpers/BarcodeHelper');
require('./helpers/CanvasHelper');
require('./helpers/DateHelper');
require('./helpers/FileHelper');
require('./helpers/PhotoHelper');
require('./helpers/PopupHelper');
require('./helpers/SearchHelper');
require('./helpers/SubFormHelper');
require('./helpers/ResourceHelper');
require('./helpers/LocationHelper');
require('./helpers/PinHelper');
require('./helpers/ModalHelper');
require('./helpers/ActionSheetHelper');
require('./helpers/LoadingHelper');
require('./helpers/PaymentHelper');
require('./helpers/UUIDHelper');
require('./helpers/DrawingHelper');
require('./helpers/VideoHelper');

require('./services/FileSystemService');
require('./services/UserInfoService');

require('./directives/ClearButton');
require('./directives/RegistrationFileImg');