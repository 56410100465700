'use strict';

angular.module('MoreGrid').directive('moreGridObjectDataCell', ['$q', '$modalService', '$stringUtils', function ($q, $modalService, $stringUtils) {
  return {
    restrict: 'E',
    template: require('../templates/more-grid-object-data-cell.html'),
    scope: {
      ngModel: '='
    },
    link: function (scope) {
      scope.showDetails = function () {
        $modalService.open({
          size: 'lg',
          controller: ['$scope', function ($scope) {
            $scope.data = scope.ngModel;
            $scope.columns = buildColumns($scope.data);
            $scope.listedObjects = function () {
              var deferred = $q.defer();
              if (angular.isArray($scope.data)) {
                deferred.resolve({
                  listedObjects: $scope.data,
                  totalItems: 1
                });
              }
              deferred.resolve({
                listedObjects: [$scope.data],
                totalItems: 1
              });
              return deferred.promise;
            };
          }],
          template: require('../templates/more-grid-object-data-cell.modal.html')
        });
      };
    }
  };

  ////////
  function buildColumns(data) {
    let headerKeys = Object.keys(data);
    if (angular.isArray(data)) {
      const keysArray = data.map(data => {
        return Object.keys(data);
      });
      // Don't ask, this is the only way IE11 supports Sets
      let set = new Set();
      const array = Array.prototype.concat.apply([], keysArray);
      array.forEach(value => {
        set.add(value);
      });
      headerKeys = Array.from(set);
    }
    return headerKeys.filter(function (key) {
      return key.indexOf('$') !== 0;
    }).map(function (key) {
      return { id: key, name: $stringUtils.humanize(key), $selected: true };
    });
  }
}]);