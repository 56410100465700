'use strict';

angular.module('DynamicForms').factory('$dynamicFormService', ['$sidePanel', function ($sidePanel) {

  return {
    show: function (formDefinition, config) {
      $sidePanel.open({
        template: require('../templates/form.html'),
        controller: 'DynamicFormCtrl as ctrl',
        resolve: {
          definition: function () {
            return formDefinition;
          },
          config: function () {
            return config;
          }
        }
      });
    }
  };
}]);