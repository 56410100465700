'use strict';

angular.module('FormManagement.tasks').controller('FormManagementTasksCtrl', ['$formTaskService', '$paginatorFactory', '$scope', '$sidePanel', '$translate', '$customerUserService', '$simpleFilterService', '$timeout', function ($formTaskService, $paginatorFactory, $scope, $sidePanel, $translate, $customerUserService, $simpleFilterService, $timeout) {
  var self = this;
  var customerId = $scope.$state.params.customerId;
  var usernames = {};

  self.getTasks = getTasks;
  self.viewTask = viewTask;
  self.addTask = addTask;
  self.toggleSearch = toggleSearch;
  self.hasMoreTasks = true;
  self.searchEnabled = false;
  self.tasks = null;
  self.searchEnabled = false;
  self.hasTasks = false;
  self.filter = {
    currentPage: 0,
    pageSize: 10,
    sortInfo: {
      directions: [-1],
      fields: ['dates.creationDate']
    },
    query: {}
  };

  init();

  ////////
  function init() {
    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', function () {
      $timeout(() => $scope.$destroy(), 0);
    });

    $customerUserService.getUsers(customerId).$promise.then(function (users) {
      users.forEach(function (user) {
        usernames[user.id] = {
          username: user.username
        };
      });
    });

    reloadColumns();

    $scope.$watch('formVersion', function (formVersion) {
      if (formVersion && formVersion.formId) {
        self.hasTasks = false;
        getTasks(self.filter);
      }
    });

    $scope.$on('INSTRUCTION_REVOKED', function (event, taskId) {
      updateTaskToStatus(taskId, 'REVOKED');
    });
    $scope.$on('INSTRUCTION_COMPLETED', function (event, taskId) {
      updateTaskToStatus(taskId, 'COMPLETED');
    });
  }

  function toggleSearch() {
    self.searchEnabled = !self.searchEnabled;
    if (!self.searchEnabled) {
      $scope.$broadcast('paginatedmoregrid.clear_search');
    }
  }

  function reloadColumns() {
    self.columns = [];
    self.columns.push({ id: 'status',
      name: $translate.instant('STATUS'),
      $selected: true,
      type: 'custom',
      filterTemplate: require('../templates/moreGridTemplates/tasks.status.filter.html'),
      customTemplate: require('../templates/moreGridTemplates/tasks.status.html')
    });
    self.columns.push({
      id: 'usernames',
      name: $translate.instant('USER'),
      $selected: true,
      customTemplate: require('../templates/moreGridTemplates/tasks.user.html'),
      searchable: false
    });
    self.columns.push({ id: 'dates.creationDate', name: $translate.instant('CREATED'), $selected: true, type: 'date', asTimestamp: true });
    self.columns.push({ id: 'dates.publishDate', name: $translate.instant('TASKS_PUBLISH_DATE'), $selected: true, type: 'date', asTimestamp: true });
    self.columns.push({ id: 'message', name: $translate.instant('TASKS_MESSAGE'), $selected: true, type: 'string', customClass: 'task-message' });
    self.selectedColumns = self.columns;
  }

  function getTasks(filter) {
    var simpleFilter = $simpleFilterService.gridFilterToSimpleFilter(filter);
    return $formTaskService.filterTasks(customerId, $scope.formVersion.formId, filter.currentPage, simpleFilter).$promise.then(function (result) {
      var tasks = result.elements || [];
      self.hasTasks = self.hasTasks || result.totalSize > 0; // set once, so filtering without results doesn't show the message
      self.tasks = tasks;
      tasks.forEach(function (task) {
        task.usernames = task.users.filter(isActiveUser).map(function (user) {
          return usernames[user].username;
        });
        return task;
      });
      return {
        listedObjects: tasks,
        totalItems: result.totalSize
      };
    });
  }

  function isActiveUser(userId) {
    return usernames[userId] !== undefined;
  }

  function viewTask(task) {
    $sidePanel.open({
      template: require('../templates/formmanagement.tasks.detail.html'),
      resolve: {
        task: function () {
          return $formTaskService.getTask(customerId, $scope.form.id, task.id);
        },
        formId: function () {
          return $scope.form.id;
        }
      },
      controller: 'FormManagementTaskDetailCtrl'
    });
  }

  function updateTaskToStatus(taskId, status) {
    self.tasks.forEach(function (task) {
      if (task.id === taskId) {
        task.status = status;
      }
    });
  }

  function addTask() {
    $scope.$state.transitionTo($scope.$state.current.name + '.add', angular.copy($scope.$state.params));
  }
}]);
