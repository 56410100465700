'use strict';

require('./bootstrap');

require('./services/WidgetPropertyPaneService');
require('./controllers/WidgetPropertyPaneCtrl');

require('./directives/DataSourceConfiguration');
require('./directives/ListedProperty');
require('./directives/OptionsProperty');
require('./directives/OptionSubsetProperty');
require('./directives/UrlOrResourceProperty');
require('./directives/WidgetProperty');
require('./directives/FieldPropertyConfiguration');