'use strict';

angular.module('more.ui.form-builder').factory('$subFormHelper', function () {

  var callbackHandler = null;
  return {
    setCallbackHandler: function (handler) {
      callbackHandler = handler;
    },
    handler: function (field, collection) {
      return {
        open: function (formId, data, parentData, parentFormId, success) {
          if (callbackHandler) {
            var copy = angular.copy(data);
            callbackHandler(formId, field, collection, copy, success);
          }
        }
      };
    }
  };
});