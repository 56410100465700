module.exports = {
  "FORMS": "Forms",
  "FOLDERS": "Folders",
  "ADMINISTRATIVE": "Administrative",
  "INVITE": "Invite",
  "INVITES": "Invites",
  "USER_MANAGEMENT_OVERVIEW": "User overview",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "User Information",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Edit user",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Delete user",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Resend invite",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Name",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Admin",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Newsletter",
  "GROUPS": "Groups",
  "ACCOUNT_OWNER": "Account owner",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "Email Address",
  "USER_MANAGEMENT_ALL_USERS": "All Users",
  "USER_MANAGEMENT_NEW_TITLE": "New User(s)",
  "USER_MANAGEMENT_EDIT_TITLE": "Manage User",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Permissions",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Admin",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "No groups",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Folders",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(by group)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "No Folders",
  "USER_MANAGEMENT_EDIT_SECURITY": "User Security",
  "USER_MANAGEMENT_SETTINGS_LABEL": "User Settings",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Username",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "First Name",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Last Name",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Phone number",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Newsletter",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Danger Zone",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Remove User",
  "CUSTOMER_GROUPS_NEW_TITLE": "New Group",
  "CUSTOMER_GROUPS_NAME_LABEL": "Name",
  "CUSTOMER_GROUPS_NO_GROUPS": "There aren't any groups.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "No visible groups. Please change the filter.",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Group Members",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Add Group",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Member of ",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Edit",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Remove",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Edit Group",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "Client Permissions",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "This group can access forms in the following folders:",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Platform Permissions",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "This group can manage:",
  "MANAGE_FORMS_LABEL": "Forms & Folders",
  "MANAGE_REGISTRATIONS_LABEL": "Submissions",
  "MANAGE_TASKS_LABEL": "Tasks",
  "MANAGE_USERS_LABEL": "Users",
  "MANAGE_SSO_LABEL": "Single sign-on",
  "MANAGE_DATA_SOURCES_LABEL": "Data sources",
  "MANAGE_TEMPLATES_LABEL": "Templates",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Transfer Ownership",
  "MANAGE_ACCOUNT_LABEL": "Global account settings",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Remove Group",
  "SELECT_GROUPS": "Select Groups",
  "USER_COUNT": "Contains {{value}} users",
  "USER_COUNT_SINGLE": "Contains single user",
  "USER_COUNT_EMPTY": "Doesn't contain users",
  "USER_MANAGEMENT_REMOVE_TITLE": "Delete User",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "Are you sure you want to delete this user?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "You can't remove yourself",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Ask the owner of the account to remove you.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Remove user from group",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "Are you sure you want to remove the user from this group?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Delete Invite",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "Are you sure you want to delete this invite?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Remove invite from group",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "Are you sure you want to remove the invite from this group?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Delete Group",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "Are you sure you want to delete this group? You cannot undo this action! Note that this won't delete the users that are inside this group.",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Users have been invited",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "You've successfully invited <strong>{{count}} users</strong> to join your account.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "The selected file is larger than 5MB",
  "USER_MANAGEMENT_INVITE_TYPE": "How many users do you want to invite?",
  "USER_MANAGEMENT_INVITE_SINGLE": "A single user",
  "USER_MANAGEMENT_INVITE_BULK": "Multiple users",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Select an Excel file containing a list of email addresses in the first column",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Language of invite",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "User invite failed",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "The user has already been added to this account",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "Change in permissions failed",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "Could not remove group as this would leave you without the permission to manage users",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "Could not remove permission as this would leave you without the permission to manage users",
  "ROLES": "Roles",
  "ROLES_DEFAULT": "Default roles",
  "ROLES_CUSTOM": "Custom roles",
  "MANAGE_ROLES_TITLE": "Manage roles",
  "MANAGE_ROLES_DESCRIPTION": "Define the permissions that belong to your roles.",
  "MANAGE_BILLING": "Manage billing",
  "MANAGE_CREDITS": "Manage billing",
  "MANAGE_DATA_SOURCES": "Manage data sources",
  "MANAGE_FORMS": "Manage all forms & folders",
  "MANAGE_TASKS": "Manage all tasks",
  "MANAGE_REGISTRATIONS": "Manage all submissions",
  "MANAGE_USERS": "Manage users & access",
  "MANAGE_SSO": "Manage single sign-on",
  "MANAGE_SENDING_DOMAIN": "Manage email domain",
  "MANAGE_WEBHOOKS": "Manage webhooks",
  "MANAGE_TEMPLATES": "Manage templates",
  "MANAGE_ACCOUNT": "Manage Account",
  "TRANSFER_OWNERSHIP": "Transfer ownership",
  "CUSTOMER_USER": "App access",
  "CREATE_FOLDER": "Create folders",
  "CREATE_FORM": "Create forms",
  "ADD_FORM": "Add form to folder",
  "REMOVE_FORM": "Remove form from folder",
  "UPDATE_FOLDER": "Update folder",
  "DELETE_FOLDER": "Delete folder",
  "CREATE_SUBMISSION": "Create submission",
  "READ_SUBMISSION": "View submissions",
  "DELETE_SUBMISSION": "Delete submissions",
  "UPDATE_SUBMISSION": "Update submissions",
  "READ_TASK": "View tasks",
  "UPDATE_TASK": "Complete tasks",
  "CREATE_TASK": "Create tasks",
  "DELETE_TASK": "Revoke tasks",
  "UPDATE_FORM": "Update form",
  "ARCHIVE_FORM": "Delete form",
  "RESTORE_FORM": "Restore form",
  "MANAGE_BILLING_TOOLTIP": "Update your subscription, billing details and payment method. View your invoices and historic usage.",
  "MANAGE_CREDITS_TOOLTIP": "Update your subscription, billing details and payment method. View your invoices and historic usage.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Create, view, update and delete all data sources",
  "MANAGE_FORMS_TOOLTIP": "Create, view and update <b>ALL</b> forms and folders",
  "MANAGE_TASKS_TOOLTIP": "Create, view and update tasks for <b>ALL</b> forms",
  "MANAGE_REGISTRATIONS_TOOLTIP": "View, delete and resend submissions for <b>ALL</b> forms",
  "MANAGE_USERS_TOOLTIP": "Manage access for all users, invites and groups",
  "MANAGE_SSO_TOOLTIP": "Manage configuration for single sign-on",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Manage configuration for custom email domain",
  "MANAGE_WEBHOOKS_TOOLTIP": "Create, view, update and delete all webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Create, view, update and delete all templates ",
  "MANAGE_ACCOUNT_TOOLTIP": "Manage global account settings",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Manage global account settings",
  "CREATE_FOLDER_TOOLTIP": "Create new folders",
  "CREATE_FORM_TOOLTIP": "Create within a folder you have access to",
  "ADD_FORM_TOOLTIP": "Add/move to a folder you have access to",
  "REMOVE_FORM_TOOLTIP": "Remove from a folder you have access to",
  "UPDATE_FOLDER_TOOLTIP": "Update name, description, and icon.",
  "DELETE_FOLDER_TOOLTIP": "Delete folder",
  "CREATE_SUBMISSION_TOOLTIP": "Submit form in the app to create a new Submission",
  "READ_SUBMISSION_TOOLTIP": "View submissions for a specific form",
  "DELETE_SUBMISSION_TOOLTIP": "Delete submissions for a specific form",
  "UPDATE_SUBMISSION_TOOLTIP": "Update submissions for a specific form",
  "CREATE_TASK_TOOLTIP": "Create tasks for a specific form",
  "READ_TASK_TOOLTIP": "View tasks for a specific form",
  "UPDATE_TASK_TOOLTIP": "Complete tasks for a specific form",
  "DELETE_TASK_TOOLTIP": "Revoke tasks for a specific form",
  "UPDATE_FORM_TOOLTIP": "Edit and publish form",
  "ARCHIVE_FORM_TOOLTIP": "Delete form",
  "ALLOWED": "Yes",
  "ALLOW": "Allow",
  "DONT_ALLOW": "Don't allow",
  "NOT_ALLOWED": "No",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Folders and Forms",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Manage access for all users in this group.",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "This group can <b>manage all forms & folders</b> through its role mentioned above",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "This user can <b>manage all forms & folders</b> through its role",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Manage access for this user.",
  "ACCESS_ON_ACCOUNT": "Account",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Select an account role for all users in this group.",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Select an account role for this user.",
  "NEW_ROLE": "Create new role",
  "USER_IN_GROUPS_DESCRIPTION": "This user belongs to the following groups",
  "USER_NOT_IN_GROUP_DESCRIPTION": "This user does not belong to any groups",
  "ACCESS_VIA_GROUPS": "Access via groups",
  "ROLE_ACCOUNT_OWNER": "Account Owner",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Account Administrator",
  "ROLE_OWNER": "Account Owner",
  "ROLE_USER": "Account User",
  "ROLE_FORM_MANAGER": "Form Manager",
  "ROLE_FORM_USER": "Form User",
  "ROLE_FOLDER_MANAGER": "Folder Manager",
  "USER_DESCRIPTION": "User information",
  "ROLE_DELETE_TITLE": "Delete role",
  "ROLE_DELETE_MESSAGE": "You can only delete roles that are unused. Make sure you update all users/groups that use this role first.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "External",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "This group is synced from your external directory and cannot be changed.",
  "EXTERNAL_DIRECTORY_USER_LABEL": "External",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "This user is synced from your external directory and cannot be changed.",
  "USER_SUSPENDED": "Suspended",
  "USER_SUSPENDED_TOOLTIP": "This user has been suspended in your external directory",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Not allowed to transfer ownership",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "You are not allowed to transfer ownership to an invited user. User has to accept invitation first.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Not allowed to transfer ownership",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "You need to be owner of the account in order to transfer ownership to a different user",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Transfer ownership",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "Are you sure you want to transfer ownership to <em>{{firstName}} {{lastName}} ({{username}})</em>?<br /> <br /> <b>Note:</b> You will lose ownership of the account.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Transferred ownership",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> is now the current owner of this account.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "Email",
  "MENU_RULES": "Rules",
  "MENU_HOOKS": "Integrations",
  "SUB_FORM_NO_LONGER_USED_TITLE": "Subform no longer used",
  "SUB_FORM_NO_LONGER_USED_BODY": "The subform is not used anywhere else in the folder. Do you want to delete the subform?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "These subforms are not used anywhere else in the folder. Do you want to delete the subforms?",
  "UNSUPPORTED_FILE_TYPE": "Only the following file types are supported: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "This form version is not editable",
  "FORM_FINALIZED_MESSAGE": "If you want to edit your form, go back to the overview and hit 'Edit Form'.",
  "PUBLISH_FAILED_TITLE": "Failed to publish your form",
  "PUBLISH_FAILED_MESSAGE": "Don't worry, your form was successfully saved, but it could not be published. Please try again later. If the problem persists, please contact us.",
  "INTEGRATION_EOL_MESSAGE": "This integration has been disabled. Please remove or replace it with another integration or webhook. Please <a href=\"https://help.moreapp.com/en/support/tickets/new\">contact support</a> if you need help.",
  "USER_PICKER_ADD_USER": "Invite user(s)",
  "USER_PICKER_ADD_GROUP": "Create a new group",
  "USER_PICKER_ALL_USERS": "All Users",
  "USER_PICKER_INVITES": "Invites",
  "USER_PICKER_INVITED": "(Invited)",
  "COLLECTION_PICKER_ADD_FORM": "Create a new form",
  "COLLECTION_PICKER_ADD_COLLECTION": "Create a new folder",
  "SEARCH_FORMS_PLACEHOLDER": "Search for folders and forms",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Select Columns",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Search",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "View",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Delete All",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "Are you absolutely sure?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "Are you sure you want to delete all submissions for this form? You cannot undo this action!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "This deletes ALL submissions of this form, not just your selection",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Please enter your Customer ID to confirm, which is",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Export",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Export All",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Export Filtered",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Export Selected",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Include images",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Include files",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "Export to a single Excel sheet",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "Exporting might take some time",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "You are about to export a fair amount of submissions. This might take some time to download.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "There are no users for this form.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Selected",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Clear selection",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Submission Details",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Delete submission",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Permanently delete submission",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "Are you sure you want to delete this submission? You cannot undo this action!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Resend submission",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Resend submission",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "Do you want to resend this submission?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "Submission is being sent",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "Resending submission failed. Contact support, if this persists.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "Emails with PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Recipients",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Info",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "User",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Data",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "All files",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Location",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "Show in Google Maps",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "Not recorded",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Date saved",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Device",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Network",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "App version",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Task",
  "SERIAL_NUMBER": "Serial number",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Integrations",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Retry step {{index}}",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Download report",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Next attempt in",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "Mail",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "Download PDF",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Resend attempt",
  "DOWNLOAD": "Download",
  "MAIL_STATUS_SEND": "{{number}} sent",
  "MAIL_STATUS_QUEUED": "{{number}} queued",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} soft bounced",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} hard bounced",
  "MAIL_STATUS_REJECT": "{{number}} rejected",
  "BAD_MAILBOX": "Bad mailbox",
  "INVALID_DOMAIN": "Invalid domain",
  "IMAGES": "Images",
  "UNPAID_SUBMISSION": "This submission hasn't been processed yet.",
  "TRY_BETA_EXPORT": "Try the new beta version",
  "EXPORT_ALL_COLUMNS": "Export all columns",
  "SELECTED_COLUMNS": "Select columns",
  "EXPORT_OPTIONS_TIMEZONE": "Timezone",
  "EXPORT_SUCCESS_TITLE": "Export successfully started",
  "EXPORT_SUCCESS_MESSAGE": "Your export has been started, once finished it will be sent to <b>{{email}}</b>. Depending on the size of your export this can take up to several hours.",
  "EXPORT_SERVICE_UNAVAILABLE": "Export is currently unavailable, please try again later",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "Exports including files are limited to {{limit}} submissions",
  "MORE_INFORMATION": "More information",
  "SELECTABLE_REORDARABLE_DONE": "Done",
  "ACCEPT_CUSTOMER_INVITE": "Do you want to accept the invite from '{{customer}}'?",
  "ACCEPT_BUTTON": "Accept",
  "ACTIVATION_FORM_TITLE": "Account Activation",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Customer Information",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Name",
  "ACTIVATION_USER_INFORMATION_TITLE": "User Information",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "First name",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Last name",
  "ACTIVATION_PASSWORD": "Password",
  "ACTIVATION_PASSWORD_CONFIRM": "Password confirmation",
  "ACTIVATION_PASSWORD_MISMATCH": "Password mismatch. Please make sure the password matches the confirmation.",
  "ACTIVATION_FORM_TOC": "Terms and Conditions",
  "ACTIVATION_FORM_TOC_MESSAGE": "I agree to the ",
  "ACTIVATION_SUBMIT_BUTTON": "Activate",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Enter Token",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "Your invite has expired or is invalid",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Please ask your administrator to send you a new invite",
  "ACTIVATION_CHECK_TOKEN": "Check Token",
  "ACTIVATION_SUCCESS": "Yes! You have successfully activated your account!",
  "LICENSE_AGREEMENT": "License Agreement",
  "PRIVACY_POLICY": "Privacy Policy",
  "SECURITY_STATEMENT": "Security Statement",
  "LOGIN_ON_MOBILE_APP": "Log in on the mobile app:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Download the app on your Android or iOS device to get started.",
  "OR": "or",
  "SCAN_QR_CODE": "Scan QR code",
  "NO_SMARTPHONE": "Don't have a smartphone right now?",
  "OPEN_IN_WEBCLIENT": "Open in browser",
  "ICON_PICKER_SELECT": "Select Icon",
  "ICON_PICKER_MODAL_TITLE": "Icon Picker",
  "MORE_GRID_VIEW": "View",
  "MORE_GRID_LOAD_MORE": "Load more",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Details",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Details",
  "DATE_FILTER_FROM_LABEL": "From",
  "DATE_FILTER_TO_LABEL": "To",
  "DATE_FILTER_PLACEHOLDER": "Select date",
  "NO_RESULTS_FOUND": "No results found",
  "CANVAS_NOT_SUPPORTED_TITLE": "Signature not supported",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "You cannot use this functionality in the platform. It is only supported in the mobile app and the webclient.",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Photo not supported",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "You cannot use this functionality in the platform. It is only supported in the mobile app and the webclient.",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Photo library not supported",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "You cannot use this functionality in the platform. It is only supported in the mobile app and the webclient.",
  "BARCODE_SCAN_FAILURE": "Barcode scanner not supported",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "You cannot use this functionality in the platform. It is only supported in the mobile app.",
  "DATE_MODAL_TITLE": "Picker",
  "WIDGET_DETAIL_LINK": "Subform can be reused in other Subform-widgets",
  "WIDGET_DETAIL_UNLINK": "This Subform-widget is linked to another subform",
  "WIDGET_DETAIL_NEW_TITLE": "New Subform",
  "WIDGET_DETAIL_REMOVE_TITLE": "Remove Subform",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Select an existing subform",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "Changes will be applied to other widgets using this subform",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Used in {{value}} widget(s)",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "Unused",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "Are you sure you want to remove this subform?",
  "COLLECTION": "Folder",
  "DESCRIPTION": "Description",
  "LOGIN": "Login",
  "LOGIN_USERNAME": "Username",
  "LOGIN_PASSWORD": "Password",
  "LOGIN_SIGN_IN_BUTTON": "Log In",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Invalid username/password combination",
  "LOGIN_FORGOT_PASSWORD_LINK": "Forgot password",
  "LOGIN_NO_ACCOUNT_MESSAGE": "No account? Select your plan here!",
  "LOGOUT_SUCCESSFUL_TITLE": "Logged out",
  "LOGOUT_SUCCESSFUL_MESSAGE": "You're now logged out",
  "LOGOUT_SUCCESSFUL_ACTION": "Log back in",
  "FORGOT_PASSWORD_TITLE": "Forgot password",
  "FORGOT_PASSWORD_EMAIL": "Email",
  "FORGOT_PASSWORD_USERNAME": "Username",
  "FORGOT_PASSWORD_SUBMIT": "Submit",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Invalid email address",
  "FORGOT_PASSWORD_EMAIL_SENT": "Your password has been reset. Follow the link we've sent to your email to set a new password.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Back to login",
  "ACTIVATE_USER_TITLE": "Activate user",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "New password",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "New password (verification)",
  "MENU_HELP": "Help Center",
  "MENU_REGIONS": "Regions",
  "MENU_CUSTOMERS": "Customers",
  "MENU_LOGOUT": "Logout",
  "MENU_USERS": "Users",
  "MENU_REGISTRATIONS": "Submissions",
  "MENU_FORMS": "Forms",
  "MENU_TEMPLATES": "Templates",
  "MENU_SETTINGS": "Settings",
  "MENU_API_DOC": "Docs",
  "MENU_DOCUMENTATION": "Mail doc",
  "MENU_SUPPORT": "Support",
  "MENU_PLUGINS": "Plugins",
  "MENU_TRANSACTIONS": "Transactions",
  "MENU_BILLING": "Billing",
  "MENU_FINANCIAL": "Financial",
  "MENU_CONTACT": "Contact",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Choose Customer",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Data Sources",
  "MENU_PROFILE": "Profile",
  "MENU_ONBOARDING": "Sign-up page",
  "MENU_QUEUE": "Queue",
  "PROFILE_INFO": "Info",
  "PROFILE_EMAIL_VALID": "Email has been validated successfully",
  "PROFILE_EMAIL_NOT_VALIDATED": "Email has not yet been validated.",
  "SUBMITTED": "Submitted",
  "SENT": "Sent",
  "ACCEPTED": "Accepted",
  "REVOKED": "Revoked",
  "REJECTED": "Rejected",
  "CONFIRM": "Confirm",
  "AWAITING_PACKAGE_UPLOAD": "Awaiting package upload",
  "RESELLER": "Reseller",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Settings",
  "CUSTOMER_APPLICATION_OPERATIONS": "Folder Operations",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "Folder can only be removed when it is empty",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Total forms sent",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Forms sent",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Search...",
  "ADD_FORM_PANEL_TITLE": "Add a form",
  "ADD_FORM_PANEL_TITLE_LABEL": "Title of the form",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Title of the form",
  "ADD_FORM_PANEL_SAVE": "Save",
  "ADD_TEMPLATE": "Add a template",
  "MARK_GLOBAL": "Mark as global",
  "UNMARK_GLOBAL": "Unmark as global",
  "MARK_GLOBAL_TOOLTIP": "Only available as MoreApp ADMIN. Mark as global to make it publicly available in the 'New Form' screen",
  "ADD_APPLICATION_PANEL_TITLE": "Add a folder",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Name of the folder",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Name",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Delete Form",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "Are you sure you want to delete this form?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Copy Form",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Choose a folder to copy the form",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Folder",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Folder",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Name of copy",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Publish",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Manage users",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Copy",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Delete",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Copy folder",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Customer",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Publish folder",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "Are you sure you want to publish the folder?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "Folder published successfully!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Failed to publish folder due to unknown reasons. Please check the application definition missing properties, if you can't resolve this issue. Please contact your partner.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "Failed to publish folder. Please fix the following issues and retry:",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "The previous attempt had the following errors:",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Show previous errors",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Publish",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Place on Marketplace",
  "INVALID_APPLICATION_HEADER": "Application issues:",
  "INVALID_FORM_HEADER": "Form issues:",
  "INVALID_APPLICATION_START_PAGE": "Folder must have a start page",
  "INVALID_APPLICATION_NAME": "Folder must have a name",
  "INVALID_APPLICATION_THEME": "Folder must have a theme",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Search setting 'Item markup' is invalid",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "<code>{{fieldName}}</code> has invalid properties",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "<code>{{fieldName}}</code> has an invalid property <code>{{property}}</code>",
  "INVALID_VIEW_ITEM_DATA_NAME": "<code>{{fieldName}}</code> has an invalid data name",
  "INVALID_VIEW_ITEM_WIDGET_UID": "<code>{{fieldName}}</code> widget is not installed",
  "INVALID_VIEW_ITEM_VIEW_ID": "<code>{{fieldName}}</code> does not refer to a known View",
  "INVALID_VIEW_ITEM_MAIL": "Invalid placeholder in mail template near <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Invalid dynamic recipients in mail template near <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Invalid recipient in mail: <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Invalid rule with name <code>{{ fieldName }}</code>",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "User management",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Users that have access",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "No users",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "user",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "users",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Deleted ",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Groups that have access",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "No groups",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Define who can access and use this form",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Define who can add forms to this folder",
  "CONTACT": "Contact",
  "ADDITIONAL_FEES_APPLY": "Additional fees apply",
  "CUSTOMER_CONTACT_VAT_NUMBER": "BTW",
  "CUSTOMER_CONTACT_COC_NUMBER": "KVK",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Support",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "We have received your notification and will take appropriate action.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Failed to send your notification. Feel free to contact us about this issue.",
  "BILLING_INFO": "Company Information",
  "CONTACT_INFO": "Contact Information",
  "INVOICES": "Invoices",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Account",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Select customer",
  "COLLECTION_REMOVE_MODAL_TITLE": "Remove Folder",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "Are you sure you want to delete this folder? This action cannot be made undone",
  "COPY_FORM_MODAL_TITLE": "Copy Form",
  "COPY_FORM_MODAL_MESSAGE": "Choose a folder to copy the form to and change its name if preferred",
  "COPY_FORM_MODAL_NAME_LABEL": "Name",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "Target Folder",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Select a folder to copy the form to",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Optionally use a different name for the copy.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_CUSTOMER": "The account to which this form is copied.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "The copy will be placed in this folder.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Delete View",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "Are you sure you want to delete this view?",
  "SUPPORT_TITLE": "Support",
  "SUPPORT_QUESTION": "Something not quite right?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "Did you find a bug or a missing feature, or do you have a question? Please send us an email at <a href=\"mailto:support@moreapp.com\">support@moreapp.com</a>. Please provide detailed information related to your issue, possibly steps to reproduce and to which user and/or feature it is related.",
  "SUPPORT_QUESTION_SUBTITLE": "Please fill in the form below to let us know!",
  "SUPPORT_APPLICATION_QUESTION": "Which folder were you using?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Folder name/id",
  "SUPPORT_FORM_QUESTION": "Which form were you using?",
  "SUPPORT_FORM_PLACEHOLDER": "Form name",
  "SUPPORT_DEVICE_QUESTION": "Which kind of device were you using?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Type / System version",
  "SUPPORT_DESCRIPTION_QUESTION": "Anything else that you can tell us that might help?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Description",
  "SUPPORT_EMAIL_QUESTION": "If necessary, how may we contact you?",
  "SUPPORT_EMAIL_PLACEHOLDER": "E-Mail Address",
  "SUPPORT_SUCCESS_TITLE": "Thank you!",
  "SUPPORT_SUCCESS_MESSAGE": "We have received your notification and will take appropriate action.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Edit folder: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Settings",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Name",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Enter a name for the folder",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Start Page",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Theme",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Image",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Views",
  "APPLICATION_EDITOR_TO_LABEL": "To",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "Switch to simple mode",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "Are you sure you want to go back to simple mode? This means you will lose your customised PDF layout and go back to our default PDF layout.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Name",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Comma-separated list of recipients",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Dynamic recipients",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Subject",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "From",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Sender name",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Reply To",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Send replies to this address",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "From email",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Reset pin attachments",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Reset attachment",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "Are you sure you want to reset the PDF?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "This will overwrite your customizations and will regenerate the default PDF attachment, based on your current form configuration.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Attach all images",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "Attach PDF with name: ",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Use header and footer in PDF",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "With logo:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Hide fields that are not filled in",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Use horizontal orientation for subform",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Placeholders",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Show map",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Fill in one or more email addresses to whom you want to send the form, separated with commas. <br><br><b>Example</b><br>alice@example.com, bob@example.com",
  "${sys.user}": "user",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Publish Failed",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "The form was saved but could not be published due to some errors.",
  "VIEW_EDITOR_FORM": "Form",
  "VIEW_EDITOR_EMAIL": "Email",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Send an email with PDF for each submission",
  "VIEW_EDITOR_RULES": "Rules",
  "VIEW_EDITOR_RULES_SUBTITLE": "Hide or show fields under certain conditions",
  "VIEW_EDITOR_SETTINGS": "Settings",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Change the appearance and behaviour of your form",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Allows users to search for submissions in the app, and use that as a starting point for a new submission",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Integrations",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Trigger an external system on each submission",
  "VIEW_EDITOR_PROPERTIES": "Properties",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "The data name is invalid",
  "VIEW_EDITOR_DATA_NAME": "Data name",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "This data name is duplicated, which could result in unexpected behaviour",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Add Rule",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Add integration",
  "HOOK_SELECT_TITLE": "Select integration",
  "INTEGRATIONS_SUBTITLE": "Alternatively, you can integrate using <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, our <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a>, or <a href='https://zapier.com/apps/moreapp/integrations' target='_blank'>Zapier</a>",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "There is nothing to configure for this integration",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "Configuration has been validated successfully",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Remove integration",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "Are you sure you want to delete this integration? This action cannot be made undone.",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "There are currently no integrations available to add",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "Configuration has been saved, but it could not be validated at this time. If the problem persists, please contact the author of this integration: {{author}}",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Get more integrations",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "This integration is unavailable since {{lastAvailableDate|date:'medium'}}. You are able to save the configuration but it won't be validated. Also, the integration will not be executed until it is available again.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Name",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Conditions",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Other types",
  "VIEW_EDITOR_RULE_USER": "Username",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Choose a field",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Choose a condition",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Choose a value",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Starts with",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Ends with",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Contains",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Greater than",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Less than",
  "VIEW_EDITOR_RULE_CONDITION_IS": "Is",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "Has value",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "value",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "value",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Current Form Fields",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Other Form Fields",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Actions",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "When",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "of the conditions match, perform the following",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": " Choose an action",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Settings",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Name",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Description",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Icon",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "Hide in app",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Root View",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "This form should show up as root view",
  "VIEW_EDITOR_SETTINGS_INTERACTION_LABEL": "Upload interaction",
  "VIEW_EDITOR_SETTINGS_INTERACTION_IMMEDIATE_UPLOAD_LABEL": "Immediate upload",
  "VIEW_EDITOR_SETTINGS_INTERACTION_MANUAL_UPLOAD": "Manual upload",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Description in app",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Save mode",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Save and close (Default)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Save and close & Save and new",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "No save (read only)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Search settings",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Enabled",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Only show submissions made by current user",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filtering enabled",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Fields",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Choose field",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Form",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Item markup",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Available parameters:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "The value does not exist",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Value",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Value in PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "Value",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Add",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Use images as labels",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Select image",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Select image",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Select icon",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Default pin icons",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Use default icon",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Add to favorites",
  "VIEW_EDITOR_ADVANCED_MODE": "Advanced mode",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Basic",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Favorite",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "No favorites defined.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Add Favorite Widget",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Favorite name",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Name",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Original value",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Favorite value",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "There are no changes on the existing widget.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "Add Email",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Delete Widget",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "Are you sure you want to delete this favorite widget?",
  "ERROR_SOMETHING_WENT_WRONG": "Something went wrong.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Please try again later. If the problem persists, contact us",
  "ACCESS_DENIED_MESSAGE": "Access denied",
  "ACCESS_DENIED_DESCRIPTION": "This Platform is for Administrators. Contact your Account Owner or log in to the <a href='{{webclientUrl}}'>Web App</a> to fill out forms.",
  "ACCESS_DENIED_LOGOUT": "Logout",
  "ACCESS_DENIED_TO_LOGIN": "Go to login",
  "PAGE_NOT_FOUND_TITLE": "Page not found",
  "PAGE_NOT_FOUND_DESCRIPTION": "The page you were looking for could not be found",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Go back to platform overview",
  "CUSTOMER_RESOURCES_TITLE": "Resources",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Type",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Gallery",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Name",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Upload as File",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "File",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Upload from URL",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Remove resource",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "Are you sure you want to delete this resource? This action cannot be made undone",
  "RESOURCES_UPLOAD_FAILURE": "Upload Failure",
  "RESOURCES_UPLOAD_INVALID_FILE": "The selected file is not an image.",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "The selected file exceeds the file limit of 5MB.",
  "REGISTRATIONS": "Submissions",
  "START": "Start",
  "CONFIGURE": "Configure",
  "UPGRADE": "Upgrade",
  "STEP": "Step",
  "CREATED": "Created",
  "CREATE": "Create",
  "READ": "Read",
  "DELETE": "Delete",
  "REMOVE": "Remove",
  "SUBMIT": "Submit",
  "CANCEL": "Cancel",
  "CLOSE": "Close",
  "SEND": "Send",
  "SAVE": "Save",
  "RENAME": "Rename",
  "RENAME_LINKED_SUBFORM_TITLE": "Rename linked subform",
  "SAVE_PAYMENT_METHOD": "Confirm and use payment method",
  "SELECTED_PAYMENT_METHOD": "Selected payment method",
  "SELECT_OTHER_PAYMENT_METHOD": "Use another payment method",
  "LOADING": "Loading",
  "SAVE_AND_CLOSE": "Save & Close",
  "SAVE_AND_PUBLISH": "Save & Publish",
  "VALIDATE": "Validate",
  "EDIT": "Edit",
  "UNDO": "Undo",
  "BACK": "Back",
  "NEXT": "Next",
  "PREVIOUS": "Previous",
  "CLEAR": "Clear",
  "SELECT": "Select",
  "UPLOAD": "Upload",
  "BUSY_UPLOADING": "Busy Uploading...",
  "UPDATE": "Update",
  "ADD": "Add",
  "COPY": "Copy",
  "SHARE": "Share",
  "ACTIONS": "Actions",
  "THEME": "Theme",
  "ON": "On",
  "OFF": "Off",
  "OPTIONS": "Options",
  "INSTALLED": "Installed",
  "EMPTY": "Empty",
  "QUARTER_HOUR": "Every 15 minutes",
  "HALF_HOUR": "Every 30 minutes",
  "DAILY": "Daily",
  "WEEKLY": "Weekly",
  "HOURLY": "Hourly",
  "API": "API",
  "NAME": "Name",
  "EMAIL": "Email",
  "DATE": "Date",
  "DUE_DATE": "Due date",
  "HOOKS": "Integrations",
  "NONE": "None",
  "NEVER": "Never  ",
  "USER": "User",
  "GROUP": "Group",
  "VIA_GROUP": "via group(s)",
  "BETA": "beta",
  "LOCKED": "Locked",
  "URL": "URL",
  "FILE": "File",
  "YES": "Yes",
  "NO": "No",
  "DEFAULT": "Default",
  "SEARCH": "Search",
  "DETAILS": "Details",
  "UNSAVED_CHANGES_TITLE": "Unsaved changes",
  "UNAVAILABLE": "Unavailable",
  "VERSION": "Version",
  "PRICE": "Price",
  "FREE": "Free",
  "ADDRESS": "Address",
  "ADDRESS_LINE_1": "Address line 1",
  "ADDRESS_LINE_1_PLACEHOLDER": "123 Main Street",
  "CITY": "City",
  "COUNTRY": "Country",
  "ZIP_CODE": "Zip code",
  "OK": "OK",
  "INTERNAL_ERROR": "Internal error",
  "OPEN": "Open",
  "STALLED": "Stalled",
  "ERROR": "Error",
  "SUCCESS": "Success",
  "RETRY": "Retry",
  "SKIP": "Skip",
  "IN_PROGRESS": "In progress",
  "COMPLETED": "Completed",
  "DECLINED": "Declined",
  "BLOCKED": "One of the integrations has failed",
  "NOT_VALIDATED": "Not validated",
  "INVALID": "Invalid",
  "VALID": "Valid",
  "VERIFIED": "Verified",
  "UNVERIFIED": "Unverified",
  "TYPE": "Type",
  "VALUE": "Value",
  "ONLINE": "Online",
  "OFFLINE": "Offline",
  "ADVANCED": "Advanced",
  "PREVIEW": "Preview",
  "BY": "By",
  "UNTIL": "Until",
  "COUPON": "Coupon",
  "COUPON_ADD_ERROR": "Invalid coupon",
  "DISCOUNT": "Discount",
  "COUPON_REMOVE_TITLE": "Delete coupon",
  "COUPON_REMOVE_MESSAGE": "Are you sure you want to delete this coupon?",
  "PERMISSIONS": "Permissions",
  "THIS_MIGHT_TAKE_SOME_TIME": "This might take some time",
  "FIELD_ERROR_REQUIRED": "This field is required",
  "FIELD_ERROR_MIN_LENGTH": "Field should be at least {{minLength}} characters long",
  "FIELD_ERROR_MAX_LENGTH": "Field exceeds max length {{maxLength}}",
  "FIELD_ERROR_MIN": "Value is lower than min value {{min}}",
  "FIELD_ERROR_MAX": "Value is higher than max value {{max}}",
  "FIELD_ERROR_EMAIL": "Value is not a valid email address",
  "BILLING_BANK_ACCOUNT_NUMBER": "Bank Account (IBAN)",
  "BILLING_VAT_NUMBER": "VAT identification number",
  "BILLING_VAT_NUMBER_CHECKING": "Checking",
  "BILLING_VAT_NUMBER_VALID": "Valid",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "VAT Percentage",
  "BILLING_VAT_NUMBER_INVALID": "Invalid",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "Country mismatch",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "The country from your VAT identification number and the country you've selected do not match. Please fix this otherwise we are not able to process your order.",
  "BILLING_COC_NUMBER": "Chamber of Commerce",
  "BILLING_EMAIL": "Billing Email",
  "VAT_DISCLAIMER": "Prices displayed exclude VAT. The VAT amount will be displayed after the payment process and shown on invoices.",
  "RECEIVE_NEWS_LETTER": "Newsletter",
  "RECEIVE_NEWS_LETTER_MESSAGE": "I want to receive the newsletter",
  "TERMS_OF_CONDITION_DOWNLOAD": "Download the terms of condition",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Sub Form",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "New form name",
  "FORM_BUILDER_SUB_FORM_NAME": "Form name",
  "TOOLTIP_ADD_APPLICATION": "A folder will hold your forms.<br><br>Each folder has its own settings, user permissions and can be published individually.",
  "TOOLTIP_ADD_APPLICATION_NAME": "This name will be shown in the app.",
  "TOOLTIP_ADD_FORM": "Add a form to make submissions in the app.<br><br>Each form has a number of fields, rules and its own settings.",
  "TOOLTIP_ADD_FORM_NAME": "This name will be shown in the app.",
  "TOOLTIP_COPY_FORM_NAME": "Optionally use a different name for the copy.",
  "TOOLTIP_COPY_FORM_DESTINATION": "The copy will be placed in this folder.",
  "TOOLTIP_DELETE_FORM": "<b>This action is irreversible</b>.<br>Note that its submissions will be kept.",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a>Grab a handle</a> to change the order of your forms inside the folder.<br><br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "Folder has been archived. Revert this action by clicking the <a>Undo</a> button.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "Folder has been created, but is not published yet.<br><br>If you want to see this folder in the app, you will have to <a>Publish</a> it first.",
  "TOOLTIP_APP_MANAGE_USERS": "Specify which groups and/or users will see this folder in the app after logging in.",
  "TOOLTIP_VIEW_SETTING_NAME": "This name will be shown in the app.",
  "TOOLTIP_VIEW_SETTING_ICON": "This icon is used in the app when the form is shown in a list",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Enable to show this form in the main <a>Forms</a> overview.",
  "TOOLTIP_VIEW_SETTING_INTERACTION": "Choose how and when this form will be uploaded:<br><br><a>Immediate upload (default):</a><br>When saving the form, the app will upload it right away (if connected to the internet).<br><br><a>Manual upload:</a><br>Saved forms are stored on your device and can be manually uploaded from the Submissions screen.",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Choose a save mode:<br><br><a>Save and close (default):</a><br>A simple <b>Save</b> button will appear, that saves and closes the form (when valid).<br><br><a>Save and close & Save and new:</a><br>Both the <b>Save</b> and <b>Save and New</b> button appear, so you can keep adding submissions of the same form.<br><br><a>No save:</a><br>Makes this a Read-only form. No buttons appear.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "This defines how each submission, draft or task for this form is shown in the app. Use placeholders of fields that let you recognize each item.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Enable to search this form in the app.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Enable to search only for submissions that were submitted by the user that is currently logged in.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Enable to filter certain fields from found forms.",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Exclude the following values in the result",
  "TOOLTIP_FORM_RULE_CONDITION": "Specify one or more <a>Conditions</a> that should satisfy in order to execute the <a>Actions</a>.<br><br>This way, irrelevant or inapplicable fields can be skipped under certain conditions.<br><br><b>For example:</b><br>Hide <a>Partner name</a> when <a>Marital status</a> is set to <a>'unmarried'</a>",
  "TOOLTIP_FORM_RULE_ACTION": "Specify one or more <a>Actions</a> to be executed when all <a>Conditions</a> are satisfied.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Name that will be used as column name in the <a>Submission grid</a>. As default, this value is automatically filled in based on the <b>Label text</b><br><br><b>For example:</b><br>If you set the label to <a>'How old are you?'</a>, the data name would be <a>howOldAreYou</a>. You might want to change this to <a>'age'</a>.",
  "TOOLTIP_ADD_DATA_SOURCE": "Add data source",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Name of the data source",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Select an Excel file to import. Make sure the extension for the Excel file is `.xlsx`. `.xls` files will not be imported.",
  "TOOLTIP_HOOK": "The specific configuration for this integration on this form",
  "TOOLTIP_DIRECT_FORM_LINK": "Create a unique URL for your form, that you can share with others.<br><br>Using this URL, people without an account can fill in your form.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Share your form on our Marketplace, so others can use it as a template.",
  "RULE_HIDDEN": "Hidden",
  "RULE_VISIBLE": "Visible",
  "RULE_GETS_VALUE": "Gets value",
  "WIDGET_LICENSE_INVALID": "Invalid license",
  "WIDGET_LICENSE_BRAND": "Brand",
  "WIDGET_LICENSE_TYPE": "Type",
  "WIDGET_LICENSE_POWER": "Power",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "First admission",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "Euro class",
  "SEGMENT": "Sector",
  "SEGMENT_AUTOMOTIVE": "Automotive",
  "SEGMENT_CONSTRUCTION": "Construction",
  "SEGMENT_FACILITY": "Facility",
  "SEGMENT_FINANCIAL": "Financial",
  "SEGMENT_TRADE_AND_INDUSTRY": "Trade and Industry",
  "SEGMENT_GOVERNMENT": "Government",
  "SEGMENT_HEALTH_CARE": "Health Care",
  "SEGMENT_INSTALLATION": "Installation",
  "SEGMENT_OTHER": "Other",
  "GENERIC": "Generic",
  "FACILITY": "Facility",
  "CONSTRUCTION": "Construction",
  "SALES": "Sales",
  "HR": "HR",
  "AUTOMOTIVE": "Automotive",
  "SAFETY": "Safety",
  "LOGISTICS": "Logistics",
  "INSTALLATION": "Installation",
  "MAINTENANCE": "Service & Maintenance",
  "SECURITY": "Security",
  "REAL_ESTATE": "Real Estate",
  "HEALTHCARE": "Healthcare",
  "AGRICULTURE": "Agriculture",
  "INDUSTRY": "Industry",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Insufficient Permissions",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "You've logged into the platform. This is a section that is only accessible for the administrators in your organisation. If you require administration permissions please contact your own administrator. If you only need to fill in forms, please run the app on your smart phone or tablet.",
  "FORM_SUBMISSION_SERVER_ERROR": "The form could not be submitted. This may be a server issue. Please try again later",
  "UNKNOWN_ERROR": "An unknown error occurred",
  "ALL": "all",
  "ANY": "any",
  "GLOBAL_ERRORS_TITLE": "Validation Errors",
  "USE_TEMPLATE": "Use template",
  "LANGUAGE_EN": "English",
  "LANGUAGE_NL": "Dutch",
  "LANGUAGE_DE": "German",
  "LANGUAGE_ES": "Spanish",
  "LANGUAGE_RU": "Russian",
  "LANGUAGE_PT": "Portuguese",
  "LANGUAGE_FR": "French",
  "LANGUAGE_IT": "Italian",
  "LANGUAGE_CA": "Catalan",
  "LANGUAGE_NATIVE_EN": "English",
  "LANGUAGE_NATIVE_NL": "Nederlands",
  "LANGUAGE_NATIVE_DE": "Deutsch",
  "LANGUAGE_NATIVE_ES": "Español",
  "LANGUAGE_NATIVE_RU": "Русский",
  "LANGUAGE_NATIVE_PT": "Português",
  "LANGUAGE_NATIVE_FR": "Français",
  "LANGUAGE_NATIVE_IT": "Italiano",
  "LANGUAGE_NATIVE_CA": "Català",
  "UNSAVED_CHANGES": "There are unsaved changes that will be discarded if you continue",
  "CHANGE_FILE": "Change file",
  "AUTHENTICATED": "Authenticated",
  "LANGUAGE": "Language",
  "TAGS": "Tags",
  "SELECT_TAGS": "Select tags",
  "SELECT_ALL": "Select all",
  "SEARCH_TEMPLATES": "Search templates",
  "CUSTOMER": "Customer",
  "PARTNER": "Partner",
  "CUSTOM": "Custom",
  "AMOUNT": "Amount",
  "PHONE": "Phone number",
  "REGION": "Region",
  "STATUS": "Status",
  "ACTIVE": "Active",
  "INACTIVE": "Inactive",
  "DISABLED": "Disabled",
  "ACCOUNT": "Account",
  "DOCUMENTATION": "Documentation",
  "COMPANY_NAME": "Company name",
  "COMPANY": "Company",
  "SHOW_MORE": "Show more",
  "WEBSITE": "Website",
  "CLICK_HERE": "Click here",
  "MAIL_NOTIFICATION": "Mail notification",
  "CHECKED": "Checked",
  "UNCHECKED": "Unchecked",
  "ACCOUNT_NAME": "Company",
  "ACCOUNT_NAME_PLACEHOLDER": "E.g. Company Ltd or John Smith",
  "FEATURE_HOOKS": "Integrations",
  "FEATURE_HOOKS_MESSAGE": "<h4>Integrations are used to process collected data</h4><p>Set up a workflow, send files to OneDrive, send data to Google Sheets or to Word</p><p>This feature is available starting from the <b>Branch</b> plan.</p>",
  "FEATURE_MANUAL_DATASOURCES": "Data import",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Import data using Excel, Google Sheets or an URL (JSON)</h4><p>This feature is available starting from the <b>Leaf</b> plan.</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Automatic data import",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Import data automatically by adding a new record to your Google Sheet or URL</h4><p>This feature is available starting from the <b>Branch</b> plan.</p>",
  "FEATUER_ADVANCED_PDF": "Custom PDF",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Design your PDF report using HTML</h4><p>This feature is available starting from the <b>Branch</b> plan.</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Advanced user management",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Manage user group permissions in the Platform</h4><p>This feature is available starting from the <b>Tree</b> plan.</p>",
  "FEATURE_PDF_LOGO": "Your logo on PDF's",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Replace the MoreApp logo with your own</h4><p>This feature is available starting from the <b>Leaf</b> plan.</p>",
  "FEATURE_MULTIPLE_EMAIL": "Multiple emails",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Add multiple emails to one form</h4><p>This feature is available starting from the <b>Leaf</b> plan.</p>",
  "FEATURE_WIDGETS": "Pro widgets",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Start using more widgets like the Catalogue-, Calculation- and Rating-widgets</h4><p>This feature is available starting from the <b>Leaf</b> plan.</p>",
  "FEATURE_SEARCH": "Search function in app",
  "FEATURE_SEARCH_MESSAGE": "<h4>Thanks to the search function, searching for completed forms is made easy</h4><p>The search function allows you to retrieve previously submitted submissions and use it for a new submission</p><p>This feature is available starting from the <b>Leaf</b> plan.</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Custom templates",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Create your custom templates and save time on building new forms.</h4><p>This feature is available starting from the <b>Tree</b> plan.</p>",
  "FEATURE_WEBHOOKS": "Webhooks",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Use webhooks to subscribe to events in your account.</h4><p>On each event we send a message to the URL you configured, so you can perform an action. This lets you implement complex workflows.</p><p><b>Example</b>: configure a webhook to be triggered each time a form is submitted, to store the form data in your own database.</p><br><p>This feature is available starting from the <b>Branch</b> plan.</p>",
  "SWITCH_PLAN_CTA": "Select a new Plan",
  "SWITCH_PLAN_CONTACT_OWNER": "Contact Account Owner",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Request to upgrade our MoreApp account in order to use \"{{feature}}\" feature",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Select a service account...",
  "SERVICE_ACCOUNTS_NEW_LINK": "Connect",
  "SERVICE_ACCOUNTS_NEW_TEXT": "a new service account",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "No '{{provider}}' service accounts configured.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Chat with us",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "Thank you for your feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "Did we inform you sufficiently?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": "How would you rate your experience?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "How can we improve?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "Thank you for your feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "Submit your rating here!",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Enter your suggestions here",
  "ADD_USER_OR_GROUP": "Add user or group",
  "ADD_USER_TO_GROUP": "Add user",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Select one or multiple users",
  "SELECT_ROLE": "Select role",
  "ALL_USERS_ADDED_TO_GROUP": "You've already added all your existing users to this group",
  "WHATS_NEW": "What's new?",
  "WIDGET_LABEL": "Label",
  "WIDGET_LABEL_DESCRIPTION": "Use the <b>Label</b>-widget to show a divider inside your <a>form</a>.<br><br><b>Note</b><br>This is not part of the <a>submission data</a>.",
  "WIDGET_TEXT": "Text",
  "WIDGET_TEXT_DESCRIPTION": "Use the <b>Text</b>-widget to let the user fill in a single line of text.",
  "WIDGET_TEXT_AREA": "Text Area",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Use the <b>Text Area</b>-widget to let the user fill in complete sentences.",
  "WIDGET_NUMBER": "Number",
  "WIDGET_NUMBER_DESCRIPTION": "Use the <b>Number</b>-widget to let the user fill in an integer number.",
  "WIDGET_RADIO": "Radio",
  "WIDGET_RADIO_DESCRIPTION": "Use the <b>Radio</b>-widget to let the user choose one of your predefined values.<br><br><b>For example:</b><br>Male / Female.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Use the <b>Checkbox</b>-widget to let the user mark your property True or False.",
  "WIDGET_PHOTO": "Photo",
  "WIDGET_PHOTO_DESCRIPTION": "Use the <b>Photo</b>-widget to let the user make a photo with the device's <a>Camera</a> or add one from the <a>Library</a>.",
  "WIDGET_SIGNATURE": "Signature",
  "WIDGET_SIGNATURE_DESCRIPTION": "Use the <b>Signature</b>-widget to let the user draw its signature.",
  "WIDGET_HEADER": "Header",
  "WIDGET_HEADER_DESCRIPTION": "Use the <b>Header</b>-widget to add headings to your form.<br><br><b>Note</b><br>This is not part of the <a>submission data</a>.",
  "WIDGET_DATE": "Date",
  "WIDGET_DATE_DESCRIPTION": "Use the <b>Date</b>-widget to let the user to fill in a date.",
  "WIDGET_DATETIME": "Date Time",
  "WIDGET_DATETIME_DESCRIPTION": "Use the <b>Datetime</b>-widget to let the user to fill in a date and time.",
  "WIDGET_TIME": "Time",
  "WIDGET_TIME_DESCRIPTION": "Use the <b>Time</b>-widget to let the user to fill in a time.",
  "WIDGET_EMAIL": "Email",
  "WIDGET_EMAIL_DESCRIPTION": "Use the <b>Email</b>-widget to let the user fill in a valid email address.<br><br><b>Note</b><br>This field can be used as <a>Dynamic recipient</a> in the <a>email settings</a>.",
  "WIDGET_PHONE": "Phone",
  "WIDGET_PHONE_DESCRIPTION": "Use the <b>Phone</b>-widget to let the user fill in a valid phone number.",
  "WIDGET_LOOKUP": "Lookup",
  "WIDGET_LOOKUP_DESCRIPTION": "Use the <b>Lookup</b>-widget to let the user choose one (or more) of your predefined values.",
  "WIDGET_LIST_ITEM": "List Item",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Use the <b>List Item</b> widget to create a link to another <a>form</a>.",
  "WIDGET_SUBFORM": "Subform",
  "WIDGET_SUBFORM_DESCRIPTION": "Use the <b>Subform</b>-widget to let the user add <a>the same widgets multiple times</a> to your form.<br><br><b>For example:</b><br>Add multiple <a>materials used, hours worked, photos, or building parts</a> to a <a>submission</a>. It’s also possible to link subforms and to work with sub- in subforms.",
  "WIDGET_BARCODE": "Barcode",
  "WIDGET_BARCODE_DESCRIPTION": "Use the <b>Barcode</b>-widget to let the user scan a barcode using its device's <a>camera</a>.<br><br><b>Note:</b><br>Supports QR, UPC, EAN, CODE_39 and many more.",
  "WIDGET_SLIDER": "Slider",
  "WIDGET_SLIDER_DESCRIPTION": "Use the <b>Slider</b>-widget to let the user drag a slider to choose a number between your defined min and max values.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Use the <b>HTML</b>-widget to add custom markup to your form. (For technical users only)",
  "WIDGET_IMAGE": "Image",
  "WIDGET_IMAGE_DESCRIPTION": "Use the <b>Image</b>-widget to show an image inside your <a>form</a>.<br><br><b>Note</b><br>This is not part of the <a>submission data</a>. If you want users to attach a photo, use the <a>Photo</a>-widget.",
  "WIDGET_SEARCH": "Search",
  "WIDGET_SEARCH_DESCRIPTION": "Use the <b>Search</b>-widget in combination with the Data Sources to allow your users to select imported data. Such as customer, product, and employee information.",
  "WIDGET_RDW": "License Plate (NL)",
  "WIDGET_RDW_DESCRIPTION": "Use the <b>License Plate (NL)</b>-widget to allow users to enter a Dutch license plate. The widget will show additional information about the vehicle. <b>Note</b> Internet connection is required to append the additional information.",
  "WIDGET_PIN": "Pin",
  "WIDGET_PIN_DESCRIPTION": "Use the <b>Pin</b>-widget to let the user add one or more pins on a given image/map. Each pin you place opens a <a>subform</a> for the user to add additional information about the pin.",
  "WIDGET_HELP": "Help",
  "WIDGET_HELP_DESCRIPTION": "The Help-widget allows you to add additional information to any widget. When a user clicks on the Help-widget, a pop-up is visible with your additional information.",
  "WIDGET_RATING": "Rating",
  "WIDGET_RATING_DESCRIPTION": "The Rating-widget allows users to rate a given subject with a number of stars. It's possible to configure a rate scale between two and ten.",
  "WIDGET_CATALOGUE": "Catalogue",
  "WIDGET_CATALOGUE_DESCRIPTION": "Create your own order app with this Catalogue-widget. Fill a cart with products like you do in a webshop. Use a Data Source with the following required columns: id, name, priceExVat (in cents), description. Optional: photo, thumbnail, and vatRate. Photo and thumbnail are URLs, so these are only shown if your device has an internet connection. You can add as many other columns to the Data Source as you like. These will be visible on the product details page.",
  "WIDGET_SEARCH_WITH_GPS": "Search with GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Search with GPS location",
  "WIDGET_TIME_DIFFERENCE": "Time Difference",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "This widget calculates the difference between two Time-widgets or Date-Time-widgets.",
  "WIDGET_PAYMENT": "Payment",
  "WIDGET_PAYMENT_DESCRIPTION": "Lets the user make an in-app purchase to pay for the form.",
  "WIDGET_STOPWATCH": "Stopwatch",
  "WIDGET_STOPWATCH_DESCRIPTION": "Use the Stopwatch-widget to track time in your form. The result of this widget will be for example 00:16.47.",
  "WIDGET_SMILEY": "Smiley",
  "WIDGET_SMILEY_DESCRIPTION": "Widget to give a rating based on smileys.",
  "WIDGET_CURRENT_LOCATION": "Current Location",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "This widget automatically gets your current location using GPS. The result would be something like 'Museumstraat 1, 1071 XX Amsterdam, Netherlands'. The result can always be edited manually, in case it's not quite right.",
  "WIDGET_DRAWING": "Drawing",
  "WIDGET_DRAWING_DESCRIPTION": "With this widget, you can add drawings, lines, and texts to a photo.",
  "WIDGET_VIDEO": "Video",
  "WIDGET_VIDEO_DESCRIPTION": "This widget will let you record or select a video. This widget results in a URL in your PDF report to download the video.",
  "WIDGET_ZIPCODE": "Zipcode",
  "WIDGET_ZIPCODE_DESCRIPTION": "The Zip Code-widget provides address data from an entered zip code.",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "The IBAN-widget validates IBAN numbers.",
  "WIDGET_DRAWING_FREE": "Drawing Free",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "A copy of the Drawing widget, but free. For the customers of the Blauwdruk partner.",
  "WIDGET_CALCULATION": "Calculation",
  "WIDGET_CALCULATION_DESCRIPTION": "The Calculation-widget allows operations like addition, subtraction, multiplication, and division over one or more widgets.",
  "WIDGET_POSTCODE_NL": "Zip Code (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "This widget only works for Dutch zip codes! With the Zip Code (NL)-widget you’ll retrieve address information based on a zip code and house number. You need an internet connection in order to retrieve the data. When offline, you can add the data manually.",
  "WIDGET_ADVANCED_SEARCH": "Advanced Search",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Using the Advanced Search widget you can color your search results in the app. You can configure which color should be used when a search result matches a query.You can define colors using descriptors (like ‘red' or ‘green') or using hexadecimal color codes (like #1dc4c2)",
  "WIDGET_FILE": "File",
  "WIDGET_FILE_DESCRIPTION": "This widget will let you add files to your form. This widget results in a URL in your PDF report to download the files.",
  "WIDGET_LOCATION": "Location",
  "WIDGET_LOCATION_DESCRIPTION": "This widget allows you to select a location on a map.\n\nThe result can always be edited manually, in case it's not quite right.",
  "WIDGET_PROPERTY_PIN_NAME": "Name",
  "WIDGET_PROPERTY_PIN_FORM": "Form",
  "WIDGET_PROPERTY_PIN_ICON": "Icon",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Item markup",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "Tracking Id",
  "WIDGET_PROPERTY_LABEL": "Label",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "The name which is displayed above the widget",
  "WIDGET_PROPERTY_OPERATOR": "Operator",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "Operator used in the calculation.",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Calculation Terms",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "All fields that should be included in the calculation.",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Initial Value",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Initial value for the calculation.",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Decimal Precision",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "The number of digits that the calculation has.",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "No value on invalid term",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "Widget should not produce a value when one of the terms is invalid (i.e. not a number).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Catalogue Data Source",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Select one of your data sources to use for the catalogue. Make sure the data source complies to the required configuration.",
  "WIDGET_PROPERTY_CURRENCY": "Currency",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Configures what currency to use, but only if the value is a price",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "VAT percentage",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "The VAT percentage that will be applied to all catalogue items.",
  "WIDGET_PROPERTY_SHOW_PRICES": "Show prices",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Check this if you want unit- and total prices to show in the app and PDF.",
  "WIDGET_PROPERTY_SHOW_VAT": "Show VAT",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Check this if you want VAT values to show in the app and PDF.",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Allow remarks",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Check this to allow users to add a remark when adding an item.",
  "WIDGET_PROPERTY_RATE_ICON": "Rate icon",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Select which icon is used for rating, e.g. hearts",
  "WIDGET_PROPERTY_RATE_SCALE": "Rate scale",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Select the amount of displayed icons.",
  "WIDGET_PROPERTY_TITLE": "Title",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "The title of the given explanation.",
  "WIDGET_PROPERTY_EXPLANATION": "Explanation",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "The actual explanation.",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Use the help button text",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "When selected the text defined below will be displayed before a question mark.",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Help button text",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "The text which is displayed before a question mark when the above checkbox is selected.",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "The calculated value is a price",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Format the result as a price.",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Ignore hidden widgets",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "This setting allows you to ignore hidden widgets when performing calculations. It is available from version 7.0.32 onwards.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Use barcode scanner",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Check to allow the users search by a scanned barcode.",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Default Value",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "This value is already predefined.",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Default Search Query",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "Opening the search will use this pre-defined query.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Label text",
  "WIDGET_PROPERTY_START_TIME": "Start time",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Start time to use in the calculation.",
  "WIDGET_PROPERTY_END_TIME": "End time",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "End time to use in the calculation.",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Background Image",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Maximum duration in seconds",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "The maximum duration of the video in seconds.",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Video Quality",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Choose your preferred video quality.",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "File Type",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Select the file type that can be uploaded.",
  "WIDGET_PROPERTY_VALUE": "Value",
  "WIDGET_PROPERTY_PLACEHOLDER": "Placeholder",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Example input that is only shown when the field is empty and is removed when filled.",
  "WIDGET_PROPERTY_MAX_LENGTH": "Max length",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Maximum amount of characters. Will show a validation error in the mobile app if this value is exceeded.",
  "WIDGET_PROPERTY_MIN_LENGTH": "Min length",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Minimum amount of characters. Will show a validation error in the mobile app if this value length is too short.",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Minimum value",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "The app won't allow choosing a value below this one.",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Maximum value",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "The app won't allow choosing a value that exceeds this one.",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Use images as labels",
  "WIDGET_PROPERTY_OPTIONS": "Options",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Define the options that can be selected in the app.",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Vertical alignment",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Check to align the options vertically instead of horizontally.",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Checked by default",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "If checked, this field will be checked when you open a clean form.",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Photo Quality",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Choose your preferred photo quality.<br><br><a>Best possible (recommended)</a><br>No downscaling, no compression. Could cause problems sending emails with lots of photos.<br><br><a>Fast Upload</a><br>Downscale to max 1024x1024, compress by 75%. Could cause memory problems on low-end devices.<br><br><a>High Quality</a><br>Downscale to max 1920x1920, compress by 10%. Could cause memory problems on low-end devices and sending emails with lots of photos.",
  "WIDGET_PROPERTY_FILENAME": "Filename",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Choose the name of the photo as it will appear in the export.",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Allow selecting photo from device",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Allow users to select a previously made photo from the device.",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Full size preview",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Select this if you want to show a full size preview of the photo in your form.",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Save a copy on the device",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Pencil size",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Increase/decrease the pencil size of the user.",
  "WIDGET_PROPERTY_WIDE": "Wide",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "This will reduce the allowed height of a signature, making the resulting signature wider.",
  "WIDGET_PROPERTY_TEXT": "Text",
  "WIDGET_PROPERTY_HEADER_SIZE": "Header size",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Header size, ranging from h1 (largest) to h6 (smallest).",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Now as default",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Check to automatically set the value to 'now' when the form is opened.",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Sort Alphabetically",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Check to sort the choices alphabetically.",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Allow multiple selections",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Check to allow multiple options to be selected. These selections will be saved as comma-separated values in the submission data.",
  "WIDGET_PROPERTY_EXPAND": "Expand",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Check to expand the list when opening the form.",
  "WIDGET_PROPERTY_TARGET_VIEW": "Target view",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Choose the form that you want to link to.",
  "WIDGET_PROPERTY_IMAGE": "Image",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "This image will be used as thumbnail in the app.",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Add button text",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Name of the button to add an item using the subform.",
  "WIDGET_PROPERTY_SUBFORM": "Subform",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Choose the form that you want to use to create your composed object.",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Summary in app",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Specify how an added item is displayed, using the values of specific fields.<br><br><b>For example:</b><br>Each added item should be displayed as:<br> ${firstName} ${lastName}</b>",
  "WIDGET_PROPERTY_MINIMUM": "Minimum",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "The minimum amount of times a subform should be filled in.",
  "WIDGET_PROPERTY_MAXIMUM": "Maximum",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "The maximum amount of times a subform may be filled in.",
  "WIDGET_PROPERTY_STEP_SIZE": "Step size",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "When set to 3, slider will always round to a multiple of 3.",
  "WIDGET_PROPERTY_HIDE_VALUE": "Hide value",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Determines whether or not to show the value in the app.",
  "WIDGET_PROPERTY_HTML_CODE": "HTML code",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Add a chunk of HTML supporting inline CSS.",
  "WIDGET_PROPERTY_RESOURCE": "Resource",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Select an image from the gallery.",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Maximum Width",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "Image width inside the app. Width will be limited to the screen width if this value exceeds it.",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Maximum Height",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "Image height inside the app. Height will be limited to the screen height if this value exceeds it.",
  "WIDGET_PROPERTY_DATA_SOURCE": "Data source",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Select one of your data sources to search in. New data sources can be created from the main menu Data Sources.",
  "WIDGET_PROPERTY_FILTER": "Filter",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filter the results using value of other fields in your form.",
  "WIDGET_PROPERTY_LIST_COLORS": "List colors",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Apply colors to data source entries that match the configured queries.",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Allow barcode scanning",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "Check to allow the user to search the data source using the barcode scanner.",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Remember last search query",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "Check to remember search query. The search query will be executed automatically.",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Allow user to change image",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Check to allow users to use their own images as background for the pins, using camera or library.",
  "WIDGET_PROPERTY_PINS": "Pins",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Define one or more pins that can be placed on the given image. Each pin has its own icon and will open the specified form when placed.<br><br>In the app, your pins will be numbered automatically. To use your own numbering or labeling, define a Tracking id.",
  "WIDGET_PROPERTY_REQUIRED": "Required",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "If checked, this field must be filled to be able to save the form.",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Remember input",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Check this to remember the last input of the user on that device for the next submission. If you have set a default value as well, remember input will be overruled.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Decimal mark",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Minimum amount",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "The mobile app won't allow choosing a value below this one.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Maximum amount",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "The mobile app won't allow choosing a value that exceeds this one.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Click to view image",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Select this if you want to show a full size preview of the photo on clicking the photo.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Default to current location",
  "INTEGRATION_TASK": "Task",
  "INTEGRATION_TASK_DESCRIPTION": "This integration allows you to create a task based on incoming submission.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "This plugin allows you to send a mail using Mailchimp. You can use the data in your registration as placeholders in your Mailchimp template.",
  "INTEGRATION_DELETE": "Delete",
  "INTEGRATION_DELETE_DESCRIPTION": "This integration allows you to automatically delete the submissions of a form. Make sure this is the last integration you will add to your form. Once a submission is deleted it cannot be restored, so be careful when using this integration.",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "This integration allows you to use Microsoft Word for exporting data. You can send PDF or Word attachments with this integration. And you can upload your Word.docx template for styling your reports.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "The WebDAV integration allows you to send the PDF from an email to a WebDAV server.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "This integration allows you to send your completed forms to a Google Spreadsheet. You can get your Google Spreadsheet ID from the URL. Note: Your submitted forms will simply be added as a new row in your sheet. We do not update the sheet headers whenever you update your form. You will have to update this yourself. We don't support images/files and all nested data (Subform- , Pin-, Search-widgets) will be added to a single cell. With forms with rules and empty fields, data can be placed in the wrong column.",
  "INTEGRATION_MONGODB_INSERT": "MongoDB Insert",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "The MongoDB Insert integration lets you insert your submission data into a Mongo database.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "This integration allows you to send the PDF attached to your form to Microsoft OneDrive.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "This integration allows you to use Excel for exporting data. Upload your Excel.xlsx template and receive your data in an Excel file.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Google Sheets Cell Update",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Update a specific cell in an existing sheet. This integration allows you to update a sheet that is also used as an imported Data Source in MoreApp.",
  "INTEGRATION_MESSAGE_BOARD": "Message Board",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Allow messages to be sent",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Google Sheet Accept",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "Not for production",
  "INTEGRATION_PROPERTY_FOLDER": "Folder",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "The folder for which a task should be sent.",
  "INTEGRATION_PROPERTY_FORM": "Form",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "The form (inside the selected folder) that should be used as target for the task.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Recipients (comma separated list of emails)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Recipients (comma separated list of emails)",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Dynamic recipients",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Use the data from an Email widget as a recipient",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Message for the task",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Message for the task",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Delay (in days)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "The amount of days the submission should persist before being deleted.",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Output format",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Configures what output format to use as attachment",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Reply To (optional)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "The address recipients will reply to",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "From (optional)",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "The name from which you want the emails to originate",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Recipients",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Comma separated list of recipients",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "Send to user that submitted the form",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Check this to add the user that submitted the form as recipient",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Comma seperated list of recipients",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Comma separated list of recipients",
  "INTEGRATION_PROPERTY_SUBJECT": "Subject",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Subject with optional placeholders",
  "INTEGRATION_PROPERTY_BODY": "Body",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "Email body with optional placeholders",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Image Quality",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "If your submission has many high-resolution photos, you may want to lower the quality. Otherwise, the email might get too big for your mailbox.",
  "INTEGRATION_PROPERTY_FILENAME": "Filename",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Filename with optional placeholders",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Upload a Word template",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "The Word template to use",
  "INTEGRATION_PROPERTY_SERVER": "Server",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL to the server, will be used as a base path. E.g.: http://my.server.com",
  "INTEGRATION_PROPERTY_PATH": "Path",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "Path where the PDFs should be stored, can include placeholders, e.g.: /drive/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "WebDAV username",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Username to use when authenticating to the WebDAV server",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "WebDAV password",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Password to use when authenticating to the WebDAV server",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Google Login",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Pick a Google account to allow us to write to your Google Spreadsheets",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "Spreadsheet ID",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "The unique ID of your spreadsheet, shown in the URL like https://docs.google.com/spreadsheets/d/<b>my-spreadsheet-id</b>.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Sheet name",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "The name of your sheet, which usually defaults to 'Sheet1'",
  "INTEGRATION_PROPERTY_METADATA": "Metadata",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Select which meta fields you want to include in the export. These will be inserted before the form data",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "MongoDB Host",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "The IP adress of the MongoDB host",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "MongoDB Port",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "The port on which MongoDB is listening",
  "INTEGRATION_PROPERTY_USE_SSL": "Use SSL",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Use a secure TLS connection",
  "INTEGRATION_PROPERTY_USERNAME": "Username",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "The username of the Mongo user we should authenticate as",
  "INTEGRATION_PROPERTY_PASSWORD": "Password",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "The password of the Mongo user we should authenticate as",
  "INTEGRATION_PROPERTY_DATABASE": "Database",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "The database in which you want to save the submissions",
  "INTEGRATION_PROPERTY_COLLECTION": "Collection",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "The collection in which you want to save the submissions",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Search-widget",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "The Search-widget",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "OneDrive Login",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Pick a OneDrive account to allow us to save files",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "Include CSV file",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Automatically appends a CSV (Comma Separated Value) file to your OneDrive directory",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Upload an Excel template",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "The Excel template to use",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Excel file name",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Name of the Excel file as it should be attached",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Copy to user",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Send a copy to the use who filled in the form",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Attach images",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Attach images and signatures to the mail",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "New value widget",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Select the widget you want to get the new value from",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "Column name to update",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Define the column you want to update with the value from the selected 'new value widget'",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "The FTPS integration saves the submission data on the given FTPS server",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protocol",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "The protocol to use when connecting to the FTP server",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "Disable SSL verification",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "You can use this option when you have a self-signed certificate or an invalid certificate chain (only applicable when using FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Use 'implicit' FTPS mode",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Switch between 'implicit' and 'explicit FTPS mode' (only applicable when using FTPS)",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "URL to the server without the protocol, will be used as a base path. E.g.: my.server.com",
  "INTEGRATION_PROPERTY_PORT": "Port",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Port of the server, this will default to 22 (SFTP), 21 (FTP), or 990 (FTPS) if not filled in",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Put each submission in its own folder",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Automatically appends '/customerId/form-name/serial-number' to the remote path to make a unique path per submission",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "Username",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Username to use when authenticating to the server",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "Password",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Password to use when authenticating to the server",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "Include PDF",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Indicates whether or not to copy the PDF too",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "Store PDF for emails without recipient",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Indicates whether or not store PDFs that are generated for emails without a recipient",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Include files",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Indicates whether or not to copy files too",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Upload an Excel template",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "The Excel template to use",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Use legacy delimiters",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Use this option if you want to use '${' as the starting delimiter for placeholders (this is only recommended for older existing templates).",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Excel file name",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Name of the Excel file as it should be attached",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Recipients",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Comma separated list of recipients.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Dynamic recipients",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Use the data from an Email widget as a recipient",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Copy to user",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Send a copy to the user who filled in the form",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Image Quality",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "More quality will take more time to process the mail",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Subject",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Subject with optional placeholders",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Body",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "Email body with optional placeholders",
  "DISABLED_PENDING_VERIFICATION": "Feature will be enabled after contacting support. Please reach out to our support team to activate this option.",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Check your time settings",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "You can't log in, because your clock is too far off.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>FIX</b>: change your device’s time and timezone so it accurately reflects the time and timezone of your current location. Then try again.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Retry login",
  "TOUR_PROGRESS_STEP1_LABEL": "How it works",
  "TOUR_PROGRESS_STEP2_LABEL": "Tell us more",
  "TOUR_PROGRESS_STEP3_LABEL": "Your first form",
  "TOUR_SKIP": "Skip tour",
  "TOUR_STEP1_TRIAL": "You are on your 14-day free trial",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Build and modify forms on your desktop",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Fill out forms on your phone or tablet",
  "TOUR_STEP1_RECEIVE_DATA": "Receive a report in your inbox",
  "TOUR_STEP2_HEADER": "Tell us something about yourself",
  "TOUR_STEP3_HEADER": "How can we contact you?",
  "TOUR_ERROR_MODAL_TITLE": "Oops...",
  "TOUR_ERROR_MODAL_MESSAGE": "Something went wrong during our tour. In the meantime you can explore {{appName}} by yourself.",
  "TOUR_ERROR_MODAL_BUTTON": "Explore {{appName}}",
  "FIRST_NAME": "First name",
  "LAST_NAME": "Last name",
  "PAGE_CONTROL_PAGE_SIZE": "Page size",
  "PAGE_CONTROL_TOTAL_ITEMS": "Total items",
  "VIEW_MANAGEMENT_OVERVIEW": "Overview",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Submissions",
  "VIEW_MANAGEMENT_TASKS": "Tasks",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Costs",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Status",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "On",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Edit Form",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_BETA": "Edit form (Beta)",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Sharing",
  "FORM_REMOVE_MODAL_TITLE": "Remove form",
  "FORM_REMOVE_MODAL_MESSAGE": "Are you sure you want to delete this form?",
  "REMOVE_DRAFT_TITLE": "Remove draft",
  "REMOVE_DRAFT_MESSAGE": "Are you sure you want to delete this draft?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Remove template",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "Are you sure you want to delete this template?",
  "UNPUBLISHED": "Unpublished",
  "PUBLISHED": "Published",
  "PUBLISHED_UPDATED": "Published, update available",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Manage users",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Place form on Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Shared on Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Hidden from Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "View in Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Edit",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Update to latest version",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "Your Form Template could not be updated to the latest version. Please try again later.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Hide from Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Show on Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Place form on Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "So you want to share your Form with all other users?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Please fill out this form first. This info (except your Bank Account) will be visible in the Marketplace.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Update info",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Namespace",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Example: com.mycompany",
  "FORM_TEMPLATE_KEY_LABEL": "Key",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Example: specialform",
  "FORM_TEMPLATE_TYPE_LABEL": "Type",
  "FORM_TEMPLATE_NAME_LABEL": "Name",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Name your Form Template",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Description",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Describe your Form Template",
  "FORM_TEMPLATE_LOGO_LABEL": "Logo",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "The URL of your Form Template logo",
  "DIRECT_FORM_LINK": "Generate direct URL",
  "DIRECT_FORM_LINK_GENERATED": "Open direct URL",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Revoke direct URL",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "Are you sure you want to revoke the URL for direct access to your form? This means the URL no longer works.",
  "PUBLISH": "Publish",
  "PUBLISH_FORM_VERSION": "Publish this version",
  "PUBLISH_FORM_VERSION_MESSAGE": "Are you sure you want to publish this version of your form?",
  "PUBLISHED_BY": "Published by",
  "PUBLISHED_ON": "Published",
  "REMARKS": "Remarks",
  "DRAFT": "Draft",
  "FORM_HISTORY": "Version history",
  "FORM_DESCRIPTION_PLACEHOLDER": "Add a short description of your form",
  "FORM_MOVE_MODAL_TITLE": "Move",
  "FORM_SAVE_AS_TEMPLATE": "Save as template",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "Are you sure you want to save this form as template?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Move the form to the selected folder",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Select folder",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "It appears you or a colleague are editing this form somewhere else. Make sure you are the only one editing this form and try again.",
  "INTEGRATIONS_EOL_MESSAGE": "This form uses an integration that has been disabled. Please remove or replace it with another integration or webhook. Please <a href=\"https://help.moreapp.com/en/support/tickets/new\">contact support</a> if you need help.",
  "DATASOURCE_PICKER_ADD": "Add a data source",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Search for data sources",
  "GROUP_PICKER_ADD_USER": "Invite user(s)",
  "GROUP_PICKER_ADD_GROUP": "+ Create a new group",
  "GROUP_PICKER_ALL_USERS": "All Users",
  "GROUP_PICKER_INVITED": "(Invited)",
  "MANAGE_ROLES": "Manage roles",
  "ROLE": "Role",
  "SEARCH_GROUP_PLACEHOLDER": "Search for groups",
  "BILLING_TITLE": "Billing",
  "BILLING_OVERVIEW_MENU_ITEM": "Overview",
  "BILLING_INVOICES_MENU_ITEM": "Invoices",
  "BILLING_USAGE_MENU_ITEM": "Usage",
  "SUBTOTAL": "Subtotal",
  "TOTAL": "Total",
  "NEXT_INVOICE": "Next Invoice",
  "PREVIEW_CHANGE_EXPLANATION": "This overview shows you the upcoming invoice after applying the changes. Check out this <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Help Center</a> article for more information about your invoice.",
  "PREVIEW_CHANGE": "Preview changes",
  "CHANGE_SUBSCRIPTION_BUTTON": "Change subscription",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "View change",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Planned subscription change",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Planned",
  "SCHEDULED_CANCEL_MESSAGE": "Your subscription is scheduled for cancellation at the end of the current billing period",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Cancel change",
  "SELECT_PLAN": "Choose your plan",
  "YOUR_SELECTED_PLAN": "Your selected plan",
  "LOADING_PLANS": "Loading available plans",
  "MONTHLY_PLAN": "Monthly Plan",
  "YEARLY_PLAN": "Annual Plan",
  "MONTH_SHORT": "mo",
  "YEAR_SHORT": "yr",
  "TRIAL_ACTIVE": "Trial active",
  "DAYS": "days",
  "TRIAL_DAYS_REMAINING": "days remaining",
  "TRIAL_ENDS_TODAY": "Your trial ends today",
  "TRIAL_ENDS_TOMORROW": "Your trial ends tomorrow",
  "TRIAL_ENDS_ON": "Your trial ends on",
  "SUBMISSIONS": "Submissions",
  "USERS": "Users",
  "USAGE": "Usage",
  "UNLIMITED": "Unlimited",
  "CHANGE_PLAN": "Change Plan",
  "CANCEL_PLAN": "Cancel Plan",
  "UNCANCEL_PLAN": "Uncancel Plan",
  "PLAN_MORE_OPTIONS": "More options",
  "DELETE_ACCOUNT": "Delete Account",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "You still have an open invoice",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "You still have an open invoice, therefore you can't perform this action. Navigate to your invoices to complete your payment.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Go to Invoices",
  "CANCEL_PLAN_MODAL_TITLE": "Cancel plan",
  "CANCEL_PLAN_MODAL_MESSAGE": "You can keep using our product until the end of your billing cycle. After that, submissions will not be processed. You can still access your account to view and export your data for 30 days. After that we will delete your account",
  "UNCANCEL_PLAN_MODAL_TITLE": "Uncancel plan",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Great, so you changed your mind and want to keep using our product?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "Plan already scheduled to cancel",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "Your plan is scheduled to be cancelled at the end of your current billing cycle.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "Please let us know how we can improve our product. Your feedback matters to us.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>It's not always a good fit</h4><p>Please let us know how we can improve our product. Your feedback matters to us.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_CANCEL_PLAN": "Why do you want to cancel your plan? You can select multiple answers.",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "Why do you want to delete your account?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "Found an alternative tool",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "It's too expensive",
  "TERMINATION_FEEDBACK_MODAL_REASON_RECENT_PRICE_INCREASE": "Recent price increase",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "No longer needed",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "I am closing down my business",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "I am not satisfied with the product",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "It's missing a feature",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Other",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Please select at least one reason.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "Is there anything else you want to share with us?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Please enter a message.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Cancel Plan",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Delete Account",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Yes, I want to delete my account",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "I understand that after <b>30 days</b>, my company account <b>{{customerName}}</b> ({{customerId}}) and all related data will be permanently deleted.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Please confirm by filling in your account name",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "Filled in account name does not match the name of the account you are trying to delete.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Delete Account",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "Failed to delete account. Please try again later.",
  "BILLING_PROFILE": "Billing Profile",
  "BILLING_PHONE_NUMBER": "Phone number",
  "INVOICE_ADDRESS": "Invoice Address",
  "PAYMENT_METHOD": "Payment Method",
  "UPCOMING_INVOICE": "Upcoming Invoice",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "On trial",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Testing our Product during the trial period is free. Ready to continue after your trial? Add a payment method to receive your first invoice.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Testing our Product during the trial period is free. After the trial period has ended, you will receive this next invoice.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Amount due after trial",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "No upcoming invoices",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "Your subscription has been cancelled. There are no upcoming invoices. Your billing period ends on ",
  "CARD_HOLDER_NAME": "Cardholder Name",
  "CARD_HOLDER_NAME_PLACEHOLDER": "e.g. John Smith",
  "ADD_CARD": "+ Add Payment Method",
  "MAKE_DEFAULT": "Make default",
  "EXPIRES": "expires",
  "PAYMENT_METHOD_REMOVE_TITLE": "Remove payment method",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "Are you sure you want to delete this payment method?",
  "PLAN_LEAF": "Leaf",
  "PLAN_BRANCH": "Branch",
  "PLAN_TREE": "Tree",
  "PLAN_FOREST": "Forest",
  "PLAN_LEAF_DESCRIPTION": "Create professional forms",
  "PLAN_BRANCH_DESCRIPTION": "Advanced data processing",
  "PLAN_TREE_DESCRIPTION": "For the entire company",
  "PLAN_FOREST_DESCRIPTION": "For enterprises",
  "PLAN_LEAF_FEATURES_HEADER": "Features",
  "PLAN_BRANCH_FEATURES_HEADER": "Everything in Leaf +",
  "PLAN_TREE_FEATURES_HEADER": "Everything in Branch +",
  "PLAN_FOREST_FEATURES_HEADER": "Everything in Tree +",
  "PLAN_AMOUNT_OF_USERS_ONE": "One user",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Unlimited users",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} free submissions",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} trees",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "€0.40 / extra submission",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "€0.40 / extra submission",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "€0.40 / extra submission",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "€0.10 / extra submission",
  "FEATURE_FORM_BUILDER": "Form Builder",
  "FEATURE_APP": "App",
  "FEATURE_EXCEL_EXPORT": "Excel export",
  "FEATURE_EMAIL": "Email with PDF",
  "FEATURE_RULES": "Rules",
  "FEATURE_API": "API access",
  "FEATURE_MARKETPLACE_TEMPLATES": "Templates",
  "FEATURE_TASKS": "Tasks",
  "FEATURE_LOGO_PDF": "Your logo on PDF's",
  "FEATURE_DATA_IMPORT": "Data import",
  "FEATURE_MULTIPLE_PDF": "Multiple emails",
  "FEATURE_ADVANCED_PDF": "Custom PDF",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Automatic data import",
  "FEATURE_SINGLE_SIGN_ON": "Single sign-on",
  "FEATURE_ZAPIER": "Zapier",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Single sign-on",
  "FEATURE_SENDING_DOMAIN": "Custom email domain",
  "FEATURE_DEDICATED_SUPPORT": "Dedicated support",
  "VAT": "VAT",
  "APPLIED_BALANCE": "Applied balance",
  "AMOUNT_DUE": "Amount due",
  "REMAINING_BALANCE": "Remaining balance",
  "CREDIT_CARD": "Credit Card",
  "SEPA_DIRECT_DEBIT": "SEPA Direct Debit",
  "PAY_ON_FILE": "Manual",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "This option is not available for your current subscription",
  "SEPA_MANDATE": "By providing your IBAN, you are authorizing (A) MoreApp and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. This mandate is only intended for business-to-business transactions. You are not entitled to a refund from your bank after your account has been debited, but you are entitled to request your bank not to debit your account up until the day on which the payment is due.",
  "INVOICE_NUMBER": "Invoice number",
  "INVOICE_DATE": "Date",
  "INVOICE_TOTAL": "Total",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "Status",
  "BASE_MONTH": "Plan monthly",
  "BASE_YEAR": "Plan yearly",
  "FORMS_MONTH": "Usage monthly",
  "FORMS_YEAR": "Usage yearly",
  "BRANDING_MONTH": "Branding monthly",
  "BRANDING_YEAR": "Branding yearly",
  "ADDON_MONTH": "Add-on monthly",
  "ADDON_YEAR": "Add-on yearly",
  "MAINTENANCE_FTPS_MONTH": "Maintenance - FTPS monthly",
  "MAINTENANCE_FTPS_YEAR": "Maintenance - FTPS yearly",
  "MANUAL_INVOICE_DESCRIPTION": "Invoices will be sent to your billing email address",
  "USAGE_DAILY": "Daily",
  "USAGE_MONTHLY": "Monthly",
  "MONTH": "Month",
  "YEAR": "Year",
  "INVOICE_REFERENCE": "Additional invoice information",
  "INVOICE_REFERENCE_DESCRIPTION": "This information will be added to the invoice for your administration, for example a purchase order number.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "This change will be effective immediately",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "This change will be effective from your next invoice",
  "TREES_PLANTED": "Trees planted",
  "TREES_PLANTED_TOTAL": "trees planted in total",
  "TREES_PLANTED_PER_MONTH": "trees planted each month",
  "TREES_PLANTED_PER_YEAR": "trees planted each year",
  "DOWNGRADE_QUESTION": "Why do you want to change your plan?",
  "DOWNGRADE_NOT_NEEDED": "Not needed anymore",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Remarks",
  "DOWNGRADE_ALTERNATIVE": "Found an alternative",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "Which alternative?",
  "DOWNGRADE_MISSING_FEATURE": "Missing a feature",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "What feature are you missing?",
  "DOWNGRADE_PRICING": "Too expensive",
  "DOWNGRADE_PRICING_DESCRIPTION": "Remarks",
  "DOWNGRADE_UNSATISFIED": "Unsatisfied",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "What can we improve?",
  "DOWNGRADE_OTHER": "Other",
  "DOWNGRADE_OTHER_DESCRIPTION": "What is your reason?",
  "SELECT_OPTION": "Select an option",
  "CONTACT_BUTTON": "Contact us",
  "CONTACT_LINK": "https://meet.moreapp.com/meetings/moreapp/tours-en",
  "PRICE_LABEL_NOW": "now",
  "PRICE_LABEL_UPCOMING": "from Jan 2025",
  "PRICE_ANNUAL_BILLED_MONTHLY": "Billed annually",
  "BILLING_FLOW_STEP1_LABEL": "Select Plan",
  "BILLING_FLOW_STEP2_LABEL": "Billing details",
  "BILLING_FLOW_STEP3_LABEL": "Payment method",
  "BILLING_FLOW_STEP4_LABEL": "Confirm",
  "ADMIN_SETTINGS_TITLE": "Settings",
  "ADMIN_FINANCIAL_TITLE": "Financial",
  "ONETIME": "One Time",
  "MONTHLY": "Monthly",
  "YEARLY": "Yearly",
  "RECURRENCE": "Recurrence",
  "ADMIN_CUSTOMERS_TITLE": "Customers",
  "ADMIN_CUSTOMER_NO_CUSTOMERS": "No customers",
  "ADMIN_REGIONS_TITLE": "Regions",
  "ADMIN_QUEUE_TITLE": "Queue",
  "ADMIN_QUEUE_DETAILS_TITLE": "Queue details",
  "ADMIN_QUEUE_DETAILS_TRIGGER_BUTTON": "Request retry",
  "BRANDING": "Branding",
  "USER_DELETE": "Delete user",
  "USER_DELETE_MESSAGE": "You're trying to delete a user, this can never be reverted. Ever.",
  "CUSTOMER_DELETE": "Delete customer",
  "CUSTOMER_DELETE_MESSAGE": "You're trying to delete customer <b>{{customerName}} ({{customerId}})</b>, this can never be reverted. Ever.",
  "RESTORE": "Restore",
  "RESTORE_FORM_MESSAGE": "By restoring this form, it will re-appear in the list of forms in the platform. It will also show in the app, if the form was published.",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Create your account for free",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "Start 14-day trial",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "Email Address",
  "SIGN_UP_CUSTOMER_PASSWORD": "Password",
  "SIGN_UP_CUSTOMER_BUTTON": "Create account",
  "EMAIL_ADDRESS_PLACEHOLDER": "email@company.com",
  "EMAIL_VALIDATION_VALIDATING": "Validating email...",
  "EMAIL_VALIDATION_FAILED": "Failed to validate the email address. We could not find the account corresponding your account validation. Your account may already be validated.",
  "EMAIL_VALIDATION_SUCCESS": "Your email address is successfully verified!",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Click image to add pins",
  "ALERT_TOUCH_DEVICE": "The platform doesn't work properly on mobile devices. Please use a laptop or PC",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>You have an expired payment method!</b> Make sure you <a href ui-sref='portal.customers.billing.overview'>add a new payment method</a> to continue submitting forms.",
  "ADD_VIEW_TEMPLATE_TYPE": "Using template:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "Blank",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Installed",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Get more forms from the Marketplace",
  "BLANK_FORM": "Blank",
  "BLANK_FORM_DESCRIPTION": "Start with an empty form",
  "AGREEMENTS_MODAL_TITLE": "Terms and Conditions Update",
  "AGREEMENTS_MODAL_MESSAGE": "We've updated our End User Agreement including its Data Processing Addendum. Please read them carefully. If you have any questions or concerns, please contact us. Otherwise, no action is required by you and the updated terms and conditions are effectuated per February 1, 2019.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Continue",
  "ADD_PHOTO": "Add photo",
  "CAPTURE_PHOTO": "Capture photo",
  "BROWSE_PHOTO": "Browse photos",
  "DRAW_SIGNATURE": "Draw signature",
  "START_DRAWING": "Start drawing",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Choose background image",
  "ADD_VIDEO": "Add video",
  "SIGN_UP_THANKS": "Thanks for signing up!",
  "SIGN_UP_CHECK_MAIL": "Please check your inbox to verify your email address.",
  "FORM_BUILDER": "Form Builder",
  "MOBILE_APP": "Mobile App",
  "PDF_REPORTS": "PDF Reports",
  "EXCEL_EXPORT": "Excel Export",
  "TASKS": "Tasks",
  "PDF_LOGO": "Custom PDF Logo",
  "REALTIME_DATA_SOURCES": "Realtime Data Sources",
  "PDF_LAYOUT": "Custom PDF Layout",
  "RULES": "Rules",
  "API_ACCESS": "API Access",
  "COLOR_THEMES": "Color Themes",
  "THEMES_SYSTEM": "Default themes",
  "THEMES_CUSTOM": "Custom themes",
  "GROUP_ROLES": "Group Roles & Permissions",
  "CUSTOM_DOMAIN": "Custom Domain",
  "DEDICATED_ACCOUNT_MANAGER": "Dedicated Account Manager",
  "PREMIUM_SUPPORT": "Premium Support",
  "FILE_SELECT": "Select a file",
  "NEW_CUSTOMER": "+ New Customer",
  "TO_PORTAL": "To Portal",
  "EXPIRED": "Expired",
  "READ_MORE": "Read more",
  "CHOOSE_A_PLAN": "Choose a plan",
  "TRIAL": "Trial",
  "TRIAL_BANNER_TITLE": "You're on a free trial",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} days left",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} submissions left",
  "TRIAL_BANNER_CTA_CONTACT": "Talk to us",
  "TRIAL_BANNER_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-en",
  "UPGRADE_NOW": "Upgrade now",
  "CONTACT_SALES": "Talk to us",
  "BANNER_DELETION_LABEL": "Your account is scheduled to be deleted in {{days}} day(s)",
  "BANNER_DELETE_TODAY_LABEL": "Your account will be deleted today",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Choose a plan to continue using MoreApp",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Please select a plan to continue using MoreApp. Otherwise, this account will be deleted in {{days}} day(s).",
  "BANNER_NO_SUBSCRIPTION_TITLE": "No active subscription",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Please select a plan to continue",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Important:</b> Due to scheduled <a href='https://help.moreapp.com/support/solutions/articles/13000100648' target='_blank'>maintenance</a> work, MoreApp will not be available on Wednesday 19/04/2023 between 19:00-21:00 CET",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Important:</b> Due to scheduled maintenance work, the Product will not be available on Wednesday 19/04/2023 between 19:00-21:00 CET.",
  "BANNER_OVER_USAGE_TITLE": "You’ve exceeded the number of free submissions.",
  "BANNER_OVER_USAGE_DESCRIPTION": "✈️ {{ nrOfSubmissionsOverIncluded }} extra submissions 💰 {{ (extraUsageCostsInCents / 100 | currencySymbol:'eur') }} extra cost",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Upgrade now",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Talk to us",
  "BANNER_OVER_USAGE_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-en",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "Your credit card has expired.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Please add a valid payment method.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Update Payment Method",
  "BANNER_PAST_DUE_INVOICE_TITLE": "You have an unpaid invoice.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Please pay your invoice before your account gets blocked.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Pay Invoice",
  "BANNER_UNPAID_INVOICE_TITLE": "Your account is blocked due to an unpaid invoice.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Pay your invoice to unblock.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Pay Invoice",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Select columns overview",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Edit Data Source",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Upload Excel",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Schedule import",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Search",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Enable",
  "DATA_SOURCES_TITLE": "Data Sources",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Last updated",
  "DATA_SOURCES_GENERAL_INFORMATION": "General Information",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Last successful update",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Show warnings",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "URL Configuration",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Schedule url import",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Importing...",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Schedule Excel import",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Importing...",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Last warnings:",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "( Show all {{numberOfWarningMessages}} )",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Show less )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Upload Excel file",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "The selected file is too big to upload. The file limit for the upload of excel files is 10MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Select an Excel file to be imported. Make sure the extension for the Excel file is `.xlsx`. `.xls` files will not be imported.",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "drop files here",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "HTML5 Drop File is not supported!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Entry Details",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Parameters",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Last update type",
  "DATA_SOURCES_SEARCH_LABEL": "Data Source",
  "DATA_SOURCES_ADD_BUTTON": "+ Add data source",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Add a data source",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Edit data source",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Name",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Enter a name for the data source",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Type",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Enter an URL to retrieve the data source from",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Parameters",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Add Parameter",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "No parameters",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Key",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Value",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "Request headers",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Add request header",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Key",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Value",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Automatic import",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Basic Authentication",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Authenticate with Google",
  "AUTHENTICATED_WITH_GOOGLE": "Authenticated with Google",
  "CHANGE_ACCOUNT": "change account",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Value between /d/ and /edit in the URL of your spreadsheet",
  "DATA_SOURCE_GRID_NO_ENTRIES": "There are no entries for this data source.",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Loading entries",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Importing entries",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Last failed update",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Last updated: never",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Last updated",
  "DATA_SOURCE_WARNINGS_TITLE": "Import warnings",
  "DATA_SOURCE_REMOVE_TITLE": "Remove Data Source",
  "DATA_SOURCE_REMOVE_QUESTION": "Are you sure you want to delete this data source? You cannot undo this action!",
  "DATA_SOURCE_ENABLE_TITLE": "Enable Data Source",
  "DATA_SOURCE_ENABLE_MESSAGE": "The data source was disabled because 10 or more consecutive imports failed. Make sure you have a correct url configuration. If you enable the data source, we will try again.",
  "CLIENTS": "Clients",
  "CLIENTS_TITLE": "Clients",
  "SETTINGS_TITLE": "Settings",
  "SETTINGS_TAB_GENERAL": "General",
  "SETTINGS_TAB_SSO": "Single sign-on",
  "SETTINGS_TAB_SENDING_DOMAIN": "Email domain",
  "BILLING_SUCCESS_MESSAGE": "Successfully updated customer billing information.",
  "BILLING_DETAILS_HEADER": "Customer",
  "BILLING_INVOICES_HEADER": "Customer",
  "GENERAL_HEADER": "General",
  "GENERAL_SUCCESS_MESSAGE": "Successfully updated customer settings.",
  "LOGO_HEADER": "Logo",
  "LOGO_SELECT": "Select logo",
  "SETTINGS_DATE_FORMAT": "Date format",
  "SETTINGS_CUSTOMER_NAME": "Company name",
  "SETTINGS_TAB_THEMES": "Themes",
  "SETTINGS_TAB_THEMES_CUSTOM": "Custom Themes",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Add Theme",
  "THEME_FORM": "Theme",
  "THEME_FORM_NAME_LABEL": "Name",
  "THEME_FORM_BUTTON_LABEL": "Button",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Save Button",
  "THEME_FORM_BAR_LABEL": "Bar",
  "THEME_FORM_BACKGROUND_LABEL": "Background",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Service Accounts",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "New API key",
  "API_KEY_CREATED": "New API key created",
  "API_KEY_SAFETY": "Keep it safe",
  "API_KEY_CREATED_MESSAGE": "Please store this key in a secure place, like a password manager. <br><b>You won't be able to see it again.</b",
  "API_KEY_REMOVE_TITLE": "Delete API key?",
  "API_KEY_REMOVE_MESSAGE": "Deleting this key may cause integrations that use it to stop working! It cannot be restored once deleted.",
  "WEBHOOK_DETAILS": "Webhook details",
  "WEBHOOK_ATTEMPTS": "Webhook attempts",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Attempts to send an event to your endpoint in the past 30 days",
  "WEBHOOK_REQUEST_BODY": "Request body",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "The payload that we sent to your endpoint",
  "WEBHOOK_RESPONSE": "Response",
  "WEBHOOK_RESPONSE_MESSAGE": "The response we received from your endpoint",
  "WEBHOOK_REMOVE_TITLE": "Delete webhook?",
  "WEBHOOK_REMOVE_MESSAGE": "Are you sure you want to delete this webhook? Did you know you can also <b>disable</b> it from the details view?",
  "ADD_SUBSCRIBER": "Add subscriber",
  "EVENT_TYPES": "Event types",
  "SELECT_EVENT_TYPES": "Select the event types you want to subscribe to",
  "SECRET": "Secret",
  "SERVICE_ACCOUNTS_NAME": "Name",
  "SERVICE_ACCOUNTS_TYPE": "Type",
  "SERVICE_ACCOUNTS_ACTIONS": "Actions",
  "SERVICE_ACCOUNTS_RECONNECT": "Reconnect",
  "SERVICE_ACCOUNTS_DELETE": "Delete",
  "SERVICE_ACCOUNTS_TYPE_GOOGLE_SHEETS": "Google Sheets",
  "SERVICE_ACCOUNTS_TYPE_ONEDRIVE": "OneDrive",
  "SERVICE_ACCOUNTS_ADD": "Add Service Account",
  "SERVICE_ACCOUNTS_NON_FOUND": "No service accounts configured",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "Failed to load service accounts",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "Failed to update service account",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "Failed to create service account",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "Failed to reconnect service account",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "Failed to delete service account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Delete service account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "Are you sure you want to delete service account <b>'{{ displayName }}'</b>? <br><br> Forms with integrations that use this service account will stop working.",
  "GET_STARTED": "Get started",
  "MANAGE": "Manage",
  "SSO_DSYNC": "Directory sync",
  "SSO": "Single sign-on",
  "SSO_DESCRIPTION": "Connect your company directory and sign on method to simplify user management",
  "SSO_SHARED_WARNING": "This Single Sign-On (SSO) configuration is used by multiple customers, any changes will affect all.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Use single sign-on to sign in with the credentials you use for all your other applications.</h4><p></p><br><p>This feature is available starting from the <b>Forest</b> plan.</p>",
  "SSO_CONNECTION_ACTIVE": "Active",
  "SSO_CONNECTION_INACTIVE": "Inactive",
  "SSO_CONNECTION_DRAFT": "Draft",
  "SSO_CONNECTION_PENDING": "Pending",
  "DSYNC_CONNECTION_LINKED": "Linked",
  "DSYNC_CONNECTION_UNLINKED": "Unlinked",
  "DSYNC_CONNECTION_PENDING": "Pending",
  "SSO_STEP_1_TITLE": "Get Started",
  "SSO_STEP_2_TITLE": "Configure Single Sign-On (SSO)",
  "SSO_STEP_2_DESCRIPTION": "Connect to your identity provider",
  "SSO_STEP_3_TITLE": "Configure Directory Synchronisation",
  "SSO_STEP_3_DESCRIPTION": "Synchronise your groups and users to keep them up-to-date",
  "SSO_STEP_4_TITLE": "SSO enabled",
  "SSO_STEP_4_DESCRIPTION": "Your users can now use SSO to log in",
  "SSO_IMPORTED_USERS": "{{count}} imported users from your directory",
  "SSO_IMPORTED_GROUPS": "{{count}} imported groups from your directory",
  "SSO_NO_IMPORTED_USERS_GROUPS": "No imported users or groups",
  "SENDING_DOMAIN_TITLE": "Setup your email domain",
  "SENDING_DOMAIN_DESCRIPTION": "Make emails more recognizable and consistent with your company brand. We'll send emails (like PDF reports) from <b>mycompany.com</b> instead of moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "This email domain is used by multiple customers, any changes will affect all.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "To change to a different domain, just delete it and create a new one",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Domain",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Domain",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "The domain used to send emails from, like <a>mycompany.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Default From Name",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "A name or title that will be recognizable to your recipients. In most cases, this will be your company name.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "Default From Email",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "The from email will be used as origin email address. This will also be the reply-to.",
  "SENDING_DOMAIN_DELETE_TITLE": "Delete custom email domain?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "This means your emails will be sent from our default domain again. You will no longer need the associated DNS records. You can remove them from your domain provider.",
  "SENDING_DOMAIN_VERIFY_TITLE": "Add DNS Records",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Add these DNS records to your domain provider to verify that you own this domain. Verification may take up to 48 hours while your record changes propagate.",
  "SENDING_DOMAIN_VERIFY_BTN": "Verify records",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Invalid domain '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "From email should be from the '{{domain}}' domain",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>Send emails (like PDF reports) from your company domain</h4><p>This feature is available starting from the <b>Branch</b> plan.</p>",
  "SETTINGS_SUCCESS_MESSAGE": "Your settings have been saved",
  "DYNAMIC_RECIPIENTS_ADD": "Add recipient",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Default",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "Email fields",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Other fields",
  "TASKS_NEW": "New task",
  "TASKS_PUBLISH_DATE": "Published",
  "TASKS_MESSAGE": "Message",
  "TASKS_AND_X_OTHERS": "and {{others}} more",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "There was a failure while loading the tasks. Sorry for the inconvenience. You may try to refresh the page.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "The selected folder is not published. To create tasks for this folder you have to publish it in the forms section.",
  "TASK_ADD_TITLE": "New task",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Select user(s) and/or group(s)",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} user(s)",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "There are no users in this group",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Users in this group:",
  "TASK_ADD_MESSAGE_LABEL": "Message",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "There are no users or groups with access to this form. Please give your users access using 'Manage Users' in the form overview.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Send task",
  "TASK_DETAIL_TITLE": "Task",
  "TASK_DETAIL_MESSAGE": "Message",
  "TASK_DETAIL_ACTIONS": "Actions",
  "TASK_DETAIL_ACTION_COMPLETE": "Complete",
  "TASK_DETAIL_ACTION_REVOKE": "Revoke",
  "TASKS_GRID_NO_ENTRIES": "There are no tasks for this form",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Immediately",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "In 1 hour",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "In 2 hours",
  "TASKS_ADD_PUBLISH_TOMORROW": "Tomorrow",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Fulfilled",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "Pending",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Declined",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Revoked",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Completed",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Inserted submission on row: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Created tasks for:",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "No task was created because there were no recipients",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "Report has been emailed to:",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "Report has been emailed to:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO_SELECT_BUTTON": "Replace default logo"
}
;