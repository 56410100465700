'use strict';

angular.module('more.ui.form-builder').factory('$locationHelper', ['$q', '$timeout', function ($q, $timeout) {

  var seconds = function (seconds) {
    return seconds * 1000;
  };

  return {
    getLocation: function () {
      var deferred = $q.defer();

      var locationTimeout = $timeout(function () {
        locationTimeout = null;
        deferred.reject();
      }, seconds(20));

      var cancelLocationTimeout = function () {
        if (locationTimeout) {
          $timeout.cancel(locationTimeout);
          locationTimeout = null;
        }
      };

      navigator.geolocation.getCurrentPosition(function (position) {
        cancelLocationTimeout();
        deferred.resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      }, function () {
        cancelLocationTimeout();
        deferred.reject();
      }, { maximumAge: seconds(90), timeout: seconds(10), enableHighAccuracy: true });
      return deferred.promise;
    },
    calcKmsBetweenPositions: function (lat1, lon1, lat2, lon2) {
      var R = 6371;
      var a = 0.5 - Math.cos((lat2 - lat1) * Math.PI / 180) / 2 + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * (1 - Math.cos((lon2 - lon1) * Math.PI / 180)) / 2;

      return R * 2 * Math.asin(Math.sqrt(a));
    }
  };
}]);