'use strict';

angular.module('FormManagement').controller('FormManagementPermissionsCtrl', ['$q', 'form', '$customerRolesService', '$customerGroupsService', '$customerUserService', '$customerInviteService', '$scope', '$state', '$translate', 'moreConstants', function ($q, form, $customerRolesService, $customerGroupsService, $customerUserService, $customerInviteService, $scope, $state, $translate, moreConstants) {
    const self = this;

    self.form = form;
    self.groups = [];
    self.allUsers = [];
    self.users = [];
    self.roles = [];
    self.adding = false;
    self._tmp = {};

    self.changeUserGrant = changeUserGrant;
    self.changeGroupGrant = changeGroupGrant;
    self.addGroupOrUser = addGroupOrUser;
    self.addUser = addUser;
    self.addGroup = addGroup;
    self.removeUser = removeUser;
    self.removeGroup = removeGroup;
    self.getAvailableGroupsAndUsers = () => self.availableGroups && self.availableUsers ? self.availableGroups.concat(self.availableUsers) : [];
    self.getUsersByGroupId = (groupId) => self.allUsers.filter(u => u.groups.indexOf(groupId) !== -1);
    self.getRoleInfo = $customerRolesService.getRoleInfo;

    init();

    ////////
    function init() {
        _loadGrants(form.id);
    }

    function addGroupOrUser (userOrGroup, role) {
        if (userOrGroup.type === $translate.instant('GROUP')) {
            addGroup(userOrGroup, role);
        } else {
            addUser(userOrGroup, role);
        }
    }

    function changeUserGrant(user, newRole) {
        const removeGrant = {
            operation: 'REMOVE',
            resourceId: form.id,
            resourceType: 'FORM'
        };
        const addGrant = {
            operation: 'ADD',
            roleId: newRole.id,
            resourceId: form.id,
            resourceType: 'FORM'
        };
        if (user.isInvite) {
            $customerInviteService.patchGrant($state.params.customerId, user.id, removeGrant)
              .then(() => $customerInviteService.patchGrant($state.params.customerId, user.id, addGrant))
              .then(() => _loadUsers(user.resourceId));
        } else {
            $customerUserService.patchGrant($state.params.customerId, user.id, removeGrant)
                .then(() => $customerUserService.patchGrant($state.params.customerId, user.id, addGrant))
                .then(() => _loadUsers(user.resourceId));
        }
    }

    function changeGroupGrant(group, newRole) {
        const removeGrant = {
            operation: 'REMOVE',
            resourceId: form.id,
            resourceType: 'FORM'
        };
        const addGrant = {
            operation: 'ADD',
            roleId: newRole.id,
            resourceId: form.id,
            resourceType: 'FORM'
        };
        $customerGroupsService.patchGrant($state.params.customerId, group.id, removeGrant)
          .then(() => $customerGroupsService.patchGrant($state.params.customerId, group.id, addGrant))
          .then(() => _loadGroups(group.resourceId));
    }

    function removeUser(user) {
        const removeGrant = {
            operation: 'REMOVE',
            resourceId: form.id,
            resourceType: 'FORM'
        };
        if (user.isInvite) {
            $customerInviteService.patchGrant($state.params.customerId, user.id, removeGrant)
                .then(() => _loadUsers(user.resourceId));
        } else { // user
            $customerUserService.patchGrant($state.params.customerId, user.id, removeGrant)
              .then(() => _loadUsers(user.resourceId));
        }
    }

    function addUser(user, role) {
        const addGrant = {
            operation: 'ADD',
            resourceId: form.id,
            roleId: role.id,
            resourceType: 'FORM'
        };
        if (user.isInvite) {
            $customerInviteService.patchGrant($state.params.customerId, user.id, addGrant)
              .then(() => _loadUsers(form.id))
              .finally(_resetAddForm);
        } else {
            $customerUserService.patchGrant($state.params.customerId, user.id, addGrant)
              .then(() => _loadUsers(form.id))
              .finally(_resetAddForm);
        }
    }

    function addGroup(group, role) {
        const addGrant = {
            operation: 'ADD',
            resourceId: form.id,
            roleId: role.id,
            resourceType: 'FORM'
        };
        self.adding = true;
        $customerGroupsService.patchGrant($state.params.customerId, group.id, addGrant)
          .then(() => _loadGroups(form.id))
          .finally(_resetAddForm);
    }

    function removeGroup(group) {
        const removeGrant = {
            operation: 'REMOVE',
            resourceId: form.id,
            resourceType: 'FORM'
        };
        self.adding = true;
        $customerGroupsService.patchGrant($state.params.customerId, group.id, removeGrant)
          .then(() => _loadGroups(group.resourceId));
    }

    function _loadGrants(resourceId) {
        $customerRolesService.getRoles($state.params.customerId).then(roles => {
            // Filter all roles that don't do anything with this form
            self.roles = roles
                .filter(role => role.permissions.find(permission => moreConstants.ALLOWED_FORM_PERMISSIONS.find(allowedPermission => allowedPermission === permission)));
            _loadGroups(resourceId);
            _loadUsers(resourceId);
        });
    }

    function _loadGroups(resourceId) {
        return $customerGroupsService.getGroups($state.params.customerId).then(groups => {
            self.groups = groups
                .filter(group => group.grants && group.grants.find(grant => grant.resourceId === resourceId))
                .map(group => {
                    const resourceGrant = group.grants.find(grant => grant.resourceId === resourceId);
                    const resourceRole = self.roles.find(role => role.id === resourceGrant.roleId);
                    return {
                        id: group.id,
                        name: group.name,
                        resourceId: resourceId,
                        role: resourceRole,
                        type: $translate.instant('GROUP')
                    };
                });
            self.availableGroups = groups
                .filter(x => !self.groups.find(group => group.id === x.id))
                .map(group => {
                    return {
                        id: group.id,
                        name: group.name,
                        type: $translate.instant('GROUP')
                    };
                });
        });
    }

    function _loadUsers(resourceId) {
        const usersPromise = $customerUserService.getUsers($state.params.customerId).$promise;
        const invitePromise = $customerInviteService.getInvites($state.params.customerId).$promise;

        return $q.all([usersPromise, invitePromise]).then(res => {
            const [theUsers, invites] = res;
            const users = invites.map(i => ({
                username: i.emailAddress,
                id: i.id,
                isInvite: true,
                grants: i.grants,
                groups: i.groups
            })).concat(theUsers);

            self.allUsers = users.map(user => ({name: user.username, groups: user.groups}));
            self.users = users
                .filter(user => user.grants && user.grants.find(grant => grant.resourceId === resourceId))
                .map(user => {
                    const resourceGrant = user.grants.find(grant => grant.resourceId === resourceId);
                    const resourceRole = self.roles.find(role => role.id === resourceGrant.roleId);
                    return {
                        id: user.id,
                        name: user.username,
                        resourceId: resourceId,
                        role: resourceRole,
                        isInvite: Boolean(user.isInvite),
                        disabled: Boolean(user.disabled),
                        type: $translate.instant('USER')
                    };
                });
            self.availableUsers = users
                .filter(x => !self.users.find(user => user.id === x.id))
                .map(user => {
                    return {
                        id: user.id,
                        name: Boolean(user.isInvite) ? user.username + ' ' + $translate.instant('USER_PICKER_INVITED'): user.username,
                        isInvite: Boolean(user.isInvite),
                        disabled: Boolean(user.disabled),
                        type: $translate.instant('USER')
                    };
                });
        });
    }

    function _resetAddForm() {
        self._tmp = {};
        self.adding = false;
    }
}]);
