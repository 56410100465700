'use strict';

angular.module('FormEditor').controller('EmailNotificationCtrl', ['$scope', '$rootScope', '$customerFormService', '$timeout', '$mailUtils', '$formVersionUtils', '$modalService', function ($scope, $rootScope, $customerFormService, $timeout, $mailUtils, $formVersionUtils, $modalService) {

  var self = this;
  self.attachments = getAttachments();
  self.validateEmail = validateEmail;
  self.addAttachments = addAttachments;
  self.toggleAttachPdf = toggleAttachPdf;
  self.toggleAttachment = toggleAttachment;
  self.toggleUseHeaderAndFooter = toggleUseHeaderAndFooter;
  self.toggleHideNoValues = toggleHideNoValues;
  self.clickResetPDF = clickResetPDF;
  self.enforcePdfFilenameSuffix = enforcePdfFilenameSuffix;

  init();

  ////////
  function init() {
    $scope.$watch('targetView', function () {
      self.attachments = getAttachments();
    });

    $scope.$on('formbuilder.email.advanced', function () {
      if (!$scope.trigger.pdfContent) {
        resetPdfHtml($scope.trigger);
      }
    });

    $rootScope.$on('more-form.field-removed', function () {
      self.attachments = getAttachments();
      sanitizeSelectedAttachments();
    });
  }

  function validateEmail(currentTrigger) {
    if ($scope.statics !== undefined && $scope.statics !== null) {
      currentTrigger.staticRecipients = [];
      for (var i = 0; i < $scope.statics.length; i++) {
        if ($mailUtils.isEmailAddress($scope.statics[i])) {
          currentTrigger.staticRecipients.push($scope.statics[i]);
        }
      }
    }
  }

  function toggleAttachment(currentTrigger, dataName) {
    var index = currentTrigger.configuration.attachments.indexOf(dataName);
    if (index === -1) {
      currentTrigger.configuration.attachments.push(dataName);
    } else {
      currentTrigger.configuration.attachments.splice(index, 1);
    }
  }

  function sanitizeSelectedAttachments() {
    if (!$scope.trigger.configuration.attachments) {
      return;
    }
    $scope.trigger.configuration.attachments.forEach(function (dataName, index, object) {
      if (self.attachments.indexOf(dataName) === -1) {
        object.splice(index, 1);
      }
    });
  }

  function getAttachments() {
    return $formVersionUtils.getAttachments($scope.targetView);
  }

  function addAttachments(currentTrigger) {
    currentTrigger.configuration.attachments = angular.copy(self.attachments);
  }

  function toggleAttachPdf(currentTrigger) {
    currentTrigger.configuration.attachPdf = !currentTrigger.configuration.attachPdf;
    if (!currentTrigger.configuration.attachPdf) {
      return;
    }

    if (!currentTrigger.configuration.attachments) {
      addAttachments(currentTrigger);
    }

    if (!currentTrigger.configuration.simpleMode) {
      resetPdfHtml(currentTrigger);
    }
  }

  function toggleUseHeaderAndFooter(currentTrigger) {
    currentTrigger.configuration.useHeaderAndFooter = !currentTrigger.configuration.useHeaderAndFooter;
  }

  function toggleHideNoValues(currentTrigger) {
    currentTrigger.configuration.hideNoValues = !currentTrigger.configuration.hideNoValues;
  }

  function clickResetPDF(currentTrigger) {
    $modalService.confirmModal({
      title: 'APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE',
      message: 'APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE',
      confirmButtonTitle: 'OK',
      showExclamation: true
    }).then(function () {
      resetPdfHtml(currentTrigger);
    });
  }

  function resetPdfHtml(currentTrigger) {
    var customerId = $scope.$state.params.customerId;
    var view = $scope.targetView;
    if ($scope.form) {
      $customerFormService.getDefaultPdf(customerId, view, currentTrigger.configuration).$promise.then(function (content) {
        currentTrigger.pdfContent = content.pdf;
      });
    }
  }

  function enforcePdfFilenameSuffix() {
    if ($scope.trigger.pdfFilename.trim().length === 0) {
      $scope.trigger.pdfFilename = 'registration.pdf';
    } else if ($scope.trigger.pdfFilename.indexOf('.pdf', $scope.trigger.pdfFilename.length - '.pdf'.length) === -1) {
      $scope.trigger.pdfFilename += '.pdf';
    }
  }
}]);
