'use strict';

angular.module('DataSources').directive('datasourceExcelConfiguration', function () {
  const MAX_SIZE = 1024 * 1024 * 10;

  return {
    restrict: 'E',
    template: require('../templates/directives/datasource-excel-configuration.html'),
    scope: {
      ngModel: '=',
      selectedFiles: '&'
    },
    link: function (scope) {
      scope.didSelectFiles = function ($files) {

        scope.fileTooLarge = false;
        $files.forEach(function (file) {
          if (file.size > MAX_SIZE) {
            scope.fileTooLarge = true;
          }
        });

        if (scope.fileTooLarge) {
          scope.selectedFiles({ files: null });
        } else {
          scope.selectedFiles({ files: $files });
        }
      };
    }
  };
});
