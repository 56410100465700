'use strict';

angular.module('MoreAppsServices').factory('$hookService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var Hooks = $resource(moreConstants.apiEndpoint + '/customers/:customerId/plugins/hooks/:namespace/:key', { 'customerId': '@customerId', 'namespace': '@namespace', 'key': '@key' }, {
    'get': { method: 'GET', isArray: false }
  });

  var ActiveHooks = $resource(moreConstants.apiEndpoint + '/customers/:customerId/plugins/hooks', { 'customerId': '@customerId' }, {
    'get': { method: 'GET', isArray: false }
  });

  return {
    getAllActiveHooks: function (customerId) {
      return ActiveHooks.query({ customerId: customerId });
    },
    getHook: function (customerId, namespace, key) {
      return Hooks.get({ customerId: customerId, namespace: namespace, key: key });
    }
  };
}]);
