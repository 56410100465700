'use strict';

angular.module('MoreDirectives').directive('wizardStep', function () {
  return {
    restrict: 'E',
    template: require('../../MoreDirectives/templates/wizard-step.html'),
    scope: {
      step: '@',
      title: '@',
      description: '@',
      status: '@', // done, open, warn
    },
    transclude: true,
    replace: true,
    link: function (scope) {
      scope.status = scope.status || 'open';
    }
  };
});