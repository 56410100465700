'use strict';

angular.module('more.ui.form-builder').directive('registrationFileImg', ['$state', 'moreConstants', function ($state, moreConstants) {

  var registrationFilePrefix = 'gridfs://registrationFiles/';
  var registrationFilesURL = moreConstants.apiEndpoint + '/client/registrations/files/';

  return {
    restrict: 'E',
    template: '<img ng-if="_registrationPath" data-ng-src="{{ _registrationPath }}" data-ng-style="_style" >' + '<customer-resource ng-if="resourceId && !_registrationPath" data-resource="::resourceId" data-customer-id="::customerId"></customer-resource>' + '<placeholder-image ng-if="!resourceId && !_registrationPath"></placeholder-image>',
    scope: true,
    link: function (scope) {
      scope.$watch('initialValue', newValue => {
        let fileId;
        if (typeof newValue === 'string' && newValue.indexOf(registrationFilePrefix) === 0) {
          fileId = newValue.replace(registrationFilePrefix, '');
          scope._registrationPath = registrationFilesURL + fileId;
        } else if (newValue && typeof newValue === 'object' && typeof newValue.image === 'string' && newValue.image.indexOf(registrationFilePrefix) === 0) {
          fileId = newValue.image.replace(registrationFilePrefix, '');
          scope._registrationPath = registrationFilesURL + fileId;
        } else {
          scope._registrationPath = null;
          scope.resourceId = getResourceId(scope);
          scope.customerId = $state.params.customerId;
        }
      }, true);
    }
  };

  function getResourceId(scope) {
    var field = scope.$parent.$parent.field;
    return field && field.properties && field.properties.default_image ? field.properties.default_image : null;
  }
}]);
