'use strict';

angular.module('CustomerPortalSettings').controller('CustomerCreateCtrl', ['$scope', '$state', '$customerCreationService', '$q', '$interval', '$billingService', function ($scope, $state, $customerCreationService, $q, $interval, $billingService) {
    const self = this;

    const customerId = $state.params.customerId;

    self.createCustomer = createCustomer;
    let pollSubscriptionInterval = null;
    const POLL_INTERVAL = 2000;
    const POLL_MAX_RETRIES = 5;

    init();

    function init() {
        $scope.$on('$destroy', _destroySubscriptionPoll);
    }

    function createCustomer(customer) {
        self.saving = true;
        $customerCreationService.createCustomer(customerId, customer).then((newCustomer) => {
            return _pollSubscriptionCreated(newCustomer);
        }, (error) => {
            self.saving = false;
            if (error.data.message) {
                self.errorMessage = error.data.message;
            } else {
                self.errorMessage = error;
            }
        }).then((newCustomer) => $scope.$close({customer: newCustomer}));
    }

    function _pollSubscriptionCreated(newCustomer) {
        return $q((resolve) => {
            pollSubscriptionInterval = $interval(() => {
                $billingService.getActiveSubscriptions(newCustomer.customerId).$promise.then((res) => {
                    if (res.length > 0) {
                        _destroySubscriptionPoll();
                        resolve(newCustomer);
                    }
                });
            }, POLL_INTERVAL, POLL_MAX_RETRIES).then(iteration => {
                if (iteration === POLL_MAX_RETRIES) {
                    resolve(newCustomer); // It'll work eventually, no point waiting for it indefinitely here
                }
            });
        });
    }

    function _destroySubscriptionPoll() {
        $interval.cancel(pollSubscriptionInterval);
    }

}]);
