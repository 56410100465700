'use strict';

angular.module('AdminServices').factory('$adminBrandingService', ['moreConstants', '$resource', function (moreConstants, $resource) {

    const Branding = $resource(moreConstants.apiEndpoint + '/admin/branding', {}, {});

    return {
        all: function () {
            return Branding.query();
        }
    };
}]);