'use strict';

angular.module('FormManagement.tasks').factory('$formTaskService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var Tasks = $resource(moreConstants.apiEndpoint + '/customers/:customerId/:formId/tasks/:taskId/:action', {}, {
    revoke: {
      method: 'POST',
      params: {
        action: 'revoke'
      }
    },
    complete: {
      method: 'POST',
      params: {
        action: 'complete'
      }
    }
  });
  var TaskPages = $resource(moreConstants.apiEndpoint + '/customers/:customerId/:formId/tasks/:action/:page/', {}, {
    page: {
      method: 'POST',
      isArray: true,
      params: {
        action: 'page'
      }
    },
    filter: {
      method: 'POST',
      params: {
        action: 'filter'
      }
    }
  });

  return {
    filterTasks: function (customerId, formId, page, filter) {
      return TaskPages.filter({ customerId, formId, page }, filter);
    },
    saveTask: function (customerId, formId, task) {
      return Tasks.save({ customerId, formId }, task);
    },
    getTask: function (customerId, formId, taskId) {
      return Tasks.get({ customerId, formId, taskId });
    },
    complete: function (customerId, formId, taskId) {
      return Tasks.complete({ customerId, formId, taskId }, {});
    },
    revoke: function (customerId, formId, taskId) {
      return Tasks.revoke({ customerId, formId, taskId }, {});
    }
  };
}]);