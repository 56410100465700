module.exports = {
  "FORMS": "Formulários",
  "FOLDERS": "Pastas",
  "ADMINISTRATIVE": "Administrativo",
  "INVITE": "Convidar",
  "INVITES": "Convites",
  "USER_MANAGEMENT_OVERVIEW": "Vista geral dos usuários",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "Informação do Usuário",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Editar usuário",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Eliminar usuário",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Reenviar convite",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Nome",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Administrador",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Newsletter",
  "GROUPS": "Grupos",
  "ACCOUNT_OWNER": "Proprietário da conta",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "Endereço de email",
  "USER_MANAGEMENT_ALL_USERS": "Todos os Usuários",
  "USER_MANAGEMENT_NEW_TITLE": "Novos Usuário(s)",
  "USER_MANAGEMENT_EDIT_TITLE": "Gerir Usuários",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Permissões",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Administrador",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "Não há grupos",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Pastas",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(por grupos)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "Não há nenhuma pasta",
  "USER_MANAGEMENT_EDIT_SECURITY": "Segurança do Usuário",
  "USER_MANAGEMENT_SETTINGS_LABEL": "Ajustes do Usuário",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Nome de usuário",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "Nome",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Sobrenome",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Número de telefone",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Newsletter",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Zona Perigosa",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Eliminar Usuário",
  "CUSTOMER_GROUPS_NEW_TITLE": "Novo Grupo",
  "CUSTOMER_GROUPS_NAME_LABEL": "Nome",
  "CUSTOMER_GROUPS_NO_GROUPS": "Não há grupos.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "Não há grupo visível. Por favor mude os filtros.",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Membros do Grupo",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Adicionar Grupo",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Membro de",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Editar",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Eliminar",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Editar Grupo",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "Permissões do Grupo",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "Este grupo pode ter acesso aos formulários nas seguintes pastas:",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Permissões da Plataforma",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "Este grupo pode gerenciar:",
  "MANAGE_FORMS_LABEL": "Formulários & Pastas",
  "MANAGE_REGISTRATIONS_LABEL": "Registros",
  "MANAGE_TASKS_LABEL": "Tarefas",
  "MANAGE_USERS_LABEL": "Usuários",
  "MANAGE_SSO_LABEL": "Single sign-on",
  "MANAGE_DATA_SOURCES_LABEL": "Base de dados",
  "MANAGE_TEMPLATES_LABEL": "Modelos",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Transferencia de propiedad",
  "MANAGE_ACCOUNT_LABEL": "Configuración global de la cuenta",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Eliminar Grupo",
  "SELECT_GROUPS": "Selecionar Grupos",
  "USER_COUNT": "Contém {{value}} utilizadores",
  "USER_COUNT_SINGLE": "Contém um único utilizador",
  "USER_COUNT_EMPTY": "Não contém utilizadores",
  "USER_MANAGEMENT_REMOVE_TITLE": "Eliminar Usuário",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "Tem a certeza que pretende eliminar este usuário?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "Não pode eliminar você próprio como usuário",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Peça ao administrador da conta que o remova.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Remover usuário do grupo",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "Tem a certeza de que quer remover o usuário deste grupo?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Eliminar Convite",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "Tem a certeza de que quer eliminar este convite?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Remover convite do grupo",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "Tem a certeza de que quer eliminar o convite deste grupo?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Eliminar Grupo",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "Tem certeza de que deseja excluir esse grupo? Você não pode desfazer essa ação! Tenha em conta que isso não irá excluir usuários que estejam dentro deste grupo.",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Os usuários foram convidados",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "Convidou com sucesso <strong>{{count}} usuários</strong> para se juntarem à sua conta.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "O arquivo selecionado é maior que 5MB",
  "USER_MANAGEMENT_INVITE_TYPE": "Quantos usuários deseja convidar?",
  "USER_MANAGEMENT_INVITE_SINGLE": "Somente um usuário",
  "USER_MANAGEMENT_INVITE_BULK": "Múltiplos usuários",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Selecione um arquivo do Excel que contenha uma lista de emails na primeira coluna",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Idioma do convite",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "Convite do usuário falhou",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "O usuário já foi adicionado a esta conta",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "Mudança nas permissões falhou",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "Não foi possível excluir o grupo de usuários, pois isso deixaria o usuário atual sem permissão para gerenciar usuários",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "Não foi possível excluir a permissão, pois isso deixaria o usuário atual sem permissão para gerenciar usuários",
  "ROLES": "Funções",
  "ROLES_DEFAULT": "Funções padrão",
  "ROLES_CUSTOM": "Funções personalizadas",
  "MANAGE_ROLES_TITLE": "Administrar funções",
  "MANAGE_ROLES_DESCRIPTION": "Defina as permissões que pertencem às suas funções.",
  "MANAGE_BILLING": "Administrar faturação",
  "MANAGE_CREDITS": "Administrar faturação",
  "MANAGE_DATA_SOURCES": "Administrar base de dados",
  "MANAGE_FORMS": "Administrar todos os formulários & pastas",
  "MANAGE_TASKS": "Gerir todas as tarefas",
  "MANAGE_REGISTRATIONS": "Administrar todos os envios",
  "MANAGE_USERS": "Administrar usuários & acesso",
  "MANAGE_SSO": "Administrar single sign-on",
  "MANAGE_SENDING_DOMAIN": "Administrar dominio de correo electrónico",
  "MANAGE_WEBHOOKS": "Administrar webhooks",
  "MANAGE_TEMPLATES": "Administrar modelos",
  "MANAGE_ACCOUNT": "Administrar conta",
  "TRANSFER_OWNERSHIP": "Transferência de propriedade",
  "CUSTOMER_USER": "Acesso ao Aplicativo",
  "CREATE_FOLDER": "Crie Pastas",
  "CREATE_FORM": "Crie Formulários",
  "ADD_FORM": "Adicione formulários a pastas",
  "REMOVE_FORM": "Remova formulários de pastas",
  "UPDATE_FOLDER": "Atualize pasta",
  "DELETE_FOLDER": "Eliminar pasta",
  "CREATE_SUBMISSION": "Fazer um registro",
  "READ_SUBMISSION": "Ver registros",
  "DELETE_SUBMISSION": "Eliminar registros",
  "UPDATE_SUBMISSION": "Atualizar envios",
  "READ_TASK": "Ver tarefas",
  "UPDATE_TASK": "Concluir tarefas",
  "CREATE_TASK": "Criar tarefas",
  "DELETE_TASK": "Revogar tarefas",
  "UPDATE_FORM": "Atualizar formulário",
  "ARCHIVE_FORM": "Eliminar formulário",
  "RESTORE_FORM": "Recuperar Formulário",
  "MANAGE_BILLING_TOOLTIP": "Atualize o cadastro, detalhes de faturação e forma de pagamento. Veja as suas faturas e o seu histórico de uso.",
  "MANAGE_CREDITS_TOOLTIP": "Actualize a sua subscrição, detalhes de facturação e método de pagamento. Ver as suas facturas e utilização histórica.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Criar, visualizar, atualizar e excluir todas as fontes de dados",
  "MANAGE_FORMS_TOOLTIP": "Criar, visualizar e atualizar <b>todos</b> os formulários e pastas",
  "MANAGE_TASKS_TOOLTIP": "Criar, visualizar e atualizar tarefas para <b>TODOS</b> os formulários",
  "MANAGE_REGISTRATIONS_TOOLTIP": "Visualizar, excluir e reenviar envios para <b>todos</b> os formulários",
  "MANAGE_USERS_TOOLTIP": "Administrar o acesso para todos os usuários, convidados e grupos",
  "MANAGE_SSO_TOOLTIP": "Gerir a configuração para uma única sessão",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Gerir configuração para domínio de e-mail personalizado",
  "MANAGE_WEBHOOKS_TOOLTIP": "Criar, visualizar, atualizar e excluir todos os webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Criar, visualizar, atualizar e eliminar todos os modelos ",
  "MANAGE_ACCOUNT_TOOLTIP": "Gerir configurações de contas globais",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Gerir configurações de contas globais",
  "CREATE_FOLDER_TOOLTIP": "Criar novas pastas",
  "CREATE_FORM_TOOLTIP": "Criar dentro de uma pasta à qual tenha acesso",
  "ADD_FORM_TOOLTIP": "Adicionar/mover para uma pasta à qual tem acesso",
  "REMOVE_FORM_TOOLTIP": "Remover de uma pasta à qual tem acesso",
  "UPDATE_FOLDER_TOOLTIP": "Atualizar nome, descrição e ícone.",
  "DELETE_FOLDER_TOOLTIP": "Apagar pasta",
  "CREATE_SUBMISSION_TOOLTIP": "Enviar formulário no aplicativo para criar um novo registro",
  "READ_SUBMISSION_TOOLTIP": "Visualizar registros de um formulário específico",
  "DELETE_SUBMISSION_TOOLTIP": "Eliminar registros de um formulário específico",
  "UPDATE_SUBMISSION_TOOLTIP": "Atualizar registros de um formulário específico",
  "CREATE_TASK_TOOLTIP": "Criar tarefas para um formulário específico",
  "READ_TASK_TOOLTIP": "Ver tarefas para um formulário específico",
  "UPDATE_TASK_TOOLTIP": "Concluir tarefas para um formulário específico",
  "DELETE_TASK_TOOLTIP": "Revogar tarefas para um formulário específico",
  "UPDATE_FORM_TOOLTIP": "Editar e publicar formulário",
  "ARCHIVE_FORM_TOOLTIP": "Eliminar formulário",
  "ALLOWED": "Sim",
  "ALLOW": "Permitir",
  "DONT_ALLOW": "Não permitir",
  "NOT_ALLOWED": "Não",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Pastas e Formulários",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Gerencie o acesso de todos os usuários neste grupo.",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "Este grupo pode <b>gerir todos os formulários e pastas</b> através da sua função",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "Este usuário pode <b>gerir todos os formulários e pastas</b> através da sua função",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Gerencie o acesso deste usuário.",
  "ACCESS_ON_ACCOUNT": "Conta",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Selecione uma função de conta para todos os usuários deste grupo.",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Selecione uma função de conta para este usuário.",
  "NEW_ROLE": "Criar nova função",
  "USER_IN_GROUPS_DESCRIPTION": "O usuário pertence aos seguintes grupos",
  "USER_NOT_IN_GROUP_DESCRIPTION": "O usuário não pertence a nenhum grupo",
  "ACCESS_VIA_GROUPS": "Acesso por grupos",
  "ROLE_ACCOUNT_OWNER": "Administrador da Conta",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Administrador da Conta",
  "ROLE_OWNER": "Administrador da Conta",
  "ROLE_USER": "Usuário da Conta",
  "ROLE_FORM_MANAGER": "Administrador de Formulários",
  "ROLE_FORM_USER": "Usuário de Formulários",
  "ROLE_FOLDER_MANAGER": "Administrador de Pastas",
  "USER_DESCRIPTION": "Informação do usuário",
  "ROLE_DELETE_TITLE": "Eliminar função",
  "ROLE_DELETE_MESSAGE": "Só é possível eliminar funções que não estejam a ser utilizadas. Certifique-se de atualizar todos os usuários/grupos que utilizam esta função primeiro.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "Exterior",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "Este grupo é sincronizado a partir do seu directório externo e não pode ser alterado.",
  "EXTERNAL_DIRECTORY_USER_LABEL": "Exterior",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "Este grupo é sincronizado a partir do seu directório externo e não pode ser alterado.",
  "USER_SUSPENDED": "Suspenso",
  "USER_SUSPENDED_TOOLTIP": "Este utilizador foi suspenso no seu directório externo",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Não é permitida a transferência de propriedade",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Não lhe é permitido transferir a propriedade para um utilizador convidado. O utilizador tem de aceitar o convite primeiro.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Não é permitida a transferência de propriedade",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "É necessário ser proprietário da conta para transferir a propriedade para um utilizador diferente",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Transferencia de propiedad",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "Tem a certeza de que pretende transferir a propriedade para a <em>{{firstName}} {{lastName}} ({{username}})</em>?<br/><br/><b><b>Nota:</b> Perderá a titularidade da conta.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Transferencia de propiedad",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> é agora o actual proprietário desta conta.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "Email",
  "MENU_RULES": "Regras",
  "MENU_HOOKS": "Integrações",
  "SUB_FORM_NO_LONGER_USED_TITLE": "O subformulário já não é utilizado",
  "SUB_FORM_NO_LONGER_USED_BODY": "O subformulário não é usado em nenhum outro lugar da pasta. Deseja excluir o subformulário?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "Esses subformulários não são usados em nenhum outro lugar da pasta. Deseja excluir os subformulários?",
  "UNSUPPORTED_FILE_TYPE": "Somente os seguintes tipos de arquivo são suportados: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "Esta versão do formulário não é editável",
  "FORM_FINALIZED_MESSAGE": "Caso queira criar uma nova versão do formulário, volte para a visão geral e clique em 'Editar formulário'.",
  "PUBLISH_FAILED_TITLE": "Falha na publicação de seu formulário",
  "PUBLISH_FAILED_MESSAGE": "Não se preocupe, seu formulário não pôde ser publicado agora, porém, foi salvo com sucesso. Tente novamente mais tarde. Se o problema persistir, entre em contato conosco",
  "INTEGRATION_EOL_MESSAGE": "Esta integración ha sido desactivada. Por favor, elimínala o sustitúyela por otra integración o webhook. Por favor, <a href=\"https://help.moreapp.com/es/support/tickets/new\">contacta con soporte</a> si necesitas ayuda.",
  "USER_PICKER_ADD_USER": "Convidar usuário(s)",
  "USER_PICKER_ADD_GROUP": "Criar um grupo novo",
  "USER_PICKER_ALL_USERS": "Todos os usuários",
  "USER_PICKER_INVITES": "Convidar",
  "USER_PICKER_INVITED": "(Convidado)",
  "COLLECTION_PICKER_ADD_FORM": "Novo Formulário",
  "COLLECTION_PICKER_ADD_COLLECTION": "Nova Pasta",
  "SEARCH_FORMS_PLACEHOLDER": "Busca por pastas e formulários",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Selecionar Coluna",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Buscar",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "Ver",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Eliminar Tudo",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "Você está absolutamente seguro?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "Tem certeza de que deseja excluir todos os registros dessa maneira? Esta ação não pode ser desfeita!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "Isso remove TODOS os registros desse formulário, não da sua seleção",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Por favor, insira o seu ID de cliente para confirmar, qual é",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Exportar",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Exportar Tudo",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Exportação Filtrada",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Exportar Selecionados",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Incluir imagens",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Incluir ficheiros",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "Exportar para uma única folha Excel",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "Exportar demorará um pouco",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "Você está prestes a exportar muitos registros. O download pode demorar um pouco.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "Nenhum usuário para este formulário.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Selecionado",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Excluir seleção",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Detalhes do Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Eliminar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Eliminar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "Tem certeza de que deseja excluir este registro? Esta ação não pode ser desfeita",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Reenviar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Reenviar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "Deseja reenviar esse registro?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "O registro foi enviado",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "Falha ao encaminhar o registro. Entre em contato com o suporte, se isso permanecer.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "Email com PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Destinatário",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Info",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "Usuário",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Dados",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "Todos os ficheiros",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Localização",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "Mostrar em Google Maps",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "Não registrado",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Data do salvamento",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Dispositivo",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Red",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "Versão do aplicativo",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Tarefa",
  "SERIAL_NUMBER": "Número de série",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Integrações",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Repetir passo {{index}}",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Baixar relatório",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Seguinte tentativa em",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "Mail",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "Baixar PDF",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Tentativa de reenvio",
  "DOWNLOAD": "Baixar",
  "MAIL_STATUS_SEND": "{{number}} enviados",
  "MAIL_STATUS_QUEUED": "{{number}} na fila",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} soft bounced",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} hard bounced",
  "MAIL_STATUS_REJECT": "{{number}} rejeitados",
  "BAD_MAILBOX": "Problema com o endereço\n",
  "INVALID_DOMAIN": "Domínio Inválido",
  "IMAGES": "Imagens",
  "UNPAID_SUBMISSION": "Este registro ainda não foi processado.",
  "TRY_BETA_EXPORT": "Experimente a nova versão beta",
  "EXPORT_ALL_COLUMNS": "Exportar todas as colunas",
  "SELECTED_COLUMNS": "Selecionar colunas",
  "EXPORT_OPTIONS_TIMEZONE": "Fuso horário",
  "EXPORT_SUCCESS_TITLE": "Exportação iniciada com sucesso",
  "EXPORT_SUCCESS_MESSAGE": "A sua exportação foi iniciada, uma vez terminada será enviada para <b>{{{{email}}</b>. Dependendo do tamanho da sua exportação, isto pode demorar até várias horas.",
  "EXPORT_SERVICE_UNAVAILABLE": "A exportação não está actualmente disponível, por favor tente novamente mais tarde",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "As exportações, incluindo ficheiros, estão limitadas a {{{limite}} submissões",
  "MORE_INFORMATION": "Mais informação",
  "SELECTABLE_REORDARABLE_DONE": "Feito",
  "ACCEPT_CUSTOMER_INVITE": "Você quer aceitar o convite de '{{customer}}'?",
  "ACCEPT_BUTTON": "Aceitar",
  "ACTIVATION_FORM_TITLE": "Ativação da Conta",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Informação do Cliente",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Nome",
  "ACTIVATION_USER_INFORMATION_TITLE": "Informação do Usuário",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "Nome",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Sobrenome",
  "ACTIVATION_PASSWORD": "Senha",
  "ACTIVATION_PASSWORD_CONFIRM": "Confirmação da Senha",
  "ACTIVATION_PASSWORD_MISMATCH": "As Senhas não coincidem. Por favor, certifique-se de que a senha coincida com a de confirmação.",
  "ACTIVATION_FORM_TOC": "Termos e Condições",
  "ACTIVATION_FORM_TOC_MESSAGE": "Ao clicar, você concorda com:",
  "ACTIVATION_SUBMIT_BUTTON": "Ativar",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Entrar Token",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "Your invite has expired or is invalid",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Please ask your administrator to send you a new invite",
  "ACTIVATION_CHECK_TOKEN": "Comprovar Token",
  "ACTIVATION_SUCCESS": "Sim! Você ativou sua conta com sucesso!",
  "LICENSE_AGREEMENT": "Acordo de Licença",
  "PRIVACY_POLICY": "Política de Privacidade",
  "SECURITY_STATEMENT": "Declaração de Segurança",
  "LOGIN_ON_MOBILE_APP": "Faça login no aplicativo para dispositivos móveis:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Baixe o aplicativo em seu dispositivo Android ou iOS para começar.",
  "OR": "ou",
  "SCAN_QR_CODE": "Escanear o código QR",
  "NO_SMARTPHONE": "Você não tem um smartphone agora mesmo?",
  "OPEN_IN_WEBCLIENT": "Abrir no navegador",
  "ICON_PICKER_SELECT": "Selecionar Ícone",
  "ICON_PICKER_MODAL_TITLE": "Selecionador de Ícones",
  "MORE_GRID_VIEW": "Vista",
  "MORE_GRID_LOAD_MORE": "Carregar mais",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Detalhes",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Detalhes",
  "DATE_FILTER_FROM_LABEL": "De",
  "DATE_FILTER_TO_LABEL": "A",
  "DATE_FILTER_PLACEHOLDER": "Selecionar Data",
  "NO_RESULTS_FOUND": "Não se encontraram resultados",
  "CANVAS_NOT_SUPPORTED_TITLE": "Signature não é compatível",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "Você não pode usar essa funcionalidade na plataforma. Somente é compatível com o aplicativo móvel e o WebClient",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Foto não é compatível",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "Você não pode usar essa funcionalidade na plataforma. Somente é compatível com o aplicativo móvel e o WebClient",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Biblioteca de fotos não é compatível",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "Você não pode usar essa funcionalidade na plataforma. Somente é compatível com o aplicativo móvel e o WebClient",
  "BARCODE_SCAN_FAILURE": "Escaneamento de código de barras não é compatível",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "Você não pode usar essa funcionalidade na plataforma. Somente é compatível com a aplicação móvel",
  "DATE_MODAL_TITLE": "Selecionador",
  "WIDGET_DETAIL_LINK": "Permitir que o subformulário seja reutilizado em outros widgets ",
  "WIDGET_DETAIL_UNLINK": "Vincule este widget a um subformulário reutilizável",
  "WIDGET_DETAIL_NEW_TITLE": "Novo Subformulário",
  "WIDGET_DETAIL_REMOVE_TITLE": "Eliminar Subformulário",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Vincule um formulário já existente",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "As alterações serão aplicadas a outros widgets usando este subformulário",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Utilizado em {{value}} widgets",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "Não utilizado",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "Tem certeza de que deseja excluir este subformulário?",
  "COLLECTION": "Pasta",
  "DESCRIPTION": "Descrição",
  "LOGIN": "Iniciar sessão",
  "LOGIN_USERNAME": "Nome do usuário",
  "LOGIN_PASSWORD": "Senha",
  "LOGIN_SIGN_IN_BUTTON": "Iniciar Sessão",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Combinação nome do usuário/senha incorreto",
  "LOGIN_FORGOT_PASSWORD_LINK": "Esqueceu sua senha?",
  "LOGIN_NO_ACCOUNT_MESSAGE": "Não tem uma conta? Selecione um plano aqui!",
  "LOGOUT_SUCCESSFUL_TITLE": "Sessão encerrada",
  "LOGOUT_SUCCESSFUL_MESSAGE": "Não tem sessão iniciada",
  "LOGOUT_SUCCESSFUL_ACTION": "Voltar a iniciar sessão",
  "FORGOT_PASSWORD_TITLE": "Esqueceu sua senha?",
  "FORGOT_PASSWORD_EMAIL": "Email",
  "FORGOT_PASSWORD_USERNAME": "Nome de usuário",
  "FORGOT_PASSWORD_SUBMIT": "Enviar",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Email incorreto",
  "FORGOT_PASSWORD_EMAIL_SENT": "Sua nova senha foi reenviada. Siga o link que enviamos ao seu endereço de email para redefinir sua nova senha.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Voltar",
  "ACTIVATE_USER_TITLE": "Ativar usuário",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "Nova senha",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "Nova senha (verificação)",
  "MENU_HELP": "Ajuda",
  "MENU_REGIONS": "Regiões",
  "MENU_CUSTOMERS": "Clientes",
  "MENU_LOGOUT": "Encerrar sessão",
  "MENU_USERS": "Usuários",
  "MENU_REGISTRATIONS": "Registros",
  "MENU_FORMS": "Formulários",
  "MENU_TEMPLATES": "Modelos",
  "MENU_SETTINGS": "Ajustes",
  "MENU_API_DOC": "Docs",
  "MENU_DOCUMENTATION": "Emails",
  "MENU_SUPPORT": "Suporte",
  "MENU_PLUGINS": "Plugins",
  "MENU_TRANSACTIONS": "Transações",
  "MENU_BILLING": "Conta",
  "MENU_FINANCIAL": "Finanças",
  "MENU_CONTACT": "Contactar",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Selecionar cliente",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Dados",
  "MENU_PROFILE": "Perfil",
  "MENU_ONBOARDING": "Registro",
  "MENU_QUEUE": "Queue",
  "PROFILE_INFO": "Info",
  "PROFILE_EMAIL_VALID": "Seu email foi validado com sucesso",
  "PROFILE_EMAIL_NOT_VALIDATED": "Seu email ainda não foi validado.",
  "SUBMITTED": "Enviado",
  "SENT": "Enviado",
  "ACCEPTED": "Aceito",
  "REVOKED": "Revogada",
  "REJECTED": "Rejeitada",
  "CONFIRM": "Confirme",
  "AWAITING_PACKAGE_UPLOAD": "Esperando carregar o pacote",
  "RESELLER": "Distribuidor",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Ajustes",
  "CUSTOMER_APPLICATION_OPERATIONS": "Pasta de operações",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "A pasta só pode ser removida quando estiver vazia",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Total de formulários enviados",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Formulários enviados",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Buscar...",
  "ADD_FORM_PANEL_TITLE": "Adicionar um formulário",
  "ADD_FORM_PANEL_TITLE_LABEL": "Título do formulário",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Título do formulário",
  "ADD_FORM_PANEL_SAVE": "Guardar",
  "ADD_TEMPLATE": "Adicionar um modelo",
  "MARK_GLOBAL": "Marcar como global",
  "UNMARK_GLOBAL": "Desmarcar como global",
  "MARK_GLOBAL_TOOLTIP": "Apenas disponível como MoreApp ADMIN (Administrador). Marcar como global para o tornar publicamente disponível na tela 'Novo Formulário'",
  "ADD_APPLICATION_PANEL_TITLE": "Adicionar uma pasta",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Nome da pasta",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Nome",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Eliminar formulário",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "Tem certeza de que deseja excluir esse formulário?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Copiar formulário",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Selecionar uma pasta para copiar o formulário",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Pasta",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Pasta",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Nome da cópia",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Publicar",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Administrar usuários",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Copiar",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Eliminar",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Copiar pasta",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Cliente",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Publicar pasta",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "Tem certeza de que deseja publicar esta pasta?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "Pasta publicada com sucesso!!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Falha ao publicar pasta por motivos desconhecidos. Por favor, verifique as propriedades de definição que faltam. Se você não conseguir resolver o problema, entre em contato com seu partner.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "Falha ao publicar a pasta. Por favor, corrija os seguintes erros e tente novamente:",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "A tentativa anterior teve os seguintes erros:",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Mostrar erros anteriores",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Publicar",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Colocar no Marketplace",
  "INVALID_APPLICATION_HEADER": "Erros de aplicação:",
  "INVALID_FORM_HEADER": "Problemas do formulário:",
  "INVALID_APPLICATION_START_PAGE": "A pasta deve ter uma página de início",
  "INVALID_APPLICATION_NAME": "A pasta deve ter um nome",
  "INVALID_APPLICATION_THEME": "A pasta deve ter um tema",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Ajustes de pesquisa \"Item markup\" não é válido",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "\"{{fieldName}}\" tem propriedades inválidas",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "\"{{fieldName}}\" tem uma propriedade inválida: \"{{property}}\"",
  "INVALID_VIEW_ITEM_DATA_NAME": "\"{{fieldName}}\" tem um nome de dados inválido",
  "INVALID_VIEW_ITEM_WIDGET_UID": "\"{{fieldName}}\" widget não está instalado",
  "INVALID_VIEW_ITEM_VIEW_ID": "\"{{fieldName}}\" não se refere a uma vista conhecida",
  "INVALID_VIEW_ITEM_MAIL": "Placeholder inválido no modo de email perto de \"{{ syntaxError }}\"",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Destinatários dinâmicos inválidos no modoo de email perto de  \"{{ syntaxError }}\"",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Destinatário errado no email: <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Regra inválida com o nome de  \"{{ fieldName }}\"",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "Gerenciamento de Usuários",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Usuários que têm acesso",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "Nenhum usuário",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "usuário",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "usuários",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Eliminado ",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Grupos que têm acesso",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "Nenhum grupo",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Defina quem pode editar, publicar e usar este formulário",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Defina quem pode adicionar formulários a esta pasta",
  "CONTACT": "Contato",
  "ADDITIONAL_FEES_APPLY": "Aplicam-se custos adicionais",
  "CUSTOMER_CONTACT_VAT_NUMBER": "CIF",
  "CUSTOMER_CONTACT_COC_NUMBER": "CdC",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Suporte",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "Recebemos sua notificação e tomaremos as medidas apropriadas.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Falha ao enviar sua notificação. Não hesite em contactar-nos sobre este problema.",
  "BILLING_INFO": "Informação de faturação",
  "CONTACT_INFO": "Informação de contato",
  "INVOICES": "Faturas",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Conta",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Selecionar cliente",
  "COLLECTION_REMOVE_MODAL_TITLE": "Eliminar pasta",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "Tem certeza de que deseja excluir esta pasta? Esta ação não pode ser desfeita ",
  "COPY_FORM_MODAL_TITLE": "Copiar formulário",
  "COPY_FORM_MODAL_MESSAGE": "Selecionar uma pasta aonde copiar o formulário e, se preferir, mudar Nome",
  "COPY_FORM_MODAL_NAME_LABEL": "Nome",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "Pasta objetivo",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Selecionar a pasta aonde copiar o formulário",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Utiliza opcionalmente um Nome diferente para a cópia.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_CUSTOMER": "A conta para a qual esse formulário é copiado.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "A cópia se colocará nessa pasta.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Eliminar vista",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "Tem certeza de eliminar esta vista?",
  "SUPPORT_TITLE": "Suporte",
  "SUPPORT_QUESTION": "Algo foi mal?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "Encontrou um bug ou alguma característica que falte, ou tem alguma pergunta? Por favor, envie um email a <a href=\"mailto:support@moreapp.com\">support@moreapp.com</a>. Por favor, nos facilite a informação detalhada sobre o problema com os possíveis passos feitos para reproduzi-los e que usuários e/ou característica seja relacionada.",
  "SUPPORT_QUESTION_SUBTITLE": "Por favor, preencha o formulário abaixo para nos deixar cientes!",
  "SUPPORT_APPLICATION_QUESTION": "Qual pasta você estava utilizando?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Nome/id da pasta",
  "SUPPORT_FORM_QUESTION": "Que formulário você estava utilizando?",
  "SUPPORT_FORM_PLACEHOLDER": "Nome do formulário",
  "SUPPORT_DEVICE_QUESTION": "Que tipo de dispositivo você estava utilizando?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Tipo / Versão do sistema",
  "SUPPORT_DESCRIPTION_QUESTION": "Algo mais talvez possa nos ajudar?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Descrição",
  "SUPPORT_EMAIL_QUESTION": "Se fosse necessário, como poderíamos entrar em contato contigo?",
  "SUPPORT_EMAIL_PLACEHOLDER": "Email",
  "SUPPORT_SUCCESS_TITLE": "Muito obrigado!",
  "SUPPORT_SUCCESS_MESSAGE": "Recebemos sua notificação e tomaremos as medidas apropriadas.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Editar pasta: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Ajustes",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Nome",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Colocar Nome na pasta",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Página inicial",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Tema",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Imagem",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Vistas",
  "APPLICATION_EDITOR_TO_LABEL": "A",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "Trocar para o modo simples",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "Tem a certeza que deseja voltar para o modo simples? Isto significa que irá perder o layout do seu PDF personalizado e voltará para o nosso layout padrão de PDF.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Nome",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Destinatário estático",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Destinatário dinâmico",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Assunto",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "De Nome",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Nome do remetente",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Responder a",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Enviar respostas a este Endereço",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "De email",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Redefinir pin anexo",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Redefinir documento anexo",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "Tem certeza de que deseja redefinir o PDF anexado?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "Isso substituirá as mudanças de personalização e gerará, de novo, o PDF baseado na configuração do seu formulário atual.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Todas as imagens",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "Anexar PDF com nome:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Usar cabeçalho e rodapé no PDF",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "Com o logo:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Ocultar campos que não estão preenchidos",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Use a orientação horizontal para o subformulário",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Marcadores de posição",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Mostrar mapa",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Preencha um ou mais endereços de email de quem você deseja enviar o formulário, separados por vírgulas. <br><br><b>Exemplo</b><br>alice@example.com, bob@example.com",
  "${sys.user}": "Usuário",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Falhou ao publicar",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "O formulário foi salvo, mas não pode ser publicado devido a alguns erros.",
  "VIEW_EDITOR_FORM": "Formulário",
  "VIEW_EDITOR_EMAIL": "Email",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Mande um email com um PDF em cada registro realizado",
  "VIEW_EDITOR_RULES": "Regras",
  "VIEW_EDITOR_RULES_SUBTITLE": "Ocultar ou mostrar campos baixo certas condições.",
  "VIEW_EDITOR_SETTINGS": "Ajustes",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Mude a aparência e o comportamento do seu formulário.",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Permita aos usuários pesquisar registros desde o aplicativo e usá-los como ponto de partida para um novo registro.",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Integrações",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Faça funcionar um sistema externo por cada registro",
  "VIEW_EDITOR_PROPERTIES": "propriedades",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "O Nome dos dados é inválido",
  "VIEW_EDITOR_DATA_NAME": "Nome dos dados",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "Este Nome de dados está duplicado, o que poderia levar a um comportamento inesperado.",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Adicionar regra",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Adicionar Integração",
  "HOOK_SELECT_TITLE": "Selecionar integração",
  "INTEGRATIONS_SUBTITLE": "Em alternativa, pode integrar-se usando <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, our <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a>, or <a href='https://zapier.com/apps/moreapp/integrations' target='_blank'>Zapier</a>",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "Não há nada que configurar nesse Integração.",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "Definições validadas com sucesso.",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Eliminar Integração",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "Tem certeza de que deseja excluir esse Integração? Esta ação não pode ser desfeita.",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "Atualmente, não há integrações disponíveis para adicionar.",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "A configuração foi salva, mas não pôde ser validada no momento. Se o problema persistir, entre em contato com o autor deste Integração: {{author}}",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Obter mais integrações",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "Este integração não estará disponível até {lastAvailableDate|date:\"medium\"}}. Você pode salvar a configuração, mas não será validado. Além disso, o integração não será executado até que esteja disponível novamente.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Nome",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Condições",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Outros tipos",
  "VIEW_EDITOR_RULE_USER": "Nome de usuário",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Selecionar um campo",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Selecionar uma condição",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Selecionar um valor",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Começa com",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Termina com",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Contém",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Maior que",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Menor que",
  "VIEW_EDITOR_RULE_CONDITION_IS": "é",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "Tem valor",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "valor",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "valor",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Campos atuais do formulário",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Outros campos do formulário",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Ações",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "Quando",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "as condições coincidam, faça o seguinte",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": "Selecione uma ação",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Ajustes",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Nome",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Descrição",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Ícone",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "Ocultar no Aplicativo",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Vista raiz",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "Este formulário deveria ser exibido como uma vista raiz",
  "VIEW_EDITOR_SETTINGS_INTERACTION_LABEL": "Carregar interação",
  "VIEW_EDITOR_SETTINGS_INTERACTION_IMMEDIATE_UPLOAD_LABEL": "Carregamento imediato ",
  "VIEW_EDITOR_SETTINGS_INTERACTION_MANUAL_UPLOAD": "Carregamento manual",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Descrição na app",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Guardar modo",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Guardar e sair (Padrão)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Guardar e sair & Guardar e Novo",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "Não guardar (só ler)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Configurações de pesquisa",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Ativado",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Mostrar apenas os registros feitos pelo usuário atual",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filtragem habilitada",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Campos",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Selecionar campo",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Formulário",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Item markup",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Parâmetros disponíveis:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "Este valor não existe",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Valor",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Valor em PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "Valor",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Adicionar",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Usar imagem como etiquetas",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Selecionar imagem",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Selecionar imagem",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Selecione o ícone pin",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Ícones pin padrões",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Selecione o ícone pin padrão",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Adicionar a favoritos",
  "VIEW_EDITOR_ADVANCED_MODE": "Modo avançado",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Padrão",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Favoritos",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "Nenhum favorito definido.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Adicionar Widget Favorito",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Nome do Favorito",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Nome",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Valor original",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Valor do favorito",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "Não há alterações no widget existente.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "Adicionar Email",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Eliminar Widget",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "Tem certeza de que deseja excluir este widget favorito?",
  "ERROR_SOMETHING_WENT_WRONG": "Algo deu errado.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Por favor, tente de novo mais tarde. Se o problema persistir, entre em contato com support@moreapp.com",
  "ACCESS_DENIED_MESSAGE": "Acesso negado",
  "ACCESS_DENIED_DESCRIPTION": "Esta plataforma destina-se a administradores. Contacte o proprietário da sua conta ou inicie sessão no <a href='{{webclientUrl}}'> Web App</a> para preencher formulários.",
  "ACCESS_DENIED_LOGOUT": "Fechar Sessão",
  "ACCESS_DENIED_TO_LOGIN": "Ir a iniciar sessão",
  "PAGE_NOT_FOUND_TITLE": "Página não encontrada",
  "PAGE_NOT_FOUND_DESCRIPTION": "A página que você estava procurando não pôde ser encontrada",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Voltar à visão geral da plataforma",
  "CUSTOMER_RESOURCES_TITLE": "Fontes",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Tipo",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Galeria",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Nome",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Carregar um documento",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "Documento",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Carregar desde URL",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Eliminar fonte",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "Tem certeza de que deseja excluir esta fonte? Esta ação não pode ser desfeita.",
  "RESOURCES_UPLOAD_FAILURE": "Carregamento falho",
  "RESOURCES_UPLOAD_INVALID_FILE": "O documento selecionado não é uma imagem.",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "O documento selecionado excede o limite de 5 MB.",
  "REGISTRATIONS": "Registros",
  "START": "Começar",
  "CONFIGURE": "Configurar",
  "UPGRADE": "Actualização",
  "STEP": "Etapa",
  "CREATED": "Criado",
  "CREATE": "Criar",
  "READ": "Olhe",
  "DELETE": "Eliminar",
  "REMOVE": "Eliminar",
  "SUBMIT": "Enviar",
  "CANCEL": "Cancelar",
  "CLOSE": "Fechar",
  "SEND": "Enviar",
  "SAVE": "Guardar",
  "RENAME": "Renomear",
  "RENAME_LINKED_SUBFORM_TITLE": "Renomear subforma ligada",
  "SAVE_PAYMENT_METHOD": "Confirmar e utilizar o método de pagamento",
  "SELECTED_PAYMENT_METHOD": "Método de pagamento seleccionado",
  "SELECT_OTHER_PAYMENT_METHOD": "Utilizar outro método de pagamento",
  "LOADING": "Carregando",
  "SAVE_AND_CLOSE": "Guardar & Fechar",
  "SAVE_AND_PUBLISH": "Guardar & Publicar",
  "VALIDATE": "Validar",
  "EDIT": "Editar",
  "UNDO": "Desfazer",
  "BACK": "Voltar",
  "NEXT": "Seguinte",
  "PREVIOUS": "Prévio",
  "CLEAR": "Excluir",
  "SELECT": "Selecionar",
  "UPLOAD": "Carregar",
  "BUSY_UPLOADING": "Carga lenta...",
  "UPDATE": "Atualizar",
  "ADD": "Adicionar",
  "COPY": "Copiar",
  "SHARE": "Compartilhar",
  "ACTIONS": "Operações",
  "THEME": "Tema",
  "ON": "On",
  "OFF": "Off",
  "OPTIONS": "Opções",
  "INSTALLED": "Instalado",
  "EMPTY": "Esvaziar",
  "QUARTER_HOUR": "Cada 15 minutos",
  "HALF_HOUR": "Cada 30 minutos",
  "DAILY": "Diariamente",
  "WEEKLY": "Mensalmente",
  "HOURLY": "Cada hora",
  "API": "API",
  "NAME": "Nome",
  "EMAIL": "Email",
  "DATE": "Datas",
  "DUE_DATE": "Data de vencimento",
  "HOOKS": "Integrações",
  "NONE": "Nenhum",
  "NEVER": "Nunca",
  "USER": "Usuário",
  "GROUP": "Grupo",
  "VIA_GROUP": "através de grupo(s)",
  "BETA": "beta",
  "LOCKED": "Bloqueado",
  "URL": "URL",
  "FILE": "Documento",
  "YES": "Sim",
  "NO": "Não",
  "DEFAULT": "Padrão",
  "SEARCH": "Pesquisar",
  "DETAILS": "Detalhes",
  "UNSAVED_CHANGES_TITLE": "Alterações não guardadas",
  "UNAVAILABLE": "Não disponível",
  "VERSION": "Versão",
  "PRICE": "Preço",
  "FREE": "Grátis",
  "ADDRESS": "Endereço",
  "ADDRESS_LINE_1": "Endereço",
  "ADDRESS_LINE_1_PLACEHOLDER": "123 Rua Principal",
  "CITY": "Cidade",
  "COUNTRY": "País",
  "ZIP_CODE": "Código postal",
  "OK": "OK",
  "INTERNAL_ERROR": "Erro interno",
  "OPEN": "Abrir",
  "STALLED": "Estagnado",
  "ERROR": "Erro",
  "SUCCESS": "Sucesso",
  "RETRY": "Repetir",
  "SKIP": "Saltar",
  "IN_PROGRESS": "Em progresso",
  "COMPLETED": "Terminado",
  "DECLINED": "Recusado",
  "BLOCKED": "Um dos integrações falhou",
  "NOT_VALIDATED": "Não validado",
  "INVALID": "Inválido",
  "VALID": "Válido",
  "VERIFIED": "Verificado",
  "UNVERIFIED": "Não verificado",
  "TYPE": "Tipo",
  "VALUE": "Valor",
  "ONLINE": "Online",
  "OFFLINE": "Offline",
  "ADVANCED": "Avançado",
  "PREVIEW": "Vista Prévia",
  "BY": "Por",
  "UNTIL": "Até",
  "COUPON": "Cupom",
  "COUPON_ADD_ERROR": "Cupom inválido",
  "DISCOUNT": "Desconto",
  "COUPON_REMOVE_TITLE": "Apagar cupom",
  "COUPON_REMOVE_MESSAGE": "Tem a certeza que pretende apagar este cupom?",
  "PERMISSIONS": "Permissões",
  "THIS_MIGHT_TAKE_SOME_TIME": "Pode demorar um pouco.",
  "FIELD_ERROR_REQUIRED": "Este campo é obrigatório",
  "FIELD_ERROR_MIN_LENGTH": "O campo deve ter pelo menos o comprimento de {{minLength}} caracteres",
  "FIELD_ERROR_MAX_LENGTH": "O campo excede o comprimento máximo {{maxLength}}",
  "FIELD_ERROR_MIN": "O valor é inferior ao valor mínimo {{min}}",
  "FIELD_ERROR_MAX": "O valor é maior do que o valor máximo {{max}}",
  "FIELD_ERROR_EMAIL": "O valor não é um Endereço de email válido",
  "BILLING_BANK_ACCOUNT_NUMBER": "Conta Bancária (IBAN)",
  "BILLING_VAT_NUMBER": "Número de identificação IVA",
  "BILLING_VAT_NUMBER_CHECKING": "Comprovação",
  "BILLING_VAT_NUMBER_VALID": "Válido",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "Porcentagem IVA",
  "BILLING_VAT_NUMBER_INVALID": "Inválido",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "País não coincide",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "O país do seu número de identificação de IVA e o país selecionado não coincidem. Por favor, conserte pois não será possível processar seu pedido.",
  "BILLING_COC_NUMBER": "Câmara de comércio",
  "BILLING_EMAIL": "Email de Faturação",
  "VAT_DISCLAIMER": "Os preços apresentados não incluem IVA. O valor do IVA será exibido após o processamento do pagamento e será exibido nas faturas.",
  "RECEIVE_NEWS_LETTER": "Newsletter",
  "RECEIVE_NEWS_LETTER_MESSAGE": "Quero receber o newsletter",
  "TERMS_OF_CONDITION_DOWNLOAD": "Baixar os Termos e Condições",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Subformulário",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "Nome do novo formulário",
  "FORM_BUILDER_SUB_FORM_NAME": "Nome do Formulário",
  "TOOLTIP_ADD_APPLICATION": "Uma pasta conterá seus formulários. <br> <br> Cada formulário possui suas próprias configurações, permissões de usuário e pode ser publicado individualmente.",
  "TOOLTIP_ADD_APPLICATION_NAME": "Este nome será exibido no aplicativo.",
  "TOOLTIP_ADD_FORM": "Adicione um formulário para criar registros no aplicativo. <br> <br> Cada formulário tem uma série de campos, regras e suas próprias configurações.",
  "TOOLTIP_ADD_FORM_NAME": "Este nome será exibido no aplicativo.",
  "TOOLTIP_COPY_FORM_NAME": "Opcionalmente use um nome diferente para a cópia.",
  "TOOLTIP_COPY_FORM_DESTINATION": "A cópia foi colocada nesta pasta.",
  "TOOLTIP_DELETE_FORM": "<b>Esta ação é irreversível </b>.<br> Tenha em mente que seus registros serão mantidos",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a>Arraste o \"handle\"</a> para mudar a ordem dos seus Formulários dentro da pasta.<br><br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "A pasta foi arquivada. Reverte esta ação clicando no botão <a>Desfazer</a>.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "A pasta foi criada, mas ainda não publicada.<br><br>Se quiser ver esta pasta no aplicativo, você terá que <a>Publicar</a> primero.",
  "TOOLTIP_APP_MANAGE_USERS": "Especifica quais grupos e/ou usuários verão essa pasta no aplicativo depois de efetuar o login.",
  "TOOLTIP_VIEW_SETTING_NAME": "Este nome será exibido no aplicativo.",
  "TOOLTIP_VIEW_SETTING_ICON": "Este ícone é usado no aplicativo quando o formulário é exibido em uma lista",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Permitir que este formulário seja exibido na visão geral de <a>Formulários</a>.",
  "TOOLTIP_VIEW_SETTING_INTERACTION": "Escolha como e quando este formulário será carregado:<br><br><a>Carregar imediatamente:</a><br>Quando você salva o formulário, o aplicativo irá carregá-lo instantaneamente (se houver uma conexão com a internet).<br><br><a>Permitir edição com carregamento manual:</a><br> Depois de salvar o formulário, você pode editar o formulário antes de carregá-lo para o servidor.<br><br><a>Carregamento manual (padrão):</a><br>Los Formulários salvos estão escondidos no dispositivo, e podem ser carregados manualmente como lotes pela tela \"data \".",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Escolha o modo de salvar:<br><br><a>Somente Salvar e Fechar (padrão):</a><br>Um simples botão de <b>Guardar</b> aparecerá, que armazena e fecha o formulário (quando é válido).<br><br><a>Todos:</a><br>O botão de <b>Guardar</b> e <b>Guardar e Novo</b> aparece, para que você possa continuar adicionando registros do mesmo formulário.<br><br><a>Não guardar:</a><br>Ter este formulário só para leitura. Não aparece nenhum botão.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "Isto define a forma como cada envio, rascunho ou tarefa para este formulário é mostrado na aplicação. Utilize marcadores de posição de campos que lhe permitam reconhecer cada item.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Ativar a pesquisa neste formulário no aplicativo.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Habilite a pesquisa apenas dos registros enviados pelo usuário atualmente conectado.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Habilitar a filtragem de determinados campos nos formulários encontrados",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Excluir os seguintes valores no resultado",
  "TOOLTIP_FORM_RULE_CONDITION": "Especifique uma ou mais <a>Condições</a> que deve ser cumprir para executar as <a>Ações</a>.<br><br>De esta maneira, os campos irrelevantes ou inaplicáveis podem ser omitidos sob certas condições.<br><br><b>Por exemplo:</b><br>Esconder <a>Nome do Partner </a> Quando <a>Estado civil</a> seja <a>\"solteiro\"</a>",
  "TOOLTIP_FORM_RULE_ACTION": "Especifique uma ou mais <a>Ações</a> para que se executem quando todas as <a>Condições</a> se cumpram.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Nome que será usado na coluna nome em <a>Grid de Registros</a>. Por padrão, esse valor será automaticamente preenchido com base no<b>texto da etiqueta</b><br><br><b>Por exemplo:</b><br>Se o seu texto de rótulo for <a>\"Quantos anos você tem?\"</a>, O Nome de dados será <a>QuantosAnosVoceTem</a>. É possível que você queira mudar isso a <a>\"anos\"</a>.",
  "TOOLTIP_ADD_DATA_SOURCE": "Adicionar base de dados",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Nome da base de dados",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Selecionar um Excel para importar. Verifique que a extensão do Excel seja `.xlsx`. Documentos `.xls` não serão importados.",
  "TOOLTIP_HOOK": "A configuração específica deste integração nesse formulário",
  "TOOLTIP_DIRECT_FORM_LINK": "Criar um URL exclusivo para o seu formulário, para que você possa compartilhá-lo com outras pessoas.<br><br>Utilizando este URL, as pessoas que não tenham uma conta MoreApp podem preencher seu formulário.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Compartilhe seu formulário em nosso Marketplace, para que outros possam usá-lo como um modelo.",
  "RULE_HIDDEN": "Ocultar",
  "RULE_VISIBLE": "Visível",
  "RULE_GETS_VALUE": "Tem valor",
  "WIDGET_LICENSE_INVALID": "Licença inválida",
  "WIDGET_LICENSE_BRAND": "Marca",
  "WIDGET_LICENSE_TYPE": "Tipo",
  "WIDGET_LICENSE_POWER": "Power",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "Primeira admissão",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "Euro class",
  "SEGMENT": "Setor",
  "SEGMENT_AUTOMOTIVE": "Automotivo",
  "SEGMENT_CONSTRUCTION": "Construção",
  "SEGMENT_FACILITY": "Serviços",
  "SEGMENT_FINANCIAL": "Financeiro",
  "SEGMENT_TRADE_AND_INDUSTRY": "Comércio e Indústria",
  "SEGMENT_GOVERNMENT": "Governo",
  "SEGMENT_HEALTH_CARE": "Saúde",
  "SEGMENT_INSTALLATION": "Instalação",
  "SEGMENT_OTHER": "Outro",
  "GENERIC": "Genérico",
  "FACILITY": "Instalação",
  "CONSTRUCTION": "Construção",
  "SALES": "Vendas",
  "HR": "Recursos Humanos",
  "AUTOMOTIVE": "Automotivo",
  "SAFETY": "Segurança",
  "LOGISTICS": "Logistica",
  "INSTALLATION": "Instalação",
  "MAINTENANCE": "Serviços & Manutenção",
  "SECURITY": "Segurança",
  "REAL_ESTATE": "Imobiliário",
  "HEALTHCARE": "Saúde",
  "AGRICULTURE": "Agricultura",
  "INDUSTRY": "Indústria",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Permissões insuficientes",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "Você está logado na plataforma. Esta é uma seção em que apenas os administradores da sua organização têm acesso. Se você precisa preencher os Formulários, por favor, abra o aplicativo em seu smartphone ou tablet.",
  "FORM_SUBMISSION_SERVER_ERROR": "O formulário não pôde ser enviado. Isso pode ser um problema de servidor. Por favor, tente de novo mais tarde",
  "UNKNOWN_ERROR": "Ocorreu um erro desconhecido",
  "ALL": "Tudo",
  "ANY": "Qualquer",
  "GLOBAL_ERRORS_TITLE": "Erros de Validação",
  "USE_TEMPLATE": "Utilizar modelo",
  "LANGUAGE_EN": "Inglês",
  "LANGUAGE_NL": "Holandês",
  "LANGUAGE_DE": "Alemão",
  "LANGUAGE_ES": "Espanhol",
  "LANGUAGE_RU": "Russo",
  "LANGUAGE_PT": "Português",
  "LANGUAGE_FR": "Francês",
  "LANGUAGE_IT": "Italiano",
  "LANGUAGE_CA": "Catalão",
  "UNSAVED_CHANGES": "Existem mudanças não salvas que serão descartadas se você continuar",
  "CHANGE_FILE": "Selecionar outro arquivo",
  "AUTHENTICATED": "Autentificado",
  "LANGUAGE": "Idioma",
  "TAGS": "Tags",
  "SELECT_TAGS": "Selecionar tags",
  "SELECT_ALL": "Selecionar tudo",
  "SEARCH_TEMPLATES": "Procurar Modelos",
  "CUSTOMER": "Cliente",
  "PARTNER": "Partner",
  "CUSTOM": "Personalizado",
  "AMOUNT": "Quantidade",
  "PHONE": "Número de telefone",
  "REGION": "Região",
  "STATUS": "Status",
  "ACTIVE": "Ativo",
  "INACTIVE": "Inativo",
  "DISABLED": "Inativo",
  "ACCOUNT": "Conta",
  "DOCUMENTATION": "Documentação",
  "COMPANY_NAME": "Nome da Empresa",
  "COMPANY": "Empresa",
  "SHOW_MORE": "Mostrar mais",
  "WEBSITE": "Website",
  "CLICK_HERE": "Clique aqui",
  "MAIL_NOTIFICATION": "Notificação de email",
  "CHECKED": "Verificado",
  "UNCHECKED": "Não verificado",
  "ACCOUNT_NAME": "Empresa",
  "ACCOUNT_NAME_PLACEHOLDER": "Ex: Empresa Ltd ou João da Silva",
  "FEATURE_HOOKS": "Integrações",
  "FEATURE_HOOKS_MESSAGE": "<h4>Os integrações são usados para processar dados após o envio de formulários</h4><p>Definir um fluxo de trabalho, enviar arquivos para o OneDrive, enviar dados para o Google Sheets ou para o Word</p><p>Este recurso fica disponível ao mudar para o <b>Ramo</b> Plano</p>",
  "FEATURE_MANUAL_DATASOURCES": "Importação de dados",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Importar dados usando Excel, Google Sheets ou uma URL (JSON)</h4><p>>Este recurso fica disponível ao mudar para o <b>Folha</b>Plano.</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Importação de dados automático",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Importar dados automaticamente adicionando um novo registro à sua Planilha do Google ou URL</h4><p>p>Este recurso fica disponível ao alternar para o <b>Ramo</b>Plano.</p>",
  "FEATUER_ADVANCED_PDF": "PDF personalizado",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Desenhe seu relatório PDF usando código HTML</h4><p>p>Este recurso fica disponível ao mudar para o <b>Ramo</b>Plano.</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Gestão avançada de usuários",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Dê acesso a certas partes da Plataforma a grupos</h4><p>p>Este recurso fica disponível ao mudar para a <b>Árvore</b>Plano.</p>",
  "FEATURE_PDF_LOGO": "Sua logo no PDF",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Substitua o logotipo MoreApp pelo seu próprio</h4><p>Este recurso fica disponível ao mudar para o <b>Folha</b>Plano.</p>",
  "FEATURE_MULTIPLE_EMAIL": "Múltiplos emails",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Adicionar vários e-mails a um formulário</h4><p>p>Este recurso fica disponível ao mudar para o <b>Folha</b>plano.</p>",
  "FEATURE_WIDGETS": "Pro Widgets",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Inicie usando mais widgets como os widgets Catálogo, Cálculo e Avaliação</h4><p>>Este recurso fica disponível ao mudar para o <b>Folha</b>plan.</p>",
  "FEATURE_SEARCH": "Ferramenta de pesquisa no aplicativo",
  "FEATURE_SEARCH_MESSAGE": "Graças à função de pesquisa, é fácil procurar formulários preenchidos</h4><p>p>A função de pesquisa permite que você recupere registros enviados anteriormente e os use para um novo registro</p><p>Este recurso fica disponível ao mudar para o <b>Folha</b> plan.</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Modelos Customizáveis",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Crie os seus modelos customizáveis e poupe tempo ao construir novos formulários.</h4><p>Este recurso fica disponível ao mudar para a <b>Árvore</b>plan.</p>",
  "FEATURE_WEBHOOKS": "Integrações Web",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Use integrações web para subscrever a eventos na sua conta.</h4><p>Em cada evento enviamos uma mensagem para o URL configurado de forma a poder realizar uma ação. Isto permite-lhe implementar fluxos de trabalho complexos.</p><p><b>Example</b>: Configure a integração web que deverá ser ativada cada vez que um formulário for submetido, para guardar as informações do formulário na sua própria base de dados.</p><br><p>Este recurso fica disponível ao mudar para o <b>Ramo</b> Plano</p>",
  "SWITCH_PLAN_CTA": "Selecione um novo Plano",
  "SWITCH_PLAN_CONTACT_OWNER": "Contacte o seu Proprietário de Conta",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Pedido de actualização da nossa conta MoreApp a fim de utilizar a funcionalidade \"{{feature}}\"",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Seleccione uma conta de serviço...",
  "SERVICE_ACCOUNTS_NEW_LINK": "Conectar",
  "SERVICE_ACCOUNTS_NEW_TEXT": "uma nova conta de serviço",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "Sem contas de serviço '{{provider}}' configuradas.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Fale Conosco",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "Obrigado por seu feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "Respondemos suas dúvidas?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": "Como você classificaria sua experiência?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "Como podemos melhorar?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "Obrigado por seu feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "Envie sua avaliação aqui!",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Digite suas sugestões aqui",
  "ADD_USER_OR_GROUP": "Adicionar usuário ou grupo",
  "ADD_USER_TO_GROUP": "Adicionar usuário",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Selecionar um ou múltiplos usuários",
  "SELECT_ROLE": "Selecionar função",
  "ALL_USERS_ADDED_TO_GROUP": "Já adicionou todos os seus usuários existentes a este grupo",
  "WHATS_NEW": "Quais as novidades?",
  "WIDGET_LABEL": "Etiqueta",
  "WIDGET_LABEL_DESCRIPTION": "Utilize o <b>Etiqueta</b>-widget para mostrar uma divisória dentro do seu\n<a>formulário</a>.<br><br><b>Nota</b><br>Isto não faz parte dos dados <a>submissão</a>.",
  "WIDGET_TEXT": "Texto",
  "WIDGET_TEXT_DESCRIPTION": "Utilize o widget-<b>Texto</b> para deixar o utilizador preencher uma única linha de texto.",
  "WIDGET_TEXT_AREA": "Área de Texto",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Use a <b>Text Area</b>-widget para deixar o usuário preencher as frases completas.",
  "WIDGET_NUMBER": "Número",
  "WIDGET_NUMBER_DESCRIPTION": "Utilize o widget-<b>Número</b> para deixar o utilizador preencher um número inteiro.",
  "WIDGET_RADIO": "Rádio",
  "WIDGET_RADIO_DESCRIPTION": "Utilize o widget <b>Rádio</b> para deixar o utilizador escolher um dos seus valores pré-definidos.<br><br><b>Por exemplo:</b><br>Masculino / Feminino.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Utilize o widget <b>Checkbox</b> para deixar o utilizador marcar a sua propriedade Verdadeiro ou Falso.",
  "WIDGET_PHOTO": "Foto",
  "WIDGET_PHOTO_DESCRIPTION": "Utilize o widget <b>Photo</b> para deixar o utilizador fazer uma fotografia com a <a>Câmara<a> do dispositivo ou adicionar uma da <a>Biblioteca<a>.",
  "WIDGET_SIGNATURE": "Assinatura",
  "WIDGET_SIGNATURE_DESCRIPTION": "Utilize o widget-<b>Assinatura</b> para deixar o utilizador desenhar a sua assinatura.",
  "WIDGET_HEADER": "Cabeçalho",
  "WIDGET_HEADER_DESCRIPTION": "Utilize o widget-<b>Cabeçalho</b> para adicionar cabeçalhos ao seu formulário.<br><br><b> Nota</b><br> Isto não faz parte dos <a>dados apresentados<a>.",
  "WIDGET_DATE": "Data",
  "WIDGET_DATE_DESCRIPTION": "Utilize o widget-<b>Data</b> para deixar o utilizador preencher uma data.",
  "WIDGET_DATETIME": "Data Hora",
  "WIDGET_DATETIME_DESCRIPTION": "Utilize o widget-<b>Date e Hora</b> para deixar o utilizador preencher uma data e hora.",
  "WIDGET_TIME": "Horas",
  "WIDGET_TIME_DESCRIPTION": "Utilize o widget-<b>Hora</b> para deixar o utilizador preencher um tempo.",
  "WIDGET_EMAIL": "Email",
  "WIDGET_EMAIL_DESCRIPTION": "Utilize o widget-<b>Email</b> para deixar o utilizador preencher um endereço de correio electrónico válido.<br><br><b>Nota</b><br>Este campo pode ser usado como <a>Destinatário dinâmico<a><a> nas <a>definições de correio electrónico<a>.",
  "WIDGET_PHONE": "Telefone",
  "WIDGET_PHONE_DESCRIPTION": "Utilize o widget-<b>Telefone<b> para deixar o utilizador preencher um número de telefone válido.",
  "WIDGET_LOOKUP": "Lookup",
  "WIDGET_LOOKUP_DESCRIPTION": "Utilize o widget-<b>Lookup</b> para deixar o utilizador escolher um (ou mais) dos seus valores predefinidos.",
  "WIDGET_LIST_ITEM": "Lista de Itens",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Use o widget <b>Lista de itens</b> para criar um link para outro<a>formulário</a>.",
  "WIDGET_SUBFORM": "Sub-formulário",
  "WIDGET_SUBFORM_DESCRIPTION": "Use o Widget </b>Sub-formulário</b> para deixar o usuário adicionar <a> os mesmos widgets várias vezes</a> ao seu formulário.<br><br><b>Por exemplo:</b><br>Adicionar vários <a>materiais usados, horas trabalhadas, fotos, ou peças de construção</a> a uma <a>submissão</a>. Também é possível vincular sub-formulários e trabalhar com sub-formulários em sub-formulários.",
  "WIDGET_BARCODE": "Código de Barras",
  "WIDGET_BARCODE_DESCRIPTION": "Utilize o widget-<b>Código de barras</b> para deixar o utilizador digitalizar um código de barras usando o seu dispositivo <a>câmara</a>.<br><br><b>Nota:</b><br>Apoia QR, UPC, EAN, CODE_39 e muitos mais.",
  "WIDGET_SLIDER": "Deslizador",
  "WIDGET_SLIDER_DESCRIPTION": "Utilize o widget-<b>Deslizador</b> para deixar o utilizador arrastar um selector para escolher um número entre os seus valores mínimo e máximo definidos.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Use o <b>HTML</b>-widget para adicionar uma marcação personalizada ao seu formulário. (Somente para usuários técnicos)",
  "WIDGET_IMAGE": "Imagem",
  "WIDGET_IMAGE_DESCRIPTION": "Utilize o widget-<b>Imagem</b> para mostrar uma imagem dentro do seu\n<a>formulário</a>.<br><br><b>Nota</b><br>Isto não faz parte dos <a>dados de apresentação</a>. Se quiser que os utilizadores anexem uma fotografia, utilize o widget-<a>Photo</a>.",
  "WIDGET_SEARCH": "Pesquisa",
  "WIDGET_SEARCH_DESCRIPTION": "Use o widget <b>Pesquisa</b> em combinação com as Bases de Dados para permitir que os usuários selecionem os dados importados. Tais como informações de clientes, produtos e funcionários.",
  "WIDGET_RDW": "License Plate (NL)",
  "WIDGET_RDW_DESCRIPTION": "Utilize o widget-<b>License Plate (NL)</b> para permitir aos utilizadores a entrada de uma matrícula holandesa. O widget mostrará informação adicional sobre o veículo. <b>Nota</b> É necessária uma ligação à Internet para anexar a informação adicional.",
  "WIDGET_PIN": "Pino",
  "WIDGET_PIN_DESCRIPTION": "Utilize o widget-<b>Pino</b> para deixar o utilizador adicionar um ou mais pinos numa dada imagem/mapa. Cada pino que coloca abre um <a>sub-formulário</a> para o utilizador adicionar informação adicional sobre o pino.",
  "WIDGET_HELP": "Ajuda",
  "WIDGET_HELP_DESCRIPTION": "O widget-Ajuda permite-lhe adicionar informação adicional a qualquer widget. Quando um utilizador clica no widget-Ajuda, é visível um pop-up com a sua informação adicional.",
  "WIDGET_RATING": "Avaliação",
  "WIDGET_RATING_DESCRIPTION": "El widget-Avaliação permite aos utilizadores classificar um determinado assunto com um certo número de estrelas. É possível configurar uma escala de classificação entre duas e dez.",
  "WIDGET_CATALOGUE": "Catálogo",
  "WIDGET_CATALOGUE_DESCRIPTION": "Crie seu próprio aplicativo de pedido com este widget de Catálogo. Preencha um carrinho com produtos como você faz em uma loja virtual. Use uma Fonte de Dados com as seguintes colunas necessárias: id, nome, preçoExVat (em centavos), descrição. Opcional: foto, miniatura, e IVA. Foto e miniatura são URLs, portanto, estas só são mostradas se seu dispositivo tiver uma conexão com a Internet. Você pode adicionar tantas outras colunas à Fonte de Dados quantas quiser. Estas serão visíveis na página de detalhes do produto.",
  "WIDGET_SEARCH_WITH_GPS": "Pesquisar com GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Pesquisar com localização GPS",
  "WIDGET_TIME_DIFFERENCE": "Diferença de Tempo",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "Este widget calcula a diferença entre dois widgets-Hora ou widgets-Data-Hora.",
  "WIDGET_PAYMENT": "Pagamento",
  "WIDGET_PAYMENT_DESCRIPTION": "Permite que o usuário faça uma compra no próprio aplicativo para pagar pelo formulário.",
  "WIDGET_STOPWATCH": "Cronômetro",
  "WIDGET_STOPWATCH_DESCRIPTION": "Use o widget do Cronômetro para acompanhar o tempo em seu formulário. O resultado deste widget será, por exemplo, 00:16.47.",
  "WIDGET_SMILEY": "Smiley",
  "WIDGET_SMILEY_DESCRIPTION": "Widget para dar uma classificação baseada em smileys.",
  "WIDGET_CURRENT_LOCATION": "Localização Atual",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "Este widget obtém automaticamente sua localização atual usando GPS. O resultado seria algo como \"Museumstraat 1, 1071 XX Amsterdam, Holanda\". O resultado pode sempre ser editado manualmente, caso não esteja totalmente correto.",
  "WIDGET_DRAWING": "Desenho",
  "WIDGET_DRAWING_DESCRIPTION": "Com este widget, pode adicionar desenhos, linhas, e textos a uma fotografia.",
  "WIDGET_VIDEO": "Vídeo",
  "WIDGET_VIDEO_DESCRIPTION": "Este widget permitir-lhe-á gravar ou seleccionar um vídeo. Este widget resulta num URL no seu relatório PDF para descarregar o vídeo.",
  "WIDGET_ZIPCODE": "Código Postal",
  "WIDGET_ZIPCODE_DESCRIPTION": "O widget de código postal fornece dados de um endereço a partir de um código postal inserido.",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "O widget-IBAN valida os números IBAN.",
  "WIDGET_DRAWING_FREE": "Desenho Livre",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "Uma cópia do widget de Desenho, mas gratuita. Para os clientes do parceiro Blauwdruk.",
  "WIDGET_CALCULATION": "Cálculo",
  "WIDGET_CALCULATION_DESCRIPTION": "O widget de Cálculo permite operações como adição, subtração, multiplicação e divisão sobre um ou mais widgets.",
  "WIDGET_POSTCODE_NL": "Código Postal (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "Este widget só funciona para códigos postais holandeses! Com o widget código postal (NL), você obterá informações de endereço com base em um código postal e número de casa. Você precisa de uma conexão de internet para recuperar os dados. Quando estiver offline, você pode adicionar os dados manualmente.",
  "WIDGET_ADVANCED_SEARCH": "Pesquisa Avançada",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Usando o widget de Pesquisa Avançada, você pode colorir seus resultados de pesquisa no aplicativo. Você pode configurar qual cor deve ser usada quando um resultado de busca corresponde a uma consulta. Você pode definir cores usando descritores (como 'vermelho' ou 'verde') ou usando códigos de cores hexadecimais (como #1dc4c2)",
  "WIDGET_FILE": "Ficheiro",
  "WIDGET_FILE_DESCRIPTION": "Este widget permitir-lhe-á adicionar ficheiros ao seu formulário. Este widget resulta num URL no seu relatório PDF para descarregar os ficheiros.",
  "WIDGET_LOCATION": "Localização",
  "WIDGET_LOCATION_DESCRIPTION": "Este widget permite-lhe seleccionar um local num mapa.\n\nO resultado pode sempre ser editado manualmente, no caso de não estar totalmente correcto.",
  "WIDGET_PROPERTY_PIN_NAME": "Nome",
  "WIDGET_PROPERTY_PIN_FORM": "Formulário",
  "WIDGET_PROPERTY_PIN_ICON": "Ícone",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Identificação do item",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "Identificação do rastreamento",
  "WIDGET_PROPERTY_LABEL": "Descrição",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "O nome que é exibido acima do widget",
  "WIDGET_PROPERTY_OPERATOR": "Operador",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "Operador utilizado no cálculo.",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Termos de Cálculo",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "Todos os campos que devem ser incluídos no cálculo.",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Valor Inicial",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Valor inicial para o cálculo.",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Precisão Decimal",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "O número de dígitos que o cálculo tem.",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "Sem valor sobre termo inválido",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "Widget should not produce a value when one of the terms is invalid (i.e. not a number).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Catálogo de Fonte de Dados",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Selecione uma de suas fontes de dados para utilizar para o catálogo. Certifique-se de que a fonte de dados esteja de acordo com a configuração requerida.",
  "WIDGET_PROPERTY_CURRENCY": "Moeda",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Configura a moeda a utilizar, mas apenas se o valor for um preço",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "Porcentagem IVA",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "A percentagem de IVA que será aplicada a todos os itens do catálogo.",
  "WIDGET_PROPERTY_SHOW_PRICES": "Mostrar preços",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Verifique isto se você quiser que os preços unitários e totais sejam mostrados no aplicativo e no PDF.",
  "WIDGET_PROPERTY_SHOW_VAT": "Mostrar IVA",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Verifique isto se você quiser que os valores de IVA sejam mostrados no aplicativo e no PDF.",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Permitir observações",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Verifique isto para permitir que os usuários adicionem uma observação ao adicionar um item.",
  "WIDGET_PROPERTY_RATE_ICON": "Ícone de Classificação",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Selecione qual ícone é usado para a classificação, ex. corações",
  "WIDGET_PROPERTY_RATE_SCALE": "Escala de Classificação",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Selecione a quantidade de ícones exibidos.",
  "WIDGET_PROPERTY_TITLE": "Título",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "O título da explicação dada.",
  "WIDGET_PROPERTY_EXPLANATION": "Explicação",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "A explicação em si.",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Use o texto do botão de ajuda",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Quando selecionado, o texto definido abaixo será exibido antes de um ponto de interrogação.",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Butão de texto de Ajuda",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "O texto que é exibido antes de um ponto de interrogação quando a caixa de seleção acima é selecionada.",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "O valor calculado é um preço",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Formate o resultado como um preço.",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Ignorar widgets ocultos",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "Esta definição permite-lhe ignorar os widgets ocultos ao efetuar cálculos. Está disponível a partir da versão 7.0.32.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Usar leitor de código de barras",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Verifique para permitir que os usuários pesquisem através de um código de barras lido.",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Valor Padrão",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "Este valor já está predefinido.",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Consulta de pesquisa predefinida",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "A abertura da pesquisa usará essa consulta predefinida.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Texto da descrição",
  "WIDGET_PROPERTY_START_TIME": "Hora de Início",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Hora de início para uso no cálculo.",
  "WIDGET_PROPERTY_END_TIME": "Hora de finalização",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "Hora de fim para uso no cálculo.",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Imagem de Fundo",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Máxima duração em segundos",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "A duração máxima do vídeo em segundos.",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Qualidade do Vídeo",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Escolha a qualidade de vídeo de sua preferência.",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "Tipo de Ficheiro",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Selecione o tipo de ficheiro que pode ser carregado.",
  "WIDGET_PROPERTY_VALUE": "Valor",
  "WIDGET_PROPERTY_PLACEHOLDER": "Placeholder",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Selecione o tipo de ficheiro que pode ser carregado.",
  "WIDGET_PROPERTY_MAX_LENGTH": "Máx tamanho",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Quantidade máxima de caracteres. Mostrará um erro de validação no aplicativo móvel se este valor for excedido.",
  "WIDGET_PROPERTY_MIN_LENGTH": "Mín tamanho",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Quantidade mínima de caracteres. Mostrará um erro de validação no aplicativo móvel se este valor não for atingido.",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Valor mínimo",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "O aplicativo não permitirá escolher um valor abaixo deste.",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Valor máximo",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "O aplicativo não permitirá escolher um valor acima deste.",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Usar imagens como etiquetas",
  "WIDGET_PROPERTY_OPTIONS": "Opções",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Defina as opções que podem ser selecionadas no aplicativo.",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Alinhamento vertical",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Verifique para alinhar as opções verticalmente ao invés de horizontalmente.",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Verificado por padrão",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "Se verificado, este campo será verificado quando você abrir um formulário limpo.",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Qualidade da Fotografia",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Escolha sua qualidade fotográfica preferencial.<br><br><a>Melhor qualidade possível (recomendada)</a><br>Sem downscaling, sem compressão. Pode causar problemas no envio de emails com muitas fotos.<br><br><a>Submissão rápida</a><br>Downscale até um máximo de 1024x1024, comprimir em 75%. Pode causar problemas de memória em dispositivos de baixa qualidade.<br><br><a>Alta Qualidade</a><br>Downscale até o máximo de 1920x1920, comprimir em 10%. Podem causar problemas de memória em dispositivos de baixa qualidade e envio de emails com muitas fotos.",
  "WIDGET_PROPERTY_FILENAME": "Nome do Ficheiro",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Escolha o nome da foto conforme ela aparecerá na exportação.",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Permitir escolher imagem do dispositivo",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Permitir que os usuários selecionem uma foto previamente tirada a partir do dispositivo.",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Visualização em tamanho real",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Selecione esta opção se você quiser mostrar uma visualização em tamanho real da foto em seu formulário.",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Guardar uma cópia no dispositivo",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Tamanho do lápis",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Aumentar/diminuir o tamanho do lápis do usuário.",
  "WIDGET_PROPERTY_WIDE": "Alargar",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "Isto reduzirá a altura permitida de uma assinatura, tornando a assinatura resultante mais ampla.",
  "WIDGET_PROPERTY_TEXT": "Texto",
  "WIDGET_PROPERTY_HEADER_SIZE": "Tamanho do cabeçalho",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Tamanho do cabeçalho, variando de h1 (maior) a h6 (menor).",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Agora como pré-definição",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Marque para definir automaticamente o valor como \"agora\" quando o formulário for aberto.",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Ordenar Alfabeticamente",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Marque para classificar as opções em ordem alfabética.",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Permitir múltiplas seleções",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Verificar para permitir a selecção de múltiplas opções. Estas selecções serão guardadas como valores separados por vírgulas nos dados de submissão.",
  "WIDGET_PROPERTY_EXPAND": "Expandir",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Verifique para expandir a lista ao abrir o formulário.",
  "WIDGET_PROPERTY_TARGET_VIEW": "Visão geral",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Escolha o formulário a que pretende ligar.",
  "WIDGET_PROPERTY_IMAGE": "Imagem",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "Esta imagem será utilizada como miniatura na aplicação.",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Adicionar butão de texto",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Nome do botão para adicionar um item utilizando o subforma.",
  "WIDGET_PROPERTY_SUBFORM": "Sub-formulário",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Escolha o formulário que deseja utilizar para criar o seu objecto composto.",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Resumo em anexo",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Especifique como um item adicionado é exibido, usando os valores de campos específicos.<br><br><b>Por exemplo:</b><br> Cada item adicionado deve ser exibido como:<br> ${firstName} ${lastName}</b>",
  "WIDGET_PROPERTY_MINIMUM": "Mínimo",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "A quantidade mínima de vezes que uma subforma deve ser preenchida.",
  "WIDGET_PROPERTY_MAXIMUM": "Máximo",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "A quantidade máxima de vezes que uma subforma pode ser preenchida.",
  "WIDGET_PROPERTY_STEP_SIZE": "Tamanho do passo",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "Quando ajustado para 3, o deslizador irá sempre arredondar para um múltiplo de 3.",
  "WIDGET_PROPERTY_HIDE_VALUE": "Esconder valor",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Determina se deve ou não mostrar o valor na aplicação.",
  "WIDGET_PROPERTY_HTML_CODE": "HTML code",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Acrescentar um pedaço de HTML que suporte CSS em linha.",
  "WIDGET_PROPERTY_RESOURCE": "Fontes",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Seleccione uma imagem da galeria.",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Largura Máxima",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "Largura da imagem no interior da aplicação. A largura será limitada à largura do ecrã se este valor a exceder.",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Altura Máxima",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "Altura da imagem no interior da aplicação. A altura será limitada à altura do ecrã se este valor o exceder.",
  "WIDGET_PROPERTY_DATA_SOURCE": "Base de dados",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Seleccione uma das suas fontes de dados para pesquisar. Novas fontes de dados podem ser criadas a partir do menu principal Fontes de Dados.",
  "WIDGET_PROPERTY_FILTER": "Filtro",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filtre os resultados usando o valor de outros campos em seu formulário.",
  "WIDGET_PROPERTY_LIST_COLORS": "Lista de cores",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Aplique cores às entradas da fonte de dados que correspondem às consultas configuradas.",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Permitir escaneamento de códigos de barras",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "Marque para permitir que o usuário pesquise a fonte de dados usando o leitor de código de barras.",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Lembrar última pesquisa",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "Seleccione para chamar a consulta de pesquisa. A consulta de pesquisa será executada automaticamente.",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Permitir que o usuário mude imagem",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Selecione para permitir que os utilizadores utilizem as suas próprias imagens como fundo para pins, utilizando a câmara ou a galeria.",
  "WIDGET_PROPERTY_PINS": "Pins",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Define um ou mais pinos que podem ser colocados na imagem. Cada pino tem o seu próprio ícone e abrirá o formulário especificado quando colocado.<br><br> Na aplicação, os pinos serão numerados automaticamente. Para utilizar a sua própria numeração ou rotulagem, defina um Id de Seguimento.",
  "WIDGET_PROPERTY_REQUIRED": "Obrigatório",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "Se selecionado, este campo deve ser preenchido para guardar o formulário.",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Lembrar entrada",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Marque essa opção para lembrar a última entrada do usuário nesse dispositivo para o próximo envio. Se você também tiver definido um valor padrão, a opção Lembrar entrada será substituída.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Marca decimal",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Cantidad mínima",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "O aplicativo não permitirá escolher um valor abaixo deste.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Cantidad máxima",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "O aplicativo não permitirá escolher um valor acima deste.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Clique para ver a imagem",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Selecione esta opção se pretender apresentar uma pré-visualização em tamanho real da fotografia quando clicar na mesma.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Predefinição para a localização atual",
  "INTEGRATION_TASK": "Tarefa",
  "INTEGRATION_TASK_DESCRIPTION": "Esta integração permite-lhe criar uma tarefa com base na submissão recebida.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "Este plugin permite enviar um email usando o Mailchimp. Você pode usar os dados em seu registro como placeholders (marcadores de lugar) em seu modelo no Mailchimp.",
  "INTEGRATION_DELETE": "Eliminar",
  "INTEGRATION_DELETE_DESCRIPTION": "Essa integração permite que você exclua automaticamente os envios de um formulário. Certifique-se de que essa seja a última integração que você adicionará ao seu formulário. Depois que um envio é excluído, ele não pode ser restaurado, portanto, tenha cuidado ao usar essa integração.",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "Essa integração permite que você use o Microsoft Word para exportar dados. Você pode enviar anexos em PDF ou Word com essa integração. E você pode carregar seu modelo Word.docx para estilizar seus relatórios.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "A integração com o WebDAV permite que você envie o PDF de um e-mail para um servidor WebDAV.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "Essa integração permite que você envie seus formulários preenchidos para uma planilha do Google. Você pode obter o ID da planilha do Google no URL. Observação: seus formulários enviados serão simplesmente adicionados como uma nova linha em sua planilha. Não atualizamos os cabeçalhos da planilha sempre que você atualiza o formulário. Você mesmo terá que atualizar isso. Não oferecemos suporte a imagens/arquivos e todos os dados aninhados (subformulários, pins, widgets de pesquisa) serão adicionados a uma única célula. Com formulários com regras e campos vazios, os dados podem ser colocados na coluna errada.",
  "INTEGRATION_MONGODB_INSERT": "Inserção MongoDB",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "A integração MongoDB Insert permite que você insira os dados do seu envio em um banco de dados Mongo.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "Essa integração permite que você envie o PDF anexado ao seu formulário para o Microsoft OneDrive.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "Essa integração permite que você use o Excel para exportar dados. Carregue seu modelo Excel.xlsx e receba seus dados em um arquivo Excel.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Atualização de células do Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Atualizar uma célula específica em uma planilha existente. Essa integração permite que você atualize uma planilha que também é usada como uma fonte de dados importada na MoreApp.",
  "INTEGRATION_MESSAGE_BOARD": "Painel de mensagens",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Permitir o envio de mensagens",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Aceitar Google Sheet",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "Não destinado à produção",
  "INTEGRATION_PROPERTY_FOLDER": "Pasta",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "A pasta para a qual uma tarefa deve ser enviada.",
  "INTEGRATION_PROPERTY_FORM": "Formulário",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "O formulário (dentro da pasta selecionada) que deve ser utilizado como destino para a tarefa.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Destinatários (lista de endereços de email separada por vírgulas)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Recipientes (lista de endereços de email separada por vírgulas)",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Destinatários dinâmicos",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Use os dados de um widget de Email como um destinatário",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Mensagem para a tarefa",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Mensagem para a tarefa",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Atraso (em dias)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "O número de dias que o envio deve persistir antes de ser excluído.",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Formato de saída",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Configuração do formato de saída a ser usado como anexo",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Responder a (opcional)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "Os recipientes do endereço responderão a",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "De (opcional)",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "O nome do qual você quer que os emails se originem",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Recipientes",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Lista separada por vírgula dos destinatários",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "Enviar ao usuário que submeteu o formulário",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Verifique isso para adicionar o usuário que submeteu o formulário como destinatário",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Lista separada por vírgula dos destinatários",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Lista separada por vírgula dos destinatários",
  "INTEGRATION_PROPERTY_SUBJECT": "Assunto",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Assunto com placeholders opcionais",
  "INTEGRATION_PROPERTY_BODY": "Corpo de texto",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "Estrutura de correio eletrônico com placeholders opcionais",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Qualidade de imagem",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Se o seu envio tiver muitas fotos de alta resolução, talvez seja melhor diminuir a qualidade. Caso contrário, o e-mail poderá ficar muito grande para sua caixa postal.",
  "INTEGRATION_PROPERTY_FILENAME": "Nome do Ficheiro",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Assunto com placeholders opcionais",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Carregar um modelo do Word",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "O modelo Word a ser usado",
  "INTEGRATION_PROPERTY_SERVER": "Servidor",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL para o servidor, será usado como um caminho de base. Por exemplo: http://my.server.com",
  "INTEGRATION_PROPERTY_PATH": "Direção",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "A direção onde os PDFs devem ser armazenados, pode incluir placeholders, por exemplo /drive/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "Nome de usuário WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Nome de usuário a ser usado ao se autenticar no servidor WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "Palavra-passe WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Palavra-passe a utilizar ao autenticar-se no servidor WebDAV",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Iniciar Sessão no Google",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Escolha uma conta Google para que possamos escrever para suas planilhas do Google",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "ID de Folha de Cálculo",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "O ID exclusivo de sua planilha, mostrado na URL como https://docs.google.com/spreadsheets/d/<b>my-spreadsheet-id</b>.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Nome da Folha",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "O nome de sua folha, que normalmente não tem como padrão 'Folha1'",
  "INTEGRATION_PROPERTY_METADATA": "Metadados",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Selecione quais meta campos você deseja incluir na exportação. Estes serão inseridos antes dos dados do formulário",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "Inserção MongoDB",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "O endereço IP do servidor MongoDB",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "Portal MongoDB (Port)",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "O portal sobre o qual MongoDB está escutando",
  "INTEGRATION_PROPERTY_USE_SSL": "Use SSL",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Use uma conexão TLS segura",
  "INTEGRATION_PROPERTY_USERNAME": "Nome de usuário",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "O nome de usuário do usuário Mongo que devemos autenticar como",
  "INTEGRATION_PROPERTY_PASSWORD": "Senha",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "A palavra-passe do usuário Mongo que devemos autenticar como",
  "INTEGRATION_PROPERTY_DATABASE": "Base de dados",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "O banco de dados no qual você deseja salvar os envios",
  "INTEGRATION_PROPERTY_COLLECTION": "Coleção",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "A coleção na qual você deseja salvar os envios",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Widget de Pesquisa",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "Widget de Pesquisa",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "Login no OneDrive",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Escolha uma conta do OneDrive para que possamos salvar arquivos",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "Incluir arquivo CSV",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Anexa automaticamente um arquivo CSV (Comma Separated Value) ao seu diretório do OneDrive",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Fazer upload de um modelo do Excel",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "O modelo do Excel a ser usado",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Nome do arquivo do Excel",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Nome do arquivo Excel como deve ser anexado",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Copiar para o usuário",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Enviar uma cópia para o usuário que preencheu o formulário",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Anexar imagens",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Anexar imagens e assinaturas ao correio",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "Novo widget de valor",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Selecione o widget do qual você deseja obter o novo valor",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "Nome da coluna a ser atualizada",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Defina a coluna que você deseja atualizar com o valor do \"widget de novo valor\" selecionado",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "A integração FTPS salva os dados de envio no servidor FTPS fornecido.",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protocolo",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "O protocolo a ser usado na conexão com o servidor FTP",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "Desativar a verificação SSL",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "Você pode usar essa opção quando tiver um certificado autoassinado ou uma cadeia de certificados inválida (aplicável somente ao usar FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Utilizar o modo FTPS 'implícito'",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Alternar entre 'implícito' e 'modo FTPS explícito' (apenas aplicável quando se utiliza FTPS).",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "URL para o servidor sem o protocolo, será utilizado como caminho de base. Por exemplo: my.server.com.",
  "INTEGRATION_PROPERTY_PORT": "Porto",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Porto para o servidor FTP(S), este será por defeito de 21 ou 990 se não for preenchido.",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Colocar cada um envio na sua própria pasta",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Anexa automaticamente '/customerId/form-name/número de série' ao caminho remoto para fazer um caminho único por um envio.",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "Nome de utilizador FTP",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Nome de utilizador a utilizar quando se autenticar no servidor WebDAV.",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "Senha FTP",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Senha a utilizar quando se autenticar no servidor WebDAV.",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "Incluir PDF",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Indica se também deve ou não copiar o PDF.",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "Armazenar PDF para e-mails sem destinatário",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Indica se armazena ou não PDFs que são gerados para e-mails sem um destinatário.",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Incluir ficheiros",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Indica se também deve ou não copiar ficheiros.",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Carregar um modelo do Excel",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "O modelo do Excel a ser usado",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Usar delimitadores",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Seleccione esta opção se pretender utilizar '${' como delimitador inicial para os placeholders (esta opção só é recomendada para modelos existentes mais antigos).",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Nome do arquivo do Excel",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Nome do arquivo Excel como deve ser anexado",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Destinatários",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Lista separada por vírgula dos destinatários.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Destinatários dinâmicos",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Use os dados de um widget de Email como um destinatário",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Copiar para o usuário",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Enviar uma cópia para o usuário que preencheu o formulário",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Qualidade de imagem",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Uma maior qualidade levará mais tempo a processar o correio",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Assunto",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Assunto com placeholders opcionais",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Corpo de texto",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "Estrutura de correio eletrónico com placeholders opcionais",
  "DISABLED_PENDING_VERIFICATION": "Essa funcionalidade será ativada após o contato com o suporte. Entre em contato com nossa equipe para ativar essa opção.",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Verifique as suas definições de tempo",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "Não pode iniciar sessão, porque o seu relógio está muito distante.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>SOLUÇÃO</b>: Altere a hora e o fuso horário no seu dispositivo para reflectir com precisão a hora e o fuso horário da sua localização actual. Depois tente novamente.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Tentativa de login",
  "TOUR_PROGRESS_STEP1_LABEL": "Como funciona",
  "TOUR_PROGRESS_STEP2_LABEL": "Conte-nos mais",
  "TOUR_PROGRESS_STEP3_LABEL": "Primeiro formulário",
  "TOUR_SKIP": "Pular tour",
  "TOUR_STEP1_TRIAL": "Está iniciando o seu Período Experimental de 30 dias.",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Construa e modifique formulários no seu computador",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Preencha formulários no seu telemóvel ou tablet",
  "TOUR_STEP1_RECEIVE_DATA": "Receba um relatório na sua caixa de email",
  "TOUR_STEP2_HEADER": "Dê-nos mais",
  "TOUR_STEP3_HEADER": "Como podemos entrar em contato com você?",
  "TOUR_ERROR_MODAL_TITLE": "Oops...",
  "TOUR_ERROR_MODAL_MESSAGE": "Algo deu errado neste tour. Enquanto isso, você pode explorar {{appName}} por si mesmo.",
  "TOUR_ERROR_MODAL_BUTTON": "Explorar {{appName}}",
  "FIRST_NAME": "Nome",
  "LAST_NAME": "Sobrenome",
  "PAGE_CONTROL_PAGE_SIZE": "Tamanho da página",
  "PAGE_CONTROL_TOTAL_ITEMS": "Total de itens",
  "VIEW_MANAGEMENT_OVERVIEW": "Vista Geral",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Registros",
  "VIEW_MANAGEMENT_TASKS": "Tarefas",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Custos",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Estado",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "Em",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Editar",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_BETA": "Editar formulário (Beta)",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Compartilhar",
  "FORM_REMOVE_MODAL_TITLE": "Eliminar Rascunho",
  "FORM_REMOVE_MODAL_MESSAGE": "Tem certeza de que deseja excluir esse rascunho?",
  "REMOVE_DRAFT_TITLE": "Eliminar Formulário",
  "REMOVE_DRAFT_MESSAGE": "Tem certeza de que deseja excluir esse formulário?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Remover modelo",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "Tem a certeza que pretende eliminar este modelo de formulário?",
  "UNPUBLISHED": "Não Publicado",
  "PUBLISHED": "Publicado",
  "PUBLISHED_UPDATED": "Publicado, atualização disponível",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Administrar usuários",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Por no Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Compartilhado no Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Oculto no Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "Ver no Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Editar",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Atualizar para última versão",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "Seu modelo de formulário não pôde ser atualizado para a versão mais recente. Por favor, tente novamente mais tarde.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Ocultar do Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Mostrar no Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Por Formulário no Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "Então, você deseja compartilhar o seu formulário com todos os outros usuários?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Por favor, preencha primeiro este formulário. Esta informação (exceto sua Conta Bancária) estará visível no Marketplace.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Atualizar informação",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Nomeespaço",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Exemplo: com.minhaempresa",
  "FORM_TEMPLATE_KEY_LABEL": "Chave",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Exemplo: formularioespecial",
  "FORM_TEMPLATE_TYPE_LABEL": "Tipo",
  "FORM_TEMPLATE_NAME_LABEL": "Nome",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Coloque um nome no seu Modelo de Formulário",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Descrição",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Descreva seu Modelo de Formulário",
  "FORM_TEMPLATE_LOGO_LABEL": "Logo",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "O URL do logo de seu Modelo de Formulário",
  "DIRECT_FORM_LINK": "Gerar URL direta",
  "DIRECT_FORM_LINK_GENERATED": "Abrir URL direta",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Revogar URL direta",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "Tem certeza de que deseja revogar o URL que dá acesso direto ao seu formulário? Isso significa que o URL deixará de funcionar.",
  "PUBLISH": "Publicar",
  "PUBLISH_FORM_VERSION": "Publicar esta versão",
  "PUBLISH_FORM_VERSION_MESSAGE": "Tem certeza que quer publicar esta versão do seu formulário?",
  "PUBLISHED_BY": "Publicado por",
  "PUBLISHED_ON": "Publicado em",
  "REMARKS": "Observações",
  "DRAFT": "Rascunho",
  "FORM_HISTORY": "Histórico de versões",
  "FORM_DESCRIPTION_PLACEHOLDER": "Adicione uma breve descrição do seu formulário",
  "FORM_MOVE_MODAL_TITLE": "Alterar",
  "FORM_SAVE_AS_TEMPLATE": "Salvar como Modelo",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "Tem certeza que quer salvar esse formulário como modelo?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Alterar formulário para a pasta selecionada",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Selecionar pasta",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "Parece que você ou um colega estão a editar este formulário noutro lugar. Certifique-se de que é o único que está a editar este formulário e tente novamente.",
  "INTEGRATIONS_EOL_MESSAGE": "Este formulario utiliza una integración que ha sido desactivada. Por favor, elimínala o sustitúyela por otra integración o webhook. Por favor, <a href=\"https://help.moreapp.com/es/support/tickets/new\">contacta con soporte</a> si necesitas ayuda.",
  "DATASOURCE_PICKER_ADD": "Adicionar uma base de dados",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Pesquisar por bases de dados",
  "GROUP_PICKER_ADD_USER": "Convidar usuário(s)",
  "GROUP_PICKER_ADD_GROUP": "+ Criar Novo Grupo",
  "GROUP_PICKER_ALL_USERS": "Todos usuários",
  "GROUP_PICKER_INVITED": "(Convidado)",
  "MANAGE_ROLES": "Gerencie Funções",
  "ROLE": "Funções",
  "SEARCH_GROUP_PLACEHOLDER": "Pesquisar por grupos",
  "BILLING_TITLE": "Faturação",
  "BILLING_OVERVIEW_MENU_ITEM": "Vista Geral",
  "BILLING_INVOICES_MENU_ITEM": "Faturas",
  "BILLING_USAGE_MENU_ITEM": "Uso",
  "SUBTOTAL": "Subtotal",
  "TOTAL": "Total",
  "NEXT_INVOICE": "Próxima Fatura",
  "PREVIEW_CHANGE_EXPLANATION": "Esta tabela mostra sua futura fatura quando aplicadas. alterações. Verifique este artigo da <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Central de Ajuda</a> para explicar como sua fatura é composta.",
  "PREVIEW_CHANGE": "Vista previa de alterações",
  "CHANGE_SUBSCRIPTION_BUTTON": "Alterar subscrição",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "Ver Alteração",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Alteração de plano configurada",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Alteração",
  "SCHEDULED_CANCEL_MESSAGE": "A sua subscrição está programada para ser cancelada no final do período de faturação atual",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Cancelar Alteração",
  "SELECT_PLAN": "Escolha seu Plano",
  "YOUR_SELECTED_PLAN": "O seu plano seleccionado",
  "LOADING_PLANS": "Carregando Planos disponíveis",
  "MONTHLY_PLAN": "Plano Mensal",
  "YEARLY_PLAN": "Plano Anual",
  "MONTH_SHORT": "Mês",
  "YEAR_SHORT": "ano",
  "TRIAL_ACTIVE": "Período de Teste Ativo",
  "DAYS": "Dias",
  "TRIAL_DAYS_REMAINING": "Dias Restantes",
  "TRIAL_ENDS_TODAY": "O seu período de teste acaba hoje",
  "TRIAL_ENDS_TOMORROW": "O seu período de teste acaba amanhã",
  "TRIAL_ENDS_ON": "O seu período de teste acaba a",
  "SUBMISSIONS": "Envios",
  "USERS": "Usuários",
  "USAGE": "Uso",
  "UNLIMITED": "Ilimitado",
  "CHANGE_PLAN": "Alterar Plano",
  "CANCEL_PLAN": "Cancelar Plano",
  "UNCANCEL_PLAN": "Não cancelar o plano",
  "PLAN_MORE_OPTIONS": "Mais opções",
  "DELETE_ACCOUNT": "Eliminar Conta",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "Ainda tem uma fatura pendente",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "Ainda tem uma fatura pendente, pelo que não pode executar esta ação. Vá para a guia Faturas para concluir o seu pagamento.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Ir para Faturas",
  "CANCEL_PLAN_MODAL_TITLE": "Cancelar Plano",
  "CANCEL_PLAN_MODAL_MESSAGE": "Pode continuar a utilizar o nosso produto até ao final do seu ciclo de faturação. Depois disso, os envios não serão processados. Pode ainda aceder à sua conta para ver e exportar os seus dados durante 30 dias. Depois disso, a sua conta será eliminada",
  "UNCANCEL_PLAN_MODAL_TITLE": "Não cancelar o plano",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Ótimo, mudaste de ideias e queres continuar a usar a MoreApp?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "Plano com cancelamento previsto",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "O seu plano está programado para ser cancelado no final do seu ciclo de faturação atual.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "Informe-nos sobre como podemos melhorar o nosso produto. O seu feedback é importante para nós.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>A sua opinião é importante para nós</h4><p>Por favor, diga-nos como podemos melhorar o nosso produto.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_CANCEL_PLAN": "Por que você deseja cancelar o seu plano? Você pode selecionar várias respostas.",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "Por que você quer eliminar a sua conta?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "Encontrei uma alternativa",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "É demasiado caro",
  "TERMINATION_FEEDBACK_MODAL_REASON_RECENT_PRICE_INCREASE": "Aumento de preços recente",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "Já não é necessário",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "Estou a encerrar o meu negócio",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "Não estou satisfeito com o produto",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "Falta uma funcionalidade",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Outro",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Por favor, selecione pelo menos um motivo.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "Há mais alguma coisa que queira partilhar connosco?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Por favor, introduza uma mensagem.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Cancelar Plano",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Eliminar Conta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Sim, quero eliminar a minha conta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "Compreendo que, após <b>30 dias</b>, a conta da minha empresa <b>{{customerName}}</b> ({{customerId}}) e todos os dados relacionados serão permanentemente eliminados.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Por favor, confirme preenchendo o nome da sua conta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "O nome da conta preenchido não corresponde ao nome da conta que está a tentar eliminar.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Eliminar Conta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "Falha ao eliminar a conta. Tente novamente mais tarde.",
  "BILLING_PROFILE": "Perfil de Faturação",
  "BILLING_PHONE_NUMBER": "Número de telefone",
  "INVOICE_ADDRESS": "Endereço de Faturação",
  "PAYMENT_METHOD": "Método de Pagamento",
  "UPCOMING_INVOICE": "Próxima Fatura",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "Em período experimental",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Inicie a MoreApp gratuitamente durante o período experimental. Quer continuar após o período experimental ? Adicione um método de pagamento para receber a sua primeira fatura.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Empieza gratis con MoreApp durante el período experimental. Una vez finalizado, recibirás la siguiente factura.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Valor devido após o teste",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "Nenhuma fatura futura",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "A sua subscrição foi cancelada. Não existem facturas futuras. O seu período de faturação termina em ",
  "CARD_HOLDER_NAME": "Nome do Titular do Cartão",
  "CARD_HOLDER_NAME_PLACEHOLDER": "e.x. João Sousa",
  "ADD_CARD": "+ Adicionar Método de Pagamento",
  "MAKE_DEFAULT": "Estabelecer como predeterminada",
  "EXPIRES": "expira",
  "PAYMENT_METHOD_REMOVE_TITLE": "Eliminar método de pago",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "Tem a certeza que quer eliminar este método de pagamento?",
  "PLAN_LEAF": "Folha",
  "PLAN_BRANCH": "Ramo",
  "PLAN_TREE": "Árvore",
  "PLAN_FOREST": "Floresta",
  "PLAN_LEAF_DESCRIPTION": "Crie Formulários Profissionais",
  "PLAN_BRANCH_DESCRIPTION": "Processamento avançado de dados",
  "PLAN_TREE_DESCRIPTION": "Para todo o seu negócio",
  "PLAN_FOREST_DESCRIPTION": "Para empresas",
  "PLAN_LEAF_FEATURES_HEADER": "Características",
  "PLAN_BRANCH_FEATURES_HEADER": "Plano Folha +",
  "PLAN_TREE_FEATURES_HEADER": "Plano Ramo +",
  "PLAN_FOREST_FEATURES_HEADER": "Plano Árvore +",
  "PLAN_AMOUNT_OF_USERS_ONE": "Um Usuário",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Usuários ilimitados",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} envios",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} Árvores",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / envio adicional",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / envio adicional",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / envio adicional",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "0,10€ / envio adicional",
  "FEATURE_FORM_BUILDER": "Gerador de Formulários",
  "FEATURE_APP": "Aplicativo",
  "FEATURE_EXCEL_EXPORT": "Relatório Excel",
  "FEATURE_EMAIL": "Email com PDF",
  "FEATURE_RULES": "Regras",
  "FEATURE_API": "Acesso à API",
  "FEATURE_MARKETPLACE_TEMPLATES": "Modelos",
  "FEATURE_TASKS": "Tarefas",
  "FEATURE_LOGO_PDF": "O teu logo no PDF",
  "FEATURE_DATA_IMPORT": "Importação de dados",
  "FEATURE_MULTIPLE_PDF": "Múltiplos emails",
  "FEATURE_ADVANCED_PDF": "PDF personalizado",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Importação automática de dados",
  "FEATURE_SINGLE_SIGN_ON": "Single sign-on",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Single sign-on",
  "FEATURE_SENDING_DOMAIN": "Domínio e-mail personalizado",
  "FEATURE_DEDICATED_SUPPORT": "Assistência Personalizada",
  "VAT": "IVA",
  "APPLIED_BALANCE": "Saldo aplicado",
  "AMOUNT_DUE": "Valor devido",
  "REMAINING_BALANCE": "Saldo restante",
  "CREDIT_CARD": "Cartão de Crédito",
  "SEPA_DIRECT_DEBIT": "SEPA Debito Automático",
  "PAY_ON_FILE": "Transferência",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "Esta opção não está disponível para sua assinatura atual",
  "SEPA_MANDATE": "Ao fornecer o seu IBAN, está a autorizar (A) MoreApp e o Stripe, o nosso fornecedor de serviços de pagamento, a enviar instruções ao seu banco para debitar a sua conta e (B) o seu banco para debitar a sua conta de acordo com essas instruções. Este comando destina-se apenas a transacções entre empresas. Não tem direito a um reembolso do seu banco após o débito da sua conta, mas tem o direito de solicitar ao seu banco que não debite a sua conta até ao dia em que o pagamento é devido.",
  "INVOICE_NUMBER": "Número da Fatura",
  "INVOICE_DATE": "Data",
  "INVOICE_TOTAL": "Total",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "Status",
  "BASE_MONTH": "Plano Mensal",
  "BASE_YEAR": "Plano Anual",
  "FORMS_MONTH": "Uso Mensal",
  "FORMS_YEAR": "Uso Anual",
  "BRANDING_MONTH": "Branding Mensal",
  "BRANDING_YEAR": "Branding Anual",
  "ADDON_MONTH": "Extensão Mensal",
  "ADDON_YEAR": "Extensão Anual",
  "MAINTENANCE_FTPS_MONTH": "Manutenção - FTPS mensal",
  "MAINTENANCE_FTPS_YEAR": "Manutenção - FTPS anual",
  "MANUAL_INVOICE_DESCRIPTION": "As faturas serão enviadas para o seu email de faturação",
  "USAGE_DAILY": "Diariamente",
  "USAGE_MONTHLY": "Mensalmente",
  "MONTH": "Mês",
  "YEAR": "Ano",
  "INVOICE_REFERENCE": "Informações adicionais da fatura",
  "INVOICE_REFERENCE_DESCRIPTION": "Esta informação serão adicionada à fatura de sua administração, por exemplo, um número de pedido de compra.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "Essa alteração será executada imediatamente",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "Essa alteração será executada em sua próxima fatura",
  "TREES_PLANTED": "Árvores plantadas",
  "TREES_PLANTED_TOTAL": "árvores plantadas no total",
  "TREES_PLANTED_PER_MONTH": "árvores plantadas por mês",
  "TREES_PLANTED_PER_YEAR": "árvores plantadas por ano",
  "DOWNGRADE_QUESTION": "Por que você quer mudar seu plano?",
  "DOWNGRADE_NOT_NEEDED": "Não é mais necessário",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Observações",
  "DOWNGRADE_ALTERNATIVE": "Encontrei uma alternativa",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "Qual alternativa?",
  "DOWNGRADE_MISSING_FEATURE": "Falta uma funcionalidade",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "Que funcionalidade lhe falta?",
  "DOWNGRADE_PRICING": "Muito caro",
  "DOWNGRADE_PRICING_DESCRIPTION": "Observações",
  "DOWNGRADE_UNSATISFIED": "Insatisfeito",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "Como podemos melhorar?",
  "DOWNGRADE_OTHER": "Outro",
  "DOWNGRADE_OTHER_DESCRIPTION": "Qual é o seu motivo?",
  "SELECT_OPTION": "Selecione uma opção",
  "CONTACT_BUTTON": "Contacte-nos",
  "CONTACT_LINK": "https://meet.moreapp.com/meetings/moreapp/account-manager-es",
  "PRICE_LABEL_NOW": "agora",
  "PRICE_LABEL_UPCOMING": "desde janeiro 2025",
  "PRICE_ANNUAL_BILLED_MONTHLY": "cobrados anualmente",
  "BILLING_FLOW_STEP1_LABEL": "Seleccionar Plano",
  "BILLING_FLOW_STEP2_LABEL": "Detalhes da facturação",
  "BILLING_FLOW_STEP3_LABEL": "Forma de pagamento",
  "BILLING_FLOW_STEP4_LABEL": "Confirme",
  "ONETIME": "Um vez",
  "MONTHLY": "Mensal",
  "YEARLY": "Anualmente",
  "RECURRENCE": "Recorrência",
  "BRANDING": "Branding",
  "RESTORE": "Recuperar",
  "RESTORE_FORM_MESSAGE": "Ao recuperar este formulário, ele irá reaparecer na lista de formulários da plataforma. Também vai aparecer no aplicativo, se o formulário tiver sido publicado.",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Crie a sua conta gratuitamente",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "Comece o seu período experimental de 30 dias",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "Endereço de email",
  "SIGN_UP_CUSTOMER_PASSWORD": "Senha",
  "SIGN_UP_CUSTOMER_BUTTON": "Crie uma conta",
  "EMAIL_ADDRESS_PLACEHOLDER": "email@empresa.com",
  "EMAIL_VALIDATION_VALIDATING": "Validando email...",
  "EMAIL_VALIDATION_FAILED": "Falho ao validar o endereço de email. Não foi possível encontrar a conta correspondente para validação da sua conta. Pode ser que sua conta já esteja validada.",
  "EMAIL_VALIDATION_SUCCESS": "Seu endereço de email foi verificado com sucesso!",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Clique na imagem para adicionar pins",
  "ALERT_TOUCH_DEVICE": "A plataforma não funciona corretamente em dispositivos móveis. Por favor, use seu laptop ou PC",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>Você tem uma fatura em aberto!</b> Certifique-se de <a href ui-sref='portal.customers.billing.overview'>pagar todas as suas faturas</a> para continuar a enviar seus formulários.",
  "ADD_VIEW_TEMPLATE_TYPE": "Utilizando modelo:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "Branco",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Instalado",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Obter mais formulários do Marketplace",
  "BLANK_FORM": "Em Branco",
  "BLANK_FORM_DESCRIPTION": "Comece com um formulário em branco",
  "AGREEMENTS_MODAL_TITLE": "Atualizações de Termos e Condições",
  "AGREEMENTS_MODAL_MESSAGE": "Atualizámos o nosso Acordo de Final de Usuário incluindo o Adendo de Processamento de Dados. Por favor leia cuidadosamente. Se tiver alguma dúvida ou preocupação contacte-nos. De outra forma, não é necessária qualquer ação da sua parte e a atualização dos termos e condições será efetuada a 1 de fevereiro, 2019.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Continuar",
  "ADD_PHOTO": "Adicionar foto",
  "CAPTURE_PHOTO": "Capturar foto",
  "BROWSE_PHOTO": "Buscar fotos",
  "DRAW_SIGNATURE": "Desenhe a assinatura",
  "START_DRAWING": "Começar desenho",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Escolha a imagem de fundo",
  "ADD_VIDEO": "Adicione vídeo",
  "SIGN_UP_THANKS": "Obrigado por se Registrar!",
  "SIGN_UP_CHECK_MAIL": "Por favor, verifique sua Caixa de Entrada para Validar seu endereço de Email.",
  "FORM_BUILDER": "Construtor de Formulários",
  "MOBILE_APP": "Aplicativo MoreApp",
  "PDF_REPORTS": "Relatórios PDF",
  "EXCEL_EXPORT": "Exportar Excel",
  "TASKS": "Tarefas",
  "PDF_LOGO": "Logotipo PDF personalizado",
  "REALTIME_DATA_SOURCES": "Fontes de dados em Tempo Real",
  "PDF_LAYOUT": "Layout PDF personalizado",
  "RULES": "Regras",
  "API_ACCESS": "Acesso API",
  "COLOR_THEMES": "Esquemas de Cor",
  "THEMES_SYSTEM": "Temas padrões",
  "THEMES_CUSTOM": "Temas personalizados",
  "GROUP_ROLES": "Funções e Permissões do Grupo",
  "CUSTOM_DOMAIN": "Domínio Customizado",
  "DEDICATED_ACCOUNT_MANAGER": "Gestor de Conta Dedicado",
  "PREMIUM_SUPPORT": "Suporte Premium",
  "FILE_SELECT": "Selecione um Arquivo",
  "NEW_CUSTOMER": "+ Novo cliente",
  "TO_PORTAL": "Para o Portal",
  "EXPIRED": "Expirado",
  "READ_MORE": "Ler mais",
  "CHOOSE_A_PLAN": "Escolha um plano",
  "TRIAL": "Julgamento",
  "TRIAL_BANNER_TITLE": "Está a fazer um teste gratuito",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} dias restantes",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} envios restantes",
  "TRIAL_BANNER_CTA_CONTACT": "Entre em contato conosco",
  "TRIAL_BANNER_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-pt",
  "UPGRADE_NOW": "Actualizar agora",
  "CONTACT_SALES": "Entre em contato conosco",
  "BANNER_DELETION_LABEL": "A sua conta está programada para ser apagada em {{days}} dia(s)",
  "BANNER_DELETE_TODAY_LABEL": "A sua conta será apagada hoje",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Escolha um plano para continuar a usar MoreApp",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Por favor, seleccione um plano para continuar a utilizar MoreApp. Caso contrário, esta conta será apagada no(s) {{days}} dia(s).",
  "BANNER_NO_SUBSCRIPTION_TITLE": "Sem assinatura activa",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Por favor seleccione um plano para continuar",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Importante:</b> Devido à <a href='https://help.moreapp.com/support/solutions/articles/13000100648' target='_blank'>manutenção</a> programada do sistema, o MoreApp não estará disponível na quarta-feira 19-04-2023 entre 19.00-21.00 CET",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Importante:</b> Devido ao trabalho de manutenção programado, o Produto não estará disponível na quarta-feira 19/04/2023 entre as 19:00-21:00 CET.",
  "BANNER_OVER_USAGE_TITLE": "Excedeu o número de envios gratuitos.",
  "BANNER_OVER_USAGE_DESCRIPTION": "<b>Excedeu o número de registos gratuitos.</b> ✈️ {{nrOfSubmissionsOverIncluded}} envios adicionais 💰 {{(extraUsageCostsInCents / 100 | currencySymbol:'eur')}} custo adicional",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Actualizar o seu plano",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Contacte-nos",
  "BANNER_OVER_USAGE_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/account-manager-es",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "O seu cartão de crédito expirou.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Adicione um método de pagamento válido.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Atualizar o Método de Pagamento",
  "BANNER_PAST_DUE_INVOICE_TITLE": "Tem uma fatura por pagar.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Por favor, pague a sua fatura antes que a sua conta seja bloqueada.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Pagar fatura",
  "BANNER_UNPAID_INVOICE_TITLE": "A sua conta está bloqueada devido a uma fatura não paga.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Pague a sua fatura para desbloquear.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Pagar fatura",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Exibição de seleção de colunas",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Editar Base de Dados",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Subir Excel",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Programar importação",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Buscar",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Ativar",
  "DATA_SOURCES_TITLE": "Base de Dados",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Última atualização",
  "DATA_SOURCES_GENERAL_INFORMATION": "Informação Geral",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Última atualização correta",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Mostrar avisos",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "Configuração URL",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Programar importação url",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Importando...",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Programar importação Excel",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Importando...",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Últimas advertências:",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "( Mostrar tudo {{numberOfWarningMessages}} )",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Mostrar menos )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Carregar arquivo Excel",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "O ficheiro selecionado é muito grande para carregar. O limite de arquivo para carregar ficheiros do Excel é de 10 MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Selecionar um Excel para importar. Verifique que a extensão do Excel é `.xlsx`. Os documentos `.xls 'não serão importados.",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "deixe os arquivos aqui",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "Não se permite deixar arquivos via HTML5!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Insira Detalhes",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Parâmetros",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Última atualização tipo",
  "DATA_SOURCES_SEARCH_LABEL": "Base de Dados",
  "DATA_SOURCES_ADD_BUTTON": "+ Adicionar base de dados",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Adicionar uma base de dados",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Editar base de dados",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Nome",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Dar um nome a base de dados",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Tipo",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Digite uma URL para recuperar a base de dados",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Parâmetros",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Adicionar Parâmetros",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "No há parâmetros",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Chave",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Valor",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "Pedir cabeçalhos",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Adicionar cabeçalho do pedido",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Chave",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Valor",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Atualizar Intervalo",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Autentificação Básica",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Iniciar sessão com Google",
  "AUTHENTICATED_WITH_GOOGLE": "Autentificado com Google",
  "CHANGE_ACCOUNT": "Trocar de conta",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Valor entre /d/ e /edit na URL do seu spreadsheet",
  "DATA_SOURCE_GRID_NO_ENTRIES": "Não há entradas para esta base de dados.",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Carregando entradas",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Importando entradas",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Última atualização falhou",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Última atualização: nunca",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Última atualização",
  "DATA_SOURCE_WARNINGS_TITLE": "Avisos de importação",
  "DATA_SOURCE_REMOVE_TITLE": "Eliminar Base de Dados",
  "DATA_SOURCE_REMOVE_QUESTION": "Você tem certeza de que deseja excluir este banco de dados? Esta ação não pode ser desfeita!",
  "DATA_SOURCE_ENABLE_TITLE": "Habilitar Base de Dados",
  "DATA_SOURCE_ENABLE_MESSAGE": "A base de dados foi desabilitada porque 10 ou mais importações consecutivas falharam. Certifique-se de ter uma configuração de url correta, se você habilitar a base de dados, tentaremos novamente",
  "CLIENTS": "Apps",
  "CLIENTS_TITLE": "Apps",
  "SETTINGS_TITLE": "Definições",
  "SETTINGS_TAB_GENERAL": "Geral",
  "SETTINGS_TAB_SSO": "Sign-on único",
  "SETTINGS_TAB_SENDING_DOMAIN": "Domínio e-mail",
  "BILLING_SUCCESS_MESSAGE": "Informações de cobrança dos clientes foram atualizadas com sucesso.",
  "BILLING_DETAILS_HEADER": "Cliente",
  "BILLING_INVOICES_HEADER": "Cliente",
  "GENERAL_HEADER": "Geral",
  "GENERAL_SUCCESS_MESSAGE": "As configurações do cliente foram atualizadas com sucesso.",
  "LOGO_HEADER": "Logo",
  "LOGO_SELECT": "Selecionar logo",
  "SETTINGS_DATE_FORMAT": "Formato da Data",
  "SETTINGS_CUSTOMER_NAME": "Nome da Empresa",
  "SETTINGS_TAB_THEMES": "Temas",
  "SETTINGS_TAB_THEMES_CUSTOM": "Temas Personalizados",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Adicionar Tema",
  "THEME_FORM": "Tema",
  "THEME_FORM_NAME_LABEL": "Nome",
  "THEME_FORM_BUTTON_LABEL": "Botão",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Botão de Salvar",
  "THEME_FORM_BAR_LABEL": "Barra",
  "THEME_FORM_BACKGROUND_LABEL": "Fundo",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Contas de Serviço",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "Nova API key",
  "API_KEY_CREATED": "Nova API key criada",
  "API_KEY_SAFETY": "Guarde de forma segura",
  "API_KEY_CREATED_MESSAGE": "Por favor guarde esta key num sítio seguro, como um gestor de senhas.<br><b>Não poderá voltar a ver a mesma.</b",
  "API_KEY_REMOVE_TITLE": "Apagar API key?",
  "API_KEY_REMOVE_MESSAGE": "Apagar esta key pode fazer com que integrações que a usem deixem de funcionar! Depois de apagada, não é possível recuperar.",
  "WEBHOOK_DETAILS": "Detalhes de webhook",
  "WEBHOOK_ATTEMPTS": "Tentativas de webhook",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Tentativas de enviar um evento para o seu terminal nos últimos 30 dias",
  "WEBHOOK_REQUEST_BODY": "Request body",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "A carga útil que enviamos para o seu terminal",
  "WEBHOOK_RESPONSE": "Resposta",
  "WEBHOOK_RESPONSE_MESSAGE": "A resposta que recebemos do seu terminal",
  "WEBHOOK_REMOVE_TITLE": "Apagar webhook?",
  "WEBHOOK_REMOVE_MESSAGE": "Tem a certeza de que quer apagar este webhook? Sabia que também pode <b>disable</b> a partir da visualização de detalhes?",
  "ADD_SUBSCRIBER": "Adicionar Subscritor",
  "EVENT_TYPES": "Tipos de Evento",
  "SELECT_EVENT_TYPES": "Selecione os tipos de evento a que pretende subscrever",
  "SECRET": "Segredo",
  "SERVICE_ACCOUNTS_NAME": "Nome",
  "SERVICE_ACCOUNTS_TYPE": "Tipo",
  "SERVICE_ACCOUNTS_ACTIONS": "Ações",
  "SERVICE_ACCOUNTS_RECONNECT": "Reconectar",
  "SERVICE_ACCOUNTS_DELETE": "Eliminar",
  "SERVICE_ACCOUNTS_ADD": "Adicionar Conta de Serviço",
  "SERVICE_ACCOUNTS_NON_FOUND": "Sem contas de serviço configuradas",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "Falha em carregar contas de serviço",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "Falha ao atualizar conta de serviço",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "Falha ao criar conta de serviço",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "Falha em reconectar conta de serviço",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "Falha ao eliminar conta de serviço",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Eliminar conta de serviço",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "Tem a certeza de que deseja excluir a conta de serviço <b>'{{{ displayName }}'</b>? <br><br> Formulários com integrações que utilizam esta conta de serviço deixarão de funcionar.",
  "GET_STARTED": "Comece",
  "MANAGE": "Gerir",
  "SSO_DSYNC": "Sincronização de diretório",
  "SSO": "Sign-on único",
  "SSO_DESCRIPTION": "Conecte o diretório da sua empresa e o método de logon para simplificar o gerenciamento de usuários",
  "SSO_SHARED_WARNING": "Essa configuração de logon único (SSO) é usada por vários clientes; qualquer alteração afetará a todos.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Use o logon único para fazer login com as credenciais que você usa para todos os outros aplicativos.</h4><p></p><br><p>Esse recurso está disponível a partir do <b>plano</b>Forest.</p>",
  "SSO_CONNECTION_ACTIVE": "Ativo",
  "SSO_CONNECTION_INACTIVE": "Inativo",
  "SSO_CONNECTION_DRAFT": "Rascunho",
  "SSO_CONNECTION_PENDING": "Pendente",
  "DSYNC_CONNECTION_LINKED": "Ligado",
  "DSYNC_CONNECTION_UNLINKED": "Desvinculado",
  "DSYNC_CONNECTION_PENDING": "Pendente",
  "SSO_STEP_1_TITLE": "Comece",
  "SSO_STEP_2_TITLE": "Configurar Sign-on único (SSO)",
  "SSO_STEP_2_DESCRIPTION": "Conecte-se ao seu provedor de identidade",
  "SSO_STEP_3_TITLE": "Configurar a sincronização do diretório",
  "SSO_STEP_3_DESCRIPTION": "Sincronize seus grupos e usuários para mantê-los atualizados",
  "SSO_STEP_4_TITLE": "SSO ativado",
  "SSO_STEP_4_DESCRIPTION": "Seus usuários agora podem usar o SSO para fazer login",
  "SSO_IMPORTED_USERS": "{{count}} usuários importados do seu diretório",
  "SSO_IMPORTED_GROUPS": "{{count}} grupos importados de seu diretório",
  "SSO_NO_IMPORTED_USERS_GROUPS": "Nenhum usuário ou grupo importado",
  "SENDING_DOMAIN_TITLE": "Configure seu domínio de correio eletrônico",
  "SENDING_DOMAIN_DESCRIPTION": "Torne os e-mails mais reconhecíveis e consistentes com a marca de sua empresa. Enviaremos e-mails (como relatórios em PDF) de <b>mycompany.com</b> em vez de moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "Esse domínio de e-mail é usado por vários clientes, portanto, qualquer alteração afetará a todos.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "Para mudar para um domínio diferente, basta excluí-lo e criar um novo",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Domínio",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Domínio",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "O domínio usado para enviar e-mails, como <a>mycompany.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Nome padrão de origem",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "Um nome ou título que seja reconhecível por seus destinatários. Na maioria dos casos, esse será o nome de sua empresa.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "E-mail padrão de origem",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "O e-mail de origem será usado como endereço de e-mail de origem. Esse também será o endereço de resposta.",
  "SENDING_DOMAIN_DELETE_TITLE": "Excluir domínio de e-mail personalizado?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "Isso significa que seus e-mails serão enviados novamente de nosso domínio padrão. Você não precisará mais dos registros DNS associados. Você pode removê-los do seu provedor de domínio.",
  "SENDING_DOMAIN_VERIFY_TITLE": "Adicionar registros DNS",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Adicione esses registros DNS ao seu provedor de domínio para verificar se você é o proprietário desse domínio. A verificação pode levar até 48 horas enquanto as alterações de registro são propagadas.",
  "SENDING_DOMAIN_VERIFY_BTN": "Verificar registros",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Domínio inválido '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "O e-mail de origem deve ser do '{{domain}}' domínio",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>Envie e-mails (como relatórios em PDF) do domínio de sua empresa</h4><p>Esse recurso está disponível a partir do <b>plano</b>Filial </p>",
  "SETTINGS_SUCCESS_MESSAGE": "Suas configurações foram salvas",
  "DYNAMIC_RECIPIENTS_ADD": "Adicionar destinatário",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Padrão",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "Campos de Email",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Outros campos",
  "TASKS_NEW": "Nova tarefa",
  "TASKS_PUBLISH_DATE": "Publicado",
  "TASKS_MESSAGE": "Mensagem",
  "TASKS_AND_X_OTHERS": "a {{others}} mais",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "Ocorreu uma falha durante o carregamento das tarefas. Pedimos desculpa pelo incómodo. Pode tentar atualizar a página.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "A pasta selecionada não está publicada. Para criar tarefas para esta pasta, tem de a publicar na secção de formulários.",
  "TASK_ADD_TITLE": "Nova tarefa",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Selecionar utilizador(es) e/ou grupo(s)",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} utilizador(es)",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "Não existem utilizadores neste grupo",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Utilizadores deste grupo:",
  "TASK_ADD_MESSAGE_LABEL": "Mensagem",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "Não existem utilizadores ou grupos com acesso a este formulário. Dê acesso aos seus utilizadores utilizando \"Gerir utilizadores\" na vista geral do formulário.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Enviar tarefa",
  "TASK_DETAIL_TITLE": "Tarefa",
  "TASK_DETAIL_MESSAGE": "Mensagem",
  "TASK_DETAIL_ACTIONS": "Acções",
  "TASK_DETAIL_ACTION_COMPLETE": "Completo",
  "TASK_DETAIL_ACTION_REVOKE": "Revogar",
  "TASKS_GRID_NO_ENTRIES": "Não existem tarefas para este formulário",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Imediatamente",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "Em 1 hora",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "Em 2 horas",
  "TASKS_ADD_PUBLISH_TOMORROW": "Amanhã",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Cumprido",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "Pendente",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Recusado",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Revogado",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Concluído",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Submissão inserida na linha: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Tarefas criadas para:",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "Não foi criada nenhuma tarefa porque não havia destinatários",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "O relatório foi enviado por e-mail:",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "O relatório foi enviado por e-mail:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO_SELECT_BUTTON": "Substituir o logótipo predefinido"
}
;