'use strict';

angular.module('MoreAppsServices').factory('$userService', ['moreConstants', '$resource', '$upload', '$q', function (moreConstants, $resource, $upload, $q) {

  var Users = $resource(moreConstants.apiEndpoint + '/users/:action', {}, {
    update: { method: 'PUT' },
    status: { method: 'GET', params: { action: 'status' } },
    updateInfo: { method: 'POST', params: { action: 'info' } },
    updateStatus: { method: 'POST', params: { action: 'status' } },
    current: { method: 'GET', params: { action: 'current' } }
  });

  var UserSettings = $resource(moreConstants.apiEndpoint + '/users/settings/:action', {}, {
    updateLanguage: { method: 'PUT', params: { action: 'language' } },
  });

  var CustomerUsers = $resource(moreConstants.apiEndpoint + '/customers/:customerId/users/:userId', { userId: '@id' }, {
    update: { method: 'PUT' }
  });

  return {
    getUser: () => Users.current(),
    getUserStatus: () => Users.status(),
    updateUserInfo: userInfo => Users.updateInfo(userInfo),
    updateLanguage: language => UserSettings.updateLanguage(language),
    updateUserStatus: userStatus => Users.updateStatus(userStatus),
    save: (customerId, user) => {
      if (user.id) {
        return CustomerUsers.update({ customerId: customerId }, user);
      }
      return CustomerUsers.save({ customerId: customerId }, user);
    },
    importUsers: (customerId, language, groups, file) => {
      var deferred = $q.defer();

      $upload.upload({
        url: moreConstants.apiEndpoint + '/customers/' + customerId + '/users/import',
        method: 'POST',
        fields: { language: language, groups: groups },
        file: file,
        fileFormDataName: 'file'
      }).success(deferred.resolve).error(deferred.reject);

      return deferred.promise;
    }
  };
}]);
