'use strict';

angular.module('customerPortal').controller('FolderSettingsCtrl', ['$state', '$scope', 'folder', '$customerService', '$customerFolderService', '$modalService', '$timeout', '$uibModal', function ($state, $scope, folder, $customerService, $customerFolderService, $modalService, $timeout, $uibModal) {

  var customerId = $scope.$state.params.customerId;

  $scope.folder = folder;
  $scope.customerId = customerId;
  $scope.hasActiveForms = hasActiveForms();

  $scope.updateName = updateName;
  $scope.updateIcon = updateIcon;
  $scope.updateImage = updateImage;
  $scope.updateStatus = updateStatus;
  $scope.selectIcon = selectIcon;
  $scope.clearIcon = clearIcon;
  $scope.deleteFolder = deleteFolder;

  ////////

  function selectIcon () {
    $uibModal.open({
      template : require('../../../../modules/IconPicker/templates/icon-picker.modal.html'),
      controller : 'IconPickerModalCtrl',
      controllerAs : 'ctrl'
    }).result.then(function (icon) {
      folder.meta.icon = icon;
      updateIcon();
    });
  }

  function clearIcon () {
    folder.meta.icon = null;
    updateIcon();
  }

  function deleteFolder () {
    $modalService.deleteModal({
      title : 'COLLECTION_REMOVE_MODAL_TITLE',
      template : require('../../../../shared/templates/folders/folder.remove.html'),
      data : {
        template : folder
      }
    }).then(function (result) {
      if (!result) {
        return;
      }

      $customerFolderService.deleteFolder(customerId, folder.id).$promise.then(function () {
        $scope.$close({action : 'removed', data : {folder : folder}});
      });
    });
  }

  function hasActiveForms () {
    return folder.forms.filter(form => form.status === 'ACTIVE' || form.status === 'HIDDEN').length > 0;
  }

  function updateName () {
    $customerFolderService.patch($state.params.customerId, $scope.folder.id, [{op: 'replace', path: '/meta/name', value: folder.meta.name}]);
  }

  function updateIcon () {
    $customerFolderService.patch($state.params.customerId, $scope.folder.id, [{op: 'replace', path: '/meta/icon', value: folder.meta.icon}]);
  }

  function updateImage () {
    $customerFolderService.patch($state.params.customerId, $scope.folder.id, [{op: 'replace', path: '/meta/image', value: folder.meta.image}]);
  }

  function updateStatus () {
    $customerFolderService.patch($state.params.customerId, $scope.folder.id, [{op: 'replace', path: '/status', value: folder.status}]);
  }

}]);
