'use strict';

angular.module('UserPicker').directive('userPickerGroup', ['$state', '$userStateService', '$rootScope', '$customerUserService', function ($state, $userStateService, $rootScope, $customerUserService) {

  return {
    restrict: 'E',
    template: require('../templates/user-picker-group.html'),
    scope: {
      group: '=',
      users: '=',
      selectGroupCallback: '=',
      focusGroupCallback: '=',
      selectUserCallback: '=',
      addUserCallback: '='
    },
    link: function (scope) {

      scope.activeUser = null;

      scope.selectUser = function (user) {
        scope.activeUser = user;
        $userStateService.setUserId(user.id);
        scope.selectUserCallback({ user: user, group: scope.group });
      };

      var setupGroupUsers = function () {
        return scope.users.$promise.then(function (users) {
          scope.group.$$groupUsers = [];
          scope.group.users.forEach(function (userId) {
            users.forEach(function (user) {
              if (userId === user.id) {
                scope.group.$$groupUsers.push(user);
              }
            });
          });
        });
      };

      var loadGroup = function (options) {
        options = options || {};
        if (options.force || !scope.group.$$groupUsers) {
          scope.loadingGroup = true;
          setupGroupUsers().then(function () {
            // activate form
            var userState = $userStateService.getState();
            scope.group.$$groupUsers.forEach(function (user) {
              if (user.id === userState.userId) {
                scope.activeUser = user;
                scope.selectUserCallback({ user: user, group: scope.group });
              }
            });
          });
        }

        if (options.removeDeletedUsers) {
          scope.loadingGroup = true;
          removeDeletedUsers($state.params.customerId);
        }

        scope.loadingGroup = false;
      };

      var removeDeletedUsers = function (customerId) {
        $customerUserService.getUsers(customerId).$promise.then(function (users) {
          var deletedUsers = [];

          scope.group.users.forEach(function (localUserId) {
            var deletedUser = true;

            users.forEach(function (user) {
              if (user.id === localUserId || scope.group.id === 'invites') {
                deletedUser = false;
              }
            });

            if (deletedUser) {
              deletedUsers.push({ id: localUserId });
            }
          });

          deletedUsers.forEach(function (user) {
            $rootScope.$broadcast('user.removed', user);
          });
        });
      };

      var removeUser = function (user) {
        if (scope.group.users.indexOf(user.id) === -1 && scope.group.id !== 'all_users_group') {
          return; // user not in this group
        }

        var indexOfUser = scope.users.map(function (user) {
          return user.id;
        }).indexOf(user.id);

        if (indexOfUser !== -1) {
          scope.users.splice(indexOfUser, 1);
        }
        scope.group.users.splice(scope.group.users.indexOf(user.id), 1);

        if ($userStateService.getState().userId === user.id) {
          $userStateService.setUserId(null);
        }
        loadGroup({ force: true });
      };

      scope.toggle = function () {
        scope.group.$$open = !scope.group.$$open;
        if (scope.group.$$open) {
          $userStateService.setGroupId(scope.group.id);
          scope.selectGroupCallback({ group: scope.group });
          loadGroup({ removeDeletedUsers: true });
        } else {
          $userStateService.setGroupId(null);
        }
      };

      if (scope.group.$$open) {
        loadGroup();
      }

      scope.$on('group.changed', function (event, groupId) {
        if (scope.group.$$open && scope.group.id !== groupId) {
          scope.group.$$open = false;
        }
      });

      scope.$on('user.removed', function (event, user) {
        removeUser(user);
      });

      scope.$on('user.addedToGroup', function (event, data) {
        if (scope.group.id !== data.groupId) {
          return; // message not meant for this group
        }

        if (data.updatePickerGroup) {
          scope.group.users.push(data.user.id);
        }
        loadGroup({ force: true });
      });

      scope.$on('user.removedFromGroup', function (event, data) {
        if (scope.group.id !== data.groupId) {
          return; // message not meant for this group
        }

        if (data.updatePickerGroup) {
          scope.group.users.splice(scope.group.users.indexOf(data.user.id), 1);
        }
        loadGroup({ force: true });
      });

      scope.$watch('activeUser', function (newVal) {
        $rootScope.pickerItemActive = !!newVal;
      });

      scope.$on('$destroy', function () {
        $rootScope.pickerItemActive = false;
      });

      scope.$on('removeDeletedUsers', function (event, customerId) {
        removeDeletedUsers(customerId);
      });
    }
  };
}]);
