'use strict';

angular.module('MoreAppsServices').factory('$adminRegionService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const Regions = $resource(moreConstants.apiEndpoint + '/admin/regions/:regionId', { regionId: '@regionId' }, {});
  const RegionTransferCustomer = $resource(moreConstants.apiEndpoint + '/admin/regions/:regionId/transfer/:customerId', { regionId: '@regionId', customerId: '@customerId' }, {
    transfer: {
      method: 'POST'
    }
  });

  return {
    getRegions: getRegions,
    transferCustomerToRegion: transferCustomerToRegion,
  };

  ////////
  function getRegions() {
    return Regions.query();
  }

  function transferCustomerToRegion(customerId, regionId) {
    return RegionTransferCustomer.transfer({customerId, regionId});
  }
}]);