'use strict';

angular.module('more.ui.form-builder').service('$uuidHelper', function () {

  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  return {
    generateGuid: function () {
      return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }
  };
});