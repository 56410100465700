'use strict';

angular.module('MoreDirectives').directive('oauthLogin', ['$state', '$q', '$http', '$window', '$interval', function ($state, $q, $http, $window, $interval) {
  return {
    restrict: 'E',
    template: require('../templates/oauth-login.html'),
    replace: true,
    scope: false,
    link: function (scope) {
      var OAUTH_POLL_INTERVAL = 1000; //Milliseconds
      var configuration = scope.config.configuration;
      var viewId = $state.params.viewId;
      var pollIntervalPromise = null;
      scope.authenticate = authenticate;

      init();

      /////////////
      function init() {
        if (scope.form[scope.config.key] === undefined) {
          scope.form[scope.config.key] = {
            $error: {}
          };
        }

        scope.model[scope.config.key] = null;
        checkOauthSession(configuration.oauth_success_poll_url, viewId);

        scope.$on('$destroy', function () {
          cancelSuccessPoll();
        });
      }

      function authenticate() {
        var loginUrl = configuration.oauth_login_url;
        loginUrl += '&state=' + viewId;
        var loginScreen = openOauthLoginScreen(loginUrl);

        if (loginScreen) {
          pollOauthLoginSuccess(configuration.oauth_success_poll_url, viewId, OAUTH_POLL_INTERVAL).then(function () {
            scope.model[scope.config.key] = true;
            loginScreen.close();
          });

          scope.$watch(function () {
            return loginScreen.closed;
          }, function (closed) {
            if (closed === true) {
              cancelSuccessPoll();
            }
          });
        }
      }

      function checkOauthSession(url, formId) {
        checkOauthLoginSuccess(url, formId).then(function () {
          scope.model[scope.config.key] = true;
        });
      }

      function openOauthLoginScreen(loginUrl) {
        var windowHeight = 500;
        var windowWidth = 900;
        var topPosition = ($window.outerHeight - windowHeight) / 2;
        var leftPosition = ($window.outerWidth - windowWidth) / 2;

        if (loginUrl !== null && loginUrl !== undefined) {
          return $window.open(loginUrl, 'Login', 'width=' + windowWidth + ',height=' + windowHeight + ',top=' + topPosition + ',left=' + leftPosition);
        }
      }

      function pollOauthLoginSuccess(url, formId, intervalDuration) {
        return $q(function (resolve) {
          pollIntervalPromise = $interval(function () {
            checkOauthLoginSuccess(url, formId).then(function () {
              cancelSuccessPoll();
              resolve();
            });
          }, intervalDuration);
        });
      }

      function checkOauthLoginSuccess(url, formId) {
        var callOptions = {
          method: 'GET',
          url: url + (url.indexOf('?') > -1 ? '&form_id=' + formId : '?form_id=' + formId)
        };

        return $q(function (resolve, reject) {
          $http(callOptions).then(function (success) {
            if (success.data.status === 'authenticated') {
              resolve();
              return;
            }
            reject();
          }, function () {
            setErrorOnField('The authentication service appears to be offline');
          });
        });
      }

      function cancelSuccessPoll() {
        $interval.cancel(pollIntervalPromise);
      }

      function setErrorOnField(errorMessage) {
        scope.form[scope.config.key].$error.custom = errorMessage;
      }
    }
  };
}]);