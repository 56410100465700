'use strict';

angular.module('FormManagement.submissions').controller('ExportSubmissionsModalCtrl', ['$rootScope', '$scope', '$state', '$modalService', '$submissionService', '$simpleFilterService', '$translate', 'formId', 'filter', 'idSelection', function ($rootScope, $scope, $state, $modalService, $submissionService, $simpleFilterService, $translate, formId, filter, idSelection) {
  const self = this;
  const allTimezones = ct.getAllTimezones();
  const timezoneFallback = allTimezones['Etc/UTC'];

  let allColumns = [];

  self.exporting = false;
  self.exportSuccess = false;
  self.customerId = $state.params.customerId;
  self.includeFiles = false;
  self.timezones = Object.values(allTimezones);
  self.timezone = allTimezones[Intl.DateTimeFormat().resolvedOptions().timeZone] || timezoneFallback;
  self.excelSingleSheet = false;
  self.useBetaExport = true;
  self.exportAllColumns = true;
  self.selectedColumns = [];
  self.scheduleExport = scheduleExport;
  self.toggleField = toggleField;
  self.bulkSelect = (value) => self.selectedColumns.forEach(f => toggleField(f, value, {enableNested: true}));

  init();

  ////////
  function init() {
    $submissionService.getExportFields(self.customerId, formId).then(res => {
      allColumns = res;
      self.selectedColumns = res;
      // enable default meta fields
      toggleField(self.selectedColumns.find(f => f.dataName === 'meta.serialNumber'), true);
      toggleField(self.selectedColumns.find(f => f.dataName === 'meta.userId'), true);
      toggleField(self.selectedColumns.find(f => f.dataName === 'meta.registrationDate'), true);
    });
  }

  function toggleField(field, newValue, options) {
    const {enableNested, parent} = options || {};
    field.$selected = newValue;

    // Select all subfields when toggling parent
    if (enableNested) {
      field.fields.forEach(f => toggleField(f, newValue));
    }

    // Select parent if one or more subfields are selected and deselect when none are
    if (parent) {
      parent.$selected = parent.fields.some(subfield => subfield.$selected);
    }
  }

  function scheduleExport() {
    const email = $scope.currentUser.consumerKey;
    const exportRequest = {
      exporterType: 'EXCEL',
      mailOnFinish: [email],
      submissionIds: idSelection,
      exportFields: getExportFields(),
      filterQueries: $simpleFilterService.gridFilterToSimpleFilter(filter).query,
      options: {
        timezone: self.timezone.name,
        dateTimeFormat: `${$rootScope.settings.dateFormat} HH:mm:ss`,
        includeFiles: self.includeFiles,
        excelSingleSheet: self.excelSingleSheet,
        languageCode: $translate.use().toUpperCase()
      }
    };

    $submissionService.scheduleExport(self.customerId, formId, exportRequest).then(
      () => {
        $scope.$close();
        $modalService.alertModal({
          title: 'EXPORT_SUCCESS_TITLE',
          message: $translate.instant('EXPORT_SUCCESS_MESSAGE', { email })
        });
      },
      (response) => {
        const { code, message, data } = response.data;
        const translatedMessage = code === 'service_unavailable' ?
            $translate.instant('EXPORT_SERVICE_UNAVAILABLE') :
            $translate.instant(code, data) || message;

        $modalService.errorModal(translatedMessage);
      }
    );
  }

  function getExportFields() {
    if (self.exportAllColumns) {
      return allColumns;
    }
    const getSelectedSubFields = (field) => {
      const {fields, ...rest} = field;
      rest.fields = fields.filter(f => f.$selected);
      return rest;
    };
    return self.selectedColumns
      .filter(c => c.$selected)
      .map(c => c.fields && c.fields.length ? getSelectedSubFields(c) : c);
  }
}]);
