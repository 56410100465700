'use strict';

angular.module('CustomerPortalSettings').controller('SettingsBillingCtrl', ['$state', '$customerBillingService', function ($state, $customerBillingService) {
  const self = this;

  $customerBillingService.getBilling($state.params.customerId).$promise.then((result) => {
    self.billing = result;
  });

  self.save = (billing) => {
    self.saving = true;
    $customerBillingService.updateBilling($state.params.customerId, billing).$promise.then(() => {
      self.saving = false;
      self.successMessage = 'BILLING_SUCCESS_MESSAGE';
    }, function (error) {
      self.saving = false;
      if (error.data.message) {
        self.errorMessage = error.data.message;
      } else {
        self.errorMessage = error;
      }
    });
  };
}]);
