'use strict';

angular.module('MoreAppsServices').factory('$adminAccessService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const AdminPartnerUsers = $resource(moreConstants.apiEndpoint + '/admin/access/users/:userId/partner', {userId: '@userId'}, {
    remove: {
      method: 'DELETE',
      isArray: true
    },
    enable: {
      method: 'POST',
      isArray: true
    }
  });
  const AdminRoles = $resource(moreConstants.apiEndpoint + '/admin/access/:customerId/roles', {customerId: '@customerId'}, {});
  const AdminGroups = $resource(moreConstants.apiEndpoint + '/admin/access/:customerId/groups', {customerId: '@customerId'}, {});
  const AdminOwner = $resource(moreConstants.apiEndpoint + '/admin/access/:customerId/owner', {customerId: '@customerId'}, {});

  return {
    removePartnerGrant: (userId) => AdminPartnerUsers.remove({userId}).$promise,
    addPartnerGrant: (userId) => AdminPartnerUsers.enable({userId}).$promise,
    getRoles: (customerId) => AdminRoles.query({customerId}).$promise,
    getGroups: (customerId) => AdminGroups.query({customerId}).$promise,
    getOwner: (customerId) => AdminOwner.get({customerId})
  };
}]);
