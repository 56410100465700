'use strict';

require('./bootstrap');
require('./controllers/AddSubFormCtrl');
require('./controllers/DeleteWidgetFavoriteCtrl');
require('./controllers/EmailTabCtrl');
require('./controllers/EmailNotificationCtrl');
require('./controllers/FormEditorIndexCtrl');
require('./controllers/RulePanelCtrl');
require('./controllers/RuleTabCtrl');
require('./controllers/SettingsTabCtrl');
require('./controllers/FormSearchSettingsCtrl');
require('./controllers/UrlSelectModalCtrl');
require('./controllers/FormEditorTopMenuCtrl');
require('./controllers/WidgetFavoriteCtrl');
require('./controllers/WidgetListCtrl');
require('./controllers/IntegrationTabCtrl');
require('./controllers/IntegrationSelectModalCtrl');

require('./directives/ActionPicker');
require('./directives/ConditionPicker');
require('./directives/DragItem');
require('./directives/DragSection');
require('./directives/ValidationErrors');
require('./directives/IntegrationPanel');
require('./directives/EmailList');

require('./toolbars/Showmap');
require('./toolbars/Placeholders');

require('./filters/FormView');
