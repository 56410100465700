'use strict';

angular.module('MoreDirectives').directive('integrationUserConfiguration', ['$state', '$customerServiceAccountsService', '$customerFolderService', '$moreResourceService', '$downloadUtil', '$oauthAuthorizeService', '$translate', function ($state, $customerServiceAccountsService, $customerFolderService, $moreResourceService, $downloadUtil, $oauthAuthorizeService, $translate) {

  var MB_SIZE_IN_KB = 1024;
  var propertyTemplates = {
    text: require('../../../shared/templates/builder/hooks/text.html'),
    textarea: require('../../../shared/templates/builder/hooks/textarea.html'),
    number: require('../../../shared/templates/builder/hooks/number.html'),
    boolean: require('../../../shared/templates/builder/hooks/checkbox.html'),
    lookup_value: require('../../../shared/templates/builder/hooks/lookup_value.html'),
    collection: require('../../../shared/templates/builder/hooks/folder.html'),
    form: require('../../../shared/templates/builder/hooks/form-version.html'),
    file: require('../../../shared/templates/builder/hooks/file.html'),
    oauth_authentication: require('../../../shared/templates/builder/hooks/oauth.html'),
    cloud_authentication: require('../../../shared/templates/builder/hooks/service_account.html'),
    field: require('../../../shared/templates/builder/hooks/field.html'),
    wysiwyg: require('../../../shared/templates/builder/hooks/wysiwyg.html'),
    password: require('../../../shared/templates/builder/hooks/password.html')
  };

  return {
    restrict: 'E',
    scope: {
      config: '=',
      model: '=',
      form: '=',
      formVersion: '=',
      validationErrors: '=',
      viewFields: '=',
      viewId: '='
    },
    template: '<div compile="getPropertyTemplate(config.type)"></div>',

    link: function (scope) {
      var customerId = $state.params.customerId;
      var configuration = scope.config.configuration;

      scope.getPropertyTemplate = function (configType) {
        return propertyTemplates[configType];
      };

      scope.uploadResourceFile = function ($files, $rejectedFiles) {
        // validate file type
        if ($files.length === 0) {
          if ($rejectedFiles.length > 0) {
            setErrorOnField('Invalid file type. Allowed: ' + configuration.allowedExtensions.join(', '));
          }
          return;
        }

        resetFileField();

        // validate file size
        var maxSize = configuration.maxSize;
        var fileTooLarge = false;
        if (maxSize) {
          $files.forEach(function (file) {
            if (file.size / MB_SIZE_IN_KB > maxSize) {
              var maxSizeString = maxSize >= MB_SIZE_IN_KB ? maxSize / MB_SIZE_IN_KB + 'MB' : maxSize + 'kB';
              setErrorOnField('File too large. Max size: ' + maxSizeString);
              fileTooLarge = true;
            }
          });
        }
        if (fileTooLarge) {
          return;
        }

        // save as customer resource
        var options = {
          name: $files[0].name,
          shared: false,
          importFormat: 'PNG'
        };
        $moreResourceService.saveFileResource(customerId, $files[0], options).then(function (moreResource) {
          scope.model[scope.config.key] = {
            resourceId: moreResource.id,
            filename: moreResource.name
          };
        }, function () {
          setErrorOnField('Could not upload file. Please try again.');
        });
      };

      scope.downloadResourceFile = function (resourceId) {
        $moreResourceService.asUrl(customerId, resourceId).then(function (url) {
          $downloadUtil.downloadFile(url, function (data) {
            window.saveAs(data, scope.model[scope.config.key].filename);
          });
        });
      };

      scope.initTextValue = function (value, defaultValue) {
        if (typeof value === 'string') {
          return value;
        }
        if (typeof value === 'number') {
          return '' + value;
        }
        return defaultValue;
      };

      scope.initBooleanValue = function (value, defaultValue) {
        if (typeof value === 'boolean') {
          return value;
        }
        if (typeof defaultValue === 'boolean') {
          return defaultValue;
        }
        return false;
      };

      scope.initNumberValue = function (value, defaultValue) {
        if (typeof value === 'number') {
          return value;
        }
        if (typeof value === 'string') {
          var numValue = parseInt(value);
          if (numValue && numValue < Number.MAX_SAFE_INTEGER) {
            return numValue;
          }
        }
        return defaultValue;
      };

      scope.initLookupValue = function (value) {
        if (typeof value === 'object') {
          if (configuration.multiple) {
            return value;
          }
          return value[0];
        }
        if (typeof value === 'string') {
          if (!configuration.multiple) {
            return value;
          }
          return [value];
        }
        return scope.config.defaultValue;
      };

      scope.getAllowedExtensions = function () {
        if (!configuration || !configuration.allowedExtensions) {
          return '*';
        }
        // Example output: *.pdf,*.docx
        return '*.' + configuration.allowedExtensions.join(',*.');
      };

      scope.getSelectableFolders = function () {
        $customerFolderService.getFolders(customerId).$promise.then(function (folders) {
          folders = folders.filter(function (folder) {
            return folder.status === 'ACTIVE' || folder.status === 'HIDDEN';
          });
          configuration.selectableFolders = folders;
        });
      };

      scope.initFormPicker = function (key) {
        var folderKey = configuration && configuration.filterByCollection;
        if (folderKey) {
          getSelectableForms(scope.model[folderKey]);
          scope.$watch('model.' + folderKey, function (newVal, oldval) {
            if (newVal !== oldval) {
              scope.model[key] = null;
              getSelectableForms(newVal);
            }
          });
        } else {
          getSelectableForms($state.params.folderId);
        }
      };

      function getSelectableForms(folderId) {
        if (!folderId) {
          configuration.selectableForms = [];
          return;
        }

        $customerFolderService.getFolder(customerId, folderId).$promise.then(folder => {
          var forms = folder.forms.filter(form => form.status === 'ACTIVE' || form.status === 'HIDDEN');
          if (configuration && configuration.hideUnpublished) {
            forms = forms.filter(form => form.publishedVersion && form.publishedVersion.formVersion);
          }
          configuration.selectableForms = forms;
        });
      }

      scope.getSelectableAccounts = function () {
        return $customerServiceAccountsService.getServiceAccounts(customerId).$promise.then(function (serviceAccounts) {
          if (configuration && configuration.provider) {
            serviceAccounts = serviceAccounts.filter(function (serviceAccount) {
              return serviceAccount.authType === configuration.provider;
            });
          }
          configuration.selectableServiceAccounts = serviceAccounts;

          return serviceAccounts;
        }, function(error) {
          setErrorOnField($translate.instant('SERVICE_ACCOUNTS_LOAD_FAILED'));
        });
      };

      scope.getNewAccountLink = function (key) {
        $oauthAuthorizeService.createServiceAccount(customerId, configuration.provider, function (data) {
          scope.getSelectableAccounts().then(function(serviceAccounts) {
            scope.model[key] = serviceAccounts.find(serviceAccount => serviceAccount.id === data.accountId).id;
          });
        });
      };

      init();

      ////////
      function init() {
        if (configuration) {
          scope.validationOptions = {
            minLength: configuration.min,
            maxLength: configuration.max,
            min: configuration.min,
            max: configuration.max,
            pattern: configuration.pattern && configuration.pattern
          };
        }
      }

      function resetFileField() {
        if (scope.form[scope.config.key].$error) {
          scope.form[scope.config.key].$error = {};
        }
        delete scope.model[scope.config.key];
      }

      function setErrorOnField(errorMessage) {
        scope.form[scope.config.key].$error.custom = errorMessage;
      }
    }
  };
}]);
