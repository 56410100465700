'use strict';

angular.module('Clients').controller('ClientsIndexCtrl', ['$scope', 'moreConstants', function ($scope, moreConstants) {
  var self = this;

  self.menuItems = [{
    name: 'CLIENTS',
    state: 'content'
  }];

  init();

  ////////
  function init() {
    $scope.features.$promise.then(function (features) {
      self.moreIosDownloadLink = features.urls.ios;
      self.moreAndroidDownloadLink = features.urls.android;
      if (features.urls.webclient === 'https://app.moreapp.com') {
        self.webclientUrl = moreConstants.webClientUrl; // override with localhost/develop/staging links
      } else {
        self.webclientUrl = features.urls.webclient;
      }
    });
  }
}]);
