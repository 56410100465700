'use strict';

angular.module('FormManagement').controller('FormManagementOverviewCtrl', ['$scope', '$state', '$translate', '$modalService', '$rootScope', '$customerService', '$customerFormService', '$customerFolderService', '$usersContextService', '$formVersionUtils', '$timeout', '$uibModal', '$featureHelper', '$customerFormTemplateService', '$customerAuthenticationTokenService', '$languageUtil', 'moreConstants', function ($scope, $state, $translate, $modalService, $rootScope, $customerService, $customerFormService, $customerFolderService, $usersContextService, $formVersionUtils, $timeout, $uibModal, $featureHelper, $customerFormTemplateService, $customerAuthenticationTokenService, $languageUtil, moreConstants) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.hasCustomTemplatesFeature = false;
  self.loading = true;
  self.edit = $scope.edit;
  self.showPermissions = $scope.showPermissions;
  self.showHistory = $scope.showHistory;
  self.deleteForm = deleteForm;
  self.moveForm = moveForm;
  self.copyForm = copyForm;
  self.saveAsTemplate = saveAsTemplate;
  self.canManageForms = $usersContextService.canManageForms();
  self.canManageTemplates = $usersContextService.canManageTemplates();
  self.canManageUsers = $usersContextService.canManageUsers();

  init();

  ////////
  function init () {
    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', function () {
      $timeout(() => $scope.$destroy(), 0);
    });

    $featureHelper.hasFeature(moreConstants.CUSTOM_TEMPLATES).then(hasFeature => {
      self.hasCustomTemplatesFeature = hasFeature;
    });
  }

  // The following methods apply to the complete Collection, but are shown in all the Form overview pages. This might be moved later
  function deleteForm(form) {
    $modalService.deleteModal({
      title: 'FORM_REMOVE_MODAL_TITLE',
      template: require('../templates/formmanagement.remove.html'),
      data: {
        formName: form.meta.name
      }
    }).then(function () {
      $customerAuthenticationTokenService.revokeAuthenticationTokens(customerId, form.id);
      $customerFormService.trashForm(customerId, form.id).$promise.then(function (trashedForm) {
        $rootScope.$broadcast('form.removed', trashedForm);
        $state.go('portal.customers.form.overview', {}, {reload: true});
      });
    });
  }

  function copyForm(formVersion) {
    $uibModal.open({
      template : require('../templates/formmanagement.copy.html'),
      controller : 'FormManagementCopyCtrl',
      controllerAs : 'ctrl',
      resolve : {
        customers : () => $customerService.getCustomers().$promise,
        result : {
          customerId: customerId,
          formName : $scope.form.meta.name + ' ' + $translate.instant('COPY'),
        },
      }
    }).result.then(function (result) {
      $customerFormService.copyFormVersion(customerId, formVersion.formId, formVersion.id, result.folderId, result.formName, result.customerId).$promise.then(function (copy) {
        // Only navigate to form editor if same customer
        if (customerId === result.customerId) {
          $rootScope.$broadcast('form.added', {form : copy.form, folder : copy.folder, formVersion : copy.formVersion});
        }
      });
    });
  }

  function moveForm(form) {
    $modalService.confirmModal({
      title: 'FORM_MOVE_MODAL_TITLE',
      confirmButtonTitle: 'FORM_MOVE_MODAL_TITLE',
      template: require('../templates/formmanagement.move.html'),
      data: {
        result: {
          folderId: null
        },
        form: form,
        folders: $customerFolderService.getFolders(customerId)
      }
    }).then(function (result) {
      $customerFolderService.moveForm(customerId, result.folderId, form.id).$promise.then(() => {
        $state.go('portal.customers.form.overview', {}, {reload: true});
      });
    });
  }

  function saveAsTemplate(form) {
    if (!self.hasCustomTemplatesFeature) {
      return $modalService.upgradePlanModal({
        title: 'FEATURE_CUSTOM_TEMPLATES',
        message: 'FEATURE_CUSTOM_TEMPLATES_MESSAGE',
        imgSrc: 'https://cdn.moreapp.com/features/en/feature-custom-templates.png'
      });
    }
    $modalService.confirmModal({
      title: 'FORM_SAVE_AS_TEMPLATE',
      message: 'FORM_SAVE_AS_TEMPLATE_MESSAGE'
    }).then(() => {
      let template = angular.copy(form);
      delete template.id;
      delete template.meta.tags; // because it contains form name
      template.meta.language = $languageUtil.getActiveLanguageISO();
      $customerFormTemplateService.createForm(customerId, template).$promise.then((createdTemplate) => {
        let formVersionTemplate = angular.copy(getSelectedFormVersion());
        delete formVersionTemplate.id;
        formVersionTemplate.formId = createdTemplate.id;
        $customerFormTemplateService.createFormVersion(customerId, createdTemplate.id, formVersionTemplate).$promise
          .then(() => $state.go('portal.customers.templates', {}));
      });
    });
  }

  function getSelectedFormVersion() {
    return $scope.formVersions.find((version) => version.id === $scope.form.publishedVersion.formVersion);
  }
}]);
