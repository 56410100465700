'use strict';

angular.module('Billing').controller('BillingOverviewCtrl', ['moreConstants', '$state', '$billingService', '$window', '$q', '$translate', '$uibModal', '$customerService', '$customerBillingService', '$modalService', '$rootScope', '$usersContextService', function (moreConstants, $state, $billingService, $window, $q, $translate, $uibModal, $customerService, $customerBillingService, $modalService, $rootScope, $usersContextService) {

  const self = this;
  const customerId = $state.params.customerId;
  const isTrialing = () => self.activeSubscription ? self.activeSubscription.trialActive : false;

  self.ccBrandImages = {
    visa: 'https://js.stripe.com/v3/fingerprinted/img/visa-d6c6e0a636f7373e06d5fb896ad49475.svg',
    mastercard: 'https://js.stripe.com/v3/fingerprinted/img/mastercard-a96ee3841a5e1e28d05ed3f0f4da62b8.svg',
    amex: 'https://js.stripe.com/v3/fingerprinted/img/amex-edf6011de255d8a4c22904795c9d8770.svg',
    discover: 'https://js.stripe.com/v3/fingerprinted/img/discover-8f3d8fc6ef836da1fcac12c095ee6fb8.svg',
    diners: 'https://js.stripe.com/v3/fingerprinted/img/diners-fced9e136fd8c25f40a3e7b37a51dc1d.svg',
    jcb: 'https://js.stripe.com/v3/fingerprinted/img/jcb-1b12d588a1e9465d4d9fb84a610f9136.svg'
  };

  self.trialLimit = moreConstants.TRIAL_SUBMISSION_LIMIT;
  self.activeSubscription = undefined;
  self.isLoading = {
    subscription: true,
    paymentMethods: true
  };

  self.changePlan = () => $billingService.openBillingFlow(isTrialing()).then(init);
  self.cancelPlan = cancelPlan;
  self.uncancelPlan = uncancelPlan;
  self.showSubscriptionChange = showSubscriptionChange;
  self.getFeatures = (plan) => Array.isArray(plan.features) ? plan.features.map(p => $translate.instant(p)).join(', ') : '';
  self.getPlanSubmissions = () => self.activeSubscription.items.filter(x => x.type === 'submission')[0].tiers[0].upTo;
  self.getPlanTrees = () => self.activeSubscription.trees;
  self.hasUnlimitedUsers = () => self.activeSubscription.features.indexOf('UNLIMITED_USERS') !== -1;
  self.openTreesLink = openTreesLink;
  self.editBilling = editBilling;
  self.addPaymentMethod = addPaymentMethod;
  self.makePaymentMethodDefault = makePaymentMethodDefault;
  self.removePaymentMethod = removePaymentMethod;
  self.hasScheduledChange = hasScheduledChange;
  self.hasManageAccountPermission = $usersContextService.canManageAccount();
  self.deleteCustomer = deleteCustomer;

  function showOpenInvoicesModal() {
    $modalService.open({
      template: require('../templates/billing.termination.open.invoice.modal.html'),
      controller: ['$scope', ($scope) => {
        $scope.gotoInvoices = () => {
          $scope.$close();
          $rootScope.$broadcast('top-menu.activate-item', {name: 'BILLING_INVOICES_MENU_ITEM'});
          $state.transitionTo('portal.customers.billing.invoices', {customerId: customerId});
        };
      }],
      controllerAs: 'ctrl'
    });
  }

  function showFeedbackModal(translations, onSubmit) {
    $modalService.open({
      template: require('../templates/billing.termination.feedback.modal.html'),
      controller: ['$scope', ($scope) => {
        $scope.reasonLabel = translations.reasonLabel;
        $scope.primaryActionLabel = translations.primaryActionLabel;

        $scope.terminationReasons = {};
        $scope.terminationMessage = '';

        $scope.submit = () => {
          const terminationReasons = Object.entries($scope.terminationReasons).reduce((acc, [key, checked]) => {
            if (checked) {
              acc.push(key);
            }
            return acc;
          }, []);
          const terminationFeedback = {
            terminationReasons,
            message: $scope.terminationMessage
          };

          if (terminationFeedback.terminationReasons.length === 0) {
            $scope.errorMessage = 'TERMINATION_FEEDBACK_MODAL_REASON_ERROR';
            return;
          }

          if (terminationFeedback.message.length === 0) {
            $scope.errorMessage = 'TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR';
            return;
          }

          $scope.$close();
          onSubmit(terminationFeedback);
        };
      }],
      controllerAs: 'ctrl',
      size: 'lg',
      windowClass: 'modal-fullscreen'
    });
  }

  function deleteCustomer() {
    self.loadingSubscriptionStatus = true;

    $billingService.hasOpenInvoices(customerId).then(hasOpenInvoices => {
      if (hasOpenInvoices) {
        showOpenInvoicesModal();
      } else {
        showFeedbackModal({
          reasonLabel: 'TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT',
          primaryActionLabel: 'TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT'
        }, (terminationFeedback) => {
          $modalService.open({
            template: require('../templates/billing.deletion.confirmation.modal.html'),
            controller: ['$scope', ($scope) => {
              $scope.submitting = false;
              $scope.customerId = $rootScope.customer.customerId;
              $scope.customerName = $rootScope.customer.name;
              $scope.customerNameConfirmation = '';

              $scope.submit = () => {
                if ($scope.customerNameConfirmation !== $scope.customerName) {
                  $scope.errorMessage = 'DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR';
                  return;
                }

                $scope.submitting = true;
                $customerService.scheduleForDelete(customerId, terminationFeedback).$promise.then(() => {
                  location.reload();
                }).catch(() => {
                  $scope.errorMessage = 'DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR';
                  $scope.submitting = false;
                });
              };
            }],
            controllerAs: 'ctrl'
          });
        });
      }
    }).finally(() => {
      self.loadingSubscriptionStatus = false;
    });
  }

  init();

  ////////
  function init() {
    _loadBilling();
    _loadSubscription();
    _loadUsage();
    _loadUpcomingInvoice();
    _loadPaymentMethods();
    _loadTreesPlanted();
  }

  function showSubscriptionChange() {
    $uibModal.open({
      template: require('../templates/billing.subscription.schedule.modal.html'),
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      controller: 'BillingSubscriptionScheduleCtrl as ctrl',
      resolve: {
        subscription: self.activeSubscription,
        upcomingInvoice: self.upcomingInvoice
      }
    }).result.then(init);
  }

  function editBilling() {
    return $uibModal.open({
      template: require('../templates/billing.info.edit.modal.html'),
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      controller: ['$scope', function ($scope) {
        $scope.billing = angular.copy(self.billing);
      }],
    }).result.then(_loadBilling);
  }

  function addPaymentMethod() {
    const showPaymentModal = () => $uibModal.open({
      template: require('../templates/billing.method.modal.html'),
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      controller: ['$scope', function ($scope) {
        $scope.billing = angular.copy(self.billing);
        $scope.allowPayOnFile = self.activeSubscription ? self.activeSubscription.allowPayOnFile : false;
      }]
    }).result.then(_loadPaymentMethods);

    if (!self.billing.billingAddress || !self.billing.billingAddress.address) {
      editBilling().then(showPaymentModal);
    } else {
      showPaymentModal();
    }
  }

  function makePaymentMethodDefault(paymentMethod) {
    self.isLoading.paymentMethods = true;
    $billingService.setDefaultPaymentMethod(customerId, paymentMethod.id).$promise.then(_loadPaymentMethods, () => {
      self.isLoading.paymentMethods = false;
      $modalService.errorModal();
    });
  }

  function removePaymentMethod(paymentMethod) {
    $modalService.deleteModal({
      title: 'PAYMENT_METHOD_REMOVE_TITLE',
      message: 'PAYMENT_METHOD_REMOVE_MESSAGE'
    }).then(confirmed => {
      if (!confirmed) {
        return;
      }
      self.isLoading.paymentMethods = true;
      $billingService.removePaymentMethod(customerId, paymentMethod.id).$promise.then(_loadPaymentMethods, () => {
        self.isLoading.paymentMethods = false;
        $modalService.errorModal();
      });
    });
  }

  function openTreesLink() {
    const website = 'https://moreapp.com';
    const activeLanguage = $translate.use();
    const links = {
      en: `${website}/en/planting-trees/`,
      nl: `${website}/nl/bomen-planten/`,
      de: `${website}/de/baume-pflanzen/`,
      es: `${website}/es/planta-arboles/`,
      pt: `${website}/pt-PT/plantar-arvores/`,
      fr: `${website}/fr/plantation-arbres/`,
      it: `${website}/it/piantare-alberi/`
    };
    $window.open(links[activeLanguage] || links.en);
  }

  function hasScheduledChange() {
    if (self.activeSubscription.scheduled && self.activeSubscription.scheduled.isPricingChange) {
      const currentBasePlan = self.activeSubscription.scheduled.current.find(i => i.type === 'BASE');
      const futureBasePlan = self.activeSubscription.scheduled.future.find(i => i.type === 'BASE');

      return currentBasePlan.name !== futureBasePlan.name || currentBasePlan.interval !== futureBasePlan.interval;
    } else {
      return !!self.activeSubscription.scheduled;
    }
  }

  function cancelPlan() {
    self.cancelling = true;
    $billingService.hasOpenInvoices(customerId).then(hasOpenInvoices => {
      self.cancelling = false;
      if (self.activeSubscription.cancelAtPeriodEnd) {
        $modalService.alertModal({
          title: 'CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE',
          message: 'CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE'
        });
        return;
      }
      if (hasOpenInvoices) {
        showOpenInvoicesModal();
      } else {
        $modalService.confirmModal({
          title: 'CANCEL_PLAN_MODAL_TITLE',
          message: 'CANCEL_PLAN_MODAL_MESSAGE'
        }).then(() => {
          showFeedbackModal({
            reasonLabel: 'TERMINATION_FEEDBACK_MODAL_REASON_LABEL_CANCEL_PLAN',
            primaryActionLabel: 'TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN'
          }, (terminationFeedback) => {
            self.cancelling = true;
            $billingService.cancelSubscription(customerId, self.activeSubscription.subscription, terminationFeedback).then(
                () => location.reload(),
                (err) => $modalService.errorModal(err.data && err.data.message)
            ).finally(() => {
              self.cancelling = false;
            });
          });
        });
      }
    });
  }

  function uncancelPlan() {
    $modalService.confirmModal({
      title: 'UNCANCEL_PLAN_MODAL_TITLE',
      message: 'UNCANCEL_PLAN_MODAL_MESSAGE'
    }).then(() => {
      $billingService.uncancelSubscription(customerId, self.activeSubscription.subscription).$promise.then(
          () => init(),
          (err) => $modalService.errorModal(err.data && err.data.message));
    });
  }

  function _loadSubscription() {
    $billingService.getActiveSubscriptions(customerId).$promise.then(subscriptions => {
      if (subscriptions.length === 0) {
        return;
      }
      self.activeSubscription = subscriptions.filter(x => x.type === 'BASE')[0];

      const now = new Date();
      const trialEnd = new Date(self.activeSubscription.trialEnd * 1000);

      self.activeSubscription.trialActive = trialEnd > now;
      self.activeSubscription.trialDaysRemaining = Math.floor(self.activeSubscription.trialActive ? (trialEnd - now) / (1000 * 3600 * 24) : 0);
    }).finally(() => {
      self.isLoading.subscription = false;
    });
  }

  function _loadUsage() {
    self.usage = {};
    $billingService.getUsage(customerId).$promise.then(x => {
      self.usage.plan = x.plans.filter(p => p.type === 'BASE')[0];
      self.usage.submissions = x.plans.filter(p => p.type === 'FORMS')[0];
      if (!self.usage.plan) {
        return;
      }
      const cycle = self.usage.plan.cycle;
      self.usage.cycle = {
        days: Math.round((cycle.end - cycle.start) / 24 / 60 / 60),
        remaining: Math.round((cycle.end - (new Date().getTime() / 1000)) / 24 / 60 / 60)
      };
    });
  }

  function _loadUpcomingInvoice() {
    self.upcomingInvoice = $billingService.getUpcomingInvoice(customerId);
  }

  function _loadBilling() {
    self.billing = $customerBillingService.getBilling(customerId);
  }

  function _loadPaymentMethods() {
    self.isLoading.paymentMethods = true;
    self.paymentMethods = $billingService.getPaymentMethods(customerId);
    self.paymentMethods.$promise.then(() => {
      self.isLoading.paymentMethods = false;
    });
  }

  function _loadTreesPlanted() {
    self.treesOverview = $billingService.getTreesOverview(customerId);
  }
}]);
