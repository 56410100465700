'use strict';

angular.module('MoreResource').factory('$moreResourceModalState', function () {
  return {
    data: {},
    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
      $uibModal.open({
        template: require('../templates/modal.html'),
        controller: 'MoreResourceModalCtrl'
      }).result.then(function (moreResource) {
        var callback = $state.current.data.resourceCallback;
        $state.go('^').then(function () {
          if (callback) {
            callback(moreResource);
          }
        });
      }, function () {
        $state.go('^');
      });
    }]
  };
});