'use strict';

angular.module('customerPortal').controller('CustomerInfoModalCtrl', ['$scope', '$translate', '$userService', 'customer', '$customerService', function ($scope, $translate, $userService, customer, $customerService) {

  var self = this;
  var customerId = parseInt($scope.$state.params.customerId);

  self.addCustomerInfo = addCustomerInfo;

  var init = function () {
    self.language = $translate.use();
    self.customer = customer;
    $userService.getUser().$promise.then(function (user) {
      self.user = user;
    });
  };

  init();

  ////////
  function addCustomerInfo() {
    $userService.updateUserInfo(self.user.settings).$promise.then(function () {
      return $customerService.updateGeneralCustomerSettings(customerId, self.customer.settings);
    }).then(function () {
      return $customerService.updateName(customerId, self.customer.name);
    }).then(function () {
      $scope.$close();
    });
  }
}]);