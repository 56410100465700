'use strict';

angular.module('MoreAppsServices').factory('$customerWebhookService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const Subscribers = $resource(moreConstants.apiEndpoint + '/webhooks/customer/:customerId/subscribers/:id', {}, {
    update: {
      method: 'PUT'
    }
  });
  const Events = $resource(moreConstants.apiEndpoint + '/webhooks/customer/:customerId/events/:id', {});
  const Invocations = $resource(moreConstants.apiEndpoint + '/webhooks/customer/:customerId/subscribers/:subscriberId/invocations', {});

  return {
    getSubscribers: customerId => Subscribers.query({customerId}),
    createSubscriber: (customerId, subscriber) => Subscribers.save({customerId}, subscriber),
    deleteSubscriber: (customerId, id) => Subscribers.delete({customerId, id}),
    updateSubscriber: (customerId, id, subscriber) => Subscribers.update({customerId, id}, subscriber),
    getInvocations: (customerId, subscriberId, page) => Invocations.query({customerId, subscriberId}, {page}),
    getEvent: (customerId, id) => Events.get({customerId, id}),
    getEventTypes: () => [
      {category: 'submission', value: 'submission.created'},
      {category: 'submission', value: 'submission.deleted'},
      {category: 'pipeline', value: 'submission.pipeline.created'},
      {category: 'pipeline', value: 'submission.pipeline.blocked'},
      {category: 'pipeline', value: 'submission.pipeline.failed'},
      {category: 'pipeline', value: 'submission.pipeline.succeeded'},
      {category: 'mail', value: 'submission.pipeline.mail.sent'},
      {category: 'task', value: 'submission.task.created'},
      {category: 'task', value: 'submission.task.fulfilled'},
      {category: 'export', value: 'submission.export.scheduled'},
      {category: 'export', value: 'submission.export.started'},
      {category: 'export', value: 'submission.export.finished'},
      {category: 'datasource', value: 'datasource.import.failed'},
    ],
  };
}]);
