'use strict';

angular.module('more.ui.form-builder').factory('$fileHelper', ['$q', function ($q) {

  return {
    resourceToRegistrationFile: function () {
      var deferred = $q.defer();
      deferred.reject();
      return deferred.promise;
    },
    getFullRegistrationFilePath: function () {},
    storeValue: function () {},
    downloadRegistrationFile: function () {
      return $q.reject();
    }
  };
}]);