'use strict';

angular.module('MoreDirectives').directive('moreLanguagePicker', ['$translate', '$userService', function ($translate, $userService) {
  return {
    restrict: 'A',
    link: function (scope) {

      scope.languages = buildLanguages();
      scope.activeLanguage = scope.languages[getActiveLanguage()];
      scope.switchLanguage = switchLanguage;
      scope.getActiveLanguage = getActiveLanguage;

      function switchLanguage(language) {
        scope.activeLanguage = scope.languages[language];
        $translate.use(language);

        $userService.updateLanguage({ language });
      }

      function getActiveLanguage() {
        return $translate.use();
      }

      function buildLanguages() {
        return {
          en: {
            displayName: $translate.instant('LANGUAGE_NATIVE_EN'),
            displayNameShort: 'EN'
          },
          es: {
            displayName: $translate.instant('LANGUAGE_NATIVE_ES'),
            displayNameShort: 'ES'
          },
          de: {
            displayName: $translate.instant('LANGUAGE_NATIVE_DE'),
            displayNameShort: 'DE'
          },
          nl: {
            displayName: $translate.instant('LANGUAGE_NATIVE_NL'),
            displayNameShort: 'NL'
          },
          pt: {
            displayName: $translate.instant('LANGUAGE_NATIVE_PT'),
            displayNameShort: 'PT'
          },
          fr: {
            displayName: $translate.instant('LANGUAGE_NATIVE_FR'),
            displayNameShort: 'FR'
          },
          ca: {
            displayName: $translate.instant('LANGUAGE_NATIVE_CA'),
            displayNameShort: 'CA'
          }
        };
      }
    }
  };
}]);
