'use strict';

angular.module('MoreAppsServices').factory('$customerService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const Customers = $resource(moreConstants.apiEndpoint + '/customers/:customerId/:action', {customerId: '@customerId'}, {});
  const CustomerSettings = $resource(moreConstants.apiEndpoint + '/customers/:customerId/settings', {customerId: '@customerId'}, {
    update: {method: 'PUT'}
  });
  const CustomerLogo = $resource(moreConstants.apiEndpoint + '/customers/:customerId/logo/:resourceId', {
    customerId: '@customerId',
    resourceId: '@resourceId'
  }, {
    update: {method: 'PUT'}
  });
  const CustomerName = $resource(moreConstants.apiEndpoint + '/customers/:customerId/name', {customerId: '@customerId'}, {
    update: {method: 'PUT'}
  });
  const CustomerTrust = $resource(moreConstants.apiEndpoint + '/customers/:customerId/trust', {customerId: '@customerId'}, {});
  const CustomerDelete = $resource(moreConstants.apiEndpoint + '/customers/:customerId/schedule-delete', {customerId: '@customerId'}, {
    schedule: {method: 'POST'}
  });

  return {
    getCustomers: (filter) => Customers.query({filter}),
    getCustomer: customerId => Customers.get({customerId}),
    getTrust: customerId => CustomerTrust.get({customerId}),
    scheduleForDelete: (customerId, terminationFeedback) => CustomerDelete.schedule({customerId}, terminationFeedback),
    updateGeneralCustomerSettings: (customerId, settings) => CustomerSettings.update({customerId}, settings),
    updateLogo: (customerId, resourceId) => CustomerLogo.update({customerId, resourceId}),
    updateName: (customerId, customerName) => CustomerName.update({customerId}, customerName)
  };
}]);
