'use strict';

angular.module('FormManagement.submissions').controller('FormManagementPipelineDetailCtrl', ['$rootScope', '$scope', 'pipeline', 'customerId', 'registrationId', 'formId', '$pipelineService', '$interval', '$customerRegistrationFileService', '$downloadUtil', function ($rootScope, $scope, pipeline, customerId, registrationId, formId, $pipelineService, $interval, $customerRegistrationFileService, $downloadUtil) {
  const UPDATE_INTERVAL = 2000;
  const UPDATE_NEXT_EXECUTION_INTERVAL = 999;
  const RETRYABLE_PIPELINE_STATUS = ['BLOCKED', 'RETRY'];
  let updateInterval, retryTimeInterval;

  const self = this;
  self.pipeline = pipeline;
  self.activeStep = undefined;
  self.showStepActions = false;
  self.retrying = false;
  self.nextExecutionTime = undefined;

  self.retry = retry;
  self.getStepColor = getStepColor;
  self.getFileIcon = getFileIcon;
  self.downloadFile = downloadFile;
  self.isStepSkippable = isStepSkippable;
  self.skip = skip;

  init();

  //////////

  function init() {
    _renderPipeline();
    if (self.pipeline.status !== 'SUCCESS') {
      _startPolling();
    }
    $scope.$on('$destroy', _killIntervals);
  }

  function retry () {
    self.showStepActions = false;
    $pipelineService.retryPipeline(customerId, formId, registrationId).$promise.then(() => {
      $rootScope.$broadcast('hook.pipeline.retry', registrationId);
    });
  }

  function skip () {
    self.showStepActions = false;
    $pipelineService.skipPipeline(customerId, formId, registrationId).$promise.then(() => {
      $rootScope.$broadcast('hook.pipeline.retry', registrationId);
    });
  }

  function getStepColor (step) {
    switch (step.status) {
      case 'SUCCESS':
      case 'OPEN':
        return '';
      case 'ERROR':
      case 'STALLED':
        return 'danger';
      case 'SKIPPED':
        return 'info';
    }
  }

  function getFileIcon(mediaType) {
      switch (mediaType) {
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'fa-file-word-o';
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              return 'fa-file-excel-o';
          case 'application/pdf':
              return 'fa-file-pdf-o';
          default:
              return 'fa-file-o';
      }
  }

  function downloadFile(fileId) {
    $customerRegistrationFileService.getSecureUri(customerId, fileId).then((secureUri) => {
      $downloadUtil.downloadFileInNewWindow(secureUri);
    });
  }

  function isStepSkippable (step) {
    return ['SUCCESS', 'OPEN', 'SKIPPED'].indexOf(step.status) === -1;
  }

  function _startPolling() {
    updateInterval = $interval(() => {
      $pipelineService.getPipeline(customerId, formId, registrationId).$promise.then(pipeline => {
        self.pipeline = pipeline;
        _renderPipeline();
      });
    }, UPDATE_INTERVAL);
  }

  function _renderPipeline() {
    self.activeStep = self.pipeline.steps.findIndex(x => ['SUCCESS', 'SKIPPED'].indexOf(x.status) === -1);

    // All done.
    if (self.activeStep === -1) {
      _killIntervals();
      return;
    }

    // Not all steps are completed
    let retryableStatus = RETRYABLE_PIPELINE_STATUS.indexOf(self.pipeline.status) !== -1;
    if (retryableStatus) {
      self.showStepActions = true;
      if (self.pipeline.nextExecution > new Date().getTime()) {
        _updateExecutionTime();
        retryTimeInterval = $interval(_updateExecutionTime, UPDATE_NEXT_EXECUTION_INTERVAL);
      } else {
        _killRetryTimeInterval();
      }
    } else {
      _killRetryTimeInterval();
    }
  }

  function _updateExecutionTime() {
    self.nextExecutionTime = Math.max(self.pipeline.nextExecution - new Date().getTime(), 0);
  }

  function _killUpdateInterval() {
    if (updateInterval) {
      $interval.cancel(updateInterval);
    }
  }

  function _killRetryTimeInterval() {
    self.nextExecutionTime = null;
    if (retryTimeInterval) {
      $interval.cancel(retryTimeInterval);
    }
  }

  function _killIntervals() {
    _killUpdateInterval();
    _killRetryTimeInterval();
  }
}]);
