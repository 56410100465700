'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', function ($stateProvider, stateConfig) {

  $stateProvider.state('portal.customers.templates', {
    url: '/templates',
    template: require('../../modules/FormTemplates/templates/template.index.html'),
    resolve: {
      formTemplates: ['$stateParams', '$customerFormTemplateService', function ($stateParams, $customerFormTemplateService) {
        return $customerFormTemplateService.findForCustomer($stateParams.customerId);
      }]
    },
    data: {
      pickerTemplate: require('../templates/portal/pickers/templates.html')
    },
    access: stateConfig.defaultAccess
  });
}]);
