'use strict';

angular.module('MoreDirectives').directive('uiReadonly', ['$interval', function ($interval) {
  return {
    restrict: 'A',
    link: function (scope, elem) {
      var interval = $interval(function () {
        var inputs = elem.find('input');
        if (inputs.length === 0) {
          return;
        }
        angular.element(inputs[0]).attr('readonly', '');
        $interval.cancel(interval);
      }, 500);

      scope.$on('$destroy', function () {
        $interval.cancel(interval);
      });
    }
  };
}]);