'use strict';

angular.module('UserManagement').controller('AddGroupPanelCtrl', ['$scope', '$customerGroupService', function ($scope, $customerGroupService) {

  $scope.newGroup = {};
  $scope.saving = false;

  $scope.saveNewGroup = function (name) {
    $scope.saving = true;
    $customerGroupService.create($scope.$state.params.customerId, { name: name }).$promise.then(function (savedGroup) {
      $scope.saving = false;
      $scope.$close(savedGroup);
    });
  };
}]);
