'use strict';

angular.module('MoreAppsServices').factory('$customerInviteService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerInvites = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/invites/:id/:action', {}, {
    update: {
      method: 'PUT'
    },
    resend: {
      method: 'GET',
      params: { action: 'resend' }
    }
  });

  var CustomerInviteGroups = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/invites/:id/groups/:groupId', {}, {});

  var CustomerInviteGrants = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/invites/:id/grants', {}, {
    patch: {
      method: 'PATCH',
      isArray: true
    }
  });

  return {
    inviteUser: (customerId, inviteRequest) => CustomerInvites.save({customerId}, inviteRequest),
    getInvites: customerId => CustomerInvites.query({customerId}),
    updateInviteSettings: (customerId, id, settings) => CustomerInvites.update({customerId, id}, settings),
    removeInvite: (customerId, id) => CustomerInvites.delete({customerId, id}),
    resendInvite: (customerId, id) => CustomerInvites.resend({customerId, id}),
    patchGrant: (customerId, id, grantChange) => CustomerInviteGrants.patch({customerId, id}, grantChange).$promise,
    getGrants: (customerId, id) => CustomerInviteGrants.query({customerId, id}).$promise,
    addGroup: (customerId, groupId, id) => CustomerInviteGroups.save({customerId, groupId, id}, {}).$promise,
    removeGroup: (customerId, groupId, id) => CustomerInviteGroups.delete({customerId, groupId, id}, {}).$promise,
  };
}]);
