'use strict';

angular.module('UserManagement').controller('AddUserPanelCtrl', ['$scope', '$userService', '$customerInviteService', '$modalService', 'groups', 'selectedGroupIds', function ($scope, $userService, $customerInviteService, $modalService, groups, selectedGroupIds) {
  var MAX_SIZE = 1024 * 1024 * 5;
  var files;
  var customerId = $scope.$state.params.customerId;

  $scope.addUserForm = {};
  $scope.saving = false;
  $scope.fileTooLarge = false;
  $scope.form = {
    inviteType: 'SINGLE',
    emailAddress: '',
    language: 'en',
    groups: selectedGroupIds || []
  };
  $scope.groups = groups;
  $scope.clickInvite = clickInvite;
  $scope.onSelectFiles = onSelectFiles;

  ////////
  function clickInvite() {
    if ($scope.form.inviteType === 'SINGLE') {
      saveNewUser();
    } else if ($scope.form.inviteType === 'BULK') {
      importUsers();
    }
  }

  function saveNewUser() {
    $scope.saving = true;
    const request = { emailAddress: $scope.form.emailAddress, language: $scope.form.language, groups: $scope.form.groups };
    $customerInviteService.inviteUser(customerId, request).$promise.then(function (savedUser) {
      $scope.saving = false;
      savedUser.emailAddress = savedUser.emailAddress.toLowerCase();
      $scope.$close();
    }, (response) => {
      $scope.saving = false;

      if (response.data.code === 'user_already_exists_for_customer') {
        $modalService.alertModal({
          title: 'USER_MANAGEMENT_INVITE_FAILED_TITLE',
          message: 'USER_MANAGEMENT_INVITE_FAILED_MESSAGE'
        });
      } else {
        $modalService.errorModal();
      }
    });
  }

  function importUsers() {
    $userService.importUsers(customerId, $scope.form.language, $scope.form.groups.join(','), files[0]).then(function (emailAddresses) {
      $scope.$close();
      $modalService.alertModal({
        title: 'USER_MANAGEMENT_IMPORTED_MODAL_TITLE',
        template: require('../templates/modals/usermanagement.users.imported.html'),
        data: {
          emailAddresses: emailAddresses
        }
      });
    }, () => {
      $scope.saving = false;

      if (response.data.code === 'user_already_exists_for_customer') {
        $modalService.alertModal({
          title: 'USER_MANAGEMENT_INVITE_FAILED_TITLE',
          message: 'USER_MANAGEMENT_INVITE_FAILED_MESSAGE'
        });
      } else {
        $modalService.errorModal();
      }
    });
  }

  function onSelectFiles($files) {
    files = $files;
    $files.forEach(function (file) {
      if (file.size > MAX_SIZE) {
        $scope.fileTooLarge = true;
      }
    });
  }
}]);
