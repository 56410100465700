'use strict';

angular.module('FormSimulator').directive('dateFormSimulatorField', function () {
  var DATE_SEPARATOR = '-';
  var TIME_SEPARATOR = ':';

  return {
    restrict: 'A',
    scope: {
      ngModel: '=',
      inputModel: '=',
      type: '='
    },
    link: function (scope) {

      scope.inputModel = scope.ngModel;

      var doubleDigit = function (value) {
        return value.length === 1 ? '0' + value : value;
      };

      var getFormatDate = function (date) {
        var year = date.getFullYear().toString();
        var month = doubleDigit((date.getMonth() + 1).toString());
        var day = doubleDigit(date.getDate().toString());
        return year + DATE_SEPARATOR + month + DATE_SEPARATOR + day;
      };

      var getTimeString = function (date) {
        return doubleDigit(date.getHours().toString()) + TIME_SEPARATOR + doubleDigit(date.getMinutes().toString());
      };

      scope.$watch('inputModel', function (newValue) {
        if (!newValue) {
          scope.ngModel = undefined;
          return;
        }

        switch (scope.type) {
          case 'date':
            scope.ngModel = getFormatDate(newValue);
            return;
          case 'time':
            scope.ngModel = getTimeString(newValue);
            return;
          default:
            //datetime
            scope.ngModel = getFormatDate(newValue) + ' ' + getTimeString(newValue);
        }
      });
    }
  };
});