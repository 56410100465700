'use strict';

angular.module('WidgetPropertyPane').directive('datasourceConfiguration', ['$customerDataSourceService', '$state', function ($customerDataSourceService, $state) {

  return {
    restrict: 'E',
    scope: true,
    link: function (scope) {
      var lastDataSourceId;

      scope.dataSources = $customerDataSourceService.getDataSources($state.params.customerId);

      var updateGroupMapping = function (newValue) {
        lastDataSourceId = newValue;
        scope.dataSources.$promise.then(function (dataSources) {
          var dataSource = dataSources.filter(function (dataSource) {
            return dataSource.id === newValue;
          })[0];
          scope.activeDataSource = dataSource;
          var newMapping = {};
          var oldMapping = {};
          var mapping = scope.formFieldProperties.data_source_configuration.mapping;
          if (mapping) {
            oldMapping = mapping;
          }
          if (dataSource) {
            dataSource.columnMapping.forEach(function (dataKey) {
              var oldValue = oldMapping[dataKey.id];
              newMapping[dataKey.id] = oldValue !== null && oldValue !== undefined ? oldValue : true;
            });
          }
          scope.formFieldProperties.data_source_configuration.mapping = newMapping;
        });
      };

      scope.$watch('formFieldProperties.data_source_configuration.id', function (newValue) {
        if (newValue !== lastDataSourceId) {
          updateGroupMapping(newValue);
        }
      });
    }
  };
}]);