'use strict';

angular.module('MoreAppsServices').factory('$customerServiceAccountsService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const CustomerServiceAccounts = $resource(moreConstants.apiEndpoint + '/customers/:customerId/service-accounts/:serviceAccountId', { 'customerId': '@customerId', 'serviceAccountId': '@serviceAccountId' }, {
    update: {
      method: 'PUT'
    }
  });
  const CustomerServiceAccountUrl = $resource(moreConstants.apiEndpoint + '/customers/:customerId/service-accounts/url', { 'customerId': '@customerId' });
  const CustomerServiceAccountUrl2 = $resource(moreConstants.apiEndpoint + '/customers/:customerId/service-accounts/:serviceAccountId/url', { 'customerId': '@customerId', 'serviceAccountId': '@serviceAccountId' });

  return {
    getServiceAccounts: customerId => CustomerServiceAccounts.query({customerId}),
    saveServiceAccount: (customerId, type, redirectUrl) => CustomerServiceAccountUrl.get({customerId, type, redirectUrl}),
    deleteServiceAccount: (customerId, serviceAccountId) => CustomerServiceAccounts.delete({customerId, serviceAccountId}),
    updateDisplayName: (customerId, serviceAccount) => CustomerServiceAccounts.update({customerId, serviceAccountId: serviceAccount.id}, {displayName: serviceAccount.displayName}),
    reconnect: (customerId, serviceAccount, redirectUrl) => CustomerServiceAccountUrl2.get({customerId, serviceAccountId: serviceAccount.id, redirectUrl})
  };
}]);
