'use strict';

angular.module('Billing').controller('BillingFlowCtrl', ['$scope', '$state', '$customerBillingService', '$customerService', '$billingService', '$interval', 'isTrialing', function ($scope, $state, $customerBillingService, $customerService, $billingService, $interval, isTrialing) {
  const self = this;
  const customerId = $state.params.customerId;

  self.isTrialing = isTrialing;
  self.selectedPlan = undefined;
  self.payOnFile = false;
  self.activeStep = 0;
  self.downgradeReasons = {};
  self.downgradeMessage = undefined;
  self.steps = [
    {label: 'BILLING_FLOW_STEP1_LABEL', bodyTemplate: require('../templates/flow/step1.body.html')},
    {label: 'BILLING_FLOW_STEP2_LABEL', bodyTemplate: require('../templates/flow/step2.body.html')},
    {label: 'BILLING_FLOW_STEP3_LABEL', bodyTemplate: require('../templates/flow/step3.body.html')},
    {label: 'BILLING_FLOW_STEP4_LABEL', bodyTemplate: require('../templates/flow/step4.body.html')}
  ];
  self.nextStep = () => self.activeStep++;
  self.previousStep = () => {
    self.activeStep--;
    if (self.activeStep < 0) {
      $scope.$dismiss();
    }
  };
  self.isStepActive = (index) => self.activeStep === index;
  self.isStepCompleted = (index) => self.activeStep > index;
  self.activateStep = (index) => self.activeStep = index;

  init();

  function init() {
    self.billing = $customerBillingService.getBilling(customerId);
    _loadPaymentMethods();
    $billingService.getActiveSubscriptions(customerId).$promise.then(subscriptions => {
      self.activeSubscription = subscriptions.filter(x => x.type === 'BASE')[0];
    });
  }

  self.onPreviewChangeLoaded = (previewChange) => {
    self.previewChange = previewChange;
  };

  self.hasDowngradeMessage = () => self.downgradeMessage && Object.values(self.downgradeReasons).filter(x => x).length > 0;

  self.onSelectPlan = (plan) => {
    self.selectedPlan = plan;
    self.nextStep();
  };

  self.onSaveBilling = (billing) => {
    self.billing = billing;
    self.nextStep();
  };

  self.onPaymentMethodSave = (payOnFile) => {
    _loadPaymentMethods();
    self.payOnFile = payOnFile;
    self.nextStep();
  };

  self.confirmPlanChange = () => {
    self.confirming = true;
    const planId = self.selectedPlan.id;
    let promise;
    if (self.activeSubscription) {
      const reason = getDowngradeReasons();
      promise = $billingService.changeSubscription(customerId, self.activeSubscription.subscription, self.activeSubscription.product, planId, reason, self.payOnFile).$promise;
    } else {
      promise = $billingService.convertToPlan(customerId, planId, self.payOnFile).$promise;
    }
    promise.then(_closeAfterUnblock, (err) => {
      self.errorMessage = err.data.message;
      self.confirming = false;
    });
  };

  self.showNextInvoice = () => {
    return !(self.payOnFile && isTrialing); // in this case we can't show a preview via Stripe API
  };

  function _closeAfterUnblock() {
    const poll = $interval(() => {
      $customerService.getCustomer(customerId).$promise.then(customer => {
        if (!customer.meta.deleteOn) { // blockage is gone
          $interval.cancel(poll);
          $scope.$close();
        }
      });
    }, 2000);
  }

  function _loadPaymentMethods() {
    self.paymentMethods = $billingService.getPaymentMethods(customerId);
    self.paymentMethods.$promise.then(methods => {
      self.selectedPaymentMethod = methods.find(p => p.default && !p.expired);
    });
  }

  function getDowngradeReasons() {
    if (self.previewChange === undefined) {
      return null; // if preview fails to load, don't bother getting downgrade reasons
    }
    const isDowngrade = self.previewChange.scheduleChange;
    if (!isDowngrade) {
      return null;
    }

    const reasons = Object.entries(self.downgradeReasons).reduce((acc, [key, checked]) => {
      if (checked) {
        acc.push(key);
      }
      return acc;
    }, []);

    return {
      reasons,
      message: self.downgradeMessage
    };
  }
}]);
