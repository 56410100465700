'use strict';

angular.module('Billing').controller('BillingPaymentMethodCtrl', ['$scope', '$timeout', '$state', '$billingService', '$interval', '$q', function ($scope, $timeout, $state, $billingService, $interval, $q) {
  const self = this;
  const POLL_DELAY = 2000;
  const POLL_MAX_RETRIES = 5;
  let pollInterval = null;
  const billing = $scope.billing;

  self.method = 'cc';
  self.saving = false;
  self.cardHolderName = undefined;
  self.email = undefined;
  self.country = undefined;
  self.addressLine1 = undefined;
  self.saveCreditCard = saveCreditCard;
  self.saveSepaDebit = saveSepaDebit;
  self.allowPayOnFile = $scope.allowPayOnFile;

  init();

  ////////
  function init() {
    _loadStripeUI();
    $scope.$on('$destroy', _destroyPoll);
  }

  function saveCreditCard() {
    self.saving = true;

    $billingService.createSetupIntent($state.params.customerId).$promise.then(intent => {
      const options = {
        payment_method_data: {
          billing_details: {name: self.cardHolderName}
        }
      };
      self.stripe.handleCardSetup(intent.clientSecret, self.cardElement, options).then(_waitForPaymentMethod);
    }, (err) => {
      self.saving = false;
      self.errorMessage = err.message;
    });
  }

  function saveSepaDebit() {
    self.saving = true;

    $billingService.createSetupIntent($state.params.customerId).$promise.then(intent => {
      const options = {
        payment_method: {
          sepa_debit: self.sepaElement,
          billing_details: {
            name: self.cardHolderName,
            email: self.email,
            address: {
              country: self.country,
              line1: self.addressLine1
            }
          },
        }
      };
      self.stripe.confirmSepaDebitSetup(intent.clientSecret, options).then(_waitForPaymentMethod);
    }, (err) => {
      self.saving = false;
      self.errorMessage = err.message;
    });
  }

  function _loadStripeUI() {
    $billingService.getPublishableKey($state.params.customerId).$promise.then(key => {
      self.stripe = window.Stripe(key.publishableKey);
      const elements = self.stripe.elements();
      const style = {
        base: {
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      self.cardElement = elements.create('card', {style});
      self.sepaElement = elements.create('iban', {
        style,
        supportedCountries: ['SEPA'],
        placeholderCountry: billing.billingAddress ? billing.billingAddress.countryCode : 'NL',
      });

      $timeout(() => {
        self.cardElement.mount('#card-element');
        self.sepaElement.mount('#sepa-element');
      });
    });
  }

  function _waitForPaymentMethod(result) {
    $timeout(() => { // needed to force Angular digest cycle
      if (result.error) {
        self.saving = false;
        self.errorMessage = result.error.message;
      } else {
        _pollPaymentMethodCreated().then(() => { // Allow time for Stripe hook to attach the card to customer
          self.saving = false;
          $scope.onSave({payOnFile: false});
        });
      }
    }, POLL_DELAY);
  }

  function _pollPaymentMethodCreated() {
    return $q((resolve) => {
      pollInterval = $interval(() => {
        $billingService.getPaymentMethods($state.params.customerId).$promise.then((res) => {
          if (res.length > 0) {
            _destroyPoll();
            resolve();
          }
        });
      }, POLL_DELAY, POLL_MAX_RETRIES);
    });
  }

  function _destroyPoll () {
    $interval.cancel(pollInterval);
  }

}]);
