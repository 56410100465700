'use strict';

angular.module('MoreAppsServices').factory('$customerPluginService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerPlugins = $resource(moreConstants.apiEndpoint + '/customers/:customerId/plugins/:action/:namespace/:key', {
    customerId: '@customerId', namespace: '@namespace', key: '@key'
  }, {
    filter: {
      method: 'POST',
      isArray: true
    }
  });

  return {
    find(customerId, query) {
      const baseQuery = [
        {path: 'usable', value: true, type: 'boolean'}
      ];
      const filter = {
        pageSize: 100,
        sort: [],
        idSelection: [],
        query: query ? baseQuery.concat(query) : baseQuery
      };
      return CustomerPlugins.filter({customerId}, filter);
    },
  };
}]);
