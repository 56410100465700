'use strict';

angular.module('MoreDirectives').directive('checkableInput', ['$timeout', function ($timeout) {
  return {
    restrict: 'E',
    template: require('../templates/checkable-input.html'),
    replace: true,
    scope: {
      ngModel: '=',
      timeout: '@',
      placeholder: '@',
      invoke: '&',
      stateChanged: '&'
    },
    link: function (scope) {
      var timeout = scope.timeout || 1000;
      scope.state = 'UNKNOWN';
      var checkTimeout;

      var setState = function (state) {
        scope.state = state;
        scope.stateChanged({ state: state });
      };

      scope.checkValue = function (value) {
        $timeout.cancel(checkTimeout);
        setState('UNKNOWN');

        checkTimeout = $timeout(function () {
          setState('CHECKING');
          scope.invoke({ value: value }).then(function (result) {
            if (scope.state === 'CHECKING') {
              setState(result.valid ? 'VALID' : 'INVALID');
            }
          });
        }, timeout);
      };

      if (scope.ngModel && scope.ngModel.length > 0) {
        scope.checkValue(scope.ngModel);
      }
    }
  };
}]);