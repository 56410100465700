'use strict';

angular.module('MoreGrid').directive('dateFilter', ['$timeout', function ($timeout) {
  var DELAY = 300;

  var millisWithHour = function (date, hour) {
    if (!date) {
      return null;
    }
    var answer = new Date(date);
    answer.setHours(hour);
    answer.setMinutes(0);
    answer.setSeconds(0);
    answer.setMilliseconds(0);
    return answer.getTime();
  };

  return {
    restrict: 'E',
    template: require('../templates/datefilter.html'),
    scope: {
      onChange: '&',
      id: '@',
      toTimestamp: '='
    },
    link: function (scope) {
      var timeoutId;
      scope.start = null;
      scope.end = null;

      var triggerChange = function () {
        if (timeoutId) {
          $timeout.cancel(timeoutId);
        }
        timeoutId = $timeout(function () {
          if (scope.toTimestamp) {
            scope.onChange({
              value: {
                start: millisWithHour(scope.start, 0),
                end: millisWithHour(scope.end, 24)
              },
              id: scope.id,
              type: 'date'
            });
          } else {
            scope.onChange({
              value: {
                start: scope.start,
                end: scope.end
              },
              id: scope.id,
              type: 'date'
            });
          }
        }, DELAY);
      };

      var changed = function (newValue, oldValue) {
        if (newValue !== oldValue) {
          triggerChange();
        }
      };

      scope.$watch('start', changed);
      scope.$watch('end', changed);
    }
  };
}]);