'use strict';

angular.module('MoreAppsServices').factory('$customerFormThemeService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var Themes = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/themes', {}, {});

  return {
    getThemes: function (customerId) {
      return Themes.query({ customerId: customerId });
    }
  };
}]);