'use strict';

angular.module('CustomerPortalSettings').controller('SettingsServiceAccountsCtrl', ['$scope', '$customerServiceAccountsService', '$sidePanel', '$featureHelper', 'moreConstants', '$oauthAuthorizeService', '$modalService', '$translate', function ($scope, $customerServiceAccountsService, $sidePanel, $featureHelper, moreConstants, $oauthAuthorizeService, $modalService, $translate) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.authTypes = [
    'GOOGLE_SHEETS',
    'ONEDRIVE',
  ];

  $scope.form = {
    type: 'GOOGLE_SHEETS'
  };

  self.getServiceAccounts = function () {
    $customerServiceAccountsService.getServiceAccounts(customerId).$promise.then((serviceAccounts) => {
      self.serviceAccounts = serviceAccounts;
    }, () => {
      self.error = "SERVICE_ACCOUNTS_LOAD_FAILED";
    });
  };

  self.addServiceAccount = function () {
    $oauthAuthorizeService.createServiceAccount(customerId, $scope.form.type, () => {
      self.getServiceAccounts();
    }, () => {
      self.error = "SERVICE_ACCOUNTS_CREATE_FAILED";
    });
  };

  self.updateServiceAccount = function (account) {
    $customerServiceAccountsService.updateDisplayName(customerId, account).$promise.then((response) => {
      // Nothing to do
    }, () => {
      self.error = "SERVICE_ACCOUNTS_UPDATE_FAILED";
    });
  };

  self.reconnectServiceAccount = (account) => {
    $oauthAuthorizeService.reconnectServiceAccount(customerId, account, () => {
      self.getServiceAccounts();
    }, () => {
      self.error = "SERVICE_ACCOUNTS_RECONNECT_FAILED";
    });
  };

  self.deleteServiceAccount = (account) => {
    $modalService.confirmModal({
      title: 'SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE',
      message: $translate.instant('SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE', { displayName: account.displayName }),
      confirmButtonTitle: 'DELETE',
      showExclamation: true
    }).then(() => {
      $customerServiceAccountsService.deleteServiceAccount(customerId, account.id).$promise.then((response) => {
        self.getServiceAccounts();
      }, () => {
        self.error = "SERVICE_ACCOUNTS_DELETE_FAILED";
      });
    });
  };

  init();

  function init() {
    self.getServiceAccounts();
  }
}]);
