'use strict';

angular.module('customerPortal').controller('PortalCtrl', ['$scope', '$state', 'localStorageService', function ($scope, $state, localStorageService) {

  if ($state.is('portal')) {
    // No customer id in URL
    let activeCustomerId = localStorageService.get('userState.currentCustomerId');
    const adminRoles = $scope.adminRoles();
    if (adminRoles.indexOf(activeCustomerId) === -1) {
      console.info(`Last known active customer ID (${activeCustomerId}) is not accessible from this account, open first known account: ${adminRoles[0]}`);
      activeCustomerId = adminRoles[0];
    }

    $state.go('portal.customers', {customerId: activeCustomerId});
  }
}]);
