'use strict';

angular.module('FormEditor').controller('IntegrationSelectModalCtrl', ['$scope', '$customerPluginService', '$modalService', 'installedIntegrations', 'customerId', 'moreConstants', function ($scope, $customerPluginService, $modalService, installedIntegrations, customerId, moreConstants) {

  const self = this;
  const BASE_QUERY = [
    {path: 'type', value: 'HOOK', type: 'string'}
  ];

  self.adding = false;
  self.integrations = null;
  self.selectedIntegration = null;
  self.select = select;
  self.goBack = goBack;
  self.addToForm = addToForm;

  init();

  ////////
  function init() {
    $customerPluginService.find(customerId, BASE_QUERY).$promise.then(res => {
      self.integrations = res;

      const extraIntegrations = installedIntegrations.filter(i => !res.find(x => x.namespace === i.namespace && x.key === i.key));
      extraIntegrations.forEach(item => {
        const query = [
          {path: 'type', value: 'HOOK', type: 'string'},
          {path: 'namespace', value: item.namespace, type: 'string'},
          {path: 'key', value: item.key, type: 'string'}
        ];
        $customerPluginService.find(customerId, query).$promise.then(found => {
          self.integrations = self.integrations.concat(found);
        });
      });
    });
  }

  function goBack() {
    if (self.selectedIntegration) {
      self.selectedIntegration = null;
    } else {
      $scope.$dismiss();
    }
  }

  function select(integration) {
    self.selectedIntegration = integration;
  }

  function addToForm(integration) {
    $scope.$close(integration);
  }
}]);
