'use strict';

angular.module('FormManagement.submissions').controller('FormManagementSubmissionDetailCtrl', ['$scope', '$rootScope', '$modalService', '$submissionService', '$customerRegistrationService', 'submission', 'dataColumns', 'canShowData', '$customerRegistrationFileService', '$window', '$downloadUtil', '$interval', function ($scope, $rootScope, $modalService, $submissionService, $customerRegistrationService, submission, dataColumns, canShowData, $customerRegistrationFileService, $window, $downloadUtil, $interval) {

  const customerId = submission.info.customerId;
  const DELETE_POLL_DELAY = 1000;
  const DELETE_POLL_MAX_RETRIES = 10;
  let deletePoll;

  $scope.submission = submission;
  $scope.dataColumns = dataColumns;
  $scope.canShowData = canShowData;
  $scope.isDeleting = false;

  $scope.getDeviceIcon = getDeviceIcon;
  $scope.deleteSubmission = deleteSubmission;
  $scope.resendSubmission = resendSubmission;
  $scope.downloadPdf = downloadPdf;
  $scope.downloadFiles = downloadFiles;

  ////////
  function getDeviceIcon(device) {
    if (!device) {
      return;
    }
    var deviceLower = device.toLowerCase();
    if (deviceLower.indexOf('android') !== -1) {
      return 'fa-android';
    } else if (deviceLower.indexOf('ios') !== -1) {
      return 'fa-apple';
    } else {
      return 'fa-desktop';
    }
  }

  function deleteSubmission(submission) {
    $modalService.deleteModal({
      title: 'VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE',
      message: 'VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE'
    }).then(function () {
      $scope.isDeleting = true;
      $customerRegistrationService.deleteRegistration(customerId, submission.id).$promise.then(function () {
        deletePoll = $interval((i) => {
          $submissionService.getSubmission(customerId, submission.info.formId, submission.id).$promise.catch((err) => {
            if (err.status === 404) {
              $interval.cancel(deletePoll);
              $scope.$close({ deleted: true });
            }
          });
          if (i === DELETE_POLL_MAX_RETRIES) { // Delete takes longer than expected, don't keep the user waiting forever
            $interval.cancel(deletePoll);
            $scope.$close({ deleted: true });
          }
        }, DELETE_POLL_DELAY, DELETE_POLL_MAX_RETRIES);
      }, function () {
        $modalService.errorModal();
        $scope.isDeleting = false;
      });
    });
  }

  function resendSubmission (submission) {
    $modalService.confirmModal({
      title: 'VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE',
      template: require('../templates/formmanagement.submissionResend.html'),
      confirmButtonTitle: 'VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON',
      data: {
        template: submission
      }
    }).then(function () {
      $submissionService.resendSubmission(customerId, submission.id).$promise.then(function () {
        $scope.resendSubmissionStatus = 'SUCCESS';
      }, function () {
        $scope.resendSubmissionStatus = 'ERROR';
      });
    });
  }

  function downloadPdf (pdfFileId) {
    $customerRegistrationFileService.getSecureUri(customerId, pdfFileId).then(function (secureUri) {
      $downloadUtil.downloadFileInNewWindow(secureUri);
    });
  }

  function downloadFiles (submission) {
    $submissionService.getSubmissionFiles(customerId, submission.info.formId, submission.id).then(function (secureUri) {
      $downloadUtil.downloadFileInNewWindow(secureUri.secureUri);
    });
  }
}]);
