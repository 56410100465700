'use strict';

angular.module('FormManagement').controller('FormManagementHistoryCtrl', ['form', 'formVersions', 'selectedVersion', '$scope', '$state', '$customerFormService', '$modalService', '$formVersionUtils', function (form, formVersions, selectedVersion, $scope, $state, $customerFormService, $modalService, $formVersionUtils) {
  const self = this;
  const customerId = $state.params.customerId;
  const pageSize = 10;
  let page = 0;

  self.hasMorePages = formVersions.length >= 10;
  self.temp = {};
  self.form = form;
  self.selectedVersion = selectedVersion;
  self.formVersions = formVersions;
  self.selectVersion = selectVersion;
  self.publishVersion = publishVersion;
  self.deleteVersion = deleteVersion;
  self.loadMoreVersions = loadMoreVersions;

  function selectVersion (formVersion) {
    self.selectedVersion = formVersion;
  }

  function publishVersion (formVersion) {
    $modalService.confirmModal({
      title: 'PUBLISH_FORM_VERSION',
      message: 'PUBLISH_FORM_VERSION_MESSAGE',
      confirmButtonTitle: 'CONFIRM',
    }).then(function () {
      $customerFormService.publishFormVersion(customerId, formVersion.formId, formVersion.id).$promise.then(form => {
        $scope.$close({form, formVersion});
      });
    });
  }

  function deleteVersion (formVersion) {
    $modalService.deleteModal({
      title: 'REMOVE_DRAFT_TITLE',
      message: 'REMOVE_DRAFT_MESSAGE'
    }).then(function () {
      $customerFormService.deleteFormVersion(customerId, formVersion.formId, formVersion.id).$promise.then(() => {
        $scope.$close({formVersions: formVersions.filter(version => version.id !== formVersion.id)});
      });
    });
  }

  function loadMoreVersions () {
    page++;
    $customerFormService.getFormVersions(customerId, form.id, page).$promise.then((versions) => {
      self.formVersions = [...self.formVersions, ...versions];
      if (versions.length < pageSize) {
        self.hasMorePages = false;
      }
    });
  }

}]);
