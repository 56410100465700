'use strict';
angular.module('more.ui.form').directive('moreForm', function ($fieldInitializer, $timeout, $formDataHelper, $q, $loadingHelper) {
  return {
    restrict : 'E',
    template : require('../templates/more-form.html'),
    scope : {
      form : '=',
      config : '=',
      customerId : '=',
      sourceData : '=',
      targetData : '=',
      parentData : '=',
      collection : '=',
      behaviour : '=',
      onDrop : '&',
      onViewDataChange : '&',
      onFieldEdit : '&',
      ruleEvaluator : '=',
      widgetsPromise : '=',
      rememberedValues : '=',
      enableDataEntry : '='
    },
    link : function (scope, element) {
      var source = scope.sourceData || {};
      var config = scope.config || {};
      var ruleEvaluator = scope.ruleEvaluator;
      var visibility = {};
      var validators = {};
      var widgetMap = {};
      var targetData = scope.targetData;
      var parentData = scope.parentData || {};
      var validationEnabled = false;
      var viewConfigs = [];
      var renderFieldsInitial = 20;
      var renderFieldsIncrement = 20;
      var renderFieldsTimeout = 300;

      scope.loading = true;
      scope.valueChanged = valueChanged;
      scope.rejectInitialValue = rejectInitialValue;
      scope.registerValidate = registerValidate;
      scope.removeField = prepareRemoveField;
      scope.editField = editField;
      scope.loadMore = loadMore;
      scope.viewConfigs = [];
      scope.limit = config.paginated ? renderFieldsInitial : undefined;
      scope.hasMoreFields = !!config.paginated;

      init();

      ////////

      function init () {
        scope.fields = scope.form.type === 'list_view' ? scope.form.items : scope.form.fields;

        scope.$on('more-form.stop-validation', function () {
          if (!validationEnabled) {
            return;
          }
          validationEnabled = false;
          Object.keys(validators).forEach(function (key) {
            validators[key].reset();
          });
        });

        scope.$on('more-form.start-validation', function () {
          validationEnabled = true;
          ensureAllFieldsAreRendered().then(function () {
            var result = validateFields();
            scope.$emit('more-form.post-validation', result);
          });
        });

        scope.$on('more-form.add-field', function (event, data) {
          if (data.viewId === scope.form.uid) {
            var field = data.field;
            field.$$widget = data.widget;
            var collection = scope.fields;
            collection.push(field);
            var viewConfig = asViewConfig(field);
            viewConfigs.push(viewConfig);
            scope.viewConfigs.push(viewConfig);
            setupRecompile(field);
          }
        });

        scope.$on('more-form.prepare-remove-field-accept', function (event, field) {
          removeField(event, field);
        });

        scope.widgetsPromise.then(function (widgets) {
          widgets.forEach(function (widget) {
            widgetMap[widget.info.namespace + ':' + widget.info.key] = widget;
          });

          scope.viewData = angular.copy(source);

          scope.fields.forEach(function (field) {
            var key = field.widget.replace(/:[0-9]+/gi, '');
            var widget = widgetMap[key];

            field.$$widget = widget;
            setupRecompile(field);

            if (!config.skipInitialization) {
              $fieldInitializer.initializeField(widget, field, scope.viewData, scope.rememberedValues || {});
            }
          });

          Object.keys(scope.viewData).forEach(function (sourceKey) {
            source[sourceKey] = targetData[sourceKey] = angular.copy(scope.viewData[sourceKey]);
          });

          scope.formDataHelper = $formDataHelper(targetData, scope.fields, scope.collection);
          viewConfigs = scope.fields.map(asViewConfig);
          scope.viewConfigs = viewConfigs.concat([]); // copy array, keep pointers

          triggerRules();

          scope.loading = false;
          bindDragDrop();
          scope.onViewDataChange({data : angular.copy(scope.viewData)});
        });
      }

      function loadMore () {
        $timeout(function () {
          scope.limit += renderFieldsIncrement;
          scope.hasMoreFields = scope.limit < scope.fields.length;
          scope.$broadcast('scroll.infiniteScrollComplete');
        }, renderFieldsTimeout);
      }

      function ensureAllFieldsAreRendered () {
        if (!scope.hasMoreFields) {
          return $q.resolve();
        }
        var deferred = $q.defer();
        var message = $loadingHelper.addTranslatedMessageContent('FORM_VALIDATING');

        $timeout(function () { // ensure spinner is rendered
          scope.limit = scope.fields.length;
        }, 500);

        $timeout(function () { // ensure fields are rendered
          $loadingHelper.hideMessage(message);
          deferred.resolve();
        }, 1000);

        return deferred.promise;
      }

      function asViewConfig (field) {
        return {
          uid : field.uid,
          field : field,
          widget : field.$$widget,
          customerId : scope.customerId,
          formDataHelper : scope.formDataHelper.widget,
          ngModel : function () {
            return scope.targetData[field.properties.data_name];
          },
          valueChanged : valueChanged,
          rejectInitialValue : rejectInitialValue,
          registerValidate : registerValidate,
          collection : scope.collection,
          formId : scope.form.uid,
          form : scope.form
        };
      }

      function valueChanged (field, newValue) {
        if (!field) {
          return;
        }
        scope.formDataHelper.changeValue(field, newValue, function () {
          triggerRules(field);

          if (validationEnabled) {
            validateField(field);
          }
        });
      }

      var getFormFieldByUid = function (uid) {
        return scope.fields.filter(function (formFields) {
            return formFields.uid === uid;
          })[0] || null;
      };

      function triggerRules (field) {
        if (!config.rulesEnabled) {
          return;
        }
        var ruleData = angular.copy(targetData);
        ruleData._parent_ = parentData;
        var changedFields = ruleEvaluator.execute(visibility, ruleData, field ? field.uid : null);
        scope.viewConfigs = visibleViewConfigs();

        Object.keys(changedFields.value).forEach(function (uid) {
          var changedField = getFormFieldByUid(uid);
          if (changedField) {
            valueChanged(changedField, changedFields.value[uid]);
          }
        });
      }

      function reorderCollection (from, to) {
        var collection = scope.fields;
        var item = collection[from];
        var viewConfigItem = viewConfigs[from];

        if (from === to) {
          return;
        }

        collection.splice(from, 1);
        collection.splice(to, 0, item);

        viewConfigs.splice(from, 1);
        viewConfigs.splice(to, 0, viewConfigItem);

        scope.viewConfigs = visibleViewConfigs();
      }

      function rejectInitialValue (field, newValue) {
        var result = angular.copy(newValue);
        source[field.properties.data_name] = result;
        scope.viewData[field.properties.data_name] = result;

        scope.formDataHelper.changeValue(field, result, function () {
          scope.onViewDataChange({data : scope.viewData});
          triggerRules(field);
        });
      }

      function registerValidate (uid, validatorObject) {
        validators[uid] = validatorObject;
      }

      function editField (field) {
        scope.onFieldEdit({field : field});
      }

      function prepareRemoveField ($event, field) {
        scope.$emit('more-form.prepare-remove-field', field);
      }

      function removeField ($event, field) {
        var collection = scope.fields;
        var index = collection.indexOf(field);

        if (index < 0) {
          return;
        }

        collection.splice(index, 1);
        viewConfigs.splice(index, 1);
        scope.viewConfigs.splice(index, 1);
        scope.$emit('more-form.field-removed');
      }

      function visibleViewConfigs () {
        return viewConfigs.filter(function (viewConfig) {
          return visibility[viewConfig.uid] == null || visibility[viewConfig.uid];
        });
      }

      function insertInCollection (index, field, widget) {
        var collection = scope.fields;
        field.$$widget = widget;
        collection.splice(index, 0, field);
        viewConfigs.splice(index, 0, asViewConfig(field));
        scope.viewConfigs.splice(index, 0, asViewConfig(field));
        setupRecompile(field);
      }

      function setupRecompile (field) {
        field.$$recompileCount = 0;
        field.$$recompile = function (newValue, oldValue) {
          delete validators[field.uid];
          if (oldValue.data_name) {
            delete scope.viewData[oldValue.data_name];
          }
          if (!config.skipInitialization) {
            $fieldInitializer.initializeField(field.$$widget, field, scope.viewData, {});
          }
          if (newValue.data_name) {
            scope.formDataHelper.changeValue(field, angular.copy(scope.viewData[newValue.data_name]));
          }
          field.$$recompileCount++;
          scope.onViewDataChange({data : scope.viewData});
        };
      }

      function validateFields () {
        return scope.fields.filter(function (field) {
          return (isFieldInViewConfigs(field));
        }).map(validateField).filter(function (val) {
          return val;
        });
      }

      function isFieldInViewConfigs (field) {
        return scope.viewConfigs.filter(function (viewConfig) {
          return field.uid === viewConfig.uid;
        }).length > 0;
      }

      function validateField (field) {
        if (!validators[field.uid]) {
          return null;
        }
        var message = validators[field.uid].validate(targetData[field.properties.data_name]);
        return message && {uid : field.uid, key : field.properties.data_name, message : message};
      }

      function bindDragDrop () {
        if (!scope.behaviour || !scope.behaviour.dragDrop) {
          return;
        }
        $timeout(function () {
          $(element).find('.more-form-orderable').sortable({
            revert : false,
            placeholder : 'placeholder-dropping-item',
            start : function (event, ui) {
              ui.placeholder.height(ui.item.height());
              if (ui.item.hasClass('more-form-item')) {
                ui.item.startPos = ui.item.index();
              }
            },
            beforeStop : function (event, ui) {
              ui.item.data('widget', ui.helper.data('widget'));
            },
            stop : function (event, ui) {
              scope.$apply(function () {
                if (ui.item.startPos != null) {
                  var oldIndex = ui.item.startPos;
                  var newIndex = ui.item.index();
                  reorderCollection(oldIndex, newIndex);
                } else {
                  var widget = ui.item.data('widget');
                  var field = scope.onDrop({widget : widget});
                  insertInCollection(ui.item.index(), field, widget);
                  ui.item.remove();
                }

              });
            }
          });
        }, 100);
      }
    }
  };
});
