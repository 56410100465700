'use strict';

angular.module('Impersonation').controller('AdminLoginCtrl', ['$scope', '$securityService', '$adminUserService', '$adminCustomerService', '$http', '$location', 'moreConstants', function ($scope, $securityService, $adminUserService, $adminCustomerService, $http, $location, moreConstants) {
  var self = this;

  function init() {
    // Remove previous impersonation if active (for switching back to your normal user and making it possible to do 'ADMIN' API calls again)
    delete $scope.user.impersonation;

    const customerId = $location.search()['customer'];
    if (customerId) {
      self.impersonate('customer', customerId);
    }
  }

  self.impersonate = function (type, value) {
    if (type === 'user') {
      $adminUserService.getUser(value).$promise
          .then((res) =>  {
            if (res.status && res.status !== 200) {
              self.errorMessage = 'User not found';
            } else {
              impersonateHandle(type, value);
            }
          });
    } else if (type === 'customer') {
      $adminCustomerService.getCustomer(value).$promise
          .then((res) => {
            if (res.status && res.status !== 200) {
              self.errorMessage = 'Customer not found';
            } else {
              impersonateHandle(type, value);
            }
          });
    } else {
      console.warn(`Invalid impersonation type: ${type}`);
    }
  };

  function impersonateHandle(type, value) {
    $scope.user.impersonation = { value };
    $http({ method: 'GET', url: moreConstants.apiEndpoint + '/authentication' }).success(function (data) {
      //Fix local permissions
      $scope.user.roles = data.roles;
      $scope.user.permissions = data.permissions;
      $securityService.updateUser($scope.user);

      if (type === 'customer') {
        $scope.$state.go('portal.customers.form.overview', { customerId: value });
      } else {
        $scope.$state.go('portal');
      }
    }, function () {
      $securityService.logout();
      $scope.$state.go('login');
    });
  }

  init();
}]);
