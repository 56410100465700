'use strict';

angular.module('Tour').factory('$tourService', ['moreConstants', '$resource', '$translate', function (moreConstants, $resource, $translate) {

  var TourSetup = $resource(moreConstants.apiEndpoint + '/customers/:customerId/tour/setup/:action', {}, {
    setup: {
      method: 'POST'
    }
  });

  return {
    setupCustomerForTour: function (customerId) {
      var language = $translate.use();
      return TourSetup.setup({ customerId: customerId }, { language: language });
    }
  };
}]);