'use strict';

require('./bootstrap');
require('./controllers/CustomerCreateCtrl');
require('./controllers/SettingsIndexCtrl');
require('./controllers/SettingsGeneralCtrl');
require('./controllers/SettingsSsoCtrl');
require('./controllers/SettingsBillingCtrl');
require('./controllers/SettingsApiKeysCtrl');
require('./controllers/SettingsServiceAccountsCtrl');
require('./controllers/SettingsSendingDomainCtrl');
require('./controllers/webhooks/SettingsWebhooksCtrl');
require('./controllers/webhooks/AddSubscriberCtrl');
require('./controllers/webhooks/SubscriberDetailCtrl');
