'use strict';

angular.module('Billing').directive('billingDetailsForm', ['$translate', function () {
  return {
    restrict: 'E',
    scope: {
      onSave: '&',
      billing: '='
    },
    template: require('../templates/directives/billing.details-form.html')
  };
}]);
