'use strict';

angular.module('MoreAppsServices').factory('$customerRegistrationService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var Registrations = $resource(moreConstants.apiEndpoint + '/customers/:customerId/registrations/:registrationId', {}, {});

  return {
    deleteRegistration: (customerId, registrationId) => Registrations.delete({customerId, registrationId})
  };
}]);
