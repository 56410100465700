'use strict';

angular.module('FormManagement.submissions').controller('FormManagementMailStatusDetailCtrl', ['registration', 'customerId', '$customerRegistrationFileService', '$downloadUtil', function (registration, customerId, $customerRegistrationFileService, $downloadUtil) {

  var self = this;
  self.registration = registration;

  self.downloadPdf = downloadPdf;

  ////////
  function downloadPdf(pdfFileId) {
    $customerRegistrationFileService.getSecureUri(customerId, pdfFileId).then(function (secureUri) {
      $downloadUtil.downloadFileInNewWindow(secureUri);
    });
  }
}]);