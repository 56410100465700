'use strict';

angular.module('customerPortal').controller('GroupPickerCtrl', ['$scope', '$rootScope', '$sidePanel', function ($scope, $rootScope, $sidePanel) {

  $scope.selectGroupCallback = group => {
    $rootScope.$broadcast('group.changed', group);
  };

  $scope.editGroupCallback = (group) => {
    $sidePanel.open({
      template: require('../../../../modules/UserManagement/templates/groupmanagement.group.edit.html'),
      resolve: {
        group: () => angular.copy(group)
      },
      controller: 'EditGroupCtrl as ctrl'
    });
  };

  $scope.addGroupCallback = () => {
    $sidePanel.open({
      template: require('../../../templates/portal/group.new.html'),
      resolve: {},
      controller: 'AddGroupCtrl as ctrl'
    }).then(newGroup => {
      $scope.$broadcast('group.added', newGroup);
    });
  };
}]);
