'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', function ($stateProvider, stateConfig) {

  $stateProvider.state('portal.customers.dataSources', {
    url: '/dataSources',
    template: require('../../modules/DataSources/templates/dataSource.index.html'),
    resolve: {
      dataSources: ['$stateParams', '$customerDataSourceService', function ($stateParams, $customerDataSourceService) {
        return $customerDataSourceService.getDataSources($stateParams.customerId);
      }]
    },
    data: {
      pickerTemplate: require('../templates/portal/pickers/datasources.html')
    },
    access: stateConfig.defaultAccess
  });
}]);