'use strict';

angular.module('FormEditor').filter('formView', function () {
  return function (arr) {
    if (arr === null || arr === undefined) {
      return null;
    }
    var answer = [];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].type === 'form') {
        answer.push(arr[i]);
      }
    }
    return answer;
  };
});

angular.module('FormEditor').filter('typedView', function () {
  return function (arr, type) {
    if (arr === null || arr === undefined || !type) {
      return arr;
    }

    return arr.filter(function (item) {
      return item.type === type;
    });
  };
});

angular.module('FormEditor').filter('notFormId', function () {
  return function (arr, viewId) {
    if (arr === null || arr === undefined || !viewId) {
      return arr;
    }

    return arr.filter(function (item) {
      return item.key !== viewId;
    });
  };
});