module.exports = {
  "moreappMail" : true,
  "showAppLinks" : true,
  "appName" : "MoreApp",
  "brandingKey": "moreapp",
  "isBranded": false,
  "showOnboarding" : true,
  "showHelp" : true,
  "allowSignup" : true,
  "showHelpBanner": true,
  "customerId": null,
  "pageTitle" : "MoreApp - Platform",
  "favicon" : "https://cdn.moreapp.com/more-icons/favicons/moreapp.ico",
  "urls" : {
    "webclient" : "https://app.moreapp.com",
    "ios" : "https://itunes.apple.com/us/app/moreapp/id839543670",
    "android" : "https://play.google.com/store/apps/details?id=com.moreapp"
  },
  "privacyUrl" : "https://moreapp.com/en/privacy",
  "licenseAgreementUrl" : "https://moreapp.com/assets/MoreApp-LA-EULA-DPA.pdf"
}
;