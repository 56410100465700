'use strict';

angular.module('more.ui.form-builder').factory('$popupHelper', ['$modalService', function ($modalService) {

  return {
    confirm: function (title, message, callback) {
      return $modalService.confirmModal({
        title: title,
        message: message,
        confirmButtonTitle: 'SAVE'
      }).then(function () {
        callback(true);
      }, function () {
        callback(false);
      });
    },
    alert: function (title, message) {
      return $modalService.alertModal({
        title: title,
        message: message
      });
    },
    info: function (title, message) {
      return $modalService.alertModal({
        title: title,
        message: message
      });
    }
  };
}]);