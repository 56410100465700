'use strict';

angular.module('MoreDirectives').directive('onlyNumericList', ['$numberUtils', function ($numberUtils) {
  return {
    restrict: 'E',
    scope: {
      ngModel: '='
    },
    template: '<input class="form-control" ng-list="," ng-trim="true" ng-model="ngModel" ng-keypress="onKeypress($event)" ng-change="onChange()">',
    link: function (scope, element) {
      scope.onKeypress = onKeypress;
      scope.onChange = onChange;

      ////////
      function onChange() {
        if (_getLastChar(element.find('input')[0].value) === ',') {
          return;
        }
        scope.ngModel = $numberUtils.toUniqueNumbers(scope.ngModel);
      }
    }
  };

  function onKeypress($event) {
    var input = String.fromCharCode($event.keyCode);
    var lastChar = _getLastChar($event.target.value);
    if (input === ' ' || isNaN(input) && (input !== ',' || lastChar === ',')) {
      $event.preventDefault();
    }
  }

  function _getLastChar(value) {
    return value[value.length - 1];
  }
}]);