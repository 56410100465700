'use strict';

angular.module('FormTemplates').controller('TemplateManagementCtrl', ['$scope', '$rootScope', '$customerFormTemplateService', '$formVersionUtils', '$modalService', function ($scope, $rootScope, $customerFormTemplateService, $formVersionUtils, $modalService) {
  const self = this;
  const customerId = $scope.$state.params.customerId;

  self.temp = {};
  self.template = null;
  self.updateName = updateName;
  self.updateDescription = updateDescription;
  self.updateLanguage = updateLanguage;
  self.updateTags = updateTags;
  self.selectIcon = selectIcon;
  self.editTemplate = editTemplate;
  self.deleteTemplate = deleteTemplate;
  self.toggleScope = toggleScope;
  self.selectableTags = $customerFormTemplateService.getSelectableTags();

  init();

  function init() {
    $scope.$on('template.removed', () => self.template = null);
    $scope.$on('template.changed', (event, data) => {
      self.template = data;
      if (self.template.publishedVersion && self.template.publishedVersion.formVersion) {
        $customerFormTemplateService.getFormVersion(customerId, self.template.id, self.template.publishedVersion.formVersion).$promise
          .then(formVersion => {
            self.templateVersion = formVersion;
            return $customerFormTemplateService.getFormVersions(customerId, self.template.id).$promise;
          })
          .then(formVersions => self.templateVersions = formVersions);
      } else {
        $customerFormTemplateService.getFormVersions(customerId, self.template.id).$promise.then((formVersions) => {
          self.templateVersions = formVersions;
          self.templateVersion = getFormVersionToDisplay(formVersions);
        });
      }
    });
  }

  function updateName(name) {
    $customerFormTemplateService.updateName(customerId, self.template.id, name).$promise.then(template => {
      $rootScope.$broadcast('template.updated', template);
    });
  }

  function updateDescription(description) {
    $customerFormTemplateService.updateDescription(customerId, self.template.id, description);
  }

  function updateLanguage (language) {
    $customerFormTemplateService.updateLanguage(customerId, self.template.id, language);
  }

  function updateTags (tags) {
    $customerFormTemplateService.updateTags(customerId, self.template.id, tags);
  }

  function selectIcon() {
    $modalService.open({
      template: require('../../../modules/IconPicker/templates/icon-picker.modal.html'),
      controller: 'IconPickerModalCtrl',
      controllerAs: 'ctrl'
    }).then(icon => {
      $customerFormTemplateService.updateIcon(customerId, self.template.id, icon).$promise.then(template => {
        self.template = template;
        $rootScope.$broadcast('template.updated', template);
      });
    });
  }

  function editTemplate(template, formVersion) {
    if (formVersion && formVersion.meta.status === 'DRAFT') { // Only draft available, otherwise it would be FINAL here
      goFormBuilder(formVersion);
    } else {
      const draftVersions = self.templateVersions.filter(version => version.meta.status === 'DRAFT');
      if (draftVersions && draftVersions.length > 0) { // Should be one for now, retrieve latest just in case
        goFormBuilder(draftVersions[draftVersions.length - 1]);
      } else {
        $customerFormTemplateService.createFormVersion(customerId, template.id, formVersion).$promise.then(version => goFormBuilder(version));
      }
    }
  }

  function deleteTemplate(template) {
    $modalService.deleteModal({
      title: 'TEMPLATE_REMOVE_MODAL_TITLE',
      template: require('../templates/template.remove.html'),
      data: {
        name: template.meta.name
      }
    }).then(() => {
      $customerFormTemplateService.trashForm(customerId, template.id).$promise.then(trashedForm => {
        $rootScope.$broadcast('template.removed', trashedForm);
      });
    });
  }

  function toggleScope (template) {
    const newScope = template.scope === 'GLOBAL' ? 'CUSTOMER' : 'GLOBAL';
    $customerFormTemplateService.updateScope(customerId, self.template.id, newScope).$promise.then(template => {
      self.template = template;
      $rootScope.$broadcast('template.updated', template);
    });
  }

  function goFormBuilder(formVersion) {
    const newParams = angular.copy($scope.$state.params);
    newParams.formId = formVersion.formId;
    newParams.formVersionId = formVersion.id;
    newParams.openWithValidationErrors = true;
    newParams.isTemplate = true;
    newParams.exitState = 'portal.customers.templates';
    $scope.$state.go('portal.customers.form.editor.edit', newParams);
  }

  function getFormVersionToDisplay(formVersions) {
    if (!formVersions || formVersions.length === 0) {
      return $formVersionUtils.newFormVersion(self.template); // show empty form
    }
    return formVersions[formVersions.length - 1];
  }
}]);
