'use strict';

angular.module('CustomerPortalSettings').controller('SettingsGeneralCtrl', ['$state', '$customerService', '$rootScope', '$dateUtils', function ($state, $customerService, $rootScope, $dateUtils) {

  var self = this;
  self.customerNameInvalid = false;

  $customerService.getCustomer($state.params.customerId).$promise.then(function (result) {
    self.customer = result;
  });

  self.saveGeneralCustomerSettings = function (customer) {
    self.successMessage = null;
    self.errorMessage = null;
    self.saving = true;
    $customerService.updateGeneralCustomerSettings(customer.customerId, customer.settings).$promise.then(function () {
      self.saving = false;
      self.successMessage = 'GENERAL_SUCCESS_MESSAGE';
      $rootScope.settings.dateFormat = $dateUtils.formatDateFormat(customer.settings.dateFormat);
    }, function (error) {
      self.saving = false;
      if (error.data.message) {
        self.errorMessage = error.data.message;
      } else {
        self.errorMessage = error;
      }
    });
  };

  self.updateCustomerName = function (customer) {
    self.successMessage = null;
    self.errorMessage = null;
    $customerService.updateName(customer.customerId, customer.name).$promise.then(function () {
      self.successMessage = 'GENERAL_SUCCESS_MESSAGE';
    }, function (error) {
      if (error.data.message) {
        self.errorMessage = error.data.message;
      } else {
        self.errorMessage = error;
      }
    });
  };

  self.updateLogo = function () {
    $customerService.updateLogo(self.customer.customerId, self.customer.logoAssetId);
  };
}]);
