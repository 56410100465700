'use strict';

angular.module('MoreAppsServices').factory('$customerRolesService', ['moreConstants', '$resource', '$translate', function (moreConstants, $resource, $translate) {

  const ALL_PERMISSIONS = [...moreConstants.ALLOWED_ACCOUNT_PERMISSIONS, ...moreConstants.ALLOWED_FORM_PERMISSIONS, ...moreConstants.ALLOWED_FOLDER_PERMISSIONS];

  const CustomerRoles = $resource(moreConstants.apiEndpointV2 + '/customers/:customerId/roles/:roleId', {}, {
    patch: {
      method: 'PATCH',
    }
  });

  return {
    isOwnerRole: (role) => role.permissions.find(permission => 'TRANSFER_OWNERSHIP' === permission),
    getRoleInfo: (role) => {
      const sortedPermissions = role.permissions
        .sort((a, b) => ALL_PERMISSIONS.indexOf(a) - ALL_PERMISSIONS.indexOf(b))
        .filter(x => x !== 'CUSTOMER_USER')
        .map(x => $translate.instant(x));
      if (sortedPermissions.length === 0) {
        sortedPermissions.push($translate.instant('NONE'));
      }
      return `<div class="pa-m">
          <p class="fs-l bold">${$translate.instant('PERMISSIONS')}</p>
          ${sortedPermissions.map(p => `<p class="ma-xs"><span class="mr-s">-</span>${p}</p>`).join('\n')}
        </div>`;
    },
    getRoles: customerId => CustomerRoles.query({customerId}).$promise,
    patchRole: (customerId, roleId, role) => CustomerRoles.patch({customerId, roleId}, role).$promise,
    saveRole: (customerId, role) => CustomerRoles.save({customerId}, role).$promise,
    deleteRole: (customerId, roleId) => CustomerRoles.delete({customerId, roleId}).$promise,
  };
}]);
