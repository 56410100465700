'use strict';

angular.module('MoreGrid').directive('booleanFilter', function () {
  return {
    restrict: 'E',
    template: '<select class="form-control" data-ng-model="selectedValue" data-ng-change="selectValue(selectedValue)">' + '<option></option>' + '<option value="true" data-translate="CHECKED"></option>' + '<option value="false" data-translate="UNCHECKED"></option>' + '</select>',
    scope: {
      onChange: '&',
      id: '@'
    },
    link: function (scope) {

      scope.selectValue = function (value) {
        if (value === 'true') {
          scope.onChange({ id: scope.id, value: true, type: 'boolean' });
        } else if (value === 'false') {
          scope.onChange({ id: scope.id, value: false, type: 'boolean' });
        } else {
          scope.onChange({ id: scope.id, value: undefined, type: 'boolean' });
        }
      };
    }
  };
});