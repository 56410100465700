'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', function ($stateProvider, stateConfig) {

  $stateProvider.state('portal.customers.access', {
    url: '/access',
    abstract: true,
    template: require('../../modules/UserManagement/templates/groupmanagement.index.html'),
    controller: 'GroupManagementIndexCtrl',
    data: {
      pickerTemplate: require('../templates/portal/pickers/groups.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.customers.access.users', {
    url: '/users',
    access: stateConfig.defaultAccess,
    data: {
      viewContentTemplate: require('../../modules/UserManagement/templates/groupmanagement.users.html')
    }
  }).state('portal.customers.access.permissions', {
    url: '/permissions',
    access: stateConfig.defaultAccess,
    data: {
      viewContentTemplate: require('../../modules/UserManagement/templates/groupmanagement.permissions.html')
    }
  });
}]);
