'use strict';

angular.module('UserManagement').controller('EditGroupCtrl', ['$scope', '$rootScope', '$state', '$customerGroupsService', '$modalService', 'group', function ($scope, $rootScope, $state, $customerGroupsService, $modalService, group) {

  const self = this;

  self.saving = false;
  self.group = group;
  self.updateName = updateName;
  self.removeGroup = removeGroup;

  function updateName () {
    $customerGroupsService.patch($state.params.customerId, {id: group.id, name: group.name}).then((group) => {
      $rootScope.$broadcast('group.updated', group);
    });
  }

  function removeGroup () {
    $modalService.deleteModal({
      title: 'USER_MANAGEMENT_GROUP_REMOVE_TITLE',
      template: require('../templates/usermanagement.group.remove.html'),
      data: {
        groupName: group.name
      }
    }).then((result) => {
      if (!result) {
        return;
      }
      $customerGroupsService.removeGroup($state.params.customerId, group.id).then(() => {
        $rootScope.$broadcast('group.removed', group);
        $scope.$close();
      }).catch(() => {
        $modalService.alertModal({
          title: 'USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE',
          message: 'USER_MANAGEMENT_GROUP_REMOVE_FAILED'
        });
      });
    });
  }
}]);
