'use strict';

angular.module('MoreAppsServices').factory('$submissionService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const Submission = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/submissions/:submissionId', {}, {});

  const SubmissionFiles = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/submissions/:submissionId/files', {}, {});

  var Submissions = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/submissions/:action/:subAction', { 'formId': '@formId' }, {
    filter: {
      method: 'POST',
      params: {
        action: 'filter'
      }
    }
  });

  var ExportV2 = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/submissions/export/:action', { 'formId': '@formId' }, {
    scheduleExport: {
      method: 'POST',
      params: {}
    },
    getFields: {
      method: 'GET',
      params: {
        action: 'fields'
      },
      isArray: true
    }
  });

  var SubmissionsResend = $resource(moreConstants.apiEndpoint + '/customers/:customerId/submissions/resend/:registrationId', { 'customerId': '@customerId', 'registrationId': '@registrationId' }, {
    'resend': {
      method: 'PUT'
    }
  });

  return {
    getSubmission: (customerId, formId, submissionId) => Submission.get({customerId, formId, submissionId}),
    getSubmissionFiles: (customerId, formId, submissionId) => SubmissionFiles.get({customerId, formId, submissionId}).$promise,
    filterSubmissions: function (customerId, formId, page, submissionFilter) {
      return Submissions.filter({ customerId: customerId, formId: formId, subAction: page }, submissionFilter);
    },
    resendSubmission: (customerId, registrationId) => SubmissionsResend.resend({customerId, registrationId}),
    getExportFields: (customerId, formId) => ExportV2.getFields({customerId, formId}).$promise,
    scheduleExport: (customerId, formId, exportRequest) => {
      return ExportV2.scheduleExport({
        customerId,
        formId,
      }, exportRequest).$promise;
    }
  };
}]);
