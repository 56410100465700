'use strict';

angular.module('Billing').directive('pricingPlan', ['$translate', function ($translate) {
  const FEATURE_PREFIX = `FEATURE_`;
  const HIDDEN_FEATURES = ['UNLIMITED_USERS', 'NO_EXTRA_COSTS', 'THEMES'];

  return {
    restrict: 'A',
    link: function (scope) {
      scope.getUsersLabel = plan => {
        const unlimited = plan.features.indexOf('UNLIMITED_USERS') !== -1;
        return $translate.instant(unlimited ? 'PLAN_AMOUNT_OF_USERS_UNLIMITED' : 'PLAN_AMOUNT_OF_USERS_ONE');
      };

      scope.getSubmissionsLabel = plan => {
        const submissionsItem = plan.items.find(item => item.type === 'submission');
        const amount = submissionsItem ? submissionsItem.tiers[0].upTo : 0;
        return $translate.instant('PLAN_AMOUNT_OF_SUBMISSIONS', {amount: amount});
      };

      scope.getPricePerExtraSubmissionsLabel = plan => $translate.instant(`PLAN_${plan.name.toUpperCase()}_PRICE_PER_EXTRA_SUBMISSION`);
      scope.getTreesLabel = plan => $translate.instant('PLAN_AMOUNT_OF_TREES', {amount: plan.trees});
      scope.getFeaturesLabel = plan => $translate.instant(`PLAN_${plan.name.toUpperCase()}_FEATURES_HEADER`);

      scope.getFeatureList = (plans, planIndex) => {
        let finalList = plans[planIndex].features;
        if (planIndex !== 0) {
          const previousList = plans[planIndex - 1].features;
          finalList = finalList.filter(x => !previousList.includes(x));
        }
        return finalList
          .filter(x => HIDDEN_FEATURES.indexOf(x) === -1)
          .map(x => `${FEATURE_PREFIX}${x}`)
          .map($translate.instant)
          .join(',');
      };
    }
  };
}]);
