'use strict';

angular.module('customerPortal').controller('OnboardingCtrl', ['$scope', '$state', '$emailValidationService', '$timeout', function ($scope, $state, $emailValidationService, $timeout) {

  $scope.loading = true;

  $timeout(() => {
    $emailValidationService.checkToken($scope.$state.params.token).$promise.then(() => {
      $state.go('email-validated');
    }, () => {
      $scope.loading = false;
      $scope.success = false;
    });
  }, 1000);
}]);
