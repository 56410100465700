'use strict';

const MORE_BASE = {
    PARTNER: 'PARTNER',
    MANAGE_CREDITS: 'MANAGE_CREDITS',
    MANAGE_DATA_SOURCES: 'MANAGE_DATA_SOURCES',
    MANAGE_FORMS: 'MANAGE_FORMS',
    MANAGE_TEMPLATES: 'MANAGE_TEMPLATES',
    MANAGE_WEBHOOKS: 'MANAGE_WEBHOOKS',
    MANAGE_SENDING_DOMAIN: 'MANAGE_SENDING_DOMAIN',
    MANAGE_TASKS: 'MANAGE_TASKS',
    MANAGE_REGISTRATIONS: 'MANAGE_REGISTRATIONS',
    MANAGE_ACCOUNT: 'MANAGE_ACCOUNT',
    TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
    CREATE_FOLDER: 'CREATE_FOLDER',
    CREATE_FORM: 'CREATE_FORM',
    ADD_FORM: 'ADD_FORM',
    UPDATE_FOLDER: 'UPDATE_FOLDER',
    UPDATE_FORM: 'UPDATE_FORM',
    ARCHIVE_FORM: 'ARCHIVE_FORM',
    READ_SUBMISSION: 'READ_SUBMISSION',
    READ_TASK: 'READ_TASK',
    MANAGE_USERS: 'MANAGE_USERS',
    MANAGE_SSO: 'MANAGE_SSO',
    READ_THEME: 'READ_THEME',
    CREATE_THEME: 'CREATE_THEME',
    UPDATE_THEME: 'UPDATE_THEME',
    DELETE_THEME: 'DELETE_THEME',
    READ_WEBHOOK: 'READ_WEBHOOK',
    CREATE_WEBHOOK: 'CREATE_WEBHOOK',
    UPDATE_WEBHOOK: 'UPDATE_WEBHOOK',
    DELETE_WEBHOOK: 'DELETE_WEBHOOK',
    segments: {
        AUTOMOTIVE: 'AUTOMOTIVE',
        CONSTRUCTION: 'CONSTRUCTION',
        FACILITY: 'FACILITY',
        FINANCIAL: 'FINANCIAL',
        TRADE_AND_INDUSTRY: 'TRADE_AND_INDUSTRY',
        GOVERNMENT: 'GOVERNMENT',
        HEALTH_CARE: 'HEALTH_CARE',
        INSTALLATION: 'INSTALLATION',
        OTHER: 'OTHER'
    },
    PDF_LOGO: 'PDF_LOGO',
    MANUAL_DATASOURCES: 'MANUAL_DATASOURCES',
    AUTOMATIC_DATASOURCES: 'AUTOMATIC_DATASOURCES',
    HOOKS: 'HOOKS',
    MULTIPLE_PDF: 'MULTIPLE_PDF',
    ADVANCED_PDF: 'ADVANCED_PDF',
    THEMES: 'THEMES',
    WIDGETS: 'WIDGETS',
    ADVANCED_USER_MANAGEMENT: 'ADVANCED_USER_MANAGEMENT',
    SEARCH: 'SEARCH',
    SINGLE_SIGN_ON: 'SINGLE_SIGN_ON',
    UNLIMITED_USERS: 'UNLIMITED_USERS',
    CUSTOM_TEMPLATES: 'CUSTOM_TEMPLATES',
    WEBHOOKS: 'WEBHOOKS',
    SENDING_DOMAIN: 'SENDING_DOMAIN',
    ALLOWED_ACCOUNT_PERMISSIONS: [
        'CUSTOMER_USER',
        'CREATE_FOLDER',
        'CREATE_FORM',
        'MANAGE_CREDITS',
        'MANAGE_DATA_SOURCES',
        'MANAGE_FORMS',
        'MANAGE_TASKS',
        'MANAGE_REGISTRATIONS',
        'MANAGE_USERS',
        'MANAGE_SSO',
        'MANAGE_SENDING_DOMAIN',
        'MANAGE_WEBHOOKS',
        'MANAGE_TEMPLATES',
    ],
    ALLOWED_FORM_PERMISSIONS : [
        'CREATE_SUBMISSION',
        'READ_SUBMISSION',
        'DELETE_SUBMISSION',
        'CREATE_TASK',
        'READ_TASK',
        'UPDATE_TASK',
        'DELETE_TASK',
        'UPDATE_FORM',
        'ARCHIVE_FORM',
    ],
    ALLOWED_FOLDER_PERMISSIONS : [
        'ADD_FORM',
        'REMOVE_FORM',
        'UPDATE_FOLDER',
        'DELETE_FOLDER',
    ],
    TRIAL_TIME_LIMIT_IN_DAYS: 30,
    TRIAL_SUBMISSION_LIMIT: 75
};

angular.module('MoreAppsConstants', []);

angular.module('MoreAppsConstants').constant('moreConstants', {...MORE_BASE, ...window.MORE_ENV});
