'use strict';

angular.module('MoreAppsServices').factory('$customerAuthenticationTokenService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerAuthenticationToken = $resource(moreConstants.apiEndpoint + '/customers/:customerId/authenticationtoken/:action', {}, {
    revoke: {
      method: 'POST',
      params: {
        action: 'revoke'
      }
    }
  });

  return {
    createAuthenticationToken: function (customerId, formId) {
      var request = { formId: formId };
      return CustomerAuthenticationToken.save({ customerId: customerId }, request);
    },
    getAuthenticationTokens: function (customerId) {
      return CustomerAuthenticationToken.query({ customerId: customerId });
    },
    revokeAuthenticationTokens: function (customerId, formId) {
      var request = { formId: formId };
      return CustomerAuthenticationToken.revoke({ customerId: customerId }, request);
    }
  };
}]);
