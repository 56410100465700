'use strict';

angular.module('MoreAppsServices').factory('$oauthAuthorizeService', ['moreConstants', '$resource', '$customerServiceAccountsService', function (moreConstants, $resource, $customerServiceAccountsService) {

  let windowObjectReference = null;
  let previousUrl = null;

  const OAuthAuthorize = $resource(moreConstants.apiEndpoint + '/oauth/authorize/:customerId', {}, {
    buildUrl: {
      method: 'GET',
      transformResponse: function (data) {
        return { url: data };
      }
    }
  });
  const CurrentOAuthAuthorization = $resource(moreConstants.apiEndpoint + '/oauth/authorize/:customerId/current', {}, {});

  const openOAuthWindow = (response, successCallback) => {
    const openSignInWindow = (url, name) => {
      // remove any existing event listeners
      window.removeEventListener('message', receiveMessage);

      const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

      if (windowObjectReference === null || windowObjectReference.closed) {
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        windowObjectReference.focus();
      }

      // add the listener for receiving a message from the popup
      window.addEventListener('message', event => receiveMessage(event, successCallback), false);
      previousUrl = url;
    };

    openSignInWindow(response.url, 'MoreApp - OAuth2');

    const receiveMessage = (event, cb) => {
      if (event.origin !== window.location.origin && event.data.messageType !== 'com.moreapp.oauth') {
        return;
      }

      cb(event.data);
    };
  }

  return {
    isAuthenticated: function (customerId) {
      return CurrentOAuthAuthorization.get({ customerId: customerId });
    },
    buildOAuthUrl: function (customerId) {
      return OAuthAuthorize.buildUrl({ customerId: customerId });
    },
    createServiceAccount: function (customerId, provider, successCallback, errorCallback) {
      $customerServiceAccountsService.saveServiceAccount(customerId, provider, window.location.origin + '/oauth2-callback').$promise.then((response) => {
        openOAuthWindow(response, successCallback);
      }, errorCallback);
    },
    reconnectServiceAccount: function (customerId, account, successCallback, errorCallback) {
      $customerServiceAccountsService.reconnect(customerId, account, window.location.origin + '/oauth2-callback').$promise.then((response) => {
        openOAuthWindow(response, successCallback);
      }, errorCallback);
    }
  };
}]);
