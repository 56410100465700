'use strict';

angular.module('MoreDirectives').directive('moreTopMenu', ['$state', '$rootScope', 'localStorageService', function ($state) {
  return {
    restrict: 'E',
    template: require('../../../MoreDirectives/templates/more-top-menu.html'),
    scope: {
      items: '=',
      callback: '&',
    },
    link: function (scope, element, attrs) {
      scope.$watch('items', function () {
        init();
      });

      function init() {
          setDefaultValues();
          setActiveState();
      }

      init();

      scope.selectItem = function (item) {
        if (!scope.activeItem) {
          return;
        }
        var parentStateIndex = $state.current.name.indexOf('.' + scope.activeItem.state);
        scope.activeItem = item;

        if (!scope.items[0].state) {
          scope.$emit('controlMode.changed', item.name.toLowerCase());
        } else if (attrs.callback) {
          scope.callback({ item: item });
        } else if (parentStateIndex === -1) {
          $state.go('^.' + item.state);
        } else {
          $state.go($state.current.name.substr(0, parentStateIndex) + '.' + item.state);
        }
      };

      scope.$on('top-menu.activate-item', function (event, item) {
        scope.selectItem(item);
      });

      ////////
      function setDefaultValues() {
        if (scope.simpleItems) {
          scope.controlTabs = scope.simpleItems;
        } else {
          scope.controlTabs = scope.items;
        }
      }

      function setActiveState() {
        scope.controlTabs.forEach(function (item) {
          if ($state.current.name.indexOf('.' + item.state) !== -1) {
            scope.activeItem = item;
          }
        });
        if (!scope.activeItem) {
          scope.activeItem = scope.controlTabs[0];
        }
      }
    }
  };
}]);
