'use strict';

angular.module('WidgetPropertyPane').directive('optionsProperty', function () {
  return {
    restrict: 'A',
    scope: true,
    controller: ['$scope', function ($scope) {

      $scope.options = $scope.formFieldProperties[$scope.widgetProperty.key] || [];

      $scope.addOption = function () {
        $scope.options.push({});
      };

      $scope.removeOption = function (option) {
        var index = $scope.options.indexOf(option);
        if (index > -1) {
          $scope.options.splice(index, 1);
        }
      };
    }]
  };
});

angular.module('WidgetPropertyPane').directive('optionProperty', ['$moreDataNameService', function ($moreDataNameService) {
  return {
    restrict: 'A',
    scope: true,
    controller: ['$scope', function ($scope) {

      var matchesId = function (id, value) {
        return (id === value || !id) && (!!value || id === value);
      };

      $scope.$watch('option.name', function (newValue, oldValue) {
        //Because of MORE-2339
        var oldLegacyId = $moreDataNameService.asDataName(oldValue);
        if (matchesId($scope.option.id, oldLegacyId) || matchesId($scope.option.id, oldValue)) {
          $scope.option.id = newValue;
        }
      });
    }]
  };
}]);