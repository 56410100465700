'use strict';

angular.module('FolderPicker').directive('folderPicker', ['$customerFolderService', '$state', '$userStateService', '$rootScope', '$usersContextService', '$timeout', function ($customerFolderService, $state, $userStateService, $rootScope, $usersContextService, $timeout) {

  return {
    restrict: 'E',
    template: require('../templates/folder-picker.html'),
    scope: {
      formFilter: '=?',
      selectFolderCallback: '&',
      focusFolderCallback: '&',
      selectFormCallback: '&',
      addFolderCallback: '&',
      addFormCallback: '&'
    },
    link: function (scope) {
      const loadFolders = customerId => {
        customerId = customerId || $state.params.customerId;
        if ($usersContextService.canManageForms() || $usersContextService.canManageTasks() || $usersContextService.canManageRegistrations()) {
          scope.folders = $customerFolderService.getFolders(customerId);
        }
        scope.canCreateFolders = $usersContextService.canCreateFolders();
      };

      loadFolders();

      scope.$on('folders.reload', function () {
        loadFolders($state.params.customerId);
      });

      scope.$on('folder.added', function (event, data) {
        data.folder.$open = true;
        scope.folders.push(data.folder);
        $userStateService.setFolderId(data.folder.id);
        scope.selectFolderCallback({folder: data.folder});
      });

      scope.$on('folder.removed', function (event, data) {
        var index = scope.folders.indexOf(data.folder);
        scope.folders.splice(index, 1);
        var userState = $userStateService.getState();
        if (userState.folderId === data.folder.id) {
          $rootScope.$broadcast('form.removed', {viewId: userState.viewId, collectionId: userState.collectionId});
          $userStateService.setFolderId(null);
        }
      });

      scope.$on('folder.changed', (event, folderId) => {
        $userStateService.setFormId(null);

        scope.folders.forEach((folder) => {
          if (folder.id === folderId) {
            folder.$open = scope.formFilter ? true : !folder.$open;
          } else {
            folder.$open = false;
            folder.activeForm = null;
          }
        });

        if (scope.formFilter) {
          scope.formFilter = '';
        }
      });

      scope.canManageForms = $usersContextService.canManageForms();
    }
  };
}]);
