'use strict';

angular.module('FormManagement.tasks').controller('FormManagementTaskDetailCtrl', ['$formTaskService', '$scope', '$customerUserService', '$rootScope', 'task', 'formId', function ($formTaskService, $scope, $customerUserService, $rootScope, task, formId) {

  $scope.task = task;
  $scope.users = $customerUserService.getUsers($scope.$state.params.customerId);

  init();

  ////////
  function init () {
    task.$promise.then(function (data) {
      var userFulfilmentMap = {};
      data.users.forEach(function (userId) {
        userFulfilmentMap[userId] = { userId: userId };
      });
      data.fulfilments.forEach(function (fulfilment) {
        userFulfilmentMap[fulfilment.userId].fulfilment = fulfilment;
      });

      $scope.users.$promise.then(function (users) {
        users.forEach(function (user) {
          if (userFulfilmentMap[user.id]) {
            userFulfilmentMap[user.id].username = user.username;
          }
        });
        $scope.userFulfilments = Object.keys(userFulfilmentMap).map(function (key) {
          return userFulfilmentMap[key];
        });
      });

      return data;
    });
  }

  $scope.complete = function () {
    $scope.completing = true;
    $formTaskService.complete($scope.$state.params.customerId, formId, $scope.task.id).$promise.then(function () {
      $scope.completing = false;
      $scope.task.status = 'COMPLETED';
      $rootScope.$broadcast('INSTRUCTION_COMPLETED', $scope.task.id);
    });
  };

  $scope.revoke = function () {
    $scope.revoking = true;
    $formTaskService.revoke($scope.$state.params.customerId, formId, $scope.task.id).$promise.then(function () {
      $scope.revoking = false;
      $scope.task.status = 'REVOKED';
      $rootScope.$broadcast('INSTRUCTION_REVOKED', $scope.task.id);
    });
  };

  $scope.getFulfilmentStatus = function (userFulfilment) {
    if (userFulfilment.fulfilment && userFulfilment.fulfilment.registrationId) {
      return 'COMPLETED';
    } else if (userFulfilment.fulfilment && !userFulfilment.fulfilment.registrationId) {
      return 'DECLINED';
    } else if ($scope.task.status === 'REVOKED') {
      return 'TASK_REVOKED';
    } else if ($scope.task.status === 'COMPLETED') {
      return 'TASK_COMPLETED';
    }
    return 'PENDING';
  };

  $scope.getFulfilmentIcon = function (userFulfilment) {
    if (userFulfilment.fulfilment) {
      if (userFulfilment.fulfilment.registrationId) {
        return 'fulfilled fa-check-circle';
      } else {
        return 'fa-ban';
      }
    } else if (!userFulfilment.fulfilment) {
      if ($scope.task.status === 'REVOKED' || $scope.task.status === 'COMPLETED') {
        return 'fa-ban';
      } else {
        return 'fa-circle-thin';
      }
    }
  };
}]);
