'use strict';
angular.module('more.ui.form').directive('freemarkerTemplate', function ($freemarkerHelper) {

  return {
    restrict : 'A',
    template : '<span compile="renderedTemplate"></span>',
    require : 'ngModel',
    scope : {
      ngModel : '=',
      freemarkerTemplate : '=',
      oneTime : '='
    },
    link : function (scope) {
      scope.renderedTemplate = '';

      var lastKnownModel = scope.ngModel;
      var lastKnownTemplate = scope.freemarkerTemplate;

      var executeRenderTemplate = function () {
        if (!lastKnownTemplate || !lastKnownModel) {
          return;
        }

        scope.renderedTemplate = $freemarkerHelper.interpolate(lastKnownTemplate, lastKnownModel, 'ngModel');
      };

      executeRenderTemplate();

      if (scope.oneTime) {
        return;
      }

      scope.$watch('ngModel', function (newValue) {
        if (newValue !== lastKnownModel) {
          lastKnownModel = newValue;
          executeRenderTemplate();
        }
      });
      scope.$watch('freemarkerTemplate', function (newValue) {
        if (newValue !== lastKnownTemplate) {
          lastKnownTemplate = newValue;
          executeRenderTemplate();
        }
      });

    }
  };
});
