'use strict';

angular.module('FormManagement.submissions').controller('FormManagementPipelineStatusCtrl', ['$scope', '$pipelineService', '$sidePanel', function ($scope, $pipelineService, $sidePanel) {
  var self = this;
  var registrationId = $scope.registration.id;
  var customerId = $scope.registration.info.customerId;

  self.openPipelineDetail = openPipelineDetail;

  ////////
  function openPipelineDetail () {
    $sidePanel.open({
      template: require('../templates/formmanagement.submissions.pipelinedetail.html'),
      resolve: {
        pipeline: function () {
          return $pipelineService.getPipeline(customerId, $scope.registration.info.formId, registrationId).$promise;
        },
        customerId: function () {
          return customerId;
        },
        registrationId: function () {
          return registrationId;
        },
        formId: function () {
          return $scope.registration.info.formId;
        }
      },
      controller: 'FormManagementPipelineDetailCtrl as ctrl'
    });
  }
}]);
