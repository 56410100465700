'use strict';

angular.module('AdminServices').factory('$adminTreeService', ['moreConstants', '$resource', function (moreConstants, $resource) {

    const Trees = $resource(moreConstants.apiEndpoint + '/admin/trees', {}, {});

    return {
        overview: () => Trees.query()
    };
}]);
