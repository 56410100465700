'use strict';

angular.module('FormEditor').controller('WidgetListCtrl', ['$scope', '$state', '$widgetService', '$customerService', 'moreConstants', '$translate', '$window', '$featureHelper', '$modalService', function ($scope, $state, $widgetService, $customerService, moreConstants, $translate, $window, $featureHelper, $modalService) {

  const self = this;
  let favoriteWidgets = null;
  const proWidgets = getProWidgets();
  const defaultWidgets = getDefaultWidgets();

  self.widgetType = 'default';
  self.targetWidgets = defaultWidgets;
  self.setWidgetType = setWidgetType;

  init();

  ////////
  function init() {
    $featureHelper.hasFeature(moreConstants.WIDGETS).then(res => self.hasWidgetFeature = res);
    $scope.$on('savedFavorite', refreshFavorites);
    $scope.$on('deletedFavorite', refreshFavorites);
  }

  function setWidgetType (type) {
    self.widgetType = type;

    if (type === 'pro') {
      if (!self.hasWidgetFeature) {
        return $modalService.upgradePlanModal({
          title: 'FEATURE_WIDGETS',
          message: 'FEATURE_WIDGETS_MESSAGE',
          imgSrc: 'https://cdn.moreapp.com/features/en/feature-marketplace-widgets.png'
        });
      }

      self.targetWidgets = proWidgets;
    } else if (type === 'default') {
      self.targetWidgets = defaultWidgets;
    } else if (type === 'favorite') {
      if (favoriteWidgets === null) {
        refreshFavorites();
      }
      self.targetWidgets = favoriteWidgets;
    }
  }

  function refreshFavorites() {
    if (favoriteWidgets === null) {
      favoriteWidgets = [];
    } else {
      favoriteWidgets.splice(0, favoriteWidgets.length);
    }
    $widgetService.getFavorites().$promise.then(data => computeFavoriteWidgets(data));
  }

  function applyChangesToWidget(widget, favoriteWidget) {
    const widgetProperties = widget.definition.properties;
    favoriteWidget.changes.forEach(change => {
      const property = widgetProperties.find(widgetProperty => widgetProperty.key === change.propertyKey);
      if (property) {
        property.defaultValue = change.value;
      } else {
        // Set data for migrated or removed properties, prevent losing data on rollback to previous widget version.
        widgetProperties.push({ key: change.propertyKey, defaultValue: change.value});
      }
    });
    widget.name = favoriteWidget.name;
    widget.favorite = true;
    widget.favoriteId = favoriteWidget.id;
  }

  function computeFavoriteWidgets(widgetFavorites) {
    for (var i = 0; i < widgetFavorites.length; i++) {
      var favorite = widgetFavorites[i];
      for (var j = 0; j < $scope.widgets.length; j++) {
        if ($scope.widgets[j].info.uid === favorite.widgetUid) {
          var clonedWidget = angular.copy($scope.widgets[j]);
          applyChangesToWidget(clonedWidget, favorite);
          favoriteWidgets.push(clonedWidget);
        }
      }
    }
  }

  function getDefaultWidgets() {
    return $scope.widgets.filter(widget => widget.category === 'DEFAULT');
  }

  function getProWidgets() {
    return $scope.widgets.filter(widget => widget.category !== 'DEFAULT');
  }
}]);
