'use strict';

angular.module('FormEditor').controller('FormEditorIndexCtrl', ['$scope', '$rootScope', 'formVersion', 'form', 'formService', '$validationUtil', '$formVersionUtils', 'widgets', '$widgetPropertyPaneService', '$sidePanel', 'exitState', '$modalService', '$hookService', '$moreDataNameService', '$translate', '$q', '$uuidService', '$formVersionUtilityService', '$formVersionValidationService', '$customerAuthenticationTokenService', '$window', '$timeout', 'moreConstants', function ($scope, $rootScope, formVersion, form, formService, $validationUtil, $formVersionUtils, widgets, $widgetPropertyPaneService, $sidePanel, exitState, $modalService, $hookService, $moreDataNameService, $translate, $q, $uuidService, $formVersionUtilityService, $formVersionValidationService, $customerAuthenticationTokenService, $window, $timeout, moreConstants) {
  var customerId = $scope.$state.params.customerId;
  var touchedViews = {};
  var deploymentHash = null;
  $scope.headerTemplate = require('../../../shared/templates/navbar.html');

  $scope.toolBoxTemplates = {
    menu_email : require('../../../shared/templates/builder/toolbox/menu_email.html'),
    menu_hooks : require('../../../shared/templates/builder/toolbox/menu_integrations.html'),
    menu_rules : require('../../../shared/templates/builder/toolbox/menu_rules.html'),
    menu_settings : require('../../../shared/templates/builder/toolbox/menu_settings.html'),
    menu_widgets : require('../../../shared/templates/builder/toolbox/menu_widgets.html')
  };

  const definitionChanged = () => getDeploymentHash(formVersion) !== deploymentHash;

  const unsavedChangesConfirmation = () => window.confirm($('<div/>').html($translate.instant('UNSAVED_CHANGES')).text());
  const buildSubForm = () => ({
    uid: $uuidService.uid(),
    fields: [],
    rules: [],
    triggers: [$formVersionUtils.buildSimpleMailTriggerForSubform()],
    settings: {interaction: 'MANUAL_UPLOAD', saveMode: 'SAVE_AND_CLOSE_ONLY', searchSettings: {}}
  });
  const validateFormVersion = draftFormVersion =>
    $formVersionValidationService.validate(customerId, draftFormVersion.formId, getCleanFormVersion(draftFormVersion)).$promise
      .then($formVersionValidationService.resetPublicationErrors)
      .catch(validationErrors => {
        if (validationErrors && validationErrors.data && validationErrors.data.errors) {
          const errors = getValidationErrors(validationErrors.data.errors);
          let fieldUids = [];
          errors.forEach(error => (error.fields || []).forEach(field => fieldUids.push(field.field.uid)));
          $formVersionValidationService.resetPublicationErrors();
          $formVersionValidationService.enhanceFieldsWithPublicationErrors(fieldUids);
        }
      });
  const pushFormOnViewStack = nextView => {
    const targetElement = $('.view-editor-wrapper');
    const totalOffset = targetElement.scrollTop();
    targetElement.scrollTop(0);
    const scroll = totalOffset - targetElement.offset().top;
    $scope.viewStack.push({
      view : nextView,
      viewId : $scope.$state.params.formId,
      data : {},
      scroll
    });
    $scope.targetView = nextView;
  };

  const init = () => {
    $scope.viewStack = [];
    $scope.showPublicationErrors = $scope.$state.params.showPublicationErrors;
    $scope.finalized = false;
    $scope.formVersion = formVersion;
    $scope.saving = {publish: false, exit: false, save: false};

    if (formVersion.meta.status === 'FINAL') {
      $modalService.alertModal({title: 'FORM_FINALIZED_TITLE', message: 'FORM_FINALIZED_MESSAGE'});
      $scope.finalized = true;
    }

    if (customerId) {
      $scope.integrationConfigurations = formVersion.integrations;
      $scope.allIntegrations = $hookService.getAllActiveHooks(customerId);
      if ($scope.$state.params.openWithValidationErrors) {
        validateFormVersion(getCleanFormVersion(formVersion));
      }
    }
    $scope.controlMode = 'menu_widgets';
    $scope.widgets = widgets;
    $scope.widgetsPromise = widgets.$promise;

    $scope.deployed = false;

    if (form.publishedVersion && form.publishedVersion.formVersion) {
      $scope.deployed = true;
      formService.getFormVersion(customerId, form.id, form.publishedVersion.formVersion).$promise.then(publishedFormVersion => {
        $scope.knownFieldUids = $formVersionUtilityService.allFieldUids(publishedFormVersion);
      });
    }

    $scope.form = form;

    deploymentHash = getDeploymentHash(formVersion);
    pushFormOnViewStack(formVersion);

    $scope.$on('$destroy', () => {
      window.onbeforeunload = undefined;
    });

    window.onbeforeunload = event => {
      if (definitionChanged()) {
        const message = $translate.instant('UNSAVED_CHANGES');
        if (typeof event === 'undefined') {
          event = window.event;
        }
        if (event) {
          event.returnValue = message;
        }
        return message;
      }
    };

    $scope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
      const urlChanged = toState.url && fromState.url && toState.url !== fromState.url;
      if (urlChanged && definitionChanged()) {
        const answer = unsavedChangesConfirmation();
        if (!answer) {
          event.preventDefault();
        }
      }
    });

    $scope.$on('controlMode.changed', ($event, newControlMode) => {
      $scope.controlMode = newControlMode;
    });

    $scope.$on('more-form.prepare-remove-field', (event, field) => {
      $scope.$broadcast('more-form.prepare-remove-field-accept', field);
    });

    watchForValidationUpdates();
    loadDirectFormUrl();
  };

  const setUniqueDataName = (item, view) => {
    if (item.properties.data_name === undefined) {
      return;
    }
    item.properties.data_name = $moreDataNameService.uniquify(view.fields, item);
  };

  $scope.exitBuilder = () => $scope.$state.go(exitState);

  $scope.saveAndPreview = () => {
    $scope.saving.preview = true;
    deploymentHash = getDeploymentHash(formVersion);
    formService.saveFormVersion(customerId, form.id, formVersion.id, getCleanFormVersion(formVersion)).$promise.then(formVersionDto => {
      validateFormVersion(formVersionDto);
    }).finally(() => {
      $scope.saving.preview = false;
      $window.open($scope.formPreviewUrl, '_blank');
    });
  };

  $scope.save = () => {
    $scope.saving.save = true;
    deploymentHash = getDeploymentHash(formVersion);
    formService.saveFormVersion(customerId, form.id, formVersion.id, getCleanFormVersion(formVersion)).$promise.then(formVersionDto => {
      validateFormVersion(getCleanFormVersion(formVersionDto));
    }).finally(() => {
      $timeout(() => $scope.saving.save = false, 500);
    });
  };

  $scope.saveAndClose = () => {
    $scope.saving.exit = true;
    deploymentHash = getDeploymentHash(formVersion);
    formService.saveFormVersion(customerId, form.id, formVersion.id, getCleanFormVersion(formVersion)).$promise.then(() => {
      $scope.$state.go(exitState);
    }).finally(() => {
      $scope.saving.exit = false;
    });
  };

  $scope.saveAndPublish = () => {
    $scope.saving.publish = true;
    deploymentHash = getDeploymentHash(formVersion);
    formService.saveFormVersion(customerId, form.id, formVersion.id, getCleanFormVersion(formVersion)).$promise
      .then(() => formService.finalizeFormVersion(customerId, form.id, formVersion.id).$promise)
      .then(() => {
        window.onbeforeunload = undefined; // form is final, stop watching for changes
        return formService.publishFormVersion(customerId, form.id, formVersion.id).$promise
          .catch(err => $q.reject(err.status === '400' ? err : new Error('publish_server_error')));
    }).then(() => {
      $scope.$state.go(exitState);
    }).catch(err => {
      if (err && err.data && err.data.errors) {
        let errors = getValidationErrors(err.data.errors);
        $formVersionValidationService.showValidationModal(errors);
        let fieldUids = [];
        errors.forEach(error => {
          if (error.fields) {
            error.fields.forEach(field => fieldUids.push(field.field.uid));
          }
        });
        $formVersionValidationService.resetPublicationErrors();
        $formVersionValidationService.enhanceFieldsWithPublicationErrors(fieldUids);
      } else if (err.message === 'publish_server_error') {
        $modalService.alertModal({title: 'PUBLISH_FAILED_TITLE', message: 'PUBLISH_FAILED_MESSAGE'});
        $scope.$state.go(exitState);
      }
    }).finally(() => {
      $scope.saving.publish = false;
    });
  };

  $scope.showWidgetForViewType = item => item.info.type === 'widget';

  $scope.popNavigation = () => {
    const popped = $scope.viewStack.pop();
    const targetElement = $('.view-editor-wrapper');
    const scroll = popped.scroll + targetElement.offset().top;
    targetElement.scrollTop(scroll);
    $scope.targetView = $scope.viewStack[$scope.viewStack.length - 1].view;
  };

  $scope.openSubForm = (properties, callback) => {
    let field = properties.field;
    if (!field.form) {
      field.form = buildSubForm();
    }
    touchedViews[field.form.uid] = field.form;
    pushFormOnViewStack(field.form);
    if (callback) {
      callback(field.form);
    }
  };

  $scope.addSubForm = (field, property, callback) => {
    const subform = buildSubForm();
    subform.meta = {name: field.label_text};
    field[property] = subform.uid;
    formVersion.fieldProperties[subform.uid] = subform;
    field.form = subform;
    touchedViews[subform.uid] = field.form;
    pushFormOnViewStack(field.form);
    if (callback) {
      callback(subform.uid);
    }
  };

  $scope.editField = field => {
    field.$$active = true;
    if (document.activeElement) {
      document.activeElement.blur();
    }

    $widgetPropertyPaneService.open({
      focusedWidget : field.$$widget,
      focusedFormField : field,
      targetView : $scope.targetView,
      formVersion : formVersion,
      knownFieldUids : $scope.knownFieldUids,
      deployed : $scope.deployed,
      callbacks : {
        addSubForm : $scope.addSubForm,
        openDetail : $scope.openSubForm,
        checkSubFormUsage : () => {}
      }
    }).finally(() => {
      field.$$active = false;
    });
  };

  $scope.widgetUid = widget => widget.info.uid;

  $scope.addToItemHtml = field => {
    const item = '${' + field.properties.data_name + '}';
    if (!$scope.targetView.settings.itemHtml) {
      $scope.targetView.settings.itemHtml = '';
    }
    if ($scope.targetView.settings.itemHtml.indexOf(item) > -1) {
      return;
    }
    $scope.targetView.settings.itemHtml += item + ' ';
  };

  const createNewWidget = widget => {
    const view = $scope.viewStack[$scope.viewStack.length - 1].view;
    const field = $formVersionUtils.transformWidgetToField(widget);
    setUniqueDataName(field, view);
    if (widget.fullkey === 'com.moreapps:detail' || widget.fullkey === 'com.moreapps:pin') {
      preventLinkedSubformRecursion(field);
    }
    return {viewId: view.uid, field};
  };

  $scope.insertWidget = widget => {
    const {viewId, field} = createNewWidget(widget);
    $scope.$broadcast('more-form.add-field', {viewId, field, widget});
  };

  $scope.buildNewWidget = widget => createNewWidget(widget).field;

  function preventLinkedSubformRecursion (widgetField) {
    const propsWithTargetFormId = JSONPath({path: '$..target_form_id^', json: widgetField.properties});
    propsWithTargetFormId.forEach(properties => {
      const foundReferenceToItself = propsWithTargetFormId.find(p => p.target_form_id === $scope.targetView.uid);
      const targetFormId = properties.target_form_id;
      if (foundReferenceToItself) {
        // Prevent recursion by 'breaking the chain'. Copy the subform as 'embedded' and delete the formId reference
        properties.form = $formVersionUtilityService.copySubform(properties.form);
        delete properties.target_form_id;
      } else if (formVersion.fieldProperties[targetFormId]) {
        // not sure if this case is needed, probably to continue after unlinking
        properties.form = formVersion.fieldProperties[targetFormId];
      } else {
        // favorite is created in another form, so needs to be added.
        formVersion.fieldProperties[targetFormId] = properties.form;
      }
    });
  }

  function watchForValidationUpdates () {
    $rootScope.$on('more.field.validated', (event, data) => {
      if (data.valid) {
        $formVersionValidationService.removeErrorFromField(data.fieldId);
      } else {
        $formVersionValidationService.addErrorToField(data.fieldId);
      }
    });
  }

  function copyFormVersion(currentFormVersion) {
    const formVersionCopy = angular.copy(currentFormVersion);
    delete formVersionCopy.$resolved;
    delete formVersionCopy.$promise;
    delete formVersionCopy.meta;
    if (formVersionCopy.fields) {
      formVersionCopy.fields = formVersionCopy.fields.map(field => ({
        uid: field.uid,
        widget: field.widget,
        properties: field.properties
      }));
    }
    return formVersionCopy;
  }

  function getDeploymentHash (currentFormVersion) {
    const formVersionCopy = copyFormVersion(currentFormVersion);
    return $formVersionUtilityService.calculateDeploymentHash(angular.toJson(formVersionCopy));
  }

  function getCleanFormVersion (currentFormVersion) {
    const formVersionCopy = copyFormVersion(currentFormVersion);
    removeEmbeddedForms(formVersionCopy);
    return formVersionCopy;
  }

  function loadDirectFormUrl () {
    $customerAuthenticationTokenService.getAuthenticationTokens(customerId).$promise.then(tokens => {
      const token = tokens.find(token => token.formId === $scope.$state.params.formId);
      if (token) {
        const url = getDirectFormPreviewUrl(token);
        $scope.formPreviewUrl = `${url}/${$scope.$state.params.formVersionId}`;
      }
    });
  }

  function getDirectFormPreviewUrl(token) {
    let url = $scope.features.urls.webclient;
    if (url === 'https://app.moreapp.com') {
      url = moreConstants.webClientUrl; // override with localhost/develop/staging links
    }
    return url + '/form/' + token.token;
  }

  function getValidationError (error, availableFields) {
    const pathSegments = error.path.split('.');
    if (pathSegments[0] !== 'field') {
      return error;
    }
    const fields = [];
    const path = pathSegments.filter(segment => segment !== 'field');
    let subpath;
    path.forEach(segment => {
      // Find field for every subpath
      subpath = subpath ? subpath + '.' + segment : segment;
      const field = availableFields.find(f => f.fieldPath === subpath || f.field.uid === subpath);
      if (field) {
        fields.push(field);
      }
    });
    return {fields, message: error.message};
  }

  function getValidationErrors (validationErrors) {
    const availableFields = $formVersionUtilityService.findNestedFields(formVersion, formVersion);
    return validationErrors.map(error => getValidationError(error, availableFields));
  }

  function removeEmbeddedForms(obj) {
    // if properties contain both (embedded) form and linked form reference (target_form_id), drop the form and keep the reference
    // because either 'target_form_id' or 'form' should be set, never both
    for(let prop in obj) {
      if (prop === 'form' && obj.target_form_id) {
        delete obj.form;
      } else if (typeof obj[prop] === 'object') {
        removeEmbeddedForms(obj[prop]); // recursive
      }
    }
  }

  init();
}]);
