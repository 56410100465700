'use strict';

angular.module('FormEditor').controller('RulePanelCtrl', ['$scope', function ($scope) {

  var self = this;

  self.addCondition = function () {
    $scope.rule.conditions.push({});
  };

  self.removeCondition = function (rule, condition) {
    $scope.rule.conditions.splice($scope.rule.conditions.indexOf(condition), 1);
  };

  self.addAction = function () {
    $scope.rule.actions.push({});
  };

  self.removeAction = function (action) {
    $scope.rule.actions.splice($scope.rule.actions.indexOf(action), 1);
  };
}]);
