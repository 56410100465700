'use strict';

angular.module('MoreAppsServices').factory('$formVersionValidationService', ['moreConstants', '$resource', '$modalService', function (moreConstants, $resource, $modalService) {

  var CustomerFormVersionValidation = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/forms/:formId/versions/validate', {'customerId' : '@customerId', 'formId' : '@formId'}, {});


  var publishErrorClass = 'field-publish-error';
  return {
    validate: function (customerId, formId, formVersion) {
      return CustomerFormVersionValidation.save({ customerId: customerId, formId: formId }, formVersion);
    },

    resetPublicationErrors: function () {
      $('.' + publishErrorClass).removeClass(publishErrorClass);
    },

    enhanceFieldsWithPublicationErrors: function (publicationErrors) {
      publicationErrors.forEach(function (fieldId) {
        angular.element('#' + fieldId).parent().addClass(publishErrorClass);
      });
    },

    removeErrorFromField: function (fieldId) {
      angular.element('#' + fieldId).parent().removeClass(publishErrorClass);
    },

    addErrorToField: function (fieldId) {
      angular.element('#' + fieldId).parent().addClass(publishErrorClass);
    },
    showValidationModal: function (errors) {
      $modalService.alertModal({
        title: 'VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE',
        template: require('../../FormEditor/templates/publish.modal.html'),
        data: {
          validationErrors: errors,
          disableNavigateView: true
        }
      });
    }
  };
}]);