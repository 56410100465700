'use strict';

require('./bootstrap');
require('./controllers/UserInviteStatusCtrl');
require('./controllers/UserManagementRolesCtrl');
require('./controllers/GroupManagementIndexCtrl');
require('./controllers/AddUserToGroupModalCtrl');
require('./controllers/GroupManagementUsersCtrl');
require('./controllers/GroupManagementPermissionsCtrl');
require('./controllers/UserManagementPermissionsCtrl');
require('./controllers/AddGroupCtrl');
require('./controllers/EditGroupCtrl');
require('./controllers/AddGroupPanelCtrl');
require('./controllers/AddUserPanelCtrl');
require('./directives/ManageRolePermission');
