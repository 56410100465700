'use strict';

angular.module('Activation').factory('$inviteService', ['moreConstants', '$resource', '$browserService', function (moreConstants, $resource, $browserService) {

  const Invites = $resource(moreConstants.apiEndpointV2 + '/invites/:token', {}, {});

  return {
    getInvite: function (token) {
      return Invites.get({ token: token });
    },
    acceptInvite: function (token, onboarding) {
      if (onboarding && onboarding.settings) {
        onboarding.settings.timeZone = $browserService.getTimeZone();
      }
      return Invites.save({ token: token }, onboarding);
    }
  };
}]);
