'use strict';

angular.module('MoreDirectives').directive('moreSummernote', ['$modalService', '$translate', '$moreResourceService', '$state', 'moreConstants', function ($modalService, $translate, $moreResourceService, $state, moreConstants) {
  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      summernoteId: '='
    },
    template: require('../../MoreDirectives/templates/more-summernote.html'),
    link: function (scope, element) {
      var allowedFileTypes = ['png', 'jpg', 'jpeg', 'gif'];
      var customerId = $state.params.customerId;

      // Needed for Summernote to make the ng-model work
      scope._tmp = scope;

      scope.onImageUpload = onImageUpload;
      scope.options = {
        minHeight: 200,
        dialogsInBody: true,
        prettifyHtml: false,
        disableResizeEditor: true,
        toolbar: [['style', ['style']], ['font', ['bold', 'italic', 'underline', 'clear']], ['fontname', ['fontname']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['table', ['table']], ['insert', ['link', 'picture', 'hr']], ['view', ['codeview']]]
      };

      ////////
      function onImageUpload(files) {
        $.each(files, function (idx, file) {
          var extensionRegex = /(?:\.([^.]+))?$/;
          var ext = extensionRegex.exec(file.name)[1];

          if (typeof ext !== 'string' || allowedFileTypes.indexOf(ext.toLowerCase()) === -1) {
            $modalService.errorModal($translate.instant('UNSUPPORTED_FILE_TYPE', { fileTypes: allowedFileTypes.join() }));
          } else {
            var options = {
              name: file.name,
              shared: true
            };
            $moreResourceService.saveFileResource(customerId, file, options).then(function (moreResource) {
              var uploadedImageUrl = moreConstants.apiEndpoint + '/common/customers/' + customerId + '/resources/' + moreResource.id;
              $(element.find('.summernote')).summernote('insertImage', uploadedImageUrl);
            });
          }
        });
      }
    }
  };
}]);