'use strict';
angular.module('more.ui.form').service('$widgetPluginUtility', function ($timeout, $http, $translate, $injector) {

  var helpers = {
    photo : null,
    canvas : null,
    date : null,
    barcode : null,
    popup : null,
    file : null,
    pin : null,
    subForm : null,
    search : null,
    resource : null,
    modal : null,
    location : null,
    actionSheet : null,
    payment : null,
    drawing : null,
    video : null
  };

  Object.keys(helpers).forEach(function (key) {
    try {
      helpers[key] = $injector.get('$' + key + 'Helper');
    } catch (e) {
      console.error('Injector does not have ' + key + ' service!');
      console.error(e);
    }
  });

  return {
    timeout : $timeout,
    http : $http,
    translate : function (key, variables) {
      return $translate.instant(key, variables);
    },

    photo : helpers.photo,
    canvas : helpers.canvas,
    date : helpers.date,
    barcode : helpers.barcode,
    popup : helpers.popup,
    file : helpers.file,
    pin : helpers.pin,
    subForm : helpers.subForm,
    search : helpers.search,
    resource : helpers.resource,
    modal : helpers.modal,
    location : helpers.location,
    actionSheet : helpers.actionSheet,
    payment : helpers.payment,
    drawing: helpers.drawing,
    video: helpers.video
  };

});

