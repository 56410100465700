'use strict';

angular.module('AdminServices').factory('$adminCustomerService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const Customers = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId', {}, {});
  const CustomersBilling = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/billing', {}, {});
  const CustomersRefresh = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/refresh', {}, {});
  const CustomersSubscription = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/subscription/:action', {}, {
    put: { method: 'PUT' },
    confirm: {
      method: 'POST',
      params: {
        action: 'confirm'
      }
    },
    list: {
      method: 'GET',
      params: {
        action: 'list'
      },
      isArray: true
    }
  });
  const CustomersFolders = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/folders', {}, {});
  const CustomersForm = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/forms/:formId', {
    customerId: '@customerId',
    formId: '@formId'
  }, {
    restore: { method: 'PUT' }
  });
  const CustomersTrust = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/trust', {}, {
    update: { method: 'PUT' },
    remove: { method: 'DELETE' },
    add: { method: 'POST' }
  });
  const CustomerSegment = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/segment', {}, {
    update: { method: 'PUT' }
  });
  const CustomersBranding = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/branding', {}, {
    update: { method: 'PUT' }
  });
  const CustomersSendingDomain = $resource(moreConstants.apiEndpoint + '/admin/customers/:customerId/sending-domain', {}, {});
  const Trust = $resource(moreConstants.apiEndpoint + '/common/trust', {}, {});
  const CustomerPages = $resource(moreConstants.apiEndpoint + '/admin/customers/page/:pageNumber', {
    pageNumber: '@pageNumber'
  }, {
    filter: {
      method: 'POST',
    }
  });

  return {
    getCustomersWithFilter: (pageNumber, filter) => CustomerPages.filter({pageNumber}, filter),
    getCustomers: () => Customers.query(),
    getCustomer: customerId => Customers.get({customerId}),
    refreshCustomer: customerId => CustomersRefresh.save({customerId}, {}),
    scheduleCustomerForDeletion: (customerId, deleteIn) => Customers.delete({customerId, deleteIn}),
    getCustomerBilling: customerId => CustomersBilling.get({customerId}),
    getSubscriptionStatus: (customerId) => CustomersSubscription.get({customerId}),
    getSubscriptions: (customerId) => CustomersSubscription.list({customerId}),
    putSubscriptionStatus: (customerId, status) => CustomersSubscription.put({customerId}, status).$promise,
    confirmSubscription: (customerId) => CustomersSubscription.confirm({customerId}, {}).$promise,
    getCustomerTrust: customerId => CustomersTrust.get({customerId}),
    getFolders: customerId => CustomersFolders.query({customerId}),
    restoreForm: (customerId, formId) => CustomersForm.restore({customerId, formId}),
    updateSegment: (customerId, segment) => CustomerSegment.update({customerId}, {segment}),
    getTrustLevels: () => Trust.query(),
    overrideTrustLevel: (customerId, trustLevel) => CustomersTrust.update({customerId}, {trustLevel}),
    removeOverrideTrustLevel: (customerId) => CustomersTrust.remove({customerId}),
    addTrust: (customerId, trust) => CustomersTrust.add({customerId}, {trust}),
    updateBrandingId: (customerId, brandingId) => CustomersBranding.update({customerId}, {brandingId}),
    getSendingDomain: (customerId) => CustomersSendingDomain.get({customerId})
  };
}]);
