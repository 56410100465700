'use strict';

angular.module('UserState').factory('$userStateService', ['localStorageService', function (localStorageService) {

  var STORAGE_KEY = 'userState';

  var getState = function () {
    try {
      return localStorageService.get(STORAGE_KEY) || {};
    } catch (e) {
      return {};
    }
  };

  return {
    getState: getState,
    setFolderId: function (folderId) {
      var state = getState();
      if (state.folderId !== folderId) {
        state.folderId = folderId;
        state.viewId = null;

        localStorageService.add(STORAGE_KEY, state);
      }
    },
    setFormId: function (formId) {
      var state = getState();
      state.formId = formId;
      localStorageService.add(STORAGE_KEY, state);
    },
    setTemplateId: function (templateId) {
      var state = getState();
      state.templateId = templateId;
      localStorageService.add(STORAGE_KEY, state);
    },
    setDataSourceId: function (dataSourceId) {
      var state = getState();
      state.dataSourceId = dataSourceId;
      localStorageService.add(STORAGE_KEY, state);
    },
    setPluginId: function (pluginId) {
      var state = getState();
      state.pluginId = pluginId;
      localStorageService.add(STORAGE_KEY, state);
    },
    setGroupId: function (groupId) {
      var state = getState();
      if (state.groupId !== groupId) {
        state.groupId = groupId;
        state.userId = null;
        localStorageService.add(STORAGE_KEY, state);
      }
    },
    setUserId: function (userId) {
      var state = getState();
      state.userId = userId;
      localStorageService.add(STORAGE_KEY, state);
    }
  };
}]);
