'use strict';

angular.module('MoreAppsServices').factory('$integrationService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var IntegrationConfigurations = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:formId/integrations',
    {'customerId' : '@customerId', 'formId' : '@formId'});

  return {
    validateIntegrationConfiguration : function (customerId, formId, request) {
      return IntegrationConfigurations.save({customerId : customerId, formId : formId}, request);
    }
  };
}]);