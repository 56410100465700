'use strict';
angular.module('more.ui.form').directive('bindJavascript', function ($sce) {

  return {
    restrict : 'A',
    scope : {
      bindJavascript : '='
    },
    transclude : true,
    template : '<span ng-bind-html="::bindJavascript"></span>',
    link : function (scope) {
      scope.bindJavascript = $sce.trustAsJs(scope.bindJavascript);
    }
  };

});
