'use strict';

angular.module('WidgetPropertyPane').directive('optionSubsetProperty', ['$timeout', function ($timeout) {

  var analyzeChange = function (oldValue, newValue) {
    var i;
    if (oldValue.length > newValue.length) {
      for (i = 0; i < oldValue.length; i++) {
        if (newValue[i] === null || newValue[i] === undefined || oldValue[i].id !== newValue[i].id) {
          return {
            type: 'REMOVE',
            value: oldValue[i].id
          };
        }
      }
    } else if (oldValue.length === newValue.length) {
      for (i = 0; i < oldValue.length; i++) {
        if (oldValue[i].id !== newValue[i].id) {
          return {
            type: 'CHANGE',
            value: oldValue[i].id,
            to: newValue[i].id
          };
        }
      }
    }
  };

  return {
    restrict: 'A',
    scope: true,
    link: function (scope) {
      var propKey = scope.widgetProperty.key;
      var formProperties = scope.formFieldProperties;
      var multiple = false;

      if (scope.widgetProperty.configuration.multiple) {
        var multipleDefinition = scope.widgetProperty.configuration.multiple;
        if (multipleDefinition.type === 'static') {
          multiple = multipleDefinition.value;
        } else if (multipleDefinition.type === 'ref') {
          multiple = formProperties[multipleDefinition.value];
          scope.$watch('formFieldProperties[widgetProperty.configuration.multiple.value]', function (newValue) {
            $timeout(function () {
              scope.optionSubsetTmp.multiple = newValue;
              if (formProperties[propKey] !== null && formProperties[propKey] !== undefined) {
                if (newValue && !Array.isArray(formProperties[propKey])) {
                  formProperties[propKey] = [formProperties[propKey]];
                } else if (!newValue && Array.isArray(formProperties[propKey])) {
                  if (formProperties[propKey].length === 1) {
                    formProperties[propKey] = formProperties[propKey][0];
                  } else {
                    formProperties[propKey] = null;
                  }
                }
              }
            }, 10);
          });
        }
      }
      scope.optionSubsetTmp = {
        value: null,
        multiple: multiple
      };

      var changeValue = function (from, to) {
        if (Array.isArray(formProperties[propKey])) {
          var index = formProperties[propKey].indexOf(from);
          if (index !== -1) {
            var args = [index, 1];
            if (to !== undefined) {
              args.push(to);
            }
            formProperties[propKey].splice.apply(formProperties[propKey], args);
          }
        } else if (from === formProperties[propKey]) {
          formProperties[propKey] = to !== null && to !== undefined ? to : null;
        }
      };

      scope.$watch('formFieldProperties[widgetProperty.configuration.ref]', function (newValue, oldValue) {
        if (formProperties[propKey] !== null && formProperties[propKey] !== undefined) {
          var result = analyzeChange(oldValue, newValue);
          if (result && result.type === 'CHANGE') {
            changeValue(result.value, result.to);
          } else if (result && result.type === 'REMOVE') {
            changeValue(result.value, undefined);
          }
        }
      }, true);
    }
  };
}]);