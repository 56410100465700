'use strict';

angular.module('MoreGrid').directive('numberFilter', ['$timeout', function ($timeout) {
  var DELAY = 700;

  return {
    restrict: 'E',
    template: '<select class="form-control"  style="display: inline; margin-left: 3%; width: 44%"  data-ng-model="selectedMatcher" data-ng-change="innerValueChanged()">' + '<option></option>' + '<option value="eq">=</option>' + '<option value="neq">!=</option>' + '<option value="lt">&lt;</option>' + '<option value="lte">&lt;=</option>' + '<option value="gt">&gt;</option>' + '<option value="gte">&gt;=</option>' + '</select><input type="number" style="display: inline; margin-left: 3%; width: 44%" class="form-control" data-ng-model="selectedValue" data-ng-change="innerValueChanged()">',
    scope: {
      onChange: '&',
      id: '@'
    },
    link: function (scope) {
      var timeoutId;

      var resetTimeout = function () {
        if (timeoutId) {
          $timeout.cancel(timeoutId);
          timeoutId = null;
        }
      };

      var scheduleTimeout = function () {
        timeoutId = $timeout(function () {
          scope.onChange({
            id: scope.id,
            value: { match: scope.selectedMatcher, value: scope.selectedValue },
            type: 'number'
          });
        }, DELAY);
      };

      scope.innerValueChanged = function () {
        resetTimeout();
        if (!scope.selectedValue || !scope.selectedMatcher) {
          return;
        }
        scheduleTimeout();
      };
    }
  };
}]);
