'use strict';

angular.module('MoreDirectives').directive('moreResourceProperty', function () {
  return {
    restrict: 'A',
    scope: {
      target: '=',
      callback: '&',
      customerId: '=',
      allowClear: '=',
      showPreview: '=',
      buttonTranslation: '@'
    },
    template: require('../../../shared/templates/directives/more-resource-property.html'),
    controller: ['$scope', '$state', '$timeout', function ($scope, $state, $timeout) {

      $scope.clear = function () {
        $scope.target = null;
        _runCallback(null);
      };

      $scope.selectResource = function () {
        var newParams = angular.copy($state.params);
        $state.transitionTo($state.current.name + '.moreResource', newParams).then(function () {
          $state.current.data.resourceCallback = function (moreResource) {
            if (!moreResource) {
              return;
            }

            $scope.target = moreResource.id;

            _runCallback(moreResource.id);

          };
        });
      };

      function _runCallback(newValue) {
        if ($scope.callback) {
          // create deferred to safely run next digest: http://stackoverflow.com/a/18996042
          $timeout(function () {
            $scope.callback({ resourceId: newValue });
          });
        }
      }
    }]
  };
});
