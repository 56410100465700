'use strict';

angular.module('MoreDirectives').directive('itemHtmlPicker', ['$widgetService', '$formVersionUtilityService', function ($widgetService, $formVersionUtilityService) {
  const createLocationFields = (field) =>
      [
        { properties: { data_name: field.properties.data_name + '.coordinates.latitude', label_text: field.properties.label_text + ' - Latitude' }, icon: field.icon },
        { properties: { data_name: field.properties.data_name + '.coordinates.longitude', label_text: field.properties.label_text + ' - Longitude'}, icon: field.icon },
        { properties: { data_name: field.properties.data_name + '.formattedValue', label_text: field.properties.label_text}, icon: field.icon }
      ];

  const createFieldWithKey = (field, key) => ({
    properties: {
      data_name: field.properties.data_name + '.' + key,
      label_text: field.properties.label_text + '.' + key
    },
    icon: field.icon
  });

  var createFields = function (field, object) {
    var fields = [];
    for (var key in object) {
      fields.push(createFieldWithKey(field, key));
    }
    return fields;
  };

  var flattenComplexType = function (fields) {
    var newFields = [];
    fields.forEach(function (field) {
      if (field.dataType === 'datasource' && field.properties.data_source_configuration) {
        for (var key in field.properties.data_source_configuration.mapping) {
          newFields.push(createFieldWithKey(field, key));
        }
      } else if (field.dataType === 'location') {
        newFields.push(...createLocationFields(field));
      } else if (field.dataType === 'object') {
        for (var subKey in field.data.subdata) {
          if (Array.isArray(subKey) || typeof subKey === 'object') {
            newFields = newFields.concat(createFields(field, subKey));
          } else {
            newFields.push(createFieldWithKey(field, subKey));
          }
        }
      }
    });
    return newFields;
  };

  return {
    restrict: 'E',
    template: require('../../../shared/templates/directives/item-html-picker.html'),
    scope: {
      fields: '=',
      fieldClass: '@',
      showLabelText: '=',
      allowImages: '=',
      callback: '&'
    },
    link: function (scope) {
      init();

      function init() {
        if (scope.fields) {
          scope.flattenedFields = scope.fields.concat(flattenComplexType(scope.fields));
        }
        scope.$watch('fields', function (newVal) {
          if (newVal) {
            $widgetService.enrichFieldsWithWidgetInfo(newVal).then(function () {
              scope.flattenedFields = scope.fields.concat(flattenComplexType(newVal));
            });
          }
        });
      }

      scope.getFieldDisplayName = $formVersionUtilityService.getFieldDisplayName;

      scope.addToItemHtml = function (field) {
        scope.callback({ field: field.properties.data_name });
      };

      scope.filterAvailableFields = function (field) {
        if (['pins', 'detail', 'datasource', 'object', 'location'].indexOf(field.dataType) !== -1) {
          return false;
        }
        if (['file', 'protected-file'].indexOf(field.dataType) !== -1 && !scope.allowImages) {
          return false;
        }
        return field.properties.data_name;
      };
    }
  };
}]);
