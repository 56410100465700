'use strict';

require('./bootstrap');

require('./services/AdminAccessService');
require('./services/AdminCustomerService');
require('./services/AdminInvoiceService');
require('./services/AdminBrandingService');
require('./services/AdminQueueService');
require('./services/AdminRegionService');
require('./services/AdminUserService');
require('./services/AdminTreeService');
