'use strict';

angular.module('MoreAppsServices').factory('$sendingDomainService', ['moreConstants', '$resource', function (moreConstants, $resource) {

    const SendingDomain = $resource(moreConstants.apiEndpoint + '/customers/:customerId/sending-domain', {}, {
        update: { method: 'PUT'}
    });
    const SendingDomainVerify = $resource(moreConstants.apiEndpoint + '/customers/:customerId/sending-domain/verify', {}, {});

    return {
        get: (customerId) => SendingDomain.get({customerId}).$promise,
        create: (customerId, request) => SendingDomain.save({customerId}, request).$promise,
        update: (customerId, request) => SendingDomain.update({customerId}, request).$promise,
        verify: (customerId) => SendingDomainVerify.save({customerId}, {}).$promise,
        delete: (customerId) => SendingDomain.delete({customerId}).$promise
    };
}]);
