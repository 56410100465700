'use strict';

angular.module('MoreAppsServices').factory('$customerGroupService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerGroups = $resource(moreConstants.apiEndpoint + '/customers/:customerId/groups/:groupId/:action', {}, {
    update: { method: 'PUT' },
  });

  var CustomerGroupUsers = $resource(moreConstants.apiEndpoint + '/customers/:customerId/groups/:groupId/users/:userId', {}, {
    addUserToGroup: { method: 'POST' },
    removeUserFromGroup: { method: 'DELETE', params: { 'action': ':userId' } }
  });

  return {
    getGroups: function (customerId) {
      return CustomerGroups.query({ customerId: customerId });
    },
    create: function (customerId, group) {
      return CustomerGroups.save({ customerId: customerId }, group);
    },
    update: function (customerId, group) {
      return CustomerGroups.update({ customerId: customerId, groupId: group.id }, group);
    },
    removeGroup: function (customerId, groupId) {
      return CustomerGroups.delete({ customerId: customerId, groupId: groupId });
    },
    addUserToGroup: function (customerId, groupId, userId) {
      return CustomerGroupUsers.addUserToGroup({ customerId: customerId, groupId: groupId }, { id: userId });
    },
    removeUserFromGroup: function (customerId, groupId, userId) {
      return CustomerGroupUsers.remove({ customerId: customerId, groupId: groupId, userId: userId });
    }
  };
}]);
