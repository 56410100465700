'use strict';

angular.module('RegistrationFilePreview').directive('registrationFileLink', ['$uibModal', function ($uibModal) {
  return {
    restrict: 'E',
    scope: {
      name: '@',
      gridfs: '@'
    },
    template: '<a data-ng-click="showImage(); $event.stopPropagation()" class="btn btn-default"><i class="fa fa-picture-o"></i></a>',
    link: function (scope) {
      scope.showImage = function () {
        $uibModal.open({
          controller: ['$scope', '$customerRegistrationFileService', function ($scope, $customerRegistrationFileService) {
            var registrationFileId = scope.gridfs.substring('gridfs://registrationFiles/'.length);
            var customerId = $scope.$state.params.customerId;
            $customerRegistrationFileService.getRegistrationFile(customerId, registrationFileId).then(function (registrationFile) {
              $scope.name = scope.name;
              $scope.registrationFile = registrationFile;
            });
          }],
          template: require('../templates/registrationFile.modal.html')
        });
      };
    }
  };
}]);
