'use strict';

angular.module('more.ui.form-builder').factory('$barcodeHelper', ['$q', function ($q) {

  return {
    scan: function () {
      var deferred = $q.defer();
      deferred.reject('BARCODE_NOT_SUPPORTED_MESSAGE');
      return deferred.promise;
    },
    isScanning: function () {
      return false;
    }
  };
}]);