'use strict';

angular.module('MoreAppsSecurity').factory('$securityService', ['moreConstants', '$http', '$rootScope', '$timeout', 'localStorageService', 'OAuth2LoginService', function (moreConstants, $http, $rootScope, $timeout, localStorageService, OAuth2LoginService) {

  init();

  function init() {
    $rootScope.user = localStorageService.get('user');
    if ($rootScope.user) {
      // Fetch latest permissions
      updateRoles($rootScope.user);
    } else {
      $rootScope.user = {consumerKey: '', authorities: []};
    }
  }

  function matchAuthorities(requiredAuthorities, obtainedAuthorities) {
    if (!requiredAuthorities || requiredAuthorities.length === 0) {
      return true;
    }
    for (let i = 0; i < requiredAuthorities.length; i++) {
      if (obtainedAuthorities.indexOf(requiredAuthorities[i]) !== -1) {
        return true;
      }
    }
    return false;
  }

  function isLoggedIn() {
    return $rootScope.user && $rootScope.user.authorities && $rootScope.user.consumerKey !== '';
  }

  function currentUser() {
    return isLoggedIn() ? $rootScope.user : null;
  }

  function authenticate(success, error) {
    $http({
      method: 'GET', url: moreConstants.apiEndpoint + '/authentication', authenticate: true,
    }).success((data, status, callback, config) => {
      let user = {consumerKey: data.name};

      for (const attr in data) {
        user[attr] = data[attr];
      }
      localStorageService.add('user', user);
      $rootScope.user = user;
      success(user);
    }).error(error);
  }

  function updateRoles(user) {
      $http({method: 'GET', url: moreConstants.apiEndpoint + '/authentication'}).success(data => {
        // Fix in memory permissions
        user.roles = data.roles;
        user.permissions = data.permissions;
        updateUser(user);
      });
  }

  function updateUser(user) {
    localStorageService.set('user', user);
  }

  function logout() {
    OAuth2LoginService.logout();
  }

  return {
    authorize: (accessLevel) => {
      const authorities = $rootScope.user.authorities;
      return matchAuthorities(accessLevel, authorities);
    },
    authenticate: authenticate,
    updateRoles: updateRoles,
    updateUser: updateUser,
    logout: logout,
    isLoggedIn: isLoggedIn,
    currentUser: currentUser,
  };
}]);
