'use strict';

angular.module('customerPortal').controller('AgreementsModalCtrl', ['$userService', '$scope', function ($userService, $scope) {

  var self = this;
  var customerId = parseInt($scope.$state.params.customerId);

  self.accept = function () {
    $userService.updateUserStatus({ acceptedTermsOfCondition: [customerId] }).$promise.then(function () {
      $scope.$close();
    });
  };
}]);