'use strict';

angular.module('more.ui.form-builder').factory('$modalHelper', ['$rootScope', '$uibModal', function ($rootScope, $uibModal) {

  return {
    fromTemplate: function (templateString, options) {
      var scope = $rootScope.$new(true);
      addSuppliedScopeToModalScope(options, scope);

      return {
        show: function () {
          $uibModal.open({ template: templateString });
        }
      };
    }
  };

  ////////
  function addSuppliedScopeToModalScope(options, scope) {
    if (options.scope) {
      Object.keys(options.scope).forEach(function (key) {
        scope[key] = options.scope[key];
      });
    }
  }
}]);