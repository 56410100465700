'use strict';

angular.module('MoreGrid').directive('moreGrid', function () {
  return {
    restrict: 'E',
    template: require('../templates/moregrid.html'),
    scope: {
      columns: '=',
      searchEnabled: '=',
      filterChanged: '&',
      sortChanged: '&',
      searchDisabled: '&',
      idSelectionChanged: '&',
      sortEnabled: '=',
      selectionEnabled: '=',
      viewObject: '&',
      deleteObject: '&',
      allowSpecificObjectDeletion: '&',
      selection: '@',
      listedObjects: '=',
      viewEnabled: '=?',
      deleteEnabled: '=?'
    },
    link: function (scope, element, attrs) {
      scope.idSelection = [];
      if (typeof scope.viewEnabled === 'undefined') {
        scope.viewEnabled = !!attrs.viewObject;
      }

      if (typeof scope.deleteEnabled === 'undefined') {
        scope.deleteEnabled = !!attrs.deleteObject;
      }

      if (typeof scope.allowSpecificObjectDeletion !== 'function') {
        scope.allowSpecificObjectDeletion = () => true;
      }

      scope.sortInfo = {
        directions: [],
        fields: []
      };

      var filterObject = {};

      scope.getColumnType = function (column) {
        if (column.type) {
          return column.type;
        }

        if (!column.statistics) {
          return 'string';
        }
        var mostUsedKey = Object.keys(column.statistics).sort(function (a, b) {
          return column.statistics[b] - column.statistics[a];
        })[0];

        return mostUsedKey ? mostUsedKey : 'string';
      };

      scope.changedFilter = function (id, value, type) {
        if (value) {
          filterObject[id] = { value: value, type: type };
        } else {
          delete filterObject[id];
        }

        scope.filterChanged({ filter: filterObject });
      };

      scope.pageChecked = function () {
        if (!scope.listedObjects) {
          return false;
        }
        var listedObjectIds = scope.listedObjects.map(function (listedObject) {
          return listedObject[scope.selection];
        });
        var notListed = listedObjectIds.filter(function (id) {
          return scope.idSelection.indexOf(id) === -1;
        });
        return notListed.length === 0;
      };

      scope.toggleSelectionForPage = function () {
        var listedObjectIds = scope.listedObjects.map(function (listedObject) {
          return listedObject[scope.selection];
        });
        var notListed = listedObjectIds.filter(function (id) {
          return scope.idSelection.indexOf(id) === -1;
        });

        if (notListed.length === 0) {
          scope.idSelection = scope.idSelection.filter(function (id) {
            return listedObjectIds.indexOf(id) === -1;
          });
        } else {
          notListed.forEach(function (id) {
            scope.idSelection.push(id);
          });
        }

        scope.idSelectionChanged({ idSelection: scope.idSelection });
      };

      scope.sortColumn = function (column) {
        if (scope.sortInfo.fields[0] === column.id) {
          scope.sortInfo.directions[0] = scope.sortInfo.directions[0] === 'asc' ? 'desc' : 'asc';
        } else {
          scope.sortInfo.fields[0] = column.id;
          scope.sortInfo.directions[0] = 'asc';
        }

        scope.sortChanged({ sort: scope.sortInfo });
      };

      scope.toggleSelection = function (listedObject) {
        var id = listedObject[scope.selection];
        var index = scope.idSelection.indexOf(id);
        if (index === -1) {
          scope.idSelection.push(id);
        } else {
          scope.idSelection.splice(index, 1);
        }

        scope.idSelectionChanged({ idSelection: scope.idSelection });
      };
    }
  };
});
