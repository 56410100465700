'use strict';

angular.module('FormEditor').controller('WidgetFavoriteCtrl', ['$rootScope', '$scope', 'widget', 'field', '$widgetService', function ($rootScope, $scope, widget, field, $widgetService) {

  var computeChanges = function () {
    var answer = [];
    for (var i = 0; i < widget.definition.properties.length; i++) {
      var property = widget.definition.properties[i];
      var original = property.defaultValue;
      var value = field.properties[property.key];
      if (original !== value && !((original === null || original === undefined) && value === '')) {
        answer.push({
          property: property,
          value: value
        });
      }
    }
    if (field.properties.form) {
      answer.push({
        property: {key: 'form'},
        value: field.properties.form
      });
    }
    return answer;
  };

  $scope.widget = widget;
  $scope.field = field;
  $scope.favoriteName = 'Favorite ' + widget.info.name;
  $scope.changes = computeChanges();

  $scope.saveFavorite = function (favoriteName) {
    var submittedChanges = [];
    for (var i = 0; i < $scope.changes.length; i++) {
      submittedChanges.push({
        propertyKey: $scope.changes[i].property.key,
        value: $scope.changes[i].value
      });
    }
    $widgetService.saveFavorite({
      name: favoriteName,
      widgetUid: $scope.widget.info.uid,
      changes: submittedChanges
    }).$promise.then(function (favorite) {
      $rootScope.$broadcast('savedFavorite', favorite);
      $scope.$close();
    });
  };
}]);