'use strict';

angular.module('FormEditor').controller('FormEditorTopMenuCtrl', ['$scope', function ($scope) {
  var self = this;

  self.controlModes = [];

  $scope.$watch('targetView.uid', function (uid) {
    self.controlModes = [{ name: 'MENU_WIDGETS' }, { name: 'MENU_EMAIL' }, { name: 'MENU_RULES' }];

    // Not a widget, i.e. main form
    if (!uid) {
      self.controlModes.push({ name: 'MENU_HOOKS' });
      self.controlModes.push({ name: 'MENU_SETTINGS' });
    }

    if (!currentControlModeAvailable()) {
      self.setControlMode('menu_widgets');
    }
  });

  self.setControlMode = function (controlMode) {
    if (self.controlMode !== controlMode) {
      $scope.$emit('controlMode.changed', controlMode);
      $scope.$broadcast('top-menu.activate-item', { name: controlMode.toUpperCase() });
    }
  };

  init();

  ///////
  function init() {
    self.setControlMode('menu_widgets');

    $scope.$on('controlMode.changed', function ($event, newControlMode) {
      self.controlMode = newControlMode;
    });
  }

  function currentControlModeAvailable() {
    var foundMode = self.controlModes.filter(function (mode) {
      return mode.name.toLowerCase() === self.controlMode;
    })[0];
    return foundMode !== undefined;
  }
}]);