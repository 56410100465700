module.exports = {
  "FORMS": "Formulaires",
  "FOLDERS": "Dossiers",
  "ADMINISTRATIVE": "Administration",
  "INVITE": "Inviter",
  "INVITES": "Invitations",
  "USER_MANAGEMENT_OVERVIEW": "Aperçu des utilisateurs",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "Informations Utilisateurs",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Modifier utilisateur",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Supprimer utilisateur",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Renvoyer l'invitation",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Nom",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Administrateur",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Newsletter",
  "GROUPS": "Groupes",
  "ACCOUNT_OWNER": "Propriétaire du compte",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "Adresse e-mail",
  "USER_MANAGEMENT_ALL_USERS": "Tous les utilisateurs",
  "USER_MANAGEMENT_NEW_TITLE": "Nouvel utilisateur(s)",
  "USER_MANAGEMENT_EDIT_TITLE": "Gérer Utilisateur",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Autorisations",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Administrateur",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "Aucun groupe",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Dossiers",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(Par groupe)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "Aucun dossier",
  "USER_MANAGEMENT_EDIT_SECURITY": "Sécurité utilisateur",
  "USER_MANAGEMENT_SETTINGS_LABEL": "Paramètres utilisateurs",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Nom d'utilisateur",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "Prénom",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Nom",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Numéro de téléphone",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Newsletter",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Attention",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Supprimer l'utilisateur",
  "CUSTOMER_GROUPS_NEW_TITLE": "Nouveau groupe",
  "CUSTOMER_GROUPS_NAME_LABEL": "Nom",
  "CUSTOMER_GROUPS_NO_GROUPS": "Il n'y a aucun groupe.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "Aucun groupe visible. Veuillez changer de filtre.",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Membres du groupe",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Ajouter groupe",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Membre de ",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Modifier",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Supprimer",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Modifier groupe",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "Autorisations sur l'application",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "Ce groupe peut accéder aux formulaires dans les dossiers suivants:",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Autorisations sur la plateforme",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "Ce groupe peut gérer:",
  "MANAGE_FORMS_LABEL": "Formulaires et Dossiers",
  "MANAGE_REGISTRATIONS_LABEL": "Enregistrements",
  "MANAGE_TASKS_LABEL": "Tâches",
  "MANAGE_USERS_LABEL": "Utilisateurs",
  "MANAGE_SSO_LABEL": "Authentification unique",
  "MANAGE_DATA_SOURCES_LABEL": "Bases de données",
  "MANAGE_TEMPLATES_LABEL": "Modèles",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Transférer les droits de possession du compte",
  "MANAGE_ACCOUNT_LABEL": "Paramètres généraux du compte",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Supprimer le groupe",
  "SELECT_GROUPS": "Sélectionner Groupes",
  "USER_COUNT": "Contient {{value}} utilisateurs",
  "USER_COUNT_SINGLE": "Contient un utilisateur",
  "USER_COUNT_EMPTY": "Ne contient pas d'utilisateurs",
  "USER_MANAGEMENT_REMOVE_TITLE": "Supprimer l'utilisateur",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "Vous ne pouvez pas vous supprimer",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Demandez au propriétaire du compte de vous retirer.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Retirer l'utilisateur du groupe",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "Êtes-vous sûr de vouloir retirer cet utilisateur de ce groupe ?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Supprimer l'invitation",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "Êtes-vous sûr de vouloir supprimer cette invitation ?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Retirer l'invitation du groupe",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "Êtes-vous sûr de vouloir supprimer l'invitation de ce groupe ?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Supprimer le groupe",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce groupe ? Vous ne pouvez pas annuler cette action ! Notez que cela ne supprimera pas les utilisateurs qui se trouvent dans ce groupe.",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Les utilisateurs ont été invités",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "Vous avez invité avec succès <strong>{{count}} des utilisateurs </strong> à rejoindre votre compte.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "Le fichier sélectionné est plus grand que 5MB",
  "USER_MANAGEMENT_INVITE_TYPE": "Combien d'utilisateurs souhaitez-vous inviter ?",
  "USER_MANAGEMENT_INVITE_SINGLE": "Un seul utilisateur",
  "USER_MANAGEMENT_INVITE_BULK": "Plusieurs utilisateurs",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Sélectionnez un fichier Excel contenant une liste d'adresses email dans la première colonne",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Langue de l'invitation",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "Échec invitation utilisateur",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "L'utilisateur a déjà été ajouté à ce compte",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "La modification des autorisations a échoué",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "Impossible de supprimer le groupe car cela vous laisserait sans l'autorisation de gérer les utilisateurs",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "Impossible de supprimer la permission car cela vous laisserait sans la permission de gérer les utilisateurs",
  "ROLES": "Rôles",
  "ROLES_DEFAULT": "Rôles par défaut",
  "ROLES_CUSTOM": "Rôles personnalisés",
  "MANAGE_ROLES_TITLE": "Gérer rôles",
  "MANAGE_ROLES_DESCRIPTION": "Définissez les autorisations qui appartiennent à vos rôles.",
  "MANAGE_BILLING": "Gérer facturation",
  "MANAGE_CREDITS": "Gestion de la facturation",
  "MANAGE_DATA_SOURCES": "Gérer bases de données",
  "MANAGE_FORMS": "Gérer tous les formulaires & dossiers",
  "MANAGE_TASKS": "Gérer toutes les tâches",
  "MANAGE_REGISTRATIONS": "Gérer tous les enregistrements",
  "MANAGE_USERS": "Gérer utilisateurs et accès",
  "MANAGE_SSO": "Gérer authentification unique",
  "MANAGE_SENDING_DOMAIN": "Gérer le domaine d'e-mail",
  "MANAGE_WEBHOOKS": "Gérer webhooks",
  "MANAGE_TEMPLATES": "Gérer modèles",
  "MANAGE_ACCOUNT": "Gérer le compte",
  "TRANSFER_OWNERSHIP": "Transférer les droits de propriété",
  "CUSTOMER_USER": "Accès à l'application",
  "CREATE_FOLDER": "Créer des dossiers",
  "CREATE_FORM": "Créer des formulaires",
  "ADD_FORM": "Ajouter le formulaire à un dossier",
  "REMOVE_FORM": "Retirer le formulaire du dossier",
  "UPDATE_FOLDER": "Mettre à jour le dossier",
  "DELETE_FOLDER": "Supprimer le dossier",
  "CREATE_SUBMISSION": "Créer un enregistrement",
  "READ_SUBMISSION": "Voir les enregistrements",
  "DELETE_SUBMISSION": "Supprimer enregistrements",
  "UPDATE_SUBMISSION": "Mettre à jour enregistrements",
  "READ_TASK": "Voir les tâches",
  "UPDATE_TASK": "Accomplir les tâches",
  "CREATE_TASK": "Créer des tâches",
  "DELETE_TASK": "Révoquer des tâches",
  "UPDATE_FORM": "Mettre à jour le formulaire",
  "ARCHIVE_FORM": "Supprimer le formulaire",
  "RESTORE_FORM": "Restaurer formulaire",
  "MANAGE_BILLING_TOOLTIP": "Mise à jour de l'abonnement, des détails de facturation et du mode de paiement. Consultation des factures et de l'historique de l'utilisation.",
  "MANAGE_CREDITS_TOOLTIP": "Mettez à jour votre abonnement, vos informations de facturation et votre moyen de paiement. Consultez vos factures et l'historique de votre utilisation.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Créer, voir, mettre à jour et supprimer toutes les bases de données",
  "MANAGE_FORMS_TOOLTIP": "Créer, voir et mettre à jour <b>TOUS</b> les formulaires et dossiers",
  "MANAGE_TASKS_TOOLTIP": "Créer, visualiser et mettre à jour des tâches pour <b>TOUS</b> les formulaires",
  "MANAGE_REGISTRATIONS_TOOLTIP": "Voir, supprimer et renvoyer des enregistrements pour <b>TOUS</b> les formulaires",
  "MANAGE_USERS_TOOLTIP": "Gérer l'accès de tous les utilisateurs, les invitations et les groupes",
  "MANAGE_SSO_TOOLTIP": "Gérer la configuration de l'authentification unique (SSO)",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Gérer la configuration d'un domaine d'e-mail personnalisé",
  "MANAGE_WEBHOOKS_TOOLTIP": "Créer, voir, mettre à jour et supprimer tous les webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Créer, voir, mettre à jour et supprimer tous les modèles",
  "MANAGE_ACCOUNT_TOOLTIP": "Gérer les paramètres généraux du compte",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Gérer les paramètres généraux du compte",
  "CREATE_FOLDER_TOOLTIP": "Créer nouveaux dossiers",
  "CREATE_FORM_TOOLTIP": "Créer dans un dossier auquel vous avez accès",
  "ADD_FORM_TOOLTIP": "Ajouter/déplacer vers un dossier auquel vous avez accès",
  "REMOVE_FORM_TOOLTIP": "Supprimer d'un dossier auquel vous avez accès",
  "UPDATE_FOLDER_TOOLTIP": "Mettre à jour nom, description et icône.",
  "DELETE_FOLDER_TOOLTIP": "Supprimer dossier",
  "CREATE_SUBMISSION_TOOLTIP": "Envoyer un formulaire dans l'application pour créer nouvel enregistrement",
  "READ_SUBMISSION_TOOLTIP": "Voir les enregistrements d'un formulaire spécifique",
  "DELETE_SUBMISSION_TOOLTIP": "Supprimer les enregistrements d'un formulaire spécifique",
  "UPDATE_SUBMISSION_TOOLTIP": "Mettre à jour enregistrements d'un formulaire spécifique",
  "CREATE_TASK_TOOLTIP": "Créer des tâches pour un formulaire spécifique",
  "READ_TASK_TOOLTIP": "Visualiser les tâches d'un formulaire spécifique",
  "UPDATE_TASK_TOOLTIP": "Effectuer des tâches pour un formulaire spécifique",
  "DELETE_TASK_TOOLTIP": "Révoquer des tâches pour un formulaire spécifique",
  "UPDATE_FORM_TOOLTIP": "Modifier et publier le formulaire",
  "ARCHIVE_FORM_TOOLTIP": "Supprimer formulaire",
  "ALLOWED": "Oui",
  "ALLOW": "Autoriser",
  "DONT_ALLOW": "Ne pas autoriser",
  "NOT_ALLOWED": "Non",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Dossiers et Formulaires",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Gérer l'accès de tous les utilisateurs dans ce groupe.",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "Ce groupe peut <b>gérer tous les formulaires et dossiers</b> à travers son rôle mentionné ci-dessus.",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "Cet utilisateur peut <b>gérer tous les formulaires et dossiers</b> via son rôle",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Gérer les accès pour cet utilisateur.",
  "ACCESS_ON_ACCOUNT": "Compte",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Sélectionnez une autorisation de compte pour tous les utilisateurs de ce groupe.",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Sélectionnez une autorisation de compte pour cet utilisateur.",
  "NEW_ROLE": "Créer un nouveau rôle",
  "USER_IN_GROUPS_DESCRIPTION": "Cet utilisateur fait partie des groupes suivants",
  "USER_NOT_IN_GROUP_DESCRIPTION": "Cet utilisateur ne fait partie d'aucun groupe",
  "ACCESS_VIA_GROUPS": "Accès via les groupes",
  "ROLE_ACCOUNT_OWNER": "Propriétaire du Compte",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Administrateur Compte",
  "ROLE_OWNER": "Propriétaire Compte",
  "ROLE_USER": "Utilisateur Compte",
  "ROLE_FORM_MANAGER": "Responsable Formulaire",
  "ROLE_FORM_USER": "Utilisateur Formulaire",
  "ROLE_FOLDER_MANAGER": "Responsable Dossiers",
  "USER_DESCRIPTION": "Informations Utilisateurs",
  "ROLE_DELETE_TITLE": "Supprimer rôle",
  "ROLE_DELETE_MESSAGE": "Vous pouvez uniquement supprimer les rôles qui ne sont pas utilisés. Assurez-vous d'abord de mettre à jour tous les utilisateurs/groupes qui utilisent ce rôle.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "Externe",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "Ce groupe est synchronisé à partir de votre répertoire externe et ne peut être modifié.",
  "EXTERNAL_DIRECTORY_USER_LABEL": "Externe",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "Cet utilisateur est synchronisé à partir de votre répertoire externe et ne peut être modifié.",
  "USER_SUSPENDED": "Suspendu",
  "USER_SUSPENDED_TOOLTIP": "Cet utilisateur a été suspendu dans votre répertoire externe",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Vous n'êtes pas autorisé à transférer les droits de propriété du compte",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Vous n'êtes pas autorisé à transférer les droits de propriété du compte à un utilisateur invité. Ce dernier doit tout d'abord accepter l'invitation.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Non autorisé à transférer les droits de propriété du compte",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Vous devez être le propriétaire du compte afin de transférer les droits de propriété du compte à un autre utilisateur",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Transférer les droits de propriété",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "Êtes-vous sûr de vouloir transférer les droits de propriété du compte à <em>{{firstName}} {{lastName}} ({{username}})</em>?<br /> <br /> <b>Remarque:</b> Vous perdrez les droits de propriété du compte.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Droits de propriété du compte transférés",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> est maintenant l'actuel propriétaire du compte.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "E-mail",
  "MENU_RULES": "Règles",
  "MENU_HOOKS": "Intégrations",
  "SUB_FORM_NO_LONGER_USED_TITLE": "Le sous-formulaire n'est plus utilisé",
  "SUB_FORM_NO_LONGER_USED_BODY": "Le sous-formulaire n'est utilisé nulle part ailleurs dans le dossier. Voulez-vous supprimer le sous-formulaire?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "Ces sous-formulaires ne sont utilisés nulle part ailleurs dans le dossier. Voulez-vous supprimer les sous-formulaires?",
  "UNSUPPORTED_FILE_TYPE": "Seuls les types de fichiers suivants sont pris en charge: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "Cette version n'est pas modifiable",
  "FORM_FINALIZED_MESSAGE": "Si vous souhaitez modifier votre formulaire, retournez dans l'aperçu général et cliquez sur 'Modifier le formulaire.'",
  "PUBLISH_FAILED_TITLE": "Échec de publication de votre formulaire",
  "PUBLISH_FAILED_MESSAGE": "Ne vous inquiétez pas, votre formulaire a été sauvegardé avec succès, mais il n'a pas pu être publié. Svp réessayez ultérieurement. Si le problème persiste, contactez-nous.",
  "INTEGRATION_EOL_MESSAGE": "Cette intégration ne fonctionne plus. Supprimez ou remplacez-la avec une autre intégration ou webhook. <a href=\"https://help.moreapp.com/fr/support/tickets/new\">Contactez l'Assistance</a> si vous avez besoin d'aide.",
  "USER_PICKER_ADD_USER": "Inviter utilisateur(s)",
  "USER_PICKER_ADD_GROUP": "Créer un nouveau groupe",
  "USER_PICKER_ALL_USERS": "Tous les utilisateurs",
  "USER_PICKER_INVITES": "Invitations",
  "USER_PICKER_INVITED": "(Invité)",
  "COLLECTION_PICKER_ADD_FORM": "Créer nouveau formulaire",
  "COLLECTION_PICKER_ADD_COLLECTION": "Créer nouveau dossier",
  "SEARCH_FORMS_PLACEHOLDER": "Rechercher dossiers et formulaires",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Sélectionner les colonnes",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Rechercher",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "Voir",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Supprimer tout",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "Êtes-vous absolument sûr?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "Êtes-vous sûr de vouloir supprimer toutes les inscriptions pour ce formulaire? Vous ne pouvez pas annuler cette action!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "Ceci supprime TOUTES les inscriptions de ce formulaire, pas seulement votre sélection",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Veuillez entrer votre identifiant client pour confirmer, ce qui est",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Exporter",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Exporter tout",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Exportation Filtrée",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Exporter sélectionné",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Inclure les images",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Inclure fichiers",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "Exportation vers une seule feuille Excel",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "L'exportation peut prendre du temps",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "Vous êtes sur le point d'exporter un nombre important d'inscriptions. Cela peut prendre un certain temps à télécharger.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "Il n'y a aucun utilisateur pour ce formulaire.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Séléctionné",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Effacer la sélection",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Détails d'enregistrements",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Supprimer l'enregistrement",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Supprimer définitivement l'inscription",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "Êtes-vous sûr de vouloir supprimer cette inscription? Vous ne pouvez pas annuler cette action!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Renvoyer l'enregistrement",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Renvoyer l'enregistrement",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "Voulez-vous renvoyer cette inscription?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "L'enregistrement est en cours d'envoi",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "L'inscription de réexpédition a échoué. Contactez le support, si cela persiste.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "Emails avec PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Destinataires",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Info",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "Utilisateur",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Données",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "Tous les fichiers",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Emplacement",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "Afficher sur Google Maps",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "Non enregistré",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Date enregistrée",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Appareil",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Réseau",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "Version de l'application",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Tâche",
  "SERIAL_NUMBER": "Numéro de série",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Intégrations",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Réessayer l'étape {{index}}",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Télécharger le rapport",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Prochaine tentative de",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "Mail",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "Télécharger PDF",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Essai renvoi",
  "DOWNLOAD": "Télécharger",
  "MAIL_STATUS_SEND": "{{number}} envoyé",
  "MAIL_STATUS_QUEUED": "{{number}} en file d'attente",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} soft bounced",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} hard bounced",
  "MAIL_STATUS_REJECT": "{{number}} rejeté",
  "BAD_MAILBOX": "Boîte aux lettres incorrecte",
  "INVALID_DOMAIN": "Domaine inalide",
  "IMAGES": "Images",
  "UNPAID_SUBMISSION": "Cet envoi n'a pas encore été traité.",
  "TRY_BETA_EXPORT": "Testez la nouvelle version bêta",
  "EXPORT_ALL_COLUMNS": "Exporter toutes les colonnes",
  "SELECTED_COLUMNS": "Sélectionner colonnes",
  "EXPORT_OPTIONS_TIMEZONE": "Fuseau horaire",
  "EXPORT_SUCCESS_TITLE": "L'exportation a démarré avec succès",
  "EXPORT_SUCCESS_MESSAGE": "Votre exportation a été lancée, une fois terminée elle sera envoyée à <b>{{email}}</b>. En fonction de la taille de votre exportation, cela peut prendre jusqu'à plusieurs heures.",
  "EXPORT_SERVICE_UNAVAILABLE": "L'exportation est actuellement indisponible. Essayez plus tard s'il vous plaît.",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "L'exportation incluant des fichiers sont limités à {{limit}} enregistrements",
  "MORE_INFORMATION": "Plus d'informations",
  "SELECTABLE_REORDARABLE_DONE": "Terminé",
  "ACCEPT_CUSTOMER_INVITE": "Acceptez-vous l'invitation de '{{customer}}'?",
  "ACCEPT_BUTTON": "Accepter",
  "ACTIVATION_FORM_TITLE": "Activation de Compte",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Informations Client",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Nom",
  "ACTIVATION_USER_INFORMATION_TITLE": "Informations de l'Utilisateur",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "Prénom",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Nom",
  "ACTIVATION_PASSWORD": "Mot de Passe",
  "ACTIVATION_PASSWORD_CONFIRM": "Confirmation du Mot de Passe",
  "ACTIVATION_PASSWORD_MISMATCH": "Mot de passe non conforme. Veuillez vous assurer que le mot de passe correspond à la confirmation.",
  "ACTIVATION_FORM_TOC": "Termes et Conditions",
  "ACTIVATION_FORM_TOC_MESSAGE": "En cliquant sur le bouton, vous acceptez le ",
  "ACTIVATION_SUBMIT_BUTTON": "Activer",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Entrez le token",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "Votre invitation a expiré ou est invalide",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Demandez à votre administrateur de vous envoyer une nouvelle invitation",
  "ACTIVATION_CHECK_TOKEN": "Vérifier token",
  "ACTIVATION_SUCCESS": "Vous avez activé votre compte avec succès !",
  "LICENSE_AGREEMENT": "Contrat de licence",
  "PRIVACY_POLICY": "Politique de Confidentialité",
  "SECURITY_STATEMENT": "Déclaration de Sécurité",
  "LOGIN_ON_MOBILE_APP": "Connectez-vous sur l'application mobile:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Téléchargez l'application sur votre appareil Android ou iOS pour commencer.",
  "OR": "ou",
  "SCAN_QR_CODE": "Scannez le code QR",
  "NO_SMARTPHONE": "Vous n'avez pas accès à votre smartphone en ce moment ?",
  "OPEN_IN_WEBCLIENT": "Ouvrir dans le navigateur",
  "ICON_PICKER_SELECT": "Sélectionner Icône",
  "ICON_PICKER_MODAL_TITLE": "Sélecteur d'icône",
  "MORE_GRID_VIEW": "Voir",
  "MORE_GRID_LOAD_MORE": "Charger plus",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Détails",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Détails",
  "DATE_FILTER_FROM_LABEL": "De,",
  "DATE_FILTER_TO_LABEL": "À",
  "DATE_FILTER_PLACEHOLDER": "Sélectionner une date",
  "NO_RESULTS_FOUND": "Aucun résultat trouvé",
  "CANVAS_NOT_SUPPORTED_TITLE": "Signature non prise en charge",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "Vous ne pouvez pas utiliser cette fonctionnalité dans la plateforme. Elle est uniquement prise en charge dans l'application mobile et le Webclient.",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Photo non prise en charge",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "Vous ne pouvez pas utiliser cette fonctionnalité dans la plateforme. Elle est uniquement prise en charge dans l'application mobile et le Webclient.",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Photothèque non prise en charge",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "Vous ne pouvez pas utiliser cette fonctionnalité dans la plateforme. Elle est uniquement prise en charge dans l'application mobile et le Webclient.",
  "BARCODE_SCAN_FAILURE": "Scanner de code-barres non pris en charge",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "Vous ne pouvez pas utiliser cette fonctionnalité dans la plateforme. Elle est uniquement prise en charge dans l'application mobile.",
  "DATE_MODAL_TITLE": "Sélection date",
  "WIDGET_DETAIL_LINK": "Autoriser le sous-formulaire à être réutilisé dans d'autres widgets",
  "WIDGET_DETAIL_UNLINK": "Lier ce widget à un sous-formulaire réutilisable",
  "WIDGET_DETAIL_NEW_TITLE": "Nouveau Sous-formulaire",
  "WIDGET_DETAIL_REMOVE_TITLE": "Supprimer le Sous-formulaire",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Lier un sous-formulaire existant",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "Les changements seront apportés aux autres widgets en utilisant ce sous-formulaire",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Utilisé dans {{value}} widgets",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "Non utilisé",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce sous-formulaire?",
  "COLLECTION": "Dossier",
  "DESCRIPTION": "Description",
  "LOGIN": "S'identifier",
  "LOGIN_USERNAME": "Nom d'utilisateur",
  "LOGIN_PASSWORD": "Mot de passe",
  "LOGIN_SIGN_IN_BUTTON": "Connexion",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Nom d'utilisateur et/ou mot de passe invalide",
  "LOGIN_FORGOT_PASSWORD_LINK": "Mot de passe oublié",
  "LOGIN_NO_ACCOUNT_MESSAGE": "Pas de compte ? Sélectionnez votre formule ici !",
  "LOGOUT_SUCCESSFUL_TITLE": "Déconnecté(e)",
  "LOGOUT_SUCCESSFUL_MESSAGE": "Vous êtes maintenant déconnecté(e)",
  "LOGOUT_SUCCESSFUL_ACTION": "Se reconnecter",
  "FORGOT_PASSWORD_TITLE": "Mot de passe oublié",
  "FORGOT_PASSWORD_EMAIL": "Email",
  "FORGOT_PASSWORD_USERNAME": "Nom d'utilisateur",
  "FORGOT_PASSWORD_SUBMIT": "Enregistrer",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Adresse e-mail invalide",
  "FORGOT_PASSWORD_EMAIL_SENT": "Votre mot de passe a été réinitialisé. Suivez le lien que nous avons envoyé à votre adresse e-mail pour définir un nouveau mot de passe.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Retour à l'identification",
  "ACTIVATE_USER_TITLE": "Activer l'utilisateur",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "Nouveau mot de passe",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "Nouveau mot de passe (vérification)",
  "MENU_HELP": "Centre d'Assistance",
  "MENU_REGIONS": "Régions",
  "MENU_CUSTOMERS": "Clients",
  "MENU_LOGOUT": "Déconnexion",
  "MENU_USERS": "Utilisateurs",
  "MENU_REGISTRATIONS": "Enregistrements",
  "MENU_FORMS": "Formulaires",
  "MENU_TEMPLATES": "Modèles",
  "MENU_SETTINGS": "Paramètres",
  "MENU_API_DOC": "Documentation",
  "MENU_DOCUMENTATION": "Emails",
  "MENU_SUPPORT": "Assistance",
  "MENU_PLUGINS": "Plugins",
  "MENU_TRANSACTIONS": "Transactions",
  "MENU_BILLING": "Facturation",
  "MENU_FINANCIAL": "Financier",
  "MENU_CONTACT": "Contact",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Choisir Client",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Bases de données",
  "MENU_PROFILE": "Profil",
  "MENU_ONBOARDING": "Inscription",
  "MENU_QUEUE": "Queue",
  "PROFILE_INFO": "Information",
  "PROFILE_EMAIL_VALID": "L'e-mail a été validé avec succès",
  "PROFILE_EMAIL_NOT_VALIDATED": "L'e-mail n'a pas encore été validé.",
  "SUBMITTED": "Envoyé",
  "SENT": "Envoyé",
  "ACCEPTED": "Accepté",
  "REVOKED": "Révoqué",
  "REJECTED": "Rejeté",
  "CONFIRM": "Confirmer",
  "AWAITING_PACKAGE_UPLOAD": "Awaiting package upload",
  "RESELLER": "Revendeur",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Paramètres",
  "CUSTOMER_APPLICATION_OPERATIONS": "Opérations des dossiers",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "Un dossier ne peut être supprimé que lorsqu’il est vide",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Total des formulaires envoyés",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Formulaires envoyés",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Rechercher...",
  "ADD_FORM_PANEL_TITLE": "Ajouter un formulaire",
  "ADD_FORM_PANEL_TITLE_LABEL": "Titre du formulaire",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Titre du formulaire",
  "ADD_FORM_PANEL_SAVE": "Sauvegarder",
  "ADD_TEMPLATE": "Ajouter un modèle",
  "MARK_GLOBAL": "Sélectionner Général",
  "UNMARK_GLOBAL": "Désélectionner Général",
  "MARK_GLOBAL_TOOLTIP": "Seulement disponible pour l'administrateur MoreApp. Marquez comme Général pour le rendre publiquement disponible sur l'écran \"Nouveau Formulaire\"",
  "ADD_APPLICATION_PANEL_TITLE": "Ajouter un dossier",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Nom du dossier",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Nom",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Supprimer le formulaire",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce formulaire?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Copier le formulaire",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Choisissez un dossier pour copier le formulaire ",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Dossier",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Dossier",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Nom de la copie",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Publier",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Gérer les utilisateurs",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Copier",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Supprimer",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Copier dossier",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Client",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Publier dossier",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "Êtes-vous sûr de vouloir publier le dossier?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "Dossier publié avec succès!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Échec de la publication du dossier pour des raisons inconnues. Veuillez vérifier les propriétés manquantes de la définition de l'application si vous ne pouvez pas résoudre ce problème. S'il vous plaît contacter votre partenaire.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "Impossible de publier le dossier. Veuillez corriger les problèmes suivants et réessayer:",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "La tentative précédente avait les erreurs suivantes:",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Afficher les erreurs précédentes",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Publier",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Placer dans le marché",
  "INVALID_APPLICATION_HEADER": "Problèmes d'application:",
  "INVALID_FORM_HEADER": "Problèmes de formulaire:",
  "INVALID_APPLICATION_START_PAGE": "Le dossier doit avoir une page de démarrage",
  "INVALID_APPLICATION_NAME": "Le dossier doit avoir un nom",
  "INVALID_APPLICATION_THEME": "Le dossier doit avoir un thème",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Le paramètre de recherche \"balisage d'article\" n'est pas valide",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "<code>{{fieldName}}</code> a des propriétés non valides",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "<code>{{fieldName}}</code> a une propriété invalide <code>{{property}}</code>",
  "INVALID_VIEW_ITEM_DATA_NAME": "<code>{{fieldName}}</code> a un nom de données invalide",
  "INVALID_VIEW_ITEM_WIDGET_UID": "<code>{{fieldName}}</code> le widget n'est pas installé",
  "INVALID_VIEW_ITEM_VIEW_ID": "<code>{{fieldName}}</code> ne se réfère pas à une vue connue",
  "INVALID_VIEW_ITEM_MAIL": "Espace réservé incorrect dans le modèle de courrier à coté de <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Destinataires dynamiques non valides dans le modèle de courrier à coté de <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Destinataire incorrect dans le courrier: <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Règle non valide avec le nom <code>{{ fieldName }}</code>",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "Gestion Utilisateurs",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Utilisateurs ayant accès",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "Aucun utilisateur",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "Utilisateur",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "Utilisateurs",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Supprimé ",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Groupes ayant accès",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "Aucun groupe",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Choisissez qui peut accéder à ce formulaire et l'utiliser",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Choisissez qui peut ajouter des formulaires dans ce dossier",
  "CONTACT": "Contact",
  "ADDITIONAL_FEES_APPLY": "Des frais supplémentaires sont appliqués",
  "CUSTOMER_CONTACT_VAT_NUMBER": "VAT Number",
  "CUSTOMER_CONTACT_COC_NUMBER": "Numéro de la chambre de commerce",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Support",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "Nous avons reçu votre notification et prendrons les mesures appropriées.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Impossible d'envoyer votre notification. N'hésitez pas à nous contacter à propos de ce problème.",
  "BILLING_INFO": "Détails de Facturation",
  "CONTACT_INFO": "Informations de Contact",
  "INVOICES": "Factures",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Compte",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Sélectionner un client",
  "COLLECTION_REMOVE_MODAL_TITLE": "Supprimer le dossier",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce dossier? Cette action ne peut être annulée",
  "COPY_FORM_MODAL_TITLE": "Copier le formulaire",
  "COPY_FORM_MODAL_MESSAGE": "Choisissez un dossier dans lequel copier le formulaire et changez son nom si vous préférez",
  "COPY_FORM_MODAL_NAME_LABEL": "Nom",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "Dossier cible",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Sélectionnez un dossier pour copier le formulaire",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Utilisez éventuellement un nom différent pour la copie.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_CUSTOMER": "Le compte vers lequel ce formulaire est copié.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "La copie sera placée dans ce dossier.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Supprimer l'aperçu",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "Êtes-vous sûr de vouloir supprimer cette vue?",
  "SUPPORT_TITLE": "Support",
  "SUPPORT_QUESTION": "Quelque chose ne va pas?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "Avez-vous trouvé un bug ou une fonctionnalité manquante, ou avez-vous une question? Veuillez nous envoyer un e-mail à <a href=\"mailto:support@moreapp.com\">support@moreapp.com</a>. Veuillez fournir des informations détaillées relatives à votre problème, éventuellement des étapes à reproduire et à quel utilisateur et / ou fonctionnalité est liée.",
  "SUPPORT_QUESTION_SUBTITLE": "S'il vous plaît remplissez le formulaire ci-dessous pour nous le faire savoir!",
  "SUPPORT_APPLICATION_QUESTION": "Quel dossier utilisiez-vous?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Nom / identifiant du dossier",
  "SUPPORT_FORM_QUESTION": "Quelle formulaire utilisiez-vous?",
  "SUPPORT_FORM_PLACEHOLDER": "Nom du formulaire",
  "SUPPORT_DEVICE_QUESTION": "Quel type d'appareil utilisiez-vous?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Type / Version du système",
  "SUPPORT_DESCRIPTION_QUESTION": "Y at-il autre chose que vous pouvez nous dire qui pourrait vous aider?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Déscription",
  "SUPPORT_EMAIL_QUESTION": "Si nécessaire, comment pouvons-nous vous contacter?",
  "SUPPORT_EMAIL_PLACEHOLDER": "Adresse email",
  "SUPPORT_SUCCESS_TITLE": "Merci!",
  "SUPPORT_SUCCESS_MESSAGE": "Nous avons reçu votre notification et prendrons les mesures appropriées.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Modifier le dossier: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Paramètres",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Nom",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Entrez un nom pour le dossier",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Page de démarrage",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Thème",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Image",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Aperçus",
  "APPLICATION_EDITOR_TO_LABEL": "A",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "Passer au mode simplifié",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "Voulez-vous vraiment revenir au mode simplifié ? Cela signifie que vous perdrez votre mise en page PDF personnalisée et revenez à notre mise en page PDF par défaut.\n\n",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Nom",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Liste des destinataires séparés par des virgules",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Destinataires dynamiques",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Sujet",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "De",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Nom de l'expéditeur",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Répondre à",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Envoyer des réponses à cette adresse",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "De l'email",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Réinitialiser les pièces jointes",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Réinitialisation",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "Êtes-vous sûr de vouloir réinitialiser le PDF?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "Cela remplacera vos personnalisations et régénérera la pièce jointe PDF par défaut, en fonction de votre configuration de formulaire actuelle.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Joindre toutes les images",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "Joindre un PDF avec un nom:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Utiliser l'en-tête et le pied de page en PDF",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "Avec logo:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Masquer les champs qui ne sont pas remplis",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Utiliser l'orientation horizontale pour le sous-formulaire",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Espaces réservés",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Afficher la carte",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Remplissez une ou plusieurs adresses email auxquelles vous souhaitez envoyer le formulaire, séparées par des virgules. <br> <br> <b> Exemple </b> <br> alice@example.com, bob@example.com",
  "${sys.user}": "Utilisateur",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Échec de la publication",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "Le formulaire a été sauvegardé mais n'a pas pu être publié en raison de certaines erreurs.",
  "VIEW_EDITOR_FORM": "Formulaire",
  "VIEW_EDITOR_EMAIL": "Email",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Envoyer un email avec PDF pour chaque inscription",
  "VIEW_EDITOR_RULES": "Règles ",
  "VIEW_EDITOR_RULES_SUBTITLE": "Masquer ou afficher les champs dans certaines conditions",
  "VIEW_EDITOR_SETTINGS": "Paramètres",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Changer l'apparence et le comportement de votre formulaire",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Permet aux utilisateurs de rechercher des enregistrements dans l'application et de s'en servir comme point de départ pour une nouvelle inscription",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Intégrations",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Déclencher un système externe sur chaque enregistrement",
  "VIEW_EDITOR_PROPERTIES": "Propriétés",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "Le nom de données est invalide",
  "VIEW_EDITOR_DATA_NAME": "Nom de données",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "Ce nom de données est dupliqué, ce qui peut entraîner un comportement inattendu",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Ajouter une règle",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Ajouter intégration",
  "HOOK_SELECT_TITLE": "Séléctionner un crochet",
  "INTEGRATIONS_SUBTITLE": "Comme alternative, vous pouvez effectuer des intégrations en utilisant des <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, notre <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a>, ou <a href='https://zapier.com/apps/moreapp/integrations' target='_blank'>Zapier</a>",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "Il n'y a rien à configurer pour ce crochet",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "La configuration a été validée avec succès",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Supprimer intégration",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "Êtes-vous sûr(e) de vouloir supprimer cette intégration ? Cette action ne peut pas être annulée.",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "Il n'y a actuellement crochet intégration à ajouter",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "La configuration a été enregistrée, mais elle n'a pas pu être validée pour le moment. Si le problème persiste, veuillez contacter l'auteur de cette intégration: {{author}}",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Obtenez plus de crochet",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "Ce crochet n'est pas disponible depuis {{lastAvailableDate | date: 'medium'}}. Vous pouvez sauvegarder la configuration mais celle-ci ne sera pas validée. En outre, le crochet ne sera pas exécuté jusqu'à ce qu'il soit à nouveau disponible.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Nom",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Conditions",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Autres types",
  "VIEW_EDITOR_RULE_USER": "Nom d'utilisateur",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Choisissez un champ",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Choisissez une condition",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Choisissez une valeur",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Commence par",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Se termine par",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Contient",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Supérieur à",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Inférieur à",
  "VIEW_EDITOR_RULE_CONDITION_IS": "Est",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "A une valeur",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "valeur",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "valeur",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Champs de formulaire actuels",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Autres champs de formulaires",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Actions",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "Quand",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "Si les conditions correspondent, procédez comme suit",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": " Choisissez une action",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Paramètres",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Nom",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Déscription",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Icone",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "Cacher sur l’application",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Aperçu racine",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "Ce formulaire devrait apparaître en tant qu'aperçu racine",
  "VIEW_EDITOR_SETTINGS_INTERACTION_LABEL": "Télécharger l'interaction",
  "VIEW_EDITOR_SETTINGS_INTERACTION_IMMEDIATE_UPLOAD_LABEL": "Téléchargement immédiat",
  "VIEW_EDITOR_SETTINGS_INTERACTION_MANUAL_UPLOAD": "Téléchargement manuel",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Description dans l'application",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Mode sauvegarde",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Enregistrer et fermer (par défaut)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Enregistrer et fermer & Enregistrer et nouveau",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "Pas de sauvegarde (lecture seule)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Paramètres de recherche",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Activé",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Afficher uniquement les enregistrements effectués par l'utilisateur actuel",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filtrage activé",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Champs",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Choisir un champ",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Formulaire",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Balise d'article",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Paramètres disponibles:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "La valeur n'existe pas",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Valeur",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Valeur dans le PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "valeur",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Ajouter",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Utiliser les images comme des libellé",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Sélectionner image",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Sélectionner image",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Sélectionner icone",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Icônes par défaut",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Utiliser l'icône par défaut",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Ajouter aux favoris",
  "VIEW_EDITOR_ADVANCED_MODE": "Mode avancé",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Défaut",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Favoris",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "Aucun favori défini.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Ajouter un widget favori",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Nom favori",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Nom",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Valeur original",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Valeur favorite",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "Il n'y a aucun changement sur le widget existant.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "Ajouter Email",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Supprimer Widget",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce widget favori?",
  "ERROR_SOMETHING_WENT_WRONG": "Quelque chose s'est mal passé.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Veuillez réessayer plus tard. Si le problème persiste, contactez-nous",
  "ACCESS_DENIED_MESSAGE": "Accès refusé",
  "ACCESS_DENIED_DESCRIPTION": "Cette Plateforme est destinée aux administrateurs. Contactez le propriétaire du compte ou connectez-vous à <a href='{{webclientUrl}}'>Web App</a> pour remplir les formulaires.",
  "ACCESS_DENIED_LOGOUT": "Déconnexion",
  "ACCESS_DENIED_TO_LOGIN": "Aller à la connexion",
  "PAGE_NOT_FOUND_TITLE": "Page introuvable",
  "PAGE_NOT_FOUND_DESCRIPTION": "La page recherchée n'a pas pu être trouvée",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Retour à l'aperçu de la Plateforme",
  "CUSTOMER_RESOURCES_TITLE": "Ressources",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Type",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Galerie",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Nom",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Télécharger un fichier",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "Fichier",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Télécharger à partir de l'url",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Supprimer les ressources",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "Êtes-vous sûr de vouloir supprimer cette ressource? Cette action ne peut être annulée",
  "RESOURCES_UPLOAD_FAILURE": "Echec de téléchargement",
  "RESOURCES_UPLOAD_INVALID_FILE": "Le fichier sélectionné n'est pas une image.",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "Le fichier sélectionné dépasse la limite de fichier de 5 Mo.",
  "REGISTRATIONS": "Enregistrements",
  "START": "Commencer",
  "CONFIGURE": "Configurer",
  "UPGRADE": "Passer à une formule supérieure",
  "STEP": "Étape",
  "CREATED": "Créé",
  "CREATE": "Créer",
  "READ": "Lire",
  "DELETE": "Supprimer",
  "REMOVE": "Supprimer",
  "SUBMIT": "Soumettre",
  "CANCEL": "Annuler",
  "CLOSE": "Fermer",
  "SEND": "Envoyer",
  "SAVE": "Sauvegarder",
  "RENAME": "Renommer",
  "RENAME_LINKED_SUBFORM_TITLE": "Renommer sous-formulaire lié",
  "SAVE_PAYMENT_METHOD": "Confirmer et utiliser moyen de paiement",
  "SELECTED_PAYMENT_METHOD": "Moyen de paiement sélectionné",
  "SELECT_OTHER_PAYMENT_METHOD": "Utiliser un autre moyen de paiement",
  "LOADING": "Chargement",
  "SAVE_AND_CLOSE": "Sauvegarder & Femer",
  "SAVE_AND_PUBLISH": "Sauvegarder & Publier",
  "VALIDATE": "Valider",
  "EDIT": "Modifier",
  "UNDO": "Annuler",
  "BACK": "Retour",
  "NEXT": "Suivant",
  "PREVIOUS": "Précédent",
  "CLEAR": "Effacer",
  "SELECT": "Sélectionner",
  "UPLOAD": "Télécharger",
  "BUSY_UPLOADING": "Chargement en cours ...",
  "UPDATE": "Mettre à jour",
  "ADD": "Ajouter",
  "COPY": "Copier",
  "SHARE": "Partqger",
  "ACTIONS": "Actions",
  "THEME": "Thème",
  "ON": "On",
  "OFF": "Off",
  "OPTIONS": "Options",
  "INSTALLED": "Installée",
  "EMPTY": "Vide",
  "QUARTER_HOUR": "Toutes les 15 minutes",
  "HALF_HOUR": "Toutes les 30 minutes",
  "DAILY": "Quotidien",
  "WEEKLY": "Mensuel",
  "HOURLY": "Par heure",
  "API": "API",
  "NAME": "Nom",
  "EMAIL": "Email",
  "DATE": "Date",
  "DUE_DATE": "Date d'échéance",
  "HOOKS": "Intégration",
  "NONE": "Aucun",
  "NEVER": "Jamais  ",
  "USER": "Utilisateur",
  "GROUP": "Groupe",
  "VIA_GROUP": "via groupe(s)",
  "BETA": "beta",
  "LOCKED": "Verrouillé",
  "URL": "URL",
  "FILE": "Fichier",
  "YES": "Oui",
  "NO": "Non",
  "DEFAULT": "Défaut",
  "SEARCH": "Rechercher",
  "DETAILS": "Détails",
  "UNSAVED_CHANGES_TITLE": "Modifications non enregistrées",
  "UNAVAILABLE": "Indisponible",
  "VERSION": "Version",
  "PRICE": "Prix",
  "FREE": "Gratuit",
  "ADDRESS": "Adresse",
  "ADDRESS_LINE_1": "Adresse",
  "ADDRESS_LINE_1_PLACEHOLDER": "123, rue principale",
  "CITY": "Ville",
  "COUNTRY": "Pays",
  "ZIP_CODE": "Code Postal",
  "OK": "OK",
  "INTERNAL_ERROR": "Erreur interne",
  "OPEN": "Ouvrir",
  "STALLED": "Calé",
  "ERROR": "Erreur",
  "SUCCESS": "Succes",
  "RETRY": "Réessayer",
  "SKIP": "Passer",
  "IN_PROGRESS": "En cours",
  "COMPLETED": "Complété",
  "DECLINED": "Refusé",
  "BLOCKED": "L'un des crochets a échoué",
  "NOT_VALIDATED": "Non validé",
  "INVALID": "Invalide",
  "VALID": "Valide",
  "VERIFIED": "Vérifié",
  "UNVERIFIED": "Non vérifié",
  "TYPE": "Type",
  "VALUE": "Valeur",
  "ONLINE": "En ligne",
  "OFFLINE": "Hors Ligne",
  "ADVANCED": "Avancé",
  "PREVIEW": "Aperçu",
  "BY": "Par",
  "UNTIL": "Avant",
  "COUPON": "Coupon",
  "COUPON_ADD_ERROR": "Coupon invalide",
  "DISCOUNT": "Réduction",
  "COUPON_REMOVE_TITLE": "Supprimer coupon",
  "COUPON_REMOVE_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce coupon ?",
  "PERMISSIONS": "Permissions",
  "THIS_MIGHT_TAKE_SOME_TIME": "Cela pourrait prendre du temps",
  "FIELD_ERROR_REQUIRED": "Ce cha,ps est requis",
  "FIELD_ERROR_MIN_LENGTH": "Le champ doit contenir au moins {{minLength}} caractères",
  "FIELD_ERROR_MAX_LENGTH": "Le champ dépasse la longueur maximale {{maxLength}}",
  "FIELD_ERROR_MIN": "La valeur est inférieure à la valeur minimale {{min}}",
  "FIELD_ERROR_MAX": "La valeur est supérieure à la valeur maximale {{max}}",
  "FIELD_ERROR_EMAIL": "La valeur n'est pas une adresse e-mail valide",
  "BILLING_BANK_ACCOUNT_NUMBER": "Compte bancaire (IBAN)",
  "BILLING_VAT_NUMBER": "Numéro d'identification TVA",
  "BILLING_VAT_NUMBER_CHECKING": "Vérification",
  "BILLING_VAT_NUMBER_VALID": "Valide",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "Pourcentage TVA",
  "BILLING_VAT_NUMBER_INVALID": "Invalide",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "Incompatibilité de pays",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "Le pays de votre numéro d'identification TVA et le pays que vous avez sélectionné ne correspondent pas. Veuillez corriger ceci sinon nous ne sommes pas en mesure de traiter votre commande.",
  "BILLING_COC_NUMBER": "Chambre du Commerce",
  "BILLING_EMAIL": "E-mail de facturation",
  "VAT_DISCLAIMER": "Les prix affichés excluent la TVA. Le montant de la TVA sera affiché après le processus de paiement et indiqué sur les factures.",
  "RECEIVE_NEWS_LETTER": "Newsletter",
  "RECEIVE_NEWS_LETTER_MESSAGE": "Je veux recevoir la newsletter",
  "TERMS_OF_CONDITION_DOWNLOAD": "Télécharger les conditions d'utilisation",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Sous-formulaire",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "Nouveau nom de formulaire",
  "FORM_BUILDER_SUB_FORM_NAME": "Nom du formulaire",
  "TOOLTIP_ADD_APPLICATION": "Un dossier contient vos formulaires. <br> <br> Chaque dossier possède ses propres paramètres, autorisations d'utilisateur et peut être publié individuellement.",
  "TOOLTIP_ADD_APPLICATION_NAME": "Ce nom sera affiché dans l'application.",
  "TOOLTIP_ADD_FORM": "Ajouter un formulaire pour faire des inscriptions dans l'application. <br> <br> Chaque formulaire a un certain nombre de champs, de règles et de ses propres paramètres.",
  "TOOLTIP_ADD_FORM_NAME": "Ce nom sera affiché dans l'application.",
  "TOOLTIP_COPY_FORM_NAME": "Utilisez éventuellement un nom différent pour la copie.",
  "TOOLTIP_COPY_FORM_DESTINATION": "La copie sera placée dans ce dossier.",
  "TOOLTIP_DELETE_FORM": "<b> Cette action est irréversible </b>. <br> Notez que ses inscriptions seront conservées.",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a> Saisissez une poignée </a> pour modifier l'ordre de vos formulaires dans le dossier. <br> <br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "Le dossier a été archivé. Retournez cette action en cliquant sur le bouton <a> Annuler </a>.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "Le dossier a été créé, mais n'est pas encore publié. <br> <br> Si vous voulez voir ce dossier dans l'application, vous devez le <a> publier </a> en premier.",
  "TOOLTIP_APP_MANAGE_USERS": "Spécifiez les groupes et / ou les utilisateurs qui verront ce dossier dans l'application après s'être connectés.",
  "TOOLTIP_VIEW_SETTING_NAME": "Ce nom sera affiché dans l'application.",
  "TOOLTIP_VIEW_SETTING_ICON": "Cette icône est utilisée dans l'application lorsque le formulaire est affiché dans une liste",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Activer pour afficher ce formulaire dans la présentation générale <a> Formulaires </a>.",
  "TOOLTIP_VIEW_SETTING_INTERACTION": "Choisissez comment et quand ce formulaire sera téléchargé: <br> <br> <a> Téléchargement immédiat (par défaut): </a> <br> Lorsque vous enregistrez le formulaire, l'application le télécharge immédiatement (si vous êtes connecté à Internet) ) <br> <br> <a> Téléchargement manuel: </a> <br> Les formulaires enregistrés sont stockés sur votre appareil et peuvent être téléchargés manuellement à partir de l'écran Enregistrements.",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Choisir un mode d'enregistrement: <br> <br> <a> Enregistrer et fermer (par défaut): </a> <br> Un simple bouton <b> Enregistrer </ b> apparaîtra, qui enregistre et ferme le formulaire (quand valide). <br> <br> <a> Enregistrer et fermer & Enregistrer et nouveau: </a> <br> Les deux boutons <b> Enregistrer </ b> et <b> Enregistrer et nouveau </ b> apparaissent , donc vous pouvez continuer à ajouter des enregistrements du même formulaire. <br> <br> <a> No save: </a> <br> Rend ce formulaire en lecture seule. Aucun bouton n'apparaît.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "Ceci définit la façon dont chaque enregistrement, brouillon ou tâche pour ce formulaire est affiché dans l'application. Utilisez-les placeholders de champs qui vous permettent de reconnaître chaque élément.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Activer pour rechercher ce formulaire dans l'application.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Activer pour rechercher uniquement les enregistrements qui ont été soumis par l'utilisateur actuellement connecté.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Activer pour filtrer certains champs des formulaires trouvés.",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Exclure les valeurs suivantes dans le résultat",
  "TOOLTIP_FORM_RULE_CONDITION": "Spécifiez une ou plusieurs <a> Conditions </a> à remplir pour exécuter les <a> Actions </a>. <br> <br> De cette manière, des champs non pertinents ou inapplicables peuvent être ignorés sous certaines conditions. br> <b> Par exemple: </ b> Masquer <a> nom du partenaire </a> lorsque <a> état civil </a> <a> 'célibataire' </ a >",
  "TOOLTIP_FORM_RULE_ACTION": "Indiquez une ou plusieurs <a> actions </a> à exécuter lorsque toutes les <conditions> sont satisfaites.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Nom qui sera utilisé comme nom de colonne dans la <a> grille d'inscription </a>. Par défaut, cette valeur est renseignée automatiquement en fonction du <b> texte de l'étiquette </ b> <br> <br> <b> Par exemple: </ b> <br> Si vous définissez l'étiquette sur <a> 'Quel âge avez-vous?'</a>, le nom des données serait <a> howOldAreYou </a>. Vous voudrez peut-être changer cela pour <a> 'âge' </a>.",
  "TOOLTIP_ADD_DATA_SOURCE": "Ajouter une source de données",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Nom de la source de données",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Sélectionnez un fichier Excel à importer. Assurez-vous que l'extension pour le fichier Excel est `.xlsx`. Les fichiers `.xls` ne seront pas importés.",
  "TOOLTIP_HOOK": "La configuration spécifique pour ce crochet sur ce formulaire",
  "TOOLTIP_DIRECT_FORM_LINK": "Créez une URL unique pour votre formulaire, que vous pouvez partager avec d'autres personnes. <br> <br> En utilisant cette URL, les personnes sans compte peuvent remplir votre formulaire.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Partagez votre formulaire sur notre marché, afin que les autres puissent l'utiliser comme modèle.",
  "RULE_HIDDEN": "Caché",
  "RULE_VISIBLE": "Visible",
  "RULE_GETS_VALUE": "Obtient la valeur",
  "WIDGET_LICENSE_INVALID": "Licence invalide",
  "WIDGET_LICENSE_BRAND": "Marque",
  "WIDGET_LICENSE_TYPE": "Type",
  "WIDGET_LICENSE_POWER": "Pouvoir",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "première admission",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "Euro class",
  "SEGMENT": "Secteur",
  "SEGMENT_AUTOMOTIVE": "Automobile",
  "SEGMENT_CONSTRUCTION": "Construction",
  "SEGMENT_FACILITY": "Établissement",
  "SEGMENT_FINANCIAL": "Financier",
  "SEGMENT_TRADE_AND_INDUSTRY": "Le commerce et l'industrie",
  "SEGMENT_GOVERNMENT": "Gouvernement",
  "SEGMENT_HEALTH_CARE": "Soins de santé",
  "SEGMENT_INSTALLATION": "Installation",
  "SEGMENT_OTHER": "Autre",
  "GENERIC": "Général",
  "FACILITY": "Installation",
  "CONSTRUCTION": "Construction",
  "SALES": "Ventes",
  "HR": "RH",
  "AUTOMOTIVE": "Automobile",
  "SAFETY": "Sécurité",
  "LOGISTICS": "Logistique",
  "INSTALLATION": "Installation",
  "MAINTENANCE": "Service & Maintenance",
  "SECURITY": "Sécurité",
  "REAL_ESTATE": "Immobilier",
  "HEALTHCARE": "Santé",
  "AGRICULTURE": "Agriculture",
  "INDUSTRY": "Industrie",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Autorisations insuffisantes",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "Vous êtes connecté à la plate-forme. Cette section est uniquement accessible aux administrateurs de votre organisation. Si vous avez besoin d'autorisations d'administration, veuillez contacter votre propre administrateur. Si vous n'avez qu'à remplir des formulaires, veuillez exécuter l'application sur votre smartphone ou votre tablette.",
  "FORM_SUBMISSION_SERVER_ERROR": "Le formulaire n'a pas pu être soumis. Cela peut être un problème de serveur. Veuillez réessayer plus tard",
  "UNKNOWN_ERROR": "Une erreur inconnue est survenue",
  "ALL": "tout",
  "ANY": "quelconque",
  "GLOBAL_ERRORS_TITLE": "Erreurs de validation",
  "USE_TEMPLATE": "Utiliser modèle",
  "LANGUAGE_EN": "Anglais",
  "LANGUAGE_NL": "Néerlandais",
  "LANGUAGE_DE": "Allemand",
  "LANGUAGE_ES": "Espagnole",
  "LANGUAGE_RU": "Russe",
  "LANGUAGE_PT": "Portugais",
  "LANGUAGE_FR": "Français",
  "LANGUAGE_IT": "Italienisch",
  "LANGUAGE_CA": "Catalan",
  "UNSAVED_CHANGES": "Il y a des changements non sauvegardés qui seront écartés si vous continuez",
  "CHANGE_FILE": "Changer le filier",
  "AUTHENTICATED": "Authentifié",
  "LANGUAGE": "Language",
  "TAGS": "Tags",
  "SELECT_TAGS": "Seleccionar tags",
  "SELECT_ALL": "Sélectionner tout",
  "SEARCH_TEMPLATES": "Rechercher modèles",
  "CUSTOMER": "Client",
  "PARTNER": "Partenaire",
  "CUSTOM": "Personnalisé",
  "AMOUNT": "Montant",
  "PHONE": "Numéro de téléphone",
  "REGION": "Région",
  "STATUS": "Statut",
  "ACTIVE": "Actif",
  "INACTIVE": "Inactif",
  "DISABLED": "Inactif",
  "ACCOUNT": "Compte",
  "DOCUMENTATION": "Documentation",
  "COMPANY_NAME": "Nom de l’Entreprise",
  "COMPANY": "Entreprise",
  "SHOW_MORE": "Afficher plus",
  "WEBSITE": "Site web",
  "CLICK_HERE": "Cliquez ici",
  "MAIL_NOTIFICATION": "Notification par mail",
  "CHECKED": "Vérifié",
  "UNCHECKED": "Non vérifié",
  "ACCOUNT_NAME": "Entreprise",
  "ACCOUNT_NAME_PLACEHOLDER": "Par exemple. Company Ltd ou John Smith",
  "FEATURE_HOOKS": "Intégrations",
  "FEATURE_HOOKS_MESSAGE": "<h4>Les intégrations sont utilisés pour traiter les données après avoir envoyé les formulaires</h4><p>Créez un flux de travail, envoyez des documents vers OneDrive, envoyez des données vers Google Sheets ou Word</p><p>Cette fonctionnalité devient disponible en passant à la formule <b>Branche</b>.</p>",
  "FEATURE_MANUAL_DATASOURCES": "Importation de données",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Importez des données en utilisant Excel, Google Sheets ou une URL (JSON)</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Feuille</b>.</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Importation de données automatique",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Importez des données automatiquement en ajoutant une nouvelle saisie à votre Google Sheet ou URL</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Branche</b>.</p>",
  "FEATUER_ADVANCED_PDF": "PDF personnalisé",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Concevez votre rapport PDF en utilisant le code HTML</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Branche</b>.</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Gestion avancée des utilisateurs",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Donnez accès à des groupes à certaines parties de la Plateforme</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Arbre</b>.</p>",
  "FEATURE_PDF_LOGO": "Votre logo sur le PDF",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Remplacez le logo MoreApp par le vôtre</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Feuille</b>.</p>",
  "FEATURE_MULTIPLE_EMAIL": "E-mails multiples",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Ajoutez de multiples e-mails à un formulaire</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Feuille</b>.</p>",
  "FEATURE_WIDGETS": "Widgets Pro",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Commencez à utiliser des widgets comme Catalogue, Calcul, et Rating</h4><p>Cette fonctionnalité devient disponible en passant à la formule <b>Feuille</b>.</p>",
  "FEATURE_SEARCH": "Fonction Recherche dans l'application",
  "FEATURE_SEARCH_MESSAGE": "<h4>Grâce à la fonctionnalité Recherche, rechercher des formulaires remplis est facile</h4><p>La fonctionnalité Recherche permet de retrouver des enregistrements envoyés auparavant et de l'utiliser pour un nouvel enregistrement</p><p>Cette fonctionnalité devient disponible en passant à la formule <b>Feuille</b>.</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Personnaliser modèle",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Créez vos propres modèles et gagnez du temps sur la création de nouveaux formulaires.</h4><p>Cette fonctionnalité devient disponible en passant à la formule<b>Arbre</b>.</p>",
  "FEATURE_WEBHOOKS": "Webhooks",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Utilisez les webhooks pour enregistrer des évènements dans votre compte.</h4><p>À chaque évènement, nous envoyons un message à l'URL configurée, afin que vous puissiez effectuer une action. Cela vous permet d'implémenter des flux de travail complexes.</p><p><b>Exemple</b>: configurez un webhook afin qu'il se déclenche à chaque fois qu'un formulaire est envoyé, pour enregistrer les données dans votre propre base de données.</p><br><p>Cette fonctionnalité devient disponible en passant à la formule <b>Branche</b>.</p>",
  "SWITCH_PLAN_CTA": "Sélectionnez une nouvelle Formule",
  "SWITCH_PLAN_CONTACT_OWNER": "Contactez le Propriétaire de Compte",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Demande d'actualisation de notre compte MoreApp afin d'utiliser la fonctionnalité \"{{feature}}\"",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Sélectionner un compte de service...",
  "SERVICE_ACCOUNTS_NEW_LINK": "Connecter",
  "SERVICE_ACCOUNTS_NEW_TEXT": "un nouveau compte de service",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "Pas de '{{provider}}' de compte de services configuré.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Démarrez une conversation",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "Merci pour votre feedback !",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "Avons-nous résolu vos questions ?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": " qualifiriez-vous votre expérience ?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "En quoi pouvons-nous nous améliorer ?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "Merci pour votre feedback !",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "Insérez votre note ici !",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Notez vos suggestions ici",
  "ADD_USER_OR_GROUP": "Ajouter un utilisateur ou un groupe",
  "ADD_USER_TO_GROUP": "Ajouter un utilisateur",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Sélectionner un ou plusieurs utilisateurs",
  "SELECT_ROLE": "Sélectionner autorisation",
  "ALL_USERS_ADDED_TO_GROUP": "Vous avez déjà ajouté tous les utilisateurs existants à ce groupe",
  "WHATS_NEW": "Nouveauté",
  "WIDGET_LABEL": "Label",
  "WIDGET_LABEL_DESCRIPTION": "Utilisez le widget <b>Label</b> pour afficher un séparateur à l'intérieur de votre <a>formulaire</a>.<br><br><b>Remarque</b><br>Cela ne fait pas partie de l'<a>enregistrement</a>.",
  "WIDGET_TEXT": "Texte",
  "WIDGET_TEXT_DESCRIPTION": "Utilisez le widget- <b>Texte</b> pour laisser l'utilisateur remplir une seule ligne de texte.",
  "WIDGET_TEXT_AREA": "Zone de Texte",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Utilisez le widget-<b>Zone de Texte</b> pour laisser l'utilisateur remplir des phrases complètes.",
  "WIDGET_NUMBER": "Nombre",
  "WIDGET_NUMBER_DESCRIPTION": "Utilisez le widget-<b>Nombre</b> pour laisser l'utilisateur remplir un nombre entier.",
  "WIDGET_RADIO": "Radio",
  "WIDGET_RADIO_DESCRIPTION": "Utilisez le widget-<b>Radio</b> pour laisser l'utilisateur choisir l'une de vos valeurs prédéfinies.<br><br><b>Par exemple :</b><br>Homme / Femme.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Utilisez le widget-<b>Checkbox</b> pour permettre à l'utilisateur de marquer la propriété Vraie ou Fausse.",
  "WIDGET_PHOTO": "Photo",
  "WIDGET_PHOTO_DESCRIPTION": "Utilisez le widget-<b>Photo</b> pour permettre à l'utilisateur de réaliser une photo avec la <a>Caméra</a> de l'appareil ou d'en ajouter une depuis la <a>Bibliothèque</a>.",
  "WIDGET_SIGNATURE": "Signature",
  "WIDGET_SIGNATURE_DESCRIPTION": "Utilisez le widget-<b>Signature</b> pour laisser l'utilisateur dessiner sa signature.",
  "WIDGET_HEADER": "En-tête",
  "WIDGET_HEADER_DESCRIPTION": "Utilisez le widget-<b>En-tête</b> pour ajouter des en-têtes à votre formulaire.<br><br><b>Remarque</b><br>Cela ne fait pas partie des <a>données d'enregistrement</a>.",
  "WIDGET_DATE": "Date",
  "WIDGET_DATE_DESCRIPTION": "Utilisez le widget-<b>Date</b> pour permettre à l'utilisateur de renseigner une date.",
  "WIDGET_DATETIME": "Date-Heure",
  "WIDGET_DATETIME_DESCRIPTION": "Utilisez le widget-<b>Date-heure</b> pour permettre à l'utilisateur de renseigner une date et une heure.",
  "WIDGET_TIME": "Heure",
  "WIDGET_TIME_DESCRIPTION": "Utilisez le widget-<b>Heure</b> pour permettre à l'utilisateur de renseigner une heure.",
  "WIDGET_EMAIL": "E-mail",
  "WIDGET_EMAIL_DESCRIPTION": "Utilisez le widget-<b>E-mail</b> pour laisser l'utilisateur renseigner une adresse e-mail valide.<br><br><b>Note</b><br>Ce champ peut être utilisé comme <a>Destinataire Dynamique</a> dans les <a>paramètres de l'e-mail</a>.",
  "WIDGET_PHONE": "Téléphone",
  "WIDGET_PHONE_DESCRIPTION": "Utilisez le widget-<b>Téléphone</b> pour permettre à l'utilisateur de renseigner un numéro de téléphone valide.",
  "WIDGET_LOOKUP": "Lookup",
  "WIDGET_LOOKUP_DESCRIPTION": "Utilisez le widget-<b>Lookup</b> pour laisser l'utilisateur choisir une (ou plusieurs) valeurs prédéfinies.",
  "WIDGET_LIST_ITEM": "List Item",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Utilisez le widget <b>List Item</b> pour créer un lien vers un autre <a>formulaire</a>.",
  "WIDGET_SUBFORM": "Sous-formulaire",
  "WIDGET_SUBFORM_DESCRIPTION": "Utilisez le widget-<b>Sous-formulaire</b> pour permettre à l'utilisateur d'ajouter <a>les mêmes widgets plusieurs fois</a> à votre formulaire.<br><br><b>Par exemple :</b><br>Ajouter plusieurs <a>matériaux utilisés, heures travaillées, photos ou pièces de construction</a> à un <a>enregistrement</a>. Il est également possible de relier des sous-formulaires et de travailler avec des sous-formulaires.",
  "WIDGET_BARCODE": "Code-barres",
  "WIDGET_BARCODE_DESCRIPTION": "Utilisez le widget-<b>Codes-barres</b> pour permettre à l'utilisateur de scanner un code-barres à l'aide de la <a>caméra</a> de son appareil.<br><br><b>Remarque :</b><br>Prend en charge les codes QR, UPC, EAN, CODE_39 et bien d'autres.",
  "WIDGET_SLIDER": "Curseur",
  "WIDGET_SLIDER_DESCRIPTION": "Utilisez le widget-<b>Curseur</b> pour permettre à l'utilisateur de faire glisser un curseur pour choisir un nombre entre vos valeurs min et max définies.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Utilisez le widget-<b>HTML</b> pour ajouter des balises personnalisées à votre formulaire. (Pour les utilisateurs techniques uniquement)",
  "WIDGET_IMAGE": "Image",
  "WIDGET_IMAGE_DESCRIPTION": "Utilisez le widget-<b>Image</b> pour afficher une image à l'intérieur de votre <a>formulaire</a>.<br><br><b>Remarque</b><br>Cela ne fait pas partie de l'<a>Enregistrement</a>. Si vous souhaitez que les utilisateurs puissent joindre une photo, utilisez le widget-<a>Photo</a>.",
  "WIDGET_SEARCH": "Recherche",
  "WIDGET_SEARCH_DESCRIPTION": "Utilisez le widget-<b>Recherche</b> en combinaison avec les bases de données pour permettre à vos utilisateurs de sélectionner des données importées. Par exemple, des informations sur les clients, les produits et les employés.",
  "WIDGET_RDW": "License Plate (NL)",
  "WIDGET_RDW_DESCRIPTION": "Utilisez le <b>Widget Plaque d'immatriculation (NL)</b> pour permettre aux utilisateurs de saisir une plaque d'immatriculation néerlandaise. Le widget affichera des informations supplémentaires sur le véhicule. <b>Note</b> Une connexion Internet est nécessaire pour ajouter les informations supplémentaires.",
  "WIDGET_PIN": "Épingle",
  "WIDGET_PIN_DESCRIPTION": "Utilisez le widget-<b>Épingle</b> pour permettre à l'utilisateur d'ajouter une ou plusieurs épingles sur une image/carte donnée. Chaque épingle que vous placez ouvre un <a>sous-formulaire</a> pour que l'utilisateur puisse ajouter des informations supplémentaires à propos de l'épingle.",
  "WIDGET_HELP": "Aide",
  "WIDGET_HELP_DESCRIPTION": "Le widget-Aide vous permet d'ajouter des informations supplémentaires à n'importe quel widget. Lorsqu'un utilisateur clique sur le widget d'aide, une fenêtre contextuelle s'affiche avec vos informations supplémentaires.",
  "WIDGET_RATING": "Notation",
  "WIDGET_RATING_DESCRIPTION": "Le widget-Notation permet aux utilisateurs de noter un sujet donné avec un certain nombre d'étoiles. Il est possible de configurer une échelle de notation entre deux et dix.",
  "WIDGET_CATALOGUE": "Catalogue",
  "WIDGET_CATALOGUE_DESCRIPTION": "Créez votre propre application de commande avec le widget-Catalogue. Remplissez un panier avec des produits comme vous le faites dans une boutique en ligne. Utilisez une base de données avec les colonnes obligatoires suivantes : id, name, priceExVat (en centimes), description. Facultatif : photo, thumbnail et vatRate. La photo et la vignette sont des URL, elles ne s'affichent donc que si votre appareil dispose d'une connexion Internet. Vous pouvez ajouter autant de colonnes supplémentaires que vous le souhaitez à la base de données. Elles seront visibles sur la page de détails du produit.",
  "WIDGET_SEARCH_WITH_GPS": "Recherche avec GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Recherche avec localisation GPS",
  "WIDGET_TIME_DIFFERENCE": "Écart temporel",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "Ce widget calcule la différence entre deux widgets-Heure ou Date-Heure.",
  "WIDGET_PAYMENT": "Paiement",
  "WIDGET_PAYMENT_DESCRIPTION": "Permet à l'utilisateur d'effectuer un achat in-app pour payer le formulaire.",
  "WIDGET_STOPWATCH": "Chronomètre",
  "WIDGET_STOPWATCH_DESCRIPTION": "Utilisez le widget-Chronomètre pour suivre le temps dans votre formulaire. Le résultat de ce widget sera par exemple : 00:16.47.",
  "WIDGET_SMILEY": "Smiley",
  "WIDGET_SMILEY_DESCRIPTION": "Widget pour donner une note basée sur les smileys.",
  "WIDGET_CURRENT_LOCATION": "Localisation actuelle",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "Ce widget obtient automatiquement votre localisation actuelle grâce au GPS. Le résultat sera quelque chose comme \"Museumstraat 1, 1071 XX Amsterdam, Pays-Bas\". Le résultat peut toujours être modifié manuellement, au cas où il ne serait pas tout à fait correct.",
  "WIDGET_DRAWING": "Dessin",
  "WIDGET_DRAWING_DESCRIPTION": "Avec ce widget, vous pouvez ajouter des dessins, des lignes et du texte à une photo.",
  "WIDGET_VIDEO": "Vidéo",
  "WIDGET_VIDEO_DESCRIPTION": "Ce widget vous permet d'enregistrer ou de sélectionner une vidéo. Ce widget donne lieu à une URL dans votre rapport PDF pour télécharger la vidéo.",
  "WIDGET_ZIPCODE": "Code postal",
  "WIDGET_ZIPCODE_DESCRIPTION": "Le widget-Code Postal fournit des données d'adresse à partir d'un code postal saisi.",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "Le widget-IBAN valide les numéros IBAN.",
  "WIDGET_DRAWING_FREE": "Drawing Free",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "A copy of the Drawing widget, but free. For the customers of the Blauwdruk partner.",
  "WIDGET_CALCULATION": "Calcul",
  "WIDGET_CALCULATION_DESCRIPTION": "Le widget-Calcul permet d'effectuer des opérations telles que l'addition, la soustraction, la multiplication et la division sur un ou plusieurs widgets.",
  "WIDGET_POSTCODE_NL": "Code Postal (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "Ce widget ne fonctionne que pour les codes postaux néerlandais ! Avec le widget-Code Postal (NL), vous récupérez les informations d'adresse basées sur un code postal et un numéro de maison. Vous avez besoin d'une connexion internet pour récupérer les données. Lorsque vous êtes hors-ligne, vous pouvez ajouter les données manuellement.",
  "WIDGET_ADVANCED_SEARCH": "Recherche Avancée",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Le widget-Recherche avancée vous permet de colorer les résultats de vos recherches dans l'application. Vous pouvez configurer la couleur qui doit être utilisée lorsqu'un résultat de recherche correspond à une requête. Vous pouvez définir les couleurs à l'aide de descripteurs (comme 'rouge' ou 'vert') ou de codes de couleur hexadécimaux (comme #1dc4c2)",
  "WIDGET_FILE": "Fichier",
  "WIDGET_FILE_DESCRIPTION": "Ce widget vous permet d'ajouter des fichiers à votre formulaire. Ce widget donne lieu à une URL dans votre rapport PDF pour télécharger les fichiers.",
  "WIDGET_LOCATION": "Localisation",
  "WIDGET_LOCATION_DESCRIPTION": "Ce widget vous permet de sélectionner un emplacement sur une carte.\n\nLe résultat peut toujours être modifié manuellement, au cas où il ne serait pas tout à fait correct.",
  "WIDGET_PROPERTY_PIN_NAME": "Nom",
  "WIDGET_PROPERTY_PIN_FORM": "Formulaire",
  "WIDGET_PROPERTY_PIN_ICON": "Icône",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Balisage de l'élément",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "Identifiant de suivi",
  "WIDGET_PROPERTY_LABEL": "Label",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "Le nom qui est affiché au-dessus du widget",
  "WIDGET_PROPERTY_OPERATOR": "Opérateur",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "Opérateur utilisé dans le calcul.",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Termes de calcul",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "Tous les champs qui doivent être inclus dans le calcul.",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Valeur initiale",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Valeur initiale pour le calcul.",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Précision décimale",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "Le nombre de chiffres que comporte le calcul.",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "Pas de valeur pour terme non valide",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "Le widget ne doit pas produire de valeur lorsque l'un des termes n'est pas valide (c'est-à-dire qu'il ne s'agit pas d'un nombre).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Base de Données Catalogue",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Sélectionnez une de vos bases de données à utiliser pour le catalogue. Assurez-vous que la base de données est conforme à la configuration requise.",
  "WIDGET_PROPERTY_CURRENCY": "Devise",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Configure la devise à utiliser, mais seulement si la valeur est un prix",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "Pourcentage TVA",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "Le pourcentage de TVA qui sera appliqué à tous les articles du catalogue.",
  "WIDGET_PROPERTY_SHOW_PRICES": "Afficher les prix",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Cochez cette case si vous voulez que les prix unitaires et totaux soient affichés dans l'application et le PDF.",
  "WIDGET_PROPERTY_SHOW_VAT": "Afficher TVA",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Cochez cette case si vous voulez que les valeurs de TVA apparaissent dans l'application et le PDF.",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Autoriser les remarques",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Cochez cette case pour permettre aux utilisateurs d'ajouter une remarque lors de l'ajout d'un élément.",
  "WIDGET_PROPERTY_RATE_ICON": "Icône notation",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Sélectionnez l'icône utilisée pour la notation, par exemple des cœurs",
  "WIDGET_PROPERTY_RATE_SCALE": "Échelle notation",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Sélectionnez la quantité d'icônes affichées.",
  "WIDGET_PROPERTY_TITLE": "Titre",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "Le titre de l'explication donnée.",
  "WIDGET_PROPERTY_EXPLANATION": "Explication",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "L'explication.",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Utiliser le texte du bouton d'aide",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Lorsqu'il est sélectionné, le texte défini ci-dessous s'affichera devant un point d'interrogation.",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Texte du bouton d'aide",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Le texte qui est affiché avant un point d'interrogation lorsque la case à cocher ci-dessus est sélectionnée.",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "La valeur calculée est un prix",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Formatez le résultat sous forme de prix.",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Ignorer les widgets masqués",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "Ce paramètre vous permet d'ignorer les widgets masqués lorsque vous effectuez des calculs. Il est disponible à partir de la version 7.0.32.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Utiliser un scanner de codes-barres",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Cochez cette case pour permettre aux utilisateurs d'effectuer des recherches à partir d'un code-barres scanné.",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Valeur par défaut",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "Cette valeur est déjà prédéfinie.",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Requête de Recherche par Défaut",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "L'ouverture de la recherche utilisera cette requête prédéfinie.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Texte du label",
  "WIDGET_PROPERTY_START_TIME": "Heure de départ",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Heure de départ à utiliser dans le calcul.",
  "WIDGET_PROPERTY_END_TIME": "Heure de fin",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "Heure de fin à utiliser dans le calcul.",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Image d'arrière-plan",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Durée maximale en secondes",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "La durée maximale de la vidéo en secondes.",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Qualité Vidéo",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Choisissez la qualité vidéo que vous préférez.",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "Type de Fichier",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Sélectionnez le type de fichier qui peut être téléchargé.",
  "WIDGET_PROPERTY_VALUE": "Valeur",
  "WIDGET_PROPERTY_PLACEHOLDER": "Balise",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Exemple de saisie qui n'est affiché que lorsque le champ est vide et qui est supprimé lorsqu'il est rempli.",
  "WIDGET_PROPERTY_MAX_LENGTH": "Longueur maximum",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Nombre maximal de caractères. Une erreur de validation s'affichera dans l'application mobile si cette valeur est dépassée.",
  "WIDGET_PROPERTY_MIN_LENGTH": "Longueur minimum",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Nombre minimum de caractères. Une erreur de validation s'affichera dans l'application mobile si la longueur de cette valeur est trop courte.",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Valeur minimum",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "L'application mobile ne permettra pas de choisir une valeur inférieure à celle-ci.",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Valeur maximum",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "L'application mobile ne permettra pas de choisir une valeur supérieure à celle-ci.",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Utiliser des images comme intitulé",
  "WIDGET_PROPERTY_OPTIONS": "Options",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Définissez les options qui peuvent être sélectionnées dans l'application mobile.",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Alignement vertical",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Cochez pour aligner les options verticalement plutôt qu'horizontalement.",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Coché par défaut",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "Si la case est cochée, ce champ sera coché lorsque vous ouvrirez un nouveau formulaire.",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Qualité Photo",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Choisissez la qualité de photo que vous préférez.<br><br><a>Meilleure possible (recommandée)</a><br>Pas de réduction d'échelle, pas de compression. Pourrait causer des problèmes d'envoi d'emails avec beaucoup de photos.<br><br><a>Chargement Rapide</a><br>Réduction d'échelle à 1024x1024 max, compression de 75%. Pourrait causer des problèmes de mémoire sur les appareils bas de gamme.<br><br><a>Haute Qualité</a><br>Mise à l'échelle à 1920x1920 max, compression de 10%. Pourrait causer des problèmes de mémoire sur les appareils bas de gamme et l'envoi d'emails avec beaucoup de photos.",
  "WIDGET_PROPERTY_FILENAME": "Nom du fichier",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Choisissez le nom de la photo tel qu'il apparaîtra dans l'exportation.",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Permettre la sélection d'une photo à partir de l'appareil",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Permettez aux utilisateurs de sélectionner une photo précédemment réalisée à partir de l'appareil.",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Aperçu en taille réelle",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Sélectionnez cette option si vous souhaitez afficher un aperçu en taille réelle de la photo dans votre formulaire.",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Sauvegarder une copie sur l'appareil",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Taille du stylo",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Augmenter/diminuer la taille du stylo de l'utilisateur.",
  "WIDGET_PROPERTY_WIDE": "Large",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "Cela réduira la hauteur autorisée d'une signature, rendant la signature plus large.",
  "WIDGET_PROPERTY_TEXT": "Texte",
  "WIDGET_PROPERTY_HEADER_SIZE": "Taille de l'en-tête",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Taille de l'en-tête, allant de h1 (la plus grande) à h6 (la plus petite).",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Maintenant, par défaut",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Cochez la case pour que la valeur soit automatiquement fixée à \"maintenant\" lorsque le formulaire est ouvert.",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Trier par ordre alphabétique",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Cochez pour trier les choix par ordre alphabétique.",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Autoriser les sélections multiples",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Cochez cette case pour permettre la sélection de plusieurs options. Ces sélections seront enregistrées sous forme de valeurs séparées par des virgules dans l'enregistrement.",
  "WIDGET_PROPERTY_EXPAND": "Étendre",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Cochez pour étendre la liste lors de l'ouverture du formulaire.",
  "WIDGET_PROPERTY_TARGET_VIEW": "Vue d'ensemble",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Choisissez le formulaire vers lequel vous voulez établir un lien.",
  "WIDGET_PROPERTY_IMAGE": "Image",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "Cette image sera utilisée comme vignette dans l'application.",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Ajouter le texte du bouton",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Nom du bouton pour ajouter un élément à l'aide du sous-formulaire.",
  "WIDGET_PROPERTY_SUBFORM": "Sous-formulaire",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Choisissez le formulaire que vous voulez utiliser pour créer votre objet composé.",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Résumé dans l'application",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Spécifier comment un élément ajouté est affiché, en utilisant les valeurs de champs spécifiques.<br><br><b>Par exemple:</b><br>Chaque élément ajouté doit être affiché comme :<br> ${firstName} ${lastName}</b>",
  "WIDGET_PROPERTY_MINIMUM": "Minimum",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "Le nombre minimum de fois qu'un sous-formulaire doit être rempli.",
  "WIDGET_PROPERTY_MAXIMUM": "Maximum",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "Le nombre maximum de fois qu'un sous-formulaire peut être rempli.",
  "WIDGET_PROPERTY_STEP_SIZE": "Taille",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "Lorsqu'il est réglé sur 3, le curseur est toujours arrondi à un multiple de 3.",
  "WIDGET_PROPERTY_HIDE_VALUE": "Cacher valeur",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Détermine si la valeur doit être affichée ou non dans l'application.",
  "WIDGET_PROPERTY_HTML_CODE": "Code HTML",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Ajoutez un morceau de code HTML supportant le CSS intégré.",
  "WIDGET_PROPERTY_RESOURCE": "Ressources",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Sélectionnez une image dans la galerie.",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Largeur Maximale",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "Largeur de l'image dans l'application. La largeur sera limitée à la largeur de l'écran si cette valeur est supérieure.",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Hauteur Maximale",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "Hauteur de l'image dans l'application. La hauteur sera limitée à la hauteur de l'écran si cette valeur est supérieure.",
  "WIDGET_PROPERTY_DATA_SOURCE": "Base de données",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Sélectionnez l'une de vos bases de données pour effectuer une recherche. De nouvelles bases de données peuvent être créées à partir du menu principal Bases de données.",
  "WIDGET_PROPERTY_FILTER": "Filtre",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filtrez les résultats en utilisant la valeur d'autres champs dans votre formulaire.",
  "WIDGET_PROPERTY_LIST_COLORS": "Liste de couleurs",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Appliquez des couleurs aux entrées de la base de données qui correspondent aux requêtes configurées.",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Permettre la lecture des codes-barres",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "Cochez cette case pour permettre à l'utilisateur de rechercher la base de données à l'aide du scanner de codes-barres.",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Mémoriser la dernière requête de recherche",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "Cochez cette case pour mémoriser la requête de recherche. La requête de recherche sera exécutée automatiquement.",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Permettre à l'utilisateur de changer l'image",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Cochez la case pour permettre aux utilisateurs d'utiliser leurs propres images comme arrière-plan pour les épingles, en utilisant l'appareil photo ou la bibliothèque.",
  "WIDGET_PROPERTY_PINS": "Épingles",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Définissez une ou plusieurs épingles qui peuvent être placées sur l'image donnée. Chaque épingle a sa propre icône et ouvrira le formulaire spécifié lorsqu'elle sera placée.<br><br>Dans l'application, vos épingles seront numérotées automatiquement. Pour utiliser votre propre numérotation ou étiquetage, définissez un Tracking id.",
  "WIDGET_PROPERTY_REQUIRED": "Obligatoire",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "Si cette case est cochée, ce champ doit être rempli pour pouvoir enregistrer le formulaire.",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Mémoriser la saisie",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Cochez cette case pour mémoriser la dernière saisie de l'utilisateur sur cet appareil pour le prochain enregistrement. Si vous avez également défini une valeur par défaut, la mémorisation des données sera remplacée.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Signe décimal",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Montant minimum",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "L'application mobile ne permet pas de choisir une valeur inférieure à celle-ci.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Montant maximum",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "L'application mobile ne permet pas de choisir une valeur supérieure à celle-ci.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Cliquez pour voir l'image",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Sélectionnez cette option si vous souhaitez afficher un aperçu de la photo en taille réelle lorsque vous cliquez sur la photo.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Localisation actuelle par défaut",
  "INTEGRATION_TASK": "Tâche",
  "INTEGRATION_TASK_DESCRIPTION": "Cette intégration vous permet de créer une tâche sur la base d'un enregistrement entrant.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "Ce plugin vous permet d'envoyer un mail en utilisant Mailchimp. Vous pouvez utiliser les données de votre enregistrement comme balises dans votre modèle Mailchimp.",
  "INTEGRATION_DELETE": "Supprimer",
  "INTEGRATION_DELETE_DESCRIPTION": "Cette intégration vous permet de supprimer automatiquement les enregistrements d'un formulaire. Assurez-vous que c'est la dernière intégration que vous ajoutez à votre formulaire. Une fois qu'un enregistrement est supprimé, il ne peut pas être restauré, soyez donc prudent lorsque vous utilisez cette intégration.",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "Cette intégration vous permet d'utiliser Microsoft Word pour exporter des données. Vous pouvez envoyer des pièces jointes PDF ou Word avec cette intégration. Et vous pouvez télécharger votre modèle Word.docx pour styliser vos rapports.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "L'intégration WebDAV vous permet d'envoyer le PDF d'un e-mail à un serveur WebDAV.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "Cette intégration vous permet d'envoyer vos formulaires remplis vers une feuille de calcul Google. Vous pouvez obtenir l'ID de votre feuille de calcul Google à partir de l'URL. Remarque : les formulaires enregistrés seront simplement ajoutés comme une nouvelle ligne dans votre feuille. Nous ne mettons pas à jour les en-têtes de la feuille chaque fois que vous mettez à jour votre formulaire, vous devrez donc le faire vous-même. Nous ne prenons pas en charge les images/fichiers et toutes les données imbriquées (widgets-Sous-formulaire, -Épingle, -Recherche) seront ajoutées à une seule cellule. Avec les formulaires comportant des règles et des champs vides, les données peuvent être placées dans la mauvaise colonne.",
  "INTEGRATION_MONGODB_INSERT": "MongoDB Insert",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "L'intégration MongoDB Insert vous permet d'insérer vos données d'enregistrements dans une base de données Mongo.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "Cette intégration vous permet d'envoyer le PDF joint à votre formulaire vers Microsoft OneDrive.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "Cette intégration vous permet d'utiliser Excel pour exporter des données. Téléchargez votre modèle Excel.xlsx et recevez vos données dans un fichier Excel.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Google Sheets Cell Update",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Mettez à jour une cellule spécifique dans une feuille existante. Cette intégration vous permet de mettre à jour une feuille qui est également utilisée comme base de données importée dans MoreApp.",
  "INTEGRATION_MESSAGE_BOARD": "Tableau Message",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Autoriser l'envoi de messages",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Acceptation Google Sheet",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "Non destiné à la production",
  "INTEGRATION_PROPERTY_FOLDER": "Dossier",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "Le dossier pour lequel une tâche doit être envoyée.",
  "INTEGRATION_PROPERTY_FORM": "Formulaire",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "Le formulaire (dans le dossier sélectionné) qui doit être utilisé comme cible pour la tâche.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Destinataires (liste d'emails séparés par des virgules)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Destinataires (liste d'emails séparés par des virgules)",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Destinataires dynamiques",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Utilisez les données d'un widget E-mail comme destinataire",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Message pour la tâche",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Message pour la tâche",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Délai (en jours)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "Le nombre de jours pendant lesquels l'enregistrement doit persister avant d'être supprimé.",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Format de sortie",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Configure le format de sortie à utiliser comme pièce jointe",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Répondre à (facultatif)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "L'adresse à laquelle les destinataires répondront",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "De (facultatif)",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "Le nom d'où vous voulez que les e-mails proviennent",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Destinataires",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Liste de destinataires séparés par des virgules\n",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "Envoyer à l'utilisateur qui a enregistré le formulaire",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Cochez cette case pour ajouter l'utilisateur qui a envoyé le formulaire en tant que destinataire",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Liste de destinataires séparés par des virgules",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Liste de destinataires séparés par des virgules",
  "INTEGRATION_PROPERTY_SUBJECT": "Objet",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Objet avec balises facultatives",
  "INTEGRATION_PROPERTY_BODY": "Corps",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "Corps de l'e-mail avec balises facultatives",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Qualité Image",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Si votre enregistrement comporte de nombreuses photos en haute résolution, il est préférable de réduire la qualité. Sinon, l'e-mail risque d'être trop volumineux pour votre boîte e-mail.",
  "INTEGRATION_PROPERTY_FILENAME": "Nom fichier",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Nom de fichier avec des balises facultatives",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Télécharger un modèle Word",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "Le modèle Word à utiliser",
  "INTEGRATION_PROPERTY_SERVER": "Serveur",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL du serveur, sera utilisé comme chemin de base. Par exemple : http://mon.serveur.com",
  "INTEGRATION_PROPERTY_PATH": "Chemin",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "Chemin où les PDF doivent être stockés, peut inclure des balises, ex : /drive/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "Nom d'utilisateur WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Nom d'utilisateur à utiliser lors de l'authentification au serveur WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "Mot de passe WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Mot de passe à utiliser lors de l'authentification au serveur WebDAV",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Connexion Google",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Choisissez un compte Google pour nous permettre d'écrire dans vos feuilles de calcul Google",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "ID Feuille de calcul",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "L'identifiant unique de votre feuille de calcul, indiqué dans l'URL comme https://docs.google.com/spreadsheets/d/<b>mon-id-feuille</b>.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Nom feuille",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "Le nom de votre feuille, qui a généralement pour valeur par défaut 'Sheet1' ou 'Feuille1'",
  "INTEGRATION_PROPERTY_METADATA": "Meta-données",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Sélectionnez les champs méta que vous souhaitez inclure dans l'exportation. Ils seront insérés avant les données du formulaire",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "MongoDB Host",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "L'adresse IP de MongoDB host",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "Port MongoDB",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "Le port sur lequel Mongo est connecté",
  "INTEGRATION_PROPERTY_USE_SSL": "Utiliser SSL",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Utilisez une connexion TLS sécurisée",
  "INTEGRATION_PROPERTY_USERNAME": "Nom utilisateur",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "Le nom d'utilisateur de l'utilisateur Mongo sous lequel nous devons nous authentifier",
  "INTEGRATION_PROPERTY_PASSWORD": "Mot de passe",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "Le mot de passe de l'utilisateur Mongo avec lequel nous devons nous authentifier",
  "INTEGRATION_PROPERTY_DATABASE": "Base de données",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "La base de données dans laquelle vous voulez sauvegarder les enregistrements",
  "INTEGRATION_PROPERTY_COLLECTION": "Base de données (collecte)",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "La base de données dans laquelle vous voulez sauvegarder les enregistrements",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Widget-Recherche",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "Le widget-Recherche",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "Connexion OneDrive",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Choisissez un compte OneDrive pour nous permettre de sauvegarder des fichiers",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "Inclure fichier CSV",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Ajoutez automatiquement un fichier CSV (Valeur séparée par des virgules) à votre répertoire OneDrive",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Télécharger un modèle Excel",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "Le modèle Excel à utiliser",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Nom fichier Excel",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Nom du fichier Excel tel qu'il doit être joint",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Copie à l'utilisateur",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Envoyez une copie à l'utilisateur qui a rempli le formulaire",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Joindre images",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Joignez des images et des signatures à l'e-mail",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "Nouvelle valeur widget",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Sélectionnez le widget à partir duquel vous souhaitez obtenir la nouvelle valeur",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "Nom de la colonne à mettre à jour",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Définissez la colonne que vous souhaitez mettre à jour avec la valeur du \"widget nouvelle valeur\" sélectionné",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "L'intégration FTPS enregistre les données d'enregistrement sur le serveur FTPS donné.",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protocole",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "Protocole à utiliser pour la connexion au serveur FTP",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "Désactiver la vérification SSL",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "Vous pouvez utiliser cette option lorsque vous disposez d'un certificat auto-signé ou d'une chaîne de certificats non valide (uniquement applicable lors de l'utilisation de FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Utiliser le mode FTPS 'implicite'",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Basculer entre le mode 'implicite' et 'explicite FTPS' (seulement applicable lors de l'utilisation de FTPS).",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "L'URL du serveur sans le protocole, sera utilisé comme chemin de base. Par exemple : mon.serveur.com.",
  "INTEGRATION_PROPERTY_PORT": "Port",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Port du serveur FTP(S), il sera par défaut 21 ou 990 s'il n'est pas renseigné.",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Mettre chaque enregistrement dans son propre dossier",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Ajoute automatiquement '/customerId/form-name/serial-number' au chemin d'accès distant afin de créer un chemin d'accès unique pour chaque enregistrement.",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "Nom d'utilisateur FTP",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Nom d'utilisateur à utiliser pour s'authentifier auprès du serveur WebDAV.",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "Mot de passe FTP",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Mot de passe à utiliser pour s'authentifier auprès du serveur WebDAV.",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "Inclure PDF",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Indique s'il faut également copier ou non le PDF.",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "Stocker les PDF pour les e-mails sans destinataire",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Indique s'il faut ou non stocker les PDF qui sont générés pour les emails sans destinataire.",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Inclure fichiers",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Indique s'il faut aussi copier ou non les fichiers.",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Téléchargez un modèle Excel",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "Le modèle Excel à utiliser",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Utilisez la configuration antérieure",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Utilisez cette option si vous souhaitez utiliser '${' comme délimiteur de départ pour les placeholders (ceci n'est recommandé que pour les anciens modèles existants).",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Nom du fichier Excel",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Nom du fichier Excel que vous recevrez en pièce jointe",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Destinataires",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Liste de destinataires séparés par des virgules.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Destinataires dynamiques",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Utiliser les données d'un widget E-mail comme destinataire",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Copie pour l'utilisateur",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Envoyez une copie à l'utilisateur qui a rempli le formulaire",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Qualité de l'image",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Une qualité plus haute prendra plus de temps pour traiter l'e-mail",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Objet",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Objet avec placeholders facultatifs",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Corps",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "Corps de l'e-mail avec placeholders facultatifs",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Vérifiez les réglages de l'heure",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "Vous ne pouvez pas vous connecter, car l'horloge est trop éloignée.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>SOLUTION</b> : modifiez l'heure et le fuseau horaire de votre appareil afin qu'il reflète précisément l'heure et le fuseau horaire de votre emplacement actuel. Puis réessayez.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Réessayer la connexion",
  "TOUR_PROGRESS_STEP1_LABEL": "Comment ça marche",
  "TOUR_PROGRESS_STEP2_LABEL": "Dites-nous en plus",
  "TOUR_PROGRESS_STEP3_LABEL": "Votre premier formulaire",
  "TOUR_SKIP": "Passer la démo",
  "TOUR_STEP1_TRIAL": "Vous êtes dans votre période d'essai de 14 jours",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Créez et modifiez vos formulaires depuis votre Bureau",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Remplissez des formulaires sur votre téléphone ou tablette",
  "TOUR_STEP1_RECEIVE_DATA": "Recevez un rapport dans votre boîte mail",
  "TOUR_STEP2_HEADER": "Parlez-nous de vous",
  "TOUR_STEP3_HEADER": "Comment pouvons-nous vous contacter ?",
  "TOUR_ERROR_MODAL_TITLE": "Oups...",
  "TOUR_ERROR_MODAL_MESSAGE": "Quelque chose s'est mal passé lors notre démo. En attendant vous pouvez explorer {{appName}} par vous-même.",
  "TOUR_ERROR_MODAL_BUTTON": "Explorer {{appName}}",
  "FIRST_NAME": "Prénom",
  "LAST_NAME": "Nom",
  "PAGE_CONTROL_PAGE_SIZE": "Page",
  "PAGE_CONTROL_TOTAL_ITEMS": "Total des entrées",
  "VIEW_MANAGEMENT_OVERVIEW": "Aperçu",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Enregistrements",
  "VIEW_MANAGEMENT_TASKS": "Tâches",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Coûts",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Statut",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "Le",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Modifier le formulaire",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_BETA": "Modifier formulaire (Bêta)",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Partage",
  "FORM_REMOVE_MODAL_TITLE": "Supprimer le formulaire",
  "FORM_REMOVE_MODAL_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce formulaire?",
  "REMOVE_DRAFT_TITLE": "Supprimer le brouillon",
  "REMOVE_DRAFT_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce brouillon?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Supprimer le modèle",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce modèle ?",
  "UNPUBLISHED": "Non publié",
  "PUBLISHED": "Publié",
  "PUBLISHED_UPDATED": "Publié, mise à jour disponible",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Gérer les utilisateurs",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Placez le formulaire sur le marché",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Partagé sur le marché",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Caché du marché",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "Voir sur le marché",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Modifier",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Mettre à jour vers la dernière version",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "Votre modèle de formulaire n'a pas pu être mis à jour avec la dernière version. Veuillez réessayer plus tard.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Cacher du marché",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Montrer sur le marché",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Placer le formulaire sur le marché",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "Donc vous voulez partager votre formulaire avec tous les autres utilisateurs?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Veuillez remplir ce formulaire en premier. Cette information (à l'exception de votre compte bancaire) sera visible sur le marché.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Mettre à jour les informations",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Espace de noms",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Exemple: com.masociété",
  "FORM_TEMPLATE_KEY_LABEL": "Clé",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Exemple: formulairespécial",
  "FORM_TEMPLATE_TYPE_LABEL": "Type",
  "FORM_TEMPLATE_NAME_LABEL": "Nom",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Nommez votre modèle de formulaire",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Déscription",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Décrivez votre modèle de formulaire",
  "FORM_TEMPLATE_LOGO_LABEL": "Logo",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "L'URL de votre logo de formulaire",
  "DIRECT_FORM_LINK": "Générer une URL directe",
  "DIRECT_FORM_LINK_GENERATED": "Ouvrir une URL directe",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Revoke direct URL",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "Êtes-vous sûr de vouloir révoquer l'URL pour accéder directement à votre formulaire ? Cela signifie que l'URL ne fonctionne plus.",
  "PUBLISH": "Publier",
  "PUBLISH_FORM_VERSION": "Publier cette version",
  "PUBLISH_FORM_VERSION_MESSAGE": "Souhaitez-vous publier cette version de votre formulaire ?",
  "PUBLISHED_BY": "Publié par",
  "PUBLISHED_ON": "Publié",
  "REMARKS": "Remarques",
  "DRAFT": "Brouillon",
  "FORM_HISTORY": "Historique des versions",
  "FORM_DESCRIPTION_PLACEHOLDER": "Ajoutez une brève description de votre formulaire",
  "FORM_MOVE_MODAL_TITLE": "Déplacer",
  "FORM_SAVE_AS_TEMPLATE": "Sauvegarder modèle",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "Êtes-vous sûr de vouloir sauvegarder ce formulaire en tant que modèle ?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Déplacer le formulaire vers le dossier sélectionné",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Choisir dossier",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "Il semble que vous ou un collègue êtes en train de modifier ce formulaire à un autre endroit. Assurez-vous d'être la seule personne à modifier ce formulaire et réessayez ensuite.",
  "INTEGRATIONS_EOL_MESSAGE": "Ce formulaire utilise une intégration qui ne fonctionne plus. Nous vous prions de la supprimer ou de la remplacer avec une autre intégration ou webhook. <a href=\"https://help.moreapp.com/fr/support/tickets/new\">Contactez l'assistance</a> si vous avez besoin d'aide.",
  "DATASOURCE_PICKER_ADD": "Ajouter une base de données",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Rechercher des bases de données",
  "GROUP_PICKER_ADD_USER": "Inviter utilisateur(s)",
  "GROUP_PICKER_ADD_GROUP": "+ Créer un nouveau groupe",
  "GROUP_PICKER_ALL_USERS": "Tous les utilisateurs",
  "GROUP_PICKER_INVITED": "(Invité)",
  "MANAGE_ROLES": "Gérer rôles",
  "ROLE": "Rôle",
  "SEARCH_GROUP_PLACEHOLDER": "Rechercher des groupes",
  "BILLING_TITLE": "Facturation",
  "BILLING_OVERVIEW_MENU_ITEM": "Aperçu",
  "BILLING_INVOICES_MENU_ITEM": "Factures",
  "BILLING_USAGE_MENU_ITEM": "Utilisation",
  "SUBTOTAL": "Sous-total",
  "TOTAL": "Total",
  "NEXT_INVOICE": "Prochaine Facture",
  "PREVIEW_CHANGE_EXPLANATION": "Ce tableau affiche votre prochaine facture quand vos changements sont appliqués. Lisez cet article du <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Centre d'Assistance</a> qui indique comment votre facture est créée.",
  "PREVIEW_CHANGE": "Visualiser les changements",
  "CHANGE_SUBSCRIPTION_BUTTON": "Changer de formule",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "Voir changement",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Changement de souscription prévu",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Programmé",
  "SCHEDULED_CANCEL_MESSAGE": "Votre abonnement est programmé pour annulation à la fin de la période de facturation en cours.",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Annuler changement",
  "SELECT_PLAN": "Choisissez votre formule",
  "YOUR_SELECTED_PLAN": "Formule sélectionnée",
  "LOADING_PLANS": "Chargement des formules disponibles",
  "MONTHLY_PLAN": "Formule Mensuelle",
  "YEARLY_PLAN": "Formule Annuelle",
  "MONTH_SHORT": "mois",
  "YEAR_SHORT": "an",
  "TRIAL_ACTIVE": "Essai actif",
  "DAYS": "jours",
  "TRIAL_DAYS_REMAINING": "jours restants",
  "TRIAL_ENDS_TODAY": "Votre période d’essai se termine aujourd’hui",
  "TRIAL_ENDS_TOMORROW": "Votre période d’essai se termine demain",
  "TRIAL_ENDS_ON": "Votre période d’essai se termine le",
  "SUBMISSIONS": "Enregistrements",
  "USERS": "Utilisateurs",
  "USAGE": "Utilisation",
  "UNLIMITED": "Illimité",
  "CHANGE_PLAN": "Changer de Formule",
  "CANCEL_PLAN": "Annuler Abonnement",
  "UNCANCEL_PLAN": "Ne plus annuler",
  "PLAN_MORE_OPTIONS": "Plus d'options",
  "DELETE_ACCOUNT": "Supprimer Compte",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "Vous avez une facture ouverte",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "Vous avez encore une facture ouverte, vous ne pouvez donc pas effectuer cette action. Naviguez vers vos factures pour compléter votre paiement.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Aller à Factures",
  "CANCEL_PLAN_MODAL_TITLE": "Annuler abonnement",
  "CANCEL_PLAN_MODAL_MESSAGE": "Vous pouvez continuer à utiliser notre produit jusqu'à la fin de votre cycle de facturation. Passé ce délai, les enregistrements ne seront plus traités. Vous pouvez encore accéder à votre compte pour consulter et exporter vos données pendant 30 jours. Ensuite, nous supprimerons votre compte",
  "UNCANCEL_PLAN_MODAL_TITLE": "Ne plus annuler abonnement",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Vous avez donc changé d'avis et souhaitez continuer à utiliser notre produit ?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "Abonnement déjà programmé pour annulation",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "La résiliation de votre abonnement est prévue à la fin de votre cycle de facturation actuel.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "N'hésitez pas à nous faire savoir comment nous pouvons améliorer notre produit. Votre avis nous intéresse.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>Votre avis compte pour nous.</h4><p>Veuillez nous faire savoir comment nous pouvons améliorer notre produit.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "Pourquoi souhaitez-vous supprimer votre compte ?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "J'ai trouvé un autre outil",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "C'est trop cher",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "Je n'en n'ai plus l'utilité",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "Je mets fin à mon activité",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "Je ne suis pas satisfait du produit",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "Il manque une fonctionnalité",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Autre",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Veuillez sélectionner au moins un motif.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "Souhaitez-vous nous faire part de quelque chose d'autre ?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Veuillez saisir un message.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Annuler Abonnement",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Supprimer Compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Oui, je veux supprimer mon compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "Je comprends qu'après </b>30 jours</b>, mon compte entreprise <b>{{customerName}}</b> ({{customerId}}) et toutes les données s'y rapportant seront définitivement supprimées.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Veuillez confirmer en indiquant votre nom de compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "Le nom du compte saisi ne correspond pas au nom du compte que vous essayez de supprimer.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Supprimer Compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "Échec de la suppression du compte. Veuillez réessayer plus tard.",
  "BILLING_PROFILE": "Profil Facturation",
  "BILLING_PHONE_NUMBER": "Numéro de téléphone",
  "INVOICE_ADDRESS": "Adresse de facturation",
  "PAYMENT_METHOD": "Moyen de paiement",
  "UPCOMING_INVOICE": "Facture à venir",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "En période d’essai",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Tester notre Produit pendant la période d'essai est gratuit. Prêt à continuer après votre période d'essai ? Ajoutez un moyen de paiement pour recevoir votre première facture.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Tester notre Produit pendant la période d'essai est gratuit. Après la fin de la période d'essai, vous recevrez la facture suivante.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Montant dû après l’essai",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "Aucune facture à venir",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "Votre abonnement a été annulé. Il n'y a pas de facture à venir. Votre période de facturation se termine le ",
  "CARD_HOLDER_NAME": "Nom affiché sur la carte",
  "CARD_HOLDER_NAME_PLACEHOLDER": "ex. Martin Dupont",
  "ADD_CARD": "+ Ajouter Moyen de Paiement",
  "MAKE_DEFAULT": "Par défaut",
  "EXPIRES": "expire",
  "PAYMENT_METHOD_REMOVE_TITLE": "Supprimer moyen de paiement",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "Êtes-vous sûr(e) de vouloir supprimer ce moyen de paiement ?",
  "PLAN_LEAF": "Feuille",
  "PLAN_BRANCH": "Branche",
  "PLAN_TREE": "Arbre",
  "PLAN_FOREST": "Forêt",
  "PLAN_LEAF_DESCRIPTION": "Créez des formulaires pro",
  "PLAN_BRANCH_DESCRIPTION": "Traitement des données avancé",
  "PLAN_TREE_DESCRIPTION": "Pour toute l'organisation",
  "PLAN_FOREST_DESCRIPTION": "Pour entreprises",
  "PLAN_LEAF_FEATURES_HEADER": "Fonctionnalités",
  "PLAN_BRANCH_FEATURES_HEADER": "Formule Feuille +",
  "PLAN_TREE_FEATURES_HEADER": "Formule Branche +",
  "PLAN_FOREST_FEATURES_HEADER": "Formule Arbre +",
  "PLAN_AMOUNT_OF_USERS_ONE": "Un utilisateur",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Utilisateurs illimités",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} envois gratuits",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} arbres",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "€0.40 / envoi supplémentaire",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "€0.40 / envoi supplémentaire",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "€0.40 / envoi supplémentaire",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "€0.10 / envoi supplémentaire",
  "FEATURE_FORM_BUILDER": "Créateur de Formulaires",
  "FEATURE_APP": "Application",
  "FEATURE_EXCEL_EXPORT": "Exportation vers Excel",
  "FEATURE_EMAIL": "E-mail avec PDF",
  "FEATURE_RULES": "Règles",
  "FEATURE_API": "Accès API",
  "FEATURE_MARKETPLACE_TEMPLATES": "Modèles",
  "FEATURE_TASKS": "Tâches",
  "FEATURE_LOGO_PDF": "Votre logo sur le PDF",
  "FEATURE_DATA_IMPORT": "Importation de données",
  "FEATURE_MULTIPLE_PDF": "Multiple e-mails",
  "FEATURE_ADVANCED_PDF": "Personnalisation du PDF",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Importation automatiques des données",
  "FEATURE_SINGLE_SIGN_ON": "Authentification unique",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Authentification unique",
  "FEATURE_SENDING_DOMAIN": "Domaine d'E-mail Personnalisé",
  "FEATURE_DEDICATED_SUPPORT": "Assistance personnalisée",
  "VAT": "TVA",
  "APPLIED_BALANCE": "Solde appliqué",
  "AMOUNT_DUE": "Montant dû",
  "REMAINING_BALANCE": "Solde restant",
  "CREDIT_CARD": "Carte de crédit",
  "SEPA_DIRECT_DEBIT": "Débit Direct SEPA",
  "PAY_ON_FILE": "Payer sur facture",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "Cette option n’est pas disponible dans votre souscription actuelle",
  "SEPA_MANDATE": "En fournissant votre IBAN, vous autorisez (A) MoreApp et Stripe, notre fournisseur de service de paiement, à envoyer des instructions à votre banque afin de débiter votre compte et (B) votre banque à débiter votre compte en accord avec ces instructions. Ceci est uniquement destiné aux transactions entre entreprises. Vous n'avez pas droit à un remboursement de votre banque après que votre compte a été débité, mais vous avez le droit de demander à votre banque de ne pas débiter votre compte avant le jour où le paiement est dû.",
  "INVOICE_NUMBER": "Numéro de facture",
  "INVOICE_DATE": "Date",
  "INVOICE_TOTAL": "Total",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "Statut",
  "BASE_MONTH": "Formule mensuelle",
  "BASE_YEAR": "Formule annuelle",
  "FORMS_MONTH": "Utilisation mensuelle",
  "FORMS_YEAR": "Utilisation annuelle",
  "BRANDING_MONTH": "Branding mensuel",
  "BRANDING_YEAR": "Branding annuel",
  "ADDON_MONTH": "Extension mensuelle",
  "ADDON_YEAR": "Extension annuelle",
  "MAINTENANCE_FTPS_MONTH": "Maintenance - FTPS mensuel",
  "MAINTENANCE_FTPS_YEAR": "Maintenance - FTPS annuel",
  "MANUAL_INVOICE_DESCRIPTION": "Les factures seront envoyées à votre adresse mail de facturation",
  "USAGE_DAILY": "Quotidien",
  "USAGE_MONTHLY": "Mensuel",
  "MONTH": "Mois",
  "YEAR": "Année",
  "INVOICE_REFERENCE": "Information additionnelle sur la facture",
  "INVOICE_REFERENCE_DESCRIPTION": "Ces informations seront ajoutées à la facture pour votre administration, par exemple un numéro de commande.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "Ce changement prend effet immédiatement",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "Ce changement prendra effet à partir de votre prochaine facture",
  "TREES_PLANTED": "Arbres plantés",
  "TREES_PLANTED_TOTAL": "arbres plantés au total",
  "TREES_PLANTED_PER_MONTH": "arbres plantés chaque mois",
  "TREES_PLANTED_PER_YEAR": "arbres plantés chaque année",
  "DOWNGRADE_QUESTION": "Pourquoi souhaitez-vous changer de formule ?",
  "DOWNGRADE_NOT_NEEDED": "Je n'en ai plus besoin",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Remarques",
  "DOWNGRADE_ALTERNATIVE": "J'ai trouvé une alternative",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "Quelle alternative ?",
  "DOWNGRADE_MISSING_FEATURE": "Il manque une fonctionnalité",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "Quelle est la fonctionnalité manquante ?",
  "DOWNGRADE_PRICING": "Trop coûteux",
  "DOWNGRADE_PRICING_DESCRIPTION": "Remarques",
  "DOWNGRADE_UNSATISFIED": "Insatisfaction",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "Que pouvons-nous améliorer ?",
  "DOWNGRADE_OTHER": "Autre",
  "DOWNGRADE_OTHER_DESCRIPTION": "Quelle est la raison ?",
  "SELECT_OPTION": "Sélectionnez une option",
  "CONTACT_BUTTON": "Contactez-nous",
  "CONTACT_LINK": "https://meet.moreapp.com/meetings/moreapp/tours-fr",
  "PRICE_LABEL_NOW": "maintenant",
  "PRICE_LABEL_UPCOMING": "à partir de Janvier 2025",
  "PRICE_ANNUAL_BILLED_MONTHLY": "Facturé annuellement",
  "BILLING_FLOW_STEP1_LABEL": "Sélectionner formule",
  "BILLING_FLOW_STEP2_LABEL": "Informations de facturation",
  "BILLING_FLOW_STEP3_LABEL": "Moyen de paiement",
  "BILLING_FLOW_STEP4_LABEL": "Confirmez",
  "ONETIME": "En une fois",
  "MONTHLY": "Mensuel",
  "YEARLY": "Annuel",
  "RECURRENCE": "Récurrence",
  "BRANDING": "Branding",
  "RESTORE": "Restaurer",
  "RESTORE_FORM_MESSAGE": "En restaurant ce formulaire, il va réapparaître dans la liste de formulaires sur la Plateforme. Il va aussi apparaître dans l'application, si le formulaire était publié.",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Créez votre compte gratuitement",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "Commencez l'essai de 14 jours",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "Adresse e-mail",
  "SIGN_UP_CUSTOMER_PASSWORD": "Mot de Passe",
  "SIGN_UP_CUSTOMER_BUTTON": "Créer un compte",
  "EMAIL_ADDRESS_PLACEHOLDER": "email@entreprise.com",
  "EMAIL_VALIDATION_VALIDATING": "Validation de l'e-mail ...",
  "EMAIL_VALIDATION_FAILED": "Échec de la validation de l'adresse e-mail. Nous n'avons pas trouvé le compte correspondant à la validation de votre compte. Votre compte est peut-être déjà validé.",
  "EMAIL_VALIDATION_SUCCESS": "Votre adresse e-mail est vérifiée !",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Cliquez sur l'image pour ajouter des épingles",
  "ALERT_TOUCH_DEVICE": "La plateforme ne fonctionne pas sur les appareils mobiles. S'il vous plaît veuillez utiliser un ordinateur portable ou un PC",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>Vous avez un moyen de paiement expiré !</b> Assurez-vous <a href ui-sref='portal.customers.billing.overview'>d'ajouter un nouveau moyen de paiement</a> pour pouvoir continuer à envoyer des formulaires.",
  "ADD_VIEW_TEMPLATE_TYPE": "En utilisant le modèle:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "Vide",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Installé",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Obtenez plus de formulaires sur le marché",
  "BLANK_FORM": "Vierge",
  "BLANK_FORM_DESCRIPTION": "Commencez avec un formulaire vide",
  "AGREEMENTS_MODAL_TITLE": "Mise à jour des termes et conditions",
  "AGREEMENTS_MODAL_MESSAGE": "Nous avons mis à jour notre Contrat d'Utilisateur Final, y compris son Addendum de Traitement des Données. Merci de les lire attentivement. Si vous avez des questions ou des préoccupations, s'il vous plaît contactez-nous. Sinon, aucune action n'est requise de votre part et les conditions mises à jour sont appliquées du 1 février 2019.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Continuer",
  "ADD_PHOTO": "Ajouter photo",
  "CAPTURE_PHOTO": "Capturer photo",
  "BROWSE_PHOTO": "Parcourir photos",
  "DRAW_SIGNATURE": "Signer",
  "START_DRAWING": "Commencer dessin",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Choisissez l'image d'arrière-plan",
  "ADD_VIDEO": "Ajouter vidéo",
  "SIGN_UP_THANKS": "Merci pour votre inscription!",
  "SIGN_UP_CHECK_MAIL": "Veuillez vérifier votre boîte de réception pour vérifier votre adresse email.",
  "FORM_BUILDER": "Créateur de Formulaires",
  "MOBILE_APP": "Application Mobile",
  "PDF_REPORTS": "Rapports PDF",
  "EXCEL_EXPORT": "Exportation vers Excel",
  "TASKS": "Tâches",
  "PDF_LOGO": "Logo PDF personnalisé",
  "REALTIME_DATA_SOURCES": "Sources de données en temps réel",
  "PDF_LAYOUT": "Mise en page PDF personnalisée",
  "RULES": "Règles",
  "API_ACCESS": "Accès API",
  "COLOR_THEMES": "Thèmes de couleur",
  "THEMES_SYSTEM": "Thèmes par défaut",
  "THEMES_CUSTOM": "Thèmes personnalisés",
  "GROUP_ROLES": "Autorisations & Rôles Groupe",
  "CUSTOM_DOMAIN": "Domaine Personnalisé",
  "DEDICATED_ACCOUNT_MANAGER": "Responsable de Compte Attitré",
  "PREMIUM_SUPPORT": "Assistance Premium",
  "FILE_SELECT": "Sélectionnez un fichier",
  "NEW_CUSTOMER": "+ Nouveau client",
  "TO_PORTAL": "Vers le portail",
  "EXPIRED": "Expiré",
  "READ_MORE": "Plus d'informations",
  "CHOOSE_A_PLAN": "Choisir une formule",
  "TRIAL": "Essai",
  "TRIAL_BANNER_TITLE": "Vous êtes en période d'essai gratuit",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} jours restants",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} enregistrements restants",
  "TRIAL_BANNER_CTA_CONTACT": "Contactez-nous",
  "TRIAL_BANNER_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-fr",
  "UPGRADE_NOW": "Passer à une formule supérieure maintenant",
  "CONTACT_SALES": "Contactez-nous",
  "BANNER_DELETION_LABEL": "La suppression de votre compte est prévue dans {{days}} jours",
  "BANNER_DELETE_TODAY_LABEL": "Votre compte sera supprimé aujourd'hui",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Choisissez une formule pour continuer à utiliser MoreApp",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Veuillez sélectionner une formule pour continuer à utiliser MoreApp. Sinon, ce compte sera supprimé dans {{days}} jour(s).",
  "BANNER_NO_SUBSCRIPTION_TITLE": "Aucun abonnement actif",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Veuillez sélectionner une formule pour continuer",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Important:</b> En raison de travaux de <a href='https://help.moreapp.com/support/solutions/articles/13000100648' target='_blank'>maintenance</a> programmés, MoreApp ne sera pas accessible le mercredi 19/04/2023 de 19:00-21:00 CET",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Important:</b> En raison de travaux de maintenance programmés, le Produit ne sera pas disponible le mercredi 19/04/2023 entre 19:00-21:00 CET.",
  "BANNER_OVER_USAGE_TITLE": "Vous avez dépassé le nombre d'enregistrements gratuits.",
  "BANNER_OVER_USAGE_DESCRIPTION": "✈️ {{ nrOfSubmissionsOverIncluded }} enregistrements supplémentaires 💰 Coût extra de {{ (extraUsageCostsInCents / 100 | currencySymbol:'eur') }}",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Passer à une formule supérieure",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Contactez-nous",
  "BANNER_OVER_USAGE_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-fr",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "Votre carte de crédit a expiré.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Veuillez ajouter un moyen de paiement valide.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Mettre à jour Moyen de Paiement",
  "BANNER_PAST_DUE_INVOICE_TITLE": "Vous avez une facture impayée.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Veuillez payer votre facture avant que votre compte ne soit bloqué.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Payer Facture",
  "BANNER_UNPAID_INVOICE_TITLE": "Votre compte est bloqué en raison d'une facture impayée.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Payez votre facture pour débloquer votre compte.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Payer Facture",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Sélectionner l'aperçu des colonnes",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Modifier la base de données",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Importer Excel",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Importation de calendrier",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Rechercher",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Autoriser",
  "DATA_SOURCES_TITLE": "Bases de donées",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Dernière mise à jour",
  "DATA_SOURCES_GENERAL_INFORMATION": "Informations générales",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Dernière mise à jour réussie",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Afficher les avertissements",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "Configuration de l'URL",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Importation de l'URL du calendrier",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Importation...",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Importation du calendrier Excel",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Importation...",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Derniers avertissements:",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "(Montrer tout {{numberOfWarningMessages}})",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Montrer moins )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Importer fichier Excel",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "Le fichier sélectionné est trop volumineux pour être téléchargé. La limite pour le téléchargement de fichiers Excel est de 10MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Sélectionnez un fichier Excel à importer. Assurez-vous que l'extension pour le fichier Excel est `.xlsx`. Les fichiers `.xls` ne seront pas importés.",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "Déposez les fichiers ici",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "Le fichier Drop HTML5 n'est pas supporté!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Détails d'entrée",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Paramètres",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Dernier type de mise à jour",
  "DATA_SOURCES_SEARCH_LABEL": "Base de données",
  "DATA_SOURCES_ADD_BUTTON": "+ Ajouter base de données",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Ajouter une base de données",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Modifier une base de données",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Nom",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Entrez un nom pour la base de données",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Type",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Entrez une URL pour récupérer la base de données à partir de",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Paramètres",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Ajouter un paramètre",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "Aucun paramètre",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Clé",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Valeur",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "En-têtes de la demande",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Ajouter l'en-tête de la demande",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Clé",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Valeur",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Intervalle de mise à jour",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Authentification de base",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Authenticate with Google",
  "AUTHENTICATED_WITH_GOOGLE": "Authentifié avec Google",
  "CHANGE_ACCOUNT": "Changer de compte",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Valeur entre / d / et / modifier dans l'URL de votre feuille de calcul",
  "DATA_SOURCE_GRID_NO_ENTRIES": "Il n'y a aucune entrée pour cette base de données.",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Chargement des entrées",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Importation d'entrées",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Dernière mise à jour échoué",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Dernière mise à jour: jamais",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Dernière mise à jour",
  "DATA_SOURCE_WARNINGS_TITLE": "Avertissements d'importation",
  "DATA_SOURCE_REMOVE_TITLE": "Supprimer la base de données",
  "DATA_SOURCE_REMOVE_QUESTION": "Êtes-vous sûr de vouloir supprimer cette base de données? Vous ne pouvez pas annuler cette action!",
  "DATA_SOURCE_ENABLE_TITLE": "Activer la base de données",
  "DATA_SOURCE_ENABLE_MESSAGE": "La base de données a été désactivée car 10 importations consécutives ou plus ont échoué. Assurez-vous d'avoir une configuration d'URL correcte. Si vous activez la base de données, nous réessayerons.",
  "CLIENTS": "Apps",
  "CLIENTS_TITLE": "Apps",
  "SETTINGS_TITLE": "Paramètres",
  "SETTINGS_TAB_GENERAL": "Général",
  "SETTINGS_TAB_SSO": "Authentification unique",
  "SETTINGS_TAB_SENDING_DOMAIN": "Domaine d'E-mail",
  "BILLING_SUCCESS_MESSAGE": "Informations de facturation client mises à jour avec succès.",
  "BILLING_DETAILS_HEADER": "Client",
  "BILLING_INVOICES_HEADER": "Client",
  "GENERAL_HEADER": "Général",
  "GENERAL_SUCCESS_MESSAGE": "Mise à jour réussie des paramètres du client",
  "LOGO_HEADER": "Logo",
  "LOGO_SELECT": "Sélectionner logo",
  "SETTINGS_DATE_FORMAT": "Format de date",
  "SETTINGS_CUSTOMER_NAME": "Nom de l’Entreprise",
  "SETTINGS_TAB_THEMES": "Thèmes",
  "SETTINGS_TAB_THEMES_CUSTOM": "Thèmes personnalisés",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Ajouter Thème",
  "THEME_FORM": "Thème",
  "THEME_FORM_NAME_LABEL": "Nom",
  "THEME_FORM_BUTTON_LABEL": "Boutton",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Sauvegarder Boutton",
  "THEME_FORM_BAR_LABEL": "Barre",
  "THEME_FORM_BACKGROUND_LABEL": "Arrière-plan",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Service Accounts",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "Nouvelle API key",
  "API_KEY_CREATED": "Nouvelle API key créée",
  "API_KEY_SAFETY": "Sauvegardez-la",
  "API_KEY_CREATED_MESSAGE": "Gardez cette clé dans un endroit sûr, comme un gestionnaire de mots de passe. <br><b>Vous ne serez plus en mesure de voir cette clé par la suite.</b",
  "API_KEY_REMOVE_TITLE": "Supprimer API key?",
  "API_KEY_REMOVE_MESSAGE": "La suppression de cette clé peut entraîner l'arrêt des intégrations qui l'utilisent ! Cela ne peut pas être restauré une fois supprimé.",
  "WEBHOOK_DETAILS": "Détails Webhooks",
  "WEBHOOK_ATTEMPTS": "Essai Webhook",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Tentatives d'envoi d'évènements à votre point de terminaison lors des 30 derniers jours",
  "WEBHOOK_REQUEST_BODY": "Demande de contenu",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "Payload que nous envoyons à votre point de terminaison",
  "WEBHOOK_RESPONSE": "Réponse",
  "WEBHOOK_RESPONSE_MESSAGE": "La réponse que nous avons reçu depuis votre point de terminaison",
  "WEBHOOK_REMOVE_TITLE": "Supprimer webhook ?",
  "WEBHOOK_REMOVE_MESSAGE": "Êtes-vous sûr de vouloir supprimer ce webhook ? Saviez-vous que vous pouvez aussi le <b>désactiver</b> depuis la vue Détails ?",
  "ADD_SUBSCRIBER": "Ajouter abonné",
  "EVENT_TYPES": "Types d'évènements",
  "SELECT_EVENT_TYPES": "Sélectionnez le type d'évènement auquel vous souhaitez souscrire",
  "SECRET": "Secret",
  "SERVICE_ACCOUNTS_NAME": "Nom",
  "SERVICE_ACCOUNTS_TYPE": "Type",
  "SERVICE_ACCOUNTS_ACTIONS": "Actions",
  "SERVICE_ACCOUNTS_RECONNECT": "Reconnecter",
  "SERVICE_ACCOUNTS_DELETE": "Supprimer",
  "SERVICE_ACCOUNTS_ADD": "Ajouter un Compte Tiers",
  "SERVICE_ACCOUNTS_NON_FOUND": "Pas de comptes tiers configurés",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "Échec du chargement des comptes tiers",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "Échec de la mise à jour du compte tiers",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "Échec de la création du compte tiers",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "Échec reconnexion compte tiers",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "Échec suppression compte tiers",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Supprimer compte tiers",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "Êtes-vous sûr(e) de vouloir supprimer le compte tiers <b>'{{ displayName }}'</b>? <br><br> Les formulaires avec des intégrations qui utilisent ce compte tiers vont cesser de fonctionner.",
  "GET_STARTED": "Commencer",
  "MANAGE": "Gérer",
  "SSO_DSYNC": "Directory sync",
  "SSO": "Authentification unique",
  "SSO_DESCRIPTION": "Connectez votre répertoire d'entreprise et votre méthode d'identification pour simplifier la gestion des utilisateurs",
  "SSO_SHARED_WARNING": "Cette configuration d'Authentification Unique (SSO) est utilisée par plusieurs clients, tout changement affectera tout le monde.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Utilisez l'authentification unique pour vous connecter avec les informations d'identification que vous utilisez pour toutes vos autres applications.</h4><p></p><br><p>Cette fonctionnalité est disponible à partir de la <b>formule Forêt.</b></p>",
  "SSO_CONNECTION_ACTIVE": "Actif",
  "SSO_CONNECTION_INACTIVE": "Inactif",
  "SSO_CONNECTION_DRAFT": "Brouillon",
  "SSO_CONNECTION_PENDING": "En attente",
  "DSYNC_CONNECTION_LINKED": "Associé",
  "DSYNC_CONNECTION_UNLINKED": "Dissocié",
  "DSYNC_CONNECTION_PENDING": "En attente",
  "SSO_STEP_1_TITLE": "Commencer",
  "SSO_STEP_2_TITLE": "Configurer l'authentification unique (SSO)",
  "SSO_STEP_2_DESCRIPTION": "Connectez-vous à votre identity provider",
  "SSO_STEP_3_TITLE": "Configurer Directory Synchronisation",
  "SSO_STEP_3_DESCRIPTION": "Synchronisez vos groupes et vos utilisateurs pour les tenir à jour",
  "SSO_STEP_4_TITLE": "SSO activé",
  "SSO_STEP_4_DESCRIPTION": "Vos utilisateurs peuvent maintenant utiliser le SSO pour se connecter",
  "SSO_IMPORTED_USERS": "{{count}} utilisateurs importés de votre répertoire",
  "SSO_IMPORTED_GROUPS": "{{count}} groupes importés de votre répertoire",
  "SSO_NO_IMPORTED_USERS_GROUPS": "Aucun utilisateur ou groupe importé",
  "SENDING_DOMAIN_TITLE": "Configurez votre domaine d'e-mail",
  "SENDING_DOMAIN_DESCRIPTION": "Faites en sorte que vos e-mails soient plus reconnaissables et cohérents avec la marque de votre entreprise. Nous enverrons des e-mails (comme des rapports PDF) depuis <b>monentreprise.com</b> au lieu de moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "Ce domaine d'e-mail est utilisé par plusieurs clients, tout changement affectera tout le monde.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "Pour passer à un autre domaine, il vous suffit de le supprimer et d'en créer un nouveau",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Domaine",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Domaine",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "Le domaine utilisé pour envoyer les e-mails, tel que <a>monentreprise.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Nom d'origine par défaut",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "Un nom ou un titre qui sera reconnaissable par vos destinataires. Dans la plupart des cas, il s'agira du nom de votre entreprise.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "E-mail d'origine par défaut",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "L'e-mail d'origine sera utilisé comme e-mail source. Ce sera également l'e-mail de réponse.",
  "SENDING_DOMAIN_DELETE_TITLE": "Supprimer le domaine d'e-mail personnalisé ?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "Cela signifie que vos e-mails seront à nouveau envoyés à partir de notre domaine par défaut. Vous n'aurez plus besoin des enregistrements DNS associés. Vous pouvez les supprimer auprès de votre fournisseur de domaine.",
  "SENDING_DOMAIN_VERIFY_TITLE": "Ajouter Enregistrements DNS",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Ajouter ces enregistrements DNS à votre fournisseur de domaine pour vérifier que vous en êtes bien le propriétaire. La vérification peut prendre jusqu'à 48 heures, le temps que les modifications de vos enregistrements se propagent.",
  "SENDING_DOMAIN_VERIFY_BTN": "Vérifier les enregistrements",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Domaine invalide '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "L'e-mail d'origine doit provenir du domaine '{{domain}}'",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>Envoyez des e-mails (comme des rapports PDF) à partir du domaine de votre entreprise</h4><p>Cette fonctionnalité est disponible à partir de la formule <b>Branche</b></p>",
  "SETTINGS_SUCCESS_MESSAGE": "Vos réglages ont été sauvegardés",
  "DYNAMIC_RECIPIENTS_ADD": "Ajouter un destinataire",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Défaut",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "Champs d'e-mail",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Autres champs",
  "TASKS_NEW": "Nouvelle tâche",
  "TASKS_PUBLISH_DATE": "Publié",
  "TASKS_MESSAGE": "Message",
  "TASKS_AND_X_OTHERS": "et {{others}} plus",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "Une erreur s'est produite lors du chargement des tâches. Nous vous prions de bien vouloir nous excuser pour ce désagrément. Vous pouvez essayer d'actualiser la page.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "Le dossier sélectionné n'est pas publié. Pour créer des tâches pour ce dossier, vous devez le publier dans la section des formulaires.",
  "TASK_ADD_TITLE": "Nouvelle tâche",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Sélectionnez le(s) utilisateur(s) et/ou le(s) groupe(s)",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} utilisateur(s)",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "Il n'y a pas d'utilisateurs dans ce groupe",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Utilisateurs de ce groupe :",
  "TASK_ADD_MESSAGE_LABEL": "Message",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "Aucun utilisateur ou groupe n'a accès à ce formulaire. Veuillez donner l'accès à vos utilisateurs en utilisant la fonction \"Gérer les utilisateurs\" dans l'aperçu du formulaire.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Envoyez une tâche",
  "TASK_DETAIL_TITLE": "Tâche",
  "TASK_DETAIL_MESSAGE": "Message",
  "TASK_DETAIL_ACTIONS": "Actions",
  "TASK_DETAIL_ACTION_COMPLETE": "Complétez",
  "TASK_DETAIL_ACTION_REVOKE": "Révoquez",
  "TASKS_GRID_NO_ENTRIES": "Il n'y a pas de tâches dans ce formulaire",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Immédiatement",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "Dans une heure",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "Dans 2 heures",
  "TASKS_ADD_PUBLISH_TOMORROW": "Demain",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Complétée",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "En attente",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Refusé",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Annulée",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Complétée",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Enregistrement inséré sur la ligne: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Tâches créées pour :",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "Aucune tâche n'a été créée car il n'y avait pas de destinataires",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "Le rapport a été envoyé par e-mail à :",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "Le rapport a été envoyé par e-mail à :"
}
;