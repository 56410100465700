'use strict';

require('./bootstrap');

require('./directives/ArrayCarousel');
require('./directives/ContentEditable');
require('./directives/CollapsablePanel');
require('./directives/CustomerPicker');
require('./directives/CustomerUiSelect');
require('./directives/DateTimeFormatted');
require('./directives/InfoIcon');
require('./directives/MoreResource');
require('./directives/MoreResourceProperty');
require('./directives/MoreSummernote');
require('./directives/MoreUrlProperty');
require('./directives/NgEnter');
require('./directives/NgEscape');
require('./directives/LoadingSpinner');
require('./directives/OnlyNumericList');
require('./directives/ItemHtmlPicker');
require('./directives/ItemHtmlEditor');
require('./directives/IntegrationUserConfiguration');
require('./directives/ValidationMessages');
require('./directives/CheckableInput');
require('./directives/MoreLanguagePicker');
require('./directives/DynamicRecipientsPicker');
require('./directives/UiReadonly');
require('./directives/DatePicker');
require('./directives/OauthLogin');
require('./directives/MoreLanguageSelect');
require('./directives/WizardStep');

require('./directives/layout/MoreTopMenu');
