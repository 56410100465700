'use strict';

angular.module('UserManagement').controller('AddUserToGroupModalCtrl', ['$q', '$scope', '$state', '$translate', '$customerUserService', '$customerInviteService', '$customerGroupsService', '$modalService', 'groupId', function ($q, $scope, $state, $translate, $customerUserService, $customerInviteService, $customerGroupsService, $modalService, groupId) {

  const self = this;
  const customerId = $state.params.customerId;
  self.groupId = groupId;

  self.addUsersToGroup = (users) => users.forEach(u => addUserToGroup(u));
  self.availableUsersOrInvites = [];
  self.selectedUsers = [];

  init();

  function init() {
    const usersPromise = $customerUserService.getUsers(customerId).$promise;
    const invitePromise = $customerInviteService.getInvites(customerId).$promise;

    $q.all([usersPromise, invitePromise]).then(res => {
      const [users, invites] = res;
      const availableUsers = users.filter(u => u.groups.indexOf(groupId) === -1);
      const availableInvites = invites
        .filter(invite => invite.groups.indexOf(groupId) === -1)
        .map(invite => ({
          username: invite.emailAddress + ' ' + $translate.instant('USER_PICKER_INVITED'),
          id: invite.id,
          type: 'invite'
        }));

      self.availableUsersOrInvites = availableUsers.concat(availableInvites);
    });
  }

  function addUserToGroup(user) {
    if (user.type === 'invite') {
      $customerInviteService.addGroup(customerId, groupId, user.id).then(
        () => $scope.$close(),
        () => $modalService.errorModal()
      );
    } else {
      $customerGroupsService.addUser(customerId, groupId, user.id).then(
        () => $scope.$close(),
        () => $modalService.errorModal()
      );
    }
  }

}]);
