'use strict';

angular.module('Activation').controller('InviteCtrl', ['$scope', '$state', '$inviteService', '$translate',  function ($scope, $state, $inviteService, $translate) {

  var self = this;

  self.passwordConfirmation = '';
  self.errorMessage = null;
  self.validToken = null;
  self.isAdmin = false;
  self.activateAccount = activateAccount;
  self.activateExistingAccount = activateExistingAccount;
  self.status = 'PENDING';

  init();

  ////////
  function init() {
    _executeTokenCheck($state.params.token);
  }

  function activateExistingAccount() {
    self.errorMessage = null;
    $scope.validationErrors = null;

    self.activating = true;
    $inviteService.acceptInvite($state.params.token).$promise.then(function () {
      self.activating = false;
      $state.transitionTo('activation_success');
    }, function (error) {
      if (error.data.details) {
        $scope.validationErrors = error.data.details;
      } else {
        self.errorMessage = error.data.message;
      }
      self.activating = false;
    });
  }

  function activateAccount() {
    self.errorMessage = null;
    $scope.validationErrors = null;

    if (self.inviteOnboarding.password !== self.passwordConfirmation) {
      self.errorMessage = 'ACTIVATION_PASSWORD_MISMATCH';
      return;
    }

    self.activating = true;
    $inviteService.acceptInvite($state.params.token, self.inviteOnboarding).$promise.then(function (res) {
      self.activating = false;
      $state.transitionTo('activation_success');
    }, function (error) {
      if (error.data.details) {
        $scope.validationErrors = error.data.details;
      } else {
        self.errorMessage = error.data.message;
      }
      self.activating = false;
    });
  }

  function _executeTokenCheck(token) {
    $inviteService.getInvite(token).$promise.then(function (userInvite) {
      self.validToken = true;
      self.status = userInvite.status;
      self.username = userInvite.emailAddress;
      self.customerName = userInvite.customerName;
      self.inviteOnboarding = {
        settings: userInvite.settings,
        password: null
      };
      if (self.inviteOnboarding.settings.receiveNewsLetter === null) {
        self.inviteOnboarding.settings.receiveNewsLetter = false;
      }
      self.inviteOnboarding.settings.language = $translate.use();
    }).catch(() => {
      self.validToken = false;
    });
  }
}]);
