'use strict';

angular.module('UserPicker').directive('userPicker', ['$customerUserService', '$state', '$userStateService', '$customerGroupService', '$translate', '$customerInviteService', '$q', '$featureHelper', 'moreConstants', function ($customerUserService, $state, $userStateService, $customerGroupService, $translate, $customerInviteService, $q, $featureHelper, moreConstants) {

  return {
    restrict: 'E',
    template: require('../templates/user-picker.html'),
    scope: {
      selectUserCallback: '&',
      focusGroupCallback: '&',
      selectGroupCallback: '&',
      addUserCallback: '&',
      addGroupCallback: '&'
    },
    link: function (scope) {
      var userState = $userStateService.getState();
      var invitesGroup = {
        managable: false,
        id: 'invites',
        users: [],
        name: $translate.instant('USER_PICKER_INVITES')
      };

      var addInvite = function (invite) {
        var inviteUser = {
          isInvite: true,
          username: invite.emailAddress + ' ' + $translate.instant('USER_PICKER_INVITED'),
          emailAddress: invite.emailAddress,
          id: invite.emailAddress,
          settings: invite.userAccountInformation,
          roles: invite.customerLink ? invite.customerLink.roles : {},
          groups: invite.customerLink ? invite.customerLink.groups : []
        };

        var existing = scope.users.filter(function (user) {
          return user.emailAddress === invite.emailAddress;
        });
        if (!existing.length) {
          scope.users.push(inviteUser);
        }

        invitesGroup.users.push(invite.emailAddress);
      };

      var buildInvitesGroup = function (customerId) {
        invitesGroup.users = [];
        var deferred = $q.defer();
        $customerInviteService.getInvites(customerId).$promise.then(function (invites) {
          invites.forEach(function (invite) {
            addInvite(invite);
          });
          deferred.resolve(invitesGroup);
        });
        return deferred.promise;
      };

      var buildAllUsersGroup = function (users) {
        var userIdList = users.map(function (user) {
          return user.id;
        });
        return {
          id: 'all_users_group',
          users: userIdList,
          name: $translate.instant('USER_PICKER_ALL_USERS')
        };
      };

      var loadGroups = function (customerId) {
        customerId = customerId || $state.params.customerId;
        $customerGroupService.getGroups(customerId).$promise.then(function (groups) {
          $customerUserService.getUsers(customerId).$promise.then(function (users) {
            scope.users = users;
            groups.push(buildAllUsersGroup(users));
            scope.groups = groups;

            groups.forEach(function (group) {
              if (group.id === userState.groupId) {
                group.$$open = true;
              }
            });

            buildInvitesGroup(customerId, users).then(function (inviteGroup) {
              groups.push(inviteGroup);
            });
          });
        });
      };

      loadGroups();

      scope.orderGroupsByName = function (group) {
        // this makes sure our special 'All Users' group is always on top
        if (group.id === 'all_users_group') {
          return -1;
        }
        if (group.id === 'invites') {
          return 'zzz';
        }
        return group.name;
      };

      scope.$on('group.removed', function (event, removedGroup) {
        var index = scope.groups.indexOf(removedGroup);
        scope.groups.splice(index, 1);
        var userState = $userStateService.getState();
        if (userState.groupId === removedGroup.id) {
          $userStateService.setGroupId(null);
        }
      });

      scope.$on('group.added', function (event, newGroup) {
        newGroup.$$open = true;
        newGroup.$$groupUsers = [];
        scope.groups.push(newGroup);
        scope.selectGroupCallback({ group: newGroup });
      });

      scope.$on('user.added', function () {
        buildInvitesGroup($state.params.customerId, scope.users).then(function (invitesGroup) {
          invitesGroup.$$open = false;
          invitesGroup.$$groupUsers = null;
        });
      });

      scope.$on('group.updated', function (event, newGroup) {
        for (var i = 0; i < scope.groups.length; i++) {
          if (scope.groups[i].id === newGroup.id) {
            newGroup.$$open = true;
            scope.groups[i] = newGroup;
          }
        }
      });
    }
  };
}]);
