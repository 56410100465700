'use strict';

angular.module('more.ui.form-builder').factory('$resourceHelper', ['$q', '$moreResourceService', function ($q, $moreResourceService) {

  return {
    asUrl: function (customerId, resourceId) {
      var deferred = $q.defer();
      $moreResourceService.asUrl(customerId, resourceId).then(deferred.resolve, deferred.reject);
      return deferred.promise;
    }
  };
}]);