'use strict';

angular.module('MoreAppsServices').factory('$customerFormService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerForm = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/forms/:formId', {'customerId' : '@customerId', 'formId' : '@formId'}, {
    publish: { method: 'PATCH' },
    update: { method: 'PATCH' }
  });
  var CustomerFormVersion = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/forms/:formId/versions/:formVersionId/:action', {'customerId' : '@customerId', 'formId' : '@formId', 'formVersionId' : '@formVersionId'}, {
    update: { method: 'PUT' },
    finalize: { method: 'POST', params: { 'action': 'finalize' } },
    copy: { method: 'POST', params: { 'action': 'copy' } },
    delete: { method: 'DELETE' }
  });

  var CustomerTemplate = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/forms/templates/:formVersionId', {'customerId' : '@customerId', 'formVersionId' : '@formVersionId'}, {});

  var CustomerPdf = $resource(moreConstants.apiEndpoint + '/customers/:customerId/forms/:action', { 'customerId': '@customerId' }, {
    defaultPdfContent: { method: 'POST', params: { 'action': 'defaultPdfContent' } },
  });


  return {
    getDefaultFormNames() {
      return {
        en: 'My form',
        nl: 'Mijn formulier',
        es: 'Mi formulario',
        de: 'Meine Form',
        ru: 'Моя форма',
        pt: 'Meu formulário',
        fr: 'Mon formulaire'
      };
    },
    getDefaultFolderNames() {
      return {
        en: 'My folder',
        nl: 'Mijn map',
        es: 'Mi carpeta',
        pt: 'Minha pasta',
        de: 'Meine Ordner',
        ru: 'Моя папка',
        fr: 'Mon dossier'
      };
    },
    getForm : function (customerId, formId) {
      return CustomerForm.get({customerId : customerId, formId : formId});
    },
    createForm : function (customerId, form) {
      return CustomerForm.save({customerId : customerId}, form);
    },
    trashForm : function (customerId, formId) {
      return CustomerForm.remove({customerId : customerId, formId : formId});
    },
    getFormVersions : function (customerId, formId, page = 0) {
      return CustomerFormVersion.query({customerId, formId, page});
    },
    getFormVersion : function (customerId, formId, formVersionId) {
      return CustomerFormVersion.get({customerId : customerId, formId : formId, formVersionId: formVersionId});
    },
    getFormVersionTemplate : function (customerId, formVersionId) {
      return CustomerTemplate.get({customerId : customerId, formVersionId: formVersionId});
    },
    getDefaultPdf : function (customerId, form, triggerConfiguration) {
      return CustomerPdf.defaultPdfContent({ customerId: customerId }, { form: form, mailTriggerConfiguration: triggerConfiguration });
    },
    createFormVersion : function (customerId, formId, formVersion) {
      return CustomerFormVersion.save({customerId : customerId, formId : formId}, formVersion);
    },
    saveFormVersion : function (customerId, formId, formVersionId, formVersion) {
      return CustomerFormVersion.update({customerId : customerId, formId : formId, formVersionId: formVersionId}, formVersion);
    },
    finalizeFormVersion : function (customerId, formId, formVersionId) {
      return CustomerFormVersion.finalize({customerId : customerId, formId : formId, formVersionId: formVersionId});
    },
    publishFormVersion : function (customerId, formId, formVersionId) {
      return CustomerForm.publish({customerId : customerId, formId : formId },{ publishedVersion: { formVersion: formVersionId } });
    },
    deleteFormVersion : function (customerId, formId, formVersionId) {
      return CustomerFormVersion.delete({customerId : customerId, formId : formId, formVersionId: formVersionId });
    },
    copyFormVersion : function(customerId, formId, formVersionId, newFolderId, newFormName, newCustomerId) {
      return CustomerFormVersion.copy({customerId, formId, formVersionId }, { folderId: newFolderId, formName: newFormName, customerId: newCustomerId });
    },
    updateName(customerId, formId, name) {
      return CustomerForm.update({customerId, formId}, {meta: {name}});
    },
    updateDescription(customerId, formId, description) {
      return CustomerForm.update({customerId, formId}, {meta: {description}});
    },
    updateIcon(customerId, formId, icon) {
      return CustomerForm.update({customerId, formId}, {meta: {icon}});
    }
  };
}]);
