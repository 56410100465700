'use strict';

(function (factory) {
  /* global define */
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['jquery'], factory);
  } else {
    // Browser globals
    factory(window.jQuery);
  }
})(function ($) {

  $.extend($.summernote.plugins, {
    'placeholders': function (context) {
      var ui = $.summernote.ui;

      var options = context.options;
      var scope = context.options.scope;

      var labelOfField = function (field) {
        return '${' + field.data_name + '}';
      };

      context.memo('button.placeholders', function () {
        return ui.buttonGroup([ui.button({
          className: 'dropdown-toggle',
          contents: '<i class="fa fa-bookmark"> Placeholders ' + ui.icon(options.icons.caret, 'span'),
          tooltip: 'Placeholders',
          data: {
            toggle: 'dropdown'
          }
        }), ui.dropdown({
          className: 'placeholders-dropdown',
          items: scope.availablePlaceholderFields.map(function (field) {
            return labelOfField(field);
          }),
          click: function (event) {
            var $button = $(event.target);
            var value = $button.data('value');

            var selectedField = scope.availablePlaceholderFields.filter(function (field) {
              var label = labelOfField(field);
              return label === value;
            })[0];

            if (selectedField.dataType === 'file' && (selectedField.widgetFullkey === 'com.moreapps:photo' || selectedField.widgetFullkey === 'com.moreapps:signature')) {
              var imgNode = document.createElement('img');
              imgNode.src = value;
              imgNode.style.maxWidth = '200px';
              imgNode.style.maxHeight = '200px';
              context.invoke('editor.insertNode', imgNode);
            } else if (selectedField.dataType === 'file') {
              var anchorNode = document.createElement('a');
              anchorNode.href = value;
              var linkText = document.createTextNode(value);
              anchorNode.appendChild(linkText);
              context.invoke('editor.insertNode', anchorNode);
            } else {
              context.invoke('editor.insertText', value);
            }
          }
        })]).render();
      });
    }
  });
});