'use strict';

require('./bootstrap');
require('./controllers/PortalDataSourcesCtrl');
require('./controllers/AddDataSourcePanelCtrl');
require('./controllers/EditDataSourcePanelCtrl');
require('./controllers/RemoveDataSourceCtrl');
require('./controllers/UploadExcelDataSourceCtrl');
require('./controllers/DataSourceEntryDetailCtrl');
require('./directives/DataSourceEntrySearch');
require('./directives/DataSourceEntryTable');
require('./directives/DataSourceUrlConfiguration');
require('./directives/DataSourceExcelConfiguration');
require('./directives/DataSourceGoogleSpreadsheetConfiguration');