'use strict';

angular.module('MoreI18n', ['pascalprecht.translate', 'ngCookies']);

angular.module('MoreI18n').config(['$translateProvider', function ($translateProvider) {

  // Copied from angular-translate 2.5.1
  // tries to determine the browsers language
  var getFirstBrowserLanguage = function () {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    // support for HTML 5.1 "navigator.languages"
    if (angular.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language;
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return null;
  };

  // Copied from angular-translate 2.5.1
  // tries to determine the browsers locale
  var getLocale = function () {
    return (getFirstBrowserLanguage() || '').split('-').join('_');
  };

  var preferredLanguage = getLocale();
  if (preferredLanguage) {
    preferredLanguage = preferredLanguage.split('_')[0];
  } else {
    preferredLanguage = 'en';
  }

  $translateProvider
    .useSanitizeValueStrategy('sanitize')
    .translations('source', require('./translations/translation.json'))
    .translations('en', require('./translations/en/translation.json'))
    .translations('es', require('./translations/es/translation.json'))
    .translations('pt', require('./translations/pt/translation.json'))
    .translations('fr', require('./translations/fr/translation.json'))
    .translations('nl', require('./translations/nl/translation.json'))
    .translations('de', require('./translations/de/translation.json'))
    .translations('ca', require('./translations/ca/translation.json'))
    .useCookieStorage()
    .fallbackLanguage('source')
    .preferredLanguage(preferredLanguage);
}]);
