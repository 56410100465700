'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', '$moreResourceModalStateProvider', function ($stateProvider, stateConfig, $moreResourceModalStateProvider) {

  $stateProvider.state('portal.customers.form', {
    url: '/form',
    template: require('../../modules/FormManagement/templates/formmanagement.index.html'),
    access: stateConfig.defaultAccess,
    data: {
      pickerTemplate: require('../templates/portal/pickers/folder.html')
    },
    abstract: true
  }).state('portal.customers.form.overview', {
    url: '/overview',
    access: stateConfig.defaultAccess,
    data: {
      viewContentTemplate: require('../../modules/FormManagement/templates/formmanagement.overview.html')
    }
  }).state('portal.customers.form.overview.moreResource', $moreResourceModalStateProvider.$get())
    .state('portal.customers.form.registrations', {
    url: '/submissions',
    access: stateConfig.defaultAccess,
    data: {
      viewContentTemplate: require('../../modules/FormManagement.submissions/templates/formmanagement.submissions.html')
    }
  }).state('portal.customers.form.tasks', {
    url: '/tasks',
    access: stateConfig.defaultAccess,
    data: {
      viewContentTemplate: require('../../modules/FormManagement.tasks/templates/formmanagement.tasks.html')
    }
  }).state('portal.customers.form.tasks.add', {
    url: '/add',
    access: stateConfig.defaultAccess,
    data: {
      viewContentTemplate: require('../../modules/FormManagement.tasks/templates/formmanagement.tasks.add.html')
    }
  });
}]);
