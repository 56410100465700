'use strict';

angular.module('MoreSidePanel', []);

angular.module('MoreSidePanel').run(['$templateCache', function($templateCache) {
  $templateCache.put('sidePanelWindow.html', require('../shared/templates/sidePanel/window.html'));
}]);

angular.module('MoreSidePanel').factory('$sidePanel', ['$modalService', function ($modalService) {
  return {
    open,
    close
  };

  ////////
  function open(options) {
    options = options || {};
    options.windowClass = options.windowClass || 'side-panel';
    options.windowTemplateUrl = 'sidePanelWindow.html'; // This one loads from $templateCache

    var offClick = closeOnBackdropClick();
    var result = $modalService.open(options);
    result.then(offClick, offClick);
    return result;
  }

  function close() {
    $modalService.closeActiveModal();
  }

  function closeOnBackdropClick() {
    var onClick = function (event) {
      var el = angular.element(event.target);
      if (el.hasClass('modal-backdrop')) {
        $modalService.closeActiveModal(event);
      }
    };
    $('body').on('click', onClick);
    return function () {
      $('body').off('click', onClick);
    };
  }
}]);
