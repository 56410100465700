'use strict';

angular.module('Billing').directive('billingPreviewChange', ['$state', '$billingService', function ($state, $billingService) {
  return {
    restrict: 'E',
    scope: {
      plan: '=',
      activeSubscription: '=',
      onPreviewChangeLoaded: '&'
    },
    template: require('../templates/directives/billing.preview-change.html'),
    link: function (scope) {
      const plan = scope.plan;
      const customerId = $state.params.customerId;
      const subscription = scope.activeSubscription ? scope.activeSubscription.subscription : undefined;

      scope.previewChange = $billingService.previewChangeSubscription(customerId, subscription, plan.product, plan.id);
      scope.previewChange.$promise.then(previewChange => {
        scope.onPreviewChangeLoaded({previewChange});
      });
    }

  };
}]);
