'use strict';

angular.module('MoreDirectives').directive('dateTimeFormatted', function () {

  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      format: '='
    },
    template: '{{ ngModel | date:format }}'
  };
});