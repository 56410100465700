'use strict';

angular.module('DataSources').controller('EditDataSourcePanelCtrl', ['$scope', '$customerDataSourceService', 'dataSource', function ($scope, $customerDataSourceService, dataSource) {

  $scope.dataSource = angular.copy(dataSource);
  $scope.editDataSourceForm = {};
  $scope.saving = false;

  $scope.saveDataSource = function (updatedDataSource) {
    $scope.saving = true;
    $customerDataSourceService.update($scope.$state.params.customerId, updatedDataSource).$promise.then(function (updatedDataSource) {
      $scope.saving = false;
      $scope.$close(updatedDataSource);
    });
  };
}]);