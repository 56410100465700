'use strict';

angular.module('DynamicForms').controller('DynamicFormCtrl', ['$scope', 'definition', 'config', '$translate', '$objectUtils', function ($scope, definition, config, $translate, $objectUtils) {
  var self = this;

  self.model = config.initial ? $objectUtils.flatten(config.initial) : {};

  definition.elements.forEach(function (element) {
    if (element.placeholder) {
      element.placeholder = $translate.instant(element.placeholder);
    } else {
      element.placeholder = '';
    }
    if (element.type === 'select' && element.options) {
      element.options.forEach(function (option) {
        option.label = $translate.instant(option.label);
      });

      if (element.defaultOption !== null) {
        self.model[element.property] = element.options[element.defaultOption].value;
      }
    }

    if (element.property) {
      element.id = element.property.replace(/\./g, '_');
    }
  });

  self.definition = definition;

  self.saveForm = function () {
    var copy = angular.copy(self.model);
    if (config.filterKeys) {
      Object.keys(copy).forEach(function (key) {
        if (config.filterKeys.indexOf(key) === -1) {
          delete copy[key];
        }
      });
    }

    self.saving = true;
    var params = [];
    if (config.resourceParams) {
      params = params.concat(config.resourceParams);
    }
    var normalized = $objectUtils.unflatten(copy);
    params.push(normalized);

    config.resource.apply(this, params).$promise.then(function (data) {
      if (config.onSuccess) {
        config.onSuccess(data);
      }
      $scope.$close();
    }, function (error) {
      if (error.status === 400 && error.data.scope === 'VALIDATION') {
        self.validationErrors = error.data.details;
      } else {
        self.errorMessage = 'UNKNOWN_ERROR';
      }
      self.saving = false;
    });
  };
}]);