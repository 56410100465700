'use strict';

angular.module('CustomerPortalSettings').controller('SettingsSsoCtrl', ['$state', '$customerSsoService', '$featureHelper', '$modalService', '$customerUserService', '$customerGroupsService', 'moreConstants', '$window', '$q', function ($state, $customerSsoService, $featureHelper, $modalService, $customerUserService, $customerGroupsService, moreConstants, $window, $q) {

  const self = this;

  init();

  function init () {
    $featureHelper.hasFeature(moreConstants.SINGLE_SIGN_ON).then(hasFeature => {
      self.hasSsoFeature = hasFeature;
    });

    $customerSsoService.getStatus($state.params.customerId).$promise.then(result => {
      self.status = result;
    });

    _loadAvailableUsers();
  }

  self.provisionCustomer = () => {
    $featureHelper.hasFeature(moreConstants.SINGLE_SIGN_ON).then(result => {
      if (result) {
        self.errorMessage = null;
        self.saving = true;
        $customerSsoService.provision($state.params.customerId).$promise.then(() => {
          self.saving = false;
          init();
        }, function (error) {
          self.saving = false;
          if (error.data.message) {
            self.errorMessage = error.data.message;
          } else {
            self.errorMessage = error;
          }
        });
      } else {
        $modalService.upgradePlanModal({
          title: 'FEATURE_SINGLE_SIGN_ON',
          message: 'FEATURE_SINGLE_SIGN_ON_MESSAGE',
          imgSrc: 'https://cdn.moreapp.com/features/en/feature-sso.png'
        });
      }
    });
  };

  self.goToSsoPortal = () => {
    $customerSsoService.getSsoPortalUrl($state.params.customerId, window.location.href).$promise.then((res) => {
      $window.open(res.url, '_self');
    });
  };

  self.goToDsyncPortal = () => {
    $customerSsoService.getDsyncPortalUrl($state.params.customerId, window.location.href).$promise.then((res) => {
      $window.open(res.url, '_self');
    });
  };

  function _loadAvailableUsers() {
    const usersPromise = $customerUserService.getUsers($state.params.customerId);
    const groupsPromise = $customerGroupsService.getGroups($state.params.customerId);

    $q.all([usersPromise.$promise, groupsPromise]).then((result) => {
      const [users, groups] = result;

      self.directoryGroups = groups.filter(group => group.externallyManaged);
      self.directoryUsers = users.filter(user => user.externallyManaged);
    });
  }

}]);
