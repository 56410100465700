'use strict';

angular.module('MoreDirectives').directive('customerPicker', ['$rootScope', '$timeout', '$modalService', '$securityService', function ($rootScope, $timeout, $modalService, $securityService) {
  return {
    restrict: 'E',
    template: require('../../../shared/templates/directives/customer-picker.html'),
    scope: {
      customers: '=',
      callback: '&',
      currentCustomer: '=?',
      selectedCustomerId: '=?'
    },
    link: function (scope) {
      var _customerIdIndex;
      var increaseLimitStep = 100;
      scope.filter = {
        value: ''
      };
      scope.filteredCustomers = [];
      scope.limit = 100;
      scope.showCreateCustomer = $rootScope.user.roles.CREATE_CUSTOMERS !== undefined;

      scope.selectCustomer = selectCustomer;
      scope.openDropdown = openDropdown;
      scope.findCustomers = findCustomers;
      scope.showMore = showMore;
      scope.createCustomer = createCustomer;


      init();

      ////////
      function init() {
        scope.$watch('customers', function (newVal) {
          if (newVal.length > 0) {
            scope.filteredCustomers = angular.copy(newVal);
            loadSelectedCustomer();
            buildCustomerIdIndex();
          }
        }, true);

        scope.$watch('filter.value', function (newVal, oldVal) {
          if (newVal !== oldVal) {
            findCustomers(newVal);
          }
        });
      }

      function loadSelectedCustomer() {
        const currentCustomerId = parseFloat(scope.selectedCustomerId);
        if (currentCustomerId) {
          const customer = getCustomerById(currentCustomerId);
          if (customer) {
            scope.selectCustomer(customer);
          }
        }
      }

      function showMore($event) {
        $event.preventDefault();
        $event.stopPropagation();
        scope.limit += increaseLimitStep;
      }

      function createCustomer() {
        $modalService.open({
          template: require('../../../modules/CustomerPortalSettings/templates/customer.new.html'),
          backdrop: 'static',
          size: 'lg',
          windowClass: 'modal-fullscreen',
          controller: 'CustomerCreateCtrl as ctrl',
        }).then(data => {
          scope.customers.push(data.customer);
          $securityService.updateRoles($rootScope.user);
          selectCustomer(data.customer);
        });
      }

      function buildCustomerIdIndex() {
        _customerIdIndex = scope.customers.reduce(function (map, obj) {
          map[obj.customerId] = obj;
          return map;
        }, {});
      }

      function selectCustomer(customer) {
        if (scope.currentCustomer !== customer) {
          $timeout(function () {
            scope.callback({ customer: customer });
            scope.currentCustomer = customer;
          });
        }
      }

      function findCustomers(query) {
        if (_customerIdIndex && _customerIdIndex[query]) {
          scope.filteredCustomers = [_customerIdIndex[query]];
        } else {
          scope.filteredCustomers = scope.customers.filter(filterCustomers);
        }
      }

      function filterCustomers(customer) {
        return customer.customerId.toString().indexOf(scope.filter.value) !== -1 || customer.name.toLowerCase().indexOf(scope.filter.value.toLowerCase()) !== -1;
      }

      function openDropdown() {
        $timeout(function () {
          angular.element('#customer-filter').focus();
        }, 10);
      }

      function getCustomerById(customerId) {
        return scope.customers.filter(function (customer) {
          return customer.customerId === customerId;
        })[0];
      }
    }
  };
}]);
