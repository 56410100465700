'use strict';

angular.module('Impersonation').factory('impersonationInterceptor', ['$q', '$rootScope', 'moreConstants', function ($q, $rootScope, moreConstants) {

  return {
    request: function (config) {
      const user = $rootScope.user;

      // Only add header if user is impersonating and is requesting an API endpoint
      if (user && user.impersonation && (config.url.startsWith(moreConstants.apiEndpoint) || config.url.startsWith(moreConstants.apiEndpointV2))) {
        config.headers['X-More-Consumer-Override'] = user.impersonation.value;
      }

      return config;
    }
  };
}]);
