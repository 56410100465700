'use strict';

angular.module('UserManagement').controller('UserInviteStatusCtrl', ['$scope', '$state', '$timeout', '$modalService', '$customerInviteService', function ($scope, $state, $timeout, $modalService, $customerInviteService) {
  const self = this;
  self.inviteResent = false;

  self.resendInvite = resendInvite;

  function resendInvite (id) {
    self.inviteResent = false;
    $customerInviteService.resendInvite($state.params.customerId, id).$promise.then(() => {
      self.inviteResent = true;
    }, () => $modalService.errorModal());
  }

}]);
