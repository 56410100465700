'use strict';

angular.module('more.ui.form-builder').factory('$videoHelper', ['$q', function ($q) {

  return {
    recordVideo: function () {
      var deferred = $q.defer();
      deferred.reject('PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE');
      return deferred.promise;
    }
  };
}]);