'use strict';

angular.module('customerPortal').controller('TemplatePickerCtrl', ['$scope', '$rootScope', '$sidePanel', function ($scope, $rootScope, $sidePanel) {

  $scope.selectTemplateCallback = function (template) {
    $rootScope.$broadcast('template.changed', template);
  };

  $scope.addTemplateCallback = function () {
    $sidePanel.open({
      template: require('../../../../modules/FormTemplates/templates/template.new.html'),
      controller: 'AddTemplatePanelCtrl as ctrl'
    }).then(newTemplate => {
      $scope.$broadcast('template.added', newTemplate);
    });
  };
}]);
