'use strict';

angular.module('more.ui.form-builder').factory('$drawingHelper', ['$modalService', '$q', function ($modalService, $q) {

    var showMessage = function (title, message) {
        return function () {
            $modalService.alertModal({
                title: title,
                message: message
            });
            var deferred = $q.defer();
            deferred.reject();
            return deferred.promise;
        };
    };

    return {
        draw: showMessage('CANVAS_NOT_SUPPORTED_TITLE', 'CANVAS_NOT_SUPPORTED_MESSAGE')
    };
}]);