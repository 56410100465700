'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', function ($stateProvider, stateConfig) {

  $stateProvider.state('portal.customers.clients', {
    url: '/clients',
    template: require('../../modules/Clients/templates/clients.index.html'),
    abstract: true,
    access: stateConfig.defaultAccess
  }).state('portal.customers.clients.content', {
    url: '/content',
    data: {
      viewContentTemplate: require('../../modules/Clients/templates/clients.content.html')
    },
    access: stateConfig.defaultAccess
  });
}]);