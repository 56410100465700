'use strict';

angular.module('MoreDirectives').directive('itemHtmlEditor', function () {
  return {
    restrict: 'E',
    template: require('../../MoreDirectives/templates/item-html-editor.html'),
    scope: {
      ngModel: '=',
      fields: '=',
      fieldName: '=',
      fieldRequired: '=',
      singleLine: '=',
      prefix: '=',
      allowImages: '='
    },
    replace: true,
    link: function (scope) {
      scope.$watch('fields', (newVal) => {
        scope.selectableFields = angular.copy(newVal);
      });

      scope.addToItemHtml = function (field) {
        var item = '${' + field + '}';

        if (!scope.ngModel) {
          scope.ngModel = '';
        }
        if (scope.ngModel.indexOf(item) > -1) {
          return;
        }

        if (!scope.singleLine) {
          item += ' ';
        }

        scope.ngModel += item;
      };
    }
  };
});
