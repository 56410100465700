'use strict';

angular.module('FormManagement.submissions').controller('FormManagementMailStatusCtrl', ['$scope', '$sidePanel', '$translate', function ($scope, $sidePanel, $translate) {
  var self = this;
  var registrationId = $scope.registration.id;
  var customerId = $scope.registration.info.customerId;

  self.mailStatus = null;

  self.openMailStatusDetail = openMailStatusDetail;

  const isQueuedEvent = event => event.status === 'queued';

  init();

  ////////
  function init() {
    _findOverallStatus();
  }

  function openMailStatusDetail() {
    $sidePanel.open({
      template: require('../templates/formmanagement.submissions.mailstatusdetail.html'),
      resolve: {
        registration: function () {
          return $scope.registration;
        },
        customerId: function () {
          return customerId;
        },
        registrationId: function () {
          return registrationId;
        }
      },
      controller: 'FormManagementMailStatusDetailCtrl as ctrl'
    });
  }

  function _findOverallStatus() {
    self.mailStatus = {
      overall: '',
      send: 0,
      queued: 0,
      hard_bounce: 0,
      soft_bounce: 0,
      reject: 0,
      message: ''
    };
    if (!$scope.registration.mailStatuses) {
      return;
    }

    const mailStatuses = $scope.registration.mailStatuses
      .filter(status => status.emailAddresses && status.emailAddresses.length > 0)
      .filter(status => status.attempt === $scope.registration.info.resends);
    if (!mailStatuses) {
      return;
    }

    mailStatuses.forEach(status => {
      Object.keys(status.status).forEach(key => {
        const singleStatus = status.status[key];
        if (singleStatus.transitions) {
          // Transition events can be received in different order than they actually happened, so sort before doing anything else
          // always move 'queued' event to the start, because it CAN have the exact same or later timestamp as events like 'send', 'blocked', etc
          singleStatus.transitions.sort((a, b) => (a.date >= b.date ? 1 : -1));
          singleStatus.transitions.sort((a) => isQueuedEvent(a) ? -1 : 0);

          const lastTransition = singleStatus.transitions[singleStatus.transitions.length - 1];

          self.mailStatus[lastTransition.status]++;
        }
      });

    });
    ['send', 'queued', 'soft_bounce', 'hard_bounce', 'reject'].forEach(status => {
      if (self.mailStatus[status] > 0) {
        self.mailStatus.overall = status;
        _addToMessage(self.mailStatus[status], status);
      }
    });
  }

  function _addToMessage(number, message) {
    if (self.mailStatus.message.length > 0) {
      self.mailStatus.message += ', ';
    }
    self.mailStatus.message += $translate.instant('MAIL_STATUS_' + message.toUpperCase(), { number: number });
  }
}]);
