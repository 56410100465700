'use strict';

angular.module('FormEditor').directive('validationErrors', function () {

  return {
    restrict: 'E',
    template: require('../templates/validation-errors.html'),
    scope: {
      validationErrors: '=',
      disableNavigation: '=',
      goToView: '&'
    }
  };
});