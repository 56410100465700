'use strict';

angular.module('MoreAppsServices').factory('$customerBillingService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const CustomerBilling = $resource(moreConstants.apiEndpoint + '/customers/:customerId/billing', {}, {
    update: { method: 'PATCH' }
  });

  return {
    getBilling: (customerId) => CustomerBilling.get({customerId}),
    updateBilling: (customerId, billing) => CustomerBilling.update({customerId}, billing),
  };
}]);
