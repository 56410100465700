'use strict';

angular.module('PageControl').directive('pageController', function () {
  return {
    restrict: 'E',
    template: require('../templates/PageController.html'),
    scope: {
      pageSizes: '=',
      onChange: '&',
      itemCount: '=',
      defaultPageSize: '@'
    },
    link: function (scope) {
      var lastConfig = null;
      var triggerChange = function (newConfig) {
        if (!angular.equals(lastConfig, newConfig)) {
          scope.onChange(newConfig);
        }
        lastConfig = newConfig;
      };

      scope.activePageSize = !!parseInt(scope.defaultPageSize) ? scope.defaultPageSize : scope.pageSizes[0];

      var handleVariableChanges = function (notify) {
        scope.numberOfPages = Math.ceil(scope.itemCount / scope.activePageSize) || 1;
        if (scope.activePage === null || scope.activePage === undefined) {
          scope.activePage = 0;
        }
        scope.activePage = Math.min(scope.numberOfPages - 1, scope.activePage);
        var newConfig = { page: scope.activePage, pageSize: scope.activePageSize };
        if (notify) {
          triggerChange(newConfig);
        } else {
          lastConfig = newConfig;
        }
      };
      handleVariableChanges(false);

      scope.$watch('activePageSize', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          handleVariableChanges(true);
        }
      });
      scope.$watch('itemCount', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          handleVariableChanges(true);
        }
      });

      scope.next = function () {
        scope.activePage = scope.activePage + 1;
        triggerChange({ page: scope.activePage, pageSize: scope.activePageSize });
      };

      scope.previous = function () {
        scope.activePage = scope.activePage - 1;
        triggerChange({ page: scope.activePage, pageSize: scope.activePageSize });
      };

      scope.first = function () {
        scope.activePage = 0;
        triggerChange({ page: scope.activePage, pageSize: scope.activePageSize });
      };

      scope.last = function () {
        scope.activePage = scope.numberOfPages - 1;
        triggerChange({ page: scope.activePage, pageSize: scope.activePageSize });
      };
    }
  };
});