'use strict';
angular.module('more.ui.form').directive('customerResource', function ($resourceHelper) {
  return {
    restrict : 'E',
    scope : {
      resource : '=',
      customerId : '=',
      maxWidth : '=',
      maxHeight : '='
    },
    template : require('../templates/customer-resource.html'),
    link : function (scope) {
      scope.$watch('resource', (newVal) => {
        if (!newVal) {
          scope.noImage = true;
          return;
        }
        $resourceHelper.asUrl(scope.customerId, newVal).then((customerResourceUrl) => {
          scope.customerResourceUrl = customerResourceUrl;
        }, () => {
          scope.noImage = true;
        });
      });
    }
  };
});
