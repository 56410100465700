'use strict';

angular.module('FormEditor').directive('dragSection', function () {
  return {
    restrict: 'A',
    controller: ['$scope', '$state', function ($scope, $state) {

      $scope.deleteFavorite = function (widget) {
        var newParams = angular.copy($state.params);
        newParams.widgetId = widget.favoriteId;
        newParams.widgetUid = widget.info.uid;
        $state.transitionTo($state.current.name + '.deleteFavorite', newParams);
      };
    }]
  };
});