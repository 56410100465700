'use strict';

angular.module('WidgetPropertyPane').directive('fieldPropertyConfiguration', ['$widgetService', function ($widgetService) {

  var cache = {};

  function uidToType(widget) {
    if (cache[widget] !== undefined) {
      return cache[widget];
    }
    var widgetWithSync = $widgetService.getWidgetWithSync(widget);
    if (!widgetWithSync) {
      return;
    }
    var definition = widgetWithSync.definition;
    if (definition && definition.data && definition.data.type) {
      cache[widget] = definition.data.type;
    } else {
      cache[widget] = null;
    }
    return cache[widget];
  }

  function getTypeFilter(config, viewId) {
    var typeRestriction = config.type_restrictions;
    if (!typeRestriction) {
      return function (field) {
        return !viewId || field.viewId === viewId;
      };
    }

    return function (field) {
      var type = uidToType(field.widget);
      return typeRestriction.indexOf(type) !== -1 && (!viewId || field.viewId === viewId);
    };
  }

  function addEachPropertyAsSelectable(selectableFields, callback) {
    selectableFields.forEach(function (field) {
      if (field.dataType === 'datasource') {
        if (field.properties) {
          field.properties.forEach(function (property) {
            callback({
              displayName: field.displayName + '.' + property,
              uid: field.uid + '.' + property,
              widget: field.widget,
              viewId: field.viewId,
              property: property,
              icon: field.icon
            });
          });
        }
      } else {
        callback(field);
      }
    });
  }

  return {
    restrict: 'E',
    scope: {
      property: '=',
      target: '=',
      viewFields: '=',
      viewId: '=',
      formVersion: '='
    },
    template: require('../templates/directives/field-property-configuration.html'),
    link: function (scope) {
      var config = scope.property.configuration || {};
      var filter = getTypeFilter(config, scope.viewId);
      var key = scope.property.key;
      scope.selectableFields = [];
      var selectableFields = scope.viewFields.filter(filter);
      if (config.datasource_keys === true) {
        addEachPropertyAsSelectable(selectableFields, function (datasourcePropertyField) {
          scope.selectableFields.push(datasourcePropertyField);
        });
      } else {
        scope.selectableFields = selectableFields;
      }

      if (config.user_selectable) {
        scope.selectableFields.unshift({
          uid: 'username',
          icon: 'fa-user',
          displayName: 'Username'
        });
      }

      var value = scope.target[key];
      if (config.multiple) {
        if (typeof value === 'string') {
          scope.target[key] = [value];
        }
      } else if (Array.isArray(value)) {
        if (value.length === 1) {
          scope.target[key] = value[0];
        } else {
          scope.target[key] = [];
        }
      }
    }
  };
}]);
