'use strict';

angular.module('more.ui.form-builder').service('$actionSheetHelper', ['$modalService', '$timeout', '$q', function ($modalService, $timeout, $q) {

  var showMessage = function (title, message) {
    $modalService.alertModal({
      title: title,
      message: message
    });
    var deferred = $q.defer();
    deferred.reject();
    return deferred.promise;
  };

  return {
    showActions: function ($event, scope, actions) {
      if (scope.$parent.$parent.enableDataEntry === true) {
        var modalTemplate = '<div class="modal-body"><ul style="list-style: none; width: 300px">' + '<li data-ng-repeat="action in ::actions" class="item clickable text-align-center " data-ng-click="selectAction(action)" data-ng-class="action.popoverClassName">' + '{{::action.text}}' + '</li>' + '</ul></div>';
        scope.actions = actions;
        scope.selectAction = function (action) {
          $timeout(function () {
            document.activeModal.dismiss();
            action.onTap();
          }, 250);
        };

        return $modalService.open({
          animation: true,
          scope: scope,
          template: modalTemplate,
          size: 'sm'
        });
      } else {
        return showMessage('PHOTO_CAPTURE_NOT_SUPPORTED_TITLE', 'PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE');
      }
    }
  };
}]);