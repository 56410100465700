'use strict';

angular.module('customerPortal').config(['$stateProvider', 'stateConfig', '$moreResourceModalStateProvider', function ($stateProvider, stateConfig, $moreResourceModalStateProvider) {

  $stateProvider.state('portal.customers.form.editor', {
    abstract : true
  }).state('portal.customers.form.editor.edit', {
    url : '/:formId/edit/:formVersionId',
    template : '<div data-ui-view></div>',
    access : stateConfig.defaultAccess,
    params : {
      showPublicationErrors : false,
      openWithValidationErrors : false,
      isTemplate : false,
      exitState : 'portal.customers.form.overview'
    },
    resolve : {
      widgets : ['$widgetService', '$stateParams', function ($widgetService, $stateParams) {
        return $widgetService.all($stateParams.customerId).$promise;
      }],
      exitState : ['$stateParams', function ($stateParams) {
        return $stateParams.exitState;
      }],
      formService : ['$stateParams', '$customerFormService', '$customerFormTemplateService', function ($stateParams, $customerFormService, $customerFormTemplateService) {
        return $stateParams.isTemplate ? $customerFormTemplateService : $customerFormService;
      }],
      formVersion : ['$stateParams', 'formService', function ($stateParams, formService) {
        return formService.getFormVersion($stateParams.customerId, $stateParams.formId, $stateParams.formVersionId).$promise;
      }],
      form : ['$stateParams', 'formService', function ($stateParams, formService) {
        return formService.getForm($stateParams.customerId, $stateParams.formId).$promise;
      }]
    },
    views : {
      '@' : {
        template : require('../../shared/templates/builder/formeditor.index.html'),
        controller : 'FormEditorIndexCtrl'
      }
    }
  }).state('portal.customers.form.editor.edit.deleteFavorite', {
    params: { 'widgetId': null, 'widgetUid': null, 'customerId': null, 'viewId': null },
    access: stateConfig.defaultAccess,
    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
      $state.params = $stateParams;
      $uibModal.open({
        template: require('../../shared/templates/builder/formeditor.widgets.favorites.delete.html'),
        controller: 'DeleteWidgetFavoriteCtrl',
        resolve: {}
      }).result.then(function () {
        $state.go('^');
      });
    }]
  }).state('portal.customers.form.editor.edit.moreResource', $moreResourceModalStateProvider.$get());
}]);
