'use strict';
angular.module('more.ui.form').service('$fieldInitializer', function () {

  var loadDefaultValue = function (dataDefinitionDefaultValue, fieldProperties) {
    if (dataDefinitionDefaultValue.type === 'static') {
      return angular.copy(dataDefinitionDefaultValue.value);
    } else if (dataDefinitionDefaultValue.type === 'ref') {
      var value = angular.copy(fieldProperties[dataDefinitionDefaultValue.value]);
      return value === "" ? null : value;
    }
    return null;
  };

  return {
    initializeField : function (widget, field, data, rememberedValues) {
      if (field.properties.data_name && (data[field.properties.data_name] === null || data[field.properties.data_name] === undefined)) {
        var dataDefinition = widget ? widget.definition.data : null;
        var value = null;

        if (!dataDefinition) {
          return undefined;
        }
        if (dataDefinition.defaultValue) {
          value = loadDefaultValue(dataDefinition.defaultValue, field.properties);
        }

        if (field.properties.remember_input && field.widget == 'com.moreapps:checkbox:1') {
          value = rememberedValues[field.uid] || value;
        }

        if ((value == null || (Array.isArray(value) && value.length === 0)) && field.properties.remember_input) {
          value = rememberedValues[field.uid] || value;
        }
        data[field.properties.data_name] = value;
        return true;
      }
      return false;
    }
  };

});

