'use strict';

angular.module('customerPortal').run(['moreConstants', '$rootScope', '$location', '$securityService', '$state', '$countryService', 'localStorageService', '$featuresService', '$keyboardUtils', '$helpChatService', function (moreConstants, $rootScope, $location, $securityService, $state, $countryService, localStorageService, $featuresService, $keyboardUtils, $helpChatService) {
  $rootScope.userState = {};
  $rootScope.userState.collectionId = localStorageService.get('userState.collectionId');
  $rootScope.userState.viewId = localStorageService.get('userState.viewId');
  $rootScope.userState.formId = localStorageService.get('userState.formId');
  $rootScope.portal = 'customer';

  $rootScope.$state = $state;
  $rootScope.$countryService = $countryService;

  $rootScope.moreConstants = moreConstants;
  $rootScope.features = $featuresService.getFeatures();

  $helpChatService.openChat();

  $keyboardUtils.otherFunctionalityBackspaceForModals();
}]);

angular.module('customerPortal').config(['$httpProvider', function ($httpProvider) {
  $httpProvider.defaults.useXDomain = true;
  delete $httpProvider.defaults.headers.common['X-Requested-With'];

  if (!$httpProvider.interceptors) {
    $httpProvider.interceptors = [];
  }

  $httpProvider.interceptors.push('impersonationInterceptor');
}]);

angular.module('customerPortal').config(['localStorageServiceProvider', function (localStorageServiceProvider) {
  localStorageServiceProvider.setPrefix('moreCustomerPortal');
}]);

angular.module('customerPortal').config(['$moreResourceServiceProvider', function ($moreResourceServiceProvider) {
  $moreResourceServiceProvider.setPortal('customer');
}]);

angular.module('customerPortal').config(['showErrorsConfigProvider', function (showErrorsConfigProvider) {
  showErrorsConfigProvider.trigger('keypress');
}]);

angular.module('customerPortal').config(['laddaProvider', function (laddaProvider) {
  laddaProvider.setOption({ style: 'expand-right' });
}]);

angular.module('customerPortal').config(['$sceProvider', function ($sceProvider) {
  $sceProvider.enabled(false);
}]);

angular.module('customerPortal').config(['$translateProvider', function ($translateProvider) {
  $translateProvider.useSanitizeValueStrategy(null);
}]);

angular.module('customerPortal').config(['$locationProvider', function ($locationProvider) {
  $locationProvider.html5Mode(true);
}]);

angular.module('customerPortal').config(['$compileProvider', function ($compileProvider) {
  // Disable debug info in production for a significant performance boost: https://docs.angularjs.org/guide/production
  $compileProvider.debugInfoEnabled(false);
}]);

angular.element(document).ready(function () {
  angular.bootstrap(document, ['customerPortal']);
});
