'use strict';
angular.module('more.ui.form').service('$widgetStyleService', [function () {

  var cache = [];
  document.addStyle = function (str) {
    $("#more-styling").remove();
    var el = document.createElement('style');
    el.id = 'more-styling';
    el.type = "text/css";
    if (el.styleSheet) el.styleSheet.cssText = str;//IE only
    else el.appendChild(document.createTextNode(str));
    return document.getElementsByTagName('head')[0].appendChild(el);
  };

  var createBlockContent = function (blockDefinition) {
    var answer = '';
    Object.keys(blockDefinition).forEach(function (key) {
      answer += (key + ' : ' + blockDefinition[key] + ';');
    });
    return answer;
  };

  var publishStyling = function (styling) {
    var styleString = '';

    styling.forEach(function (styleDefinition) {
      var blockContent = createBlockContent(styleDefinition.style);
      var prefix = '.more-styled-page .more-styled-';
      if (styleDefinition.prefix === false) {
        prefix = '.more-styled-page ';
      } else if (styleDefinition.inPage === false) {
        prefix = '.more-styled-';
      }
      styleString += prefix + styleDefinition.element + '{' + blockContent + '}\n';
    });

    document.addStyle(styleString);
    return styleString;
  };

  function addWidgetStyling(widgetUid, style) {
    var identifier = 'plugin-style-' + widgetUid.replace(/[\.-]/gi, "_").replace(/:/gi, "-");
    if (cache.indexOf(identifier) > -1) {
      return;
    }
    cache.push(identifier);
    var el = document.createElement('style');
    el.id = identifier;
    el.type = "text/css";
    if (el.styleSheet) { //IE only
      el.styleSheet.cssText = style;
    } else {
      el.appendChild(document.createTextNode(style));
    }
    return document.getElementsByTagName('head')[0].appendChild(el);
  }

  function clearCache() {
    cache.forEach(function (identifier) {
      var el = document.getElementById(identifier);
      if (el && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    });
    cache = [];
  }

  return {
    publishStyling : publishStyling,
    addWidgetStyling : addWidgetStyling,
    clearCache : clearCache
  };

}]);
