'use strict';
angular.module('more.ui.form').directive('compile', function ($compile) {
  return {
    restrict : 'A',
    link : function (scope, element, attrs) {
      scope.$watch(
        function (scope) {
          return scope.$eval(attrs.compile);
        },
        function (value) {
          element.html(value);
          $compile(element.contents())(scope);
        }
      );
    }
  };
});
