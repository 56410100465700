'use strict';

angular.module('MoreAppsServices').factory('$customerFolderService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var CustomerFolders = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/folders/:folderId', {'customerId' : '@customerId', 'folderId' : '@folderId'}, {
    update : {
      method : 'PATCH'
    },
    patch : {
      method : 'PATCH',
      headers: {'Content-Type': 'application/json-patch+json'}
    }
  });
  var CustomerFolderForm = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/folders/:folderId/forms/:formId', {
    'customerId' : '@customerId',
    'folderId' : '@folderId',
    'formId' : '@formId'
  }, {
    moveForm : {
      method : 'PUT',
      hasBody : false
    }
  });
  var CustomerFolderFormMove = $resource(moreConstants.apiEndpoint + '/forms/customer/:customerId/folders/:folderId/forms/:formId/move/:position', {
    'customerId' : '@customerId',
    'folderId' : '@folderId',
    'formId' : '@formId',
    'position' : '@position'
  }, {
    moveForm : {
      method : 'PUT',
      hasBody : false
    }
  });

  return {
    getFolders : function (customerId, skipForms) {
      const expand = skipForms ? '' : 'forms';
      return CustomerFolders.query({customerId : customerId, expand : expand});
    },
    getFolder : function (customerId, folderId) {
      return CustomerFolders.get({customerId : customerId, folderId : folderId, expand : 'forms'});
    },
    saveFolder : function (customerId, folder) {
      return CustomerFolders.save({customerId : customerId}, folder);
    },
    deleteFolder : function (customerId, folderId) {
      return CustomerFolders.delete({customerId, folderId});
    },
    addForm : function (customerId, folderId, formId) {
      return CustomerFolderForm.save({customerId, folderId, formId});
    },
    removeForm : function (customerId, folderId, formId) {
      return CustomerFolderForm.remove({customerId, folderId, formId});
    },
    moveForm : function (customerId, folderId, formId) {
      return CustomerFolderForm.moveForm({customerId, folderId, formId});
    },
    moveOrderForm : function (customerId, folderId, formId, position) {
      return CustomerFolderFormMove.moveForm({customerId, folderId, formId, position});
    },
    patch : function(customerId, folderId, patch) {
      return CustomerFolders.patch({customerId, folderId}, patch);
    }
  };
}]);
