'use strict';

angular.module('MoreDirectives').directive('moreLanguageSelect', ['$languageUtil', function ($languageUtil) {
  return {
    restrict: 'E',
    scope: {
      iso2: '=',
      ngModel: '=',
      ngChange: '&',
      compact: '=',
      clearOption: '='
    },
    template: require('../../MoreDirectives/templates/more-language-select.html'),
    link: function (scope) {
      scope._tmp = scope;
      scope.languages = $languageUtil.getSupportedLanguages();

      scope.getLanguageName = () => {
        const found = scope.languages.find(l => l.code === scope.ngModel);
        return found ? found.name : undefined;
      };

      scope.getLanguageNameByIso = () => {
        const found = scope.languages.find(l => l.iso === scope.ngModel);
        return found ? found.name : undefined;
      };

      scope.onChange = newVal => {
        scope.ngModel = newVal;
        scope.ngChange({language: newVal});
      };
    }
  };
}]);
