'use strict';

angular.module('Billing').controller('BillingInvoicesCtrl', ['$scope', '$state', '$billingService', '$translate', function ($scope, $state, $billingService, $translate) {

  var self = this;

  self.invoices = $billingService.getInvoices($state.params.customerId);

  self.columns = [];
  self.columns.push({id : 'invoiceNumber', name : $translate.instant('INVOICE_NUMBER'), type : 'string'});
  self.columns.push({id : 'invoiceDate', name : $translate.instant('INVOICE_DATE'), type : 'string'});
  self.columns.push({id : 'paymentStatus.total', name : $translate.instant('INVOICE_TOTAL'), type : 'currency'});
  self.columns.push({id : 'pdfUrl', name : $translate.instant('INVOICE_PDF'), customTemplate : require('../templates/pdf.template.html')});
  self.columns.push({id : 'invoiceStatus', name : $translate.instant('INVOICE_STATUS'), customTemplate: require('../templates/status.template.html')});
}]);