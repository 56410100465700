'use strict';

angular.module('DataSources').controller('AddDataSourcePanelCtrl', ['$scope', '$customerDataSourceService', 'dataSources', function ($scope, $customerDataSourceService, dataSources) {

  $scope.dataSource = {};
  $scope.saving = false;
  var _files;

  $scope.selectedFiles = function (files) {
    _files = files;
  };

  $scope.hasNoFileSelected = function () {
    return !_files;
  };

  var startImportWithFile = function (dataSource) {
    $customerDataSourceService.importFile($scope.$state.params.customerId, dataSource.id, _files[0]).then(function () {
      $scope.saving = false;
      $scope.$close(dataSource.$get({ dataSourceId: dataSource.id }));
    });
  };

  $scope.saveDataSource = function (dataSource) {
    $scope.saving = true;
    if (dataSource.type !== 'URL') {
      delete dataSource.urlConfiguration;
    }
    if (dataSource.type !== 'GOOGLE_SPREADSHEET') {
      delete dataSource.googleConfiguration;
    }
    $customerDataSourceService.save($scope.$state.params.customerId, dataSource).$promise.then(function (savedDataSource) {
      dataSources.push(savedDataSource);
      if (dataSource.type === 'EXCEL' && _files) {
        startImportWithFile(savedDataSource);
      } else {
        $scope.saving = false;
        $scope.$close(savedDataSource);
      }
    });
  };
}]);