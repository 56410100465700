'use strict';

angular.module('more.ui.form-builder').service('$dateHelper', ['$modalService', function ($modalService) {

  function doubleDigits(input) {
    return input < 10 ? '0' + input : input;
  }

  function asTimeString(date) {
    return doubleDigits(date.getHours()) + ':' + doubleDigits(date.getMinutes());
  }

  function asDateString(date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getYear() + 1900;
    return [year, doubleDigits(month), doubleDigits(day)].join('-');
  }

  var showDateModal = function (dateOn, timeOn) {
    return $modalService.open({
      template: require('../templates/date-modal.html'),
      controller: ['$scope', function ($scope) {
        $scope.dateOn = dateOn;
        $scope.timeOn = timeOn;
        $scope.result = {
          date: new Date()
        };
      }]
    });
  };

  var configureElement = function (element, dateOn, timeOn, options, callback) {
    options = options || {};
    var onParent = options.onParent !== false;
    element.prop('readonly', true);
    var el = onParent ? element.parent() : element;
    el.on('click', function () {
      showDateModal(dateOn, timeOn).then(callback);
    });
  };

  var withZeroPrefix = function (monthOrDay) {
    return monthOrDay < 10 ? '0' + monthOrDay : monthOrDay;
  };

  return {
    setupDateTime: function (element, options, callback) {
      configureElement(element, true, true, options, function (result) {
        var dateText = asDateString(result.date);
        var timeText = asTimeString(result.date);
        var fullText = dateText + ' ' + timeText;
        element.val(fullText);
        if (callback) {
          callback({
            text: fullText,
            date: result.date
          });
        }
      });
    },
    setupDate: function (element, options, callback) {
      configureElement(element, true, false, options, function (result) {
        var dateText = asDateString(result.date);
        element.val(dateText);
        if (callback) {
          callback({
            text: dateText,
            date: result.date
          });
        }
      });
    },
    setupTime: function (element, options, callback) {
      configureElement(element, false, true, options, function (result) {
        var timeText = asTimeString(result.date);
        element.val(timeText);
        if (callback) {
          callback({
            text: timeText,
            date: result.date
          });
        }
      });
    },
    dateAsString: function (date) {
      return date.getFullYear() + '-' + withZeroPrefix(date.getMonth() + 1) + '-' + withZeroPrefix(date.getDate());
    },
    dateTimeAsString: function (date) {
      return this.dateAsString(date) + ' ' + withZeroPrefix(date.getHours()) + ':' + withZeroPrefix(date.getMinutes()) + ':' + withZeroPrefix(date.getSeconds());
    }
  };
}]);