'use strict';

angular.module('AdminServices').factory('$adminQueueService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  var Queue = $resource(moreConstants.apiEndpoint + '/admin/queue/:action/:page', {}, {
    stats: { method: 'GET', isArray: false },
    retry: { method: 'POST', isArray: true, params: { action: 'retry' } },
    filter: {
      method: 'POST',
      params: {
        page: '@currentPage',
        action: 'filter'
      }
    }
  });

  var QueueFilter = $resource(moreConstants.apiEndpoint + '/admin/queue/filter/:page', {}, {
    filter: {
      method: 'POST',
      isArray: true
    }
  });

  return {
    queueStats: function () {
      return Queue.stats();
    },
    filterQueue: function (currentPage, simpleFilter) {
      return QueueFilter.filter({ page: currentPage }, simpleFilter);
    },
    requestRetry: function (id) {
      Queue.retry(id);
    }
  };
}]);