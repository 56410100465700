'use strict';

angular.module('MoreDirectives').directive('customerUiSelect', ['$adminCustomerService', function ($adminCustomerService) {
  return {
    restrict: 'E',
    template: '<ui-select class="link-customer-select" ng-model="_tmp.ngModel" required>' + '               <ui-select-match placeholder="Insert customer ID">' + '                   {{ $select.selected.name }} ({{ $select.selected.customerId }})' + '               </ui-select-match>' + '               <ui-select-choices refresh="findCustomer($select.search)"' + '                                  repeat="customer.customerId as customer in foundCustomer | orderBy:\'name\' | filter: $select.search">' + '                   <span ng-bind-html="customer.name + \' (\' + customer.customerId + \')\' | highlight: $select.search"></span>' + '               </ui-select-choices>' + '           </ui-select>' + '           <div class="alert alert-warning" data-ng-if="findCustomerError" ng-bind="findCustomerError"></div>',
    require: 'ngModel',
    scope: {
      ngModel: '=',
    },
    link: function (scope) {
      scope._tmp = scope;
      scope.foundCustomer = [];

      scope.findCustomer = function (customerId) {
        if (!customerId) {
          scope.foundCustomer = [];
          scope.findCustomerError = null;
          return;
        }
        $adminCustomerService.getCustomer(customerId).$promise.then(function (customer) {
          scope.findCustomerError = null;
          scope.foundCustomer = [customer];
        }, function (err) {
          scope.findCustomerError = err.data.message;
          scope.foundCustomer = [];
        });
      };
    }
  };
}]);