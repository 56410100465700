'use strict';

angular.module('more.ui.form-builder').factory('$photoHelper', ['$state', '$modalService', '$q', '$uuidHelper', '$timeout', '$rootScope', '$http', 'moreConstants', function ($state, $modalService, $q, $uuidHelper, $timeout, $rootScope, $http, moreConstants) {

  function showMessage(title, message) {
    $modalService.alertModal({title, message});
    return $q.reject();
  }

  function convertToBase64(file, callback) {
    const FR = new FileReader();
    FR.onload = (e) => callback(e.target.result);
    FR.readAsDataURL(file);
  }

  function uploadFile(file, deferred) {
    convertToBase64(file, base64 => {
      const resultObject = {value: null};
      const fileObject = {
        value: {
          specification: 'base64',
          value: base64
        },
        key: 'value',
        parentObject: resultObject
      };
      const params = {
        customerNumber: $state.params.customerId
      };
      uploadFileObject(fileObject, params)
        .then(() => deferred.resolve(resultObject.value), deferred.reject);
    });
  }

  function uploadFileObject(fileObject, uploadParams) {
    if (fileObject.value.specification !== 'base64') {
      return $q.reject();
    }

    const deferred = $q.defer();
    const filename = '';
    const data = angular.copy(uploadParams);
    data.base64FileContent = fileObject.value.value;
    data.filename = filename;
    $http({
      method: 'POST',
      url: moreConstants.apiEndpoint + '/client/registrations/files/base64',
      data: data,
      responseType: 'text',
      transformResponse: data => data
    }).success(responseObject => {
      fileObject.parentObject[fileObject.key] = responseObject;
      deferred.resolve();
    }).error(deferred.reject);

    return deferred.promise;
  }

  return {
    takePhoto: () => showMessage('PHOTO_CAPTURE_NOT_SUPPORTED_TITLE', 'PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE'),
    selectPhoto(opts) {
      const deferred = $q.defer();
      const guid = $uuidHelper.generateGuid();
      const input = document.createElement('input');
      input.type = 'file';
      input.id = `hidden-file-input-${guid}`;
      input.accept = 'image/*';
      input.style.cssText = 'position:fixed;top:-1000px;';
      document.body.appendChild(input);

      $timeout(() => $(input).click());

      $(document).on('change', `#hidden-file-input-${guid}`, function () {
        if (typeof opts.onPhotoSelected === 'function') {
          opts.onPhotoSelected();
        }
        uploadFile(this.files[0], deferred);
      });

      return deferred.promise;
    },
    getSupport() {
      return {
        photo: false,
        library: true
      };
    }
  };
}]);
