'use strict';

angular.module('FormEditor').controller('IntegrationTabCtrl', ['$scope', '$state', '$translate', '$hookService', '$uibModal', '$q', '$modalService', '$uuidService', '$formVersionUtilityService', '$widgetService', '$window', '$featureHelper', 'moreConstants', function ($scope, $state, $translate, $hookService, $uibModal, $q, $modalService, $uuidService, $formVersionUtilityService, $widgetService, $window, $featureHelper, moreConstants) {

  var self = this;
  var customerId = $state.params.customerId;

  self.integrations = {};
  self.globalError = null;
  self.allIntegrations = $scope.allIntegrations;
  self.integrationConfigurations = $scope.integrationConfigurations;

  self.addIntegration = addIntegration;
  self.getIntegrationInfo = getIntegrationInfo;
  self.removeIntegrationConfiguration = removeIntegrationConfiguration;

  init();

  ////////
  function init() {
    $featureHelper.hasFeature(moreConstants.HOOKS).then(function (hasFeature) {
      self.hooksFeatureEnabled = hasFeature;
    });

    self.integrationConfigurations = $scope.targetView.integrations;
    self.integrationConfigurations.forEach(integrationConfiguration => {
      _addIntegrationDefinitionToCache(integrationConfiguration.namespace, integrationConfiguration.key);
    });

    self.viewFields = $formVersionUtilityService.expandFields($scope.targetView);
    $widgetService.enrichFieldsWithWidgetInfo(self.viewFields);
  }

  function addIntegration() {
    if (!self.hooksFeatureEnabled) {
      return $modalService.upgradePlanModal({
        title: 'FEATURE_HOOKS',
        message: 'FEATURE_HOOKS_MESSAGE',
        imgSrc: 'https://cdn.moreapp.com/features/en/feature-marketplace-hooks.png'
      });
    }

    $uibModal.open({
      template: require('../templates/integration.select.modal.html'),
      controller: 'IntegrationSelectModalCtrl as ctrl',
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      resolve: {
        customerId: () => customerId,
        installedIntegrations: () => $hookService.getAllActiveHooks(customerId).$promise
      }
    }).result.then(selectedIntegration => {
      if (selectedIntegration) {
        _addIntegrationConfig(selectedIntegration);
      }
    });
  }

  function getIntegrationInfo(namespace, key) {
    return $scope.allIntegrations.find(integration => integration.key === key && integration.namespace === namespace);
  }

  function removeIntegrationConfiguration(integrationConfiguration, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    $modalService.deleteModal({
      title: 'VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE',
      template: require('../templates/integration.remove.modal.html'),
      data: {}
    }).then(() => {
      self.integrationConfigurations.splice(self.integrationConfigurations.indexOf(integrationConfiguration), 1);
    });
  }

  ////////
  function _addIntegrationConfig(integration) {
    const integrationConfig = {
      namespace: integration.namespace,
      key: integration.key,
      version: integration.activeArtifact,
      configuration: {},
      uid: $uuidService.uid(),
      name: angular.copy(integration.name),
      $$isOpen: true
    };
    _addIntegrationDefinitionToCache(integration.namespace, integration.key);
    self.integrationConfigurations.push(integrationConfig);
  }

  function _addIntegrationDefinitionToCache(namespace, key) {
    self.integrations[namespace + ':' + key] = $hookService.getHook(customerId, namespace, key);
  }
}]);
