'use strict';

angular.module('FormTemplates').directive('templatePicker', ['$state', '$customerFormTemplateService', '$userStateService', '$rootScope', function ($state, $customerFormTemplateService, $userStateService, $rootScope) {

  return {
    restrict: 'E',
    template: require('../templates/template-picker.html'),
    scope: {
      selectTemplateCallback: '&',
      addTemplateCallback: '&'
    },
    link: function (scope) {
      scope.activeTemplate = null;
      scope.templates = [];
      scope.templateFilter = '';
      scope.languageFilter = '';

      const userState = $userStateService.getState();

      const loadTemplates = (customerId, templateFilter, languageFilter) => {
        customerId = customerId || $state.params.customerId;
        templateFilter = templateFilter || '';
        languageFilter = languageFilter || '';
        $customerFormTemplateService.find(customerId, templateFilter, languageFilter).$promise.then(templates => {
          scope.templates = templates;
          const selectedTemplate = templates.find((template) => template.id === userState.templateId);
          if (selectedTemplate) {
            scope.selectTemplate(selectedTemplate);
          }
        });
      };

      loadTemplates();

      scope.$watch('templateFilter', function (templateFilter) {
        loadTemplates($state.params.customerId, templateFilter, scope.languageFilter);
      });

      scope.$watch('languageFilter', function (languageFilter) {
        loadTemplates($state.params.customerId, scope.templateFilter, languageFilter);
      });

      scope.selectTemplate = template => {
        scope.activeTemplate = template;
        scope.selectTemplateCallback({ template: template });
        $userStateService.setTemplateId(template.id);
      };

      scope.$on('template.added', (event, newTemplate) => {
        scope.templates.push(newTemplate);
        scope.selectTemplate(newTemplate);
      });

      scope.$on('template.removed', (event, deletedTemplate) => {
        const foundTemplate = scope.templates.find((template) => template.id === deletedTemplate.id);
        foundTemplate.status = 'TRASH';
        scope.activeTemplate = null;
        if ($userStateService.getState().templateId === deletedTemplate.id) {
          $userStateService.setTemplateId(null);
        }
      });

      scope.$on('template.updated', function (event, updatedTemplate) {
        scope.templates = scope.templates.map(template => template.id === updatedTemplate.id ? updatedTemplate : template);
      });

      scope.$watch('activeTemplate', (newVal) => {
        $rootScope.pickerItemActive = newVal !== null;
      });

      scope.$on('$destroy', () => {
        $rootScope.pickerItemActive = false;
      });
    }
  };
}]);
