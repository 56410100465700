'use strict';

angular.module('customerPortal').config(['$stateProvider', function ($stateProvider) {

  $stateProvider.state('signup', {
    url: '/signup?segment',
    template: require('../templates/signup.html'),
    controller: 'SignUpCtrl as ctrl',
    data: {
      entryView: true
    }
  }).state('signup-requested', {
    url: '/signup-requested',
    template: require('../templates/signup-requested.html'),
    data: {
      entryView: true
    }
  }).state('onboarding-validation', {
    url: '/onboarding/:token',
    template: require('../templates/onboarding.html'),
    data: {
      entryView: true
    }
  }).state('onboarding-validated', {
    url: '/onboarding-success',
    template: require('../templates/onboarding-success.html'),
    data: {
      entryView: true
    }
  }).state('email-validation', {
    url: '/email-validation/:token',
    template: require('../templates/email-validation.html'),
    data: {
      entryView: true
    }
  }).state('email-validated', {
    url: '/email-validated',
    template: require('../templates/email-validated.html'),
    data: {
      entryView: true
    }
  }).state('invites', {
    url: '/invites/:token',
    template: require('../../modules/Activation/templates/invites.html'),
    controller: 'InviteCtrl',
    controllerAs: 'ctrl',
    data: {
      entryView: true
    }
  }).state('activation_success', {
    url: '/activate-success',
    template: require('../../modules/Activation/templates/activation_success.html'),
    controller: 'ActivateSuccessCtrl',
    controllerAs: 'ctrl',
    access: [],
    data: {
      entryView: true
    }
  });
}]);
