'use strict';

angular.module('Help').factory('$helpChatService', ['$featuresService', '$userService', '$hubspotVisitorService', function ($featuresService, $userService, $hubspotVisitorService) {

    return {
        openChat: openChat
    };

    ////////
    function openChat() {
        $featuresService.getFeatures().$promise.then(function (features) {

            const conversationSettings = {
                loadImmediately: false,
                enableWidgetCookieBanner: true,
                disableAttachment: false,
            };

            window.hsConversationsSettings = conversationSettings;

            if (!features.showHelp) {
                return;
            }

            $hubspotVisitorService.getToken().$promise
                .then((response) => response.token)
                .then((token) => {
                    $userService.getUser().$promise.then(user => {
                        conversationSettings.identificationEmail = user.emailAddress;
                        conversationSettings.identificationToken = token;
                    });
                })
                .finally(() => {
                    window.hsConversationsOnReady = [() => {
                        window.HubSpotConversations.widget.load();
                    },];
                });
        });
    }
}]);
