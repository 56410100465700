'use strict';

angular.module('customerPortal').controller('UserPickerCtrl', ['$scope', '$sidePanel', '$rootScope', 'moreConstants', '$customerGroupService', function ($scope, $sidePanel, $rootScope, moreConstants, $customerGroupService) {

  $scope.selectGroupCallback = selectGroupCallback;
  $scope.selectUserCallback = selectUserCallback;
  $scope.focusGroupCallback = focusGroupCallback;
  $scope.addGroupCallback = addGroupCallback;
  $scope.addUserCallback = addUserCallback;

  function selectGroupCallback (group) {
    $rootScope.$broadcast('group.changed', group.id);
  }

  function selectUserCallback (user, group) {
    $rootScope.$broadcast('user.changed', { groupId: group.id, user: user });
  }

  function focusGroupCallback (group, users) {
    $rootScope.$broadcast('group.focused', { group: group, users: users });
  }

  function addGroupCallback () {
    $sidePanel.open({
      template: require('../../../../modules/UserManagement/templates/usermanagement.group.new.html'),
      controller: 'AddGroupPanelCtrl'
    }).then(function (newGroup) {
      $rootScope.$broadcast('group.added', newGroup);
    });
  }

  function addUserCallback () {
    $sidePanel.open({
      template: require('../../../../modules/UserManagement/templates/usermanagement.user.new.html'),
      resolve: {
        groups: () => $customerGroupService.getGroups($scope.$state.params.customerId).$promise,
        selectedGroupIds: () => []
      },
      controller: 'AddUserPanelCtrl as ctrl'
    }).then(function () {
      $rootScope.$broadcast('user.added');
    });
  }
}]);
