'use strict';

angular.module('WidgetPropertyPane').directive('widgetProperty', ['$formVersionUtilityService', '$modalService', function ($formVersionUtilityService, $modalService) {

  var propertyTemplates = {
    text: require('../templates/properties/text.html'),
    textarea: require('../templates/properties/textarea.html'),
    number: require('../templates/properties/number.html'),
    boolean: require('../templates/properties/checkbox.html'),
    options: require('../templates/properties/options.html'),
    'lookup_value': require('../templates/properties/lookup_value.html'),
    'view_lookup': require('../templates/properties/view_lookup.html'),
    'form_placeholders': require('../templates/properties/form_placeholders.html'),
    html: require('../templates/properties/html.html'),
    'customer_resource': require('../templates/properties/customer_resource.html'),
    list: require('../templates/properties/list.html'),
    'url_or_resource': require('../templates/properties/url_or_resource.html'),
    'data_source': require('../templates/properties/data_source_configuration.html'),
    'options_subset': require('../templates/properties/options_subset.html'),
    field: require('../templates/properties/field.html')
  };

  var propertyErrorTemplates = {
    warning: require('../templates/errors/info.html')
  };

  return {
    restrict: 'A',
    template: '<div compile="getPropertyTemplate(widgetProperty)"></div>',

    scope: {
      form: '=',
      formVersion: '=',
      formVersionRoot: '=',
      formFieldProperties: '=',
      widgetProperty: '=',
      applicationDefinition: '=',
      showIcon: '=',
      isDeployed: '=',
      advancedMode: '=',
      targetViewId: '=',
      viewFields: '='
    },
    link: function (scope) {
      scope.currentViewLookupTargetViewId = null;

      init();

      /////////////////
      function init() {
        scope.$watch('currentViewLookupTargetViewId', function (newValue, oldValue) {
          if (oldValue !== null && oldValue !== undefined) {
            scope.$emit('WidgetProperty.view-lookup-changed', oldValue);
          }
        });

        scope.getSubformUsage = function () {
          let usages = {};
          const result = JSONPath({path : '$..target_form_id', json : scope.formVersion.fields});
          result.forEach(linkedSubform => {
            if (usages[linkedSubform]) {
              usages[linkedSubform].count++;
            } else {
              usages[linkedSubform] = {count : 1};
            }
          });
          return usages;
        };

        if (scope.formVersion) {
          scope.usages = scope.getSubformUsage();
        }

        var configuration = scope.widgetProperty.configuration;
        if (configuration) {
          scope.validationOptions = {
            minLength: configuration.min,
            maxLength: configuration.max,
            min: configuration.min,
            max: configuration.max
          };
        }
      }

      scope.getPropertyTemplate = function (widgetProperty) {
        return propertyTemplates[widgetProperty.type];
      };

      scope.getPropertyErrorTemplate = function (key) {
        return propertyErrorTemplates[key];
      };

      scope.addSubForm = function () {
        scope.$emit('ADD_SUB_FORM_REQUEST', { formFieldProperties: scope.formFieldProperties, property: 'target_form_id' });
      };

      scope.openDetail = function (viewId) {
        scope.$emit('NAVIGATE_FORM_REQUEST', viewId);
      };

      scope.openSubform = function () {
        scope.$emit('NAVIGATE_SUBFORM_REQUEST', { field: scope.formFieldProperties, property: 'form' });
      };

      scope.renameLinkedSubform = function (targetFormId) {
        const linkedSubform = scope.formVersionRoot.fieldProperties[targetFormId];
        $modalService.open({
          template: require('../templates/modals/modal.linkedsubform.rename.html'),
          controller: ['$scope', ($scope) => {
            $scope.name = linkedSubform.meta.name;
          }]
        }).then((newName)=>{
          scope.formVersionRoot.fieldProperties[targetFormId].meta.name = newName;
        });
      };

      scope.deleteSubform = function (property) {
          // Ask confirmation to remove unlinked subform
          $modalService.confirmModal({
            title : 'WIDGET_DETAIL_REMOVE_TITLE',
            message : 'WIDGET_DETAIL_REMOVE_MESSAGE',
            confirmButtonTitle : 'DELETE',
            showExclamation : true,
            confirmButtonStyle : 'danger'
          }).then(() => {
            delete property.form;
          });
      };

      scope.deleteLinkedSubform = function (targetFormId) {
        // Ask confirmation to remove unlinked subform
        $modalService.confirmModal({
          title : 'WIDGET_DETAIL_REMOVE_TITLE',
          message : 'WIDGET_DETAIL_REMOVE_MESSAGE',
          confirmButtonTitle : 'DELETE',
          showExclamation : true,
          confirmButtonStyle : 'danger'
        }).then(() => {
          // Clear all subform references
          const linkedFormWidgets = JSONPath({path: `$..[?(@.target_form_id === '${targetFormId}')]`, json: scope.formVersion.fields});
          linkedFormWidgets.forEach(linkedFormWidget => {
            delete linkedFormWidget.form;
            delete linkedFormWidget.target_form_id;
          });
          delete scope.formVersionRoot.fieldProperties[targetFormId];
        });
      };

      scope.toggleSubform = function (properties, targetKey) {
        const targetFormId = properties[targetKey];
        if (targetFormId) {
          // DO UNLINK - This widget has a linked subform
          properties.form = $formVersionUtilityService.copySubform(properties.form);
          delete properties[targetKey];
          // Remove reference from 'formVersionRoot' if this was the only usage
          scope.usages = scope.getSubformUsage();
          if (!scope.usages[targetFormId] || scope.usages[targetFormId].count === 0) {
            delete scope.formVersionRoot.fieldProperties[targetFormId];
          }
        } else {
          // DO LINK - This widget has an unlinked subform
          properties.form.meta = {name: scope.formFieldProperties.label_text};
          scope.formVersionRoot.fieldProperties[properties.form.uid] = properties.form;
          properties[targetKey] = properties.form.uid;
        }
        scope.usages = scope.getSubformUsage();
      };

      scope.viewLookupChanged = function (targetViewId) {
        if (targetViewId !== null) {
          scope.currentViewLookupTargetViewId = targetViewId;
        }
      };

      scope.shouldHideLinkedSubforms = function () {
        return (scope.formFieldProperties.form && !scope.formFieldProperties[scope.widgetProperty.key]) || Object.keys(scope.formVersionRoot.fieldProperties).length === 0;
      };

      scope.targetFormChanged = function (targetFormId) {
        if (targetFormId !== null) {
          scope.currentLookupFormId = targetFormId;
          scope.formFieldProperties.form = scope.formVersionRoot.fieldProperties[targetFormId];
          scope.usages = scope.getSubformUsage();
        }
      };

      scope.listItemHaveWarningMessage = function (propertyOfOption, itemToMatchProperty) {
        var hasWarningMessage = false;

        scope.widgetProperty.configuration.options.forEach(function (option, index) {
          if (option[propertyOfOption] === itemToMatchProperty && option.message) {
            scope.indexListItem = index;
            hasWarningMessage = true;
          }
        });

        return hasWarningMessage;
      };
    }
  };
}]);
