'use strict';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

angular.module('FormManagement.tasks').controller('FormManagementTaskAddCtrl', ['$scope', '$rootScope', '$q', '$customerUserService', '$customerGroupService', '$customerFolderService', '$formTaskService', '$customerGroupsService', '$timeout', '$translate', function ($scope, $rootScope, $q, $customerUserService, $customerGroupService, $customerFolderService, $formTaskService, $customerGroupsService, $timeout, $translate) {
  const self = this;
  self.saving = false;

  self.TYPE_GROUP = 'group';
  self.TYPE_USER = 'user';

  self.publishOptions = [{
    display: 'TASKS_ADD_PUBLISH_IMMEDIATE',
    type: 'IMMEDIATE',
    value: 0
  }, {
    display: 'TASKS_ADD_PUBLISH_IN_ONE_HOUR',
    type: 'RELATIVE',
    value: 3600
  }, {
    display: 'TASKS_ADD_PUBLISH_IN_TWO_HOURS',
    type: 'RELATIVE',
    value: 7200
  }, {
    display: 'TASKS_ADD_PUBLISH_TOMORROW',
    type: 'RELATIVE',
    value: 86400
  }];

  $scope.task = {
    message: '',
    recipients: [],
    publishInfo: {},
    data: {}
  };

  self.cancel = () => {
    returnToIndex();
  };

  self.updatePublishDate = () => {
    $scope.task.publishInfo.type = self.publishDate.type;
    $scope.task.publishInfo.value = self.publishDate.value;
  };

  self.saveNewTask = (task) => {
    self.saving = true;

    const userIds = task.recipients.filter(userOrGroup => userOrGroup.type === self.TYPE_USER)
        .map(user => user.id);
    const userIdsFromGroups = task.recipients.filter(userOrGroup => userOrGroup.type === self.TYPE_GROUP)
        .flatMap(group => group.users)
        .map(user => user.id);
    task.recipients = userIds.concat(userIdsFromGroups).filter(onlyUnique);

    $formTaskService.saveTask($scope.$state.params.customerId, $scope.form.id, task).$promise.then(() => {
      self.saving = false;
      returnToIndex();
    });
  };

  self.groupByType = (item) => {
    return $translate.instant(item.type.toUpperCase());
  };

  self.orderByType = (groups) => {
    return [
      groups.find((x) => x.name === $translate.instant(self.TYPE_USER.toUpperCase())),
      groups.find((x) => x.name === $translate.instant(self.TYPE_GROUP.toUpperCase()))
    ];
  };

  $scope.renderUsersInGroup = (users) => {
    return users.length === 0 ?
        `<div>${$translate.instant('INSTRUCTION_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP')}</div>`
        :`<div>
            <p>${$translate.instant('INSTRUCTION_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP')}</p>
            <ul>
                ${users.map((user) => `<li>${user.username}</li>`).join('')}
            </ul>
         </div>`;
  };

  init();

  ////////
  function init() {
    // IMPORTANT: $destroy isn't automatically called on navigation, due to our compile-directive.
    // We need to destroy it manually to make sure all $scope.$on() methods are detached.
    $scope.$on('$stateChangeStart', () => {
      $timeout(() => $scope.$destroy(), 0);
    });

    _loadAvailableUsersAndGroups();

    $scope.$watch('formVersion', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        returnToIndex();
      }
    });

    // set default publish date
    self.publishDate = self.publishOptions[0];
    self.updatePublishDate();
  }

  function _loadAvailableUsersAndGroups() {
    const usersPromise = $customerUserService.getUsers($scope.$state.params.customerId);
    const groupsPromise = $customerGroupsService.getGroups($scope.$state.params.customerId);

    $q.all([usersPromise.$promise, groupsPromise]).then((result) => {
      const [users, groups] = result;
      
      const groupsWithAccess = groups.filter(group => group.grants.find(grant => grant.resourceId === $scope.formVersion.formId));
      const selectableUsers = users.filter(
          user => user.groups.find(group => groupsWithAccess.find(x => x.id === group)) ||
          user.grants.find(grant => grant.resourceId === $scope.formVersion.formId)
      ).map(user => ({
          id: user.id,
          name: user.username,
          type: self.TYPE_USER,
          disabled: Boolean(user.disabled),
        })
      );

      $q.all(groupsWithAccess.map(group => {
            return $customerGroupsService.getUsers($scope.$state.params.customerId, group.id).then((users) => {
              group.users = users;
              return group;
            });
          })
        ).then((groupsWithUsers) => {
          const selectableGroups = groupsWithUsers.map(group => ({
            id: group.id,
            name: group.name,
            users: group.users,
            type: self.TYPE_GROUP
          }));

          self.selectableUsersAndGroups = selectableUsers.concat(selectableGroups);
        });
    });
  }

  function returnToIndex() {
    $scope.$state.go('^', angular.copy($scope.$state.params));
  }
}]);
