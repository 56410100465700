'use strict';

angular.module('MoreDirectives').directive('dynamicRecipientsPicker', function () {
  return {
    restrict: 'E',
    template: require('../../MoreDirectives/templates/dynamic-recipients-picker.html'),
    scope: {
      trigger: '=',
      availableEmailFields: '='
    },
    replace: true,
    link: function (scope) {
      scope.addDynamicRecipient = function (recipient) {
        if (!scope.isAdded(recipient)) {
          scope.trigger.dynamicRecipients.push(recipient);
        }
      };

      scope.isAdded = function (recipient) {
        return scope.trigger.dynamicRecipients.indexOf(recipient) !== -1;
      };
    }
  };
});