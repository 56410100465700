'use strict';

angular.module('MoreResource').provider('$moreResourceService', function () {
  var portal;
  this.setPortal = function (value) {
    portal = value;
  };

  this.$get = ['$resource', 'moreConstants', '$upload', '$q', function ($resource, moreConstants, $upload, $q) {

    var url = '/customers/:id/resources/:action';
    var downloadUrl = '/downloads/customers/:id/resources';
    var secureUriUrl = '/customers/:id/resources/:resourceId/uri';

    var Resources = $resource(moreConstants.apiEndpoint + url, {}, {
      uploadUrl: {
        method: 'POST',
        params: {
          action: 'uploadurl'
        }
      },
      find: {method: 'GET', params: {action: 'page'}}
    });

    var CustomerResource = $resource(moreConstants.apiEndpoint + secureUriUrl, {}, {
      secureUri: {
        method: 'GET'
      }
    });

    return {
      all: function (id) {
        var params = {};
        if (id) {
          params.id = id;
        }
        return Resources.query(params);
      },
      find: (id, page, size) => Resources.find({id, page, size}),
      saveUrlResource: function (id, urlUploadRequest) {
        var params = {};
        if (id) {
          params.id = id;
        }
        return Resources.uploadUrl(params, urlUploadRequest);
      },
      saveFileResource: function (id, file, options) {
        var deferred = $q.defer();
        var shared = options.shared || false;
        var importFormat = options.importFormat || 'PNG';

        var replacedUrl = url.replace(':action', 'upload');
        if (id) {
          replacedUrl = replacedUrl.replace(':id', id);
        }

        $upload.upload({
          url: moreConstants.apiEndpoint + replacedUrl,
          method: 'POST',
          fields: { name: options.name, shared: shared, importFormat: importFormat },
          file: file,
          fileFormDataName: 'file'
        }).success(function (data) {
          deferred.resolve(data);
        }).error(function (data) {
          deferred.reject(data);
        });

        return deferred.promise;
      },
      deleteResource: function (customerId, resourceId) {
        var params = { id: customerId, action: resourceId };

        return Resources.delete(params);
      },

      thumbnailAsUrl: function (id, resourceId) {
        var deferred = $q.defer();
        var params = {};
        if (id !== undefined) {
          params.id = id;
        }
        if (resourceId !== undefined) {
          params.resourceId = resourceId;
        }
        CustomerResource.secureUri(params).$promise.then(function (data) {
          var replacedUrl = downloadUrl;
          if (id) {
            replacedUrl = replacedUrl.replace(':id', id);
          }
          var url = moreConstants.apiEndpoint + replacedUrl + '/thumbnail/100?' + data.secureUri;
          deferred.resolve(url);
        }, function () {
          deferred.reject();
        });
        return deferred.promise;
      },

      asUrl: function (id, resourceId) {
        var deferred = $q.defer();
        var params = {};
        if (id !== undefined) {
          params.id = id;
        }
        if (resourceId !== undefined) {
          params.resourceId = resourceId;
        }
        CustomerResource.secureUri(params).$promise.then(function (data) {
          var replacedUrl = downloadUrl;
          if (id) {
            replacedUrl = replacedUrl.replace(':id', id);
          }
          var url = moreConstants.apiEndpoint + replacedUrl + '?' + data.secureUri;
          deferred.resolve(url);
        }, function () {
          deferred.reject();
        });
        return deferred.promise;
      }
    };
  }];
});
