'use strict';

angular.module('Modals').controller('ModalCtrl', ['$scope', 'data', function ($scope, data) {
  Object.keys(data).forEach(function (key) {
    $scope[key] = data[key];
  });
}]);

angular.module('Modals').factory('$modalService', ['$uibModal', '$timeout', function ($uibModal, $timeout) {
  var copyObjectProperties = function (src, target) {
    src = src || {};
    Object.keys(src).forEach(function (key) {
      target[key] = src[key];
    });
  };

  return {
    upgradePlanModal: upgradePlanModal,
    warningModal: function (options) {
      document.activeModal = $uibModal.open({
        controller: ['$scope', function ($scope) {
          $scope.modalFooter = {
            style: 'warning',
            text: options.submitText
          };
          $scope.modalTemplate = options.template;
          $scope.modalTitle = options.title;
          $scope.modalMessage = options.message;
          copyObjectProperties(options.data, $scope);

          $scope.deleteEntity = function () {
            $scope.$close(true);
          };
        }],
        template: require('../../../shared/templates/modals/modal.generic.html')
      });

      return document.activeModal.result;
    },
    confirmModal: function (options) {
      document.activeModal = $uibModal.open({
        controller: ['$scope', function ($scope) {
          $scope.modalTemplate = options.template;
          $scope.modalTitle = options.title;
          $scope.modalMessage = options.message;
          $scope.confirmButtonTitle = options.confirmButtonTitle || 'CONFIRM';
          $scope.confirmEnabled = options.confirmEnabled;
          $scope.confirmButtonStyle = options.confirmButtonStyle || 'primary';
          $scope.showExclamation = options.showExclamation;

          if (options.onClose) {
            $scope.$on('modal.closing', options.onClose);
          }

          copyObjectProperties(options.data, $scope);
        }],
        template: require('../../../shared/templates/modals/modal.confirm.html')
      });
      return document.activeModal.result;
    },
    deleteModal: function (options) {
      document.activeModal = $uibModal.open({
        controller: ['$scope', function ($scope) {
          $scope.modalTemplate = options.template;
          $scope.modalTitle = options.title;
          $scope.modalMessage = options.message;

          copyObjectProperties(options.data, $scope);

          $scope.deleteEntity = function () {
            $scope.$close(true);
          };
        }],
        template: require('../../../shared/templates/modals/modal.remove.html')
      });
      return document.activeModal.result;
    },
    alertModal: alertModal,
    open: function (options) {
      document.activeModal = $uibModal.open(options);
      return document.activeModal.result;
    },
    closeActiveModal: function (e) {
      e = e || new Event('click');
      $timeout(function () {
        var isOpen = document.getElementsByClassName('modal-open').length > 0;
        if (isOpen) {
          e.stopPropagation();
          e.preventDefault();
          document.activeModal.dismiss();
        }
      }, 250);
    },
    errorModal: function (message) {
      message = message || 'ERROR_SOMETHING_WENT_WRONG_MESSAGE';
      alertModal({
        title: 'ERROR_SOMETHING_WENT_WRONG',
        message: message,
        data: {}
      });
    }
  };

  ////////
  function alertModal(options) {
    document.activeModal = $uibModal.open({
      controller: ['$scope', function ($scope) {
        $scope.modalTemplate = options.template;
        $scope.modalTitle = options.title;
        $scope.modalMessage = options.message;

        if (options.data) {
          copyObjectProperties(options.data, $scope);
        }
      }],
      template: require('../../../shared/templates/modals/modal.alert.html')
    });

    return document.activeModal.result;
  }

  function upgradePlanModal(options) {
    document.activeModal = $uibModal.open({
      template: require('../../../shared/templates/modals/modal.upgradeplan.html'),
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-fullscreen',
      controller: ['$scope', '$state', '$usersContextService', '$customerUserService', '$window', '$translate', ($scope, $state, $usersContextService, $customerUserService, $window, $translate) => {
        const owner = $customerUserService.getAccountOwner($state.params.customerId);
        $scope.canManageBilling = $usersContextService.canManageBilling();
        $scope.title = options.title || 'UPGRADE_PLAN';
        $scope.message = options.message;
        $scope.imgSrc = options.imgSrc;
        $scope.openBilling = () => {
          if (options.callback) {
            options.callback();
          } else {
            $state.go('portal.customers.billing.overview');
          }
          $scope.$close();
        };
        $scope.mailAccountOwner = () => {
          const feature = $translate.instant($scope.title);
          const subject = $translate.instant('SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT', { feature });
          const body = $translate.instant('SWITCH_PLAN_CONTACT_OWNER_MAIL_BODY');
          $window.open(`mailto:${owner.emailAddress}?subject=${subject}&body=${body}`, '_self');
        };
      }]
    });
    return document.activeModal.result;
  }
}]);
