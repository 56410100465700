'use strict';

angular.module('MoreDirectives').directive('moreResource', function () {
  return {
    restrict: 'E',
    scope: {
      resourceId: '=',
      showThumbnail: '='
    },
    replace: true,
    transclude: true,
    template: require('../../../shared/templates/directives/more-resource.html'),
    link: function (scope, element) {
      element.bind('error', function () {
        element.addClass('broken-img');
      });
    },
    controller: ['$scope', '$moreResourceService', '$state', function ($scope, $moreResourceService, $state) {
      var cache = {};
      var thumbnailCache = {};
      $scope.customerId = $state.params.customerId;

      function isUrl(value) {
        return value.indexOf('http://') === 0 || value.indexOf('https://') === 0;
      }

      var getResourceAsThumbnailUrl = function (resourceId) {
        if (thumbnailCache[resourceId] !== undefined) {
          return thumbnailCache[resourceId];
        }
        thumbnailCache[resourceId] = null;
        if (isUrl(resourceId)) {
          thumbnailCache[resourceId] = resourceId;
        } else {
          return $moreResourceService.thumbnailAsUrl($scope.customerId, resourceId).then(function (data) {
            thumbnailCache[resourceId] = data;
          });
        }
      };

      var getResourceUrl = function (resourceId) {
        if (cache[resourceId] !== undefined) {
          return cache[resourceId];
        }
        cache[resourceId] = null;
        if (isUrl(resourceId)) {
          cache[resourceId] = resourceId;
        } else {
          return $moreResourceService.asUrl($scope.customerId, resourceId).then(function (data) {
            cache[resourceId] = data;
          });
        }
      };

      $scope.getResourceUrl = function (resourceId) {
        if ($scope.showThumbnail) {
          return getResourceAsThumbnailUrl(resourceId);
        } else {
          return getResourceUrl(resourceId);
        }
      };
    }]
  };
});
