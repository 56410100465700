'use strict';

angular.module('MoreGrid').directive('textualFilter', ['$timeout', function ($timeout) {
  var DELAY = 700;

  return {
    restrict: 'E',
    template: '<input type="text" class="form-control" data-ng-model="filterValue">',
    scope: {
      onChange: '&',
      id: '@'
    },
    link: function (scope) {
      var timeoutId;

      scope.$watch('filterValue', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (timeoutId) {
            $timeout.cancel(timeoutId);
          }
          timeoutId = $timeout(function () {
            scope.onChange({ value: newValue, id: scope.id });
          }, DELAY);
        }
      });
    }
  };
}]);