'use strict';

angular.module('customerPortal').factory('$signUpCustomerService', ['$resource', 'moreConstants', '$browserService', function ($resource, moreConstants, $browserService) {

  var SignUps = $resource(moreConstants.apiEndpoint + '/signups/customers', {}, {});

  return {
    signUp: function (signUp) {
      signUp.timeZone = $browserService.getTimeZone();
      return SignUps.save(signUp);
    }
  };
}]);