'use strict';

angular.module('Tour').controller('TourCtrl', ['$scope', '$rootScope', '$window', 'moreConstants', '$location', '$state', '$q', '$tourService', '$userService', 'customerId', '$customerRegistrationFileService', '$customerUserService', '$segmentUtils', '$customerService', '$customerBillingService', '$translate', 'segment', 'features', '$browserService', '$customerFormTemplateService', '$customerPluginService', '$modalService', '$customerFormService', '$customerFolderService', function ($scope, $rootScope, $window, moreConstants, $location, $state, $q, $tourService, $userService, customerId, $customerRegistrationFileService, $customerUserService, $segmentUtils, $customerService, $customerBillingService, $translate, segment, features, $browserService, $customerFormTemplateService, $customerPluginService, $modalService, $customerFormService, $customerFolderService) {
  const self = this;
  const DEFAULT_FOLDER_NAMES = $customerFormService.getDefaultFolderNames();
  let finished = false;

  self.segment = segment;
  self.activeStep = 0;
  self.logo = '/shared/images/moreapp.png';
  self.appName = features.appName;
  self.accountName = '';
  self.customer = undefined;
  self.userSettings = undefined;
  self.steps = _getSteps();
  self.errorStep = {
    bodyTemplate: require('../templates/steps/step.error.body.html')
  };

  self.nextStep = nextStep;
  self.previousStep = previousStep;
  self.finish = finish;
  self.saveInfoAndContinue = saveInfoAndContinue;

  init();

  ////////
  function init () {
     $customerService.getCustomer(customerId).$promise.then((customer) => {
       self.customer = customer;
    });

    $userService.getUser().$promise.then((user) => {
      self.user = user;
      self.userSettings = user.settings;
      self.userSettings.firstName = self.userSettings.firstName || '';
      self.userSettings.lastName = self.userSettings.lastName || '';
      self.userSettings.language = self.userSettings.language || $translate.use();
      if (self.userSettings.country === 'EN') {
        // Default setting for user, which is not even a country code
        $browserService.getBrowserCountryCode().then((countryCode) => {
          self.userSettings.country = countryCode;
        });
      }
    });

    $scope.$watch(() => {
      return $location.url();
    }, (url) => {
      if (url) {
        self.activeStep = parseInt($location.search().step || 0);
      }
    });

    $scope.$on('$locationChangeStart', (event) => {
      if (self.activeStep === 0 && !finished) {
        event.preventDefault();
      }
    });
  }

  function nextStep() {
    self.activeStep++;
    const next = self.steps[self.activeStep];
    if (next.activate) {
      next.activate();
    }
    $location.search('step', self.activeStep);
    $location.url($location.path() + '?step=' + self.activeStep);
  }

  function previousStep() {
    self.activeStep--;
  }

  function finish() {
    finished = true;
    _createDefaultFolder().then(createdFolder => {
      $userService.updateUserStatus({ finishedTour: true }).$promise.then(() => {
        $scope.$close();
        $rootScope.$broadcast('form.add', { folder: createdFolder });
      }, _showError);
    }, _showError);
  }

  function saveInfoAndContinue(options) {
    const opt = options || {finish: false};
    $userService.updateUserInfo(self.userSettings);
    $customerService.updateGeneralCustomerSettings(customerId, self.customer.settings);
    if (self.accountName && self.accountName.length > 0) {
      $customerService.updateName(customerId, self.accountName);
      $customerBillingService.updateBilling(customerId, {companyName: self.accountName});
    }
    if (opt.finish) {
      finish();
    } else {
      nextStep();
    }
  }

  function _showError() {
    self.activeStep = -1;
  }

  function _createDefaultFolder() {
    const languageCode = $translate.use();
    const newFolder = {
      meta: {
        name: DEFAULT_FOLDER_NAMES[languageCode] || DEFAULT_FOLDER_NAMES.en
      }
    };

    return $customerFolderService.saveFolder(customerId, newFolder).$promise.then((createdFolder) => {
      $rootScope.$broadcast('folder.added', { folder: createdFolder });
      return createdFolder;
    });
  }

  function _getSteps() {
    return [
      {bodyTemplate: require('../templates/steps/step1.body.html')},
      {bodyTemplate: require('../templates/steps/step2.body.html')},
      {bodyTemplate: require('../templates/steps/step3.body.html')}
    ];
  }
}]);
