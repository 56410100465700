'use strict';

angular.module('FormEditor').controller('RuleTabCtrl', ['$scope', '$state', '$formVersionUtilityService', '$widgetService', '$formVersionUtils', '$timeout', '$translate', function ($scope, $state, $formVersionUtilityService, $widgetService, $formVersionUtils, $timeout, $translate) {

  var self = this;

  var init = function () {
    self.otherViewFields = null;

    $scope.targetView.rules.forEach(function (rule) {
      if (rule.type === null) {
        rule.type = 'AND';
      }
    });

    $widgetService.all($state.params.customerId).$promise.then(function () {
      computeOtherViewFields();
      computeViewFields();
    });

    $scope.$watchCollection('targetView.fields', function () {
      computeViewFields();
      computeOtherViewFields();
    }, false);

    $scope.$on('formFieldPropertiesChanged', function () {
      computeViewFields();
    });

    self.ruleTypes = [{'id' : 'AND', name : $translate.instant('ALL')}, {'id' : 'OR', name : $translate.instant('ANY')}];
  };

  self.fieldForUid = fieldUid => $scope.targetView.fields.find(i => i.uid === fieldUid) || self.otherViewFields.find(i => i.field.uid === fieldUid).field;

  self.removeRule = function (rule) {
    $scope.targetView.rules.splice($scope.targetView.rules.indexOf(rule), 1);
  };

  self.addRule = function () {
    $formVersionUtils.addRule($scope.targetView);

    $timeout(function () {
      $scope.isopen = true;
    });
  };

  ////////

  var allowConditionOnField = function (field) {
    var widget = $widgetService.getWidgetWithSync(field.widget);
    if (widget !== undefined) {
      return widget.definition.data;
    }
  };

  var purgeAllFields = function (view, answer) {
    view.fields.forEach(function (field) {
      if (allowConditionOnField(field)) {
        var widget = $widgetService.getWidgetWithSync(field.widget);
        answer.push({
          displayName : $formVersionUtilityService.getFieldDisplayName(field),
          uid : field.uid,
          widget : $widgetService.sanitizeUid(field.widget),
          viewId : view.uid,
          data : widget.definition.data,
          field
        });
      }
    });
  };

  var computeViewFields = function () {
    var answer = [];
    var targetView = $scope.targetView;
    if (targetView.type !== 'list_view' && targetView.fields) {
      purgeAllFields(targetView, answer);
    }
    self.currentViewFields = answer;
  };

  var computeOtherViewFields = function () {
    // Load parent form (1 level up)
    if ($scope.viewStack.length > 1) {
      var parentView = $scope.viewStack[$scope.viewStack.length - 2].view;
      var answer = [];
      purgeAllFields(parentView, answer);
      self.otherViewFields = answer;
    } else {
      self.otherViewFields = null;
    }
  };

  init();
}]);
