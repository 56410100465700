'use strict';

angular.module('Billing').controller('BillingUsageCtrl', ['$state', '$customerUsageService', '$monthlyUsageChartService', '$dailyUsageChartService', function ($state, $customerUsageService, $monthlyUsageChartService, $dailyUsageChartService) {
  const self = this;

  const currentYear = new Date().getFullYear();
  const selectableYears = [];
  for (let i = currentYear; i >= 2013; i--) {
    selectableYears.push(i);
  }

  self.dailyUsageTemplate = require('../../../modules/Billing/templates/billing.usage.daily.html');
  self.monthlyUsageTemplate = require('../../../modules/Billing/templates/billing.usage.monthly.html');
  self.selectedYear = currentYear;
  self.selectableYears = selectableYears;
  self.statisticPresentation = 'monthly';
  self.chartConfig = $monthlyUsageChartService.getChartConfig();

  self.selectDailyCreditUsage = selectDailyCreditUsage;
  self.selectMonthlyCreditUsage = selectMonthlyCreditUsage;
  self.changeYear = changeYear;

  init();

  ////////
  function init() {
    $customerUsageService.getDailyUsageLastMonth($state.params.customerId).$promise.then((dailyData) => {
      self.dailyChartConfig = $dailyUsageChartService.getChartConfig(dailyData);
      self.dailyData = dailyData;
    });

    self.changeYear(self.selectedYear);
  }

  function selectDailyCreditUsage() {
    _updateToPresentation('daily');
  }

  function selectMonthlyCreditUsage() {
    _updateToPresentation('monthly');
  }

  function changeYear(year) {
    const promise = $customerUsageService.getMonthlyUsageForYear($state.params.customerId, year);
    $monthlyUsageChartService.setMonthData(self.chartConfig, promise.$promise);
    promise.$promise.then(function (usage) {
      self.monthlyData = usage;
    });
  }

  function _updateToPresentation(presentation) {
    if (self.statisticPresentation === presentation) {
      return false;
    }
    self.statisticPresentation = presentation;
    return true;
  }
}]);
