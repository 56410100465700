'use strict';

angular.module('MoreAppsSecurity').service('OAuth2LoginService', ['moreConstants', '$rootScope', '$location', 'localStorageService', function (moreConstants, $rootScope, $location, localStorageService) {
  function baseUrl(affix) {
    return $location.url() === '/' ?
      `${$location.$$absUrl}${affix}` :
      $location.absUrl().replace(new RegExp(`${$location.url().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`), `/${affix}`);
  }

  // Handle portal prefix during local development...
  const redirectUrl = baseUrl('login/callback');
  const postLogoutRedirectUrl = baseUrl('logout/callback');

  const userManager = new window.oidcClient.UserManager({
    authority: moreConstants.hydraPublicUrl,
    client_id: 'moreapp-portal',
    redirect_uri: redirectUrl,
    post_logout_redirect_uri: postLogoutRedirectUrl,
    response_type: 'code',
    scope: 'openid offline',
    automaticSilentRenew: true,
    userStore: new window.oidcClient.WebStorageStateStore({ store: window.localStorage })
  });

  return {
    login: (originalUrl) => {
      const extraQueryParams = {};
      if ($rootScope.features) {
        extraQueryParams.brandingKey = $rootScope.features.brandingKey;
        extraQueryParams.customerId = $rootScope.features.customerId;
      }

      const state = {
        originalUrl: originalUrl || '/'
      };

      userManager.signinRedirect({extraQueryParams, state});
    },
    logout: () => {
      if (localStorageService.get('user')) {
        localStorageService.remove('user');
        userManager.signoutRedirect();
      }
    },
    userManager
  };
}]);
