'use strict';

angular.module('FormEditor').controller('EmailTabCtrl', ['$scope', '$formVersionUtils', '$mailUtils', '$widgetService', '$translate', '$modalService', 'moreConstants', '$moreResourceService', '$timeout', '$rootScope', '$customerService', '$state', '$featureHelper', function ($scope, $formVersionUtils, $mailUtils, $widgetService, $translate, $modalService, moreConstants, $moreResourceService, $timeout, $rootScope, $customerService, $state, $featureHelper) {
  var self = this;

  var meta = [{ properties: { data_name: 'meta.location.longitude', label_text: 'meta.location.longitude' } }, { properties: { data_name: 'meta.location.latitude', label_text: 'meta.location.latitude' } }, { properties: { data_name: 'meta.registrationDate', label_text: 'meta.registrationDate' } }, { properties: { data_name: 'meta.guid', label_text: 'meta.guid' } }, { properties: { data_name: 'meta.taskId', label_text: 'meta.taskId' } }, { properties: { data_name: 'meta.serialNumber', label_text: 'meta.serialNumber' } }, { properties: { data_name: 'info.formName', label_text: 'info.formName' } }, { properties: { data_name: 'info.userId', label_text: 'info.userId' } }];

  self.addEmailNotification = addEmailNotification;
  self.removeEmailNotification = removeEmailNotification;
  self.toggleAdvanced = toggleAdvanced;
  self.imageUpload = onImageUpload;
  self.updateHeaderLogo = updateHeaderLogo;
  self.editHeaderLogo = editHeaderLogo;
  self.clearHeaderLogo = clearHeaderLogo;
  self.getDefaultHeaderLogo = getDefaultHeaderLogo;
  self.isMainForm = isMainForm;

  var customerId = $scope.$state.params.customerId;

  init();

  ////////
  function init() {

    $scope.$watch('targetView', function () {
      self.advanced = $scope.targetView.triggers.length > 0 && !$scope.targetView.triggers[0].configuration.simpleMode;
      setupSummernotePlaceholders();
    });

    $featureHelper.hasFeature(moreConstants.PDF_LOGO).then(hasFeature => {
      self.hasPDFLogoFeature = hasFeature;
    });

    $featureHelper.hasFeature(moreConstants.ADVANCED_PDF).then(hasFeature => {
      self.hasAdvancedPDFFeature = hasFeature;
    });

    $featureHelper.hasFeature(moreConstants.MULTIPLE_PDF).then(hasFeature => {
      self.hasMultiplePDFFeature = hasFeature;
    });

    self.summernoteOptions = {
      minHeight: 200,
      dialogsInBody: true,
      prettifyHtml: false,
      disableResizeEditor: true,
      toolbar: [['style', ['style']], ['font', ['bold', 'italic', 'underline', 'clear']], ['fontname', ['fontname']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['table', ['table']], ['insert', ['link', 'picture', 'hr']], ['view', ['codeview']], ['moreapp', ['placeholders', 'showmap']]],
      scope: self
    };
  }

  function onImageUpload(files, cssSelector) {
    uploadImage(files, function (moreResource) {
      var uploadedImageUrl = getPublicResourceUrl(moreResource.id);
      $(cssSelector + '.summernote').summernote('insertImage', uploadedImageUrl);
    });
  }

  function updateHeaderLogo(trigger) {
    return (moreResource) => {
      trigger.pdfHeaderLogoResourceId = moreResource.id;
    };
  }

  function addEmailNotification() {
    if (!self.hasMultiplePDFFeature && $scope.targetView.triggers.length > 0) {
      return $modalService.upgradePlanModal({
        title: 'FEATURE_MULTIPLE_EMAIL',
        message: 'FEATURE_MULTIPLE_EMAIL_MESSAGE',
        imgSrc: 'https://cdn.moreapp.com/features/en/feature-multiple-emails.png'
      });
    }
    $formVersionUtils.addEmailTrigger($scope.form, $scope.targetView);
  }

  function removeEmailNotification(currentTrigger) {
    $scope.targetView.triggers.splice($scope.targetView.triggers.indexOf(currentTrigger), 1);
  }

  function toggleAdvanced($event, triggerIndex) {
    $event.preventDefault();
    const isDisablingAdvancedMode = !$event.target.checked;

    if (!self.hasAdvancedPDFFeature && !isDisablingAdvancedMode) {
      return $modalService.upgradePlanModal({
        title: 'FEATUER_ADVANCED_PDF',
        message: 'FEATUER_ADVANCED_PDF_MESSAGE',
        imgSrc: 'https://cdn.moreapp.com/features/en/feature-pdf-custom.png'
      });
    }

    if ($scope.targetView.triggers[triggerIndex].configuration.simpleMode) {
      //Needed to triger angular digest cycle.
      $timeout(() => {
        $scope.targetView.triggers[triggerIndex].configuration.simpleMode = false;
      });
      $scope.$broadcast('formbuilder.email.advanced');
    } else {
      $modalService.confirmModal({
        title: 'APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE',
        message: 'APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE',
        confirmButtonTitle: 'OK',
        confirmButtonStyle: 'warning',
        showExclamation: true
      }).then(function () {
        //Needed to triger angular digest cycle.
        $timeout(() => {
          $scope.targetView.triggers[triggerIndex].configuration.simpleMode = true;
        });
      });
    }
  }

  function editHeaderLogo($event) {
    if (!self.hasPDFLogoFeature) {
      return $modalService.upgradePlanModal({
        title: 'FEATURE_PDF_LOGO',
        message: 'FEATURE_PDF_LOGO_MESSAGE',
        imgSrc: 'https://cdn.moreapp.com/features/en/feature-pdf-logo.png'
      });
    }

    var hiddenFileInput = $($event.currentTarget);
    hiddenFileInput.parent().find('.pdf-header-logo-input').click();
  }

  function clearHeaderLogo($event, trigger) {
    $event.preventDefault();
    $event.stopPropagation();
    trigger.pdfHeaderLogoResourceId = null;
  }

  function getDefaultHeaderLogo() {
    return `${moreConstants.apiEndpoint}/customers/${customerId}/logo?type=report`;
  }

  ////////
  function buildEmailField(dataName, icon, dataType, fullkey) {
    dataType = dataType || 'text';
    return { 'data_name': dataName, 'dataType': dataType, 'label_text': dataName, icon: icon, widgetFullkey: fullkey };
  }

  const buildLocationFields = (dataName, icon) => {
    return [
        { 'data_name': dataName + '.coordinates.latitude', 'label_text': dataName + ' - Latitude', icon: icon },
        { 'data_name': dataName + '.coordinates.longitude', 'label_text': dataName + ' - Longitude', icon: icon },
        { 'data_name': dataName + '.formattedValue', 'label_text': dataName + ' - Formatted Value', icon: icon },
        { 'data_name': dataName, 'label_text': dataName, icon: icon },
    ];
  };

  function uploadImage(files, successCallback) {
    var allowedFileTypes = ['png', 'jpg', 'jpeg', 'gif'];
    $.each(files, function (idx, file) {
      var extensionRegex = /(?:\.([^.]+))?$/;
      var ext = extensionRegex.exec(file.name)[1];

      if (!ext || allowedFileTypes.indexOf(ext.toLowerCase()) === -1) {
        $modalService.errorModal($translate.instant('UNSUPPORTED_FILE_TYPE', { fileTypes: allowedFileTypes.join() }));
      } else {
        var options = {
          name: file.name,
          shared: true
        };
        $moreResourceService.saveFileResource(customerId, file, options).then(successCallback);
      }
    });
  }

  function isMainForm() {
    return ($scope.targetView.meta && $scope.targetView.meta.isRootView === true) || $scope.targetView.formId;
  }

  function getPublicResourceUrl(moreResourceId) {
    return moreConstants.apiEndpoint + '/common/customers/' + customerId + '/resources/' + moreResourceId;
  }

  function setupSummernotePlaceholders() {
    self.availablePlaceholderFields = [];
    self.availableEmailFields = {
      basic: [{ 'data_name': '${sys.user}', 'label_text': $translate.instant('${sys.user}'), icon: 'fa-user' }],
      email: [],
      other: []
    };

    let availableFields = $formVersionUtils.getFields($scope.targetView);
    availableFields.forEach(function (field) {
      var dataName = field.properties.data_name;
      if (!dataName) {
        return;
      }
      $widgetService.byUid(field.widget).then(function (widget) {
        if (!widget.definition.data) {
          return;
        }
        var dataType = widget.definition.data.type;
        var prettyField = buildEmailField(dataName, widget.definition.icon, dataType, widget.fullkey);
        switch (dataType) {
          case 'email':
          case 'option':
            self.availableEmailFields.other.push(prettyField);
            self.availablePlaceholderFields.push(prettyField);
            break;
          case 'datasource':
            if (!field.properties.data_source_configuration || !field.properties.data_source_configuration.mapping) {
              break;
            }
            var keys = Object.keys(field.properties.data_source_configuration.mapping);
            keys.forEach(function (key) {
              self.availableEmailFields.other.push(buildEmailField(dataName + '.' + key, widget.definition.icon));
              self.availablePlaceholderFields.push(buildEmailField(dataName + '.' + key, widget.definition.icon));
            });
            break;
          case 'object':
            var objKeys = Object.keys(widget.definition.data.subdata);
            objKeys.forEach(function (key) {
              self.availablePlaceholderFields.push(buildEmailField(dataName + '.' + key, widget.definition.icon));
            });
            break;
          case 'location':
            self.availableEmailFields.other.push(...buildLocationFields(dataName, widget.definition.icon));
            self.availablePlaceholderFields.push(...buildLocationFields(dataName, widget.definition.icon));
            break;
          default:
            self.availablePlaceholderFields.push(prettyField);
            break;
        }
      });
    });

    meta.forEach(function (element) {
      self.availablePlaceholderFields.push(buildEmailField(element.properties.data_name, ''));
    });

    // re-render summernote, so plugins (like placeholders) are reset
    $scope.showSummernote = false;
    $timeout(function () {
      $scope.showSummernote = true;
    });
  }
}]);
