'use strict';

angular.module('DataSources').directive('datasourceGoogleSpreadsheetConfiguration', ['$state', '$oauthAuthorizeService', '$window', 'moreConstants', '$modalService', '$featureHelper', function ($state, $oauthAuthorizeService, $window, moreConstants, $modalService, $featureHelper) {

  return {
    restrict: 'E',
    template: require('../templates/directives/datasource-google-spreadsheets-configuration.html'),
    scope: {
      ngModel: '='
    },
    link: function (scope) {
      scope.ngModel = scope.ngModel || { updateInterval: 'NEVER', spreadsheetId: '' };
      scope.isAuthenticated = false;
      scope.authenticateGoogle = authenticateGoogle;

      init();

      ////////
      function init() {
        checkAuthentication();

        $featureHelper.hasFeature(moreConstants.AUTOMATIC_DATASOURCES).then(hasFeature => {
          scope.hasAutomaticDataSourcesFeature = hasFeature;
          scope.$watch('ngModel.updateInterval', (newVal, oldVal) => {
            if (!scope.hasAutomaticDataSourcesFeature && newVal && newVal !== 'NEVER') {
              scope.ngModel.updateInterval = oldVal;
              $modalService.upgradePlanModal({
                title: 'FEATURE_AUTOMATIC_DATASOURCES',
                message: 'FEATURE_AUTOMATIC_DATASOURCES_MESSAGE',
                imgSrc: 'https://cdn.moreapp.com/features/en/feature-automatic-data-import.png'
              }).then(scope.$parent.$close); // close Sidepanel
            }
          });
        });
      }

      function checkAuthentication() {
        $oauthAuthorizeService.isAuthenticated($state.params.customerId).$promise.then(function (result) {
          scope.isAuthenticated = result.status === 'authenticated';
        });
      }

      function authenticateGoogle() {
        $oauthAuthorizeService.buildOAuthUrl($state.params.customerId).$promise.then(function (response) {
          _openOauthLoginScreen(response.url);
        });
      }

      function _openOauthLoginScreen(loginUrl) {
        var windowHeight = 500;
        var windowWidth = 900;
        var topPosition = ($window.outerHeight - windowHeight) / 2;
        var leftPosition = ($window.outerWidth - windowWidth) / 2;

        var openedWindow = $window.open(loginUrl, 'Login', 'width=' + windowWidth + ',height=' + windowHeight + ',top=' + topPosition + ',left=' + leftPosition);

        var pollTimer = window.setInterval(function () {
          if (openedWindow.closed !== false) {
            // !== is required for compatibility with Opera
            window.clearInterval(pollTimer);
            checkAuthentication();
          }
        }, 200);
      }
    }
  };
}]);