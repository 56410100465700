//App
require('./scripts/customer-portal-bootstrap');

require('./scripts/stateManager');
require('./scripts/stateManager.form');
require('./scripts/stateManager.form.editor');
require('./scripts/stateManager.templates');
require('./scripts/stateManager.datasources');
require('./scripts/stateManager.activation');
require('./scripts/stateManager.customer-portal-settings');
require('./scripts/stateManager.clients');
require('./scripts/stateManager.billing');
require('./scripts/stateManager.access');
require('./scripts/stateManager.404'); // This must always be the last state manager

require('./scripts/services/SignUpCustomerService');
require('./scripts/services/EmailValidationService');
require('./scripts/services/FeaturesService');

require('./scripts/controllers/AccessDeniedCtrl');
require('./scripts/controllers/PortalCtrl');
require('./scripts/controllers/PortalCustomersCtrl');
require('./scripts/controllers/SignUpCtrl');
require('./scripts/controllers/EmailValidationCtrl');
require('./scripts/controllers/OnboardingCtrl');

require('./scripts/controllers/folders/FolderSettingsCtrl');

require('./scripts/controllers/modals/AgreementsModalCtrl');
require('./scripts/controllers/modals/CustomerInfoModalCtrl');

require('./scripts/controllers/pickers/NewFormCtrl');
require('./scripts/controllers/pickers/FolderPickerCtrl');
require('./scripts/controllers/pickers/DataSourcePickerCtrl');
require('./scripts/controllers/pickers/UserPickerCtrl');
require('./scripts/controllers/pickers/TemplatePickerCtrl');
require('./scripts/controllers/pickers/GroupPickerCtrl');

require('./scripts/directives/FormThemePicker');

//Dependencies
require('../modules/MoreSecurity');
require('../modules/MoreResource');
require('../modules/MoreServices');
require('../modules/AdminServices');
require('../modules/FormEditor');
require('../modules/WidgetPropertyPane');
require('../modules/FormVersionUtils');
require('../modules/MoreI18n');
require('../modules/MoreFilters');
require('../modules/MoreConstants');
require('../modules/MoreUtils');
require('../modules/MoreDirectives');
require('../modules/MoreSidePanel');
require('../modules/UsageCharts');
require('../modules/MoreMenu');
require('../modules/Tour');
require('../modules/Help');
require('../modules/FormSimulator');
require('../modules/DataSources');
require('../modules/Pagination');
require('../modules/UserState');
require('../modules/FolderPicker');
require('../modules/DataSourcePicker');
require('../modules/UserPicker');
require('../modules/GroupPicker');
require('../modules/Modals');
require('../modules/Activation');
require('../modules/FormTemplates');
require('../modules/FormManagement');
require('../modules/FormManagement.submissions');
require('../modules/FormManagement.tasks');
require('../modules/UserManagement');
require('../modules/CustomerPortalSettings');
require('../modules/Impersonation');
require('../modules/DynamicForms');
require('../modules/IconPicker');
require('../modules/SelectableDropdown');
require('../modules/PageControl');
require('../modules/PortalContext');
require('../modules/RegistrationFilePreview');
require('../modules/MoreGrid');
require('../modules/more.ui.form');
require('../modules/more.ui.form-builder');
require('../modules/Clients');
require('../modules/Billing');

// Bootstrap last
require('./scripts/customer-portal');
