'use strict';

angular.module('Billing').controller('BillingPlansCtrl', ['moreConstants', '$state', '$scope', '$billingService', '$window', '$q', '$modalService', '$customerBillingService', '$timeout', function (moreConstants, $state, $scope, $billingService, $window, $q, $modalService, $customerBillingService, $timeout) {
  const self = this;

  self.yearly = true;
  self.plans = undefined;
  self.monthlyPlans = undefined;
  self.yearlyPlans = undefined;
  self.activeSubscription = undefined;
  self.customerId = $state.params.customerId;
  self.ceil = Math.ceil;
  const _filterArchived = plan => plan.active;

  init();

  ////////
  function init () {
    _loadPlans();
  }

  function _loadPlans () {
    $billingService.getPlans(self.customerId).then(plans => {
      self.plans = plans;
      self.monthlyPlans = self.plans.filter(x => x.interval === 'month').filter(_filterArchived);
      self.yearlyPlans = self.plans.filter(x => x.interval === 'year' || x.amount === 0).filter(_filterArchived);
    });
  }

}]);
