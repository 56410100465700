'use strict';

angular.module('customerPortal').factory('$featuresService', function () {

  // This file is overwritten by the branding-generator
  const features = require('../../features.json');

  return {
    getFeatures: function () {
      features.$promise = Promise.resolve(features);
      return features;
    }
  };
});