'use strict';

angular.module('FormEditor').directive('conditionPicker', ['$widgetService', '$translate', function ($widgetService, $translate) {
  return {
    restrict: 'AE',
    template: require('../../../shared/templates/directives/condition-picker.html'),
    scope: {
      target: '=',
      fields: '=',
      otherFields: '=',
      fieldProvider: '&'
    },
    controller: ['$scope', function ($scope) {

      var availableConditionTypes = {
        is: { key: 'is', label: 'VIEW_EDITOR_RULE_CONDITION_IS' },
        startsWith: { key: 'startsWith', label: 'VIEW_EDITOR_RULE_CONDITION_STARTS_WITH' },
        endsWith: { key: 'endsWith', label: 'VIEW_EDITOR_RULE_CONDITION_ENDS_WITH' },
        contains: { key: 'contains', label: 'VIEW_EDITOR_RULE_CONDITION_CONTAINS' },
        greaterThan: { key: 'greaterThan', label: 'VIEW_EDITOR_RULE_CONDITION_GREATER_THAN' },
        lessThan: { key: 'lessThan', label: 'VIEW_EDITOR_RULE_CONDITION_LESS_THAN' },
        hasValue: {
          key: 'hasValue', label: 'VIEW_EDITOR_RULE_CONDITION_HAS_VALUE', options: [{ id: true, label: 'Yes' }, { id: false, label: 'No' }]
        }
      };

      var conditionTypeMap = {
        boolean: ['is', 'hasValue'],
        option: ['is', 'contains', 'hasValue'],
        number: ['is', 'startsWith', 'endsWith', 'contains', 'hasValue', 'greaterThan', 'lessThan'],
        _default: ['is', 'startsWith', 'endsWith', 'contains', 'hasValue']
      };

      init();

      function init() {
        $scope.types = buildTypes();

        var allFields = $scope.fields.concat($scope.otherFields || []);
        $widgetService.enrichFieldsWithWidgetInfo(allFields).then(function () {
          if (!$scope.target.type && $scope.target.fieldUid) {
            $scope.target.type = 'FIELD';
          }

          if ($scope.target.fieldUid) {
            var uid = $scope.target.fieldUid;
            $scope.selection = findByUid($scope.fields, uid) || findByUid($scope.otherFields, uid);

            if ($scope.selection) {
              handleSelectedField($scope.selection);

              var type = getTypeByKey($scope.target.key);
              if (type) {
                $scope.selectType(type);
              }
            }
          }
        });

        $scope.$watch('fields', function (newVal) {
          if (newVal) {
            $widgetService.enrichFieldsWithWidgetInfo(newVal);
          }
        });
      }

      function allowedConditionValues(target) {
        var answer = [];
        switch (target.dataType) {
          case 'boolean':
            answer.push({ id: true, label: $translate.instant('CHECKED') });
            answer.push({ id: false, label: $translate.instant('UNCHECKED') });
            return answer;
          case 'option':
            var field = $scope.fieldProvider({ fieldUid: target.uid });
            var options = field.properties.radio_options || field.properties.options;
            options.forEach(function (option) {
              answer.push({ id: option.id, label: option.name });
            });
            return answer;
          default:
            return null;
        }
      }

      function buildTypes(target) {
        var answer = [];
        var conditionTypeKeys = target && conditionTypeMap[target.dataType] || conditionTypeMap._default;

        conditionTypeKeys.forEach(function (conditionKey) {
          answer.push(availableConditionTypes[conditionKey]);
        });

        return answer;
      }

      function updateTarget(type, fieldUid, selection) {
        $scope.target.type = type;
        $scope.target.fieldUid = fieldUid;
        $scope.target.key = null;
        $scope.target.value = null;
        $scope.target.icon = selection.icon;
        $scope.target.dataType = selection.dataType;
        $scope.selection = selection;
        $scope.target.fieldObjectKey = null;
      }

      $scope.selectUserCondition = function () {
        if ($scope.target.type === 'USER') {
          return;
        }

        updateTarget('USER', null, { icon: 'fa-user' });
        $scope.types = buildTypes();
        $scope.conditionValues = null;
        $scope.fieldObjectKeys = null;
        selectFirstTypeWhenEmpty();
      };

      $scope.selectField = function (target) {
        if ($scope.target.type === 'FIELD' && $scope.target.fieldUid === target.uid) {
          return;
        }
        updateTarget('FIELD', target.uid, target);

        handleSelectedField(target);
      };

      $scope.selectFieldObjectKey = function (fieldObjectKey) {
        $scope.target.fieldObjectKey = fieldObjectKey;
      };

      $scope.selectType = function (type) {
        $scope.target.key = type.key;

        if (type.options) {
          $scope.conditionValues = type.options;
        } else {
          $scope.conditionValues = allowedConditionValues($scope.selection);
        }
      };

      $scope.valueLabelForId = function (id) {
        var answer = null;
        $scope.conditionValues.forEach(function (option) {
          if (option.id === id) {
            answer = option.label;
          }
        });
        return answer;
      };

      $scope.keyLabelForId = function (key) {
        if (!$scope.types) {
          return null;
        }
        var type = getTypeByKey(key);
        return type ? type.label : null;
      };

      $scope.getSourceLabel = function () {
        if ($scope.target.type === 'USER') {
          return 'VIEW_EDITOR_RULE_USER';
        } else if ($scope.target.type === 'FIELD' || $scope.target.type === 'FIELD_DATA_SOURCE') {
          return $scope.selection && $scope.selection.displayName;
        }
        return $translate.instant('VIEW_EDITOR_RULE_CHOOSE_FIELD');
      };

      function findByUid(collection, uid) {
        return collection && collection.filter(function (item) {
          return item.uid === uid;
        })[0];
      }

      function handleSelectedField(target) {
        if (target.data.type === 'datasource') {
          $scope.target.type = 'FIELD_DATA_SOURCE';
          var field = $scope.fieldProvider({ fieldUid: target.uid });

          var dataSourceConfiguration = field.properties[target.data.property];
          $scope.fieldObjectKeys = Object.keys(dataSourceConfiguration.mapping).filter(function (key) {
            return dataSourceConfiguration.mapping[key] === true;
          });
        } else {
          $scope.fieldObjectKeys = null;
        }

        $scope.types = buildTypes(target);
        selectFirstTypeWhenEmpty();
        $scope.conditionValues = allowedConditionValues(target);
      }

      function getTypeByKey(key) {
        return $scope.types.filter(function (type) {
          return type.key === key;
        })[0];
      }

      function selectFirstTypeWhenEmpty() {
        if ($scope.target.key === null) {
          $scope.selectType($scope.types[0]);
        }
      }
    }]
  };
}]);
