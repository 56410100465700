'use strict';
angular.module('more.ui.form').directive('widgetPlugin', function ($q, $widgetPluginUtility, $timeout, $http, $translate, $widgetStyleService, $compile, $userInfoService) {

  window.plugins = window.plugins || {}; // on devices this should already be filled with 'pushNotification' and 'toast'

  function setupWidgetStyling (widget) {
    if (widget && widget.code.css) {
      $widgetStyleService.addWidgetStyling(widget.info.uid, widget.code.css);
    }
  }

  function hasCapability (widget, capability) {
    return widget.definition.capabilities.indexOf(capability) !== -1;
  }

  function setupWidgetJavascript (scope, element, widget, customerId, collection, formData, formId, form) {
    if (widget && widget.code.js) {
      var widgetFunction = window.plugins[widget.info.uid];
      if (!widgetFunction) {
        /* jshint ignore:start */
        eval('function addWidget(f) { window.plugins["' + widget.info.uid + '"] = f;}' + widget.code.js);
        /* jshint ignore:end */
        widgetFunction = window.plugins[widget.info.uid];
      }

      //JS may not define addWidget
      if (!widgetFunction) {
        return;
      }

      var userInfo = $userInfoService.getUserInfo();

      widgetFunction(scope, element, {
        photo : hasCapability(widget, 'PHOTO') ? $widgetPluginUtility.photo : null,
        canvas : hasCapability(widget, 'CANVAS') ? $widgetPluginUtility.canvas : null,
        date : hasCapability(widget, 'DATE') ? $widgetPluginUtility.date : null,
        barcode : hasCapability(widget, 'BARCODE') ? $widgetPluginUtility.barcode : null,
        timeout : $widgetPluginUtility.timeout,
        http : hasCapability(widget, 'HTTP') ? $widgetPluginUtility.http : null,
        subForm : $widgetPluginUtility.subForm ? $widgetPluginUtility.subForm.handler(scope.field, collection, customerId) : null,
        popup : $widgetPluginUtility.popup,
        search : hasCapability(widget, 'SEARCH') ? $widgetPluginUtility.search(customerId) : null,
        file : hasCapability(widget, 'FILE') ? $widgetPluginUtility.file : null,
        pins : hasCapability(widget, 'PINS') ? $widgetPluginUtility.pin : null,
        resource : hasCapability(widget, 'RESOURCE') ? $widgetPluginUtility.resource : null,
        formData : formData,
        modal : hasCapability(widget, 'MODAL') ? $widgetPluginUtility.modal : null,
        location : hasCapability(widget, 'LOCATION') ? $widgetPluginUtility.location : null,
        actionSheet : hasCapability(widget, 'ACTIONSHEET') ? $widgetPluginUtility.actionSheet : null,
        payment : hasCapability(widget, 'PAYMENT') ? $widgetPluginUtility.payment : null,
        drawing: hasCapability(widget, 'DRAWING') ? $widgetPluginUtility.drawing : null,
        video: hasCapability(widget, 'VIDEO') ? $widgetPluginUtility.video : null,
        promise : $q,
        translate : $widgetPluginUtility.translate,
        variables : {
          customerId : customerId,
          formId : formId,
          form : form,
          username : userInfo ? userInfo.name : ''
        }
      });
    }
  }

  function setupWidgetHtml (scope, element, widget) {
    element.html(widget ? widget.code.html : '').show();
    $compile(element.contents())(scope);
  }

  function setupValueChanged (scope, config) {
    scope.valueChanged = function (newValue) {
      config.valueChanged(config.field, newValue);
    };
  }

  function setupValidation (scope, element, config, widget, validators) {
    function updateErrorMessage (message) {
      var errorElement = element.find(".field-error");
      var errorTextElement = errorElement.find(".field-error-text");
      if (message) {
        errorElement.css('display', 'inline');
        errorTextElement.text(message);
        scope.errorMessage = message;
      } else {
        errorElement.css('display', 'none');
        errorTextElement.text(null);
        scope.errorMessage = null;
      }
    }

    config.registerValidate(
      config.uid,
      {
        validate : function (value) {
          for (var i = 0; i < validators.length; i++) {
            var result = validators[i](value);
            if (result) {
              updateErrorMessage(result);
              return result;
            }
          }
          updateErrorMessage(null);
          return null;
        },
        reset : function () {
          updateErrorMessage(null);
        }
      }
    );

    scope.addValidator = function (validator) {
      validators.push(validator);
    };

    if (widget && widgetHasRequired(widget) && config.field.properties.required) {
      scope.addValidator(function (value) {
        return (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) ? $translate.instant('FIELD_ERROR_REQUIRED') : null;
      });
    }
  }

  function setupRejectInitialValue (scope, config) {
    scope.rejectInitialValue = function (newValue) {
      config.rejectInitialValue(config.field, newValue);
    };
  }

  function widgetHasRequired (widget) {
    if (widget.definition.data == null) {
      return false;
    }
    if (widget.definition.data.behaviour == null) {
      return false;
    }
    return !!widget.definition.data.behaviour.required;
  }

  return {
    restrict : 'E',
    scope : {
      config : '='
    },
    link : function (scope, element) {
      var config = scope.config;
      var widget = config.widget;
      delete scope.config;

      setupWidgetHtml(scope, element, widget);

      var validators = [];
      var customerId = config.customerId;
      var collection = config.collection;
      var formId = config.formId;
      var form = config.form;

      scope.widget = config.widget;
      scope.field = config.field;
      scope.errorMessage = null;
      scope.initialValue = angular.copy(config.ngModel());
      var formDataHelper = config.formDataHelper;

      setupWidgetStyling(widget);
      setupValueChanged(scope, config);
      setupRejectInitialValue(scope, config);
      setupValidation(scope, element, config, widget, validators);
      setupWidgetJavascript(scope, element, widget, customerId, collection, formDataHelper, formId, form);
    }
  };
});
