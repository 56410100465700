'use strict';

angular.module('MoreAppsServices').factory('$hubspotVisitorService', ['moreConstants', '$resource', function (moreConstants, $resource) {

    var visitorTokenResource = $resource(moreConstants.apiEndpoint + '/client/users/visitors/token');

    return {
        getToken: () => visitorTokenResource.get()
    };
}]);
