'use strict';

angular.module('MoreAppsUtils', ['ngRoute']);

angular.module('MoreAppsUtils').factory('$browserService', ['$http', '$q', function ($http, $q) {
  return {
    getBrowserCountryCode: function () {
      const deferred = $q.defer();
      const fallbackLanguageCode = (navigator.language || navigator.userLanguage || 'EN').slice(-2).toUpperCase();

      $http({method: 'GET', url: 'https://ip2c.org/s'})
        .success(function (response) {
          // Example response: 1;NL;NLD;Netherlands
          // Response can be absent when using an ad-blocker
          deferred.resolve(response ? response.split(';')[1] : fallbackLanguageCode);
        })
        .error(function () {
          deferred.resolve(fallbackLanguageCode);
        });
      return deferred.promise;
    },
    getTimeZone: function () {
      return window.jstz.determine().name();
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$languageUtil', ['$translate', function ($translate) {
  const SUPPORTED_LANGUAGES = [
    {code: 'en', iso: 'en-GB'},
    {code: 'nl', iso: 'nl-NL'},
    {code: 'de', iso: 'de-DE'},
    {code: 'es', iso: 'es-ES'},
    {code: 'fr', iso: 'fr-FR'},
    {code: 'pt', iso: 'pt-PT'}
  ].map(lang => {
    lang.name = $translate.instant(`LANGUAGE_${lang.code.toUpperCase()}`);
    return lang;
  });

  return {
    getSupportedLanguages() {
      return SUPPORTED_LANGUAGES;
    },
    getActiveLanguage() {
      return $translate.use();
    },
    getActiveLanguageISO() {
      const activeLanguage = SUPPORTED_LANGUAGES.find(lang => lang.code.toLowerCase() === this.getActiveLanguage().toLowerCase());
      if (activeLanguage) {
        return activeLanguage.iso;
      } else {
        // Fallback to default
        return 'en-GB';
      }
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$simpleFilterService', function () {

  return {
    gridFilterToSimpleFilter: function (filter, opts) {
      opts = opts || {};
      opts.prefix = opts.prefix || '';
      var sort = filter.sortInfo.fields.map(function (item, index) {
        return { key: opts.prefix + item, direction: filter.sortInfo.directions[index] === 'asc' ? 1 : -1 };
      });

      var query = [];
      if (filter.query) {
        for (var key in filter.query) {
          if (filter.query.hasOwnProperty(key)) {
            query.push({
              path: opts.prefix + key,
              'value': filter.query[key].value,
              type: filter.query[key].type || 'string'
            });
          }
        }
      }
      return {
        sort: sort,
        pageSize: filter.pageSize,
        query: query
      };
    }
  };
});

angular.module('MoreAppsUtils').factory('$uuidService', function () {
  var miniHash = function () {
    return Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);
  };

  var uid = function () {
    return miniHash() + miniHash() + miniHash() + miniHash();
  };

  return {
    uid: uid
  };
});

angular.module('MoreAppsUtils').factory('$moreDataNameService', function () {

  var getExistingDataNames = function (fields, activeField) {
    return fields.filter(function (field) {
      return field && field !== activeField;
    }).map(function (field) {
      return field.properties.data_name;
    });
  };

  var isDataNameDuplicated = function (fields, activeField) {
    return getExistingDataNames(fields, activeField).indexOf(activeField.properties.data_name) !== -1;
  };

  var stripNumberPostfix = function (value) {
    if (typeof value !== 'string') {
      return value;
    }
    var match = value.match(/\d+$/);
    if (match) {
      return value.replace(match[0], '');
    }
    return value;
  };

  return {
    isDataNameDuplicated: isDataNameDuplicated,
    asDataName: function (value) {
      if (value === null || value === undefined) {
        return null;
      }
      //Replace all characters preceded by 1 or more white space characters with the upper-case form.
      //Replace the first character with a lower-case.
      return value.replace(/[^a-zа-я0-9 ]/gi, '').replace(/\s+\S/g, function (txt) {
        return txt.charAt(txt.length - 1).toUpperCase();
      }).replace(/^[^a-zа-я]*|[^a-zа-я0-9]*$/gi, '').replace(/^\S/g, function (txt) {
        return txt.charAt(0).toLowerCase();
      });
    },
    matchesDataName: function (properties, value) {
      return (properties.data_name === value || stripNumberPostfix(properties.data_name) === value || properties.data_name === null || properties.data_name === undefined || properties.data_name === '') && (value !== null || properties.data_name === value);
    },
    uniquify: function (fields, activeField) {
      var dataName = activeField.properties.data_name;
      var existingNames = getExistingDataNames(fields, activeField);
      if (existingNames.indexOf(dataName) !== -1) {
        var i = 1;
        while (existingNames.indexOf(dataName + i.toString()) !== -1) {
          i++;
        }
        return dataName + i.toString();
      }
      return dataName;
    }
  };
});

angular.module('MoreAppsUtils').filter('validDataName', function () {
  return function (input) {
    return input ? input.match(/^[a-zа-я]+[a-zа-я0-9_]*$/gi) : false;
  };
});

angular.module('MoreAppsUtils').filter('currencySymbol', ['$filter', function ($filter) {
  return function (input, currency) {
    const currencyFilter = $filter('currency');
    switch (currency) {
      case 'usd':
        return currencyFilter(input, '$');
      case 'eur':
        return currencyFilter(input, '€');
      default:
        return input;
    }
  };
}]);

angular.module('MoreAppsUtils').filter('onlyCurrencySymbol', [function () {
  return function (input) {
    switch (input.toLowerCase()) {
      case 'usd':
        return '$';
      case 'eur':
      default:
        return '€';
    }
  };
}]);

angular.module('MoreAppsUtils').filter('mathAbs', ['$filter', function () {
  return Math.abs;
}]);

angular.module('MoreAppsUtils').filter('mathMin', ['$filter', function () {
  return Math.min;
}]);

angular.module('MoreAppsUtils').filter('timeString', function () {
  var doubleDigits = function (input) {
    return input < 10 ? '0' + input : input;
  };
  return function (input) {
    var rest = Math.floor(input / 1000);
    var seconds = rest % 60;
    rest = (rest - seconds) / 60;
    var minutes = rest % 60;
    rest = (rest - minutes) / 60;
    return doubleDigits(rest) + ':' + doubleDigits(minutes) + ':' + doubleDigits(seconds);
  };
});

angular.module('MoreAppsUtils').filter('dataNamePlaceholder', function () {
  return function (field) {
    return field ? '${' + field.properties.data_name + '}' : null;
  };
});

angular.module('MoreAppsUtils').factory('$stringUtils', function () {
  return {
    capitalise: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    humanize: function (string) {
      var result = string.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    endsWithAny: function (string, suffixes) {
      return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
      });
    }
  };
});

angular.module('MoreAppsUtils').factory('$numberUtils', function () {
  return {
    preciseRound: function (num, decimals) {
      return (Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals);
    },
    toUniqueNumbers: function (array) {
      if (!array || !array.length) {
        return [];
      }
      var result = $.unique(array.map(function (item) {
        return parseInt(item);
      }).filter(function (item) {
        return typeof item === 'number' && !isNaN(item);
      }));
      return result;
    }
  };
});

angular.module('MoreAppsUtils').factory('$imageUtils', ['$q', function ($q) {

  var invalidExtensions = ['svg'];

  return {
    isValidImage: function (src) {
      var deferred = $q.defer();

      var extension = src.split('.').pop().toLowerCase();
      if (invalidExtensions.indexOf(extension) !== -1) {
        deferred.reject();
      } else {
        var image = new Image();
        image.onerror = deferred.reject;
        image.onload = deferred.resolve;
        image.src = src;
      }

      return deferred.promise;
    }
  };
}]);

angular.module('MoreAppsUtils').directive('includeReplace', function () {
  return {
    restrict: 'A',
    link: function (scope, el) {
      el.replaceWith(el.children());
    }
  };
});

angular.module('MoreAppsUtils').directive('formIcon', function () {
  return {
    restrict: 'E',
    scope: {
      icon: '='
    },
    template: '<i class="icon" data-ng-class="\'ion-\' + icon "></i>'
  };
});

angular.module('MoreAppsUtils').directive('entryView', function () {
  return {
    restrict: 'E',
    template: require('../shared/templates/entry-view.html'),
    transclude: true
  };
});

angular.module('MoreAppsUtils').directive('compile', ['$compile', function ($compile) {
  'use strict';
  return {
    transclude: true,
    link: function link(scope, $el, attrs, ctrls, transclude) {
      var previousElements;

      compile();

      function compile() {
        transclude(scope, function(clone, clonedScope) {
          // transclude creates a clone containing all children elements;
          // as we assign the current scope as first parameter, the clonedScope is the same
          previousElements = clone;
          $el.append(clone);
        });
      }

      function recompile() {
        if (previousElements) {
          previousElements.remove();
          previousElements = null;
          $el.empty();
        }

        compile();
      }

      scope.$watch(attrs.kcdRecompile, function(_new, _old) {
        var useBoolean = attrs.hasOwnProperty('useBoolean');
        if ((useBoolean && (!_new || _new === 'false')) || (!useBoolean && (!_new || _new === _old))) {
          return;
        }
        // reset kcdRecompile to false if we're using a boolean
        if (useBoolean) {
          $parse(attrs.kcdRecompile).assign(scope, false);
        }

        recompile();
      });
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$downloadUtil', ['$q', 'OAuth2LoginService', function ($q, OAuth2LoginService) {
  function doRequest(url, success, headers = {}) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    Object.entries(headers).forEach(([key, value]) => xhr.setRequestHeader(key, value));

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (success) {
          success(xhr.response);
        }
      }
    };
    xhr.send(null);
  }

    function getAccessToken() {
        const deferred = $q.defer();
        OAuth2LoginService.userManager.getUser()
            .then((user) => {
                if (user) {
                    deferred.resolve(user.access_token);
                } else {
                    deferred.reject('User not logged in (but is required for this endpoint)');
                }
            }).catch((e) => {
            deferred.reject(e);
        });

        return deferred.promise;
    }

  return {
    downloadFileInNewWindow: function (url) {
      window.location.assign(url);
    },
    downloadFile: function (url, success) {
        doRequest(url, success);
    },
    downloadFileWithAuth: function (url, success) {
        getAccessToken().then((token) => {
            doRequest(url, success, {'Authorization': `Bearer ${token}`});
        });
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$featureHelper', ['$rootScope', '$state', '$customerService', '$q', function ($rootScope, $state, $customerService, $q) {
  return {
    hasFeature: function (feature) {
      if ($rootScope.customer) {
        return $q.when($rootScope.customer.$promise).then(customer => {
          return $q.resolve(customerHasFeature(customer, feature));
        });
      }
      return $customerService.getCustomer($state.params.customerId).$promise.then(customer => {
        $rootScope.customer = customer;
        return $q.resolve(customerHasFeature(customer, feature));
      });
    },
    customerHasFeature,
  };

  function customerHasFeature(customer, feature) {
    return customer.unlockedFeatures.indexOf(feature) !== -1;
  }
}]);

angular.module('MoreAppsUtils').directive('countrySelect', ['$countryService', function ($countryService) {
  return {
    restrict: 'EA',
    template: '<ui-select ng-model="_tmp.ngModel"> ' + '<ui-select-match placeholder="{{\'COUNTRY\'|translate}}"><span ng-bind="$select.selected.name"></span></ui-select-match> ' + '<ui-select-choices repeat="::option.alpha2Code as option in countries | filter: $select.search"> ' + '<span ng-bind-html="::option.name | highlight: $select.search"></span>&nbsp; ' + '<small ng-bind-html="::option.alpha2Code | highlight: $select.search"></small> ' + '</ui-select-choices> ' + '</ui-select>',
    require: 'ngModel',
    scope: {
      ngModel: '='
    },
    link: function (scope) {
      scope._tmp = scope;
      scope.countries = $countryService.allCountries();
    }
  };
}]);

angular.module('MoreAppsUtils').directive('uiSelectChoices', ['$animate', function ($animate) {
  // angular/ui-select has a performance issue on Angular 1.5.x due to ngAnimate
  // See: https://github.com/angular-ui/ui-select/issues/1436#issuecomment-187603499
  return {
    link: function (scope, element) {
      $animate.enabled(element, false);
    }
  };
}]);

angular.module('MoreAppsUtils').directive('segmentSelect', ['$translate', 'moreConstants', '$rootScope', function ($translate, moreConstants, $rootScope) {
  return {
    restrict: 'A',
    scope: {
      ngModel: '=',
      ngChange: '='
    },
    template: function (tElement, tAttrs) {
      var allowClear = tAttrs.allowClear;
      return ['<ui-select ng-model="_tmp.ngModel">', '<ui-select-match allow-clear="' + allowClear + '" placeholder="{{\'SEGMENT\'|translate}}"><i class="fa {{$select.selected.icon}}"></i>&nbsp;&nbsp;<span data-ng-bind="$select.selected.name"></span></ui-select-match>', '<ui-select-choices repeat="option.value as option in segments | filter: $select.search"><i class="fa {{::option.icon}}"></i>&nbsp;&nbsp;<span data-ng-bind-html="option.name | highlight: $select.search"></span></ui-select-choices>', '</ui-select>'].join();
    },
    link: function (scope, element, attrs) {
      $rootScope.$on('language.changed', function () {
        scope.segments = getTranslatedSegments();
      });

      scope.initialValue = attrs.initialValue;
      scope._tmp = scope;
      var segments = moreConstants.segments;
      scope.segments = getTranslatedSegments();

      function getTranslatedSegments() {
        return [{ name: 'SEGMENT_AUTOMOTIVE', value: segments.AUTOMOTIVE, icon: 'fa-truck' }, { name: 'SEGMENT_CONSTRUCTION', value: segments.CONSTRUCTION, icon: 'fa-wrench' }, { name: 'SEGMENT_FACILITY', value: segments.FACILITY, icon: 'fa-coffee' }, { name: 'SEGMENT_FINANCIAL', value: segments.FINANCIAL, icon: 'fa-money' }, { name: 'SEGMENT_GOVERNMENT', value: segments.GOVERNMENT, icon: 'fa-institution' }, { name: 'SEGMENT_HEALTH_CARE', value: segments.HEALTH_CARE, icon: 'fa-medkit' }, { name: 'SEGMENT_INSTALLATION', value: segments.INSTALLATION, icon: 'fa-plug' }, { name: 'SEGMENT_TRADE_AND_INDUSTRY', value: segments.TRADE_AND_INDUSTRY, icon: 'fa-industry' }, { name: 'SEGMENT_OTHER', value: segments.OTHER }].map(function (item) {
          item.name = $translate.instant(item.name);
          return item;
        });
      }
    }
  };
}]);

angular.module('MoreAppsUtils').directive('languageSelect', ['$translate', '$rootScope', function ($translate, $rootScope) {
  return {
    restrict: 'A',
    scope: {
      ngModel: '=',
      ngChange: '='
    },
    template: function (tElement, tAttrs) {
      var allowClear = tAttrs.allowClear;
      return ['<ui-select ng-model="_tmp.ngModel" on-select="onSelected($item)">', '<ui-select-match allow-clear="' + allowClear + '" placeholder="{{\'LANGUAGE\'|translate}}"><span data-ng-bind="$select.selected.name"></span></ui-select-match>', '<ui-select-choices repeat="::option.code as option in languageOptions | filter: $select.search"><span data-ng-bind-html="::option.name | highlight: $select.search"></span></ui-select-choices>', '</ui-select>'].join();
    },
    link: function (scope, element, attrs) {
      var locale = navigator.language || navigator.userLanguage;
      scope.language = locale.split('-')[0];
      scope._tmp = scope;
      var translateOnSelect = attrs.translateOnSelect === 'true';
      var selectableLanguages = attrs.languages ? attrs.languages.replace(' ', '').split(',') : null;

      var languages = [{
        code: 'en',
        name: $translate.instant('LANGUAGE_EN'),
      }, {
        code: 'nl',
        name: $translate.instant('LANGUAGE_NL'),
      }, {
        code: 'es',
        name: $translate.instant('LANGUAGE_ES'),
      }, {
        code: 'de',
        name: $translate.instant('LANGUAGE_DE'),
      }, {
        code: 'pt',
        name: $translate.instant('LANGUAGE_PT'),
      }, {
        code: 'fr',
        name: $translate.instant('LANGUAGE_FR'),
      }, {
        code: 'ca',
        name: $translate.instant('LANGUAGE_CA'),
      }];
      if (selectableLanguages && selectableLanguages.length > 0) {
        languages = languages.filter(function (language) {
          return selectableLanguages.indexOf(language.code) !== -1;
        });
      }

      scope.languageOptions = languages;
      scope.onSelected = function (option) {
        if (translateOnSelect) {
          $translate.use(option.code);
          $rootScope.$broadcast('language.changed');
        }
      };
    }

  };
}]);

angular.module('MoreAppsUtils').directive('regionSelect', ['$adminRegionService', function ($adminRegionService) {
  return {
    restrict: 'EA',
    template: '<ui-select ng-model="_tmp.ngModel"> ' + '<ui-select-match placeholder="{{\'REGION\'|translate}}"><span ng-bind="$select.selected.name"></span></ui-select-match> ' + '<ui-select-choices repeat="region.id as region in regions | orderBy:\'name\' | filter: $select.search"> ' + '<span ng-bind-html="region.name | highlight: $select.search"></span>' + '</ui-select-choices> ' + '</ui-select>',
    require: 'ngModel',
    scope: {
      ngModel: '='
    },
    link: function (scope) {
      scope._tmp = scope;
      scope.regions = $adminRegionService.getRegions();
    }
  };
}]);

angular.module('MoreAppsUtils').directive('autofocus', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, $element, attrs) {
      var shouldAutofocus = attrs.autofocus === '' || scope.$eval(attrs.autofocus);
      if (shouldAutofocus) {
        $timeout(function () {
          $element[0].focus();
        });
      }
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$segmentUtils', ['moreConstants', function (moreConstants) {
  return {
    findSegmentByName: function (name) {
      if (!name) {
        return moreConstants.segments.OTHER;
      }
      switch (name.toLowerCase()) {
        case 'automotive':
          return moreConstants.segments.AUTOMOTIVE;
        case 'construction and installation':
          return moreConstants.segments.CONSTRUCTION;
        case 'facility':
          return moreConstants.segments.FACILITY;
        case 'financial':
          return moreConstants.segments.FINANCIAL;
        case 'trade and industry':
          return moreConstants.segments.TRADE_AND_INDUSTRY;
        case 'government':
          return moreConstants.segments.GOVERNMENT;
        case 'health care':
          return moreConstants.segments.HEALTH_CARE;
        case 'installation':
          return moreConstants.segments.INSTALLATION;
        default:
          return moreConstants.segments.OTHER;
      }
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$dateUtils', function () {
  return {
    parseDateFromString: function (dateString) {
      var date = new Date();
      var dateParts = dateString.split('-');
      date.setYear(dateParts[0]);
      date.setMonth(dateParts[1] - 1);
      date.setDate(dateParts[2]);
      return date;
    },
    parseDateTimeFromString: function (dateTimeString) {
      var parts = dateTimeString.split(' ');
      var datePart = parts[0];
      var timeParts = parts[1].split(':');
      var date = this.parseDateFromString(datePart);
      date.setHours(timeParts[0]);
      date.setMinutes(timeParts[1]);
      return date;
    },
    formatDateFormat: function (format) {
      switch (format) {
        case 'DDMMYYYY':
          return 'dd-MM-yyyy';
        case 'MMDDYYYY':
          return 'MM-dd-yyyy';
        case 'YYYYMMDD':
          return 'yyyy-MM-dd';
        default:
          return format;
      }
    }
  };
});

angular.module('MoreAppsUtils').factory('$mailUtils', [function () {
  return {
    isEmailAddress: function (string) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(string);
    }
  };
}]);

angular.module('MoreAppsUtils').directive('fieldValidation', ['$interpolate', function ($interpolate) {

  return {
    replace: true,
    link: function (scope, el, attrs) {
      var inputEl = el.find('input, textarea, select, div[data-country-select], div[data-language-select], div[data-segment-select], ui-select');
      var key = inputEl.data('ng-model');
      if (attrs.fieldValidation && attrs.fieldValidation !== '') {
        key = key.replace(attrs.fieldValidation + '.', '');
      } else {
        key = $interpolate(inputEl.attr('name'))(scope);
      }

      var lastValue = null;
      scope.$watch('validationErrors.fieldErrors["' + key + '"]', function (newValue) {
        if (newValue !== lastValue) {
          lastValue = newValue && typeof newValue === 'object' ? newValue[0] : newValue;
          el.find('.help-block').remove();
          if (lastValue) {
            el.addClass('has-error');
            el.append('<p class="help-block">' + lastValue + '</p>');
          } else {
            el.removeClass('has-error');
          }
        }
      }, true);
    }
  };
}]);

angular.module('MoreAppsUtils').filter('longToDate', function () {
  return function (input) {
    if (input === null || input === undefined) {
      return null;
    }
    return new Date(input);
  };
});

angular.module('MoreAppsUtils').factory('$validationUtil', ['$modalService', function ($modalService) {
  return {
    getValidationErrorsObject: function (errors) {
      var validationErrors = {
        application: [],
        form: [],
        rule: [],
        'list_view': [],
        'view_item': [],
        'aggregated_view_items': {},
        'mail_template': []
      };

      errors.forEach(function (error) {
        var type = error.error.split('_').splice(1).join('_').toLowerCase();
        validationErrors[type].push(error.details);
      });

      validationErrors.view_item.forEach(function (viewItem) {
        if (!validationErrors.aggregated_view_items[viewItem.viewId]) {
          validationErrors.aggregated_view_items[viewItem.viewId] = [];
        }
        validationErrors.aggregated_view_items[viewItem.viewId].push(viewItem);
      });
      validationErrors.rule.forEach(function (rule) {
        if (!validationErrors.aggregated_view_items[rule.viewId]) {
          validationErrors.aggregated_view_items[rule.viewId] = [];
        }
        validationErrors.aggregated_view_items[rule.viewId].push(rule);
      });
      validationErrors.mail_template.forEach(function (mailTemplate) {
        if (!validationErrors.aggregated_view_items[mailTemplate.viewId]) {
          validationErrors.aggregated_view_items[mailTemplate.viewId] = [];
        }
        validationErrors.aggregated_view_items[mailTemplate.viewId].push(mailTemplate);
      });
      return validationErrors;
    }
  };
}]);

angular.module('MoreAppsUtils').factory('$objectPathResolver', function () {
  var resolveFromArray = function (object, components) {
    var next = components.shift();
    if (object === undefined || object === null || object[next] === null) {
      return null;
    }
    if (components.length === 0) {
      return object[next];
    }
    return resolveFromArray(object[next], components);
  };

  return {
    resolve: function (object, path) {
      if (object[path]) {
        return object[path];
      }
      var components = path.split('.');
      return resolveFromArray(object, components);
    }
  };
});

angular.module('MoreAppsUtils').factory('$keyboardUtils', ['$modalService', '$document', function ($modalService, $document) {
  var backspaceKey = 8;
  var backspaceIsAllowed = function (e) {
    //seperate check for combobox since it is not responding on backspace inputs and the if statement is checking for input
    return (e.target.isContentEditable || e.target.localName === 'input' || e.target.localName === 'textarea') && !$('input[type=checkbox]').is(':focus');
  };

  return {
    otherFunctionalityBackspaceForModals: function () {
      $document.on('keydown', function (e) {
        if (e.keyCode !== backspaceKey) {
          return;
        }
        var isModalOpen = document.getElementsByClassName('modal-open').length > 0;
        if (isModalOpen && !backspaceIsAllowed(e)) {
          e.stopPropagation();
          e.preventDefault();
        }
      });
    }

  };
}]);

angular.module('MoreAppsUtils').factory('$objectUtils', function () {
  return {
    unflatten: unflattenObject,
    flatten: flattenObject
  };

  ////////
  function applyPath(target, keys, value) {
    if (keys.length === 1) {
      target[keys[0]] = value;
    } else {
      var next = keys.shift();
      target[next] = target[next] || {};
      applyPath(target[next], keys, value);
    }
  }

  function unflattenObject(input) {
    var answer = {};
    Object.keys(input).forEach(function (key) {
      applyPath(answer, key.split(/\./gi), input[key]);
    });
    return answer;
  }

  function flattenObject(target) {
    var output = {};

    function step(object, prev) {
      Object.keys(object).forEach(function (key) {
        var value = object[key];
        var newKey = prev ? prev + '.' + key : key;
        if (value && value.constructor === Object) {
          return step(value, newKey);
        }
        output[newKey] = value;
      });
    }

    step(target);

    return output;
  }
});

angular.module('MoreAppsUtils').factory('$integrationEolUtils', function () {
  const whitelistedIntegrationNamespaces = [
    'com.moreapps.plugins',
    'com.moreapp.plugins',
    'com.moreapps',
    'com.moreapp'
  ];

  ////////
  function hasEolIntegrations(formVersion) {
    return formVersion.integrations.some(integration => isEolIntegration(integration));
  }

  function isEolIntegration(integration) {
    const namespace = integration.info ? integration.info.namespace : integration.namespace;
    return !(whitelistedIntegrationNamespaces.includes(namespace));
  }

  return {
    hasEolIntegrations,
    isEolIntegration
  };
});
