'use strict';

angular.module('FolderPicker').directive('folderPickerForm', ['$customerFolderService', '$state', '$location', '$userStateService', '$rootScope', '$usersContextService', function ($customerFolderService, $state, $location, $userStateService, $rootScope, $usersContextService) {

  return {
    restrict : 'E',
    template : require('../templates/folder-picker-form.html'),
    scope : {
      folder : '=',
      formFilter : '=',
      selectFolderCallback : '=',
      focusFolderCallback : '=',
      selectFormCallback : '=',
      addFormCallback : '='
    },
    link : function (scope, element) {
      scope.canManageFolders = $usersContextService.canManageFolders();
      scope.canAddFormsToFolder = $usersContextService.canAddFormsToFolder(scope.folder.id);
      scope.canMoveForm = (formId) => {
        return $usersContextService.canMoveForm(scope.folder.id, formId);
      };

      scope.activeForm = null;
      const userState = $userStateService.getState();

      scope.selectForm = function (form, folder) {
        scope.canAddFormsToFolder = $usersContextService.canAddFormsToFolder(folder.id);
        scope.activeForm = form.id;
        $userStateService.setFormId(form.id);
        scope.selectFormCallback({form, folder});
      };

      scope.activateForm = function () {
        const formId = $location.search().formId || userState.formId;
        if (formId) {
          const foundForm = scope.folder.forms.find(form => formId === form.id);
          if (foundForm) {
            scope.selectFolderCallback({folder: scope.folder});
            scope.selectForm(foundForm, scope.folder);
            const menuEl = $('.menu-picker-content')[0];
            menuEl.scrollTo(0, element[0].offsetTop);
          }
        }
      };

      scope.toggle = function () {
        scope.selectFolderCallback({folder : scope.folder});
      };

      scope.$on('form.changed', function (event, data) {
        if (scope.activeForm !== data.form.id) {
          scope.activeForm = null;
        }
      });

      scope.$on('form.updated', function (event, data) {
        scope.folder.forms = scope.folder.forms.map(form => form.id === data.form.id ? data.form : form);
      });

      scope.$on('form.added', function (event, data) {
        if (data.folder.id !== scope.folder.id) {
          return; // message was not meant for this collection
        }
        scope.folder.forms = data.folder.forms;
      });

      scope.sortableOptions = {
        orderChanged : function (orderScope, item, from, to) {
          $customerFolderService.moveOrderForm($state.params.customerId, scope.folder.id, item.id, to);
        }
      };

      scope.activateForm();

      scope.$on('form.removed', function (event, data) {
        // Don't process if form doesn't belong to this folder
        if (scope.folder.forms.filter(form => form.id === data.id).length === 0) {
          return;
        }

        // Get form that hasn't been removed if possible
        var forms = scope.folder.forms.filter(form => form.id !== data.id && form.status !== 'TRASH');
        if (forms.length > 0) {
          $userStateService.setFormId(forms[0].id);
        } else {
          $userStateService.setFormId(null);
        }
      });

      scope.$on('$destroy', function () {
        $rootScope.pickerItemActive = false;
      });

      $rootScope.$on('view.select', function (event, data) {
        var view = findViewById(data.viewId);
        scope.selectView(view);
        if (data.callback) {
          data.callback();
        }
      });

      function findViewById (viewId) {
        return scope.application.collectionViews.filter(function (view) {
          return view.viewId === viewId;
        })[0];
      }
    }
  };
}]);
