'use strict';

angular.module('PortalContext').service('$usersContextService', ['$rootScope', '$state', 'moreConstants', function ($rootScope, $state, moreConstants) {

  var usersDict = {};
  var userPromise = null;

  var getUser = function (username, callback) {
    if (!userPromise) {
      callback(null);
    } else {
      userPromise.then(function () {
        callback(usersDict[username]);
      });
    }
  };

  var hasPermissionsForCustomer = function (currentUser, permissions, customerId) {
    if (!currentUser.permissions) {
      return [];
    }

    return currentUser.permissions
        .filter(permissionGrant => permissionGrant.customerId === parseInt(customerId))
        .flatMap(permissionGrant => permissionGrant.permissions)
        .filter(permission => permissions.find(allowedPermission => allowedPermission === permission));
  };

  var getPermissionsForResource = function (currentUser, permissions, resourceId) {
    if (!currentUser.permissions) {
      return [];
    }

    return currentUser.permissions
        .filter(permissionGrant => permissionGrant.resourceId === resourceId)
        .flatMap(permissionGrant => permissionGrant.permissions)
        .filter(permission => permissions.find(allowedPermission => allowedPermission === permission));
  };

  return {
    setUsersPromise: function (promise) {
      userPromise = promise.then(function (users) {
        usersDict = {};
        users.forEach(function (user) {
          usersDict[user.username] = user;
        });
      });
    },
    getUser: getUser,
    getPrettyName: function (username, callback, options) {
      options = options || {};
      var withLastName = options.withLastName || false;
      this.getUser(username, function (user) {
        if (user && user.settings.firstName) {
          var name = user.settings.firstName;
          if (withLastName) {
            name += ' ' + user.settings.lastName;
          }
          callback(name);
        } else {
          callback(username);
        }
      });
    },
    hasRolesForCustomer: function (currentUser, permissions, customerId) {
      return hasPermissionsForCustomer(currentUser, permissions, customerId).length > 0;
    },
    hasPermissionsForResource: function (currentUser, permissions, resourceId) {
      return getPermissionsForResource(currentUser, permissions, resourceId).length > 0;
    },
    canManageFolders: function(firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS, moreConstants.UPDATE_FOLDER], customerId);
    },
    canManageForms: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS, moreConstants.UPDATE_FOLDER, moreConstants.UPDATE_FORM, moreConstants.CREATE_FORM, moreConstants.CREATE_FOLDER], customerId);
    },
    canManageForm: function (formId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS], customerId) ||
          this.hasPermissionsForResource($rootScope.currentUser, [moreConstants.UPDATE_FORM], formId);
    },
    canArchiveForm: function (formId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS], customerId) ||
          this.hasPermissionsForResource($rootScope.currentUser, [moreConstants.ARCHIVE_FORM], formId);
    },
    canCopyForm: function (formId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS], customerId);
    },
    canMoveForm: function (folderId, formId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS], customerId) ||
          (this.hasPermissionsForResource($rootScope.currentUser,[moreConstants.UPDATE_FOLDER], folderId) && this.hasPermissionsForResource($rootScope.currentUser,[moreConstants.UPDATE_FORM], formId));
    },
    canCreateFolders: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS, moreConstants.CREATE_FOLDER], customerId);
    },
    canAddFormsToFolder: function (folderId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_FORMS], customerId) ||
          (this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.CREATE_FORM], customerId) && this.hasPermissionsForResource($rootScope.currentUser, [moreConstants.ADD_FORM], folderId));
    },
    canManageRegistrations: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_REGISTRATIONS, moreConstants.READ_SUBMISSION], customerId);
    },
    canViewSubmissions: function (formId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_REGISTRATIONS], customerId) ||
          this.hasPermissionsForResource($rootScope.currentUser, [moreConstants.READ_SUBMISSION], formId);
    },
    canManageTasks: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_TASKS, moreConstants.READ_TASK, moreConstants.DELETE_TASK, moreConstants.CREATE_TASK], customerId);
    },
    canViewTasks: function (formId, firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_TASKS], customerId) ||
          this.hasPermissionsForResource($rootScope.currentUser, [moreConstants.READ_TASK, moreConstants.DELETE_TASK, moreConstants.CREATE_TASK], formId);
    },
    canManageDataSources: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_DATA_SOURCES], customerId);
    },
    canManageBilling: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_CREDITS], customerId);
    },
    canManageUsers: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_USERS], customerId);
    },
    canManageTemplates: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_TEMPLATES], customerId);
    },
    canTransferOwnership: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.TRANSFER_OWNERSHIP], customerId);
    },
    canManageAccount: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_ACCOUNT], customerId);
    },
    canManageSSO: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_SSO], customerId);
    },
    canManageWebhooks: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_WEBHOOKS], customerId);
    },
    canManageSendingDomain: function (firstCustomerFromRoles) {
      const customerId = firstCustomerFromRoles || $state.params.customerId;
      return this.hasRolesForCustomer($rootScope.currentUser, [moreConstants.MANAGE_SENDING_DOMAIN], customerId);
    }
  };
}]);
