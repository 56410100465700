'use strict';

angular.module('MoreGrid').directive('paginatedMoreGrid', function () {
  return {
    restrict: 'E',
    template: require('../templates/paginated-more-grid.html'),
    scope: {
      getListedObjects: '&',
      columns: '=',
      searchEnabled: '=',
      selectionEnabled: '=',
      sortEnabled: '=',
      searchDisabled: '&',
      idSelectionChanged: '&',
      viewObject: '&',
      viewEnabled: '=?',
      deleteObject: '&',
      allowSpecificObjectDeletion: '&',
      selection: '=?',
      pageSizes: '=',
      defaultPageSize: '@',
      filter: '=?',
      deleteEnabled: '=?'
    },
    link: function (scope) {
      var init = function () {
        if (typeof scope.deleteEnabled === 'undefined') {
          scope.deleteEnabled = !!scope.deleteObject;
        }

        if (!scope.filter) {
          scope.filter = {
            currentPage: 0,
            pageSize: scope.defaultPageSize,
            sortInfo: {
              directions: [],
              fields: []
            },
            query: {}
          };
        }
        scope.idSelection = [];

        // overridable defaults
        scope.columns = scope.columns || [];
        scope.selection = scope.selection || [];
        scope.searchEnabled = scope.searchEnabled || false;

        getListedObjects();
      };

      var getListedObjects = function () {
        scope.getListedObjects({ filter: scope.filter }).then(function (result) {
          scope.listedObjects = result.listedObjects;
          scope.totalItems = result.totalItems;
        });
      };

      scope.filterChanged = function (filter) {
        scope.filter.query = filter;
        getListedObjects();
      };

      scope.sortChanged = function (sortInfo) {
        scope.filter.sortInfo = angular.copy(sortInfo);
        getListedObjects();
      };

      scope.changePage = scope.changePage || function (page, pageSize) {
        scope.filter.currentPage = page;
        scope.filter.pageSize = pageSize;
        getListedObjects();
      };

      scope.$on('paginatedmoregrid.reload', function () {
        getListedObjects();
      });

      scope.$on('paginatedmoregrid.clear_search', function () {
        scope.filter.query = {};
        getListedObjects();
      });

      init();
    }
  };
});
