'use strict';

require('./bootstrap');
require('./controllers/FormManagementSubmissionCtrl');
require('./controllers/FormManagementSubmissionDetailCtrl');
require('./controllers/FormManagementMailStatusDetailCtrl');
require('./controllers/FormManagementMailStatusCtrl');
require('./controllers/FormManagementPipelineStatusCtrl');
require('./controllers/FormManagementPipelineDetailCtrl');
require('./controllers/modals/ExportSubmissionsModalCtrl');
