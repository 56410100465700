'use strict';

angular.module('MoreAppsServices').factory('$countryService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const Countries = $resource(moreConstants.countriesEndpoint + '/:countryCode' , {countryCode: ''}, {});

  let cache = null;
  let countriesPromise = null;

  const allCountries = function () {
    if (cache) {
      return cache;
    }
    cache = [];

    if (!countriesPromise) {
      countriesPromise = Countries.query();
    }
    countriesPromise.$promise.then(function (countries) {
      for (var i = 0; i < countries.length; i++) {
        cache.push(countries[i]);
      }
    });
    return cache;
  };

  return {
    allCountries,
    getCountry: countryCode => Countries.get({countryCode}),
  };
}]);
